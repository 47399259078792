import React from "react";
import { Box } from "@mui/material";
import CommonHeader from "../Common/CommonHeader";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import WebhookList from "./WebhookList";

const Webhook = () => {
  return (
    <Box className="section-wrapper">
      <CommonHeader />
      <CustomDivider />
      <WebhookList />
    </Box>
  );
};

export default Webhook;
