import React from "react";
import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import {
  destinationAccount,
  fromAccount,
  transferAmount,
  transferSummary,
} from "../messages";
import {
  getAccNameFromId,
  renderModuleRequestSummaryKeyValuePair,
  renderTermsAgreementCheckbox,
} from "../constants";
import { fetchPrecisedInteger } from "@speed/common/src/components/constants";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";

const TransferRequestSummary = (props) => {
  const { currentAccountId, values, isChecked, setIsChecked } = props;
  return (
    <Box className="margin-top30" display="flex" justifyContent="center">
      <Box width={600}>
        <Text className="default-text" size={20} variant="h2">
          {transferSummary}
        </Text>
        {renderModuleRequestSummaryKeyValuePair(fromAccount, currentAccountId)}
        {renderModuleRequestSummaryKeyValuePair(
          destinationAccount,
          <TruncatedTextTooltip
            textProps={{ className: "default-text", font: "semibold" }}
            textValue={getAccNameFromId(values?.destination_account, false)}
            cellWidth="300px"
          />
        )}
        {renderModuleRequestSummaryKeyValuePair(
          transferAmount,
          `${fetchPrecisedInteger(values?.amount, 8)} ${values?.currency?.code}`
        )}
        {renderTermsAgreementCheckbox(isChecked, setIsChecked)}
      </Box>
    </Box>
  );
};

export default TransferRequestSummary;
