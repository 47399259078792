import React from "react";
import { Box, RadioGroup } from "@mui/material";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import {
  billingAddressOnly,
  shipping,
  billingAndShippingAddress,
  billing,
  collectBillingAddress,
} from "../messages";
import { commonStyle } from "./AllLinkFormConstant";
import { RadioButton } from "@speed/common/src/components/RadioButton/RadioButton";

const BillingAddress = ({ values, setFieldValue }) => {
  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      setFieldValue("isCheckedBilling", !values.isCheckedBilling);
      setFieldValue("selectedValue", billing);
    }
  };

  return (
    <Box mt="6px" className={values.checkedCashback && "margin-top30"}>
      <CustomCheckbox
        checked={values.isCheckedBilling}
        name="collectBilling"
        label={collectBillingAddress}
        className="options"
        sx={commonStyle}
        onKeyDown={handleKeyDown}
        onChange={(e) => {
          setFieldValue("isCheckedBilling", e.target.checked);
          setFieldValue("addressType", billing);
        }}
      />
      {values.isCheckedBilling && (
        <RadioGroup>
          <RadioButton
            style={{
              marginTop: "16px",
              marginLeft: "28px",
            }}
            label={billingAddressOnly}
            value={billing}
            name="role"
            onChange={() => setFieldValue("addressType", billing)}
            checked={values.addressType === billing}
          />
          <RadioButton
            style={{
              marginTop: "16px",
              marginLeft: "28px",
            }}
            label={billingAndShippingAddress}
            value={shipping}
            name="role"
            onChange={() => setFieldValue("addressType", shipping)}
            checked={values.addressType === shipping}
          />
        </RadioGroup>
      )}
    </Box>
  );
};

export default BillingAddress;
