import React from "react";
import CustomSelect from "@speed/common/src/components/Select/Select";
import { pricingModal, selectModalPlaceholder } from "../messages";
import { Box, MenuItem } from "@mui/material";
import { find } from "lodash";

const PricingModalSelectComponent = ({ value, name, formik, id }) => {
  const pricingModals = [{ label: "Standard Pricing", value: "standard" }];
  const { handleChange } = formik;
  return (
    <CustomSelect
      customClass="price-modal"
      name={name}
      showLabel
      label={pricingModal}
      value={value}
      displayEmpty
      placeholder={selectModalPlaceholder}
      MenuProps={{
        id: "price-modal-dropdown",
      }}
      onChange={handleChange}
      renderValue={(value) => {
        if (value) {
          let priceObject = find(pricingModals, { value: value });

          return priceObject?.label;
        } else {
          return <Box> {selectModalPlaceholder} </Box>;
        }
      }}
    >
      {pricingModals.map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      ))}
    </CustomSelect>
  );
};

export default PricingModalSelectComponent;
