import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import CommonHeader from "../Common/CommonHeader";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import AllTransaction from "./AllTransaction";
import {
  selectTabOnSearch,
  setQueryParamsForTab,
  transactionStatus,
} from "@speed/common/src/components/constants";
import { useLocation } from "react-router";
import history from "@speed/common/src/components/history";
import { confirmed, filter } from "../messages";
import {
  setIsWalletTransactonDrawerOpen,
  setWalletAppliedTransactonFiltersObj,
  setWalletSelectedTransaction,
} from "../../redux/common/actions";
import { useDispatch, useSelector } from "react-redux";
import Button from "@speed/common/src/components/Button/Button";
import { clearButtonLabel } from "@speed/common/src/messages";

const WalletTransaction = () => {
  const search = useLocation().search;
  const dispatch = useDispatch();
  const targetCurrency = new URLSearchParams(search).get("target_currency");
  const { walletTransactionAppliedFilterObj } = useSelector(
    (state) => state.common
  );

  const [selectedTab, setSelectedTab] = useState("1");

  useEffect(() => {
    const name = new URLSearchParams(search).get("status");
    selectTabOnSearch(name || null, tabData, setSelectedTab);
    handleSetQueryParam(name || null);
  }, []);

  const tabData = [
    {
      label: confirmed,
      value: "1",
      tabContent: <AllTransaction tab="" />,
      tabName: confirmed.toLowerCase(),
    },
    {
      label: transactionStatus.pending.label,
      value: "2",
      tabContent: <AllTransaction tab={transactionStatus.pending.value} />,
      tabName: transactionStatus.pending.value,
    },
  ];

  const handleChange = (_event, newValue) => {
    dispatch(setWalletSelectedTransaction(null));
    dispatch(setWalletAppliedTransactonFiltersObj(null));
    setSelectedTab(newValue);
    const selectedTabName = setQueryParamsForTab(tabData, newValue);
    handleSetQueryParam(selectedTabName);
  };

  const getSearchParams = (searchValue) => {
    const sourceType = new URLSearchParams(search).get("source_type");
    const gteDateCreated = new URLSearchParams(search).get("created_gte");
    const lteDateCreated = new URLSearchParams(search).get("created_lte");

    let val = "";

    if (searchValue) {
      val = `?status=${searchValue}`;
    }
    if (targetCurrency) {
      val = val + `${searchValue ? "&" : ""}target_currency=${targetCurrency}`;
    }
    if (sourceType) {
      val = val + `&source_type=${sourceType}`;
    }
    if (gteDateCreated && lteDateCreated) {
      val =
        val + `&created_gte=${gteDateCreated}&created_lte=${lteDateCreated}`;
    }

    return val.length ? val : null;
  };

  const handleSetQueryParam = (searchValue) => {
    history.push({
      pathname: "/wallet-transactions",
      search: getSearchParams(searchValue),
    });
  };

  const handleClearTransactionFilters = () => {
    dispatch(setWalletSelectedTransaction(null));
    dispatch(setWalletAppliedTransactonFiltersObj(null));
    history.push(history.location.pathname);
  };

  const renderHeaderActionButtons = () => (
    <Box className="action-btn-wrapper">
      {walletTransactionAppliedFilterObj && (
        <Button
          color="error"
          icon="closeIcon"
          label={clearButtonLabel}
          onClick={handleClearTransactionFilters}
          variant="outlined"
          sx={{ mr: "8px" }}
        />
      )}
      <Button
        icon="filterIcon"
        label={filter}
        onClick={() => {
          dispatch(setIsWalletTransactonDrawerOpen(true));
        }}
        variant="outlined"
        sx={{ top: 0 }}
      />
    </Box>
  );

  return (
    <Box className="section-wrapper wallet-transaction-section">
      {selectedTab === "1" && renderHeaderActionButtons()}
      <CommonHeader />
      <CustomTab
        onChange={handleChange}
        tabData={tabData}
        selectedTab={selectedTab}
        sx={{
          position: "relative",
          minHeight: "unset",
          "& .MuiTabPanel-root": { minHeight: "unset", padding: 0 },
        }}
      />
      <CustomDivider />
    </Box>
  );
};

export default WalletTransaction;
