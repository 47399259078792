import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  TableCell,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import AddIcon from "@mui/icons-material/Add";
import { useFormik } from "formik";
import * as yup from "yup";
import { useFlags } from "launchdarkly-react-client-sdk";
import classNames from "classnames";
import Button from "@speed/common/src/components/Button/Button";
import CustomTable from "@speed/common/src/components/Table/Table";
import {
  clipboardElement,
  linkStatus,
} from "@speed/common/src/components/constants";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import { emptySubText } from "@speed/common/src/components/messages";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import Text from "@speed/common/src/components/Text/Text";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import {
  activate,
  addNewDomain,
  alreadyTakenUsername,
  createNew,
  deActivate,
  emptyPaymentAddressSubText,
  enterSuccessMessage,
  enterSuccessUrl,
  paymentDomainPreviewURL,
  urlWithHttpValidate,
  validateUsername,
  validateUsernameLength,
} from "../messages";
import { emptyPaymentAddresses } from "../images";
import {
  callAPIInterface,
  customPaymentDomainStatus,
  dateTimeFormatInApp,
  isPaymentAddressUsernameValid,
  noOfRecords,
  validateURL,
} from "../constants";
import CreatePaymentAddressModal from "./CreatePaymentAddressModal";
import NoDataAvailable from "@speed/common/src/components/NoDataAvailable";
import { setIsDataAvailable, setIsModalOpen } from "../../redux/common/actions";
import { CustomLink } from "@speed/common/src/components/Link/Link";

const tableHeader = [
  {
    title: "Payment Address ID",
    width: "170px",
    paddingLeft: "38px !important",
  },
  {
    title: "Payment Address",
    width: "470px",
  },
  { title: "Created on", width: "300px" },
  { title: "Status", width: "100px" },
  { title: "Actions", width: "25px", align: "center" },
];

const PaymentAddressList = (props) => {
  const { tab } = props;
  const initialDomainList = [{ domain: paymentDomainPreviewURL }];
  const queryParam = "?limit=" + noOfRecords;
  const [rows, setRows] = useState([]);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [hasMoreDomains, setHasMoreDomains] = useState(true);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [rowLoader, setRowLoader] = useState(false);
  const [columns, setColumns] = useState(tableHeader);
  const [domainsExist, setDomainsExist] = useState(initialDomainList);
  const [domainSkeleton, setDomainSkeleton] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [queryParamsOfDomain, setQueryParamsOfDomain] = useState(queryParam);

  const dispatch = useDispatch();

  const { history, isDataAvailable, isModalOpen, caughtErrorInStrapi } =
    useSelector((state) => state.common);
  const liveMode = useSelector((state) => state.auth.liveMode);

  const {
    paCreatepaymentaddressFe,
    paListallpaymentaddressFe,
    paActivatepaymentaddressFe,
    paDeactivatepaymentaddressFe,
  } = useFlags();

  useEffect(() => {
    if (isModalOpen) {
      handleCreatePaymentAddress();
    }
  }, [isModalOpen]);

  const fetchPaymentAddresses = () => {
    setRows([]);
    setHasMore(true);
    getPaymentAddresses([], queryParam);
  };

  const loadMore = useCallback(() => {
    getPaymentAddresses(rows, queryParams);
  }, [rows]);

  const fetchPaymentDomains = () => {
    setDomainsExist(initialDomainList);
    setHasMoreDomains(true);
    getPaymentDomains(initialDomainList, queryParamsOfDomain);
  };

  const getPaymentDomains = (lines, params) => {
    setDomainSkeleton(true);
    callAPIInterface("POST", `/settings/payment-domains/filter${params}`, {
      status: customPaymentDomainStatus.ready.value,
    })
      .then((domainRes) => {
        setDomainSkeleton(false);
        if (!domainRes.has_more) {
          setHasMoreDomains(false);
        } else {
          setQueryParamsOfDomain(
            queryParam +
              "&ending_before=" +
              domainRes.data[domainRes.data.length - 1].id
          );
        }
        setDomainsExist(lines.concat(domainRes.data));
      })
      .catch(() => {
        setDomainSkeleton(false);
      });
  };

  const loadMoreDomains = useCallback(() => {
    getPaymentDomains(domainsExist, queryParamsOfDomain);
  }, [domainsExist]);

  const getPaymentAddresses = (lines, params) => {
    setTableRowSkeleton(true);
    let method,
      data,
      path = "";
    if (tab) {
      method = "POST";
      data = { status: tab };
      path = "/payment-addresses/filter";
    } else {
      method = "GET";
      data = {};
      path = "/payment-addresses";
    }

    callAPIInterface(method, path + params, data)
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam + "&ending_before=" + res.data[res.data.length - 1].id
            );
          }
          setRows(lines.concat(res.data));
          dispatch(setIsDataAvailable(res.data.length > 0));
        }
      })
      .catch(() => {
        setTableRowSkeleton(false);
      });
  };

  useEffect(
    () => paListallpaymentaddressFe && fetchPaymentAddresses(),
    [liveMode, paListallpaymentaddressFe]
  );

  const shouldRenderActionColumn = (fromActionButton, status) => {
    if (tab === undefined) {
      if (fromActionButton) {
        return status === linkStatus.active.value
          ? paDeactivatepaymentaddressFe
          : paActivatepaymentaddressFe;
      } else {
        return paDeactivatepaymentaddressFe || paActivatepaymentaddressFe;
      }
    } else if (tab === linkStatus.active.value) {
      return paDeactivatepaymentaddressFe;
    } else if (tab === linkStatus.deactivated.value) {
      return paActivatepaymentaddressFe;
    }
  };

  useEffect(() => {
    if (shouldRenderActionColumn(false)) {
      setColumns(tableHeader);
    } else {
      const newColumns = tableHeader.filter(
        (column) => column.title !== "Actions"
      );
      setColumns(newColumns);
    }
  }, [tab, paDeactivatepaymentaddressFe, paActivatepaymentaddressFe]);

  const validationSchema = yup.object().shape({
    username: yup
      .string()
      .required("")
      .min(5, validateUsernameLength)
      .matches(isPaymentAddressUsernameValid, validateUsername),
    domain: yup.string().required(""),
    success_message_checked: yup.boolean(),
    success_message: yup.string().when("success_message_checked", {
      is: true,
      then: yup.string().required(enterSuccessMessage),
    }),
    success_url_checked: yup.boolean(),
    success_url: yup.string().when("success_url_checked", {
      is: true,
      then: yup
        .string()
        .required(enterSuccessUrl)
        .test({
          name: "success_url",
          message: urlWithHttpValidate,
          test: (success_url) => validateURL(success_url, true),
        }),
    }),
  });

  const initialValues = {
    username: "",
    domain: "",
    success_message_checked: false,
    success_message: "",
    success_url_checked: false,
    success_url: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (value) => {
      setIsSubmitting(true);
      const domainValue = {
        username: value.username,
        domain: value.domain,
      };
      callAPIInterface(
        "POST",
        "/payment-addresses/validate",
        JSON.stringify(domainValue)
      )
        .then((validateResponse) => {
          if (validateResponse) {
            if (validateResponse.is_available) {
              if (value.success_message_checked) {
                domainValue["success_message"] = value.success_message;
              }
              if (value.success_url_checked) {
                domainValue["success_url"] = value.success_url;
              }
              callAPIInterface(
                "POST",
                "/payment-addresses",
                JSON.stringify(domainValue)
              )
                .then((result) => {
                  result && history.push(`/payment-addresses/${result.id}`);
                  handleModalClose();
                })
                .catch(() => {
                  handleModalClose();
                });
            } else {
              setIsSubmitting(false);
              setErrors({
                username: alreadyTakenUsername,
              });
            }
          }
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    },
  });

  const {
    values,
    setFieldValue,
    touched,
    setTouched,
    isValid,
    dirty,
    handleSubmit,
    errors,
    setErrors,
    resetForm,
  } = formik;

  const handleClick = (event, rowData) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setCurrentRow(rowData);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick(e, currentRow);
    }
  };

  let columnsData = columns.map((column) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{
        minWidth: column.width,
        height: "50px",
        paddingLeft: column.paddingLeft,
      }}
    >
      {column.title}
    </TableCell>
  ));

  let rowsData = rows?.map((rowItem, rowIndex) => (
    <TableRow
      key={rowItem?.id}
      className="clickable"
      onClick={() => history.push(`/payment-addresses/${rowItem?.id}`)}
    >
      {rowItem?.id === currentRow?.id && rowLoader ? (
        columns.map((column) => (
          <TableCell
            key={`${column.title}_loader`}
            sx={{
              width: column.width,
              padding: "18px 16px !important",
              height: "50px",
            }}
          >
            <Skeleton />
          </TableCell>
        ))
      ) : (
        <>
          <TableCell sx={[{ height: "50px", paddingLeft: "38px !important" }]}>
            {clipboardElement(
              rowItem?.id,
              true,
              "payments-listing-id-clipboard",
              "inputBox",
              false
            )}
          </TableCell>
          <TableCell>
            {clipboardElement(
              rowItem?.address,
              rowItem?.status === linkStatus.active.value,
              "",
              "inputBox",
              false
            )}
          </TableCell>
          <TableCell>{dateTimeFormatInApp(rowItem?.created)}</TableCell>
          <TableCell>
            <Tag
              text={linkStatus[rowItem?.status].label}
              variant={linkStatus[rowItem.status].variant}
            />
          </TableCell>
          {shouldRenderActionColumn(false) && (
            <TableCell align="center">
              {shouldRenderActionColumn(true, rowItem?.status) && (
                <MoreHorizIcon
                  className="horizontal-dots-icon"
                  tabIndex={0}
                  onKeyDown={handleKeyDown}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClick(event, { ...rowItem, index: rowIndex });
                  }}
                />
              )}
            </TableCell>
          )}
        </>
      )}
    </TableRow>
  ));

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    textOnNoData: emptySubText(liveMode, "payment address", tab),
    subTextOnNoData: emptyPaymentAddressSubText,
    noDataImage: emptyPaymentAddresses,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
  };

  const handleUpdate = () => {
    setRowLoader(true);
    const method =
      currentRow?.status === linkStatus.active.value
        ? "deactivate"
        : "activate";
    callAPIInterface(
      "POST",
      `/payment-addresses/${currentRow?.id}/${method}`,
      {}
    )
      .then((res) => {
        if (res) {
          setAnchorEl(null);
          const rs = [...rows];
          if (tab) {
            rs?.splice(currentRow?.index, 1);
          } else {
            rs?.splice(currentRow?.index, 1, res);
          }
          setRows(rs);
          setRowLoader(false);
        }
      })
      .catch(() => {
        setRowLoader(false);
      });
  };

  const handleModalClose = () => {
    setIsSubmitting(false);
    dispatch(setIsModalOpen(false));
    resetForm(initialValues);
  };

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const addNewPaymentAddress = (
    <CustomLink
      className="pointer-cursor add-payment-custom-domain-wrapper"
      onClick={() => history.push("/settings/custom-payment-domain")}
    >
      <AddIcon
        sx={{
          width: "16px",
          height: "16px",
          color: "inherit",
        }}
      />
      <Text
        className="default-text"
        size={16}
        variant="body1"
        sx={{
          color: "inherit !important",
        }}
        component="span"
      >
        {addNewDomain}
      </Text>
    </CustomLink>
  );

  const handleCreatePaymentAddress = () => {
    dispatch(setIsModalOpen(true));
    fetchPaymentDomains();
  };

  const createPaymentAddressButton = () =>
    paCreatepaymentaddressFe && (
      <Box className="action-btn-wrapper mui-fixed">
        <Button
          icon="addIcon"
          className="add-icon"
          label={createNew}
          variant="outlined"
          color="primary"
          onClick={() => dispatch(setIsModalOpen(true))}
        />
      </Box>
    );

  return (
    <>
      {(isDataAvailable || (!isDataAvailable && tab) || caughtErrorInStrapi) &&
        createPaymentAddressButton()}

      <Box className="main-content">
        {isDataAvailable ? (
          <>
            {paListallpaymentaddressFe ? (
              <CustomTable {...tableProps} />
            ) : (
              <AccessDenied />
            )}
          </>
        ) : (
          (tab || caughtErrorInStrapi) && (
            <Box
              minHeight="calc(100vh - 201px)"
              display="flex"
              justifyContent="center"
            >
              <NoDataAvailable
                text={tableProps.textOnNoData}
                image={emptyPaymentAddresses}
                subText={emptyPaymentAddressSubText}
              />
            </Box>
          )
        )}
        <CustomPopper
          disablePortal={true}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          position="bottom"
          handleClose={() => setAnchorEl(null)}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={handleUpdate}>
                <ListItemText
                  primary={
                    currentRow?.status === linkStatus.active.value
                      ? deActivate
                      : activate
                  }
                  className={classNames(
                    currentRow?.status === linkStatus.active.value &&
                      "text-danger"
                  )}
                />
              </ListItemButton>
            </ListItem>
          </List>
        </CustomPopper>
        <CreatePaymentAddressModal
          openModal={isModalOpen}
          handleModalClose={handleModalClose}
          isValid={isValid}
          dirty={dirty}
          isSubmitting={isSubmitting}
          handleSubmit={handleSubmit}
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          setTouched={setTouched}
          errors={errors}
          defaultErrorMessageProps={defaultErrorMessageProps}
          addNewPaymentAddress={addNewPaymentAddress}
          initialDomains={domainsExist}
          domainSkeleton={domainSkeleton}
          loadMoreDomains={loadMoreDomains}
          hasMoreDomains={hasMoreDomains}
        />
      </Box>
    </>
  );
};

export default PaymentAddressList;
