import React, { forwardRef } from "react";
import { Box } from "@mui/material";
import ProductTID from "./ProductTID";
import PricingForm from "./PricingForm";

const ProductForm = forwardRef(
  (
    { isCreateProduct, pricesRef, setDisabled, setDisableCreateButton },
    ref
  ) => {
    return (
      <Box display="flex" justifyContent="center">
        <ProductTID
          ref={ref}
          isCreateProduct={isCreateProduct}
          setDisabled={setDisabled}
        />

        <PricingForm
          ref={pricesRef}
          isCreateProduct={isCreateProduct}
          setDisableCreateButton={setDisableCreateButton}
        />
      </Box>
    );
  }
);

export default ProductForm;
