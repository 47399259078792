import { Box } from "@mui/material";
import { Input } from "@speed/common/src/components/Input/Input";
import { useFormik } from "formik";
import {
  accountInfoFormLabel,
  addAdditionalDetails,
  currencyLabel,
  currencyPlaceholder,
  customerDescriptionPlaceHolder,
  customerEmailRequired,
  customerNameRequired,
  description,
  emailText,
  enterEmailPlaceHolder,
  enterNamePlaceHolder,
  mobileNoLabel,
  name,
} from "../messages";
import { invalidEmail } from "@speed/common/src/components/messages";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import { currency } from "@speed/common/src/components/currency";
import {
  getTrimmedString,
  mobileNumberWithoutCallingCode,
  renderCurrencyOption,
  validatePhoneNumber,
} from "../constants";
import * as yup from "yup";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import { removeAllEmptyFieldsFromNestedObj } from "@speed/common/src/components/constants";
import CustomAccordion from "@speed/common/src/components/Accordion/Accordion";

const CustomerAccountInfoForm = forwardRef(
  (
    {
      infoTitleElement,
      commonMobileNumberInput,
      setIsoCode,
      handleButtonDisable,
      isNextStep,
      fromInvoiceModal = false,
      renderBillingShippingSection,
    },
    ref
  ) => {
    const [billingMobileISOCode, setBillingMobileISOCode] = useState();
    const [expanded, setExpanded] = useState("");

    const validationSchema = yup.object({
      name: yup.string().required().trim(),
      email: yup.string().required().email(),
      billing_phone_number: yup.mixed().test({
        name: "billing_phone_number",
        test: (phoneNumber) => validatePhoneNumber(phoneNumber),
      }),
    });

    const formik = useFormik({
      initialValues: {
        name: "",
        email: "",
        billing_calling_code: "91",
        billing_phone_number: null,
        description: "",
        currency: null,
      },
      validationSchema: validationSchema,
      enableReinitialize: true,
    });

    const {
      values,
      errors,
      isValid,
      isSubmitting,
      dirty,
      setFieldValue,
      touched,
      setTouched,
    } = formik;

    useEffect(() => {
      const disable = !(isValid && dirty) || isSubmitting;
      handleButtonDisable(disable);
    }, [isValid, dirty, isSubmitting]);

    useEffect(() => {
      setIsoCode(values, "billing_calling_code", setBillingMobileISOCode);
    }, [values.billing_calling_code]);

    const createAccountFormData = () => {
      const {
        name,
        email,
        billing_calling_code,
        billing_phone_number,
        description,
        currency,
      } = values;

      let submittedformData = {
        name: getTrimmedString(name),
        email,
        ...(billing_phone_number && { calling_code: billing_calling_code }),
        phone: mobileNumberWithoutCallingCode(
          billing_calling_code,
          billing_phone_number
        ),
        description: getTrimmedString(description),
        currency: currency?.code,
      };

      const filteredObj = removeAllEmptyFieldsFromNestedObj(submittedformData);
      return filteredObj;
    };

    useImperativeHandle(ref, () => ({
      handleSubmitAccountData: createAccountFormData,
    }));

    const handleChange = (title) => (_e, newExpanded) => {
      setExpanded(newExpanded ? title : false);
    };

    const customStyle = !fromInvoiceModal && {
      sx: { display: isNextStep ? "none" : "block" },
    };

    const commonAdditionalDetailSection = () => (
      <>
        <Box className={fromInvoiceModal ? "input-box" : "margin-top30"}>
          <TextAreaComponent
            showLabel
            label={description}
            value={values.description}
            maxRows={4}
            minRows={4}
            maxLength={250}
            fullWidth
            error={Boolean(errors.description)}
            onBlur={() => setTouched({ ...touched, description: true })}
            onChange={(e) => {
              setFieldValue("description", e.target.value);
            }}
            placeholder={customerDescriptionPlaceHolder}
          />
        </Box>
        <Box className={fromInvoiceModal ? "input-box" : "margin-top30"}>
          <CustomAutoComplete
            {...(fromInvoiceModal && { className: "auto-complete-icon" })}
            placeholder={currencyPlaceholder}
            name="currency"
            options={currency}
            getOptionLabel={(value) => {
              return `${value.code}`;
            }}
            onChange={(_e, value) => {
              setFieldValue("currency", value);
            }}
            value={values.currency}
            showLabel
            label={currencyLabel}
            disableClearable
            fullWidth
            renderOption={renderCurrencyOption}
          />
        </Box>
      </>
    );

    const accordianFormComponent = () => (
      <Box className="customer-form-wrapper">
        {commonAdditionalDetailSection()}
        {renderBillingShippingSection()}
      </Box>
    );

    return (
      <Box
        className={!fromInvoiceModal && "customer-form-box"}
        autoComplete="off"
        {...customStyle}
      >
        {!fromInvoiceModal && infoTitleElement(accountInfoFormLabel)}
        <Box className="customer-form-info">
          <Input
            type="text"
            name="name"
            inputProps={{ maxLength: 250 }}
            value={values.name}
            customClass={!fromInvoiceModal && "margin-top30"}
            showLabel
            label={name}
            fullWidth
            placeholder={enterNamePlaceHolder}
            error={Boolean(errors.name)}
            onBlur={() => setTouched({ ...touched, name: true })}
            onChange={(e) => {
              setFieldValue("name", e.target.value);
            }}
          />
          {touched.name && Boolean(errors.name) && (
            <AlertMessage
              className="margin-top14"
              severity="error"
              message={customerNameRequired}
            />
          )}

          <Input
            customClass="margin-top30"
            showLabel
            label={emailText}
            type="email"
            fullWidth
            name="email"
            inputProps={{ maxLength: 250 }}
            value={values.email}
            onBlur={() => setTouched({ ...touched, email: true })}
            onChange={(e) => {
              setFieldValue("email", e.target.value);
            }}
            placeholder={enterEmailPlaceHolder}
          />
          {touched.email && Boolean(errors.email) && (
            <AlertMessage
              className="margin-top14"
              severity="error"
              message={values.email ? invalidEmail : customerEmailRequired}
            />
          )}

          {commonMobileNumberInput(
            formik,
            "billing_calling_code",
            "billing_phone_number",
            billingMobileISOCode,
            mobileNoLabel
          )}

          {fromInvoiceModal ? (
            <Box className="add-more-details">
              <CustomAccordion
                style={{ width: "fit-content" }}
                className="additional-details"
                isTitleShown={true}
                expandIcon="expandMore"
                accordianPanel={true}
                onChange={(title) => handleChange(title)}
                expanded={expanded}
                title={addAdditionalDetails}
                customComponent={
                  <Box
                    width="520px"
                    className="create-customer-modal invoice-additional-details"
                  >
                    {accordianFormComponent()}
                  </Box>
                }
              />
            </Box>
          ) : (
            commonAdditionalDetailSection()
          )}
        </Box>
      </Box>
    );
  }
);

export default CustomerAccountInfoForm;
