import React from "react";
import GetGuides from "./GetGuides";

const CheckoutLinkGuide = () => {
  return (
    <GetGuides
      moduleName="checkout_link"
      redirectUrl="/checkout-links"
      guideApiPath="checkout-link-guide"
      getStartedApiPath="Checkout"
      needHelpGuidePath="docs/receive-payments/checkout-link"
    />
  );
};

export default CheckoutLinkGuide;
