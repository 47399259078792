import { AppBar, Box, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import { forwardRef, useEffect, useRef, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { lightning, receive, sats } from "../../messages";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import ReceiveFooter from "../ReceivePayment/ReceiveFooter";
import { useDispatch, useSelector } from "react-redux";
import { getAccountFirebaseToken } from "../../../redux/auth/actions";
import { app } from "@speed/common/src/util/firebase";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import {
  collection,
  getFirestore,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import history from "@speed/common/src/components/history";
import { QRCodeComponent } from "@speed/common/src/components/QRCode/QRCode";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import { callAPIInterface } from "../../constants";
import { speedQrLogo } from "../../images";
import AccountFees from "../ReceivePayment/AccountFees";
import { setPaymentProcessingRecallApis } from "../../../redux/common/actions";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

let unsubscribe;
const PaymentAddressModal = ({
  isOpenPaymentAddressModal,
  setIsOpenPaymentAddressModal,
}) => {
  const { user, currentAccount } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const divRef = useRef();
  const [accountFees, setAccountFees] = useState(null);
  const [accountFeesLoading, setAccountFeesLoading] = useState(true);

  const walletPaymentAddress = useSelector(
    (state) => state.common.walletPaymentAddress
  );

  useEffect(() => {
    fetchPayment();
    fetchAccountFees();
  }, []);

  const fetchAccountFees = async () => {
    setAccountFeesLoading(true);
    try {
      const params = `?payment_method=${lightning}&transaction_code=01&target_currency=${sats}`;

      const accountFeesRes = await callAPIInterface(
        "GET",
        `/account-fees` + params
      );
      setAccountFees(accountFeesRes?.fee_percentage);
    } catch (err) {}
    setAccountFeesLoading(false);
  };

  const handleModalClose = () => {
    dispatch(setPaymentProcessingRecallApis(true));
    setIsOpenPaymentAddressModal(false);
    setAccountFees(null);
    unsubscribe?.();
  };

  const getPaymentAddressData = () => {
    const db = getFirestore(app);
    const paymentDataQuery = query(
      collection(db, "account", currentAccount?.account?.id, "payments"),
      where("modified", ">", new Date().getTime())
    );
    try {
      unsubscribe = onSnapshot(paymentDataQuery, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data();

          if (data) {
            if (data.status?.toLowerCase() === "paid") {
              history.push({
                pathname: "/success-page",
                data: { paymentData: data, fromReceived: true },
              });
              unsubscribe();
            }
          }
        });
      });
    } catch (_error) {}
  };

  const fetchPayment = async () => {
    try {
      const token = await getAccountFirebaseToken();
      const authObj = getAuth(app);

      if (token?.firebase_token) {
        await signInWithCustomToken(authObj, token?.firebase_token);
        getPaymentAddressData();
      }
    } catch (_err) {}
  };

  const showQrCode = () => {
    return (
      <div ref={divRef}>
        <QRCodeComponent
          value={walletPaymentAddress}
          size={410}
          icon
          iconSize={25}
          level={"H"}
          includeMargin={true}
          borderRadius="10px"
          src={speedQrLogo}
        />
      </div>
    );
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={isOpenPaymentAddressModal}
      TransitionComponent={Transition}
      className="fullscreen-modal receive-modal-dialog"
      onClose={handleModalClose}
      sx={{ overflow: "unset" }}
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1 }}
            className="default-text divider"
            variant="h6"
          >
            {receive}
          </Text>
        </Toolbar>
      </AppBar>
      <Box className="receive-modal-container">
        <Box className="receive-container">
          <Box className="payment-qr-code payment-address-qr-code">
            <TruncatedTextTooltip
              textProps={{
                size: 30,
                variant: "h6",
                className: "default-text ",
                font: "semibold",
                style: { marginBottom: "40px" },
              }}
              className="payment-address-name"
              textValue={user?.name}
              cellWidth="264px"
            />
            <Box className="payment-address-receive-box">
              {showQrCode()}
              <Box
                marginTop="30px !important"
                bgcolor="#F7FAFC !important"
                className="lnaddress-modal"
              >
                <Text
                  size={16}
                  className="default-text"
                  variant="h6"
                  font="semibold"
                  style={{ color: "#2A67FF", marginRight: "8px" }}
                >
                  {walletPaymentAddress}
                </Text>
                <Clipboard text={walletPaymentAddress} />
              </Box>
              <AccountFees
                isLoading={accountFeesLoading}
                accountFees={accountFees}
                isPaymentAddress={true}
              />
            </Box>
            <ReceiveFooter isPaymentAddress={true} divRef={divRef} />
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default PaymentAddressModal;
