import React, { useState } from "react";
import PayoutWalletFormCommon from "../PayoutWalletFormCommon";
import { useFormik } from "formik";
import {
  addWalletButton,
  addWalletSubmitFunc,
  payoutWalletCurrencies,
  payoutWalletValidationSchema,
} from "../../../constants";
import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import Button from "@speed/common/src/components/Button/Button";
import {
  addNewWalletSmall,
  cancel,
  setupPW,
  setupPWSubtext,
  walletAdded,
  walletAddedSuccessMsg,
} from "../../../messages";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SetupCommonHeader from "./SetupCommonHeader";

const SetupPayoutWallet = ({ handleNext }) => {
  const [disableAddWallet, setDisableAddWallet] = useState(false);
  const [isButtonClick, setIsButtonClick] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleButtonSubmit = (values) => {
    addWalletSubmitFunc({
      values,
      setDisableAddWallet,
      successCallBack: () => setOpenModal(true),
      additionalQuery: "?from=onboarding",
    });
  };

  const formik = useFormik({
    initialValues: {
      wallet_name: "",
      currency: payoutWalletCurrencies[0],
      wallet_address: "",
    },
    validationSchema: payoutWalletValidationSchema,
    onSubmit: handleButtonSubmit,
  });

  const { isValid, touched, handleSubmit } = formik;

  const disableCheck =
    !isValid ||
    (Object.keys(touched).length === 0 && touched.constructor === Object) ||
    disableAddWallet;

  const modalBody = (
    <Box className="content-center" flexDirection="column">
      <CheckCircleIcon
        sx={{ width: "60px", height: "60px", color: "#2ECC71", mb: "16px" }}
      />
      <Text
        size={18}
        className="default-text"
        variant="subtitle1"
        font="regular"
        align="center"
      >
        {walletAddedSuccessMsg}
      </Text>
    </Box>
  );

  const handleModalClose = () => {
    setOpenModal(false);
    handleNext();
  };

  return (
    <>
      <SetupCommonHeader header={setupPW} subHeader={setupPWSubtext} />
      <Box width="450px">
        {isButtonClick ? (
          <>
            <Box mt="50px" mb="30px">
              <PayoutWalletFormCommon
                formik={formik}
                currencies={payoutWalletCurrencies}
              />
            </Box>
            <Box mb="30px">
              {addWalletButton(handleSubmit, disableCheck)}
              <Button
                variant="outlined"
                sx={{ ml: "16px" }}
                label={cancel}
                disabled={disableAddWallet}
                onClick={() => setIsButtonClick(false)}
              />
            </Box>
          </>
        ) : (
          <Button
            variant="contained"
            sx={{ mt: "30px" }}
            label={addNewWalletSmall}
            onClick={() => setIsButtonClick(true)}
          />
        )}
      </Box>
      <Modal
        body={modalBody}
        handleClose={handleModalClose}
        open={openModal}
        title={walletAdded}
      />
    </>
  );
};

export default SetupPayoutWallet;
