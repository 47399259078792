import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import * as yup from "yup";

import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Text from "@speed/common/src/components/Text/Text";
import LimitField from "./LimitField";
import {
  adjustLimitMsg,
  dailySendLimit,
  maxSendLimit,
  minSendLimit,
  successSendLimit,
  transactionLimitTitle,
} from "../../messages";
import {
  setSettingFormCancel,
  setSettingFormChange,
  setSettingFormSubmit,
  setSettingFormValid,
  showToast,
} from "../../../redux/common/actions";
import { callAPIInterface, instantSendLimits } from "../../constants";
import { BTC } from "@speed/common/src/components/messages";
import {
  btcToSatsAmount,
  instantSendTypes,
  maxSat,
  minSat,
} from "@speed/common/src/components/constants";
import { currency } from "@speed/common/src/components/currency";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";

const InstantSendSettings = () => {
  const initialCurrency = { name: "Bitcoin", code: "BTC", symbol: "฿" };
  const dispatch = useDispatch();

  // Component States
  const [limitsData, setLimitsData] = useState([]);
  const [loader, setLoader] = useState(false);

  // Redux selectors
  const { settingFormSubmit, settingFormCancel } = useSelector(
    (state) => state.common
  );

  const getLimitAmount = (currencyCode, value = 0) => {
    return currencyCode === "BTC" ? btcToSatsAmount(value) : Number(value);
  };

  const transactionValidation = (amountValueInSats, parent) => {
    const lightningLimit = getLimitAmount(
      parent.lightningCurrency?.code,
      parent.lightningLimit
    );
    const onchainLimit = getLimitAmount(
      parent.onchainCurrency?.code,
      parent.onchainLimit
    );
    return amountValueInSats &&
      (lightningLimit || onchainLimit) &&
      amountValueInSats < lightningLimit + onchainLimit
      ? { isValid: false, errorMessage: dailySendLimit }
      : { isValid: true };
  };

  const limitValidation = (value, parent, type) => {
    const currency = parent[type];

    // First convert amount to SATS
    const amountValueInSats =
      currency?.code === BTC && value ? btcToSatsAmount(value) : Number(value);

    if (amountValueInSats < minSat) {
      return {
        isValid: false,
        errorMessage: minSendLimit,
      };
    } else if (amountValueInSats > maxSat) {
      return {
        isValid: false,
        errorMessage: maxSendLimit,
      };
    } else if (type === "transactionCurrency")
      return transactionValidation(amountValueInSats, parent);
    else return { isValid: true };
  };

  const validation = (type, currency) => {
    return {
      [type]: yup.string().test("validate-limit", function (value) {
        const validation = limitValidation(value, this.parent, currency);
        if (!validation.isValid) {
          return this.createError({
            path: this.path,
            message: validation.errorMessage,
          });
        } else {
          return true;
        }
      }),
    };
  };

  const validationSchema = yup.object({
    ...validation("lightningLimit", "lightningCurrency"),
    ...validation("onchainLimit", "onchainCurrency"),
    ...validation("transactionLimit", "transactionCurrency"),
  });

  const getInitialData = (type) =>
    limitsData?.length > 0 && limitsData.find((item) => item.type === type);

  const getCurrency = (type) => {
    const currCode = getInitialData(type)?.currency;
    return currency.find((item) => item.code === currCode) || initialCurrency;
  };

  const formik = useFormik({
    initialValues: {
      lightningCurrency: getCurrency(instantSendTypes.lightning),
      onchainCurrency: getCurrency(instantSendTypes.onchain),
      transactionCurrency: getCurrency(instantSendTypes.daily),
      lightningLimit: getInitialData(instantSendTypes.lightning)?.amount || "",
      onchainLimit: getInitialData(instantSendTypes.onchain)?.amount || "",
      transactionLimit: getInitialData(instantSendTypes.daily)?.amount || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { values, isValid, dirty, isSubmitting, resetForm } = formik;

  const getSendLimit = async () => {
    setLoader(true);
    try {
      const result = await callAPIInterface("GET", "/send/limits");
      !isEmpty(result) && setLimitsData(result?.SATS);
    } catch (e) {}
    setLoader(false);
  };

  const handleSubmit = () => {
    const payload = {
      SATS: [],
    };
    const { lightning, onchain, daily } = instantSendTypes;

    const generateObj = (type, curr, limitType) => {
      const id =
        limitsData.length > 0 &&
        limitsData.find((item) => item.type === limitType)?.id;
      const createdObj = {
        id: id || "",
        amount: values[type] || null,
        currency: values[curr]?.code,
        type: limitType,
      };
      !(!id && !values[type]) && payload.SATS.push(createdObj);
    };
    generateObj("lightningLimit", "lightningCurrency", lightning);
    generateObj("onchainLimit", "onchainCurrency", onchain);
    generateObj("transactionLimit", "transactionCurrency", daily);
    callAPIInterface("PUT", "/send/limits", payload)
      .then((res) => {
        dispatch(setSettingFormSubmit(false));
        dispatch(
          showToast({
            isToastOpen: true,
            toastMessage: successSendLimit,
            toastVariant: "success",
          })
        );
        !isEmpty(res) && setLimitsData(res.SATS);
        resetForm();
      })
      .catch((_e) => dispatch(setSettingFormSubmit(false)));
  };

  useEffect(() => {
    getSendLimit();
  }, []);

  useEffect(() => {
    // call API on submit
    if (isValid && dirty && settingFormSubmit) {
      handleSubmit();
    }
  }, [settingFormSubmit]);

  useEffect(() => {
    if (settingFormCancel) {
      resetForm();
      dispatch(setSettingFormCancel(false));
    }
  }, [settingFormCancel]);

  useEffect(() => {
    dispatch(setSettingFormChange(dirty));
  }, [values]);

  const reformedLimitData = useMemo(() => {
    return (
      limitsData.length > 0 && limitsData.filter((item) => item.amount !== null)
    );
  }, [limitsData]);

  useEffect(() => {
    const { lightningLimit, onchainLimit, transactionLimit } = values;
    const whenAmountNotAdded =
      reformedLimitData.length === 0
        ? Boolean(transactionLimit || lightningLimit || onchainLimit)
        : true;
    const disableBtn =
      !whenAmountNotAdded || !(isValid && dirty) || isSubmitting;
    dispatch(setSettingFormValid(disableBtn));
  }, [isValid, dirty, isSubmitting, values, limitsData]);

  const loaderComponent = (
    <>
      <CommonSkeleton
        width={241}
        height={27}
        sx={{ marginBottom: "24px", borderRadius: "7px" }}
        color="#c4ccd2"
      />
      <CommonSkeleton
        width={314}
        height={18}
        sx={{ borderRadius: "6px", mb: "14px" }}
      />
      <CommonSkeleton
        width={"100%"}
        height={30}
        color="#d8dce1"
        sx={{ borderRadius: "6px" }}
      />
    </>
  );

  return (
    <>
      <CustomDivider />
      <Box className="box-container">
        <Box width="700px">
          <Text
            size={16}
            variant="subtitle1"
            font="regular"
            className="grey-text"
          >
            {adjustLimitMsg}
          </Text>
          <Box className="instant-send-container">
            {loader ? (
              loaderComponent
            ) : (
              <>
                <Text
                  size={20}
                  font="semibold"
                  className="default-text"
                  variant="h4"
                >
                  {transactionLimitTitle}
                </Text>
                {instantSendLimits.map(({ id, ...limitItem }) => {
                  return <LimitField {...limitItem} formik={formik} key={id} />;
                })}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default InstantSendSettings;
