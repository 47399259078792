import PropTypes from "prop-types";
import { Breadcrumbs as CustomBreadcrumbs, Link } from "@mui/material";
import classNames from "classnames";
import { Text } from "../Text/Text";
import { Box } from "@mui/system";
import "./breadcrumbs.scss";

export const Breadcrumbs = ({
  breadcrumbData,
  component,
  className,
  queryParam = "",
  ...props
}) => {
  return (
    <CustomBreadcrumbs
      {...props}
      separator="›"
      className={classNames("breadcrumb-wrapper", className)}
    >
      {breadcrumbData &&
        breadcrumbData.length > 0 &&
        breadcrumbData.map((page, index) => {
          const dynamicRoute = page.applySearchParams
            ? page.route + queryParam
            : page.route;

          const lastPage = index === breadcrumbData.length - 1;
          return lastPage ? (
            <Text
              variant="caption"
              className="default-text"
              font="regular"
              key={page.title}
              size={14}
            >
              {page.title}
            </Text>
          ) : (
            <Box
              component={component || Link}
              className="breadcrumb-link-txt"
              key={page.title}
              to={dynamicRoute}
            >
              {page.title}
            </Box>
          );
        })}
    </CustomBreadcrumbs>
  );
};

export const propTypes = {
  breadcrumbData: PropTypes.array.isRequired,
};

const defaultProps = {
  breadcrumbData: null,
};

Breadcrumbs.propTypes = propTypes;
Breadcrumbs.defaultProps = defaultProps;
