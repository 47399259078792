import { AppBar, Box, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Text from "@speed/common/src/components/Text/Text";
import React, { useEffect, useState } from "react";
import {
  LoadCanvasTemplate,
  loadCaptchaEnginge,
  validateCaptcha,
} from "react-simple-captcha";
import { paymentFailedImg } from "../../images";
import { Input } from "@speed/common/src/components/Input/Input";
import {
  captch,
  captchaBlockBGColor,
  captchaCodeLabel,
  captchaCodeLength,
  captchaCodePlaceholder,
  captchaProblemMsg,
  enterValidCaptchErrMsg,
  proceedBtn,
  verifyHuman,
} from "../../messages";
import Button from "@speed/common/src/components/Button/Button";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import { sessionService } from "redux-react-session";
import CustomDivider from "@speed/common/src/components/Divider/Divider";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const RecaptchaModal = ({ openCaptchaModal, setOpenCaptchaModal }) => {
  const [captchaCode, setCaptchaCode] = useState("");
  const [captchaError, setCaptchaError] = useState("");

  useEffect(() => {
    setTimeout(() => {
      loadCaptchaEnginge(captchaCodeLength, captchaBlockBGColor);
    }, 0);
  }, []);

  const handleCaptchVerified = async () => {
    try {
      if (validateCaptcha(captchaCode)) {
        const currentTime = new Date().getTime();
        const session = await sessionService.loadSession();
        await sessionService.saveSession({
          ...session,
          withdraw_processed: 1,
          last_withdraw_processed_time: currentTime,
        });
        setCaptchaError("");
        setOpenCaptchaModal(false);
      } else {
        setCaptchaCode("");
        setCaptchaError(enterValidCaptchErrMsg);
      }
    } catch (_e) {
      throw new Error(_e);
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={openCaptchaModal}
      TransitionComponent={Transition}
      className="fullscreen-modal send-modal-dialog-section"
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setOpenCaptchaModal(false)}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1 }}
            className="default-text divider"
            variant="h6"
          >
            {verifyHuman}
          </Text>
        </Toolbar>
      </AppBar>
      <Box className="send-wallet-recaptch">
        <img
          style={{ marginTop: "30px" }}
          src={paymentFailedImg}
          alt="recaptch-img"
        />
        <Text
          size={20}
          font="semibold"
          className="default-text"
          sx={{ mt: "10px" }}
        >
          {captch}
        </Text>
        <Text
          size={14}
          font="regular"
          variant="h6"
          className="grey-text"
          sx={{ mt: "10px", textAlign: "center", mb: "14px" }}
        >
          {captchaProblemMsg}
        </Text>
        <LoadCanvasTemplate />
        <Box width="600px" sx={{ padding: "0px 30px" }} mb="10px">
          <Input
            fullWidth
            value={captchaCode}
            label={captchaCodeLabel}
            onChange={(e) => {
              setCaptchaCode(e.target.value);
            }}
            type="text"
            inputProps={{ maxLength: 6 }}
            customClass="margin-top30"
            placeholder={captchaCodePlaceholder}
          />
          {captchaError && (
            <AlertMessage
              style={{ width: "540px" }}
              message={captchaError}
              severity="error"
              className="margin-top14"
            />
          )}
        </Box>
        <CustomDivider sx={{ width: "600px" }} />
        <Button
          label={proceedBtn.toUpperCase()}
          onClick={handleCaptchVerified}
          disabled={!captchaCode.length}
          type="submit"
          sx={{ width: "540px", m: "24px 0px" }}
        />
      </Box>
    </Dialog>
  );
};

export default RecaptchaModal;
