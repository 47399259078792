import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, TableCell, TableRow } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";

import { Tag } from "@speed/common/src/components/Tag/Tag";
import { emptySubText } from "@speed/common/src/components/messages";
import CustomTable from "@speed/common/src/components/Table/Table";
import Button from "@speed/common/src/components/Button/Button";
import {
  clipboardElement,
  showAmount,
} from "@speed/common/src/components/constants";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  instantSendStatus,
  ISWithdrawMethodsObj,
  noOfRecords,
} from "../constants";
import { noInstantSendSvg } from "../images";
import InstantSendFormModal from "./InstantSendFormModal";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { btc, instantSendsLabel, sats, sendInstantly } from "../messages";
import { setIsDataAvailable, setIsModalOpen } from "../../redux/common/actions";

const columns = [
  {
    title: "ID",
    width: "250px",
    align: "left",
    paddingLeft: "38px !important",
  },
  {
    title: "Type",
    width: "200px",
  },
  {
    title: "Currency",
    width: "100",
  },
  { title: "Amount", width: "180px", align: "right" },
  { title: "Send to", width: "250px" },
  { title: "Created on", width: "250px", align: "left" },
  { title: "Status", width: "100px", align: "left" },
];

const InstantSendList = ({ tab }) => {
  const dispatch = useDispatch();
  const queryParam = `?limit=${noOfRecords}&currency_denomination=higher`;
  const liveMode = useSelector((state) => state.auth.liveMode);
  const { history, isModalOpen } = useSelector((state) => state.common);
  const { isDetailinstantsendFe, isListinstantsendFe, isCreateinstantsendFe } =
    useFlags();

  const [instantSendData, setInstantSendData] = useState([]);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [loadMoreId, setLoadMoreId] = useState("");
  const [queryParams, setQueryParams] = useState(queryParam);
  const [disableCreateButton, setDisableCreateButton] = useState(true);

  const getInstantSendData = async (lines, params) => {
    const method = tab ? "POST" : "GET";
    const path = `${tab ? "/send/filter" : "/send"}${params}`;
    const data = tab
      ? {
          status: tab,
          limit: noOfRecords,
          ending_before: loadMoreId,
        }
      : {};
    try {
      setTableRowSkeleton(true);
      const res = await callAPIInterface(method, path, data);
      setTableRowSkeleton(false);
      if (res) {
        if (!res.has_more) {
          setHasMore(false);
        } else {
          const id = res.data?.[res.data?.length - 1]?.id;
          setQueryParams(queryParam + "&ending_before=" + id);
          setLoadMoreId(id);
        }
        dispatch(setIsDataAvailable(res.data.length > 0));
        setInstantSendData(lines.concat(res.data));
      }
    } catch (e) {
      setTableRowSkeleton(false);
    }
    setDisableCreateButton(false);
  };

  useEffect(() => {
    setInstantSendData([]);
    setHasMore(true);
    getInstantSendData([], queryParam);
  }, [liveMode]);

  const loadMore = useCallback(() => {
    getInstantSendData(instantSendData, queryParams);
  }, [instantSendData]);

  const columnsData = columns.map((column) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{ minWidth: column.width, paddingLeft: column.paddingLeft }}
    >
      {column.title}
    </TableCell>
  ));

  const rowsData =
    instantSendData?.length > 0 &&
    instantSendData.map((rowItem) => {
      const targetCurrency =
        rowItem.target_currency === sats ? btc : rowItem.target_currency;
      return (
        <TableRow
          key={rowItem.id}
          className={isDetailinstantsendFe ? "clickable" : ""}
          onClick={() => {
            isDetailinstantsendFe &&
              history.push(`/instant-sends/${rowItem.id}`);
          }}
        >
          <TableCell sx={{ textAlign: "left", pl: "38px !important" }}>
            {clipboardElement(
              rowItem.id,
              true,
              "instant-send-id-clipboard",
              "inputBox",
              false
            )}
          </TableCell>
          <TableCell>
            {ISWithdrawMethodsObj[rowItem?.withdraw_type]?.label}
          </TableCell>
          <TableCell>{targetCurrency}</TableCell>
          <TableCell
            style={{
              textAlign: "right",
              wordBreak: "break-word",
            }}
          >
            {showAmount({
              amount: rowItem.target_amount,
              currency: rowItem.target_currency,
              targetedCurrency: targetCurrency,
            })}
          </TableCell>
          <TableCell>
            {clipboardElement(
              rowItem.withdraw_request,
              true,
              "instant-send-to-clipboard",
              "inputBox",
              false
            )}
          </TableCell>
          <TableCell style={{ textAlign: "left" }}>
            {dateTimeFormatInApp(rowItem.created)}
          </TableCell>
          <TableCell>
            <Tag
              text={instantSendStatus[rowItem?.status].label}
              variant={instantSendStatus[rowItem?.status].variant}
            />
          </TableCell>
        </TableRow>
      );
    });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton,
    className: "payment-table",
    loadMore: loadMore,
    hasMore: hasMore,
    textOnNoData: emptySubText(liveMode, instantSendsLabel, tab),
    subTextOnNoData: "",
    noDataImage: noInstantSendSvg,
  };

  const handleModalClose = () => dispatch(setIsModalOpen(false));

  return (
    <>
      <Box className="action-btn-wrapper mui-fixed">
        {isCreateinstantsendFe && (
          <Button
            icon="arrowOutwardIcon"
            className="add-icon"
            style={{ marginRight: "16px" }}
            label={sendInstantly}
            variant="outlined"
            color="primary"
            onClick={() => dispatch(setIsModalOpen(true))}
            disabled={disableCreateButton}
          />
        )}
      </Box>
      <Box className="section-wrapper">
        <Box className="main-content">
          <Box className="payment-wrapper">
            {isListinstantsendFe ? (
              <CustomTable {...tableProps} />
            ) : (
              <AccessDenied />
            )}
          </Box>
        </Box>
      </Box>
      {isModalOpen && (
        <InstantSendFormModal
          isModalOpen={isModalOpen}
          handleModalClose={handleModalClose}
        />
      )}
    </>
  );
};

export default InstantSendList;
