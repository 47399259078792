import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Grid,
  TableCell,
  TableRow,
  InputAdornment,
  InputBase,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import moment from "moment";
import SearchIcon from "@mui/icons-material/Search";

import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import CustomButton from "@speed/common/src/components/Button/Button";
import CustomTable from "@speed/common/src/components/Table/Table";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import { utcTimestampForDashboard } from "@speed/common/src/components/constants";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import { Input } from "@speed/common/src/components/Input/Input";

import {
  emptyLogsSubText,
  date,
  method,
  searchByResourceId,
  apiEndpoint,
  clearAll,
  clear,
  apiEndpointPlaceholder,
  apply,
  loadMore,
} from "../messages";
import {
  callAPIInterface,
  getTimeZoneAndDateFormat,
  noOfRecords,
} from "../constants";
import "../../assets/styles/developers.scss";
import { emptyLog } from "../images";
import LogSkeleton from "./LogSkeleton";
import LogJson from "./LogJson";

const METHODS = [
  { id: "1", title: "GET" },
  { id: "2", title: "POST" },
  { id: "3", title: "DELETE" },
];

const DATES = [
  { id: "today", title: "Today" },
  { id: "7days", title: "Last 7 days" },
  { id: "14days", title: "Last 14 days" },
  { id: "30days", title: "Last 30 days" },
];

const LogsData = ({ tab, ...props }) => {
  const {
    startDateTimeStamp,
    setStartDateTimeStamp,
    endDateTimeStamp,
    setEndDateTimeStamp,
    checkedValues,
    setCheckedValues,
    apiEndpointData,
    setApiEndpointData,
    selectedDateItem,
    setSelectedDateItem,
  } = props;
  const liveMode = useSelector((state) => state.auth.liveMode);

  const queryParam = "?limit=" + noOfRecords;
  const momentObj = (time) => (time ? moment(time) : moment());

  const [hasMore, setHasMore] = useState(false);
  const [searchResourceID, setSearchResourceID] = useState("");
  const [selectedLog, setSelectedLog] = useState({});
  const [queryParams, setQueryParams] = useState(queryParam);

  const [anchorElDate, setAnchorElDate] = useState(null);
  const [anchorElFilterList, setAnchorElFilterList] = useState(null);
  const [anchorElApiEndpoint, setAnchorElApiEndpoint] = useState(null);
  const [apiLogData, setApiLogData] = useState(null);
  const [tableLoader, setTableLoader] = useState(true);
  const [isMethodClearClicked, setIsMethodClearClicked] = useState(false);
  const [apiEndPointClearClicked, setIsApiEndpointClearClicked] =
    useState(false);
  const [isClearAllClicked, setIsClearAllClicked] = useState(false);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [isLoadMore, setIsLoadMore] = useState(false);
  const [methodFilterApplied, setMethodFilterApplied] = useState(false);
  const [apiEndPointFilterApplied, setApiEndpointFilterApplied] =
    useState(false);
  const [methodChecked, setMethodChecked] = useState([]);

  useEffect(() => {
    if (apiEndpointData) {
      setApiEndpointFilterApplied(true);
    }
    if (checkedValues.length) {
      setMethodFilterApplied(true);
      setMethodChecked(checkedValues);
    }
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getLogData({}, queryParam);
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [searchResourceID, startDateTimeStamp, endDateTimeStamp, liveMode]);

  useEffect(() => {
    if (isMethodClearClicked || apiEndPointClearClicked || isClearAllClicked) {
      getLogData({}, queryParam);
    }
  }, [isMethodClearClicked, apiEndPointClearClicked, isClearAllClicked]);

  // Clear all
  const removeFilters = () => {
    setSearchResourceID("");
    setApiEndpointData("");
    setStartDateTimeStamp(null);
    setEndDateTimeStamp(null);
    setCheckedValues([]);
    setIsClearAllClicked(true);
    setSelectedDateItem("");
    setMethodFilterApplied(false);
    setApiEndpointFilterApplied(false);
  };

  const handleCheckboxChange = (title) => {
    if (methodChecked.includes(title)) {
      setMethodChecked((prevValues) =>
        prevValues.filter((value) => value !== title)
      );
    } else {
      setMethodChecked((prevValues) => [...prevValues, title]);
    }
  };

  const clearDateFilter = () => {
    setSelectedDateItem(null);
    setStartDateTimeStamp(null);
    setEndDateTimeStamp(null);
    setAnchorElDate(null);
  };

  const clearMethodFilter = () => {
    setCheckedValues([]);
    setMethodChecked([]);
    setIsMethodClearClicked(true);
    setAnchorElFilterList(null);
    setMethodFilterApplied(false);
  };

  const clearApiEndpointFilter = () => {
    setApiEndpointData("");
    setIsApiEndpointClearClicked(true);
    setAnchorElApiEndpoint(null);
    setApiEndpointFilterApplied(false);
  };

  const getDate = (type) => {
    const { timezone } = getTimeZoneAndDateFormat();
    const endDate = utcTimestampForDashboard({
      timezone,
    });

    let startDate;
    switch (type) {
      case "30days":
        startDate = utcTimestampForDashboard({
          timezone,
          isStartDay: true,
          days: 29,
          operation: "subtract",
        });
        break;
      case "14days":
        startDate = utcTimestampForDashboard({
          timezone,
          isStartDay: true,
          days: 13,
          operation: "subtract",
        });
        break;
      case "7days":
        startDate = utcTimestampForDashboard({
          timezone,
          isStartDay: true,
          days: 6,
          operation: "subtract",
        });
        break;
      case "today":
        startDate = utcTimestampForDashboard({
          timezone,
          isStartDay: true,
          days: 0,
          operation: "subtract",
        });
        break;
      default:
        break;
    }

    setStartDateTimeStamp(startDate);
    setEndDateTimeStamp(endDate);
    setAnchorElDate(null);
  };

  const getLogData = async (lines, params) => {
    setTableLoader(true);
    setSelectedLog({});

    const data = {};
    const tabFilter = {};
    if (tab !== "All") {
      tabFilter["success"] = tab === "success";
    }

    apiEndpointData && (data["resource_path"] = apiEndpointData);
    searchResourceID && (data["object"] = searchResourceID);
    methodChecked.length && (data["method"] = methodChecked);
    startDateTimeStamp && (data["start_date"] = startDateTimeStamp);
    endDateTimeStamp && (data["end_date"] = endDateTimeStamp);

    const filterApplied = Object.keys(data)?.length > 0;
    setIsFilterApplied(filterApplied);

    const filteredDataObject = { ...data, ...tabFilter };
    const isFilterApplied = Object.keys(filteredDataObject)?.length > 0;
    await callAPIInterface(
      `${filterApplied || isFilterApplied ? "POST" : "GET"}`,
      `${
        filterApplied || isFilterApplied ? "/api-logs/filters" : "/api-logs"
      }` + params,
      filterApplied || isFilterApplied ? filteredDataObject : ""
    )
      .then((res) => {
        if (res) {
          const resData = res?.data;
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setHasMore(true);
            setQueryParams(
              queryParam + "&ending_before=" + resData[resData.length - 1].id
            );
          }

          const formattedData = lines || {};
          resData?.forEach((entry) => {
            const timestampMs = entry?.request_time || "";
            const formattedDate = momentObj(timestampMs).format("DD/MM/YYYY");
            if (!formattedData[formattedDate]) {
              formattedData[formattedDate] = [entry];
            } else {
              formattedData[formattedDate].push(entry);
            }
          });

          setApiLogData(formattedData);
          setSelectedLog(resData?.[0]);
        }
      })
      .finally(() => {
        setTableLoader(false);
        setIsLoadMore(false);
        setIsMethodClearClicked(false);
        setIsApiEndpointClearClicked(false);
        setIsClearAllClicked(false);
      });
  };

  const methodTypeList = METHODS.map((item) => {
    return (
      <div key={item.id} style={{ padding: "6px 12px" }}>
        <CustomCheckbox
          label={item?.title}
          onChange={() => {
            handleCheckboxChange(item?.title);
          }}
          checked={methodChecked.includes(item?.title)}
        />
      </div>
    );
  });

  const handleDate = (item) => {
    getDate(item.id);
    setSelectedDateItem(item.id);
  };

  const dateList = DATES.map((item) => {
    return (
      <ListItem key={item.id}>
        <ListItemButton
          onClick={() => handleDate(item)}
          selected={selectedDateItem === item.id}
          className={selectedDateItem === item.id && "logs-filter-selected"}
        >
          <ListItemText primary={item.title} />
        </ListItemButton>
      </ListItem>
    );
  });

  const statusTag = (log) => {
    const errorCode = log?.response?.status_code >= 400;
    return (
      <Tag
        text={`${log?.response?.status_code} ${
          errorCode ? "ERR" : log?.response?.status
        }`}
        variant={errorCode ? "error" : "success-res"}
      />
    );
  };

  const rowsData = () => {
    let rs = [];

    apiLogData &&
      Object.entries(apiLogData).map(([key, values]) => {
        // Date Row
        rs.push(
          <TableRow key={key}>
            <TableCell colSpan="3" className="date-logs">
              {key}
            </TableCell>
          </TableRow>
        );

        // Log Rows
        values?.forEach((rowItem) => {
          rs.push(
            <TableRow
              key={rowItem?.id}
              className="clickable"
              style={{
                backgroundColor: selectedLog?.id === rowItem?.id && "#EFF6FF",
              }}
              onClick={() => setSelectedLog(rowItem)}
            >
              <TableCell sx={{ width: "50px" }}>{statusTag(rowItem)}</TableCell>
              <TableCell sx={{ width: "900px" }} className="truncate-api">
                {`${rowItem?.request?.method} ${rowItem?.request?.resource_path}`}
              </TableCell>
              <TableCell sx={{ width: "50px" }}>
                {momentObj(rowItem?.request_time).format("hh:mm A")}
              </TableCell>
            </TableRow>
          );
        });
      });

    tableLoader && rs.push(<LogSkeleton />);

    return rs;
  };

  const handleMethodFilterChecked = () => {
    if (methodFilterApplied) {
      setCheckedValues(checkedValues);
      setMethodChecked(checkedValues);
    } else {
      setCheckedValues([]);
      setMethodChecked([]);
    }
    setAnchorElFilterList(null);
  };

  return (
    <Box className="logs-container">
      <Box className="logs-filter-bar">
        <Box sx={{ display: "flex", flexGrow: 1, gap: "40px" }}>
          <InputBase
            value={searchResourceID}
            className="log-search-by-resource-id"
            placeholder={searchByResourceId}
            inputProps={{ "aria-label": searchByResourceId, maxLength: 50 }}
            onChange={(event) => setSearchResourceID(event.target.value)}
            startAdornment={
              <InputAdornment className="global-search-icon" position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
          <Box className="logs-filter">
            <CustomButton
              label={date}
              variant="default"
              icon="calenderIcon"
              className={`logs-filter-btn ${
                selectedDateItem && "logs-filter-selected"
              }`}
              iconPosition="end"
              onClick={(e) =>
                setAnchorElDate(anchorElDate ? null : e.currentTarget)
              }
            />
            <CustomPopper
              disablePortal={true}
              open={Boolean(anchorElDate)}
              anchorEl={anchorElDate}
              position="bottom-end"
              handleClose={() => setAnchorElDate(null)}
            >
              {dateList}
              <CustomDivider sx={{ margin: "10px 0" }} />
              <Box
                className="log-filter-btn-container"
                sx={{ padding: "6px 8px !important" }}
              >
                <CustomButton
                  label={clear}
                  className="clear-log-filter-btn clear-button-position"
                  variant="default"
                  onClick={() => {
                    clearDateFilter();
                  }}
                />
              </Box>
            </CustomPopper>
          </Box>

          <Box className="logs-filter">
            <CustomButton
              label={method}
              variant="default"
              icon="filterList"
              className={`logs-filter-btn ${
                methodFilterApplied && "logs-filter-selected"
              }`}
              iconPosition="end"
              onClick={(e) => {
                handleMethodFilterChecked();
                setAnchorElFilterList(
                  anchorElFilterList ? null : e.currentTarget
                );
              }}
            />
            <CustomPopper
              disablePortal={true}
              open={Boolean(anchorElFilterList)}
              anchorEl={anchorElFilterList}
              position="bottom-end"
              handleClose={() => handleMethodFilterChecked()}
            >
              {methodTypeList}
              <CustomDivider sx={{ margin: "10px 0" }} />
              <Box className="log-filter-btn-container">
                <CustomButton
                  label={apply}
                  variant="contained"
                  sx={{ padding: "8px 16px !important" }}
                  onClick={async () => {
                    if (methodChecked.length) {
                      setMethodFilterApplied(true);
                      setCheckedValues(methodChecked);
                    }
                    setAnchorElFilterList(null);
                    await getLogData({}, queryParam);
                  }}
                  disabled={!methodChecked?.length}
                />
                <CustomButton
                  className="clear-log-filter-btn"
                  label={clear}
                  variant="default"
                  onClick={() => clearMethodFilter()}
                />
              </Box>
            </CustomPopper>
          </Box>

          <Box className="logs-filter">
            <CustomButton
              label={apiEndpoint}
              variant="default"
              icon="filterList"
              className={`logs-filter-btn ${
                apiEndPointFilterApplied && "logs-filter-selected"
              }`}
              iconPosition="end"
              onClick={(e) =>
                setAnchorElApiEndpoint(
                  anchorElApiEndpoint ? null : e.currentTarget
                )
              }
            />
            <CustomPopper
              disablePortal={true}
              open={Boolean(anchorElApiEndpoint)}
              anchorEl={anchorElApiEndpoint}
              position="bottom-end"
              handleClose={() => setAnchorElApiEndpoint(null)}
            >
              <Input
                showLabel={false}
                fullWidth
                onChange={(e) => {
                  setApiEndpointData(e.target.value);
                }}
                value={apiEndpointData}
                type="text"
                placeholder={apiEndpointPlaceholder}
              />
              <CustomDivider sx={{ margin: "10px 0" }} />
              <Box className="log-filter-btn-container">
                <CustomButton
                  label={apply}
                  variant="contained"
                  sx={{ padding: "8px 16px !important" }}
                  onClick={async () => {
                    if (apiEndpointData.length) {
                      setApiEndpointFilterApplied(true);
                    }
                    setAnchorElApiEndpoint(null);
                    await getLogData({}, queryParam);
                  }}
                  disabled={!apiEndpointData?.length}
                />
                <CustomButton
                  label={clear}
                  className="clear-log-filter-btn"
                  variant="default"
                  onClick={() => {
                    clearApiEndpointFilter();
                  }}
                />
              </Box>
            </CustomPopper>
          </Box>
        </Box>
        {isFilterApplied && (
          <Box>
            <CustomButton
              className="log-clear-all-btn"
              label={clearAll}
              variant="default"
              onClick={removeFilters}
            />
          </Box>
        )}
      </Box>
      <Box className="log-container">
        <Grid container height="100%">
          <Grid item xs={selectedLog ? 6.49 : 12} height="100%">
            {tableLoader && !isLoadMore ? (
              <LogSkeleton />
            ) : (
              <CustomTable
                rows={rowsData()}
                hasMore={hasMore}
                textOnNoData={emptyLogsSubText}
                className="logs-table"
                noDataImage={emptyLog}
              />
            )}
            {hasMore && !tableLoader && (
              <CustomButton
                className="load-more-log-btn"
                icon="arrowDownIcon"
                iconPosition="start"
                label={loadMore}
                variant="default"
                onClick={() => {
                  setIsLoadMore(true);
                  getLogData(apiLogData, queryParams);
                }}
              />
            )}
          </Grid>
          {selectedLog && <CustomDivider flexItem orientation="vertical" />}
          {selectedLog && (
            <LogJson
              selectedLog={selectedLog}
              statusTag={statusTag}
              isLoading={tableLoader}
            />
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default LogsData;
