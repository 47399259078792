import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import {
  automaticPayout,
  automaticSetupSubText,
  onchain,
  setUpText,
} from "../../messages";
import AutoPayoutSetupModal from "./AutoPayoutSetupModal";
import {
  PSOnchainValidationSchema,
  PSValidationSchema,
  getScheduledPayout,
  payoutLoaderComponent,
  setPayoutSchDefaultVal,
  setupAutoPayoutSchedulingFunc,
} from "../../constants";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Text from "@speed/common/src/components/Text/Text";
import Button from "@speed/common/src/components/Button/Button";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import { useFlags } from "launchdarkly-react-client-sdk";
import CommonBoxWrapper from "./CommonBoxWrapper";
import AlreadySetupPayoutComponent from "./AlreadySetupPayoutComponent";

const PayoutScheduling = () => {
  const [openAutoPayoutModal, setOpenAutoPayoutModal] = useState(false);
  const [selectedPayout, setSelectedPayout] = useState(null);
  const [scheduledPayoutData, setScheduledPayoutData] = useState(null);
  const [isPayoutCreating, setIsPayoutCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [payoutScheduleId, setPayoutScheduleId] = useState(null);

  const liveMode = useSelector((state) => state.auth.liveMode);
  const { pschCreateFe } = useFlags();

  useEffect(() => {
    setScheduledPayoutData(null);
    getScheduledPayout({ setIsLoading, setScheduledPayoutData });
  }, [liveMode]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      auto_payout_enabled: "",
      payout_type: "",
      fees_type: "high",
      wallet_address: "",
      min_payout_amount: "",
      minimum_balance: "0",
      currency_type_payout: "BTC",
      currency_type_balance: "BTC",
    },
    validationSchema:
      selectedPayout === onchain
        ? PSOnchainValidationSchema
        : PSValidationSchema,
    onSubmit: (values) => {
      handleSetupAutoPayout(values);
    },
  });

  const { values, setFieldValue, resetForm, validateForm } = formik;

  useEffect(() => {
    validateForm();
  }, [values.payout_type]);

  useEffect(() => {
    if (scheduledPayoutData?.id) {
      setFieldValue("auto_payout_enabled", scheduledPayoutData?.status);
    }
  }, [scheduledPayoutData?.status]);

  useEffect(() => {
    setPayoutSchDefaultVal({
      setFieldValue,
      isEditing,
      setSelectedPayout,
      scheduledPayoutData,
    });
  }, [scheduledPayoutData, isEditing]);

  useEffect(() => {
    isDeactivating && handleSetupAutoPayout(values);
  }, [values.auto_payout_enabled]);

  const handleSetupAutoPayout = (values) => {
    setupAutoPayoutSchedulingFunc({
      values,
      isDeactivating,
      isEditing,
      setIsPayoutCreating,
      setScheduledPayoutData,
      payoutScheduleId,
      scheduledPayoutData,
      parentCallback: handleCloseAutoPayoutModal,
    });
  };

  const handleCloseAutoPayoutModal = () => {
    if (isEditing || isDeactivating) {
      setIsEditing(false);
      setIsDeactivating(false);
      setPayoutScheduleId(null);
    }
    setOpenAutoPayoutModal(false);
    setSelectedPayout(null);
    resetForm();
  };

  const setupAutoPayout = () => {
    return (
      !isLoading && (
        <Box display="flex" alignItems="center">
          <Button
            className="payout-button"
            variant="contained"
            label={setUpText}
            onClick={() => setOpenAutoPayoutModal(true)}
          />
        </Box>
      )
    );
  };

  const beforeSetupPayoutComponent = () => {
    return (
      <CommonBoxWrapper footer={pschCreateFe && setupAutoPayout()}>
        <Box>
          <Box mb="8px" display="flex" alignItems="center">
            <Text
              className="card-text"
              size={20}
              font="bold"
              align="left"
              style={{ paddingRight: "8px" }}
            >
              {automaticPayout}
            </Text>
          </Box>
          <Box display="flex" flexDirection="row">
            <TruncatedTextTooltip
              textProps={{
                className: "grey-text",
              }}
              textValue={automaticSetupSubText}
              cellWidth="300px"
            />
          </Box>
        </Box>
      </CommonBoxWrapper>
    );
  };

  const propsToPass = {
    scheduledPayoutData,
    isPayoutCreating,
    setIsDeactivating,
    setPayoutScheduleId,
    setSelectedPayout,
    formik,
    setIsEditing,
    setOpenAutoPayoutModal,
  };
  const displayPayout = scheduledPayoutData ? (
    <AlreadySetupPayoutComponent {...propsToPass} />
  ) : (
    beforeSetupPayoutComponent()
  );

  return (
    <Box className="auto-payout-wrapper setup-verify-payout-wallet">
      <CustomDivider />
      <Box className="box-container">
        {isLoading ? payoutLoaderComponent() : displayPayout}
      </Box>

      <AutoPayoutSetupModal
        openAutoPayoutModal={openAutoPayoutModal}
        setSelectedPayout={setSelectedPayout}
        formik={formik}
        isLoading={isPayoutCreating}
        handleCloseAutoPayoutModal={handleCloseAutoPayoutModal}
        selectedPayout={selectedPayout}
      />
    </Box>
  );
};

export default PayoutScheduling;
