import { Box, Skeleton, TextField } from "@mui/material";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import {
  addCashbackMsg,
  createNewCashback,
  selectCashbackLabel,
} from "../messages";
import {
  callAPIInterface,
  getCashbackSearchResults,
  getTypeOfCashback,
  noOfRecords,
} from "../constants";
import AddIcon from "@mui/icons-material/Add";
import { linkStatus } from "@speed/common/src/components/constants";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import Text from "@speed/common/src/components/Text/Text";

const ActiveCashbackList = ({ values, touched, setTouched, setFieldValue }) => {
  const queryParam = "?limit=" + noOfRecords;
  const [searchQuery, setSearchQuery] = useState("");
  const [cashbacks, setCashbacks] = useState([]);
  const [cashbacksFromResult, setCashbacksFromResult] = useState([]);
  const [hasMoreCashbackFromResult, setHasMoreCashbacksFromResult] =
    useState(true);
  const [cashbackSkeleton, setCashbackSkeleton] = useState(false);
  const [hasMoreCashbacks, setHasMoreCashbacks] = useState(true);
  const [queryParamsOfCashback, setQueryParamsOfCashback] =
    useState(queryParam);
  const [queryParamsOfCashbackForSearch, setQueryParamsOfCashbackForSearch] =
    useState(queryParam);
  const history = useSelector((state) => state.common.history);
  const liveMode = useSelector((state) => state.auth.liveMode);

  const hasMoreItem = searchQuery
    ? hasMoreCashbackFromResult
    : hasMoreCashbacks;

  const addNewCashback = () => (
    <Box
      sx={{
        alignItems: "center",
        padding: "5px 10px",
        display: "flex !important",
        color: "#2a67ff",
        ":hover": {
          color: "#2758d4 !important",
        },
      }}
      gap={1}
      className="pointer-cursor"
      onClick={() =>
        history.push({
          pathname: "/cashbacks",
          state: {
            openCreateCashbackModal: true,
          },
        })
      }
    >
      <AddIcon
        sx={{
          width: "16px",
          height: "16px",
          color: "inherit",
        }}
      />
      <Text
        className="default-text"
        size={16}
        variant="body1"
        sx={{
          color: "inherit !important",
        }}
        component="span"
      >
        {createNewCashback}
      </Text>
    </Box>
  );

  const getCashbacks = (lines, params) => {
    setCashbackSkeleton(true);
    callAPIInterface("POST", `/cashbacks/filter${params}`, {
      status: linkStatus.active.value,
    })
      .then((res) => {
        setCashbackSkeleton(false);
        if (!res.has_more) {
          setHasMoreCashbacks(false);
        } else {
          setQueryParamsOfCashback(
            queryParam + "&ending_before=" + res.data[res.data.length - 1].id
          );
        }
        setCashbacks(lines.concat(res.data));
      })
      .catch(() => {
        setCashbackSkeleton(false);
      });
  };

  const fetchCashbacks = () => {
    setCashbacks([]);
    setHasMoreCashbacks(true);
    getCashbacks([], queryParamsOfCashback);
  };

  const handleOnChangeCashback = () => {
    if (!cashbacks?.length) fetchCashbacks();
    setFieldValue("checkedCashback", !values.checkedCashback);
  };

  const cashbackSearchResults = (params) => {
    return getCashbackSearchResults(
      searchQuery,
      linkStatus.active.value,
      params
    );
  };

  const getCashbacksFromResult = (lines, params) => {
    setCashbackSkeleton(true);
    cashbackSearchResults(params)
      .then((res) => {
        setCashbackSkeleton(false);
        if (!res.has_more) {
          setHasMoreCashbacksFromResult(false);
        } else {
          setQueryParamsOfCashbackForSearch(
            queryParam + "&ending_before=" + res.data[res.data.length - 1].id
          );
        }
        setCashbacksFromResult(lines.concat(res.data));
      })
      .catch(() => {
        setCashbackSkeleton(false);
      });
  };

  const clearMemoizedCache = () => {
    getCashbackSearchResults?.cache?.clear();
  };

  const loadMoreCashbacks = useCallback(() => {
    getCashbacks(cashbacks, queryParamsOfCashback);
  }, [cashbacks]);

  const loadMoreCashbacksFromResult = useCallback(() => {
    getCashbacksFromResult(cashbacksFromResult, queryParamsOfCashbackForSearch);
  }, [cashbacksFromResult]);

  useEffect(() => {
    if (searchQuery) {
      setCashbackSkeleton(true);
      setCashbacksFromResult([]);
      setHasMoreCashbacksFromResult(true);
      setQueryParamsOfCashbackForSearch(queryParam);
      const apiDelayFunction = setTimeout(() => {
        // this function used to send Search API call after 500ms user typing
        getCashbacksFromResult([], queryParam);
      }, 500);
      return () => clearTimeout(apiDelayFunction);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (clearMemoizedCache) clearMemoizedCache();
  }, [liveMode]);

  return (
    <>
      <CustomCheckbox
        style={{
          marginLeft: "2px",
          marginBottom: "15px",
        }}
        checked={values.checkedCashback}
        label={addCashbackMsg}
        className="checkbox-label-default options"
        onChange={handleOnChangeCashback}
      />

      {values.checkedCashback && (
        <>
          <CustomAutoComplete
            sx={{ marginLeft: "40px" }}
            showLabel={false}
            options={searchQuery ? cashbacksFromResult : cashbacks}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            onChange={(_e, value) => {
              setFieldValue("cashback", value || {});
              setSearchQuery(value?.name || "");
            }}
            renderInput={(params) => (
              <>
                <TextField
                  {...params}
                  placeholder={selectCashbackLabel}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  onBlur={() => {
                    if (values?.cashback) setSearchQuery(values.cashback.name);
                    setTouched({ ...touched, cashback: true });
                  }}
                />
              </>
            )}
            noOptionsText={
              <>
                {addNewCashback()}
                {cashbackSkeleton && (
                  <Box marginY={1} padding={1} borderRadius={1}>
                    <Skeleton
                      animation="wave"
                      width="50%"
                      sx={{ bgcolor: "#eaeef1 !important" }}
                    />
                  </Box>
                )}
              </>
            }
            value={values.cashback}
            getOptionLabel={(option) => option.name ?? ""}
            renderOption={(optionProps, option, state) => {
              const index = state.index;
              const cashbackResults = searchQuery
                ? cashbacksFromResult
                : cashbacks;
              const typeOfCashback = getTypeOfCashback(option);
              return (
                <>
                  <Box padding="4px 6px">{!index && addNewCashback()}</Box>
                  <Box
                    {...optionProps}
                    key={option?.id}
                    sx={{
                      margin: "0px !important",
                      padding: "8px 5px !important",
                      display: "block !important",
                      backgroundColor: "#FFF !important",
                      maxHeight: "42px",
                    }}
                  >
                    <Box
                      className="pointer-cursor"
                      borderRadius={1}
                      sx={{
                        padding: "5px 15px",
                        ":hover": {
                          bgcolor: "#f1f4f6",
                        },
                      }}
                    >
                      <TruncatedTextTooltip
                        textValue={`${option.name} - (${typeOfCashback})`}
                        cellWidth="500px"
                        textProps={{
                          sx: {
                            fontFamily: "Inter-SemiBold",
                          },
                        }}
                      />
                      <Text className="default-text" size={16} variant="body1">
                        {`${option.name} - (${typeOfCashback})`}
                      </Text>
                    </Box>
                  </Box>
                  {cashbackSkeleton && index === cashbackResults.length - 1 && (
                    <Box
                      sx={{
                        margin: "10px 17px",
                        borderRadius: "4px",
                      }}
                    >
                      <Skeleton
                        animation="wave"
                        width="50%"
                        sx={{ bgcolor: "#eaeef1 !important" }}
                      />
                    </Box>
                  )}
                </>
              );
            }}
            ListboxProps={{
              role: "list-box",
              onScroll: (event) => {
                const listboxNode = event.currentTarget;
                if (
                  listboxNode.scrollTop + listboxNode.clientHeight >=
                  listboxNode.scrollHeight - 10
                ) {
                  if (hasMoreItem) {
                    searchQuery
                      ? loadMoreCashbacksFromResult()
                      : loadMoreCashbacks();
                  }
                }
              },
            }}
          />
        </>
      )}
    </>
  );
};

export default ActiveCashbackList;
