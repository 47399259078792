import React, { useState } from "react";
import { Box, Card, CardContent } from "@mui/material";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Button from "@speed/common/src/components/Button/Button";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import { loadingMsg } from "@speed/common/src/components/messages";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import Text from "@speed/common/src/components/Text/Text";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { poweredBy } from "@speed/common/src/messages";
import CommonHeader from "../Common/CommonHeader";
import { banxaLogo } from "../images";
import { callAPIInterface } from "../constants";
import {
  buyBitcoin,
  closeBanxaNote,
  confirmBtn,
  disclaimer,
  redirectingToProvider,
  sats,
} from "../messages";

const buyBitcoinRows = [
  {
    id: "buybtc_banxa",
    name: "Banxa",
    description: "Easy onboarding with KYC.",
    image: banxaLogo,
    keyForWalletAddress: "walletAddress",
    url: `${process.env.REACT_APP_BANXA_URL}/?backgroundColor=ffffff&textColor=0a193e&theme=light&primaryColor=2a67ff&secondaryColor=2758d4&coinType=BTC&returnUrl=${process.env.REACT_APP_URL}/wallet-transactions&fiatType=USD&fiatAmount=100`,
  },
];

const BuyBitcoin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState();

  const handleOnClick = async () => {
    setIsLoading(true);
    try {
      const data = {
        amount: 0,
        currency: sats,
        target_currency: sats,
        payment_methods: ["onchain"],
        ttl: 31535999,
        metadata: {
          provider: selectedProvider?.name,
        },
        statement_descriptor: "Purchased from Banxa",
      };

      const response = await callAPIInterface("POST", "/payments", data);
      response?.payment_method_options?.on_chain?.address &&
        window.open(
          `${selectedProvider?.url}&${selectedProvider?.keyForWalletAddress}=${response.payment_method_options.on_chain.address}`,
          "_blank"
        );
    } catch (error) {
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        setSelectedProvider();
      }, 0);
    }
  };

  const confirmationModalFooter = (
    <Button
      style={{ width: "100%" }}
      label={confirmBtn}
      onClick={() => {
        setOpenConfirmation(false);
        handleOnClick();
      }}
      variant="contained"
    />
  );

  const closeModalBody = (
    <>
      <Text font="regular" className="grey-text" size={14} variant="body2">
        {closeBanxaNote[0]}{" "}
        <CustomLink
          size="small"
          target="_blank"
          href="https://banxa.com/terms-of-use/"
        >
          {closeBanxaNote[1]}
        </CustomLink>{" "}
        {closeBanxaNote[2]}{" "}
        <CustomLink
          size="small"
          target="_blank"
          href="https://banxa.com/privacy-policy/"
        >
          {closeBanxaNote[3]}
        </CustomLink>
        {closeBanxaNote[4]}
      </Text>
      <Box sx={{ mt: "30px", display: "flex", alignItems: "center", gap: 1 }}>
        <Text
          sx={{ lineHeight: "140% !important" }}
          font="regular"
          className="grey-text"
          size={14}
          variant="body2"
        >
          {poweredBy} :
        </Text>
        <img
          src={banxaLogo}
          alt="Banxa-logo"
          style={{ width: "65px", height: "16px" }}
        />
      </Box>
    </>
  );

  return (
    <>
      <Box className="section-wrapper">
        <CommonHeader />
        <CustomDivider />
        <Box className="box-container buy-btc-container">
          {buyBitcoinRows.map((row) => (
            <Card key={row.id}>
              <CardContent sx={{ p: 2.5 }}>
                <img src={row.image} alt={`${row.name}-logo`} />
                <TruncatedTextTooltip
                  textProps={{
                    variant: "body1",
                    className: "grey-text",
                    font: "regular",
                    size: 16,
                  }}
                  boxProps={{
                    mt: 2,
                  }}
                  textValue={row.description}
                  cellWidth="300px"
                />
                <Button
                  label={buyBitcoin}
                  onClick={() => {
                    setSelectedProvider(row);
                    setOpenConfirmation(true);
                  }}
                  sx={{ mt: 3 }}
                />
              </CardContent>
            </Card>
          ))}
        </Box>
      </Box>
      <BackdropLoader
        open={isLoading}
        alt={loadingMsg}
        customClass="loading-in-auth"
        text={redirectingToProvider(selectedProvider?.name)}
      />
      <Modal
        maxWidth="xs"
        footer={confirmationModalFooter}
        body={closeModalBody}
        open={openConfirmation}
        title={disclaimer}
        handleClose={() => {
          setSelectedProvider();
          setOpenConfirmation(false);
        }}
      />
    </>
  );
};

export default BuyBitcoin;
