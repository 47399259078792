import Text from "@speed/common/src/components/Text/Text";
import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { Box } from "@mui/material";

const ShowHideTask = ({
  setShowHiddenTask,
  showHiddenTask,
  currentQuestionnaireData,
  setExpanded,
  handleScroll,
  scrollRef,
}) => {
  const expandIconStyle = {
    height: "30px",
    width: "30px",
    paddingRight: "8px",
    color: "#848B9E",
  };
  return (
    <Box
      marginTop="40px"
      className="show-previous-task"
      onClick={() => {
        setExpanded("");
        setShowHiddenTask(!showHiddenTask);
        handleScroll(scrollRef.current);
      }}
    >
      {showHiddenTask ? (
        <ExpandLessIcon style={expandIconStyle} />
      ) : (
        <ExpandMoreIcon style={expandIconStyle} />
      )}
      <Text size={16} font="semibold" variant="body1">
        {`${showHiddenTask ? "Hide" : "Show"} More Task (${
          currentQuestionnaireData?.length
        })`}
      </Text>
    </Box>
  );
};

export default ShowHideTask;
