import * as Types from "./types";

const initialState = {
  isLoggedIn: !!localStorage.getItem("site_auth"),
  user: null,
  accessToken: "",
  idToken: "",
  refreshToken: "",
  temp: null,
  liveMode: false,
  currentAccount: null,
  shouldLoaderShow: true,
  selectedConnectAccount: null,
  toastVariant: "success",
  toastMessage: "",
  toastAutoHideDuration: 4000,
  isToastOpen: false,
  connectPageData: null,
  connectLoading: false,
};

const authConnect = (state = initialState, action) => {
  switch (action.type) {
    case Types.SHOW_TOAST_MESSAGE:
      const { isToastOpen, toastMessage, toastVariant, toastAutoHideDuration } =
        action.payload;
      return {
        ...state,
        isToastOpen: isToastOpen,
        toastMessage: toastMessage,
        toastVariant: toastVariant,
        toastAutoHideDuration: toastAutoHideDuration || 4000,
        notificationClass: action.payload.className,
      };
    case Types.HIDE_TOAST_MESSAGE:
      return {
        ...state,
        isToastOpen: false,
      };
    case Types.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case Types.SET_INITIAL_DATA:
      const { access_token, id_token, refresh_token, livemode, current_acc } =
        action.payload;
      let initialData = {
        ...state,
        accessToken: access_token,
        idToken: id_token,
        refreshToken: refresh_token,
      };
      if (current_acc) {
        initialData.currentAccount = current_acc;
      }
      if (typeof livemode == "boolean") {
        initialData.liveMode = livemode;
      }
      return initialData;
    case Types.SET_USER:
      const { first_name, last_name } = action.payload;
      return {
        ...state,
        user: { name: first_name + " " + last_name, ...action.payload },
      };
    case Types.SET_CONNECT_PAGE_DATA:
      return {
        ...state,
        connectPageData: action.payload,
      };
    case Types.SET_CONNECT_PAGE_LOADING:
      return {
        ...state,
        connectLoading: action.payload,
      };
    case Types.SET_SHOULD_LOADER_SHOW:
      return {
        ...state,
        shouldLoaderShow: action.payload,
      };
    case Types.SET_SELECTED_ACCOUNT_ID:
      return {
        ...state,
        selectedConnectAccount: action.payload,
      };
    default:
      return { ...state };
  }
};

export default authConnect;
