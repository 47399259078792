import { Box, Grid } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  verificationEmailSentTitle,
  verificationEmailSentText,
  changeEmailText,
} from "../messages";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import { Input } from "@speed/common/src/components/Input/Input";
import Text from "@speed/common/src/components/Text/Text";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { useEffect } from "react";
import {
  invalidEmail,
  emailLabel,
  emailPlaceholder,
} from "@speed/common/src/components/messages";

function ChangeEmail({ isVerificationEmailSent, ...props }) {
  const validationSchema = yup.object({
    email: yup.string().required("").email(invalidEmail),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
  });

  const { values, setFieldValue, setTouched, errors, touched, isValid, dirty } =
    formik;

  useEffect(() => {
    if (isVerificationEmailSent) {
      setFieldValue("email", "");
    }
  }, [isVerificationEmailSent]);

  useEffect(() => {
    props.onDisabled(!(isValid && dirty));
  });

  const defaultErrorMessageProps = {
    touched,
    errors,
  };
  return isVerificationEmailSent ? (
    <Grid alignItems="center" display="flex" flexDirection="column">
      <CheckCircleIcon
        style={{ color: "#58c93f", height: "62px", width: "62px" }}
      />
      <Text
        variant="h5"
        font="semibold"
        size={16}
        className="default-text margin-top14"
      >
        {verificationEmailSentTitle}
      </Text>
      <Text
        align="center"
        variant="subtitle1"
        font="regular"
        size={14}
        className="grey-text margin-top14 margin-bottom12"
      >
        {verificationEmailSentText(
          <CustomLink
            size="small"
            bold={true}
            onClick={() => {
              props.onResend();
            }}
          >
            {" "}
            Resend{" "}
          </CustomLink>
        )}{" "}
      </Text>
    </Grid>
  ) : (
    <Box component="form" autoComplete="off">
      <Text variant="caption" font="regular" className="default-text" size={14}>
        {changeEmailText}
      </Text>
      <Input
        customClass="margin-top30"
        showLabel
        label={emailLabel}
        type="email"
        fullWidth
        name="email"
        value={values.email}
        error={touched.email && Boolean(errors.email)}
        onBlur={() => {
          setTouched({ ...touched, email: true });
          props.onChange(values.email, Boolean(errors.email));
        }}
        onChange={(e) => {
          setTouched({ ...touched, email: false });
          setFieldValue("email", e.target.value);
          props.onChange(e.target.value, Boolean(errors.email));
        }}
        placeholder={emailPlaceholder}
      />
      <InputErrorMessage {...defaultErrorMessageProps} inputName="email" />
    </Box>
  );
}

export default ChangeEmail;
