import { Box } from "@mui/material";
import LimitField from "./LimitField";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Button from "@speed/common/src/components/Button/Button";
import { save } from "@speed/common/src/messages";

function CommonBodySection({
  sendLimitData,
  selectedAsset,
  formik,
  loading,
  handleSubmit,
  disableBtn,
}) {
  return (
    <>
      <Box className="filter-body">
        <Box className="instant-send-container">
          {sendLimitData?.map(({ id, ...limitItem }) => {
            return (
              <LimitField
                {...limitItem}
                selectedAsset={selectedAsset}
                formik={formik}
                key={id}
              />
            );
          })}
        </Box>
      </Box>
      <CustomDivider />
      <Box className="filter-footer">
        <Button
          loading={loading}
          label={save}
          onClick={handleSubmit}
          type="submit"
          disabled={disableBtn}
        />
      </Box>
    </>
  );
}

export default CommonBodySection;
