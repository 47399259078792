import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { callAPIInterface, expireSession } from "../../constants";
import {
  invitation,
  invitationForDiffAccount,
  invitationExpired,
  invitationRedeem,
  invitationRevoke,
  invitationRedeemForDiffAccount,
  pending,
  acceptedByOther,
  login,
  register,
  invitee,
} from "../../messages";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import { loadingMsg, Wallet } from "@speed/common/src/components/messages";
import { showToast } from "../../../redux/common/actions";
import { redirectWhenEntityNotFound } from "@speed/common/src/components/constants";

const AcceptInvite = () => {
  const history = useSelector((state) => state.common.history);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { isLoggedIn, user, currentAccount } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    isLoggedIn ? user && invitationAccept() : invitationAccept();
  }, [user]);

  const handleSetQueryParam = (path, params) => {
    history.push({
      pathname: path,
      data: params,
    });
  };

  const dataParams = (flag, email, invitedByEmail, accountName, inviteeId) => {
    return {
      flag: flag,
      email: email,
      invited_by_email: invitedByEmail,
      account_name: accountName,
      invitee_id: inviteeId,
    };
  };
  const setInvitee = (
    invitationStatus,
    email,
    accountName,
    invitedByEmail,
    inviteeId
  ) => {
    localStorage.setItem(
      invitee,
      JSON.stringify({
        inviteeStatus: invitationStatus,
        inviteeEmail: email,
        inviteeAccountName: accountName,
        invitedByEmailId: invitedByEmail,
        inviteeId: inviteeId,
      })
    );
  };

  const setRoute = async (
    type,
    route,
    email,
    inviterEmail,
    accountName,
    inviteeId,
    userId,
    status
  ) => {
    let flag;
    switch (type) {
      case invitation:
        flag = user.id == userId ? invitation : invitationForDiffAccount;
        break;
      case login:
        flag = "";
        setInvitee(status, email, accountName, inviterEmail, inviteeId);
        break;
      case register:
        flag = invitation;
        isLoggedIn && user.id != userId && (await expireSession(false));
        break;
      case invitationRedeem:
        flag =
          user.id == userId ? invitationRedeem : invitationRedeemForDiffAccount;
        break;
      case acceptedByOther:
        flag = invitationRedeem;
        break;
      case invitationRevoke:
        flag = invitationRevoke;
        break;
      case invitationExpired:
        flag = invitationExpired;
        break;
    }
    const path = route;
    const allowedDashboardPath = ["my-assets", "dashboard"];
    allowedDashboardPath.includes(path) &&
      setInvitee(status, email, accountName, inviterEmail, inviteeId);
    const params = dataParams(
      flag,
      email,
      inviterEmail,
      accountName,
      inviteeId
    );
    handleSetQueryParam(path, params);
    setLoading(false);
  };

  const invitationAccept = async () => {
    const searchURL = history.location.search.replace("+", "%2B");
    const data = new URLSearchParams(searchURL);
    const inviteeId = data.get("invitee-id");
    const accountDashboard =
      currentAccount?.account?.account_type == Wallet
        ? "/my-assets"
        : "/dashboard";
    if (inviteeId) {
      setLoading(true);
      await callAPIInterface("GET", "/invite-users/" + inviteeId, "")
        .then((response) => {
          if (response) {
            const {
              status,
              is_user_with_email_exist,
              user_id,
              email,
              accepted_by,
            } = response;
            const inviterEmail = response?.invited_by?.email;
            const accountName = response?.account?.name;
            if (status === pending) {
              is_user_with_email_exist
                ? isLoggedIn
                  ? setRoute(
                      invitation,
                      accountDashboard,
                      email,
                      inviterEmail,
                      accountName,
                      inviteeId,
                      user_id,
                      status
                    )
                  : setRoute(
                      login,
                      "/login",
                      email,
                      inviterEmail,
                      accountName,
                      inviteeId,
                      "",
                      status
                    )
                : setRoute(
                    register,
                    "/register",
                    email,
                    inviterEmail,
                    accountName,
                    inviteeId,
                    "",
                    status
                  );
            } else {
              if (is_user_with_email_exist) {
                isLoggedIn &&
                  setRoute(
                    invitationRedeem,
                    accountDashboard,
                    "",
                    "",
                    accountName,
                    inviteeId,
                    user_id,
                    status
                  );
                ((accepted_by && accepted_by != user_id) || !isLoggedIn) &&
                  setRoute(
                    login,
                    "/login",
                    email,
                    inviterEmail,
                    accountName,
                    inviteeId,
                    "",
                    status
                  );
              } else {
                isLoggedIn &&
                  setRoute(
                    invitationRedeem,
                    accountDashboard,
                    email,
                    inviterEmail,
                    accountName,
                    inviteeId,
                    user_id,
                    status
                  );
                ((accepted_by && accepted_by != null) || !isLoggedIn) &&
                  setRoute(
                    acceptedByOther,
                    "/register",
                    "",
                    "",
                    "",
                    inviteeId,
                    "",
                    status
                  );
              }
            }
          }
        })
        .catch((errors) => {
          if (errors && errors.response) {
            errors.response.data.errors[0].message && isLoggedIn
              ? setRoute(
                  invitationExpired,
                  accountDashboard,
                  "",
                  "",
                  "",
                  inviteeId,
                  "",
                  ""
                )
              : setRoute(
                  invitationRevoke,
                  "/register",
                  "",
                  "",
                  "",
                  inviteeId,
                  "",
                  ""
                );
            redirectWhenEntityNotFound &&
              dispatch(
                showToast({
                  isToastOpen: false,
                })
              );
          }
        });
    }
  };

  return (
    <>
      <BackdropLoader
        open={loading}
        alt="Loading..."
        text={loadingMsg}
        customClass="loading-in-auth"
      />
    </>
  );
};

export default AcceptInvite;
