import React, { useEffect, useState } from "react";
import Text from "@speed/common/src/components/Text/Text";
import { Grid, Box } from "@mui/material";
import {
  checkEmailLine1Msg,
  checkEmailLine2Msg,
  checkEmailLine3Msg,
  checkEmailMsg,
  magicLinkSubMsg,
  resendForgotPwdLinkMsg,
} from "../messages";
import { checkEmail } from "../images";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { useDispatch, useSelector } from "react-redux";
import { showToast } from "../../redux/common/actions";
import { forgotPassword } from "../../redux/auth/actions";
import MainLogoLogin from "../Common/MainLogoLogin";
import { resendMsg } from "@speed/common/src/messages";

const EmailSent = ({ email }) => {
  const dispatch = useDispatch();
  const [isFromMagicLink, setIsFromMagicLink] = useState(false);
  const { history, isMobile } = useSelector((state) => state.common);

  useEffect(() => {
    history &&
      setIsFromMagicLink(
        history.location.pathname === "/magic-link" ? true : false
      );
  }, [history]);

  const handleResend = () => {
    dispatch(forgotPassword({ email })).then((res) => {
      if (res)
        dispatch(
          showToast({
            isToastOpen: true,
            toastMessage: resendForgotPwdLinkMsg,
            toastVariant: "success",
          })
        );
    });
  };
  const inviteEmail = (
    <Text size={16} variant={"caption"}>
      {email}
    </Text>
  );

  return (
    <>
      <MainLogoLogin />
      <Box className="container-layout">
        <Grid
          container
          className="common-grid-wrapper check-email-box common-mobile-grid signin-signup-container-layout"
          sx={{
            padding: "50px 25px 90px !important",
          }}
        >
          <img src={checkEmail} className="email-sent-icon" alt="check-email" />
          <Text
            size={isMobile ? 22 : 30}
            align="center"
            font="bold"
            className="default-text"
            sx={{ paddingTop: "45px", lineHeight: "140% !important" }}
          >
            {checkEmailMsg}
          </Text>
          {isFromMagicLink ? (
            <Text
              align="center"
              font="regular"
              size={isMobile ? 14 : 18}
              className="text"
              variant="caption"
            >
              {magicLinkSubMsg(inviteEmail)}
            </Text>
          ) : (
            <>
              <Text
                size={isMobile ? 14 : 18}
                font="regular"
                align="center"
                className="default-text text email-verify-text"
                variant="subtitle1"
              >
                {checkEmailLine1Msg}
              </Text>
              <Text
                size={isMobile ? 14 : 18}
                font="regular"
                align="center"
                className="default-text text email-verify-text"
                variant="subtitle1"
              >
                {checkEmailLine2Msg}
              </Text>
              <Text
                size={isMobile ? 14 : 18}
                font="semibold"
                align="center"
                className="default-text text"
                variant="subtitle1"
              >
                {checkEmailLine3Msg}
                <CustomLink bold size="large" onClick={handleResend}>
                  &nbsp;{resendMsg}
                </CustomLink>
              </Text>
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default EmailSent;
