import React from "react";
import "../../assets/styles/payment-links.scss";
import ModuleEventList from "../ModuleEventList";
import { useParams } from "react-router";

const InvoiceDetailsEvents = (props) => {
  const urlParams = useParams();
  return <ModuleEventList ldFlag={true} urlParams={urlParams} {...props} />;
};

export default InvoiceDetailsEvents;
