import React, { useState } from "react";
import { Box } from "@mui/material";
import { setupAutomaticPayout } from "../../messages";
import Button from "@speed/common/src/components/Button/Button";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import { save } from "@speed/common/src/messages";
import AutoPayoutSetupCommon from "./AutoPayoutSetupCommon";
import { renderNeedHelpWithContactUs } from "../../constants";

const AutoPayoutSetupModal = (props) => {
  const { openAutoPayoutModal, formik, isLoading, handleCloseAutoPayoutModal } =
    props;
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const { isValid, handleSubmit, dirty } = formik;

  const autoPayoutModalContent = () => {
    return (
      <Box className="auto-payout-modal-content">
        <Box className="margin-top30" width="552px">
          <AutoPayoutSetupCommon
            {...props}
            setButtonDisabled={setButtonDisabled}
          />
        </Box>
      </Box>
    );
  };

  const autoPayoutModalFooter = () => {
    return (
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Button
          label={save}
          loading={isLoading}
          onClick={handleSubmit}
          type="submit"
          disabled={!(isValid && dirty) || buttonDisabled}
        />
        {renderNeedHelpWithContactUs()}
      </Box>
    );
  };

  return (
    <Modal
      maxWidth="sm"
      body={autoPayoutModalContent()}
      footer={autoPayoutModalFooter()}
      className="api-popup"
      handleClose={() => {
        handleCloseAutoPayoutModal();
      }}
      open={openAutoPayoutModal}
      title={setupAutomaticPayout}
    />
  );
};

export default AutoPayoutSetupModal;
