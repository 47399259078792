import { Card } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import { errorIcon } from "../images";

export const recentPaymentTableHeader = [
  { title: "Amount(BTC)", width: "120px" },
  { title: "Received On", width: "180px" },
];

export const recentWithdrawalTableHeader = [
  { title: "Amount(BTC)", width: "120px" },
  { title: "Withdrawn On", width: "180px" },
];

export const recentPayoutTableHeader = [
  { title: "Amount(BTC)", width: "100px" },
  { title: "To Wallet Name", width: "120px" },
  { title: "Payout On", width: "190px" },
];

export const recentTransactionTableHeader = [
  { title: "Type", width: "150px" },
  { title: "Gross Amount(BTC)", width: "175px", align: "right" },
  { title: "Net Amount(BTC)", width: "175px", align: "right" },
  { title: "Transaction on", width: "235px", align: "right" },
];

export const ShowNoDataCard = (module, period = "") => (
  <Card className="no-data-card">
    <img src={errorIcon} alt="no-data" />
    <Text variant="h2" font="semibold" size={12} className="no-data-title">
      No Data
    </Text>
    <Text variant="h2" font="regular" size={14} className="no-data-subtitle">
      You don't have any {module} {period}
    </Text>
  </Card>
);
