import React, { useEffect, useMemo, useState } from "react";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { isEmpty } from "lodash";
import * as yup from "yup";
import {
  dailySendLimit,
  maxSendLimit,
  minSendLimit,
  successSendLimit,
} from "../../messages";
import { showToast } from "../../../redux/common/actions";
import {
  callAPIInterface,
  instantSendLimits,
  instantSendValidation,
} from "../../constants";
import { BTC } from "@speed/common/src/components/messages";
import {
  btcToSatsAmount,
  instantSendTypes,
  maxSat,
  minSat,
} from "@speed/common/src/components/constants";
import { currency } from "@speed/common/src/components/currency";
import CommonBodySection from "./CommonBodySection";

const BTCManageLimits = ({
  allTypeLimitData,
  limitsData,
  setLimitsData,
  selectedAsset,
}) => {
  const initialCurrency = { name: "Bitcoin", code: "BTC", symbol: "฿" };
  const [disableBtn, setDisableBtn] = useState(true);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getLimitAmount = (currencyCode, value = 0) => {
    return currencyCode === "BTC" ? btcToSatsAmount(value) : Number(value);
  };

  const transactionValidation = (amountValueInSats, parent) => {
    const lightningLimit = getLimitAmount(
      parent.lightningCurrency?.code,
      parent.lightningLimit
    );
    const onchainLimit = getLimitAmount(
      parent.onchainCurrency?.code,
      parent.onchainLimit
    );
    return amountValueInSats &&
      (lightningLimit || onchainLimit) &&
      amountValueInSats < lightningLimit + onchainLimit
      ? { isValid: false, errorMessage: dailySendLimit }
      : { isValid: true };
  };

  const limitValidation = (value, parent, type) => {
    const currency = parent[type];

    // First convert amount to SATS
    const amountValueInSats =
      currency?.code === BTC && value ? btcToSatsAmount(value) : Number(value);

    if (amountValueInSats < minSat) {
      return {
        isValid: false,
        errorMessage: minSendLimit,
      };
    } else if (amountValueInSats > maxSat) {
      return {
        isValid: false,
        errorMessage: maxSendLimit,
      };
    } else if (type === "transactionCurrency")
      return transactionValidation(amountValueInSats, parent);
    else return { isValid: true };
  };

  const validationSchema = yup.object({
    ...instantSendValidation({
      type: "lightningLimit",
      currency: "lightningCurrency",
      limitValidation,
    }),
    ...instantSendValidation({
      type: "onchainLimit",
      currency: "onchainLimit",
      limitValidation,
    }),
    ...instantSendValidation({
      type: "transactionLimit",
      currency: "transactionCurrency",
      limitValidation,
    }),
  });

  const getInitialData = (type) =>
    limitsData?.length > 0 && limitsData.find((item) => item.type === type);

  const getCurrency = (type) => {
    const currCode = getInitialData(type)?.currency;
    return currency.find((item) => item.code === currCode) || initialCurrency;
  };

  const formik = useFormik({
    initialValues: {
      lightningCurrency: getCurrency(instantSendTypes.lightning),
      onchainCurrency: getCurrency(instantSendTypes.onchain),
      transactionCurrency: getCurrency(instantSendTypes.daily),
      lightningLimit:
        getInitialData(instantSendTypes.lightning)?.amount?.toString() || "",
      onchainLimit:
        getInitialData(instantSendTypes.onchain)?.amount?.toString() || "",
      transactionLimit:
        getInitialData(instantSendTypes.daily)?.amount?.toString() || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { values, isValid, dirty, isSubmitting, resetForm } = formik;

  const handleSubmit = () => {
    setLoading(true);
    const payload = {
      SATS: [],
    };
    const { lightning, onchain, daily } = instantSendTypes;
    const generateObj = (type, curr, limitType) => {
      const id =
        limitsData?.length > 0 &&
        limitsData.find((item) => item.type === limitType)?.id;
      const createdObj = {
        id: id || "",
        amount: +values[type] || null,
        currency: values[curr]?.code,
        type: limitType,
      };
      !(!id && !values[type]) && payload.SATS.push(createdObj);
    };
    generateObj("lightningLimit", "lightningCurrency", lightning);
    generateObj("onchainLimit", "onchainCurrency", onchain);
    generateObj("transactionLimit", "transactionCurrency", daily);
    callAPIInterface("PUT", "/send/limits", payload)
      .then((res) => {
        setLoading(false);
        dispatch(
          showToast({
            isToastOpen: true,
            toastMessage: successSendLimit,
            toastVariant: "success",
          })
        );
        !isEmpty(res) && setLimitsData({ ...allTypeLimitData, SATS: res.SATS });
        resetForm();
      })
      .catch((_e) => setLoading(false));
  };

  const reformedLimitData = useMemo(() => {
    return (
      limitsData?.length > 0 &&
      limitsData.filter((item) => item.amount !== null)
    );
  }, [limitsData]);

  useEffect(() => {
    const { lightningLimit, onchainLimit, transactionLimit } = values;
    const whenAmountAdded =
      reformedLimitData.length === 0
        ? Boolean(transactionLimit || lightningLimit || onchainLimit)
        : true;
    const disableBtn = !whenAmountAdded || !(isValid && dirty) || isSubmitting;
    setDisableBtn(disableBtn);
  }, [isValid, dirty, isSubmitting, values]);

  const bodyProps = {
    sendLimitData: instantSendLimits["SATS"],
    selectedAsset,
    formik,
    loading,
    handleSubmit,
    disableBtn,
  };

  return <CommonBodySection {...bodyProps} />;
};

export default BTCManageLimits;
