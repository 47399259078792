import React, { forwardRef, useState } from "react";
import { AppBar, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Text from "@speed/common/src/components/Text/Text";
import { confirmTransfer, createTransfer } from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import { back, next } from "@speed/common/src/components/messages";
import TransferRequestDetails from "./TransferRequestDetails";
import TransferRequestSummary from "./TransferRequestSummary";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateTransferModal = (props) => {
  const {
    openModal,
    handleModalClose,
    goToNextPage,
    handleBackButton,
    handleNextButton,
    disableConfirm,
    isChecked,
    isValid,
    dirty,
    handleSubmit,
    touched,
    setTouched,
    values,
    setFieldValue,
    errors,
    defaultErrorMessageProps,
    balanceLoading,
    currentAccountId,
    setIsChecked,
    minimumAmount,
  } = props;

  const [exchangeRateValue, setExchangeRateValue] = useState();
  const [satsAmount, setSatsAmount] = useState(0);

  const transferRequestBody = () => {
    if (!goToNextPage) {
      const amountProps = {
        exchangeRateValue,
        setExchangeRateValue,
        satsAmount,
        setSatsAmount,
      };
      return (
        <TransferRequestDetails
          touched={touched}
          setTouched={setTouched}
          values={values}
          setFieldValue={setFieldValue}
          errors={errors}
          defaultErrorMessageProps={defaultErrorMessageProps}
          balanceLoading={balanceLoading}
          currentAccountId={currentAccountId}
          minimumAmount={minimumAmount}
          {...amountProps}
        />
      );
    } else {
      return (
        <TransferRequestSummary
          currentAccountId={currentAccountId}
          values={values}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
        />
      );
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={openModal}
      TransitionComponent={Transition}
      className="fullscreen-modal"
      onClose={handleModalClose}
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1 }}
            className="default-text divider"
            variant="h6"
          >
            {createTransfer}
          </Text>
          {goToNextPage ? (
            <>
              <Button
                variant="outlined"
                color="primary"
                label={back}
                onClick={handleBackButton}
                sx={{ marginRight: "18px" }}
                disabled={disableConfirm}
              />
              <Button
                label={confirmTransfer}
                variant="contained"
                color="primary"
                className="payment-link-btn"
                onClick={handleSubmit}
                disabled={!isChecked || disableConfirm}
              />
            </>
          ) : (
            <Button
              label={next}
              variant="contained"
              color="primary"
              className="payment-link-btn"
              onClick={handleNextButton}
              disabled={!(isValid && dirty)}
            />
          )}
        </Toolbar>
      </AppBar>
      {transferRequestBody()}
    </Dialog>
  );
};

export default CreateTransferModal;
