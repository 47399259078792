import { Box } from "@mui/material";
import * as yup from "yup";
import { Input } from "@speed/common/src/components/Input/Input";
import { invalidEmail } from "@speed/common/src/components/messages";
import {
  addAddressLabel,
  addMoreRecipientsText,
  billingEmailCheckboxLabel,
  billingEmailLabel,
  billingInfoFormLabel,
  emailCCLabel,
  enterEmailPlaceHolder,
  sameInfoAsBillingText,
  shippingInfoLabel,
} from "../messages";
import {
  getTrimmedString,
  keyEventListenerForMultiEmail,
  mobileNumberWithoutCallingCode,
  validatePhoneNumber,
  validatePostalCode,
} from "../constants";
import AddIcon from "@mui/icons-material/Add";
import { useFormik } from "formik";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import Text from "@speed/common/src/components/Text/Text";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import MultiEmailWithAlert from "../MultiEmailWithAlert";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import Label from "@speed/common/src/components/Label/Label";
import { removeAllEmptyFieldsFromNestedObj } from "@speed/common/src/components/constants";

const CustomerBillingShippingForm = forwardRef(
  (
    {
      infoTitleElement,
      commonAddressInfoSection,
      setIsoCode,
      handleButtonDisable,
      accountFormData,
      isNextStep,
      fromInvoiceModal = false,
    },
    ref
  ) => {
    const cusAccDetails = fromInvoiceModal
      ? accountFormData()
      : accountFormData;
    const [shippingMobileISOCode, setShippingMobileISOCode] = useState();
    const [billingCountryISOCode, setBillingCountryISOCode] = useState();
    const [shippingCountryISOCode, setShippingCountryISOCode] = useState();
    const [addMoreRecipients, setAddMoreRecipients] = useState(false);
    const [invalidEmailData, setInValidEmailData] = useState("");
    const [limitEmail, setLimitEmail] = useState(false);
    const [invalidEmailFlag, setInValidEmailFlag] = useState(false);
    const billingInfoRef = useRef();
    const shippingInfoRef = useRef();
    const validationSchema = yup.object({
      billing_email: yup.string().email(),
      billing_zipcode: yup.mixed().test({
        name: "billing_zipcode",
        test: (billing_zipcode) =>
          validatePostalCode(billing_zipcode, billingCountryISOCode),
      }),
      shipping_phone_number: yup.mixed().test({
        name: "shipping_phone_number",
        test: (phoneNumber) => validatePhoneNumber(phoneNumber),
      }),
      shipping_zipcode: yup.mixed().test({
        name: "shipping_zipcode",
        test: (shipping_zipcode) =>
          validatePostalCode(shipping_zipcode, shippingCountryISOCode),
      }),
    });

    const formik = useFormik({
      initialValues: {
        is_same_as_account_email: true,
        billing_email: "",
        billing_cc: [],
        billing_line1: "",
        billing_line2: "",
        billing_country: null,
        billing_state: "",
        billing_city: "",
        billing_zipcode: "",
        is_same_as_billing: true,
        shipping_name: "",
        shipping_calling_code: "91",
        shipping_phone_number: null,
        shipping_line1: "",
        shipping_line2: "",
        shipping_country: null,
        shipping_state: "",
        shipping_city: "",
        shipping_zipcode: "",
      },
      validationSchema: validationSchema,
      enableReinitialize: true,
    });
    const {
      values,
      errors,
      isValid,
      isSubmitting,
      setFieldValue,
      touched,
      setTouched,
    } = formik;

    const getObjectForResetting = (
      typeOfObj,
      resetAllShippingDetails = false
    ) => {
      if (!typeOfObj) return {};
      let resetObj = {
        [`${typeOfObj}_country`]: null,
        [`${typeOfObj}_line1`]: "",
        [`${typeOfObj}_line2`]: "",
        [`${typeOfObj}_state`]: "",
        [`${typeOfObj}_city`]: "",
        [`${typeOfObj}_zipcode`]: "",
      };

      if (resetAllShippingDetails) {
        resetObj = {
          ...resetObj,
          shipping_name: "",
          shipping_calling_code: "91",
          shipping_phone_number: null,
        };
      }
      return resetObj;
    };

    const resetObjectFormValues = (resetObjectFormValues) => {
      if (!resetObjectFormValues) return;
      const fieldsToResetArray = Object.entries(resetObjectFormValues);
      if (fieldsToResetArray?.length) {
        fieldsToResetArray.map(([field, value]) => setFieldValue(field, value));
      }
    };

    const resetEmailCCStates = async () => {
      await setFieldValue("billing_email", "");
      setFieldValue("billing_cc", []);
      setInValidEmailFlag("");
      setLimitEmail(false);
    };

    const scrollUpRefElement = (refElement) => {
      refElement?.current?.scrollIntoView({ behavior: "smooth" });
    };

    const multiInput = document.querySelector(
      ".add-more-recipients .react-multi-email input"
    );
    multiInput?.addEventListener("keyup", (e) => {
      keyEventListenerForMultiEmail(
        e,
        setInValidEmailData,
        setInValidEmailFlag,
        () => {},
        values.billing_cc
      );
    });

    useEffect(() => {
      if (isNextStep || fromInvoiceModal) {
        const disable =
          !isValid || isSubmitting || invalidEmailFlag || limitEmail;

        handleButtonDisable(disable);
      }
    }, [isNextStep, isValid, isSubmitting, invalidEmailFlag, limitEmail]);

    useEffect(() => {
      setIsoCode(values, "shipping_calling_code", setShippingMobileISOCode);
    }, [values.shipping_calling_code]);

    useEffect(() => {
      //automatically scroll up the billing address form section
      if (values?.billing_country) scrollUpRefElement(billingInfoRef);
    }, [values?.billing_country]);

    useEffect(() => {
      //automatically scroll up the billing address form section
      if (!values.is_same_as_billing || values?.shipping_country)
        scrollUpRefElement(shippingInfoRef);
    }, [values.is_same_as_billing, values?.shipping_country]);

    const getShippingFormDetails = (isSameAsBilling) => {
      return isSameAsBilling
        ? {
            name: cusAccDetails?.name,
            calling_code: cusAccDetails?.calling_code,
            phone: cusAccDetails?.phone,
            line1: values.billing_line1,
            line2: values.billing_line2,
            city: values.billing_city,
            state: values.billing_state,
            country: values.billing_country,
            zipcode: values.billing_zipcode,
          }
        : {
            name: values.shipping_name,
            calling_code: values.shipping_calling_code,
            phone: values.shipping_phone_number,
            line1: values.shipping_line1,
            line2: values.shipping_line2,
            city: values.shipping_city,
            state: values.shipping_state,
            country: values.shipping_country,
            zipcode: values.shipping_zipcode,
          };
    };

    const getBillingEmail = () => {
      return values?.is_same_as_account_email
        ? cusAccDetails?.email
        : values?.billing_email;
    };

    const createBillingShippingFormData = () => {
      const {
        is_same_as_billing,
        billing_cc,
        billing_line1,
        billing_line2,
        billing_city,
        billing_state,
        billing_country,
        billing_zipcode,
      } = values;
      const billingEmail = getBillingEmail();

      const shippingData = getShippingFormDetails(is_same_as_billing);

      let submittedformData = {
        billing: {
          email: billingEmail,
          email_cc: billing_cc,
          address: {
            line1: getTrimmedString(billing_line1),
            line2: getTrimmedString(billing_line2),
            city: getTrimmedString(billing_city),
            state: getTrimmedString(billing_state),
            country: billing_country,
            zipcode: getTrimmedString(billing_zipcode),
          },
        },
        shipping: {
          name: getTrimmedString(shippingData?.name),
          ...(shippingData?.phone && {
            calling_code: shippingData?.calling_code,
          }),
          phone: mobileNumberWithoutCallingCode(
            shippingData?.calling_code,
            shippingData?.phone
          ),
          address: {
            line1: getTrimmedString(shippingData?.line1),
            line2: getTrimmedString(shippingData?.line2),
            city: getTrimmedString(shippingData?.city),
            state: getTrimmedString(shippingData?.state),
            country: shippingData?.country,
            zipcode: getTrimmedString(shippingData?.zipcode),
          },
        },
      };

      const filteredObj = removeAllEmptyFieldsFromNestedObj(submittedformData);
      return filteredObj;
    };

    useImperativeHandle(ref, () => ({
      handleSubmit: createBillingShippingFormData,
    }));

    const customStyle = !fromInvoiceModal && {
      sx: { display: isNextStep ? "block" : "none" },
    };

    return (
      <Box autoComplete="off" {...customStyle}>
        <Box className="customer-form-box">
          {infoTitleElement(billingInfoFormLabel)}

          <Box className="customer-form-info">
            <Box className="margin-top30 customer-billing-email-section">
              <Text variant="h4" className="grey-text" size={14}>
                {billingEmailLabel}
              </Text>
              <CustomCheckbox
                checked={values.is_same_as_account_email}
                label={billingEmailCheckboxLabel}
                className="checkbox-label-default options"
                onChange={() => {
                  setFieldValue(
                    "is_same_as_account_email",
                    !values.is_same_as_account_email
                  );
                  if (!values.is_same_as_account_email) {
                    resetEmailCCStates();
                  }
                }}
              />

              {!values.is_same_as_account_email && (
                <>
                  <Input
                    customClass="margin-top10"
                    showLabel={false}
                    type="email"
                    fullWidth
                    inputProps={{ maxLength: 250 }}
                    name="billing_email"
                    value={values.billing_email}
                    onBlur={() =>
                      setTouched({ ...touched, billing_email: true })
                    }
                    onChange={(e) => {
                      setFieldValue("billing_email", e.target.value);
                    }}
                    placeholder={enterEmailPlaceHolder}
                  />
                  {touched.billing_email && Boolean(errors.billing_email) && (
                    <AlertMessage
                      className="margin-top14"
                      severity="error"
                      message={invalidEmail}
                    />
                  )}

                  <Box className="add-more-recipients">
                    {addMoreRecipients ? (
                      <>
                        <Label>{emailCCLabel}</Label>
                        <MultiEmailWithAlert
                          emails={values.billing_cc}
                          setEmails={(e) => setFieldValue("billing_cc", e)}
                          invalidEmailFlag={invalidEmailFlag}
                          setInValidEmailFlag={setInValidEmailFlag}
                          invalidEmailData={invalidEmailData}
                          setInValidEmailData={setInValidEmailData}
                          limitEmail={limitEmail}
                          setLimitEmail={setLimitEmail}
                          setDisabled={() => {}}
                          customEmailTagProps={{
                            enable: ({ emailCnt }) => emailCnt < 5,
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <CustomDivider />
                        <Box
                          className="add-btn pointer-cursor"
                          gap={1}
                          onClick={() => setAddMoreRecipients(true)}
                        >
                          <AddIcon />
                          <Text
                            className="default-text"
                            size={14}
                            variant="body1"
                            component="span"
                          >
                            {addMoreRecipientsText}
                          </Text>
                        </Box>
                      </>
                    )}
                  </Box>
                </>
              )}
            </Box>

            <Box className="margin-top30" ref={billingInfoRef}>
              {infoTitleElement(addAddressLabel, 16)}
            </Box>
            {commonAddressInfoSection({
              formik,
              line1: "billing_line1",
              line2: "billing_line2",
              country: "billing_country",
              setCountryIso: setBillingCountryISOCode,
              state: "billing_state",
              city: "billing_city",
              zipcode: "billing_zipcode",
              resetAddressFormDetails: () =>
                resetObjectFormValues(getObjectForResetting("billing")),
            })}
          </Box>
        </Box>

        <Box className="customer-form-box" ref={shippingInfoRef}>
          {infoTitleElement(shippingInfoLabel)}
          <CustomCheckbox
            checked={values.is_same_as_billing}
            label={sameInfoAsBillingText}
            className="checkbox-label-default options"
            onChange={() => {
              setFieldValue("is_same_as_billing", !values.is_same_as_billing);

              values.is_same_as_billing &&
                resetObjectFormValues(getObjectForResetting("shipping", true));
            }}
          />

          <Box className="customer-form-info">
            {!values.is_same_as_billing &&
              commonAddressInfoSection({
                formik,
                line1: "shipping_line1",
                line2: "shipping_line2",
                country: "shipping_country",
                setCountryIso: setShippingCountryISOCode,
                state: "shipping_state",
                city: "shipping_city",
                zipcode: "shipping_zipcode",
                isFromShipping: true,
                shippingMobileISOCode,
                resetAddressFormDetails: () =>
                  resetObjectFormValues(getObjectForResetting("shipping")),
              })}
          </Box>
        </Box>
      </Box>
    );
  }
);

export default CustomerBillingShippingForm;
