import React from "react";
import GetGuides from "./GetGuides";

const ProductsGuide = () => {
  return (
    <GetGuides
      moduleName="products"
      redirectUrl="/products"
      guideApiPath="product-guide"
      getStartedApiPath="Products"
      needHelpGuidePath="docs/receive-payments/products"
    />
  );
};

export default ProductsGuide;
