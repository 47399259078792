import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Redirect, useLocation } from "react-router-dom";
import { handleSessionSharingRedirection } from "../components/constants";
import { setIsLoggedIn } from "../redux/auth/actions";
import { Wallet } from "@speed/common/src/components/messages";
import { setPathname } from "../redux/common/actions";

const PublicRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const session = useSelector((state) => state.session);
  const { isLoggedIn, user, currentAccount } = useSelector(
    (state) => state.auth
  );

  const isLogin = () => {
    if (
      session.authenticated &&
      localStorage.getItem("site_auth") === "1" &&
      user
    ) {
      dispatch(setIsLoggedIn(true));
      return true;
    }
    if (isLoggedIn || session.authenticated) {
      return true;
    }
  };

  useEffect(() => {
    isLogin();
  }, []);

  const checkCloseWalletAccRoute = () => {
    const pathname = location?.state?.pathname || "";

    if (pathname.includes("/close-wallet-account")) {
      dispatch(setPathname(pathname));
    }
  };

  const getRedirectPathname = () => {
    const redirect =
      sessionStorage.getItem("redirect") ||
      handleSessionSharingRedirection(location.search);
    if (redirect) {
      return "/login-success";
    }
    if (currentAccount?.account?.account_type === Wallet) return "/my-assets";

    checkCloseWalletAccRoute();
    return "/dashboard";
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <Redirect
            to={{
              pathname: getRedirectPathname(),
              state: rest.location.search
                ? {
                    searchURL: rest.location.search,
                  }
                : {},
            }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

export default PublicRoute;
