import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import {
  RouteBreadcrumbs,
  callAPIInterface,
  renderStatus,
  updateMetadata,
  getCustomerChooseStatus,
  moduleMetaDataRows,
  renderPaymentUnconfirmed,
  receivedOnPaymentDetails,
  dateTimeFormatInApp,
} from "../constants";
import {
  clipboardElement,
  getCurrencyObj,
  redirectWhenEntityNotFound,
  linkStatus,
  generateBreadcrumbs,
  showAmount,
} from "@speed/common/src/components/constants";
import { Grid, TableCell, TableRow } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import {
  add,
  checkoutSummary,
  customField,
  dateCreated,
  edit,
  entityNotFoundMessage,
  events as eventsText,
  explorerLink,
  metaDataText,
  method,
  none,
  paymentDetails,
  receivedOnLabel,
  status,
  transfersText,
} from "../messages";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/styles/payments.scss";
import WarningIcon from "@mui/icons-material/Warning";
import { events } from "../Events/Events";
import { useFlags } from "launchdarkly-react-client-sdk";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import MetaDataForm from "../MetaDataForm";
import DetailPagePaymentSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import PaymentsSkeleton from "@speed/common/src/components/Skeleton/PaymentsSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";
import { showToast } from "../../redux/common/actions";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { KeyValueGrid } from "@speed/common/src/components/KeyValueGrid";

const PaymentsDetail = (props) => {
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const { liveMode, currentAccount } = useSelector((state) => state.auth);
  const history = useSelector((state) => state.common.history);
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [eventsList, setEventsList] = useState([]);
  const [transfers, setTransfers] = useState([]);
  const [metaDataList, setMetaDataList] = useState([]);
  const [eventSkeleton, setEventSkeleton] = useState(true);
  const [metaDataSkeleton, setMetaDataSkeleton] = useState(true);
  const [checkoutDataSkeleton, setCheckoutDataSkeleton] = useState(true);
  const [isMetaDataEditable, setIsMetaDataEditable] = useState(false);
  const [checkoutSummaryDetails, setCheckoutSummaryDetails] = useState(null);

  const { pyEventFe, pyMetadataFe, pyTransferFe, csGetcsfromapiFe } =
    useFlags();

  const urlParams = useParams();

  useEffect(() => {
    setData(null);
    setIsShowNotFound(false);
    getPaymentsDetail();
    csGetcsfromapiFe && getCheckoutSummaryData();
    pyEventFe && getEventsList();
  }, [liveMode, urlParams?.id, pyEventFe]);

  useEffect(() => {
    if (pyMetadataFe && data?.metadata) {
      const convertedMetaDataToArray = Object.entries(data?.metadata)?.map(
        (e) => ({
          key: e[0],
          value: e[1],
        })
      );
      setMetaDataList(convertedMetaDataToArray);
      setMetaDataSkeleton(false);
    }
  }, [pyMetadataFe, data?.metadata]);

  const getCheckoutSummaryData = async () => {
    setCheckoutDataSkeleton(true);
    await callAPIInterface("GET", `/checkout-sessions/payments/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setCheckoutSummaryDetails(res);
        }
      })
      .catch(() => {
        dispatch(
          showToast({
            isToastOpen: false,
            toastVariant: "error",
          })
        );
      })
      .finally(() => {
        setCheckoutDataSkeleton(false);
      });
  };

  const getEventsList = async () => {
    setEventSkeleton(true);
    const filterEvents = {
      ids: [urlParams?.id],
    };
    await callAPIInterface(
      "POST",
      "/events/filters",
      JSON.stringify(filterEvents)
    )
      .then((res) => {
        if (res) {
          setEventSkeleton(false);
          setEventsList(res?.data);
        }
      })
      .catch(() => {
        setEventSkeleton(false);
      });
  };

  const getPaymentsDetail = async () => {
    setShowSkeleton(true);
    setMetaDataSkeleton(true);
    await callAPIInterface("GET", `/payments/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setData(res);
          pyTransferFe &&
            res?.transfers?.length > 0 &&
            setTransfers(res.transfers);
        }
        setShowSkeleton(false);
      })
      .catch((err) => {
        setShowSkeleton(false);
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const getAmountType = () => {
    let amountType;
    if (checkoutSummaryDetails?.type === "preset") {
      amountType = "Customer Choose (Preset Amount)";
    } else if (checkoutSummaryDetails?.type === "options") {
      amountType = "Customer Choose (Options Amount)";
    } else if (
      checkoutSummaryDetails?.type === "fixed" &&
      checkoutSummaryDetails?.amount === 0
    ) {
      amountType = "Customer Choose (Any Amount)";
    } else {
      amountType = "You Choose (Fixed Amount)";
    }
    return amountType;
  };

  const getClipboardDataForBillingAndShippingAddress = (billingOrShipping) => {
    return `${checkoutSummaryDetails?.customer_collections?.[billingOrShipping]?.name}, ${checkoutSummaryDetails?.customer_collections?.[billingOrShipping]?.line1}, ${checkoutSummaryDetails?.customer_collections?.[billingOrShipping]?.country}, ${checkoutSummaryDetails?.customer_collections?.[billingOrShipping]?.city}, ${checkoutSummaryDetails?.customer_collections?.[billingOrShipping]?.state}, ${checkoutSummaryDetails?.customer_collections?.[billingOrShipping]?.postal_code}`;
  };

  const getBillingAndShippingAddressData = (billingOrShipping) => {
    return (
      <Box display="flex">
        <Text variant="h4" size={16} className="default-text" font="regular">
          {
            checkoutSummaryDetails?.customer_collections?.[billingOrShipping]
              ?.name
          }
          <br />
          {
            checkoutSummaryDetails?.customer_collections?.[billingOrShipping]
              ?.line1
          }
          {", "}
          <br />
          {
            checkoutSummaryDetails?.customer_collections?.[billingOrShipping]
              ?.country
          }
          {", "}
          {
            checkoutSummaryDetails?.customer_collections?.[billingOrShipping]
              ?.city
          }
          {", "}
          {
            checkoutSummaryDetails?.customer_collections?.[billingOrShipping]
              ?.state
          }
          {", "}
          {
            checkoutSummaryDetails?.customer_collections?.[billingOrShipping]
              ?.postal_code
          }
        </Text>
        {clipboardComponent(
          getClipboardDataForBillingAndShippingAddress(billingOrShipping)
        )}
      </Box>
    );
  };

  const clipboardComponent = (text) => {
    return (
      <Box onClick={(event) => event.stopPropagation()} display="flex" ml="6px">
        <Clipboard text={text} />
      </Box>
    );
  };

  const checkoutSummaryData = () => {
    const checkoutData = [];
    checkoutData.push({
      header: "Amount type",
      cell: (
        <Text variant="h4" size={16} className="default-text" font="regular">
          {getAmountType()}
        </Text>
      ),
    });
    if (checkoutSummaryDetails?.amount_paid) {
      checkoutData.push({
        header: "Checkout amount",
        cell: showAmount({
          amount: checkoutSummaryDetails.amount_paid,
          currency: checkoutSummaryDetails.currency,
          showCommaSeparated: true,
          appendCurrency: true,
        }),
      });
    }
    if (
      checkoutSummaryDetails?.customer_collections?.billing_address &&
      Object.keys(checkoutSummaryDetails?.customer_collections?.billing_address)
        .length
    ) {
      checkoutData.push({
        header: "Billing address",
        cell: getBillingAndShippingAddressData("billing_address"),
      });
    }
    if (
      checkoutSummaryDetails?.customer_collections?.shipping_address &&
      Object.keys(
        checkoutSummaryDetails?.customer_collections?.shipping_address
      ).length
    ) {
      checkoutData.push({
        header: "Shipping address",
        cell: getBillingAndShippingAddressData("shipping_address"),
      });
    }
    if (checkoutSummaryDetails?.customer_collections?.email) {
      checkoutData.push({
        header: "Email address",
        cell: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Text
              variant="h4"
              size={16}
              className="default-text"
              font="regular"
            >
              {checkoutSummaryDetails?.customer_collections?.email}
            </Text>
            {clipboardComponent(
              checkoutSummaryDetails?.customer_collections?.email
            )}
          </Box>
        ),
      });
    }
    if (checkoutSummaryDetails?.customer_collections?.phone) {
      checkoutData.push({
        header: "Phone number",
        cell: (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Text
              variant="h4"
              size={16}
              className="default-text"
              font="regular"
            >
              {checkoutSummaryDetails?.customer_collections?.phone}
            </Text>
            {clipboardComponent(
              checkoutSummaryDetails?.customer_collections?.phone
            )}
          </Box>
        ),
      });
    }
    if (checkoutSummaryDetails?.custom_fields?.length) {
      checkoutSummaryDetails.custom_fields?.forEach((field) => {
        checkoutData.push({
          header: (
            <>
              {field?.label}
              <br />
              {customField}
              {`${field?.is_optional ? " (optional)" : ""}`}
            </>
          ),
          cell: (
            <Text
              variant="h4"
              size={16}
              className="default-text"
              font="regular"
            >
              {field?.value || none}
            </Text>
          ),
        });
      });
    }
    return checkoutData;
  };

  const eventRows = eventsList?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/events/${rowItem?.id}`)}
      >
        <TableCell>{events(rowItem)}</TableCell>
        <TableCell align="right" sx={{ color: "#848b9e" }}>
          {dateTimeFormatInApp(rowItem?.created)}
        </TableCell>
      </TableRow>
    );
  });

  const headerContent = () => {
    const targetCurrency = data?.target_currency;
    const targetAmount =
      data.status === "paid" ? data.target_amount_paid : data.target_amount;

    const checkoutCurrency = checkoutSummaryDetails?.currency;
    const checkoutAmount =
      checkoutSummaryDetails?.payments?.find((p) => p.id === urlParams.id)
        ?.amount_paid || 0;

    const showFiatAmount =
      targetCurrency !== checkoutCurrency && checkoutAmount > 0;

    return (
      <Box className="header-content">
        <Text className="btc-amount" variant="h1" size={28}>
          {getCustomerChooseStatus(targetAmount, targetCurrency, true)}
        </Text>
        <Box className="header-price-content">
          <Text
            className="grey-text"
            size={16}
            font="regular"
            variant="subtitle"
          >
            {showFiatAmount && (
              <>
                ≈ {getCurrencyObj(checkoutSummaryDetails.currency)?.symbol}
                {showAmount({
                  amount: checkoutAmount,
                  currency: checkoutCurrency,
                  showCommaSeparated: true,
                })}
              </>
            )}
          </Text>
          <Box>
            {clipboardElement(data?.id, true, "payments-detail", "text", true)}
          </Box>
        </Box>
      </Box>
    );
  };

  const onSubmit = (payload) => {
    setMetaDataSkeleton(true);
    const finalPayload = { metadata: payload };
    updateMetadata(finalPayload, `/payments/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setData(res);
        }
        setMetaDataSkeleton(false);
        setIsMetaDataEditable(false);
      })
      .catch((err) => {
        setMetaDataSkeleton(false);
        setIsMetaDataEditable(false);
      });
  };

  const onCancel = () => setIsMetaDataEditable(false);

  const transferColumnData = [
    { title: "Transfer ID", width: "300px" },
    { title: "From Account", width: "200px" },
    { title: "To Account", width: "200px" },
    { title: "Transfer Amount", align: "right", width: "250px" },
  ];

  const transferRows = transfers?.map((rowItem) => {
    const targetCurrency = data?.target_currency || "SATS";
    return (
      <TableRow
        key={rowItem?.transfer_id}
        className="clickable"
        onClick={() => history.push(`/transfers/${rowItem?.transfer_id}`)}
      >
        <TableCell>{rowItem?.transfer_id}</TableCell>
        <TableCell>{currentAccount?.account?.id}</TableCell>
        <TableCell>{rowItem?.destination_account}</TableCell>
        <TableCell align="right">
          {getCurrencyObj(targetCurrency)?.symbol}
          {showAmount({
            amount: rowItem?.amount,
            currency: targetCurrency,
            showCommaSeparated: true,
          })}
        </TableCell>
      </TableRow>
    );
  });

  const { methodType, methodValue } = receivedOnPaymentDetails(data);

  const detailKeyValueData = [
    {
      key: status,
      value: (
        <Box display="flex" gap={2} alignItems="end">
          {renderStatus(data?.status)}
          {renderPaymentUnconfirmed(data)}
        </Box>
      ),
    },
    {
      key: dateCreated,
      value: dateTimeFormatInApp(data?.created),
    },
    {
      key: method,
      value: methodType,
    },
    {
      key: "Date modified",
      value: dateTimeFormatInApp(data?.modified),
    },
  ];

  if (data?.status === "paid") {
    detailKeyValueData.push({
      key: receivedOnLabel,
      value: clipboardElement(methodValue, true, "webhook-id-copy", "", true),
    });
  }

  if (data?.on_chain_tx_explorer_link) {
    detailKeyValueData.push({
      key: explorerLink,
      value: clipboardElement(
        data.on_chain_tx_explorer_link,
        true,
        "webhook-id-copy",
        "",
        true
      ),
    });
  }

  return !isShowNotFound ? (
    <Grid className={`payments-detail-wrapper`} container spacing={1}>
      <Box className="section-wrapper" sx={{ position: "relative" }}>
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        {data ? (
          <>
            {headerContent()}
            <Box className="details-content">
              <Text size={20} className="default-text">
                {paymentDetails}
              </Text>
            </Box>
            <CustomDivider sx={{ my: "12px" }} />
            <Box
              gap={2.75}
              display="flex"
              flexWrap="wrap"
              justifyContent="space-between"
            >
              {detailKeyValueData.map((detailData) => (
                <KeyValueGrid
                  key={detailData?.key}
                  keyName={detailData?.key}
                  value={detailData?.value}
                />
              ))}
            </Box>
          </>
        ) : (
          showSkeleton && <PaymentsSkeleton props={props} />
        )}
        {csGetcsfromapiFe && (
          <>
            {!checkoutDataSkeleton ? (
              checkoutSummaryDetails && (
                <Box className="details-content checkout-summary">
                  <VerticalTable
                    label={checkoutSummary}
                    rowData={checkoutSummaryData()}
                  />
                </Box>
              )
            ) : (
              <DetailPagePaymentSkeleton />
            )}
          </>
        )}
        {pyTransferFe && data?.status === linkStatus.paid.value && (
          <>
            {!showSkeleton ? (
              <Box className="product-content latest-payment-content">
                <HorizontalTable
                  tableBodyClassName="pointer-cursor"
                  label={transfersText}
                  columns={transferColumnData}
                  rows={transferRows}
                  rowsPerPage={3}
                  isColumnShown={transferRows?.length > 0}
                  handleViewAllClick={() =>
                    history.push(`/payments/${urlParams?.id}/payment-transfers`)
                  }
                  isShowButton={transferRows?.length > 3}
                />
              </Box>
            ) : (
              <DetailPagePaymentSkeleton />
            )}
          </>
        )}
        {pyMetadataFe && (
          <>
            {!metaDataSkeleton ? (
              <Box className="product-content meta-data-content">
                {isMetaDataEditable ? (
                  <MetaDataForm
                    metaDataList={metaDataList}
                    setIsMetaDataEditable={setIsMetaDataEditable}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                  />
                ) : (
                  <HorizontalTable
                    label={metaDataText}
                    rows={moduleMetaDataRows(metaDataList)}
                    rowsPerPage={51}
                    isColumnShown={moduleMetaDataRows(metaDataList)?.length > 0}
                    tableBodyClassName="border-none"
                    isShowButton={!isMetaDataEditable}
                    displayShowButton={true}
                    viewAllButtonLabel={metaDataList?.length > 0 ? edit : add}
                    viewAllButtonIcon="editIcon"
                    handleViewAllClick={() => setIsMetaDataEditable(true)}
                  />
                )}
              </Box>
            ) : (
              <DetailPagePaymentSkeleton />
            )}
          </>
        )}
        {pyEventFe && (
          <>
            {!eventSkeleton ? (
              <Box className="product-content">
                <HorizontalTable
                  label={eventsText}
                  isColumnShown={eventRows?.length > 0}
                  rows={eventRows}
                  rowsPerPage={3}
                  handleViewAllClick={() => {
                    history.push(`/payments/${urlParams?.id}/events`);
                  }}
                  isShowButton={eventRows?.length > 3}
                  tableBodyClassName="border-none"
                />
              </Box>
            ) : (
              <DetailPagePaymentSkeleton />
            )}
          </>
        )}
      </Box>
    </Grid>
  ) : (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage("Payment")}
        subTitle={`Payment ${urlParams.id} is not found.`}
        btnText={`View ${(liveMode && "live") || "test"} mode payments`}
      />
    </Box>
  );
};

export default PaymentsDetail;
