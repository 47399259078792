import history from "@speed/common/src/components/history";
import PageLoader from "@speed/common/src/components/PageLoader";
import { useEffect, useState } from "react";
import { sessionService } from "redux-react-session";
import { callAPIInterface } from "./constants";

const Documentation = (props) => {
  const [isLoading, setLoading] = useState(false);

  const getDocumentationSecretToken = (redirectDetails) => {
    callAPIInterface("GET", "/settings/documentation/auth-token")
      .then(async (res) => {
        const token = res.auth_token;
        if (token) {
          let currentSession = await sessionService.loadSession();
          localStorage.removeItem("documentation_details");
          await sessionService.saveSession(currentSession);
          window.location.href = `${process.env.REACT_APP_SPEED_API_URL}${redirectDetails.redirect}?auth_token=${token}`;
        }
      })
      .catch((e) => {
        // what should be done here if api gets failed? For now it will redirect to the dashboard.
        if (e.response?.status === 404) history.push("/dashboard");
      });
  };

  useEffect(() => {
    setLoading(true);
    const redirectDetails = props.history?.location?.state?.redirectDetails;
    redirectDetails
      ? getDocumentationSecretToken(redirectDetails)
      : history.push("/dashboard");
  }, []);

  return (
    <PageLoader open={isLoading} alt="loading..." customClass="page-loader" />
  );
};

export default Documentation;
