import React from "react";
import GetGuides from "./GetGuides";

const PaymentAddressGuide = () => {
  return (
    <GetGuides
      moduleName="payment_addresses"
      redirectUrl="/payment-addresses"
      guideApiPath="payment-address-guide"
      getStartedApiPath="Payment Address"
      needHelpGuidePath="docs/receive-payments/payment-addresses"
    />
  );
};

export default PaymentAddressGuide;
