import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import React from "react";
import {
  insufficientBalance,
  insufficientBalanceMessage,
} from "../../messages";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { useSelector } from "react-redux";
import { displayAmount, displayTaprootCurrency } from "../../constants";

const InsufficientBalanceErrorSection = ({
  switchCurrency,
  assetBalance,
  amount,
  isOnchainMethod,
  calculatedSpeedFee,
  networkFeeAmount,
  exchangeRate,
  decodedCurrency,
}) => {
  const fiatCurrency = JSON.parse(
    localStorage.getItem("wallet_default_currency")
  );
  const showBtcAmount = useSelector((state) => state.common.showBtcAmount);
  const totalToKeepInWallet =
    Number(calculatedSpeedFee) + Number(networkFeeAmount);
  const amountToKeepInWallet = showBtcAmount
    ? totalToKeepInWallet?.toFixed(8)
    : Math.floor(totalToKeepInWallet);

  return (
    <Box className="send-error-section">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box display="flex" alignItems="center">
          <ErrorOutlineIcon
            sx={{
              height: "20px",
              width: "20px",
              color: "#fd371e",
              mr: "2px",
            }}
          />
          <Text
            size={16}
            font="semibold"
            className="default-text"
            variant="h6"
            sx={{ textColor: "#FD371E" }}
          >
            {insufficientBalance}
          </Text>
        </Box>
        <Text
          size={16}
          font="semibold"
          className="default-text"
          variant="h6"
          sx={{ color: "#FD371E" }}
        >
          {switchCurrency && fiatCurrency?.symbol}
          {displayAmount(
            assetBalance,
            exchangeRate,
            fiatCurrency?.code,
            decodedCurrency,
            switchCurrency,
            showBtcAmount
          )}{" "}
          {!switchCurrency &&
            displayTaprootCurrency(
              assetBalance,
              decodedCurrency,
              showBtcAmount
            )}
        </Text>
      </Box>
      {!isOnchainMethod && amount < assetBalance && (
        <Text
          size={14}
          font="regular"
          className="default-text"
          variant="h6"
          sx={{ color: "#FD371E", mt: "8px", width: "380px" }}
        >
          {insufficientBalanceMessage(amountToKeepInWallet, showBtcAmount)}
        </Text>
      )}
    </Box>
  );
};

export default InsufficientBalanceErrorSection;
