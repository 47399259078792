const filterableData = {
  created_date: {
    title: "Created Date",
    criteria: [
      {
        label: "is",
        value: "is",
        type: "dropdown",
        dropdownValues: [
          {
            value: "Today",
          },
          {
            value: "Yesterday",
          },
          {
            value: "Last 7 days",
          },
          {
            value: "Last 15 Days",
          },
          {
            value: "Last 30 Days",
          },
          {
            value: "Last 60 Days",
          },
          {
            value: "Last 90 Days",
          },
        ],
      },
      {
        label: "is equal to",
        value: "is-equal-to",
        type: "date-picker",
      },
      {
        label: "is before",
        value: "is-before",
        type: "date-picker",
      },
      {
        label: "is after",
        value: "is-after",
        type: "date-picker",
      },
      {
        label: "is between",
        value: "is-between",
        type: "date-picker-range",
      },
    ],
  },
  status: {
    title: "Status",
    criteria: [
      {
        label: "is any of",
        value: "is-any-of",
        type: "dropdown",
        dropdownValues: [
          {
            value: "Active",
          },
          {
            value: "Expired",
          },
        ],
      },
      {
        label: "is none of",
        value: "is-none-of",
        type: "dropdown",
        dropdownValues: [
          {
            value: "Active",
          },
          {
            value: "Expired",
          },
        ],
      },
    ],
  },
  ip_address: {
    title: "IP Address",
    criteria: [
      {
        label: "contains",
        value: "contains",
        type: "number",
      },
      {
        label: "doesn't contain",
        value: "not-contains",
        type: "number",
      },
    ],
  },
  city: {
    title: "City",
    criteria: [
      {
        label: "contains",
        value: "contains",
        type: "normal-string",
      },
      {
        label: "doesn't contain",
        value: "not-contains",
        type: "normal-string",
      },
    ],
  },
  country: {
    title: "Country",
    criteria: [
      {
        label: "contains",
        value: "contains",
        type: "normal-string",
      },
      {
        label: "doesn't contain",
        value: "not-contains",
        type: "normal-string",
      },
    ],
  },
};

export default filterableData;
