import React from "react";
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const SimpleTable = ({ columns, rows, className }) => {
  return (
    <TableContainer component={Paper} className={className}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>{columns}</TableRow>
        </TableHead>

        <TableBody>{rows}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default SimpleTable;
