import React from "react";
import {
  autoGeneratePasswordText,
  emailPlaceholder,
  enterPassword,
  enterYourNamePlaceholder,
  passwordProtected,
  passwordProtectedInfo,
  sendViaEmailInfo,
  sendViaEmailLabel,
  setPassword,
  withdrawalLink,
  withdrawalLinkFormInfo,
} from "../messages";
import { email, name } from "@speed/common/src/components/messages";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import { Box } from "@mui/material";
import Info from "@mui/icons-material/Info";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import Text from "@speed/common/src/components/Text/Text";
import { generatePassword } from "../constants";
import { Input } from "@speed/common/src/components/Input/Input";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";

const WithdrawalForm = ({
  linkType,
  commonStyle,
  values,
  touched,
  setTouched,
  setFieldValue,
  errors,
  handleClick,
  handleClose,
  open,
  anchorEl,
  openEmailInfo,
  emailAnchorEl,
  isPasswordProtected,
  sendViaEmail,
  handleOnChange,
  handleChangePasswordProtected,
}) => {
  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const commonCheckBoxElement = ({ checked, label, type }) => (
    <CustomCheckbox
      style={{
        marginBottom: "15px",
        marginRight: "0px",
      }}
      checked={checked}
      label={label}
      className="checkbox-wrapper checkbox-label-default password-protected-checkbox"
      onChange={() => handleOnChange(type)}
    />
  );

  const commonInfoElement = ({ type }) => (
    <Info
      className="label-with-icon"
      onClick={(e) => handleClick(e, type)}
      style={{ height: 20, width: 20, color: "#848b9e" }}
    />
  );

  const commonPopperElement = ({ open, anchorEl, type, infoMsg }) => (
    <Box
      className="info-icon"
      sx={{ position: "absolute", top: "21px", left: "207px" }}
    >
      <CustomPopper
        disablePortal={true}
        open={open}
        anchorEl={anchorEl}
        position="bottom"
        handleClose={() => handleClose(type)}
        sx={{ minWidth: "212px" }}
      >
        <Text
          variant="inherit"
          font="regular"
          size={14}
          className="default-text"
        >
          {infoMsg}
        </Text>
      </CustomPopper>
    </Box>
  );

  return (
    linkType === withdrawalLink && (
      <>
        <Box className="margin-top30" sx={commonStyle}>
          {commonCheckBoxElement({
            checked: isPasswordProtected,
            label: passwordProtected,
            type: "password",
          })}
          {commonInfoElement({ type: "password" })}
          {commonPopperElement({
            open,
            anchorEl: anchorEl,
            type: "password",
            infoMsg: passwordProtectedInfo,
          })}

          {isPasswordProtected && (
            <Box position="relative" marginBottom="30px">
              <Text
                className="auto-generate-pass"
                size={14}
                font="semibold"
                align="right"
                onClick={() => setFieldValue("password", generatePassword())}
              >
                {autoGeneratePasswordText}
              </Text>
              <Box>
                <Input
                  fullWidth
                  customClass="password-protected"
                  position="top"
                  inputProps={{ minLength: 8, maxLength: 20 }}
                  type="password"
                  isPasswordVisible={true}
                  showLabel
                  label={setPassword}
                  value={values.password}
                  name="password"
                  autoFocus={isPasswordProtected}
                  onBlur={(_e) => setTouched({ ...touched, password: true })}
                  onChange={(e) => {
                    handleChangePasswordProtected(e.target.value);
                  }}
                  error={Boolean(errors.password)}
                  placeholder={enterPassword}
                />
              </Box>

              <InputErrorMessage
                {...defaultErrorMessageProps}
                inputName="password"
              />
            </Box>
          )}
          <Box sx={(commonStyle, { width: "480px" })}>
            <Text
              sx={{ color: "#E74C3C", marginRight: "5px" }}
              size={16}
              font="regular"
              variant="inline"
            >
              *
            </Text>
            <Text
              size={14}
              font="regular"
              className="grey-text"
              variant="inline"
            >
              {withdrawalLinkFormInfo}
            </Text>
          </Box>
        </Box>
        <Box className="margin-top36" sx={commonStyle}>
          {commonCheckBoxElement({
            checked: sendViaEmail,
            label: sendViaEmailLabel,
            type: "email",
          })}
          {commonInfoElement({ type: "email" })}
          {commonPopperElement({
            open: openEmailInfo,
            anchorEl: emailAnchorEl,
            type: "email",
            infoMsg: sendViaEmailInfo,
          })}
          {sendViaEmail && (
            <Box position="relative" marginBottom="30px">
              <Input
                customClass="margin-top15"
                type="email"
                showLabel
                label={email}
                inputProps={{ maxLength: 250 }}
                onBlur={() => setTouched({ ...touched, email: true })}
                onChange={(e) => {
                  setTouched({ ...touched, email: false });
                  setFieldValue("email", e.target.value);
                }}
                value={values.email}
                name="email"
                error={touched.email && Boolean(errors.email)}
                fullWidth
                placeholder={emailPlaceholder}
              />

              <InputErrorMessage
                {...defaultErrorMessageProps}
                inputName="email"
              />

              <Input
                customClass="margin-top15"
                showLabel
                value={values.name}
                name="name"
                label={name}
                type="text"
                fullWidth
                inputProps={{ maxLength: 250 }}
                placeholder={enterYourNamePlaceholder}
                onBlur={() => setTouched({ ...touched, name: true })}
                onChange={(e) => {
                  setTouched({ ...touched, name: false });
                  setFieldValue("name", e.target.value);
                }}
              />

              <InputErrorMessage
                {...defaultErrorMessageProps}
                inputName="name"
              />
            </Box>
          )}
        </Box>
      </>
    )
  );
};

export default WithdrawalForm;
