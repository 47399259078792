import React, { forwardRef } from "react";
import CommonLinkForm from "./CommonLinkForm";
import CheckoutLinkDisplay from "./CheckoutLinkDisplay";
import LinkModalContent from "../Common/LinkModalContent";

const CheckoutLinkModal = forwardRef((props, ref) => {
  const {
    handleButtonDisable,
    isPaymentLink,
    confirmRef,
    setDisableCreateButton,
  } = props;

  let rightSectionsProps = {
    confirmPayment: isPaymentLink,
  };
  const formComponent = (
    <CommonLinkForm
      handleButtonDisable={handleButtonDisable}
      isPaymentLink={isPaymentLink}
      ref={ref}
      linkType="checkout link"
    />
  );

  const previewComponent = <CheckoutLinkDisplay {...rightSectionsProps} />;

  return (
    <LinkModalContent
      formComponent={formComponent}
      previewComponent={previewComponent}
      linkConfirmationProps={{
        isPaymentLink: isPaymentLink,
        setDisableCreateButton: setDisableCreateButton,
        ref: confirmRef,
      }}
    />
  );
});

export default CheckoutLinkModal;
