import React, { forwardRef, useEffect, useState } from "react";
import { AppBar, Box, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";

import Button from "@speed/common/src/components/Button/Button";
import Text from "@speed/common/src/components/Text/Text";

import RestrictedKeyTypeList from "./RestrictedKeyTypeList";
import {
  create,
  createRestrictedKey,
  descriptionError,
  doneBtn,
  editRestrictedKey,
  keyNameError,
  selectOneResourceType,
} from "../messages";
import {
  setExecuteAPICall,
  setLoading,
  setOpenRestrictedKeyCreateModal,
} from "../../redux/common/actions";
import { callAPIInterface, setShowCrucialActionModal } from "../constants";
import history from "@speed/common/src/components/history";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateRestrictedKeyModal = ({
  isUpdating,
  setIsUpdating,
  restrictedKeyDetails,
  fromDetailPage,
  setWillCallApi,
}) => {
  const dispatch = useDispatch();
  const { executeAPICall, openRestrictedKeyCreateModal } = useSelector(
    (state) => state.common
  );
  const user = useSelector((state) => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (executeAPICall) {
      dispatch(setLoading(false));
      setShowCrucialActionModal(user, false);
      dispatch(setExecuteAPICall(false));
      handleCreateRestrictedKey();
    }
  }, [executeAPICall]);

  const handleSubmit = () => {
    // api call
    setShowCrucialActionModal(user, true);
  };

  const handleValidation = (values) => {
    const errors = {};
    if (!values?.keyNameValue) {
      errors.keyNameValue = keyNameError;
    }
    if (!values?.keyPermissions?.length) {
      errors.keyPermissions = selectOneResourceType;
    }
    if (!values?.description) {
      errors.description = descriptionError;
    }
    return errors;
  };

  const handleModalClose = () => {
    setIsUpdating(false);
    resetForm();
    dispatch(setOpenRestrictedKeyCreateModal(false));
  };

  const formik = useFormik({
    initialValues: {
      keyNameValue: restrictedKeyDetails?.name || "",
      description: restrictedKeyDetails?.description || "",
      keyPermissions: restrictedKeyDetails?.permissions || [],
    },
    enableReinitialize: true,
    validate: handleValidation,
    onSubmit: handleSubmit,
  });

  const {
    values,
    setFieldValue,
    resetForm,
    touched,
    errors,
    setTouched,
    isValid,
    dirty,
  } = formik;

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const handleCreateRestrictedKey = () => {
    const restrictedKeyPayload = {
      name: values.keyNameValue,
      description: values.description,
      permissions: values.keyPermissions,
    };
    setIsLoading(true);
    callAPIInterface(
      "POST",
      `${
        isUpdating
          ? "/api-key/restricted-key/" + restrictedKeyDetails?.id
          : "/api-key/restricted-key"
      }`,
      restrictedKeyPayload
    )
      .then((res) => {
        setIsLoading(false);
        handleModalClose();
        if (!fromDetailPage) {
          history.push(`/apikeys/restricted-keys/${res?.id}`);
        }
        fromDetailPage && setWillCallApi(true);
      })
      .catch((_e) => setIsLoading(false));
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={openRestrictedKeyCreateModal}
      TransitionComponent={Transition}
      className="fullscreen-modal"
      onClose={handleModalClose}
      sx={{ overflow: "unset" }}
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1 }}
            className="default-text divider"
            variant="h6"
          >
            {isUpdating ? editRestrictedKey : createRestrictedKey}
          </Text>
          <Button
            label={isUpdating ? doneBtn : create}
            variant="contained"
            color="primary"
            className="payment-link-btn"
            loading={isLoading}
            onClick={handleSubmit}
            disabled={!(isValid && dirty)}
          />
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          overflowX: "auto",
        }}
      >
        <RestrictedKeyTypeList
          setFieldValue={setFieldValue}
          touched={touched}
          setTouched={setTouched}
          errors={errors}
          defaultErrorMessageProps={defaultErrorMessageProps}
          values={values}
        />
      </Box>
    </Dialog>
  );
};

export default CreateRestrictedKeyModal;
