import PropTypes from "prop-types";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import "./timeline.scss";
import classNames from "classnames";

function TimelineComponent({ position, timelineData, className, ...props }) {
  const classes = classNames("timeline-wrapper", className);
  return (
    <Timeline position={position} {...props} className={classes}>
      {timelineData &&
        timelineData.length > 0 &&
        timelineData.map((step, index) => {
          const lastElem = index === timelineData.length - 1;
          return (
            <TimelineItem key={index}>
              <TimelineSeparator>
                <TimelineDot variant="outlined">{step.icon}</TimelineDot>
                {!lastElem && <TimelineConnector />}
              </TimelineSeparator>
              <TimelineContent>{step.content}</TimelineContent>
            </TimelineItem>
          );
        })}
    </Timeline>
  );
}

const propTypes = {
  position: PropTypes.oneOf(["right", "left", "alternate"]),
  timelineData: PropTypes.array.isRequired,
};

const defaultProps = {
  position: "right",
};

TimelineComponent.propTypes = propTypes;
TimelineComponent.defaultProps = defaultProps;

export default TimelineComponent;
