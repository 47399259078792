import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  TableCell,
  TableRow,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import {
  generateBreadcrumbs,
  getAmountByCurrency,
  getCurrencyObj,
} from "@speed/common/src/components/constants";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import CustomTable from "@speed/common/src/components/Table/Table";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { emptySubText } from "@speed/common/src/components/messages";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import ImageIcon from "@mui/icons-material/Image";
import { emptyProduct } from "../images";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import { viewPriceDetails, viewProductDetails } from "../messages";
import { RouteBreadcrumbs, callAPIInterface } from "../constants";

const InvoiceDetailProducts = (props) => {
  const urlParams = useParams();
  const inProductFe = true;
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const [productTableRowSkeleton, setProductTableRowSkeleton] = useState(true);
  const [productRows, setProductRows] = useState([]);
  const [productAnchorEl, setProductAnchorEl] = useState(null);

  const history = useSelector((state) => state.common.history);
  const { liveMode } = useSelector((state) => state.auth);

  const getAllProducts = () => {
    setProductTableRowSkeleton(true);
    callAPIInterface("GET", `/invoices/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setProductTableRowSkeleton(false);
          setProductRows(res);
        }
      })
      .catch(() => {
        setProductTableRowSkeleton(false);
      });
  };

  useEffect(() => {
    if (inProductFe) {
      setProductRows([]);
      getAllProducts();
    }
  }, [liveMode, inProductFe]);

  const productTableColumns = [
    { title: "Name", width: "400px" },
    { title: "Qty.", width: "40" },
    {
      title: "Unit Price",
      width: "150px",
    },
    { title: "Total", width: "150px" },
    { title: "Action", width: "50" },
  ];

  const columnsData = productTableColumns?.map((column) => (
    <TableCell
      key={column.title}
      sx={{ paddingLeft: column.paddingLeft, minWidth: column.width }}
    >
      {column.title}
    </TableCell>
  ));

  const commonActionButton = ({
    popperElement,
    onClickActionButton,
    handleClosePopper,
    actionData,
  }) => {
    const iconProps = {};
    if (onClickActionButton) {
      iconProps.onClick = onClickActionButton;
    }

    return (
      actionData?.length > 0 && (
        <>
          <MoreHorizIcon
            className="horizontal-dots-icon pointer-cursor"
            onClick={onClickActionButton}
          />
          <CustomPopper
            disablePortal={true}
            open={Boolean(popperElement)}
            anchorEl={popperElement}
            position="bottom"
            handleClose={handleClosePopper}
            sx={{ minWidth: "max-content" }}
          >
            <List>
              {actionData?.map((action) => (
                <ListItem key={actionData.actionName} disablePadding>
                  <ListItemButton onClick={action.onClickAction}>
                    <ListItemText primary={action.actionName} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </CustomPopper>
        </>
      )
    );
  };

  const loadMore = useCallback(() => {
    getAllProducts();
  }, [productRows]);

  let productTableRows = productRows?.invoice_line_items?.map((rowItem) => (
    <TableRow key={rowItem.id}>
      <TableCell>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          {rowItem?.product_images?.[0] ? (
            <img
              src={rowItem?.product_images?.[0]}
              width="40px"
              height="40px"
              style={{ marginRight: "15px" }}
            />
          ) : (
            <Box
              height="40px"
              width="40px"
              className="content-center"
              sx={{
                color: "#b8bfc7",
                marginRight: "15px",
                backgroundColor: "#f5f5f5",
              }}
            >
              <ImageIcon className="image-icon" />
            </Box>
          )}
          <TruncatedTextTooltip cellWidth="500px" textValue={rowItem.name} />
        </Box>
      </TableCell>
      <TableCell>{rowItem.quantity}</TableCell>
      <TableCell>
        {getCurrencyObj(productRows.currency)?.symbol}
        {getAmountByCurrency(rowItem.unit_amount, productRows.currency)}
      </TableCell>
      <TableCell>
        {getCurrencyObj(productRows.currency)?.symbol}
        {getAmountByCurrency(rowItem.row_total_amount, productRows.currency)}
      </TableCell>
      <TableCell>
        {commonActionButton({
          popperElement: productAnchorEl,
          onClickActionButton: (event) => {
            event.stopPropagation();
            setProductAnchorEl(productAnchorEl ? null : event.currentTarget);
          },
          handleClosePopper: () => setProductAnchorEl(null),
          ...(rowItem?.type === "line_item" && {
            actionData: [
              {
                actionName: viewPriceDetails,
                onClickAction: () => {
                  rowItem?.price_id &&
                    history.push(`/prices/${rowItem?.price_id}`);
                  setProductAnchorEl(null);
                },
              },
              {
                actionName: viewProductDetails,
                onClickAction: () => {
                  rowItem?.price_id &&
                    history.push(`/products/${rowItem?.product_id}`);
                  setProductAnchorEl(null);
                },
              },
            ],
          }),
        })}
      </TableCell>
    </TableRow>
  ));

  const tableProps = {
    columns: columnsData,
    rows: productTableRows || [],
    tableRowSkeleton: productTableRowSkeleton,
    loadMore: loadMore,
    className: "link-table",
    textOnNoData: emptySubText(liveMode, "products"),
    noDataImage: emptyProduct,
  };

  return (
    <Box className="section-wrapper">
      <Box className="payment-link-header-wrapper">
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      </Box>
      <CustomDivider />
      <Box className="payments-wrapper">
        <Box className="section-wrapper">
          <Box className="payment-wrapper">
            <Box className="main-content">
              {inProductFe ? <CustomTable {...tableProps} /> : <AccessDenied />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default InvoiceDetailProducts;
