import React from "react";
import { Box, Grid } from "@mui/material";
import { preview, previewSubMsg } from "../messages";
import {
  setIsModalOpen,
  setPaymentConfirmationOption,
  setPaymentRedirectWebPreview,
} from "../../redux/common/actions";
import { useDispatch, useSelector } from "react-redux";
import Text from "@speed/common/src/components/Text/Text";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import OneQRPreview from "./OneQRPreview";

const OneQRRightSidePreview = () => {
  const dispatch = useDispatch();

  const liveMode = useSelector((state) => state.auth.liveMode);
  const { brandingPageUI, history } = useSelector((state) => state.common);

  const globalStates = {
    brandingPageUI,
    liveMode,
  };

  return (
    <Box
      container
      className="apply-auto-scroll mobile-view-box one-qr-preview-wrapper"
      paddingRight="60px !important"
      paddingLeft="10px !important"
    >
      <Grid
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "830px",
        }}
        item
        lg={12}
      >
        <Box>
          <Text size={20} font="semibold" className="default-text">
            {preview}
          </Text>
          <Text
            size={14}
            font="regular"
            className="grey-text"
            variant="subtitle1"
            sx={{ marginTop: "10px" }}
          >
            {previewSubMsg[0]}
            <CustomLink
              size="small"
              bold
              onClick={() => {
                dispatch(setIsModalOpen(false));
                // dispatch(setPaymentRedirectWebPreview(false));
                // dispatch(setPaymentConfirmationOption("confirmation"));
                history.push("/settings/one-qr");
              }}
            >
              {previewSubMsg[1]}
            </CustomLink>
          </Text>
        </Box>
      </Grid>
      {/** one qr preview section */}
      <Grid container marginTop="31px">
        <Grid
          item
          className="mobile-view-branding"
          marginBottom={0}
          width="369px !important"
        >
          <OneQRPreview {...globalStates} height="545px" type="phone" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OneQRRightSidePreview;
