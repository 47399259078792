import { Box } from "@mui/system";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useBrandIcon,
  uploadBrandLogo,
  uploadBrandIcon,
  brandColor,
} from "../../messages";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  setBrandingPageUI,
  setLoading,
  setSettingFormCancel,
  setSettingFormChange,
  setSettingFormValid,
  updatePublicInfo,
  setBrandingView,
} from "../../../redux/common/actions";
import { MenuItem } from "@mui/material";
import CustomSelect from "@speed/common/src/components/Select/Select";
import {
  brandingIconSelectionArray,
  convertToSixDigitHex,
  fontFamilySection,
  labelElement,
  uploadColorSection,
  uploadSection,
} from "../../constants";
import { validateColor } from "@speed/common/src/components/constants";
import { sessionService } from "redux-react-session";
import { setInitialState } from "../../../redux/auth/actions";
import { ButtonToggle } from "@speed/common/src/components/ButtonToggle/ButtonToggle";
import LaptopIcon from "@mui/icons-material/Laptop";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

function UploadBrandingDetails() {
  const [iconLoader, setIconLoader] = useState(false);
  const [logoLoader, setLogoLoader] = useState(false);
  const [formData, setFormData] = useState({});

  const currentAccount = useSelector((state) => state.auth.currentAccount);
  const [brandingDetails, setBrandDetails] = useState(
    currentAccount?.account?.account_public_info?.branding
  );
  const {
    settingFormSubmit,
    settingFormCancel,
    brandingSelectedView,
    history,
  } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  const [intervalValue, setIntervalValue] = useState(false);
  // const [selectedSecondaryColor, setSecondaryColor] = useState();

  // const currentSecondaryColor = useRef();
  // currentSecondaryColor.current = selectedSecondaryColor;

  const primaryThemeColor = "#2250A1";
  const [brandUIPrimaryColors, setBrandUIPrimaryColors] = useState({
    selectedColor: "",
    savedColor: primaryThemeColor,
  });
  const currentBrandUIPrimaryColors = useRef();
  currentBrandUIPrimaryColors.current = brandUIPrimaryColors;
  // const secondaryThemeColor = "#f9df00";
  const content = [
    {
      value: "laptop",
      children: <LaptopIcon style={{ width: 24, height: 24 }} />,
    },
    {
      value: "phone",
      children: <PhoneAndroidIcon style={{ width: 24, height: 24 }} />,
    },
  ];
  const handleButtonToggle = (_e, value) => {
    if (value) {
      dispatch(setBrandingView(value));
    }
  };

  const validationSchema = yup.object({
    primary_color: yup.mixed().test({
      test: (primary_color) => validateColor(primary_color),
    }),
  });

  const formik = useFormik({
    initialValues: {
      icons:
        brandingDetails && brandingDetails.icons ? brandingDetails.icons : null,
      logos:
        brandingDetails && brandingDetails.logos ? brandingDetails.logos : null,
      use_logo_or_icon:
        brandingDetails && brandingDetails.use_logo_or_icon
          ? brandingDetails.use_logo_or_icon
          : "icon",
      primary_color:
        brandingDetails && brandingDetails.primary_color
          ? brandingDetails.primary_color
          : "#2250A1",
      // secondary_color: '#2a67ff',
      checkout_font_family:
        brandingDetails && brandingDetails.checkout_font_family
          ? brandingDetails.checkout_font_family
          : "system-ui",
      pay_via:
        brandingDetails && brandingDetails.pay_via
          ? brandingDetails.pay_via
          : "USA top wallets",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { values, setFieldValue, resetForm, isValid, dirty, isSubmitting } =
    formik;

  const updateBrandingDetails = async () => {
    if (formData.primary_color && formData.primary_color.length === 4) {
      let sixDigitHexColor = convertToSixDigitHex(formData.primary_color);
      formData.primary_color = sixDigitHexColor;
    }

    formData["use_logo_or_icon"] = values?.use_logo_or_icon || "icon";

    let postFormObj = {
      branding: formData,
    };
    if ("icons" in formData) {
      postFormObj.branding.icon = formData.icons
        ? formData.icons.original
        : null;
      delete postFormObj.branding.icons;
    }
    if ("logos" in formData) {
      postFormObj.branding.logo = formData.logos
        ? formData.logos.original
        : null;
      delete postFormObj.branding.logos;
    }

    await dispatch(updatePublicInfo(postFormObj, true)).then(
      async (updatedPublicInfoData) => {
        if (updatedPublicInfoData) {
          const updatedAccountDetails = {
            ...currentAccount,
            account: {
              ...currentAccount?.account,
              account_public_info: updatedPublicInfoData,
            },
          };
          sessionService.loadSession().then((newSession) => {
            dispatch(
              setInitialState({
                ...newSession,
                current_acc: updatedAccountDetails,
              })
            );
          });
        }
        setFormData({});
        dispatch(setLoading(false));
      }
    );
  };

  useEffect(() => {
    if (currentAccount?.account?.account_public_info?.branding) {
      setBrandDetails(currentAccount.account.account_public_info.branding);
    } else {
      setBrandDetails(values);
    }
  }, [currentAccount]);

  useEffect(() => {
    dispatch(setSettingFormValid(!(isValid && dirty) || isSubmitting));
  }, [isValid, dirty, isSubmitting]);

  useEffect(() => {
    dispatch(setSettingFormChange(dirty));
    validateColor(values.primary_color) && dispatch(setBrandingPageUI(values));
  }, [values]);

  useEffect(() => {
    // get values and call action to update this change into DB
    if (
      Object.keys(formData).length != 0 &&
      isValid &&
      dirty &&
      settingFormSubmit
    ) {
      updateBrandingDetails();
    }
  }, [settingFormSubmit]);

  const onChangeFormField = (fieldname, value) => {
    setFieldValue(fieldname, value);
    formData[fieldname] = value;
    setFormData(formData);
  };

  useEffect(() => {
    if (settingFormCancel) {
      setFormData({});
      resetForm();
      dispatch(setSettingFormCancel(false));
    }
  }, [settingFormCancel]);

  const UseBrandIconSection = (
    <Box className="upload-brand-details">
      {labelElement(useBrandIcon)}
      <CustomSelect
        className="select-branding-icon"
        name="use_logo_or_icon"
        value={values.use_logo_or_icon}
        MenuProps={{
          id: "branding-icon-select",
        }}
        onChange={(e) => {
          onChangeFormField("use_logo_or_icon", e.target.value);
        }}
      >
        {brandingIconSelectionArray.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </CustomSelect>
    </Box>
  );

  const toggleButtonPath = [
    "/settings/checkout-payment",
    "/settings/withdrawal-link",
    "/settings/invoice-payment-page",
  ];

  const getSelectedColorCallback = (brandingValues) => {
    const { checkForColor } = brandingValues;
    const colorObj = {
      ...values,
      primarySavedColor: currentBrandUIPrimaryColors?.current?.savedColor,
    };
    checkForColor && dispatch(setBrandingPageUI(colorObj));
  };

  return (
    <Box className="branding-upload-section">
      {uploadSection({
        id: "icon_upload",
        uploadType: "icons",
        isLoading: iconLoader,
        setLoader: setIconLoader,
        labelText: uploadBrandIcon,
        onChangeFormField,
        values,
      })}
      {uploadSection({
        id: "logo_upload",
        uploadType: "logos",
        isLoading: logoLoader,
        setLoader: setLogoLoader,
        labelText: uploadBrandLogo,
        onChangeFormField,
        values,
      })}
      {UseBrandIconSection}
      {uploadColorSection({
        label: brandColor,
        colorType: "primary_color",
        defaultThemeColor: primaryThemeColor,
        currentBrandUIColors: currentBrandUIPrimaryColors,
        setBrandUIColors: setBrandUIPrimaryColors,
        intervalValue,
        onChangeFormField,
        setIntervalValue,
        values,
        getSelectedColorCallback,
      })}
      {/* {uploadColorSection(accentColor, "secondary_color", secondaryThemeColor, currentSecondaryColor, setSecondaryColor)} */}

      {fontFamilySection({
        values,
        onChangeFormField,
        fieldName: "checkout_font_family",
      })}

      {toggleButtonPath.includes(history.location.pathname) && (
        <Box className="branding-button-toggle">
          <ButtonToggle
            content={content}
            value={brandingSelectedView}
            onChange={handleButtonToggle}
          />
        </Box>
      )}
    </Box>
  );
}

export default UploadBrandingDetails;
