import React, { useEffect, useState } from "react";
import SetupCommonHeader from "./SetupCommonHeader";
import {
  alreadyVerified,
  btc,
  linkNotFoundTitle,
  payoutWalletDescription,
  remove,
  removePayoutWallet,
  resendVerificationMail,
  sats,
  unverified,
  verifyMessage,
  verifyPW,
  verifyPWSubtext,
  walletVerficationEmailTitle,
  walletVerficationTitle,
} from "../../../messages";
import { Box, ListItem, ListItemButton } from "@mui/material";
import CommonCardWithSwitch from "../../../Common/CommonCardWithSwitch";
import {
  callAPIInterface,
  confirmationModalContent,
  confirmationModalFooter,
  emailVerificationContent,
  getWalletTypeName,
  linkNotFoundContent,
  noOfRecords,
  payoutLoaderComponent,
} from "../../../constants";
import Text from "@speed/common/src/components/Text/Text";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { setPayoutModalData } from "../../../../redux/common/actions";
import { loaderSpinner } from "@speed/common/src/components/images";

const SetupVerifyPayoutWallet = ({ handleNext }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(false);
  const [cardLoader, setCardLoader] = useState(false);
  const [walletsData, setWalletsData] = useState([]);
  const [resendFlag, setResendFlag] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [currentRow, setCurrentRow] = useState(null);
  const [isAlreadyVerified, setIsAlreadyVerified] = useState(false);
  const [linkNotFound, setLinkNotFound] = useState(false);
  const [resendLoader, setResendLoader] = useState({});

  const { payoutModalData, accountAsset } = useSelector(
    (state) => state.common
  );

  useEffect(() => {
    if (payoutModalData?.isVerified)
      setIsEmailVerified(payoutModalData.isVerified);
    if (payoutModalData?.showLinkExpiredModal)
      setLinkNotFound(payoutModalData.showLinkExpiredModal);
    if (payoutModalData?.alreadyVerified)
      setIsAlreadyVerified(payoutModalData.alreadyVerified);
  }, [payoutModalData]);

  const handleResendEmail = (id) => {
    setResendLoader({ [id]: true });
    callAPIInterface(
      "POST",
      `/payout-wallets/${id}/resend-email?from=onboarding`,
      {}
    )
      .then(() => {
        setResendFlag(true);
      })
      .finally(() => {
        setResendLoader({ [id]: false });
      });
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const getPayoutWallets = (params) => {
    setCardLoader(true);
    let method = "POST",
      data = { status: "unverified" },
      path = "/payout-wallets/filter";
    callAPIInterface(method, path + params, data)
      .then((res) => {
        setCardLoader(false);
        const dataWithIcon = res.data?.map((data) => ({
          ...data,
          icon: accountAsset?.find((asset) => asset?.uuid === data?.currency)
            ?.icon,
        }));
        setWalletsData(dataWithIcon);
      })
      .catch(() => {
        setCardLoader(false);
      });
  };

  useEffect(() => {
    const queryParam = "?limit=" + noOfRecords;
    getPayoutWallets(queryParam);
  }, []);

  const customPopperContent = (
    <ListItem disablePadding>
      <ListItemButton onClick={() => setOpenConfirmModal(true)}>
        <Text
          size={14}
          className="grey-text pointer-cursor list-item"
          variant="subtitle1"
          sx={{ color: "#E74C3C !important" }}
        >
          {remove}
        </Text>
      </ListItemButton>
    </ListItem>
  );

  const bottomLineComponent = (id) => {
    return (
      <Text
        size={14}
        variant="subtitle1"
        className="grey-text"
        font="regular"
        sx={{ p: "11px 24px", borderTop: "1px solid #E4E9EE" }}
        withIcon={resendLoader[id] && "start"}
      >
        {verifyMessage}
        {resendLoader[id] ? (
          <img
            src={loaderSpinner}
            alt="Loader"
            width={15}
            height={15}
            style={{ marginLeft: "5px" }}
          />
        ) : (
          <CustomLink
            size="small"
            bold={true}
            onClick={() => handleResendEmail(id)}
          >
            {" "}
            {resendVerificationMail}
          </CustomLink>
        )}
      </Text>
    );
  };

  const handleRemoveWallet = () => {
    setCardLoader(true);
    setOpenConfirmModal(false);
    callAPIInterface("DELETE", `/payout-wallets/${currentRow?.id}`, {})
      .then(() => {
        const payoutWalletsList = walletsData;
        const result = payoutWalletsList.filter((row) => {
          return row?.id !== currentRow?.id;
        });
        setWalletsData(result);
        setCardLoader(false);
      })
      .catch(() => {
        setCardLoader(false);
      });
  };

  const verifiedWalletCard =
    walletsData.length > 0 ? (
      walletsData.map((item) => {
        return (
          <Box mb="24px" key={item.id}>
            <CommonCardWithSwitch
              isSwitchVisible={false}
              anchorEl={anchorEl}
              bottomComponent={() => bottomLineComponent(item.id)}
              customPopperContent={customPopperContent}
              data={item}
              iconClick={(event) => {
                handleClick(event);
                setCurrentRow(item);
              }}
              isMoreIconVisible={item?.status === "unverified"}
              setAnchorEl={setAnchorEl}
              title={item?.currency === sats ? btc : item?.currency}
              walletName={item.name}
              walletType={getWalletTypeName(item?.type)}
              isLdFlagRequired={false}
              tagProps={{
                text: unverified,
                variant: "warning",
                icon: null,
              }}
              showLeftSideIcon={true}
              leftIcon={item?.icon}
            />
          </Box>
        );
      })
    ) : (
      <Text size={14} className="grey-text" variant="subtitle1" font="medium">
        {payoutWalletDescription}
      </Text>
    );

  return (
    <>
      <SetupCommonHeader header={verifyPW} subHeader={verifyPWSubtext} />
      <Box
        sx={{ mt: "50px" }}
        className="auto-payout-wrapper setup-verify-payout-wallet"
      >
        {cardLoader ? payoutLoaderComponent() : verifiedWalletCard}
      </Box>
      {(isEmailVerified || resendFlag) && (
        <Modal
          maxWidth="xs"
          body={emailVerificationContent(isEmailVerified)}
          handleClose={() => {
            if (isEmailVerified) {
              setIsEmailVerified(false);
              dispatch(setPayoutModalData(null));
              handleNext();
            } else {
              setResendFlag(false);
            }
          }}
          open={isEmailVerified || resendFlag}
          title={
            isEmailVerified
              ? walletVerficationTitle
              : walletVerficationEmailTitle
          }
        />
      )}
      {(isAlreadyVerified || linkNotFound) && (
        <Modal
          maxWidth="xs"
          body={linkNotFoundContent(isAlreadyVerified)}
          handleClose={() => {
            if (isAlreadyVerified) {
              setIsAlreadyVerified(false);
            } else {
              setLinkNotFound(false);
            }
            dispatch(setPayoutModalData(null));
            handleNext();
          }}
          open={isAlreadyVerified || linkNotFound}
          title={isAlreadyVerified ? alreadyVerified : linkNotFoundTitle}
        />
      )}
      <Modal
        maxWidth="xs"
        body={confirmationModalContent}
        footer={confirmationModalFooter(
          setOpenConfirmModal,
          handleRemoveWallet
        )}
        handleClose={() => setOpenConfirmModal(false)}
        open={openConfirmModal}
        title={removePayoutWallet}
      />
    </>
  );
};

export default SetupVerifyPayoutWallet;
