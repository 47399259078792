import React, { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import CustomTable from "@speed/common/src/components/Table/Table";
import {
  Box,
  Grid,
  TableRow,
  TableCell,
  ListItemText,
  ListItem,
  ListItemButton,
  List,
} from "@mui/material";
import {
  emptySubText,
  emptyInvitedSubText,
} from "@speed/common/src/components/messages";
import {
  remove,
  invited,
  removeInvitee,
  inviteSentLabel,
  invitationSuccess,
  resendInvitee,
  RemoveInvitedMemberMsg,
  confirmBtn,
} from "../../messages";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import classNames from "classnames";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import { callAPIInterface, noOfRecords } from "../../constants";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import Button from "@speed/common/src/components/Button/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Text from "@speed/common/src/components/Text/Text";
import { emptyInvite } from "@speed/common/src/components/images";
import { resendMsg } from "@speed/common/src/messages";

const InviteMembers = ({ rolesName, inviteUser, handleInviteUser }) => {
  const queryParam = "?limit=" + noOfRecords;
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [hasMore, setHasMore] = useState(true);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const user = useSelector((state) => state.auth.user);
  const [rows, setRows] = useState([]);
  const [deleteFlag, setDeleteFlag] = useState(false);
  const [resendFlag, setResendFlag] = useState(false);

  let columns = [
    { title: "Email address", width: "410px" },
    { title: "Role", width: "260px" },
    { title: "Status", width: "205px" },
    { title: "Actions", width: "120px", align: "center" },
  ];
  useEffect(() => {
    setHasMore(true);
    setRows([]);
    getInviteUsers([], queryParam);
    handleInviteUser();
  }, [inviteUser]);

  const handleClick = (event, rowData) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setCurrentRow(rowData);
  };
  let columnsData = columns.map((column, index) => (
    <TableCell key={index} align={column.align} sx={{ minWidth: column.width }}>
      {column.title}{" "}
    </TableCell>
  ));

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick(e, currentRow);
    }
  };

  const openPopper = Boolean(anchorEl);

  const loadMore = useCallback(() => {
    getInviteUsers(rows, queryParams);
  }, [rows]);

  const resendBody = (
    <Grid alignItems="center" display="flex" flexDirection="column">
      <CheckCircleIcon
        style={{ color: "#58c93f", height: "62px", width: "62px" }}
      />
      <Text
        variant="h5"
        font="semibold"
        size={16}
        className="default-text margin-top14"
      >
        {inviteSentLabel}
      </Text>
      <Text
        align="center"
        variant="subtitle1"
        font="regular"
        size={14}
        className="grey-text margin-top14 margin-bottom12"
      >
        {invitationSuccess(currentRow?.email)}
      </Text>
    </Grid>
  );

  const resendInvitation = () => {
    callAPIInterface(
      "POST",
      `/invite-users/${currentRow?.id}/resend-email`,
      {}
    ).then(() => {
      setResendFlag(true);
    });
  };

  const getInviteUsers = async (lines, params) => {
    setTableRowSkeleton(true);
    const response = await callAPIInterface("GET", "/invite-users" + params);
    if (response) {
      setTableRowSkeleton(false);
      if (!response.has_more) {
        setHasMore(false);
      } else {
        setQueryParams(
          queryParam +
            "&ending_before=" +
            response.data[response.data.length - 1].id
        );
      }
      setRows(lines.concat(response.data));
    }
  };

  const removeItem = () => {
    callAPIInterface("DELETE", `/invite-users/${currentRow?.id}`, {}).then(
      () => {
        setAnchorEl(null);
        setDeleteFlag(false);
        setRows([]);
        getInviteUsers([], queryParam);
      }
    );
  };

  let rowsData =
    rolesName &&
    rows &&
    rows.map((rowItem) => {
      return (
        <TableRow key={rowItem.id}>
          <TableCell>
            <Box className="flex-inline">
              <Box sx={{ paddingRight: "15px", margin: "2.5px 0px" }}>
                {rowItem.email}
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            <Box className="flex-inline">
              <Box sx={{ paddingRight: "15px", margin: "2.5px 0px" }}>
                {rolesName[rowItem.role]}
              </Box>
            </Box>
          </TableCell>
          <TableCell>
            {" "}
            {rowItem.status.toLowerCase() === "pending" && (
              <Tag text={invited} variant="warning" />
            )}
          </TableCell>
          {rowItem.teamMember === user.name ? (
            <TableCell sx={{ textAlign: "center" }}></TableCell>
          ) : (
            <TableCell sx={{ textAlign: "center" }}>
              <MoreHorizIcon
                className="horizontal-dots-icon pointer-cursor"
                tabIndex={0}
                onKeyDown={handleKeyDown}
                onClick={(event) => {
                  event.stopPropagation();
                  handleClick(event, rowItem);
                }}
              />
            </TableCell>
          )}
        </TableRow>
      );
    });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    className: "link-table",
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
    textOnNoData: emptySubText(true, "", "Invites Pending"),
    subTextOnNoData: emptyInvitedSubText,
    noDataImage: emptyInvite,
  };

  return (
    <Box className="payment-links-wrapper">
      <Box className="main-content">
        <CustomTable {...tableProps} />
      </Box>
      <CustomPopper
        disablePortal={true}
        open={openPopper}
        anchorEl={anchorEl}
        position="bottom"
        handleClose={() => setAnchorEl(null)}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary={resendMsg} onClick={resendInvitation} />
            </ListItemButton>
          </ListItem>

          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText
                primary={remove}
                className={classNames("text-danger")}
                onClick={() => setDeleteFlag(true)}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </CustomPopper>
      <Modal
        maxWidth="xs"
        body={
          currentRow?.status?.toLowerCase() === "pending" &&
          RemoveInvitedMemberMsg(currentRow?.email || "")
        }
        footer={
          <Button
            label={confirmBtn}
            color="error"
            onClick={() => removeItem()}
          />
        }
        handleClose={() => setDeleteFlag(false)}
        open={deleteFlag}
        title={removeInvitee}
      />
      <Modal
        maxWidth="xs"
        body={resendBody}
        handleClose={() => setResendFlag(false)}
        open={resendFlag}
        title={resendInvitee}
      />
    </Box>
  );
};

export default InviteMembers;
