import React from "react";
import { Box, MenuItem, Skeleton } from "@mui/material";
import CustomSelect from "@speed/common/src/components/Select/Select";
import { Input } from "@speed/common/src/components/Input/Input";
import { RadioButton } from "@speed/common/src/components/RadioButton/RadioButton";
import Text from "@speed/common/src/components/Text/Text";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import {
  confirmationMessageLabel,
  domain,
  websiteURLError,
  enterYourUsername,
  messagePlaceholder,
  noteForAddingSuccessUrlInPaymentAddress,
  paymentDomainPlaceholder,
  successURL,
  username,
  websiteLabel,
} from "../messages";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";

const PaymentAddressRequestDetails = (props) => {
  const {
    values,
    setFieldValue,
    touched,
    setTouched,
    errors,
    defaultErrorMessageProps,
    initialDomains,
    addNewPaymentAddress,
    domainSkeleton,
    loadMoreDomains,
    hasMoreDomains,
    isSubmitting,
  } = props;

  return (
    <Box className="margin-top30" display="flex" justifyContent="center">
      <Box>
        <Box width={600}>
          <CustomSelect
            showLabel
            label={domain}
            disabled={isSubmitting}
            value={values?.domain}
            MenuProps={{
              id: "payout-wallet-address",
              disableScrollLock: true,
              sx: {
                "& .MuiPopover-paper.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation1 .MuiList-root.MuiMenu-list":
                  {
                    padding: "0px !important",
                  },
              },
              PaperProps: {
                style: { maxHeight: 300 },
                onScroll: (event) => {
                  const listboxNode = event.currentTarget;
                  if (
                    listboxNode.scrollTop + listboxNode.clientHeight ===
                    listboxNode.scrollHeight
                  ) {
                    if (hasMoreDomains) {
                      loadMoreDomains();
                    }
                  }
                },
              },
            }}
            onChange={(e) => setFieldValue("domain", e.target.value)}
            placeholder={paymentDomainPlaceholder}
            renderValue={() =>
              values?.domain || <Box>{paymentDomainPlaceholder}</Box>
            }
          >
            {addNewPaymentAddress}
            {initialDomains?.map((dropDownValue) => {
              return (
                <MenuItem
                  key={dropDownValue?.domain}
                  value={dropDownValue?.domain}
                  disableTouchRipple
                  sx={{
                    bgcolor: "unset !important",
                    p: "0 16px !important",
                    my: "16px !important",
                  }}
                >
                  <RadioButton
                    checked={values?.domain === dropDownValue?.domain}
                    label={dropDownValue?.domain}
                    onChange={() =>
                      setFieldValue("domain", dropDownValue?.domain)
                    }
                    value={dropDownValue?.domain}
                    sx={{ display: "flex" }}
                  />
                </MenuItem>
              );
            })}
            {domainSkeleton && (
              <Box px={2} my={2} borderRadius={1}>
                <Skeleton
                  animation="wave"
                  sx={{ bgcolor: "#eaeef1 !important" }}
                />
              </Box>
            )}
          </CustomSelect>
        </Box>
        <Box width={600} className="margin-top30" display="flex" gap={3}>
          <Box width={values?.domain ? "55%" : "100%"}>
            <Input
              type="text"
              name="username"
              value={values.username}
              onChange={(e) => {
                setTouched({ ...touched, username: false });
                setFieldValue("username", e.target.value);
              }}
              inputProps={{ maxLength: 32 }}
              showLabel
              label={username}
              fullWidth
              placeholder={enterYourUsername}
              onBlur={() => setTouched({ ...touched, username: true })}
              error={errors.username && Boolean(errors.username)}
              disabled={isSubmitting}
            />
            <InputErrorMessage
              {...defaultErrorMessageProps}
              inputName="username"
            />
          </Box>
          {values?.domain && (
            <Text
              className="default-text"
              font="regular"
              size={16}
              variant="body1"
              sx={{ mt: 4.5 }}
            >
              @{values.domain}
            </Text>
          )}
        </Box>
        <Box
          className="confirmation-box margin-top30"
          sx={{ cursor: "unset !important" }}
          width={600}
        >
          <CustomCheckbox
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "#0A193E !important",
                fontWeight: "600 !important",
              },
            }}
            checked={values?.success_message_checked}
            label={confirmationMessageLabel}
            className="checkbox-wrapper"
            onChange={() =>
              setFieldValue(
                "success_message_checked",
                !values?.success_message_checked
              )
            }
          />
          <Box ml={4} mt={2.5}>
            <TextAreaComponent
              showLabel={false}
              value={values.success_message}
              disabled={!values?.success_message_checked}
              maxRows={3}
              minRows={3}
              maxLength={255}
              fullWidth
              error={touched.success_message && Boolean(errors.success_message)}
              onBlur={() => setTouched({ ...touched, success_message: true })}
              onChange={(e) => {
                setTouched({ ...touched, success_message: false });
                setFieldValue("success_message", e.target.value);
              }}
              placeholder={messagePlaceholder}
            />
            <InputErrorMessage
              {...defaultErrorMessageProps}
              inputName="success_message"
            />
          </Box>
        </Box>
        <Box
          className="confirmation-box margin-top30"
          sx={{ cursor: "unset !important" }}
          width={600}
        >
          <CustomCheckbox
            sx={{
              "& .MuiFormControlLabel-label": {
                color: "#0A193E !important",
                fontWeight: "600 !important",
              },
            }}
            checked={values?.success_url_checked}
            label={successURL}
            className="checkbox-wrapper"
            onChange={() =>
              setFieldValue("success_url_checked", !values?.success_url_checked)
            }
          />
          <Box ml={4} mt={1}>
            <Text
              className="grey-text"
              font="regular"
              size={14}
              sx={{ mb: 2, maxWidth: 600 }}
              variant="body2"
            >
              {noteForAddingSuccessUrlInPaymentAddress}
            </Text>
            <Input
              showLabel
              value={values.success_url}
              disabled={!values?.success_url_checked}
              name="success_url"
              label={websiteLabel}
              type="text"
              fullWidth
              inputProps={{ maxLength: 255 }}
              error={touched.success_url && Boolean(errors.success_url)}
              placeholder={websiteURLError}
              onBlur={() => {
                setTouched({ ...touched, success_url: true });
              }}
              onChange={(e) => {
                setTouched({ ...touched, success_url: false });
                setFieldValue("success_url", e.target.value);
              }}
            />
            <InputErrorMessage
              {...defaultErrorMessageProps}
              inputName="success_url"
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentAddressRequestDetails;
