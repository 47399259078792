import React from "react";
import { Box } from "@mui/material";
import CommonSkeleton from "../../CommonSkeleton";

const AccountLoader = ({ customClass = "" }) => {
  return (
    <Box>
      <CommonSkeleton
        color="#EAEAEA"
        width={600}
        height={20}
        sx={{ mr: "8px", borderRadius: "15px" }}
        className={`account-loader-header ${customClass}`}
      />
      <CommonSkeleton
        color="#F4F4F4"
        width={450}
        height={16}
        className={`account-loader-sub-line ${customClass}`}
        sx={{ mt: "8px", borderRadius: "15px" }}
      />
    </Box>
  );
};

export default AccountLoader;
