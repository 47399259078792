import React, { useEffect, useState } from "react";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import { Box, Grid } from "@mui/material";
import { FieldArray, useFormikContext } from "formik";
import Text from "@speed/common/src/components/Text/Text";
import AddIcon from "@mui/icons-material/Add";
import {
  addOptions,
  addOptionsLower,
  cancel,
  editOptionsLower,
  optionsLeft,
} from "../messages";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import { Input } from "@speed/common/src/components/Input/Input";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Button from "@speed/common/src/components/Button/Button";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import { save } from "@speed/common/src/messages";
import { reorder } from "@speed/common/src/components/constants";

const DropDownOptionsModal = ({
  openModal,
  handleClose,
  optionsInitialValue,
  handleKeypress,
  parentIndex,
  name,
  handleParentSave,
  isEdit,
}) => {
  const { errors, handleChange, values, setFieldValue } = useFormikContext();

  const [disable, setDisable] = useState(false);
  const dropDownOptions = values.customFieldsArr[parentIndex]?.dropDownOptions;

  useEffect(() => {
    !errors.customFieldsArr?.[parentIndex]?.dropDownOptions
      ? setDisable(false)
      : setDisable(true);
  }, [errors]);

  const footer = (
    <>
      <Button
        variant="outlined"
        label={cancel}
        sx={{ mr: "16px" }}
        onClick={handleClose}
      />
      <Button
        variant="contained"
        label={save}
        sx={{ ml: "0 !important" }}
        disabled={disable}
        onClick={handleParentSave}
      />
    </>
  );

  const removeField = (index, arrayHelpers) => {
    if (dropDownOptions?.length > 2) {
      arrayHelpers.remove(index);
    }
  };

  // Function to update list on drop
  const handleDrop = ({ destination, source }) => {
    // Ignore drop outside droppable container
    if (!destination) return;

    const newItems = reorder(dropDownOptions, source.index, destination.index);
    setFieldValue(name, newItems);
  };

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    margin: `0 0 18px 0`,

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const optionsCount = 10 - dropDownOptions?.length;
  const optionLengthCheck = dropDownOptions?.length < 10;

  const handleInputKeyDown = (event, arrayHelpers) => {
    if (optionLengthCheck) {
      if (event.key === "Enter" && (event.metaKey || event.ctrlKey)) {
        arrayHelpers.push(optionsInitialValue);
      }
    }
  };

  const modalContent = () => {
    return (
      <FieldArray
        name={name}
        render={(arrayHelpers) => (
          <DragDropContext onDragEnd={handleDrop} enableDefaultSensors={true}>
            <Droppable droppableId="list-container">
              {(provided) => (
                <Box
                  component="div"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  marginBottom="15px"
                >
                  {dropDownOptions?.map((_option, index) => {
                    const errorCheck =
                      errors?.customFieldsArr?.[parentIndex]?.dropDownOptions?.[
                        index
                      ];
                    return (
                      <Draggable
                        isDragDisabled={_option.option === ""}
                        key={`dropdown_option_${index}`}
                        draggableId={index.toString()}
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <>
                            <Grid
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                              container
                              justifyContent="space-between"
                              className="margin-top15"
                              alignItems="center"
                              sx={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style
                              )}
                            >
                              <Grid item lg={1} textAlign="right">
                                <DragIndicatorIcon
                                  sx={{
                                    height: "24px",
                                    width: "24px",
                                    color: "#B5B9C5",
                                  }}
                                />
                              </Grid>
                              <Grid item lg={9.5}>
                                <Input
                                  autoComplete="off"
                                  type="text"
                                  showLabel={false}
                                  fullWidth={true}
                                  placeholder={`Option ${index + 1}`}
                                  name={`${name}[${index}].option`}
                                  value={
                                    values.customFieldsArr[parentIndex]
                                      ?.dropDownOptions[index]?.option
                                  }
                                  onChange={handleChange}
                                  onKeyDown={(e) =>
                                    handleInputKeyDown(e, arrayHelpers)
                                  }
                                  error={
                                    errorCheck && Boolean(errorCheck?.option)
                                  }
                                />
                              </Grid>
                              <Grid item lg={1}>
                                {dropDownOptions.length > 2 && (
                                  <RemoveCircleOutlinedIcon
                                    onClick={() =>
                                      removeField(index, arrayHelpers)
                                    }
                                    className="remove-circle-icon"
                                    tabIndex={0}
                                    onKeyUp={(e) =>
                                      handleKeypress(e, index, arrayHelpers)
                                    }
                                  />
                                )}
                              </Grid>
                            </Grid>
                            {errorCheck && Boolean(errorCheck?.option) && (
                              <AlertMessage
                                key={`option_error_${errorCheck}`}
                                message={errorCheck?.option}
                                className="margin-top15"
                                severity="error"
                                sx={{
                                  margin: "auto",
                                  width: "425px",
                                }}
                              />
                            )}
                          </>
                        )}
                      </Draggable>
                    );
                  })}
                </Box>
              )}
            </Droppable>
            <Box
              className="content-center margin-top15"
              justifyContent="space-between !important"
            >
              <Text
                withIcon="start"
                size={14}
                font="semibold"
                variant="subtitle1"
                sx={{
                  color: "#2A67FF",
                  cursor: "pointer",
                  ml: "45px",
                  width: "fit-content",
                }}
                onClick={() => {
                  arrayHelpers.push(optionsInitialValue);
                }}
              >
                {optionLengthCheck && (
                  <>
                    <AddIcon
                      sx={{
                        height: "24px",
                        width: "24px",
                        color: "#2A67FF !important",
                      }}
                    />{" "}
                    {addOptions}
                  </>
                )}
              </Text>

              {optionsCount > 0 && (
                <Text
                  size={14}
                  font="semibold"
                  className="grey-text"
                  variant="subtitle1"
                  sx={{
                    width: "fit-content",
                    marginRight: dropDownOptions.length > 2 ? "23px" : "56px",
                  }}
                >
                  {optionsCount}{" "}
                  {optionsCount === 1 ? optionsLeft[0] : optionsLeft[1]}
                </Text>
              )}
            </Box>
          </DragDropContext>
        )}
      />
    );
  };

  return (
    <Box component="div">
      <Modal
        maxWidth="sm"
        body={modalContent()}
        footer={footer}
        open={openModal}
        title={isEdit ? editOptionsLower : addOptionsLower}
        handleClose={handleClose}
        className="dropdown-option-modal"
      />
    </Box>
  );
};

export default DropDownOptionsModal;
