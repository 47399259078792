import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { QRCodeComponent } from "@speed/common/src/components/QRCode/QRCode";
import Text from "@speed/common/src/components/Text/Text";
import { speedQrLogo } from "../../images";
import {
  bitcoin,
  lightning,
  on_chain,
  onchain,
  sats,
  usdt,
} from "../../messages";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import { useSelector } from "react-redux";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import { displayTaprootCurrency, getAccountFees } from "../../constants";
import ReceiveFooter from "./ReceiveFooter";
import AccountFees from "./AccountFees";
import classNames from "classnames";
import {
  makeBoldString,
  satsToBtcAmount,
} from "@speed/common/src/components/constants";

const ReceivePayment = ({
  setIsEnterCustomAmount,
  receiveResponse,
  amount,
  isLoading,
  selectedCurrencyType,
  setStep,
  setIsNoteClicked,
  switchCurrency,
  displayAmount,
  generatedPaymentMethodArr,
  setSelectedMethodName,
  selectedMethodName,
  paymentId,
  statementDesc,
  setSelectedTab,
  selectedTab,
}) => {
  const currency = selectedCurrencyType?.uuid || sats;
  const walletDefaultCurrency = JSON.parse(
    localStorage.getItem("account_default_currency")
  );
  const targetedCurrency = walletDefaultCurrency?.code;
  const showBtcAmount = useSelector((state) => state.common.showBtcAmount);
  const currencyColor = classNames("bg-color-" + currency);
  const sliderRef = useRef();
  const divRef = useRef();
  const isDisplayGeneratedAddress = !(
    selectedMethodName === lightning ||
    (currency === usdt && selectedMethodName === on_chain)
  );
  const tabLoaderCss = {
    borderRadius: "10px",
    width: "85%",
    height: "55px",
  };

  const [accountFees, setAccountFees] = useState(null);

  useEffect(() => {
    fetchAccountFees();
  }, [selectedTab]);

  const fetchAccountFees = async () => {
    try {
      const speedFee = await getAccountFees(
        selectedMethodName === on_chain ? onchain : selectedMethodName,
        currency,
        "01"
      );
      setAccountFees(speedFee);
    } catch (err) {}
  };

  const handleSwipe = (value) => {
    const arrLength = generatedPaymentMethodArr?.length;
    const leftTab = arrLength === selectedTab ? selectedTab : selectedTab + 1;
    const rightTab = selectedTab === 1 ? selectedTab : selectedTab - 1;
    let selectTab = value === "left" ? leftTab : rightTab;
    const res = generatedPaymentMethodArr?.find(
      (method) => method?.tab == selectTab
    );
    setSelectedMethodName(res?.name);
    setSelectedTab(selectTab);
  };

  const carouselProps = {
    dots: false,
    touchMove: true,
    infinite: false,
    arrows: false,
    onSwipe: handleSwipe,
  };

  const rowPaymentAddress = useMemo(() => {
    return generatedPaymentMethodArr?.find(
      (addr) => addr?.name === selectedMethodName
    )?.address;
  }, [generatedPaymentMethodArr, selectedMethodName, paymentId]);

  const getGeneratedAddress = () => {
    return makeAddress(
      rowPaymentAddress,
      statementDesc,
      amount,
      selectedMethodName === on_chain
        ? bitcoin.toLowerCase()
        : selectedMethodName
    );
  };

  const makeAddress = (
    address,
    statement_descriptor,
    amount,
    addressHeader
  ) => {
    const url = new URL(`${addressHeader}:${address}`);
    if (amount) {
      let amountToSendInAddress = 0;
      if (addressHeader === "bitcoin" && currency === sats) {
        amountToSendInAddress = showBtcAmount
          ? amount
          : satsToBtcAmount(amount);
      } else {
        amountToSendInAddress = amount;
      }
      url.searchParams.append("amount", amountToSendInAddress);
    }
    if (statement_descriptor) {
      url.searchParams.append("label", statement_descriptor);
    }
    return url.toString();
  };

  const handleTabChange = (tab) => {
    sliderRef.current?.slickGoTo(tab === 1 ? 0 : tab - 1);
    setSelectedTab(tab);
  };

  const displayQR = () => {
    const paymentAddress = isDisplayGeneratedAddress
      ? getGeneratedAddress()
      : rowPaymentAddress;
    return (
      <QRCodeComponent
        value={paymentAddress}
        size={410}
        icon
        iconSize={25}
        level={paymentAddress?.length > 100 ? "L" : "H"}
        includeMargin={true}
        borderRadius="10px"
        src={speedQrLogo}
      />
    );
  };

  const renderTabData = (tabId, icon, text) => {
    return (
      <Box
        className={
          selectedTab === tabId
            ? `selected-tab ${currencyColor}`
            : "lightning-onchain-tab"
        }
        onClick={() => {
          handleTabChange(tabId);
          setSelectedMethodName(text);
        }}
        sx={{
          padding: "9px",
        }}
      >
        <Text
          size={20}
          font="semibold"
          className="default-class content-center"
        >
          <img
            alt=""
            src={icon}
            height="24px"
            width="24px"
            style={{ marginRight: "9px" }}
          />
          {text === on_chain
            ? bitcoin
            : text.charAt(0).toUpperCase() + text.slice(1)}
        </Text>
      </Box>
    );
  };

  const renderLightningOnchainTabs = () => {
    return isLoading ? (
      <Box
        className={`receive-tabs-box ${
          generatedPaymentMethodArr?.length >= 2 && "receive-tabs"
        } `}
        width="520px"
      >
        <CommonSkeleton
          {...tabLoaderCss}
          sx={{ transform: "scale(1, 1)", marginRight: "10px" }}
        />
        <CommonSkeleton {...tabLoaderCss} sx={{ transform: "scale(1, 1)" }} />
      </Box>
    ) : (
      <Box
        className={`receive-tabs-box ${
          generatedPaymentMethodArr?.length >= 2 && "receive-tabs"
        } `}
      >
        <>
          {generatedPaymentMethodArr?.map((method) => {
            return renderTabData(method?.tab, method?.icon, method?.name);
          })}
        </>
      </Box>
    );
  };

  const handleEnteredAmount = (isNote) => {
    setIsEnterCustomAmount(true);
    setIsNoteClicked(isNote);
    setStep("2");
  };
  return (
    <Box className="receive-container">
      {renderLightningOnchainTabs()}
      <Box
        className={`payment-qr-code ${
          currency !== sats ? "payment-qr-code-usdt" : "payment-qr-code-btc"
        }`}
      >
        {amount > 0 && (
          <Box
            className="entered-amount"
            onClick={() => handleEnteredAmount(false)}
          >
            <Text
              size={16}
              className="default-text"
              font="regular"
              variant="subtitle"
            >
              {switchCurrency
                ? targetedCurrency
                : displayTaprootCurrency(amount, currency, showBtcAmount)}
            </Text>
            <Text size={44} className="default-text" font="semibold">
              {displayAmount(switchCurrency)}
            </Text>
            <Text size={24} className="grey-text" font="regular">
              {!switchCurrency && walletDefaultCurrency?.symbol}
              {displayAmount(!switchCurrency)}{" "}
              {switchCurrency &&
                displayTaprootCurrency(amount, currency, showBtcAmount)}
            </Text>
            {selectedTab === 1 && receiveResponse?.statement_descriptor && (
              <Box
                className="note-section"
                sx={{
                  marginTop: "16px",
                  cursor: "pointer",
                }}
                onClick={() => handleEnteredAmount(true)}
              >
                <TruncatedTextTooltip
                  textProps={{ className: "grey-text" }}
                  textValue={receiveResponse.statement_descriptor}
                  cellWidth="225px"
                  fontSize={14}
                />
              </Box>
            )}
          </Box>
        )}
        {isLoading ? (
          <Skeleton
            style={{
              margin: "24px 40px",
              borderRadius: "8px",
            }}
            variant="rectangular"
            width={410}
            height={410}
          />
        ) : (
          <div ref={divRef}>
            <Slider {...carouselProps} ref={sliderRef}>
              {isLoading
                ? displayQR()
                : generatedPaymentMethodArr?.map(() => displayQR())}
            </Slider>
          </div>
        )}
        <Box className="wallet-address-section">
          <Box className="wallet-receive-clipboard">
            <Text
              size={16}
              font="regular"
              variant="subtitle1"
              className="default-text wallet-receive-text"
            >
              {isLoading ? (
                <Skeleton width={310} animation={"wave"} />
              ) : (
                makeBoldString(rowPaymentAddress, 15)
              )}
            </Text>
            {!isLoading && <Clipboard text={rowPaymentAddress} />}
          </Box>
          <AccountFees isLoading={isLoading} accountFees={accountFees} />
        </Box>
      </Box>
      <ReceiveFooter
        isLoading={isLoading}
        setIsEnterCustomAmount={setIsEnterCustomAmount}
        amount={amount}
        setStep={setStep}
        receiveResponse={receiveResponse}
        getGeneratedAddress={getGeneratedAddress}
        rowPaymentAddress={rowPaymentAddress}
        divRef={divRef}
        isDisplayGeneratedAddress={isDisplayGeneratedAddress}
        selectedTab={selectedTab}
      />
    </Box>
  );
};
export default ReceivePayment;
