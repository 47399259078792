import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import {
  addAnotherPrice,
  deleteText,
  duplicate,
  pricingDetail,
  pricingInfo,
} from "../messages";
import Text from "@speed/common/src/components/Text/Text";
import { useFormik, FieldArray, FormikProvider } from "formik";
import CustomAccordion from "@speed/common/src/components/Accordion/Accordion";
import PricingFormAccordion from "./PricingFormAccordion";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import Button from "@speed/common/src/components/Button/Button";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import * as yup from "yup";
import { currencyAmountValidation } from "../constants";

const PricingForm = forwardRef((props, ref) => {
  const [expanded, setExpanded] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [addPricingDisable, setAddPricingDisable] = useState(false);
  const [currentRow, setCurrentRow] = useState({ id: null, row: null });

  const priceObj = {
    modal: "standard",
    currency: { name: "", code: "", symbol: "" },
    amount: "",
  };

  const validationSchema = yup.object().shape({
    pricing: yup.array().of(yup.object().shape(currencyAmountValidation)),
  });

  const formik = useFormik({
    initialValues: {
      pricing: [priceObj],
    },
    validationSchema: validationSchema,
  });

  const { values, dirty, isValid, isSubmitting } = formik;

  useEffect(() => {
    const disable = !(isValid && dirty) || isSubmitting;

    props.setDisableCreateButton(disable);
  }, [dirty, isValid, isSubmitting, values]);

  useEffect(() => {
    setExpanded(values.pricing.length > 1 || 0);
  }, []);

  const handleChangeDropdown = (title) => (_e, newExpanded) => {
    setExpanded(newExpanded ? title : false);
  };

  const handleOnClick = (event, index) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleDuplicate = (arrayHelpers) => {
    const { modal, currency, amount } = currentRow.row;
    arrayHelpers.push({
      modal: modal,
      currency: {
        name: currency.name,
        code: currency.code,
        symbol: currency.symbol,
      },
      amount: amount,
    });
  };

  const handleDelete = (index, arrayHelpers) => {
    arrayHelpers.remove(currentRow.id);
  };

  const renderAccordionHeader = (price) => {
    return price?.currency?.name === "Satoshi" ? (
      <>
        <span
          style={{
            fontFamily: "Satoshi-Symbol",
            height: "24px",
            paddingTop: "1.5px",
            width: "11px",
          }}
        >
          !
        </span>
        {price?.amount}
      </>
    ) : (
      `${price?.currency?.symbol} ${price?.amount}`
    );
  };

  useImperativeHandle(ref, () => ({
    handleCreateProduct: () => {
      setAddPricingDisable(true);
      return values.pricing;
    },
  }));

  return (
    <Box
      className="pricing-form-wrapper"
      width="30%"
      component="form"
      autoComplete="off"
      sx={!props.isCreateProduct ? { display: "none" } : { display: "block" }}
    >
      <Text size={18} font="semibold" sx={{ margin: "15px 0px" }}>
        {pricingInfo}
      </Text>
      <FormikProvider value={formik}>
        <FieldArray name="pricing">
          {(arrayHelpers) => (
            <>
              {values.pricing.map((price, index) => {
                return (
                  <>
                    <CustomPopper
                      disablePortal={true}
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      position="bottom"
                      handleClose={() => setAnchorEl(null)}
                    >
                      <List>
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => handleDuplicate(arrayHelpers)}
                          >
                            <ListItemText
                              primary={duplicate}
                              className="text-primary"
                            />
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemButton
                            onClick={() => handleDelete(index, arrayHelpers)}
                          >
                            <ListItemText
                              primary={deleteText}
                              className="error"
                            />
                          </ListItemButton>
                        </ListItem>
                      </List>
                    </CustomPopper>
                    <CustomAccordion
                      className="pricing-detail"
                      isTitleShown={true}
                      key={index}
                      expandIcon="expandMore"
                      from="price"
                      onChange={(title) => handleChangeDropdown(index)}
                      expanded={expanded === index}
                      accordianPanel={price}
                      title={
                        price?.amount
                          ? renderAccordionHeader(price)
                          : pricingDetail
                      }
                      customComponent={
                        <PricingFormAccordion index={index} formik={formik} />
                      }
                      helperElement={
                        <MoreHorizIcon
                          className="horizontal-dots-icon"
                          tabIndex={0}
                          //   onKeyDown={handleKeyDown}
                          onClick={(event) => {
                            event.stopPropagation();
                            setCurrentRow({ id: index, row: price });
                            handleOnClick(event, index);
                          }}
                        />
                      }
                    />
                  </>
                );
              })}
              <CustomDivider sx={{ marginTop: "18px" }} />
              <Button
                icon="addIcon"
                variant="outlined"
                color="primary"
                label={addAnotherPrice}
                disabled={addPricingDisable}
                onClick={() => {
                  arrayHelpers.push(priceObj);
                  setExpanded(values.pricing.length);
                }}
                sx={{ marginTop: "18px" }}
              />
            </>
          )}
        </FieldArray>
      </FormikProvider>
    </Box>
  );
});

export default PricingForm;
