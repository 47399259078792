import React, { useState } from "react";
import { Box } from "@mui/material";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import {
  emailPopperText,
  phoneNumberLabel,
  phonePopperText,
  provideEmail,
} from "../messages";
import { commonStyle, handleClick, handleClose } from "./AllLinkFormConstant";
import { CustomPopperElement } from "../constants";
import { Info } from "@mui/icons-material";

const EmailPhoneNumber = ({ emailFlag, phoneFlag, values, setFieldValue }) => {
  const [anchorElEmail, setAnchorElEmail] = useState(null);
  const [anchorElPhone, setAnchorElPhone] = useState(null);

  const openPhone = Boolean(anchorElPhone);
  const openEmail = Boolean(anchorElEmail);

  const handleKeyDown = (e, name, value) => {
    if (e.keyCode === 13) {
      setFieldValue(name, !value);
    }
  };

  return (
    <>
      {emailFlag && (
        <Box className="margin-top20" display="flex" alignItems="center">
          <CustomCheckbox
            checked={values.isCheckedEmail}
            name="allowEmail"
            label={provideEmail}
            className="options checkbox-label"
            sx={commonStyle}
            onKeyDown={(e) =>
              handleKeyDown(e, "isCheckedEmail", e.target.checked)
            }
            onChange={(e) => {
              setFieldValue("isCheckedEmail", e.target.checked);
            }}
          />
          <Info
            onClick={(e) => handleClick(e, setAnchorElEmail, anchorElEmail)}
            className="info-icon-payment-link"
          />
          <CustomPopperElement
            id="email"
            desc={emailPopperText}
            setAnchor={setAnchorElEmail}
            anchor={anchorElEmail}
            open={openEmail}
            handleClose={handleClose}
          />
        </Box>
      )}
      {phoneFlag && (
        <Box className="margin-top20" display="flex" alignItems="center">
          <CustomCheckbox
            checked={values.isCheckedPhone}
            name="allowPhone"
            label={phoneNumberLabel}
            className="options checkbox-label"
            sx={commonStyle}
            onKeyDown={(e) =>
              handleKeyDown(e, "isCheckedPhone", e.target.checked)
            }
            onChange={(e) => {
              setFieldValue("isCheckedPhone", e.target.checked);
            }}
          />
          <Info
            onClick={(e) => handleClick(e, setAnchorElPhone, anchorElPhone)}
            className="info-icon-payment-link"
          />
          <CustomPopperElement
            id="phone"
            desc={phonePopperText}
            setAnchor={setAnchorElPhone}
            anchor={anchorElPhone}
            open={openPhone}
            handleClose={handleClose}
          />
        </Box>
      )}
    </>
  );
};

export default EmailPhoneNumber;
