import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sessionService } from "redux-react-session";
import { Box, Skeleton } from "@mui/material";

import Text from "@speed/common/src/components/Text/Text";
import {
  addNote,
  addNotePlaceholder,
  addressDesc,
  amountPlaceholder,
  balanceExceeded,
  balancePreText,
  currencyPlaceholder,
  enterAmount,
  keepSatsMsg,
  lightning,
  lnurl,
  maxAllowed,
  minAllowed,
  onchain,
  recipientLabel,
  sats,
  sendMax,
  sendPaymentLabel,
} from "../messages";
import { Input } from "@speed/common/src/components/Input/Input";
import CurrencyAutoComplete from "../Common/CurrencyAutoComplete";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import {
  allowedFourAfterDecimalRegex,
  availableBalanceTextComponent,
  callAPIInterface,
  getPayoutBalanceAPI,
  idealTimeLimitReached,
  setShowCrucialActionModal,
  updateSessionLastActionTime,
} from "../constants";
import {
  btcToSatsAmount,
  commaSeperatedCurrencyAmount,
  excludedCode,
  satsToBtcAmount,
  satsToFiatAmount,
} from "@speed/common/src/components/constants";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import Label from "@speed/common/src/components/Label/Label";
import {
  setExecuteAPICall,
  setInstantSendButtonClicked,
  setLoading,
  showToast,
} from "../../redux/common/actions";
import FeesExceedModal from "../Common/FeesExceedModal";
import InsufficientBalanceModal from "../Common/InsufficientBalanceModal";
import history from "@speed/common/src/components/history";
import CommonEstimateFeesBox from "../Common/CommonEstimateFeesBox";

const InstantSendForm = ({
  formik,
  disableCurrency,
  disableAmount,
  amountLimits,
  decodeResponse,
  satsAmount,
  setSatsAmount,
  setDisableSend,
  setSendBtnLoader,
  handleModalClose,
  handleBack,
}) => {
  const dispatch = useDispatch();

  const [exchangeRateValue, setExchangeRateValue] = useState();
  const [exchangeRateLoader, setExchangeRateLoader] = useState(false);
  const [amountError, setAmountError] = useState("");
  const [speedFees, setSpeedFees] = useState(0);
  const [estimatedFeeLoader, setEstimatedFeeLoader] = useState(false);
  const [estimatedFees, setEstimatedFees] = useState(0);
  const [timeoutId, setTimeoutId] = useState(null);
  const [sendMaxLoader, setSendMaxLoader] = useState(false);
  const [balance, setBalance] = useState();
  const [balanceLoader, setBalanceLoader] = useState(false);
  const [openFeesModal, setOpenFeesModal] = useState(false);
  const [openInsufficientBalanceModal, setOpenInsufficientBalanceModal] =
    useState(false);
  const [newEstimatedFees, setNewEstimatedFees] = useState();
  const [session, setSession] = useState(null);
  const [feesBtnLoader, setFeesBtnLoader] = useState(false);
  const [speedFeeLoader, setSpeedFeeLoader] = useState(false);

  const abortController = new AbortController();
  const { values, setFieldValue } = formik;
  const speedFeesInSats = Math.floor(satsAmount * (speedFees / 100));
  const {
    withdraw_method,
    max_sendable,
    min_sendable,
    decodeAmt,
    withdraw_request,
  } = {
    ...decodeResponse,
  };

  const { instantSendButtonClicked, executeAPICall } = useSelector(
    (state) => state.common
  );
  const user = useSelector((state) => state.auth.user);

  const getCalculatedBalance = async () => {
    const updatedBalance = await getPayoutBalance();
    const balanceInSats = btcToSatsAmount(updatedBalance);
    return { updatedBalance, balanceInSats };
  };

  const createInstantSend = async () => {
    setSendBtnLoader(true);
    const { amount, currency, note } = values;
    const params = {
      currency: currency?.code,
      target_currency: "SATS",
      withdraw_request,
      withdraw_method: withdraw_method === lnurl ? lightning : withdraw_method,
      amount,
    };
    if (note) params.note = note;
    try {
      const result = await callAPIInterface(
        "POST",
        "/send",
        JSON.stringify(params)
      );
      result && history.push(`/instant-sends/${result.id}`);
    } catch (e) {}
    handleModalClose();
    setSendBtnLoader(false);
  };

  const proceedFunc = (balanceInSats) => {
    if (balanceInSats < satsAmount + estimatedFees + speedFeesInSats)
      setOpenInsufficientBalanceModal(true);
    else if (idealTimeLimitReached(session.last_action_time)) {
      setSendBtnLoader(false);
      setShowCrucialActionModal(user, true);
    } else createInstantSend();
  };

  const handleSend = async () => {
    setSendBtnLoader(true);
    formik.setSubmitting(false);
    const { balanceInSats } = await getCalculatedBalance();

    if (withdraw_method === onchain) {
      const feesResponse = await getEstimatedFees(satsAmount, false);
      const newFees = feesResponse?.fee;
      if (estimatedFees < newFees) {
        setOpenFeesModal(true);
        setNewEstimatedFees(newFees);
      } else proceedFunc(balanceInSats);
    } else {
      proceedFunc(balanceInSats);
    }
    dispatch(setInstantSendButtonClicked(false));
  };

  useEffect(() => {
    instantSendButtonClicked && handleSend();
  }, [instantSendButtonClicked]);

  //Get exchange rates when fiat currency is selected
  const getExchangeRate = (currency) => {
    setDisableSend(true);
    setExchangeRateLoader(true);
    const data = {
      currency,
      target_currency: "SATS",
    };
    callAPIInterface("POST", "/utility/exchange-rate", data)
      .then((res) => {
        setExchangeRateLoader(false);
        const exchangeRate = res?.target_lowest_rate;
        setExchangeRateValue(exchangeRate);
      })
      .catch((_e) => setExchangeRateLoader(false));
  };

  const setMaxAmountToInput = (sendMaxAmt) => {
    const cryptoAmount = isSATS ? sendMaxAmt : satsToBtcAmount(sendMaxAmt);
    const amount = excludedCode.includes(values.currency?.code)
      ? cryptoAmount
      : satsToFiatAmount(sendMaxAmt, exchangeRateValue, 4);
    setFieldValue("amount", amount);
    setSatsAmount(sendMaxAmt);
    setInitialAmountValue(amount);
  };

  const getLightningMaxAmount = async () => {
    setAmountError("");
    setSendMaxLoader(true);
    try {
      const res = await callAPIInterface(
        "GET",
        "/withdraws/lightning-max-amount"
      );
      setMaxAmountToInput(res?.amount);
    } catch (e) {}
    setSendMaxLoader(false);
  };

  const getMethod = () => {
    const methodsArr = [lightning, lnurl];
    if (methodsArr.includes(withdraw_method)) return lightning;
    else if (withdraw_method === onchain) return onchain;
  };

  const getSpeedFee = async () => {
    setSpeedFeeLoader(true);
    try {
      const params = `?payment_method=${getMethod()}&transaction_code=04&target_currency=SATS`;
      const response = await callAPIInterface("GET", `/account-fees${params}`);
      setSpeedFees(response?.fee_percentage);
      setSpeedFeeLoader(false);
      return response?.fee_percentage;
    } catch (_err) {
      setSpeedFeeLoader(false);
    }
  };

  const getPayoutBalance = async () => {
    setBalanceLoader(true);
    try {
      const result = await getPayoutBalanceAPI();
      setBalance(result);
      setBalanceLoader(false);
      return result;
    } catch (e) {
      setBalanceLoader(false);
    }
  };

  useEffect(() => {
    setInitialAmountValue(); // When address has an amount; checking validation of amount and calling estimate fees api
    sessionService.loadSession().then((newSession) => {
      setSession(newSession);
    });
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    if (executeAPICall) {
      const newSession = updateSessionLastActionTime();
      setSession(newSession);
      createInstantSend();
      dispatch(setLoading(false));
      setShowCrucialActionModal(user, false);
      dispatch(setExecuteAPICall(false));
    }
  }, [executeAPICall]);

  const handleFeesProceed = async () => {
    setFeesBtnLoader(true);
    const { balanceInSats } = await getCalculatedBalance();

    // Check if balance is less than actual amount+fees
    if (balanceInSats < satsAmount + newEstimatedFees + speedFeesInSats) {
      setEstimatedFees(newEstimatedFees);
      setOpenInsufficientBalanceModal(true);
    } else if (idealTimeLimitReached(session.last_action_time)) {
      setSendBtnLoader(false);
      setShowCrucialActionModal(user, true);
    } else createInstantSend();
    setOpenFeesModal(false);
    setFeesBtnLoader(false);
  };

  const handleTryAgain = async () => {
    setOpenInsufficientBalanceModal(false);
    handleBack();
  };

  const convertFiatToSats = (amount) =>
    // Check current currency is Fiat; If it is convert it to SATS
    !excludedCode.includes(values.currency?.code)
      ? Math.floor(exchangeRateValue * amount) // Fiat to SATs
      : parseFloat(amount);

  const getAmountValueInSATS = (amount) => {
    const fiatValue = convertFiatToSats(amount);
    return values.currency?.code === "BTC"
      ? btcToSatsAmount(amount)
      : fiatValue;
  };

  const checkValidationForAmount = (value, balanceInSats = undefined) => {
    const satsOfEnteredAmount = getAmountValueInSATS(value);
    setSatsAmount(satsOfEnteredAmount);

    const { min, max } = { ...amountLimits };
    let errorMsg = "";
    const satsLabel = min === 1 ? "SAT" : sats;
    const balToCompare = balanceInSats ?? btcToSatsAmount(balance);
    if (satsOfEnteredAmount > balToCompare) errorMsg = balanceExceeded;
    else if (satsOfEnteredAmount > max) errorMsg = maxAllowed(max, sats);
    else if (satsOfEnteredAmount < min) errorMsg = minAllowed(min, satsLabel);
    setAmountError(errorMsg);
    const isValid = !errorMsg;
    !isValid && estimatedFees && setEstimatedFees(0); // To hide the fees section if error occurred in above validation
    return { isValid, satsAmount: satsOfEnteredAmount };
  };

  const setInitialAmountValue = async (valueFromSendMax = 0) => {
    const { balanceInSats } = await getCalculatedBalance();
    const speedFee = await getSpeedFee();
    const amount = valueFromSendMax || values?.amount;

    if (amount) {
      const { isValid, satsAmount } = checkValidationForAmount(
        amount,
        balanceInSats
      );
      if (isValid) {
        const feesRes = await getEstimatedFees(satsAmount);
        checkForEstimateFees(satsAmount, feesRes?.fee, {
          instantBal: balanceInSats,
          instantSpeedFee: speedFee,
        });
      }
    }
  };

  useEffect(() => {
    const isDisable =
      !values.amount ||
      !values.currency ||
      Boolean(amountError) ||
      balanceLoader ||
      !balance ||
      sendMaxLoader ||
      speedFeeLoader;
    setDisableSend(isDisable);
  }, [
    amountError,
    values.amount,
    balance,
    balanceLoader,
    sendMaxLoader,
    speedFeeLoader,
  ]);

  const checkForEstimateFees = (satsAmount, fee, optionalParam = {}) => {
    const { instantBal, instantSpeedFee } = optionalParam;
    const speedFeeToCompare = instantSpeedFee ?? speedFees;
    const balToCompare = instantBal ?? btcToSatsAmount(balance);
    const speedFeesInSats = Math.floor(satsAmount * (speedFeeToCompare / 100));
    const total = satsAmount + fee + (speedFeesInSats || 0);
    total > balToCompare && setAmountError(keepSatsMsg(fee));
  };

  const debouncedHandleChange = (satsAmount) => {
    const newTimeoutId = setTimeout(async () => {
      if (satsAmount > 0) {
        const feesRes = await getEstimatedFees(satsAmount);
        checkForEstimateFees(satsAmount, feesRes?.fee);
      }
      setEstimatedFeeLoader(false);
      satsAmount === 0 && setEstimatedFees(0);
    }, 500);
    setTimeoutId(newTimeoutId);
  };

  const handleAmountChange = (value) => {
    const { isValid, satsAmount } = checkValidationForAmount(value);
    if (timeoutId) clearTimeout(timeoutId);
    isValid ? debouncedHandleChange(satsAmount) : setEstimatedFees(0);
    setFieldValue("amount", value);
  };

  const handleCurrencyOnChange = (value) => {
    const isFiat = !excludedCode.includes(value?.code);
    setFieldValue("amount", "");
    setSatsAmount(0);
    setAmountError("");
    isFiat && getExchangeRate(value?.code);
  };

  const getEstimatedFees = (satsAmount, shouldStateSet = true) => {
    setDisableSend(true);
    setEstimatedFeeLoader(true);
    const params = {
      address_with_amount: {
        [withdraw_request]: satsAmount,
      },
      target_confirmation: 3,
    };
    const { signal } = abortController;
    return callAPIInterface(
      "POST",
      "/withdraws/estimate",
      params,
      null,
      null,
      signal
    )
      .then((res) => {
        setEstimatedFeeLoader(false);
        setDisableSend(false);
        if (shouldStateSet) {
          setEstimatedFees(res?.fee);
        }
        return res;
      })
      .catch((e) => {
        const errorMsg = e.response?.data?.errors?.[0]?.message;
        dispatch(
          showToast({
            isToastOpen: true,
            toastMessage: errorMsg,
            toastVariant: "error",
          })
        );
        setEstimatedFeeLoader(false);
      });
  };

  const isSendMaxVisible =
    (withdraw_method === lightning && !decodeAmt) ||
    (withdraw_method === lnurl && max_sendable !== min_sendable);
  const isSATS = values.currency?.code === sats;

  const feesSectionProps = {
    estimatedFees,
    satsAmount,
    exchangeRateValue,
    loader: estimatedFeeLoader || exchangeRateLoader,
    speedFeesInSats,
    isDecodedResMethodOnchain: withdraw_method === onchain,
    currencyCode: values?.currency?.code,
    amount: values?.amount,
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const isFiat = !excludedCode.includes(values.currency?.code);
    if (value && isFiat && !allowedFourAfterDecimalRegex.test(value)) {
      e.preventDefault();
    } else handleAmountChange(value.slice(0, isSATS ? 9 : 10));
  };

  const handleKeyDown = (event) => {
    const excludedKey = ["e", "E", "+", "-"];
    if (isSATS) excludedKey.push(".");
    if (excludedKey.includes(event.key)) event.preventDefault();
  };

  const inputTextSection = exchangeRateLoader ? (
    <Skeleton width={50} height={8} />
  ) : (
    <>~{commaSeperatedCurrencyAmount(satsAmount, "BTC")} SATS</>
  );

  return (
    <Box component="div" display="flex" justifyContent="center">
      <Box
        component="div"
        width="599px"
        className="margin-top30 margin-bottom30"
      >
        <Text size={20} className="default-text" variant="h3" font="semibold">
          {sendPaymentLabel}
        </Text>
        <Input
          value={values.formatted_request}
          showLabel={true}
          label={recipientLabel}
          fullWidth
          customClass="margin-top30"
          disabled={true}
        />
        <Label
          sx={{
            mt: "8px",
            mb: "30px !important",
            fontFamily: "Inter-Regular !important",
            lineHeight: "20px !important",
          }}
        >
          {addressDesc}
        </Label>
        <CurrencyAutoComplete
          setFieldValue={setFieldValue}
          currencyValue={values.currency}
          placeholder={currencyPlaceholder}
          isDisabled={disableCurrency}
          handleOnchange={handleCurrencyOnChange}
        />
        <Input
          name="amount"
          value={values.amount}
          tabIndex={0}
          type="number"
          showLabel={true}
          label={amountPlaceholder}
          fullWidth
          customClass="margin-top30"
          disabled={disableAmount}
          placeholder={enterAmount}
          actionLabelLoaderComponent={
            <Skeleton width={70} height={8} sx={{ mb: "14px" }} />
          }
          actionLabelLoader={sendMaxLoader}
          actionLabel={isSendMaxVisible && sendMax}
          actionLabelClick={getLightningMaxAmount}
          actionLabelClass="instant-send-send-max"
          error={Boolean(amountError)}
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
          onWheel={(e) => e.target.blur()}
          isTextVisible={!excludedCode.includes(values.currency?.code)}
          inputText={inputTextSection}
          inputProps={{
            min: 0,
          }}
        />
        {availableBalanceTextComponent({
          preText: balancePreText,
          balance: `${balance} BTC`,
          loading: balanceLoader,
          calculatedSATS: `${commaSeperatedCurrencyAmount(
            btcToSatsAmount(balance),
            "BTC"
          )} SATS`,
          sx: { mb: !amountError ? "30px" : "15px", mt: "6px" },
        })}
        {amountError && (
          <AlertMessage
            message={amountError}
            severity="error"
            sx={{ mb: "30px" }}
          />
        )}
        <TextAreaComponent
          label={addNote}
          minRows={3}
          fullWidth
          name="text-area-name"
          onChange={(e) => setFieldValue("note", e.target.value)}
          placeholder={addNotePlaceholder}
          resize={false}
          value={values.note}
          maxLength={1000}
        />
        {Boolean(values?.amount && estimatedFees) && (
          <CommonEstimateFeesBox {...feesSectionProps} />
        )}
      </Box>
      {openFeesModal && (
        <FeesExceedModal
          btnLoader={feesBtnLoader}
          estimatedFees={estimatedFees}
          handleClose={() => setOpenFeesModal(false)}
          handleFeesProceed={handleFeesProceed}
          newEstimatedFees={newEstimatedFees}
          openFeesModal={openFeesModal}
        />
      )}
      {openInsufficientBalanceModal && (
        <InsufficientBalanceModal
          handleClose={() => setOpenInsufficientBalanceModal(false)}
          handleTryAgain={handleTryAgain}
          open={openInsufficientBalanceModal}
          totalFees={satsAmount + estimatedFees + speedFeesInSats}
          type="Instant send"
        />
      )}
    </Box>
  );
};

export default InstantSendForm;
