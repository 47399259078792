import React from "react";
import InvoicePreview from "../../Invoices/InvoicePreview";
import { Box } from "@mui/material";
import { staticInvoiceData } from "@speed/common/src/components/constants";

const InvoicePdf = () => {
  const totalRows = [
    // {
    //   name: "Subtotal",
    //   amount: "$2350.00",
    // },
    // {
    //   name: "Shipping (Air)",
    //   amount: "$2326.50",
    // },
    // {
    //   name: "Total excluding tax",
    //   amount: "$2326.50",
    // },
    // {
    //   name: "Sales tax (1% on $2350)",
    //   amount: "$23.50",
    // },
    {
      name: "Total",
      amount: "$2350.00",
    },
    {
      name: "Amount due",
      amount: "$2350.00",
    },
  ];
  const customFields = [
    // {
    //   name: "Custom field",
    //   value: "Test Custom Field",
    // },
  ];

  return (
    <Box
      container
      className="apply-auto-scroll invoice-preview-section-wrapper"
    >
      <Box className="inner-grid invoice-preview">
        <InvoicePreview
          from="branding"
          rows={staticInvoiceData}
          totalRows={totalRows}
          customFields={customFields}
        />
      </Box>
    </Box>
  );
};

export default InvoicePdf;
