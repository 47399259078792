import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "./connect-common.scss";
import {
  DiagonalDiv,
  isFontAvailable,
  isLightColor,
  renderFrequentComponent,
  validateColor,
} from "../constants";
import ConnectHeader from "./ConnectHeader";
import PaymentPageFooter from "../PaymentPageFooter";
import ConnectCard from "./ConnectCard";

const RenderConnectPreview = (props) => {
  const {
    currentAccount,
    connectBrandingDetails,
    customClass,
    contentClassName,
    visibleInConnectPage,
    livemode,
    deviceType,
  } = props;

  const [textColor, setTextColor] = useState("#fff");
  const [accentTextColor, setAccentTextColor] = useState("#fff");
  const [textFontFamily, setTextFontFamily] = useState("system-ui");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (connectBrandingDetails) {
      const { branding_color, accent_color, primarySavedColor, font_family } =
        connectBrandingDetails;

      const selectedColor = validateColor(branding_color)
        ? branding_color
        : primarySavedColor;

      const selectedAccentColor = validateColor(accent_color)
        ? accent_color
        : primarySavedColor;

      isLightColor(selectedColor)
        ? setTextColor("#0a193e")
        : setTextColor("#fff");

      isLightColor(selectedAccentColor)
        ? setAccentTextColor("#0a193e")
        : setAccentTextColor("#fff");

      if (font_family !== "system-ui") {
        setLoader(true);
        isFontAvailable(font_family)
          .then((res) => {
            res && setLoader(false);
          })
          .catch(() => setLoader(false));
      }
      setTextFontFamily(font_family || "system-ui");
    }
  }, [connectBrandingDetails]);

  const previewColor =
    connectBrandingDetails?.primarySavedColor ||
    connectBrandingDetails?.branding_color ||
    "#2250A1";
  const accentColor =
    connectBrandingDetails?.primarySavedAccentColor ||
    connectBrandingDetails?.accent_color ||
    "#2A67FF";

  return (
    <Box className={`connect-main-view-box ${customClass}`}>
      {loader && !visibleInConnectPage ? (
        renderFrequentComponent("pageLoader", { loader })
      ) : (
        <DiagonalDiv previewColor={previewColor}>
          {deviceType === "mobile" &&
            renderFrequentComponent("testLiveMode", { liveMode: livemode })}
          <Box
            className="connect-overlay-container"
            sx={visibleInConnectPage && { height: "100%" }}
          >
            <Box className={contentClassName}>
              <ConnectHeader
                connectBrandingDetails={connectBrandingDetails}
                previewColor={previewColor}
                textColor={textColor}
                textFontFamily={textFontFamily}
                currentAccount={currentAccount}
                liveMode={livemode}
                deviceType={deviceType}
              />
              <ConnectCard
                accentColor={accentColor}
                accentTextColor={accentTextColor}
                textFontFamily={textFontFamily}
                commonProps={{ ...props }}
              />
              {visibleInConnectPage && (
                <PaymentPageFooter
                  theme="light"
                  gridPadding="0 !important"
                  fontStyle={{
                    fontFamily: textFontFamily,
                  }}
                  customFontColor="#848B9E !important"
                  paymentPageSettings={null}
                />
              )}
            </Box>
          </Box>
        </DiagonalDiv>
      )}
    </Box>
  );
};

export default RenderConnectPreview;
