import { Box, Grid } from "@mui/material";
import MainLogoLogin from "../Common/MainLogoLogin";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { callAPIInterface, redirectToLogin } from "../constants";
import { Input } from "@speed/common/src/components/Input/Input";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  getCountryObj,
  isAlphaNumeric,
} from "@speed/common/src/components/constants";
import {
  alphaNumName,
  countryLabel,
  countryPlaceholder,
  createAccount,
} from "@speed/common/src/messages";
import {
  accountNameLabel,
  createNewAccount,
  newBusinessPlaceholder,
  signedOutFromAllAccountMsg,
} from "../messages";
import { Countries } from "@speed/common/src/components/country";
import Button from "@speed/common/src/components/Button/Button";
import Text from "@speed/common/src/components/Text/Text";
import { sessionService } from "redux-react-session";
import { setAfterLoginProcess } from "../../redux/auth/actions";

function CreateUserAccount() {
  const { history, isMobile } = useSelector((state) => state.common);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!history?.location?.state?.isOrphanUser) redirectToLogin();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const postObject = {
      name: values.businessName,
      country: values.country,
    };

    const session = await sessionService.loadSession();
    callAPIInterface("POST", "/account", JSON.stringify(postObject))
      .then(() => {
        setAfterLoginProcess(session, dispatch, !session.email, false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const validationSchema = yup.object({
    businessName: yup
      .string()
      .matches(isAlphaNumeric, alphaNumName)
      .required(""),
    country: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      businessName: "",
      country: null,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setTouched,
    isValid,
    dirty,
    isSubmitting,
  } = formik;

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  return (
    <>
      <MainLogoLogin />
      <Box className="container-layout">
        <Grid
          container
          alignItems="center"
          className="common-grid-wrapper common-mobile-grid signin-signup-container-layout"
        >
          <Grid item className="email-password-grid sign-in-grid">
            <Text size={isMobile ? 22 : 30} font="bold" sx={{ width: "100%" }}>
              {createNewAccount}
            </Text>
            <Text
              size={isMobile ? 14 : 18}
              font="regular"
              className="default-text text margin-top10"
              variant="subtitle1"
              sx={{ lineHeight: "140% !important" }}
            >
              {signedOutFromAllAccountMsg}
            </Text>
            <Input
              type="text"
              name="businessName"
              inputProps={{ maxLength: 250 }}
              customClass="margin-top25"
              showLabel
              label={accountNameLabel}
              fullWidth
              placeholder={newBusinessPlaceholder}
              onBlur={() => setTouched({ ...touched, businessName: true })}
              {...getFieldProps("businessName")}
            />
            <InputErrorMessage
              {...defaultErrorMessageProps}
              inputName="businessName"
            />

            <CustomAutoComplete
              name="country"
              value={values.country}
              customClass="create-account-country margin-top30"
              placeholder={countryPlaceholder}
              showLabel
              label={countryLabel}
              options={Countries}
              getOptionLabel={(optionValues) => {
                const country = getCountryObj(
                  "short_name",
                  typeof optionValues === "object"
                    ? optionValues.short_name
                    : optionValues
                );
                return country ? country.short_name : "";
              }}
              onChange={(_e, countyValue) => {
                setFieldValue(
                  "country",
                  countyValue && typeof countyValue === "object"
                    ? countyValue.short_name
                    : countyValue
                );
              }}
            />
            <Button
              loading={loading}
              fullWidth
              className="submit-signin-btn margin-top30"
              label={createAccount}
              iconPosition="end"
              icon="rightArrowIcon"
              type="submit"
              disabled={!(isValid && dirty) || isSubmitting}
              onClick={handleSubmit}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default CreateUserAccount;
