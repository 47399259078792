import Button from "@speed/common/src/components/Button/Button";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import { Box } from "@mui/material";
import { cancel } from "@speed/common/src/components/messages";
import { addCustomerBtnLabel, addNewCustomerModal } from "../messages";
import { createRef, useState } from "react";

import "../../assets/styles/customer.scss";
import CreateCustomer from "../Customer/CreateCustomer";
import { callAPIInterface } from "../constants";

const AddCustomerModal = ({ openModal, setOpenModal, setInvoiceCustomer }) => {
  const ref = createRef();
  const confirmRef = createRef();
  const [disabled, setDisabled] = useState(true);

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const getAccountFormData = () => ref?.current?.handleSubmitAccountData();

  const createNewCustomer = () => {
    const accountFormData = getAccountFormData();
    const billingFormData = confirmRef?.current?.handleSubmit();

    const customerFormData = {
      ...accountFormData,
      ...billingFormData,
    };

    setDisabled(true);
    callAPIInterface("POST", "/customers", JSON.stringify(customerFormData))
      .then((response) => {
        setInvoiceCustomer(response);
        setDisabled(false);
        setOpenModal(false);
      })
      .catch(() => {
        setDisabled(false);
      });
  };

  const renderModalContent = () => (
    <CreateCustomer
      fromInvoiceModal
      ref={ref}
      accountFormData={getAccountFormData}
      confirmRef={confirmRef}
      handleButtonDisable={(value) => setDisabled(value)}
    />
  );

  const renderModalFooter = () => {
    return (
      <Box className="invoice-customer-modal-footer">
        <Button
          variant="outlined"
          type="submit"
          label={cancel}
          onClick={handleModalClose}
          disabled={false}
        />
        <Button
          className="save-btn"
          type="submit"
          label={addCustomerBtnLabel}
          onClick={createNewCustomer}
          disabled={disabled}
        />
      </Box>
    );
  };

  return (
    <Modal
      maxWidth="sm"
      className="invoice-add-customor-modal"
      open={openModal}
      body={renderModalContent()}
      footer={renderModalFooter()}
      handleClose={handleModalClose}
      title={addNewCustomerModal}
    />
  );
};
export default AddCustomerModal;
