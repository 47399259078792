import { Merchant, Wallet } from "@speed/common/src/components/messages";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import { setPathname } from "../redux/common/actions";
import { onlyWalletRoutes } from "../components/constants";

const PrivateRoute = ({
  component: Component,
  isGuideUrl,
  isWalletAllowedRoute,
  ...rest
}) => {
  const session = useSelector((state) => state.session);
  const dispatch = useDispatch();
  const { isLoggedIn, accessToken, idToken, liveMode, currentAccount } =
    useSelector((state) => state.auth);
  const { location } = { ...rest };
  const accountType = currentAccount?.account?.account_type;

  const isAuthenticated =
    session.authenticated &&
    localStorage.getItem("site_auth") === "1" &&
    accessToken &&
    idToken;

  useEffect(() => {
    if ((!isLoggedIn || !isAuthenticated) && location) {
      dispatch(setPathname(location.pathname));
    }
  }, []);

  const renderComponent = (props) => {
    if (accountType === Wallet && !isWalletAllowedRoute) {
      return (
        <Redirect
          to={{
            pathname: "/my-assets",
            state: { from: props.location },
          }}
        />
      );
    } else if (
      (liveMode && isGuideUrl) ||
      (accountType === Merchant && onlyWalletRoutes.includes(location.pathname))
    ) {
      return (
        <Redirect
          to={{
            pathname: "/dashboard",
            state: { from: props.location },
          }}
        />
      );
    } else {
      return <Component {...props} />;
    }
  };

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn || isAuthenticated ? (
          renderComponent(props)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
