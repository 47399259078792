import React from "react";
import { Box, Grid, List, ListItem } from "@mui/material";

const CommonBoxWrapper = ({ children, footer, bottomComponent }) => {
  return (
    <Box
      borderBottom="none"
      className="custom-domain-wrapper payout-schedule-wrapper "
    >
      <Grid
        className="payment-links-wrapper"
        display="flex"
        flexDirection="column"
      >
        <Box width="100%">
          <Box display="flex" justifyContent="space-between" pr="24px">
            <List disablePadding>
              <ListItem disablePadding>{children}</ListItem>
            </List>
            {footer}
          </Box>
        </Box>
        {bottomComponent?.()}
      </Grid>
    </Box>
  );
};

export default CommonBoxWrapper;
