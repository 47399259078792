import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Skeleton } from "@mui/material";

const LogSkeleton = () => {
  return (
    <TableRow key="table-skeleton">
      <TableCell key={1} style={{ padding: "21px 40px", width: "235px" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell key={2} style={{ padding: "21px 40px", width: "1200px" }}>
        <Skeleton animation="wave" />
      </TableCell>
      <TableCell key={3} style={{ padding: "21px 40px", width: "235px" }}>
        <Skeleton animation="wave" />
      </TableCell>
    </TableRow>
  );
};
export default LogSkeleton;
