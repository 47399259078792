import React, { forwardRef } from "react";
import PaymentLinkDisplay from "./PaymentLinkDisplay";
import PaymentLinkForm from "./PaymentLinkForm";
import LinkModalContent from "../Common/LinkModalContent";

const PaymentLinkModal = forwardRef((props, ref) => {
  const {
    handleButtonDisable,
    isPaymentLink,
    confirmRef,
    setDisableCreateButton,
  } = props;

  let rightSectionsProps = {
    confirmPayment: isPaymentLink,
  };

  const formComponent = (
    <PaymentLinkForm
      isPaymentLink={isPaymentLink}
      handleButtonDisable={handleButtonDisable}
      ref={ref}
      linkType="payment link"
    />
  );

  const previewComponent = <PaymentLinkDisplay {...rightSectionsProps} />;
  return (
    <LinkModalContent
      formComponent={formComponent}
      previewComponent={previewComponent}
      linkConfirmationProps={{
        isPaymentLink: isPaymentLink,
        setDisableCreateButton: setDisableCreateButton,
        ref: confirmRef,
      }}
    />
  );
});

export default PaymentLinkModal;
