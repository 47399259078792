import React, { useEffect, useState } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import Text from "@speed/common/src/components/Text/Text";
import Button from "@speed/common/src/components/Button/Button";
import { back, next } from "@speed/common/src/components/messages";
import { send, sendPaymentLabel, withdrawAddressMsg } from "../messages";
import InstantSendForm from "./InstantSendForm";
import {
  setInstantSendButtonClicked,
  setInstantSendNextClicked,
} from "../../redux/common/actions";
import InstantSendFirstStep from "./InstantSendFirstStep";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const InstantSendFormModal = ({ isModalOpen, handleModalClose }) => {
  const dispatch = useDispatch();
  const [payViaOptions, setPayViaOptions] = useState([]);
  const [disablePayViaOption, setDisablePayViaOption] = useState(false);
  const [disableSend, setDisableSend] = useState(false);
  const [decodeResponse, setDecodeResponse] = useState(null);
  const [disableCurrency, setDisableCurrency] = useState(false);
  const [satsAmount, setSatsAmount] = useState(0);
  const [step1Complete, setStep1Complete] = useState(false);
  const [btnLoader, setBtnLoader] = useState(false);
  const [sendBtnLoader, setSendBtnLoader] = useState(false);
  const [amountLimits, setAmountLimits] = useState(null);
  const [isUploadedFromQR, setIsUploadedFromQR] = useState(false);

  const accountAsset = useSelector((state) => state.common.accountAsset);

  const validationSchema = yup.object({
    withdraw_request: yup.mixed().required(withdrawAddressMsg),
  });

  const formik = useFormik({
    initialValues: {
      pay_via: { uuid: "SATS", name: "BTC" },
      withdraw_request: "",
      formatted_request: "",
      currency: null,
      amount: "",
      note: "",
    },
    validationSchema: validationSchema,
  });

  const { isSubmitting, isValid, dirty, resetForm } = formik;

  useEffect(() => {
    const currencyList = accountAsset?.map((assetData) => ({
      name: assetData.name,
      uuid: assetData.uuid,
    }));
    setPayViaOptions(currencyList);
    return () => dispatch(setInstantSendNextClicked(false));
  }, []);

  const handleBack = () => {
    setIsUploadedFromQR(false);
    setStep1Complete(false);
    setDecodeResponse(null);
    setDisableCurrency(false);
    setAmountLimits(null);
    setSendBtnLoader(false);
    resetForm();
  };

  const handleNext = () => dispatch(setInstantSendNextClicked(true));
  const handleSend = () => dispatch(setInstantSendButtonClicked(true));
  const isValidForm = !(isValid && dirty) || isSubmitting;
  const checkForForm = isUploadedFromQR ? false : isValidForm;
  const disabledBtn = checkForForm || (step1Complete && disableSend);

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={isModalOpen}
      TransitionComponent={Transition}
      className="fullscreen-modal"
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1 }}
            className="default-text divider"
            variant="h6"
          >
            {sendPaymentLabel}
          </Text>
          {step1Complete && (
            <Button
              variant="outlined"
              sx={{ mr: "16px" }}
              label={back}
              onClick={handleBack}
            />
          )}
          {step1Complete ? (
            <Button
              label={send}
              loading={sendBtnLoader}
              disabled={disabledBtn}
              onClick={handleSend}
            />
          ) : (
            <Button
              label={next}
              loading={btnLoader}
              disabled={disabledBtn}
              onClick={handleNext}
            />
          )}
        </Toolbar>
      </AppBar>
      {!step1Complete ? (
        <InstantSendFirstStep
          formik={formik}
          payViaOptions={payViaOptions}
          setPayViaOptions={setPayViaOptions}
          setDisablePayViaOption={setDisablePayViaOption}
          setDecodeResponse={setDecodeResponse}
          setDisableCurrency={setDisableCurrency}
          setStep1Complete={setStep1Complete}
          setBtnLoader={setBtnLoader}
          setAmountLimits={setAmountLimits}
          setSatsAmount={setSatsAmount}
          setIsUploadedFromQR={setIsUploadedFromQR}
          handleNext={handleNext}
          btnLoader={btnLoader}
        />
      ) : (
        <InstantSendForm
          formik={formik}
          payViaOptions={payViaOptions}
          disablePayViaOption={disablePayViaOption}
          disableCurrency={disableCurrency}
          amountLimits={amountLimits}
          decodeResponse={decodeResponse}
          satsAmount={satsAmount}
          setSatsAmount={setSatsAmount}
          disableSend={disableSend}
          setDisableSend={setDisableSend}
          setSendBtnLoader={setSendBtnLoader}
          handleModalClose={handleModalClose}
          handleBack={handleBack}
        />
      )}
    </Dialog>
  );
};

export default InstantSendFormModal;
