import { Box } from "@mui/material";
import React, { useRef, useState, useEffect } from "react";
import {
  accentColor,
  appearance,
  brandColor,
  businessDetailsLabel,
  copyBrand,
  invalidLabel,
  uploadBrandIcon,
} from "../../messages";
import * as yup from "yup";
import Text from "@speed/common/src/components/Text/Text";
import { Input } from "@speed/common/src/components/Input/Input";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import { useFormik } from "formik";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import {
  convertToSixDigitHex,
  fontFamilySection,
  idealTimeLimitReached,
  updateSessionLastActionTime,
  uploadColorSection,
  uploadSection,
} from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import {
  setConfirmAction,
  setConnectBranding,
  setExecuteAPICall,
  setLoading,
  setSettingFormCancel,
  setSettingFormChange,
  setSettingFormSubmit,
  setSettingFormValid,
  updateConnectBrandingInfo,
} from "../../../redux/common/actions";
import { validateColor } from "@speed/common/src/components/constants";
import { sessionService } from "redux-react-session";
import {
  businessNameLabel,
  whiteSpacePattern,
} from "@speed/common/src/messages";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";

const ConnectBrandingForm = () => {
  const dispatch = useDispatch();

  const [iconLoader, setIconLoader] = useState(false);
  const [formData, setFormData] = useState({});
  const [session, setSession] = useState(null);

  const {
    settingFormSubmit,
    settingFormCancel,
    brandingPageUI,
    executeAPICall,
    platformConnectBranding,
  } = useSelector((state) => state.common);

  const primaryThemeColor = "#2250A1";
  const secondaryThemeColor = "#2A67FF";

  const [brandUIPrimaryColors, setBrandUIPrimaryColors] = useState({
    selectedColor: "",
    savedColor: primaryThemeColor,
  });
  const [intervalValue, setIntervalValue] = useState(false);
  const [selectedSecondaryColor, setSelectedSecondaryColor] = useState({
    selectedColor: "",
    savedColor: secondaryThemeColor,
  });

  const currentSecondaryColor = useRef();
  currentSecondaryColor.current = selectedSecondaryColor;
  const currentBrandUIPrimaryColors = useRef();
  currentBrandUIPrimaryColors.current = brandUIPrimaryColors;

  const initialValues = {
    business_name: platformConnectBranding?.business_name || "",
    is_use_account_branding:
      platformConnectBranding?.is_use_account_branding || false,
    icons: platformConnectBranding?.icons || null,
    branding_color: platformConnectBranding?.branding_color || "#2250A1",
    accent_color: platformConnectBranding?.accent_color || "#2A67FF",
    font_family: platformConnectBranding?.font_family || "system-ui",
  };

  const validationSchema = yup.object({
    branding_color: yup.mixed().test({
      test: (branding_color) => validateColor(branding_color),
    }),
    accent_color: yup.mixed().test({
      test: (accent_color) => validateColor(accent_color),
    }),
    business_name: yup
      .string()
      .required("")
      .matches(whiteSpacePattern, invalidLabel("business name"))
      .trim(),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    isSubmitting,
    isValid,
    dirty,
    setFieldValue,
    setTouched,
    handleChange,
    resetForm,
  } = formik;

  const onChangeFormField = (fieldname, value) => {
    setFieldValue(fieldname, value);
    formData[fieldname] = value;
    setFormData(formData);
  };

  useEffect(() => {
    if (settingFormCancel) {
      setFormData({});
      resetForm();
      dispatch(setSettingFormCancel(false));
    }
  }, [settingFormCancel]);

  useEffect(() => {
    dispatch(setSettingFormValid(!(isValid && dirty) || isSubmitting));
  }, [isValid, dirty, isSubmitting]);

  useEffect(() => {
    dispatch(setSettingFormChange(dirty));

    const params = { ...values };
    if (values.is_use_account_branding) {
      params.branding_color = brandingPageUI?.primary_color;
      params.icons = brandingPageUI?.icons;
      params.font_family = brandingPageUI?.checkout_font_family;
    } else {
      params.branding_color = values.branding_color;
      params.icons = values.icons;
      params.font_family = values.font_family;
    }

    validateColor(params.branding_color) &&
      validateColor(params.accent_color) &&
      dispatch(setConnectBranding(params));
  }, [values]);

  useEffect(() => {
    sessionService.loadSession().then((newSession) => {
      setSession(newSession);
    });
  }, []);

  const updateConnectBrandingDetails = () => {
    if (values.branding_color && values.branding_color.length === 4) {
      let sixDigitHexColor = convertToSixDigitHex(values.branding_color);
      values.branding_color = sixDigitHexColor;
    }

    if (values.accent_color && values.accent_color.length === 4) {
      let sixDigitHexColor = convertToSixDigitHex(values.accent_color);
      values.accent_color = sixDigitHexColor;
    }

    let postFormObj = {
      ...values,
      business_name: values.business_name.trim(),
      is_use_account_branding: values.is_use_account_branding,
    };
    if (values.is_use_account_branding) {
      postFormObj.branding_color = brandingPageUI?.primary_color;
      postFormObj.icons = brandingPageUI?.icons;
      postFormObj.font_family = brandingPageUI?.checkout_font_family;
    }
    values.business_name = postFormObj.business_name;

    if ("icons" in values) {
      postFormObj.icon = postFormObj.icons ? postFormObj.icons.original : null;
      delete postFormObj.icons;
    }

    dispatch(updateConnectBrandingInfo(postFormObj)).then(() => {
      const newSession = updateSessionLastActionTime();
      setSession(newSession);
      setFormData({});
      dispatch(setLoading(false));
      dispatch(setConfirmAction(false));
    });
  };

  useEffect(() => {
    if (executeAPICall) {
      // API call to save branding details of connect
      updateConnectBrandingDetails();
      dispatch(setExecuteAPICall(false));
    }
  }, [executeAPICall]);

  useEffect(() => {
    // get values and call action to update this change into DB
    if (
      Object.keys(formData).length != 0 &&
      isValid &&
      dirty &&
      settingFormSubmit
    ) {
      if (idealTimeLimitReached(session.last_action_time)) {
        dispatch(setSettingFormSubmit(false));
        dispatch(setConfirmAction(true));
      } else {
        // API call to save branding details of connect
        updateConnectBrandingDetails();
      }
    }
  }, [settingFormSubmit]);

  const copyBrandLabel = (
    <Text size={16} font="medium" variant="subtitle1" className="default-text">
      {copyBrand[0]}
      <CustomLink size="large">{copyBrand[1]}</CustomLink>
    </Text>
  );

  const heading = (label) => {
    return (
      <Text
        size={18}
        className="default-text"
        variant="subtitle1"
        font="semibold"
        sx={{ lineHeight: "120%" }}
      >
        {label}
      </Text>
    );
  };

  const getSelectedColorCallback = (brandingValues) => {
    const { checkForColor } = brandingValues;
    const colorObj = {
      ...values,
      primarySavedColor: currentBrandUIPrimaryColors?.current?.savedColor,
      primarySavedAccentColor: currentSecondaryColor?.current?.savedColor,
    };
    checkForColor && dispatch(setConnectBranding(colorObj));
  };

  return (
    <Box className="connect-branding-box">
      {heading(businessDetailsLabel)}
      <Input
        showLabel={true}
        label={businessNameLabel}
        customClass="margin-top30 custom-label-style"
        fullWidth={true}
        name="business_name"
        value={values.business_name}
        placeholder={businessNameLabel}
        onBlur={() => setTouched({ ...touched, business_name: true })}
        inputProps={{ maxLength: 250 }}
        onChange={(e) => {
          setTouched({ ...touched, business_name: false });
          setFieldValue("business_name", e.target.value);
          onChangeFormField("business_name", e.target.value);
        }}
        error={touched.business_name && Boolean(errors.business_name)}
      />
      {Boolean(errors.business_name) && (
        <AlertMessage
          message={errors.business_name}
          className="margin-top15"
          severity="error"
          sx={{
            margin: "auto",
          }}
        />
      )}
      <CustomCheckbox
        checked={values.is_use_account_branding}
        name="is_use_account_branding"
        label={copyBrandLabel}
        className="label-formatting"
        onChange={handleChange}
        sx={{ marginTop: "24px", mb: "40px" }}
      />
      {heading(appearance)}
      {uploadSection({
        id: "icon_upload",
        uploadType: "icons",
        isLoading: iconLoader,
        setLoader: setIconLoader,
        labelText: uploadBrandIcon,
        onChangeFormField,
        values,
      })}
      {fontFamilySection({
        values,
        onChangeFormField,
        fieldName: "font_family",
      })}
      {uploadColorSection({
        label: brandColor,
        colorType: "branding_color",
        defaultThemeColor: primaryThemeColor,
        currentBrandUIColors: currentBrandUIPrimaryColors,
        setBrandUIColors: setBrandUIPrimaryColors,
        intervalValue,
        onChangeFormField,
        setIntervalValue,
        values,
        from: "connect",
        getSelectedColorCallback,
      })}

      {uploadColorSection({
        label: accentColor,
        colorType: "accent_color",
        defaultThemeColor: secondaryThemeColor,
        currentBrandUIColors: currentSecondaryColor,
        setBrandUIColors: setSelectedSecondaryColor,
        intervalValue,
        onChangeFormField,
        setIntervalValue,
        values,
        from: "connect",
        getSelectedColorCallback,
      })}
    </Box>
  );
};

export default ConnectBrandingForm;
