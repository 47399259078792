import React from "react";
import { Box } from "@mui/material";
import { tickGif } from "../../images";
import Text from "../../Text/Text";
import { accountConnected } from "../../../messages";

const SuccessConnect = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      marginTop="32px"
      height="444px"
      width="377px"
    >
      <img
        src={tickGif}
        className="action-img"
        alt="success"
        height="94px"
        width="94px"
      />
      <Text
        size={20}
        align="center"
        font="medium"
        className="default-text margin-top30"
      >
        {accountConnected}
      </Text>
    </Box>
  );
};

export default SuccessConnect;
