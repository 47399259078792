import React, { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { TableCell, TableRow, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import {
  generateBreadcrumbs,
  integerWithCurrency,
} from "@speed/common/src/components/constants";
import CustomTable from "@speed/common/src/components/Table/Table";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { emptySubText } from "@speed/common/src/components/messages";
import { emptyTransfers } from "@speed/common/src/components/images";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  noOfRecords,
  RouteBreadcrumbs,
} from "../constants";

const MerchantTransfers = (props) => {
  const queryParam = "?limit=" + noOfRecords;
  const { aflListoftransferlnsFe } = useFlags();
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const urlParams = useParams();
  const [rows, setRows] = useState([]);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [queryParams, setQueryParams] = useState(queryParam);
  const history = useSelector((state) => state.common.history);
  const liveMode = useSelector((state) => state.auth.liveMode);

  const fetchTransfers = () => {
    setRows([]);
    setHasMore(true);
    getTransfers([], queryParam);
  };

  const loadMore = useCallback(() => {
    getTransfers(rows, queryParams);
  }, [rows]);

  const getTransfers = (lines, params) => {
    setTableRowSkeleton(true);
    callAPIInterface(
      "GET",
      `/affiliate-partner/${urlParams?.id}/transfers` + params,
      {}
    )
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam + "&ending_before=" + res.data[res.data.length - 1].id
            );
          }
          setRows(lines.concat(res.data));
        }
      })
      .catch(() => {
        setTableRowSkeleton(false);
      });
  };

  useEffect(
    () => aflListoftransferlnsFe && fetchTransfers(),
    [aflListoftransferlnsFe, liveMode]
  );

  const columns = [
    { title: "Transfer ID", width: "410px", paddingLeft: "38px !important" },
    { title: "Amount", width: "530px", align: "right" },
    { title: "Date", width: "225px" },
  ];

  let columnsData = columns?.map((column) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{ paddingLeft: column.paddingLeft, minWidth: column.width }}
    >
      {column.title}
    </TableCell>
  ));

  const rowsData = rows?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/transfers/${rowItem?.id}`)}
      >
        <TableCell sx={{ paddingLeft: "38px !important" }}>
          {rowItem?.id}
        </TableCell>
        <TableCell>
          <Box display="flex" justifyContent="end" gap={0.6}>
            {integerWithCurrency(rowItem?.currency, rowItem?.target_amount)}
          </Box>
        </TableCell>
        <TableCell>{dateTimeFormatInApp(rowItem?.created)}</TableCell>
      </TableRow>
    );
  });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    textOnNoData: emptySubText(liveMode, "transfers"),
    noDataImage: emptyTransfers,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
  };

  return (
    <Box className="section-wrapper">
      <Box className="payment-link-header-wrapper">
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      </Box>
      <CustomDivider />
      <Box className="payments-wrapper">
        <Box className="section-wrapper">
          <Box className="payment-wrapper">
            <Box className="main-content">
              {aflListoftransferlnsFe ? (
                <CustomTable {...tableProps} />
              ) : (
                <AccessDenied />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default MerchantTransfers;
