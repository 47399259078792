import PropTypes from 'prop-types';
import * as React from 'react';
import Stack from '@mui/material/Stack';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import './button-toggle.scss'
import LaptopIcon from '@mui/icons-material/Laptop';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';

export const ButtonToggle = ({ value, content, ...props }) => {
    return (
        <Stack direction="row" spacing={4} className={props.stackclass || ""} >
            <ToggleButtonGroup
                value={value}
                exclusive
                {...props}
                className="button-toggle"
            >
                {content.length > 0 && content.map((item, index) => {
                    return (
                        <ToggleButton key={index} value={item.value} aria-label={item.value}>
                            {item.children}
                        </ToggleButton>
                    )
                })}
            </ToggleButtonGroup>
        </Stack >
    );
};

export const propTypes = {
    value: PropTypes.string,
    content: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    className: PropTypes.string
};

const defaultProps = {
    content: [
        { value: "laptop", children: <LaptopIcon /> },
        { value: "phone", children: <PhoneAndroidIcon /> }
    ]
};

ButtonToggle.propTypes = propTypes;
ButtonToggle.defaultProps = defaultProps;
