import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import React, { useState, createRef, useEffect } from "react";
import Text from "@speed/common/src/components/Text/Text";
import { Button } from "@speed/common/src/components/Button/Button";
import {
  createCheckoutLinkMsg,
  testModeTitle,
  testModeTextForCheckout,
  createNew,
} from "../messages";
import { callAPIInterface } from "../constants";
import { useSelector, useDispatch } from "react-redux";
import "../../assets/styles/payment-links.scss";
import TestModeHeader from "../Payments/TestModeHeader";
import {
  setIsModalOpen,
  setPaymentConfirmationOption,
  setPaymentRedirectWebPreview,
  updatePaymentInfo,
  updatePaymentMessage,
} from "../../redux/common/actions";
import CheckoutLinkTable from "../Common/CheckoutLinkTable";
import { back, next } from "@speed/common/src/components/messages";
import { useFlags } from "launchdarkly-react-client-sdk";
import CheckoutLinkModal from "./CheckoutLinkModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//Checkout link table
const LinksList = (props) => {
  const ref = createRef();
  const confirmRef = createRef();
  const [disabled, setDisabled] = useState(true);
  const [isPaymentLink, setIsPaymentLink] = useState(false);
  const [formDataValue, setFormDataValue] = useState(null);
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [disableBackButton, setDisableBackButton] = useState(false);
  const dispatch = useDispatch();

  const liveMode = useSelector((state) => state.auth.liveMode);
  const {
    paymentConfirmationOption,
    history,
    isModalOpen,
    isDataAvailable,
    caughtErrorInStrapi,
  } = useSelector((state) => state.common);

  const { clConfirmlink } = useFlags();
  useEffect(() => {
    if (!clConfirmlink) {
      setIsPaymentLink(false);
      dispatch(setPaymentRedirectWebPreview(false));
    }
  }, [clConfirmlink]);

  const handleNext = () => {
    setFormDataValue(ref.current && ref.current.handleSubmit());
    setIsPaymentLink(true);
    if (paymentConfirmationOption == "redirect") {
      dispatch(setPaymentRedirectWebPreview(true));
    }
  };

  const handleModalClose = () => {
    dispatch(setIsModalOpen(false));
    setIsPaymentLink(false);
    dispatch(updatePaymentMessage(""));
    dispatch(setPaymentRedirectWebPreview(false));
    dispatch(setPaymentConfirmationOption("confirmation"));
  };

  const handleBackButton = () => {
    setIsPaymentLink(false);
    setDisabled(false);
    dispatch(setPaymentRedirectWebPreview(false));
  };

  const handleSubmit = () => {
    // return the values of form while submitting the form
    const confirmData =
      confirmRef.current && confirmRef.current.handleSubmitConfirm();
    const checkoutLinkFormData = ref.current && ref.current.handleSubmit();
    let checkoutLinkData = null;

    if (clConfirmlink) {
      checkoutLinkData = {
        ...formDataValue,
        ...confirmData,
      };
    } else {
      checkoutLinkData = {
        ...checkoutLinkFormData,
      };
    }

    if (checkoutLinkData) {
      if (clConfirmlink) {
        setDisableCreateButton(true);
        setDisableBackButton(true);
      } else setDisabled(true);
      callAPIInterface(
        "POST",
        "/checkout-links",
        JSON.stringify(checkoutLinkData)
      )
        .then((response) => {
          response && history.push(`/checkout-links/${response.id}`);
          dispatch(setPaymentConfirmationOption("confirmation"));
          dispatch(setPaymentRedirectWebPreview(false));
          dispatch(updatePaymentMessage(""));
          dispatch(updatePaymentInfo(null));
        })
        .catch((_error) => {
          if (clConfirmlink) {
            setDisableCreateButton(false);
            setDisableBackButton(false);
          } else setDisabled(false);
        });
    }
  };

  const renderCreateConfirmButtons = () => {
    return !isPaymentLink ? (
      <Button
        label={next}
        variant="contained"
        color="primary"
        className="payment-link-btn"
        disabled={disabled}
        onClick={handleNext}
      />
    ) : (
      <React.Fragment>
        <Button
          variant="outlined"
          color="primary"
          label={back}
          onClick={handleBackButton}
          sx={{ marginRight: "18px" }}
          disabled={disableBackButton}
        ></Button>
        <Button
          label={createCheckoutLinkMsg}
          variant="contained"
          color="primary"
          className="payment-link-btn"
          onClick={handleSubmit}
          disabled={disableCreateButton}
        />
      </React.Fragment>
    );
  };

  const renderCreateLinkButton = () => {
    return (
      <Button
        label={createCheckoutLinkMsg}
        variant="contained"
        color="primary"
        className="payment-link-btn"
        onClick={handleSubmit}
        disabled={disabled}
      />
    );
  };

  const createNewLinkButton = () => {
    return (
      <Box className="action-btn-wrapper mui-fixed">
        <Button
          icon="addIcon"
          className={`add-icon`}
          label={createNew}
          variant="outlined"
          color="primary"
          onClick={() => {
            dispatch(setIsModalOpen(true));
          }}
        />
      </Box>
    );
  };
  return (
    <>
      {isDataAvailable
        ? createNewLinkButton()
        : ((!isDataAvailable && props.tab) || caughtErrorInStrapi) &&
          createNewLinkButton()}

      <CheckoutLinkTable tab={props.tab} />

      <Dialog
        disableEscapeKeyDown
        fullScreen
        open={isModalOpen}
        TransitionComponent={Transition}
        className="fullscreen-modal"
      >
        {!liveMode && (
          <TestModeHeader
            buttonText={testModeTitle}
            captionText={testModeTextForCheckout}
          />
        )}
        <AppBar sx={{ position: "relative" }} className="modal-app-bar">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => {
                handleModalClose();
              }}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Text
              size={20}
              font="semibold"
              sx={{ flex: 1 }}
              className="default-text divider"
              variant="h6"
            >
              {createCheckoutLinkMsg}
            </Text>
            {clConfirmlink
              ? renderCreateConfirmButtons()
              : renderCreateLinkButton()}
          </Toolbar>
        </AppBar>
        <Box className="fullscreen-modal-box">
          <CheckoutLinkModal
            ref={ref}
            confirmRef={confirmRef}
            handleButtonDisable={(value) => setDisabled(value)}
            isPaymentLink={isPaymentLink}
            setDisableCreateButton={setDisableCreateButton}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default LinksList;
