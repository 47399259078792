import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import CommonHeader from "../Common/CommonHeader";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import {
  selectTabOnSearch,
  setQueryParamsForTab,
} from "@speed/common/src/components/constants";
import InstantSendList from "./InstantSendList";
import { callStrapiAPIInterface } from "../constants";
import {
  setCaughtErrorInStrapi,
  setIsDataAvailable,
  setIsModalOpen,
} from "../../redux/common/actions";
import GetStartedHeader from "@speed/common/src/components/GetStartedHeader/GetStartedHeader";

const tabData = [
  {
    label: "All",
    value: "1",
    tabContent: <InstantSendList />,
    tabName: "all",
  },
  {
    label: "Paid",
    value: "2",
    tabContent: <InstantSendList tab="paid" />,
    tabName: "paid",
  },
  {
    label: "Unpaid",
    value: "3",
    tabContent: <InstantSendList tab="unpaid" />,
    tabName: "unpaid",
  },
  {
    label: "Failed",
    value: "4",
    tabContent: <InstantSendList tab="failed" />,
    tabName: "failed",
  },
];

const InstantSend = () => {
  const search = useLocation().search;
  const dispatch = useDispatch();
  const { history, isDataAvailable, caughtErrorInStrapi, isModalOpen } =
    useSelector((state) => state.common);
  const liveMode = useSelector((state) => state.auth.liveMode);

  const [selectedTab, setSelectedTab] = useState("1");
  const [instantSendGuide, setInstantSendGuide] = useState(null);
  const [isHeaderLoading, setIsHeaderLoading] = useState(true);

  const getInstantSendGuideData = async () => {
    setIsHeaderLoading(true);
    try {
      const response = await callStrapiAPIInterface(
        "GET",
        `/api/instant-send-guide?populate[header][populate][use_cases][populate]=*`
      );
      setInstantSendGuide(response?.data?.attributes?.header);
    } catch (e) {
      dispatch(setCaughtErrorInStrapi(true));
    }
    setIsHeaderLoading(false);
  };

  useEffect(() => {
    dispatch(setIsDataAvailable(true));
    getInstantSendGuideData();
    return () => {
      dispatch(setIsModalOpen(false));
      dispatch(setCaughtErrorInStrapi(false));
    };
  }, [liveMode]);

  const handleChange = (_event, newValue) => {
    dispatch(setIsDataAvailable(true));
    setSelectedTab(newValue);
    const selectedTabName = setQueryParamsForTab(tabData, newValue);
    handleSetQueryParam(selectedTabName);
  };

  const handleSetQueryParam = (searchValue) => {
    history.push({
      pathname: "/instant-sends",
      search: searchValue ? `?status=${searchValue}` : null,
    });
  };

  useEffect(() => {
    // used when user searches manually
    const name = new URLSearchParams(search).get("status");
    selectTabOnSearch(name || null, tabData, setSelectedTab);
    handleSetQueryParam(name || null); // To set query params
  }, []);

  const strapiHeaderCondition =
    !isDataAvailable &&
    !caughtErrorInStrapi &&
    !isModalOpen &&
    selectedTab === "1";

  return (
    <Box
      className="section-wrapper"
      sx={{ background: strapiHeaderCondition && "#f7fafc" }}
    >
      {strapiHeaderCondition ? (
        <GetStartedHeader
          getHeaderInfo={instantSendGuide}
          loading={isHeaderLoading}
          setIsModalOpen={setIsModalOpen}
        />
      ) : (
        <>
          <CommonHeader />
          <Box className="payments-wrapper">
            <CustomTab
              onChange={handleChange}
              tabData={tabData}
              selectedTab={selectedTab}
              noScroll={true}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default InstantSend;
