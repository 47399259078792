import React from "react";
import GetGuides from "./GetGuides";

const SwapsGuide = () => {
  return (
    <GetGuides
      moduleName="swaps"
      redirectUrl="/swaps"
      guideApiPath="swaps-guide"
      getStartedApiPath="Swaps"
      needHelpGuidePath="docs/after-the-payment/swaps"
    />
  );
};

export default SwapsGuide;
