import React, { useEffect } from "react";
import { HmacSHA256, enc } from "crypto-js";

function Auth() {
  // let clientId = 'c339ceb52389548f3a7919e5dc73a1e3';
  // let appSecretKey = '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';

  const clientId = "7c4b887ff341a7d99206a7dd05ab1036";
  const appSecretKey = "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";

  useEffect(() => {
    const currentAuthUrl = window.location.href;
    const splitUrl = currentAuthUrl?.split("?");
    const queryParams = splitUrl?.[1]?.split("&");
    let queryObj = {};
    let messageURL;

    queryParams?.map((params, index) => {
      const obj = params.split("=");
      if (index == 0 && obj[0] === "code") {
        queryObj["code"] = obj[1];
        messageURL = params;
      } else {
        queryObj[obj[0]] = obj[1];
        if (obj[0] !== "hmac") {
          messageURL = messageURL + "&" + params;
        }
      }
    });

    if (queryObj.code) {
      const wordArrayObj = HmacSHA256(messageURL, appSecretKey);
      const generatedHash = wordArrayObj?.toString(enc.Hex);
      if (queryObj.hmac === generatedHash) {
        const requestOptions = {
          method: "POST",
          body: JSON.stringify({
            client_id: clientId,
            client_secret: appSecretKey,
            code: queryObj.code,
          }),
        };
        const apiURL = `https://` + queryObj.shop + `/admin/oauth/access_token`;
        fetch(apiURL, requestOptions)
          .then((data) => console.log("Accecc Token::", data))
          .catch((err) => {
            console.log(
              "ERROR while fetching access token from shopify...",
              err
            );
          });
      }
    } else {
      const redirectURL =
        `https://` +
        queryObj.shop +
        `/admin/oauth/authorize?client_id=` +
        clientId +
        `&scope=write_payment_gateways,read_payment_gateways,read_payment_sessions,write_payment_sessions&redirect_uri=https://development.decentpay.co/auth/callback`;
      window.location.href = redirectURL;
    }
  }, []);

  return <div></div>;
}
export default Auth;
