import { useState, useEffect, useCallback } from "react";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  TableCell,
  TableRow,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useFlags } from "launchdarkly-react-client-sdk";
import classNames from "classnames";
import { useLocation } from "react-router";
import {
  noOfRecords,
  callAPIInterface,
  payoutWalletCurrencies,
  emailVerificationContent,
  confirmationModalContent,
  confirmationModalFooter,
  linkNotFoundContent,
} from "../../constants";
import {
  payoutWalletDescription,
  addNewWallet,
  payoutWalletDeactivation,
  remove,
  resendEmail,
  removePayoutWallet,
  walletVerficationEmailTitle,
  walletVerficationTitle,
  activate,
  deActivate,
  linkNotFoundTitle,
  alreadyVerified,
} from "../../messages";

import Button from "@speed/common/src/components/Button/Button";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import {
  clipboardElement,
  linkStatus,
} from "@speed/common/src/components/constants";
import { showToast } from "../../../redux/common/actions";
import { emptyPayoutWallets } from "../../images";
import PayoutWalletFormModal from "./PayoutWalletFormModal";
import DeactivatePayoutModal from "./DeactivatePayoutModal";
import CustomTable from "@speed/common/src/components/Table/Table";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import { emptySubText } from "@speed/common/src/components/messages";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { loaderSpinner } from "@speed/common/src/components/images";
import { Modal } from "@speed/common/src/components/Modal/Modal";

const tableHeader = [
  { title: "Wallet Name", width: "350px", paddingLeft: "38px !important" },
  { title: "Type", width: "300px" },
  { title: "Address", width: "350px" },
  { title: "Status", width: "200px" },
  { title: "Actions", width: "25px" },
];

const PayoutWalletList = (props) => {
  const queryParam = "?limit=" + noOfRecords;
  const dispatch = useDispatch();
  const state = useLocation().state;

  const liveMode = useSelector((state) => state.auth.liveMode);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [rows, setRows] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [rowId, setRowId] = useState(null);
  const [rowLoader, setRowLoader] = useState(false);
  const [onActivateDeactivateRows, setOnActivateDeactivateRows] = useState([]);
  const [columns, setColumns] = useState(tableHeader);
  const [isResendingEmail, setIsResendingEmail] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [linkNotFound, setLinkNotFound] = useState(false);
  const [resendFlag, setResendFlag] = useState(false);
  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [isDeactivateModalOpen, setIsDeactivateModalOpen] = useState(false);
  const [isAlreadyVerified, setIsAlreadyVerified] = useState(false);

  const { poListpayoutwallet, poCreatepayoutwallet, poManagepayoutwallet } =
    useFlags();

  useEffect(() => fetchWallets(), [liveMode]);

  useEffect(() => {
    if (state?.isVerified) setIsEmailVerified(state.isVerified);
    if (state?.showLinkExpiredModal)
      setLinkNotFound(state.showLinkExpiredModal);
    if (state?.alreadyVerified) setIsAlreadyVerified(state.alreadyVerified);
  }, [state]);

  useEffect(() => {
    if (poManagepayoutwallet) {
      setColumns(tableHeader);
    } else {
      const newColumns = tableHeader.filter(
        (column) => column.title !== "Actions"
      );
      setColumns(newColumns);
    }
  }, [poManagepayoutwallet]);

  useEffect(() => {
    setRows(onActivateDeactivateRows);
  }, [onActivateDeactivateRows]);

  const getPayoutWallets = async (lines, params) => {
    setTableRowSkeleton(true);

    let method,
      data,
      path = "";
    if (props?.tab) {
      method = "POST";
      data = { status: props?.tab };
      path = "/payout-wallets/filter";
    } else {
      method = "GET";
      data = {};
      path = "/payout-wallets";
    }

    await callAPIInterface(method, path + params, data)
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam + "&ending_before=" + res.data[res.data.length - 1].id
            );
          }
          setRows(lines.concat(res.data));
        }
      })
      .catch(() => {
        setTableRowSkeleton(false);
      });
  };

  const fetchWallets = () => {
    setRows([]);
    setHasMore(true);
    getPayoutWallets([], queryParam);
  };

  const loadMore = useCallback(() => {
    getPayoutWallets(rows, queryParams);
  }, [rows]);

  const handleClick = (event, rowData) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setCurrentRow(rowData);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const activateDeactivatePayoutWallet = (id, method) => {
    return new Promise((resolve, reject) => {
      callAPIInterface("POST", `/payout-wallets/${id}/${method}`, {})
        .then((response) => {
          resolve(response);
        })
        .catch((_error) => {
          reject(_error);
        });
    });
  };

  const handleActiveDeActive = () => {
    setRowId(currentRow?.id);
    setRowLoader(true);
    const method =
      currentRow?.status === linkStatus.verified.value
        ? "deactivate"
        : "activate";
    activateDeactivatePayoutWallet(currentRow?.id, method)
      .then((res) => {
        setAnchorEl(null);
        rows.map((row) => {
          if (row.id === currentRow?.id) {
            row.status = res.status;
          }
        });
        const result = props?.tab
          ? rows.filter((row) => row.id !== currentRow?.id)
          : rows;
        setOnActivateDeactivateRows(result);
        setRowLoader(false);
      })
      .catch((error) => {
        setRowLoader(false);
        const errors = error?.response?.data?.errors;
        const errorMsg = errors?.[0]?.message.toLowerCase();
        const errorType = errors?.[0]?.type.toLowerCase();
        if (errorType === "invalid_request_error") {
          if (errorMsg.includes("automatic")) {
            setIsDeactivateModalOpen(true);
            dispatch(
              showToast({
                isToastOpen: false,
                toastVariant: "error",
              })
            );
          } else {
            dispatch(
              showToast({
                isToastOpen: true,
                toastMessage: payoutWalletDeactivation,
                toastVariant: "error",
                toastAutoHideDuration: 7000,
              })
            );
          }
        }
      });
  };

  let columnsData = columns.map((column, index) => (
    <TableCell
      key={index}
      align={column.align}
      sx={{
        minWidth: column.width,
        height: "50px",
        paddingX: "21px !important",
        paddingLeft: column.paddingLeft,
      }}
    >
      {column.title}
    </TableCell>
  ));

  let rowsData = rows?.map((rowItem) => {
    const commonPadding = { paddingX: "21px !important" };
    return (
      <TableRow key={rowItem.id}>
        {rowItem.id === rowId && rowLoader ? (
          columns.map((column) => {
            return (
              <TableCell
                key={column.title}
                sx={{
                  minWidth: column.width,
                  padding: "10px 21px !important",
                  height: "50px",
                  paddingLeft: column.paddingLeft,
                }}
              >
                <Skeleton />
              </TableCell>
            );
          })
        ) : (
          <>
            <TableCell sx={(commonPadding, { paddingLeft: "38px !important" })}>
              <TruncatedTextTooltip
                textValue={rowItem.name}
                cellWidth="450px"
              />
            </TableCell>
            <TableCell sx={({ height: "50px" }, commonPadding)}>
              {payoutWalletCurrencies?.find(
                (currency) => currency.value === rowItem?.type
              )?.name || ""}
            </TableCell>
            <TableCell sx={commonPadding}>
              {clipboardElement(
                rowItem?.address,
                "active",
                "",
                "inputBox",
                false
              )}
            </TableCell>
            <TableCell sx={commonPadding}>
              <Tag
                text={linkStatus[rowItem?.status]?.label}
                variant={linkStatus[rowItem?.status]?.variant}
              />
            </TableCell>
            {poManagepayoutwallet && (
              <TableCell sx={commonPadding}>
                <MoreHorizIcon
                  className="horizontal-dots-icon pointer-cursor"
                  tabIndex={0}
                  onClick={(event) => {
                    handleClick(event, rowItem);
                  }}
                />
              </TableCell>
            )}
          </>
        )}
      </TableRow>
    );
  });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    textOnNoData: emptySubText(liveMode, "wallet address", props.tab),
    subTextOnNoData: payoutWalletDescription,
    noDataImage: emptyPayoutWallets,
    createButtonText: poCreatepayoutwallet && !props.tab && addNewWallet,
    handleCreate: openModal,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
  };

  const handleRemovePayoutWallet = () => {
    setRowId(currentRow?.id);
    setRowLoader(true);
    callAPIInterface("DELETE", `/payout-wallets/${currentRow?.id}`, {})
      .then(() => {
        const payoutWalletsList = rows;
        const result = payoutWalletsList.filter((row) => {
          return row?.id !== currentRow?.id;
        });
        setRows(result);
        setOpenConfirmModal(false);
        setRowLoader(false);
      })
      .catch(() => {
        setRowLoader(false);
      });
  };

  const handleResendEmail = () => {
    setIsResendingEmail(true);
    callAPIInterface(
      "POST",
      `/payout-wallets/${currentRow?.id}/resend-email`,
      {}
    )
      .then(() => {
        setResendFlag(true);
      })
      .finally(() => {
        setIsResendingEmail(false);
      });
  };

  return (
    <>
      <Box className="action-btn-wrapper mui-fixed">
        {poCreatepayoutwallet && (
          <Button
            icon="addIcon"
            className="add-icon"
            label={addNewWallet}
            variant="outlined"
            color="primary"
            onClick={openModal}
          />
        )}
      </Box>
      <Box className="main-content">
        {poListpayoutwallet ? (
          <CustomTable {...tableProps} />
        ) : (
          <AccessDenied />
        )}
      </Box>
      <CustomPopper
        disablePortal={true}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        position="bottom"
        handleClose={() => setAnchorEl(null)}
      >
        {currentRow?.status === "unverified" ? (
          <List>
            <ListItem disablePadding>
              <ListItemButton
                onClick={handleResendEmail}
                sx={{ gap: 1 }}
                disabled={isResendingEmail}
              >
                <ListItemText primary={resendEmail} />
                {isResendingEmail && (
                  <img
                    src={loaderSpinner}
                    alt="Loader"
                    width={15}
                    height={15}
                  />
                )}
              </ListItemButton>
            </ListItem>

            <ListItem disablePadding>
              <ListItemButton
                onClick={() => setOpenConfirmModal(true)}
                disabled={isResendingEmail}
              >
                <ListItemText primary={remove} className="text-danger" />
              </ListItemButton>
            </ListItem>
          </List>
        ) : (
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={handleActiveDeActive}>
                <ListItemText
                  primary={
                    currentRow?.status === linkStatus.verified.value
                      ? deActivate
                      : activate
                  }
                  className={classNames(
                    currentRow?.status === linkStatus.verified.value &&
                      "text-danger"
                  )}
                />
              </ListItemButton>
            </ListItem>
          </List>
        )}
      </CustomPopper>
      <PayoutWalletFormModal
        closeModal={closeModal}
        isModalOpen={isModalOpen}
        fetchWallets={fetchWallets}
      />
      <Modal
        maxWidth="xs"
        body={confirmationModalContent}
        footer={confirmationModalFooter(
          setOpenConfirmModal,
          handleRemovePayoutWallet
        )}
        handleClose={() => setOpenConfirmModal(false)}
        open={openConfirmModal}
        title={removePayoutWallet}
      />
      {(isEmailVerified || resendFlag) && (
        <Modal
          maxWidth="xs"
          body={emailVerificationContent(isEmailVerified)}
          handleClose={() => {
            if (isEmailVerified) {
              setIsEmailVerified(false);
            } else {
              setResendFlag(false);
            }
          }}
          open={isEmailVerified || resendFlag}
          title={
            isEmailVerified
              ? walletVerficationTitle
              : walletVerficationEmailTitle
          }
        />
      )}
      {(isAlreadyVerified || linkNotFound) && (
        <Modal
          maxWidth="xs"
          body={linkNotFoundContent(isAlreadyVerified)}
          handleClose={() => {
            if (isAlreadyVerified) {
              setIsAlreadyVerified(false);
            } else {
              setLinkNotFound(false);
            }
          }}
          open={isAlreadyVerified || linkNotFound}
          title={isAlreadyVerified ? alreadyVerified : linkNotFoundTitle}
        />
      )}
      <DeactivatePayoutModal
        isDeactivateModalOpen={isDeactivateModalOpen}
        setIsDeactivateModalOpen={setIsDeactivateModalOpen}
      />
    </>
  );
};

export default PayoutWalletList;
