import { Box, Grid } from "@mui/material";
import React, { useState } from "react";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import Text from "@speed/common/src/components/Text/Text";
import { ButtonToggle } from "@speed/common/src/components/ButtonToggle/ButtonToggle";
import { preview, previewSubMsg } from "../messages";
import { useDispatch, useSelector } from "react-redux";
import {
  setIsModalOpen,
  setPaymentConfirmationOption,
  setPaymentRedirectWebPreview,
  updatePaymentMessage,
} from "../../redux/common/actions";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import { handleClick, handleClose } from "./AllLinkFormConstant";
import InfoIcon from "@mui/icons-material/Info";

const RightSideCommonPreview = (props) => {
  const {
    handleChange,
    priceMsg,
    content,
    selected,
    brandingURL,
    showWebMobilePreviewButton = true,
    customClass = "",
    additionalHeaderComponent = null,
    showPriceMsg = true,
    customSelectComponent = undefined,
    infoIconVisible = true,
  } = props;
  const dispatch = useDispatch();
  const history = useSelector((state) => state.common.history);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  return (
    <Box
      container
      className={`apply-auto-scroll ${customClass}`}
      height="none !important"
    >
      <Grid container sx={{ ml: "5px", alignItems: "center", width: "800px" }}>
        <Grid item lg={customSelectComponent ? 4.3 : 6}>
          <Box>
            <Text
              size={20}
              font="semibold"
              className="default-text"
              withIcon="end"
            >
              {preview}
              {infoIconVisible && (
                <InfoIcon
                  className="label-with-icon"
                  sx={{
                    height: "20px !important",
                    width: "20px !important",
                    marginLeft: "3px !important",
                  }}
                  onClick={(e) => handleClick(e, setAnchorEl, anchorEl)}
                />
              )}
            </Text>

            <CustomPopper
              disablePortal={true}
              open={open}
              anchorEl={anchorEl}
              position="bottom"
              handleClose={() => handleClose(setAnchorEl)}
            >
              <Text
                size={14}
                font="regular"
                className="grey-text"
                variant="subtitle1"
              >
                {previewSubMsg[0]}
                <CustomLink
                  size="small"
                  bold
                  onClick={() => {
                    dispatch(setIsModalOpen(false));
                    dispatch(setPaymentRedirectWebPreview(false));
                    dispatch(setPaymentConfirmationOption("confirmation"));
                    dispatch(updatePaymentMessage(""));
                    history.push(brandingURL);
                  }}
                >
                  {previewSubMsg[1]}
                </CustomLink>
              </Text>
            </CustomPopper>
          </Box>
        </Grid>
        {customSelectComponent && (
          <Grid
            item
            lg={6.4}
            display="flex"
            justifyContent="flex-end"
            pr="20px"
          >
            {customSelectComponent}
          </Grid>
        )}
        <Grid item lg={customSelectComponent ? 1.2 : 6}>
          <Box display="flex" alignItems="baseline" justifyContent="flex-end">
            {additionalHeaderComponent}
            {showWebMobilePreviewButton && (
              <ButtonToggle
                content={content}
                value={selected}
                onChange={handleChange}
              />
            )}
          </Box>
        </Grid>
      </Grid>

      {/* Render the children of parent components to create right side preview */}
      {props.children}

      {showPriceMsg && (
        <Box marginTop="26px">
          <Text
            sx={{ color: "#E74C3C", marginRight: "5px" }}
            size={16}
            font="regular"
            variant="inline"
          >
            *
          </Text>
          <Text size={14} font="regular" className="grey-text" variant="inline">
            {priceMsg}
          </Text>
        </Box>
      )}
    </Box>
  );
};

export default RightSideCommonPreview;
