import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Skeleton } from "@mui/material";

function TableSkeleton(props) {
  return (
    <TableRow key="table-skeleton">
      {props?.columns?.map((column) => {
        const key =
          column.props?.column?.title ||
          Math.random().toString(36).substring(7);
        return (
          <TableCell key={key} style={{ padding: "21px 40px" }}>
            {<Skeleton animation="wave" />}
          </TableCell>
        );
      })}
    </TableRow>
  );
}
export default TableSkeleton;
