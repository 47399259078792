import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { Grid } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import InfoIcon from "@mui/icons-material/Info";
import { Input } from "@speed/common/src/components/Input/Input";
import Text from "@speed/common/src/components/Text/Text";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import {
  domainNameText,
  domainPlaceholder,
  domainValidate,
  paymentDomainContentMsg,
  subdomainContentLink,
} from "../../messages";
import { isPaymentDomainValid } from "../../constants";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";

const AddPaymentDomainForm = forwardRef((props, ref) => {
  const subDomainContent = () => {
    return (
      <>
        <Text
          size={14}
          font="regular"
          variant="subtitle1"
          className="default-text"
        >
          {paymentDomainContentMsg}
        </Text>
        <Text
          className="grey-text"
          sx={{ marginTop: "12px" }}
          size={12}
          font="regular"
          variant="subtitle1"
        >
          {subdomainContentLink[0]}
          <CustomLink size="small" className="popper-link-content">
            {subdomainContentLink[1]}
          </CustomLink>
          {subdomainContentLink[2]}
        </Text>
      </>
    );
  };

  const validationSchema = yup.object({
    domain: yup
      .string()
      .matches(isPaymentDomainValid, domainValidate)
      .required(""),
  });

  const formik = useFormik({
    initialValues: {
      domain: "",
    },
    validationSchema: validationSchema,
  });

  const {
    errors,
    touched,
    getFieldProps,
    setTouched,
    isValid,
    dirty,
    isSubmitting,
    values,
  } = formik;

  useEffect(() => {
    props.setBtnDisable(!(isValid && dirty) || isSubmitting);
  }, [isSubmitting, isValid, dirty]);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      return {
        domain: values.domain.toLowerCase(),
      };
    },
  }));

  return (
    <Grid container>
      <Grid item lg={7.7}>
        <Input
          customClass="margin-top30"
          type="text"
          showLabel
          label={domainNameText}
          name="domain"
          error={touched.domain && Boolean(errors.domain)}
          onBlur={() => setTouched({ ...touched, domain: true })}
          {...getFieldProps("domain")}
          fullWidth
          inputProps={{ maxLength: 250 }}
          placeholder={domainPlaceholder}
          sx={{ mb: 2.5 }}
          isIcon={true}
          iconContent={subDomainContent()}
          icon={
            <InfoIcon
              style={{
                height: 14,
                width: 14,
                color: "#848b9e",
                marginLeft: "7px",
              }}
            />
          }
        />
        <InputErrorMessage {...{ touched, errors }} inputName="domain" />
      </Grid>
    </Grid>
  );
});

export default AddPaymentDomainForm;
