import React, { useEffect, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import Text from "./Text/Text";
import { RadioButton } from "./RadioButton/RadioButton";
import TruncatedTextTooltip from "./TruncatedTextTooltip";
import SearchIcon from "@mui/icons-material/Search";
import { Input } from "./Input/Input";
import { searchAccount } from "../messages";
import { Wallet, noResultfound, tryAgainText } from "./messages";

const SelectAccount = (props) => {
  const {
    walletAcctMsg,
    alreadyConnectedMsg,
    selectedRadio,
    setSelectedRadio,
    setAccountSelected,
    listCustomClass = "",
    from,
    platformAccId = "",
    accountsList,
    isListLoading = false,
  } = props;

  const accountArr =
    accountsList.length > 0 &&
    accountsList.filter((account) => account?.account_type !== Wallet);

  const [filterValue, setFilterValue] = useState();
  const [dataToShow, setDataToShow] = useState(accountArr);

  useEffect(() => {
    if (filterValue) {
      const result =
        accountArr.length > 0 &&
        accountArr.filter((e) =>
          e?.name?.toLowerCase().includes(filterValue?.toLowerCase())
        );
      setDataToShow(result);
    } else {
      setDataToShow(accountArr);
    }
  }, [filterValue, accountsList]);

  const displayAccountList = () => {
    return accountArr?.length > 0 && dataToShow?.length === 0 && filterValue ? (
      <>
        <SearchIcon
          sx={{
            display: "flex",
            mx: "auto",
            color: "#e4e9ee",
            height: 60,
            width: 60,
          }}
        />
        <Text size={16} align="center" className="grey-text">
          {noResultfound}
        </Text>
        <Text
          size={14}
          variant="h4"
          className="grey-text"
          align="center"
          font="regular"
        >
          {tryAgainText}
        </Text>
      </>
    ) : (
      <>
        {isListLoading && (
          <Box key="account-skeleton" className="connect-partner-box">
            <Skeleton animation="wave" height="20px" width="350px" />
          </Box>
        )}
        {dataToShow.length > 0 &&
          dataToShow.map((accountFromData) => {
            const disableCheck =
              from === "connect-account"
                ? accountFromData?.id === platformAccId ||
                  accountFromData?.connected_to_platform
                : from === "affiliate-partner-connect-account"
                ? accountFromData?.reseller_partner_id
                : false;
            return (
              <Box key={accountFromData?.id} className="connect-partner-box">
                <RadioButton
                  checked={selectedRadio === accountFromData?.id}
                  disabled={disableCheck}
                  label={
                    disableCheck ? (
                      <Box>
                        <TruncatedTextTooltip
                          textValue={accountFromData?.name}
                          className="custom-tooltip"
                          cellWidth="500px"
                          textProps={{
                            sx: {
                              color: "rgba(10, 25, 62, 0.3) !important",
                            },
                          }}
                          boxProps={{ className: "account-list-box" }}
                        />
                        <Text
                          font="regular"
                          size={14}
                          sx={{
                            color: "rgba(10, 25, 62, 0.3) !important",
                          }}
                        >
                          {alreadyConnectedMsg}
                        </Text>
                      </Box>
                    ) : (
                      <TruncatedTextTooltip
                        textValue={accountFromData?.name}
                        cellWidth="500px"
                        className="custom-tooltip"
                        boxProps={{ className: "account-list-box" }}
                      />
                    )
                  }
                  onChange={() => {
                    setSelectedRadio(accountFromData?.id);
                    setAccountSelected(accountFromData);
                  }}
                  value={accountFromData?.id}
                />
              </Box>
            );
          })}
      </>
    );
  };

  return (
    <Box className="account-list" width={600}>
      <Input
        type="text"
        name="destination_account"
        value={filterValue}
        onChange={(e) => setFilterValue(e.target.value)}
        label=""
        fullWidth
        placeholder={searchAccount}
        showLabel={false}
      />
      <Box
        className={`${listCustomClass} margin-top30`}
        display="flex"
        flexDirection="column"
        gap={2.5}
        overflow="hidden auto"
        maxHeight="65vh"
        p="1px"
      >
        {accountArr?.length === 0 ? (
          <>
            <WarningIcon
              sx={{
                color: "#E74C3C",
                height: 60,
                width: 60,
                display: "flex",
                mx: "auto",
              }}
            />
            <Text
              size={14}
              variant="h4"
              className="grey-text"
              align="center"
              font="regular"
            >
              {walletAcctMsg}
            </Text>
          </>
        ) : (
          displayAccountList()
        )}
      </Box>
    </Box>
  );
};

export default SelectAccount;
