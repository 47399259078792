import React, { useCallback, useEffect, useState } from "react";
import { Box, TableCell, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "@speed/common/src/components/Table/Table";
import {
  automatePayout,
  createInstantPayout,
  emptyPayoutSubText,
} from "../messages";
import {
  payoutStatus,
  renderPayoutStatus,
  satsToBtcAmount,
  clipboardElement,
  capitalizeFirstChar,
} from "@speed/common/src/components/constants";
import { emptySubText } from "@speed/common/src/components/messages";
import { emptyPayout } from "@speed/common/src/components/images";
import Button from "@speed/common/src/components/Button/Button";
import {
  noOfRecords,
  callAPIInterface,
  dateTimeFormatInApp,
} from "../constants";
import { setIsDataAvailable, setIsModalOpen } from "../../redux/common/actions";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import { useFlags } from "launchdarkly-react-client-sdk";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import NoDataAvailable from "@speed/common/src/components/NoDataAvailable";
import CreateInstantPayout from "./CreateInstantPayout";

const columns = [
  {
    title: "Payout Request ID",
    width: "200px",
    paddingLeft: "38px !important",
  },
  { title: "Payout Amount (BTC)", align: "right", width: "180px" },
  { title: "Network/routing fees (BTC)", align: "right", width: "230px" },
  { title: "Speed fees (BTC)", align: "right", width: "180px" },
  { title: "To Wallet Name", width: "260px", align: "center" },
  { title: "Type", width: "140px" },
  { title: "Created on", width: "220px" },
  { title: "Status", width: "160x" },
];

const PayoutsList = (props) => {
  const dispatch = useDispatch();
  const queryParam = "?limit=" + noOfRecords;

  const [rows, setRows] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [disableCreateButton, setDisableCreateButton] = useState(true);

  const { history, isDataAvailable, isModalOpen, caughtErrorInStrapi } =
    useSelector((state) => state.common);
  const liveMode = useSelector((state) => state.auth.liveMode);
  const { poListpayoutrequest, poCreatepayoutrequest, pschCreateFe } =
    useFlags();

  useEffect(() => fetchPayouts(), [liveMode]);

  useEffect(() => {
    if (isModalOpen) handleCreatePayoutRequest();
  }, [isModalOpen]);

  const fetchPayouts = () => {
    setRows([]);
    setHasMore(true);
    getPayouts([], queryParam);
  };

  const loadMore = useCallback(() => {
    getPayouts(rows, queryParams);
  }, [rows]);

  const getPayouts = async (lines, params) => {
    setDisableCreateButton(true);
    setTableRowSkeleton(true);
    let method,
      data,
      path = "";
    if (props?.tab) {
      method = "POST";
      data = { status: props?.tab };
      path = "/payouts/filter";
    } else {
      method = "GET";
      data = {};
      path = "/payouts";
    }

    await callAPIInterface(method, path + params, data)
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam + "&ending_before=" + res.data[res.data.length - 1].id
            );
          }
          const hasHoldPayoutRequests = res.data?.some(
            (payoutRes) => payoutRes.status === payoutStatus.hold.value
          );
          setDisableCreateButton(hasHoldPayoutRequests);
          setRows(lines.concat(res.data));
          dispatch(setIsDataAvailable(res.data.length > 0));
        }
      })
      .catch((_e) => {
        setTableRowSkeleton(false);
        setDisableCreateButton(true);
      });
  };

  const handleCreatePayoutRequest = () => {
    dispatch(setIsModalOpen(true));
  };

  const columnsData = columns.map((column, index) => (
    <TableCell
      key={index}
      align={column.align}
      sx={{
        minWidth: column.width,
        height: "50px",
        paddingLeft: column.paddingLeft,
      }}
    >
      {column.title}
    </TableCell>
  ));

  const rowsData = rows?.map((rowItem, index) => {
    return (
      <TableRow
        key={index}
        className="clickable"
        onClick={() => history.push(`/payouts/${rowItem.id}`)}
      >
        <TableCell sx={{ paddingLeft: "38px !important" }}>
          {clipboardElement(
            rowItem?.id,
            true,
            "payments-listing-id-clipboard",
            "inputBox",
            false
          )}
        </TableCell>
        <TableCell sx={{ textAlign: "right", height: "50px" }}>
          {satsToBtcAmount(rowItem?.target_amount)}
        </TableCell>
        <TableCell sx={{ textAlign: "right", height: "50px" }}>
          {rowItem?.fee === 0 ? 0 : satsToBtcAmount(rowItem?.fee) ?? "-"}
        </TableCell>
        <TableCell sx={{ textAlign: "right", height: "50px" }}>
          {rowItem?.speed_fee === 0
            ? 0
            : satsToBtcAmount(rowItem?.speed_fee) ?? "-"}
        </TableCell>
        <TableCell>
          <TruncatedTextTooltip
            textValue={rowItem.wallet.name}
            cellWidth="310px"
            textProps={{
              sx: { textAlign: "center" },
            }}
          />
        </TableCell>
        <TableCell>
          {capitalizeFirstChar(rowItem?.payout_type?.toLowerCase())}
        </TableCell>
        <TableCell>{dateTimeFormatInApp(rowItem.created)}</TableCell>
        <TableCell className="table-row-status">
          {renderPayoutStatus(rowItem?.status)}
        </TableCell>
      </TableRow>
    );
  });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    textOnNoData: emptySubText(
      liveMode,
      "payouts",
      props?.tab?.replace(/_/g, "-")
    ),
    subTextOnNoData: emptyPayoutSubText,
    noDataImage: emptyPayout,
    createButtonText:
      poCreatepayoutrequest && disableCreateButton && createInstantPayout,
    handleCreate: handleCreatePayoutRequest,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
  };

  const createPayoutRequestButton = () => {
    return (
      <Box className="action-btn-wrapper mui-fixed">
        {pschCreateFe && (
          <Button
            icon="arrowOutwardIcon"
            className="add-icon"
            style={{ marginRight: "16px" }}
            label={automatePayout}
            variant="outlined"
            color="primary"
            onClick={() => history.push("/settings/payout-scheduling")}
            disabled={disableCreateButton}
          />
        )}
        {poCreatepayoutrequest && (
          <Button
            icon="addIcon"
            className="add-icon"
            label={createInstantPayout}
            variant="outlined"
            color="primary"
            onClick={() => dispatch(setIsModalOpen(true))}
            disabled={disableCreateButton}
          />
        )}
      </Box>
    );
  };

  return (
    <>
      {isDataAvailable
        ? createPayoutRequestButton()
        : ((!isDataAvailable && props.tab) || caughtErrorInStrapi) &&
          createPayoutRequestButton()}
      <Box className="main-content">
        {isDataAvailable ? (
          <>
            {poListpayoutrequest ? (
              <CustomTable {...tableProps} />
            ) : (
              <AccessDenied />
            )}
          </>
        ) : (
          (props.tab || caughtErrorInStrapi) && (
            <Box
              minHeight="calc(100vh - 201px)"
              display="flex"
              justifyContent="center"
            >
              <NoDataAvailable
                text={tableProps.textOnNoData}
                image={emptyPayout}
                subText={emptyPayoutSubText}
                buttonText={poCreatepayoutrequest && createInstantPayout}
                handleCreate={handleCreatePayoutRequest}
              />
            </Box>
          )
        )}
        <CreateInstantPayout />
      </Box>
    </>
  );
};

export default PayoutsList;
