import { Grid } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Text from "@speed/common/src/components/Text/Text";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import {
  closeAccountTitleMsg,
  closeAccountCheckAcceptMsg,
  closeAccountSubtitles,
  requestSubmitted,
  reqSubmittedText,
} from "../../messages";
import {
  setSettingFormChange,
  setSettingFormValid,
  setExecuteAPICall,
  setLoading,
  setSettingFormSubmit,
  setSettingFormCancel,
} from "../../../redux/common/actions";
import { callAPIInterface, setShowCrucialActionModal } from "../../constants";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

function CloseAccount() {
  const [requestSubmittedFlag, setRequestSubmittedFlag] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const { settingFormSubmit, settingFormCancel, executeAPICall, history } =
    useSelector((state) => state.common);

  const handleSubmit = (_values) => {
    //API call will be here
  };

  const formik = useFormik({
    initialValues: {
      check_accept: false,
    },
    onSubmit: handleSubmit,
  });

  const getCloseAccountData = async () => {
    try {
      await callAPIInterface("POST", "/close-account");
      setRequestSubmittedFlag(true);
    } catch (err) {
      throw err;
    }
  };

  const { values, setFieldValue, isValid, dirty, isSubmitting, resetForm } =
    formik;

  useEffect(() => {
    if (settingFormCancel) {
      resetForm();
      dispatch(setSettingFormCancel(false));
    }
  }, [settingFormCancel]);

  const closingInstructionElement = (fieldName, checkedValue, labelText) => {
    return (
      <CustomCheckbox
        className="margin-top30"
        onChange={(_e, checked) => setFieldValue(fieldName, checked)}
        checked={checkedValue}
        name={fieldName}
        label={
          <Text font="semibold" size={16} variant="subtitle2">
            {labelText}
          </Text>
        }
      />
    );
  };

  useEffect(() => {
    dispatch(setSettingFormValid(!(isValid && dirty) || isSubmitting));
  }, [isValid, dirty, isSubmitting]);

  useEffect(() => {
    dispatch(setSettingFormChange(dirty));
  }, [values]);

  useEffect(async () => {
    if (executeAPICall) {
      dispatch(setLoading(false));
      setShowCrucialActionModal(user, false);
      dispatch(setExecuteAPICall(false));
      await getCloseAccountData();
      resetForm();
    }
  }, [executeAPICall]);

  useEffect(() => {
    // get values and call action to update this change into DB
    if (isValid && dirty && settingFormSubmit) {
      dispatch(setSettingFormSubmit(false));
      setShowCrucialActionModal(user, true);
    }
  }, [settingFormSubmit]);

  const requestSubmittedBody = (
    <Grid item className="closure-box">
      <CheckCircleIcon className="check-icon" />
      <Text
        size={14}
        align="center"
        variant="body1"
        font="regular"
        className="grey-text"
      >
        {reqSubmittedText}
      </Text>
    </Grid>
  );

  return (
    <Grid
      className="close-account-grid"
      container
      spacing={1}
      component="form"
      noValidate
      onSubmit={formik.handleSubmit}
    >
      <Grid style={{ width: "660px" }}>
        <Text size={20} variant="title1">
          {closeAccountTitleMsg}
        </Text>
        <ol style={{ padding: "0px 0px 0px 19px" }}>
          {closeAccountSubtitles.map((subtitle, index) => (
            <li
              className="margin-top20"
              font="regular"
              size={16}
              variant="subtitle1"
              key={`subtitle_${index}`}
            >
              {subtitle}
            </li>
          ))}
        </ol>
        {closingInstructionElement(
          "check_accept",
          values.check_accept,
          closeAccountCheckAcceptMsg
        )}
      </Grid>
      <Modal
        maxWidth="xs"
        body={requestSubmittedBody}
        open={requestSubmittedFlag}
        handleClose={() => {
          setRequestSubmittedFlag(false);
          history.push("/settings/account-info");
          window.location.reload();
        }}
        title={requestSubmitted}
      />
    </Grid>
  );
}

export default CloseAccount;
