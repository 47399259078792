import React from "react";
import Grid from "@mui/material/Grid";
import { needHelp } from "@speed/common/src/components/messages";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { openIntercomChatModal } from "@speed/common/src/components/constants";

const NeedHelp = () => {
  return (
    <Grid className="need-help-grid">
      <CustomLink
        size="large"
        onClick={openIntercomChatModal}
        alignIcon="start"
        color="#848B9E"
        withIcon={
          <HelpOutlineIcon
            style={{ width: 20, height: 20, color: "#848b9e" }}
          />
        }
      >
        {needHelp}
      </CustomLink>
    </Grid>
  );
};

export default NeedHelp;
