import Text from "@speed/common/src/components/Text/Text";
import { Box, Grid } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "@speed/common/src/components/Input/Input";
import {
  confirmPasswordLabel,
  passwordConfirmMsg,
  ownershipTransferMsg,
  transferOwnershipLabel,
  transferAccountOwnership,
} from "../../messages";
import { passwordPlaceholder } from "@speed/common/src/components/messages";
import { useState } from "react";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import Button from "@speed/common/src/components/Button/Button";
import {
  verifyEmailOTP,
  verifyUserPassword,
} from "../../../redux/auth/actions";
import { callAPIInterface } from "../../constants";
import CommonVerifyOTPSection from "../../Common/CommonVerifyOTPSection";

const TransferOwnership = ({
  email,
  setTransferOwnershipModal,
  transferOwnershipModal,
  newOwnerId,
  setLoaderTransfer,
  getUsers,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [disableOTPFooterBtn, setDisableOTPFooterBtn] = useState(true);
  const [transferOTP, setTransferOTP] = useState(true);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isForSocialMediaLogin = user?.signup_method !== "email";

  const validationSchema = yup.object({
    confirm_password: yup.string().required(),
  });
  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validationSchema,
  });

  const { values, setFieldValue } = formik;

  const textShow = (text) => {
    return (
      <Text
        size={16}
        font="semibold"
        variant="h4"
        className="default-text margin-transfer-ownership"
      >
        {text}
      </Text>
    );
  };

  const ownershipTransfer = () => {
    setDisabled(true);
    setLoading(true);
    const verificationAPIMethod = isForSocialMediaLogin
      ? verifyEmailOTP
      : verifyUserPassword;
    const postObject = isForSocialMediaLogin
      ? {
          otp: transferOTP,
        }
      : {
          password: values.confirm_password,
        };

    dispatch(verificationAPIMethod(postObject))
      .then((res) => {
        if (res) {
          setTransferOwnershipModal(false);
          setLoaderTransfer(true);
          callAPIInterface("POST", "/account/transfer-ownership", {
            new_owner_id: newOwnerId,
          })
            .then(() => {
              setLoaderTransfer(false);
              getUsers();
            })
            .catch(() => {
              setLoaderTransfer(false);
            });
          setDisabled(false);
          setLoading(false);
        } else {
          setDisabled(false);
          setLoading(false);
        }
      })
      .catch(() => {
        setDisabled(false);
        setLoading(false);
      });
  };

  const bodyTransferOwnership = (
    <Box component="form" autoComplete="off">
      {textShow(ownershipTransferMsg(email))}
      {isForSocialMediaLogin ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          p="0px 30px 30px 30px"
          margin="0px auto"
          width="444px"
        >
          <CommonVerifyOTPSection
            footerAuthenticationButton
            setPropOtp={setTransferOTP}
            setDisableOTPFooterBtn={setDisableOTPFooterBtn}
          />
        </Grid>
      ) : (
        <>
          {textShow(passwordConfirmMsg)}
          <Box className="margin-transfer-ownership">
            <Input
              customClass="margin-top30"
              type="password"
              showLabel
              label={confirmPasswordLabel}
              value={values.confirm_password}
              name="confirm_password"
              fullWidth
              placeholder={passwordPlaceholder}
              inputProps={{
                autoComplete: "new-password",
              }}
              onChange={(e) => {
                setFieldValue("confirm_password", e.target.value);
                e.target.value.length > 0
                  ? setDisabled(false)
                  : setDisabled(true);
              }}
            />
          </Box>
        </>
      )}
    </Box>
  );

  return (
    <Modal
      maxWidth="sm"
      className="user-team-wrapper"
      body={bodyTransferOwnership}
      footer={
        <Button
          label={transferOwnershipLabel}
          onClick={ownershipTransfer}
          disabled={isForSocialMediaLogin ? disableOTPFooterBtn : disabled}
          loading={loading}
        />
      }
      handleClose={() => setTransferOwnershipModal(false)}
      open={transferOwnershipModal}
      title={transferAccountOwnership}
    />
  );
};

export default TransferOwnership;
