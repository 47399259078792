import { Modal } from "@speed/common/src/components/Modal/Modal";
import { Box } from "@mui/material";
import { Button } from "@speed/common/src/components/Button/Button";
import { Input } from "@speed/common/src/components/Input/Input";
import {
  createSecretKey,
  createKey,
  keyName,
  notePlaceholder,
  secretKeyPlaceholder,
  noteLabel,
  keyLength,
  descriptionLength,
} from "../messages";
import {
  callAPIInterface,
  idealTimeLimitReached,
  setShowCrucialActionModal,
  updateSessionLastActionTime,
} from "../constants";
import {
  setExecuteAPICall,
  setLoading,
  setSettingFormSubmit,
} from "../../redux/common/actions";
import * as yup from "yup";
import { useFormik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import React, { useEffect, useState } from "react";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import { sessionService } from "redux-react-session";

const CreateKey = ({
  openSecretKeyModal,
  handleCloseSecretModal,
  setOpenApiKeyModal,
  setApiKey,
}) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [session, setSession] = useState(null);
  const { user } = useSelector((state) => state.auth);
  const { executeAPICall, settingFormSubmit, isLoading } = useSelector(
    (state) => state.common
  );
  const validationSchema = yup.object({
    name: yup.string().max(255, keyLength).required(),
    description: yup.string().max(255, descriptionLength),
  });

  const createSecretKeys = () => {
    return callAPIInterface("POST", "/api-key/secret-key", values)
      .then((response) => {
        if (response) {
          setApiKey(response.api_key);
        }
      })
      .catch((_e) => false);
  };

  const handleSubmit = () => {
    //api call
    createSecretKeys();
    handleCloseSecretModal();
    setOpenApiKeyModal();
    setLoading(false);
  };

  const handleCreateSecretKey = () => {
    dispatch(setSettingFormSubmit(true));
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      default: false,
    },
    validationSchema: validationSchema,
    onSubmit: handleCreateSecretKey,
  });

  const { values, setFieldValue, resetForm, isValid, dirty } = formik;

  const onChangeFormField = (fieldname, value) => {
    setFieldValue(fieldname, value);
    formData[fieldname] = value;
    setFormData(formData);
  };

  useEffect(() => {
    sessionService.loadSession().then((newSession) => {
      setSession(newSession);
    });
  }, []);

  useEffect(() => {
    if (executeAPICall) {
      const newSession = updateSessionLastActionTime();
      setSession(newSession);
      handleSubmit();
      dispatch(setExecuteAPICall(false));
    }
  }, [executeAPICall]);

  useEffect(() => {
    if (settingFormSubmit) {
      if (idealTimeLimitReached(session?.last_action_time)) {
        dispatch(setSettingFormSubmit(false));
        setShowCrucialActionModal(user, true);
      } else {
        handleSubmit();
      }
    }
  }, [settingFormSubmit]);

  const secretModalContent = (
    <Box component="form" className="create-secret-key">
      <Input
        type="text"
        name="name"
        showLabel
        label={keyName}
        fullWidth
        inputProps={{ maxLength: 255 }}
        placeholder={secretKeyPlaceholder}
        value={values.name}
        onChange={(e) => {
          onChangeFormField("name", e.target.value);
        }}
      />
      <br />
      <TextAreaComponent
        showLabel
        label={noteLabel}
        maxLength={255}
        minRows={2}
        maxRows={2}
        name="description"
        placeholder={notePlaceholder}
        fullWidth
        value={values.description}
        onChange={(e) => {
          onChangeFormField("description", e.target.value);
        }}
      />
    </Box>
  );
  return (
    <>
      <Modal
        maxWidth="xs"
        body={secretModalContent}
        footer={
          <Button
            loading={isLoading}
            type="submit"
            label={createKey}
            onClick={formik.handleSubmit}
            disabled={!(isValid && dirty)}
          />
        }
        handleClose={() => {
          resetForm();
          handleCloseSecretModal();
        }}
        open={openSecretKeyModal}
        title={createSecretKey}
      />
    </>
  );
};
export default CreateKey;
