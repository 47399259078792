import CustomDivider from "@speed/common/src/components/Divider/Divider";
import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import PaymentPageCustomizationForm from "./PaymentPageCustomizationForm";
import { useDispatch, useSelector } from "react-redux";
import PaymentPageSettingsDisplay from "./PaymentPageSettingsDisplay";
import { getAccountPublicInfo } from "../../../redux/common/actions";
import PageLoader from "@speed/common/src/components/PageLoader";

function PaymentPageSettings() {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(true);

  const settingFormChange = useSelector(
    (state) => state.common.settingFormChange
  );

  useEffect(() => {
    dispatch(getAccountPublicInfo())
      .then(async (res) => {
        res && setLoader(false);
      })
      .catch((e) => {
        setLoader(false);
      });
  }, []);

  const loaderComponent = (
    <Box sx={{ position: "relative", height: "100%" }}>
      <PageLoader
        open={loader}
        alt="loading..."
        customClass="page-loader"
        sx={{ position: "absolute" }}
      />
    </Box>
  );

  return loader ? (
    loaderComponent
  ) : (
    <>
      <CustomDivider />
      <Box className="payment-page-wrapper">
        <Box
          className="modal-container-grid"
          sx={{ display: "flex", paddingLeft: "0 !important" }}
        >
          <Box
            className="inner-grid"
            width="16%"
            padding="30px 24px 200px 35px"
            paddingBottom={settingFormChange ? "270px" : "200px"}
            overflow="auto"
          >
            <PaymentPageCustomizationForm />
          </Box>

          <Box
            paddingLeft="39px"
            paddingBottom={settingFormChange ? "215px" : "140px"}
            className="inner-grid"
            sx={{ width: "52%", backgroundColor: "#f7fafc" }}
          >
            <PaymentPageSettingsDisplay />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default PaymentPageSettings;
