import OtpInput from "react-otp-input";
import PropTypes from "prop-types";
import "./otp-input.scss";

export const OTPInput = ({ value, numInputs, onChange, ...props }) => {
  return (
    <OtpInput
      value={value}
      onChange={onChange}
      numInputs={numInputs}
      isInputNum
      className="margin-top58"
      inputStyle="otp-input"
      placeholder="------"
      containerStyle={{ alignItems: "center", justifyContent: "center" }}
      {...props}
    />
  );
};

export const propTypes = {
  value: PropTypes.string.isRequired,
  numInputs: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
};

const defaultProps = {
  numInputs: 6,
};

OTPInput.defaultProps = defaultProps;
OTPInput.propTypes = propTypes;
