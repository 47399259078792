import React, {
  useState,
  useImperativeHandle,
  forwardRef,
  useEffect,
} from "react";
import CustomTable from "@speed/common/src/components/Table/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Box } from "@mui/material";
import { emailAddressLabel } from "../../messages";
import { RadioButton } from "@speed/common/src/components/RadioButton/RadioButton";
import RadioGroup from "@mui/material/RadioGroup";
import Text from "@speed/common/src/components/Text/Text";
import PageLoader from "@speed/common/src/components/PageLoader";
import { keyEventListenerForMultiEmail } from "../../constants";
import MultiEmailWithAlert from "../../MultiEmailWithAlert";

const RoleMember = forwardRef((props, ref) => {
  const { currentRow, roles, setDisabled } = props;
  const [selectedValue, setSelectedValue] = useState("role_admin");
  const [emails, setEmails] = useState([]);
  const [invalidEmailData, setInValidEmailData] = useState("");
  const [invalidEmailFlag, setInValidEmailFlag] = useState(false);
  const [limitEmail, setLimitEmail] = useState(false);

  const onKeyUpListener = (e) =>
    keyEventListenerForMultiEmail(
      e,
      setInValidEmailData,
      setInValidEmailFlag,
      setDisabled,
      emails
    );

  useEffect(() => {
    const multiInput = document
      .getElementsByClassName("react-multi-email")?.[0]
      ?.getElementsByTagName("input")[0];

    multiInput?.addEventListener("keyup", onKeyUpListener);
    return () => {
      multiInput?.removeEventListener("keyup", onKeyUpListener);
    };
  }, [emails?.length]);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      if (!invalidEmailFlag && !limitEmail)
        return {
          emails: emails,
          role: selectedValue,
        };
    },
  }));

  let rowsData = roles?.map((rowItem) => {
    return (
      <TableRow key={rowItem.id}>
        <TableCell width="40%">
          <RadioGroup>
            <RadioButton
              label={rowItem.role_name}
              value={rowItem.role_name}
              name="role"
              onChange={() => setSelectedValue(rowItem.id)}
              checked={
                currentRow && selectedValue == "role_admin"
                  ? currentRow.role == rowItem.role
                  : selectedValue === rowItem.id
              }
            />
          </RadioGroup>
        </TableCell>
        <TableCell>{rowItem.description}</TableCell>
      </TableRow>
    );
  });

  const tableProps = {
    rows: rowsData,
    columns: [],
  };
  return (
    <>
      {currentRow ? null : (
        <Box className="main-content">
          <Box className="multi-email-tags-label">
            <Text
              className="label-text"
              size={14}
              font="regular"
              variant="caption"
            >
              {emailAddressLabel}
            </Text>
          </Box>
          <MultiEmailWithAlert
            emails={emails}
            setEmails={setEmails}
            invalidEmailFlag={invalidEmailFlag}
            setInValidEmailFlag={setInValidEmailFlag}
            invalidEmailData={invalidEmailData}
            setInValidEmailData={setInValidEmailData}
            limitEmail={limitEmail}
            setLimitEmail={setLimitEmail}
            setDisabled={setDisabled}
          />
        </Box>
      )}
      {roles?.length ? (
        <Box className="role-member-table" position="relative">
          <CustomTable {...tableProps} />
        </Box>
      ) : (
        <Box
          className="role-member-table"
          position="relative"
          sx={{ height: "260px" }}
        >
          <PageLoader
            open={true}
            alt="loading..."
            customClass="page-loader preview-loader"
            sx={{ position: "absolute" }}
          />
        </Box>
      )}
    </>
  );
});
export default RoleMember;
