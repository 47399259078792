import { Box, Grid } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import TruncatedTextTooltip from "./TruncatedTextTooltip";

export const KeyValueGrid = ({
  keyName,
  value,
  keyNameCellWidth = 4,
  valueCellWidth = 8,
  preventTextOverflow = false,
}) => {
  return (
    <Grid item xs={5}>
      <Box display="flex">
        <Grid item xs={keyNameCellWidth}>
          <Text className="grey-text" size={16} variant="body1" font="regular">
            {keyName}
          </Text>
        </Grid>
        <Grid item xs={valueCellWidth}>
          {preventTextOverflow ? (
           <TruncatedTextTooltip textValue={value} /> 
          ):
          <Text className="default-text" variant="h4" size={16} font="regular">
            {value}
          </Text>
          }
        </Grid>
      </Box>
    </Grid>
  );
};
