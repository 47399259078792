import React, { useState } from "react";
import { AppBar, Box, Dialog, Slide } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import Button from "@speed/common/src/components/Button/Button";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { selectCountryDesc, selectYourCountry, send } from "../messages";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import { Countries } from "@speed/common/src/components/country";
import {
  getCountryObj,
  getDefaultWalletCurrencyObj,
} from "@speed/common/src/components/constants";
import { countryLabel, countryPlaceholder } from "@speed/common/src/messages";
import { callAPIInterface } from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { setInitialState, updateUser } from "../../redux/auth/actions";
import { sessionService } from "redux-react-session";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SelectCountry = ({ openCountryModal, setOpenCountryModal }) => {
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const [selectedCountry, setSelectedCountry] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleUpdateAccount = async (accountData) => {
    if (accountData) {
      const updatedAccountDetails = user.accounts_details.map((accountInfo) => {
        if (accountInfo?.account?.id === accountData.id) {
          return { ...accountInfo, account: accountData };
        }
        return accountInfo;
      });

      let updatedUserData = {
        ...user,
        accounts_details: updatedAccountDetails,
      };
      dispatch(updateUser(updatedUserData));

      let updatedcurrentAccountData = updatedAccountDetails.filter(
        (accountInfo) => accountInfo?.account?.id === accountData.id
      )[0];
      sessionService.loadSession().then((session) => {
        dispatch(
          setInitialState({
            ...session,
            current_acc: updatedcurrentAccountData,
          })
        );
      });
    }
  };

  const updateCountry = () => {
    setIsLoading(true);
    callAPIInterface("PUT", "/account", { country: selectedCountry })
      .then((res) => {
        handleUpdateAccount(res);
        setIsLoading(false);
        setOpenCountryModal(false);
        const defaultCurrencyObj = getDefaultWalletCurrencyObj(selectedCountry);
        localStorage.setItem(
          "account_default_currency",
          JSON.stringify(defaultCurrencyObj)
        );
      })
      .catch((_err) => {
        setIsLoading(false);
      });
  };
  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={openCountryModal}
      TransitionComponent={Transition}
      className="fullscreen-modal send-modal-dialog-section"
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Box
          height="70px"
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1, display: "flex", alignItems: "center" }}
            className="default-text divider"
            variant="h6"
          >
            {selectYourCountry}
          </Text>
        </Box>
      </AppBar>
      <Box className="send-wallet-recaptch">
        <Text
          size={22}
          font="semibold"
          className="default-text"
          sx={{ mt: "30px" }}
        >
          {selectYourCountry}
        </Text>
        <Text
          size={16}
          font="regular"
          variant="h6"
          className="grey-text"
          sx={{ mt: "10px", textAlign: "center", mb: "14px", width: "520px" }}
        >
          {selectCountryDesc}
        </Text>
        <Box width="600px" sx={{ padding: "0px 40px" }} mb="170px">
          <CustomAutoComplete
            name="country"
            options={Countries}
            getOptionLabel={(value) => {
              let country = getCountryObj(
                "short_name",
                typeof value === "object" ? value.short_name : value
              );
              return country ? country.short_name : "";
            }}
            onChange={(_e, value) => {
              setSelectedCountry(
                value && typeof value === "object" ? value.short_name : value
              );
            }}
            value={selectedCountry}
            customClass="margin-top30"
            placeholder={countryPlaceholder}
            showLabel
            disableClearable={true}
            label={countryLabel}
            allowedAutoComplete={true}
          />
        </Box>
        <CustomDivider sx={{ width: "600px" }} />
        <Button
          label={send}
          loading={isLoading}
          onClick={updateCountry}
          disabled={!selectedCountry}
          type="submit"
          sx={{ width: "540px", m: "24px 0px" }}
        />
      </Box>
    </Dialog>
  );
};

export default SelectCountry;
