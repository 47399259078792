import React, { useEffect, useState } from "react";
import { Box, Grid, TableCell, TableRow } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import {
  callAPIInterface,
  checkStatus,
  dateTimeFormatInApp,
  fetchProductDetails,
  getPricingModal,
  getPricingType,
  moduleMetaDataRows,
} from "../constants";
import Text from "@speed/common/src/components/Text/Text";
import {
  clipboardElement,
  getAmountByCurrency,
  getCurrencyObj,
  linkStatus,
  redirectWhenEntityNotFound,
} from "@speed/common/src/components/constants";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  add,
  currencyLabel,
  dateCreated,
  dateUpdated,
  edit,
  entityNotFoundMessage,
  events,
  metaDataText,
  priceLabel,
  priceNotFound,
  pricingModal,
  pricingType,
  product as productLabel,
  productLinkNotFoundBtnTxt,
  status as statusLabel,
} from "../messages";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import { useSelector } from "react-redux";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import history from "@speed/common/src/components/history";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import CommonActionPopper from "./CommonActionPopper";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import WarningIcon from "@mui/icons-material/Warning";
import MetaDataForm from "../MetaDataForm";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import DetailPagePaymentSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import LinkSkeleton from "@speed/common/src/components/Skeleton/LinkSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";

const PriceDetail = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [eventSkeleton, setEventSkeleton] = useState(false); // after integration make default value true
  const [eventsList, setEventsList] = useState([]);
  const [data, setData] = useState(null);
  const [product, setProduct] = useState(null);
  const [breadcrumb, setBreadcrumb] = useState([]);
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [metaDataSkeleton, setMetaDataSkeleton] = useState(true);
  const [isMetaDataEditable, setIsMetaDataEditable] = useState(false);
  const [metaDataList, setMetaDataList] = useState([]);

  const urlParams = useParams();

  const liveMode = useSelector((state) => state.auth.liveMode);

  const productName =
    product?.length > 20 ? product?.substring(0, 20) + "..." : product;

  useEffect(() => {
    loadPriceDetailPage();
  }, [liveMode, urlParams?.id]);

  useEffect(() => {
    if (data?.metadata) {
      //add price metadata flag in condition
      const convertedMetaDataToArray = Object.entries(data?.metadata)?.map(
        (e) => ({
          key: e[0],
          value: e[1],
        })
      );
      setMetaDataList(convertedMetaDataToArray);
      setMetaDataSkeleton(false);
    }
  }, [data?.metadata]); // add metadata flag

  const loadPriceDetailPage = () => {
    setData(null);
    setIsShowNotFound(false);
    getPriceDetail();
  };

  let newBreadcrumb = [
    { title: "Products", route: "/products" },
    { title: productName, route: `/products/${data?.product}` },
    { title: "Price", route: `/prices/${data?.id}` },
  ];

  useEffect(() => {
    if (data) {
      setBreadcrumb(newBreadcrumb);
    }
  }, [product]);

  const getPriceDetail = () => {
    setMetaDataSkeleton(true);
    callAPIInterface("GET", `/prices/${urlParams.id}`)
      .then((res) => {
        if (res) {
          fetchProductDetails(res?.product).then((res) => {
            setProduct(res?.name);
          });
          setData(res);
        }
      })
      .catch((err) => {
        setMetaDataSkeleton(false);
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  //It will be used in future
  // const onSubmit = (payload) => {
  //   setMetaDataSkeleton(true);
  //   const finalPayload = { metadata: payload };
  //   updateMetadata(finalPayload, `/price/${urlParams.id}`)
  //     .then((res) => {
  //       if (res) {
  //         setData(res);
  //       }
  //       setMetaDataSkeleton(false);
  //       setIsMetaDataEditable(false);
  //     })
  //     .catch(() => {
  //       setMetaDataSkeleton(false);
  //       setIsMetaDataEditable(false);
  //     });
  // };

  // const onCancel = () => setIsMetaDataEditable(false);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const headerContent = () => {
    return (
      <Box className="header-content">
        <Box display="flex" justifyContent="space-between">
          <TruncatedTextTooltip
            textValue={`Price for ` + (product || "")}
            cellWidth="555px"
            textProps={{
              size: 28,
              variant: "h6",
              className: "default-text",
              font: "semibold",
            }}
            maxHeight="34px"
          />

          {/*Remove comment at the time of implementing archive/unarchive of price */}
          {/* <MoreHorizIcon
            className="horizontal-dots-icon pointer-cursor"
            tabIndex={0}
            onClick={(event) => {
              handleClick(event);
            }}
          /> */}
        </Box>

        <Box className="header-price-content">
          <Box className="fiat-price" marginTop="10px">
            {product ? (
              <TruncatedTextTooltip
                textValue={product}
                cellWidth="450px"
                textProps={{
                  size: 20,
                  variant: "h4",
                  className: "default-text",
                  font: "semibold",
                }}
                maxHeight="34px"
              />
            ) : (
              <CommonSkeleton
                width={90}
                sx={{ borderRadius: "8px" }}
                color="#2a67ff"
              />
            )}
          </Box>

          <Box>
            {clipboardElement(data?.id, true, "product-detail", "text", true)}
          </Box>
        </Box>
      </Box>
    );
  };

  const handleArchive = () => {};

  const handleDelete = () => {};

  const detailsTableLeftSideData = () => {
    return [
      {
        header: statusLabel,
        cell: (
          <Tag
            text={linkStatus[checkStatus(data)].label}
            variant={linkStatus[checkStatus(data)].variant}
          />
        ),
      },
      {
        header: productLabel,
        cell: (
          <TruncatedTextTooltip
            textValue={product}
            cellWidth="250px"
            textProps={{
              size: 16,
              variant: "h4",
              className: "default-text",
              font: "regular",
            }}
            maxHeight="34px"
          />
        ),
      },
      {
        header: dateCreated,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {dateTimeFormatInApp(data?.created)}
          </Text>
        ),
      },
      {
        header: dateUpdated,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {dateTimeFormatInApp(data?.modified)}
          </Text>
        ),
      },
    ];
  };

  const detailsTableRightSideData = () => {
    return [
      {
        header: pricingModal,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {getPricingModal(data)}
          </Text>
        ),
      },
      {
        header: currencyLabel,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {data?.currency?.toUpperCase()} (
            {getCurrencyObj(data?.currency).symbol})
          </Text>
        ),
      },
      {
        header: priceLabel,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {getCurrencyObj(data?.currency).symbol}
            {getAmountByCurrency(data?.unit_amount, data?.currency)}
          </Text>
        ),
      },
      {
        header: pricingType,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {getPricingType(data?.type)}
          </Text>
        ),
      },
    ];
  };

  const renderMetadata = () => {
    return (
      <>
        {!metaDataSkeleton ? (
          <Box className="product-content meta-data-content">
            {isMetaDataEditable ? (
              <MetaDataForm
                metaDataList={metaDataList}
                setIsMetaDataEditable={setIsMetaDataEditable}
                // onSubmit={onSubmit}
                // onCancel={onCancel}
              />
            ) : (
              <HorizontalTable
                label={metaDataText}
                rows={moduleMetaDataRows(metaDataList)}
                rowsPerPage={51}
                isColumnShown={moduleMetaDataRows(metaDataList)?.length > 0}
                tableBodyClassName="border-none"
                isShowButton={false}
                displayShowButton={false}
                viewAllButtonLabel={metaDataList?.length > 0 ? edit : add}
                viewAllButtonIcon="editIcon"
                handleViewAllClick={() => setIsMetaDataEditable(true)}
              />
            )}
          </Box>
        ) : (
          <DetailPagePaymentSkeleton />
        )}
      </>
    );
  };

  const renderDetailContent = () => {
    let verticalTableData = {
      rowData: detailsTableLeftSideData(),
      rightSideRowData: detailsTableRightSideData(),
    };

    return (
      <Box className="details-content" marginTop="60px">
        <VerticalTable label="Details" {...verticalTableData} />
      </Box>
    );
  };

  const eventRows = eventsList?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/events/${rowItem?.id}`)}
      >
        <TableCell>{events(rowItem)}</TableCell>
        <TableCell align="right" sx={{ color: "#848b9e" }}>
          {dateTimeFormatInApp(rowItem?.created)}
        </TableCell>
      </TableRow>
    );
  });

  const renderEvents = () => {
    return (
      <>
        {!eventSkeleton ? (
          <Box className="product-content">
            <HorizontalTable
              label={events}
              isColumnShown={eventRows?.length > 0}
              rows={eventRows}
              rowsPerPage={3}
              // handleViewAllClick={() => {
              //   history.push(`/checkout-links/${urlParams?.id}/events`);
              // }}
              isShowButton={eventRows?.length > 3}
              tableBodyClassName="border-none"
            />
          </Box>
        ) : (
          <DetailPagePaymentSkeleton />
        )}
      </>
    );
  };

  return !isShowNotFound ? (
    <Grid
      className="payment-link-detail-wrapper product-detail-wrapper"
      container
      spacing={1}
    >
      {productName ? (
        <Breadcrumbs component={Link} breadcrumbData={breadcrumb} />
      ) : (
        <CommonSkeleton
          width={90}
          sx={{ borderRadius: "8px" }}
          color="#c4ccd2"
        />
      )}
      <>
        {data ? (
          <>
            {headerContent()}
            {renderDetailContent()}
            {renderMetadata()}
          </>
        ) : (
          <LinkSkeleton props={props} />
        )}
      </>
      {/* {renderEvents()} */}
      <CommonActionPopper
        anchorEl={anchorEl}
        setAnchorEl={setAnchorEl}
        // handleEdit={() => }
        // handleArchive={handleArchive}
        // handleDelete={handleDelete}
        from={"price"}
      />
    </Grid>
  ) : (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumb} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage(priceLabel)}
        subTitle={priceNotFound(urlParams.id)}
        btnText={productLinkNotFoundBtnTxt(liveMode)}
        redirect={() => history.push("/products")}
      />
    </Box>
  );
};

export default PriceDetail;
