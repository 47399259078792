import AlertMessage from "./AlertMessage/AlertMessage";

const InputErrorMessage = ({
  touched,
  errors,
  inputName,
  sx = {},
  ...props
}) => {
  return touched[inputName] && Boolean(errors[inputName]) ? (
    <AlertMessage
      message={errors[inputName]}
      severity="error"
      className="margin-top14"
      sx={sx}
      {...props}
    />
  ) : null;
};

export default InputErrorMessage;
