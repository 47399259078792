import React from "react";
import GetGuides from "./GetGuides";

const PayoutGuide = () => {
  return (
    <GetGuides
      moduleName="payout"
      redirectUrl="/payouts"
      guideApiPath="payout-guide"
      getStartedApiPath="Payout"
      needHelpGuidePath="docs/send-payments/payouts"
    />
  );
};

export default PayoutGuide;
