import React from "react";
import { useSelector } from "react-redux";
import OneQRPreview from "../../Common/OneQRPreview";
import { Box, Grid } from "@mui/material";

const OneQRBranding = (props) => {
  const { brandingPageUI, paymentPageSettings } = useSelector(
    (state) => state.common
  );

  const globalStates = {
    brandingPageUI,
    paymentPageSettings,
  };
  return (
    <>
      <Box className="one-qr-preview-wrapper">
        <Grid
          item
          className="mobile-view-branding"
          marginBottom={0}
          width="369px !important"
        >
          <OneQRPreview {...globalStates} height="545px" type="phone" />
        </Grid>
      </Box>
    </>
  );
};

export default OneQRBranding;
