import React, { useEffect } from "react";
import Lottie from "lottie-react";
import { Box, Dialog } from "@mui/material";
import { useLocation } from "react-router-dom";

import Text from "@speed/common/src/components/Text/Text";
import { satsToBtcAmount } from "@speed/common/src/components/constants";
import history from "@speed/common/src/components/history";
import walletPaymentAnimation from "./WalletPaymentSentAnimation";
import { paymentReceived, paymentSent, sats } from "../messages";
import { useSelector } from "react-redux";
import { displayTaprootCurrency, getAmountFormatted } from "../constants";

const PaymentSuccessful = () => {
  const location = useLocation();
  const paymentData = location?.data?.paymentData;
  const targetCurrency = location?.data?.target_currency;
  const fromReceived = location?.data?.fromReceived;
  const showBtcAmount = useSelector((state) => state.common.showBtcAmount);

  // If payment data is not defined then redirects to my assets.
  if (!paymentData) {
    history.push("/my-assets");
  }

  useEffect(() => {
    let timeout = setTimeout(() => {
      history.push("/my-assets");
    }, 5000);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const finalAmount = showBtcAmount
    ? satsToBtcAmount(paymentData?.target_amount_paid)
    : Math.round(paymentData?.target_amount_paid);

  const showPaymentDetails = () => {
    return (
      <Box className="wallet-payment-successfull">
        <Text
          size={20}
          font="regular"
          className="default-text"
          variant="subtitle1"
          style={{
            color: "#0A193E",
            marginTop: paymentData?.statement_descriptor ? "100px" : "7px",
          }}
        >
          {fromReceived ? paymentReceived : paymentSent}
        </Text>
        <Text
          style={{ color: "#0A193E" }}
          size={44}
          font="semibold"
          className="default-text"
          variant="h6"
        >
          {getAmountFormatted(
            paymentData?.target_currency === sats
              ? finalAmount
              : paymentData?.target_amount_paid / 100,
            paymentData?.target_currency || targetCurrency,
            showBtcAmount
          )}
        </Text>
        <Text
          size={24}
          font="semibold"
          className="grey-text"
          variant="h6"
          style={{ marginTop: "4px" }}
        >
          {displayTaprootCurrency(
            paymentData?.target_amount_paid,
            paymentData?.target_currency,
            showBtcAmount
          )}
        </Text>
        {paymentData?.statement_descriptor && (
          <Text
            size={16}
            font="regular"
            className="default-text wallet-payment-success-desc"
            variant="h6"
          >
            {paymentData.statement_descriptor}
          </Text>
        )}
      </Box>
    );
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={true}
      className="fullscreen-modal payment-success-modal"
      sx={{ overflow: "hidden" }}
    >
      <Lottie
        animationData={walletPaymentAnimation}
        loop={false}
        autoplay={true}
      />
      {paymentData && showPaymentDetails()}
    </Dialog>
  );
};

export default PaymentSuccessful;
