import { Modal } from "@speed/common/src/components/Modal/Modal";
import React, { useState, useEffect } from "react";
import {
  callAPIInterface,
  idealTimeLimitReached,
  updateSessionLastActionTime,
} from "../constants";
import Button from "@speed/common/src/components/Button/Button";
import { cancel } from "@speed/common/src/components/messages";
import { remove, removeAccount, removeAccountMsg } from "../messages";
import Text from "@speed/common/src/components/Text/Text";
import { sessionService } from "redux-react-session";
import {
  setConfirmAction,
  setExecuteAPICall,
  setLoading,
} from "../../redux/common/actions";
import { useDispatch, useSelector } from "react-redux";

const RemoveAccountModal = ({
  apiPath,
  callBackFn,
  open,
  handleClose,
  idToRemove,
}) => {
  const dispatch = useDispatch();
  const [isModalLoader, setIsModalLoader] = useState(false);
  const [session, setSession] = useState(null);

  // Redux states
  const executeAPICall = useSelector((state) => state.common.executeAPICall);
  const liveMode = useSelector((state) => state.auth.liveMode);

  const handleRemoveAccount = () => {
    setIsModalLoader(true);
    callAPIInterface("DELETE", `${apiPath}/${idToRemove}`, {})
      .then(() => {
        callBackFn();
        setIsModalLoader(false);
      })
      .catch(() => {
        setIsModalLoader(false);
      });
  };

  useEffect(() => {
    if (executeAPICall) {
      // api call of activate and deactivate link
      const newSession = updateSessionLastActionTime();
      setSession(newSession);
      handleRemoveAccount();
      dispatch(setLoading(false));
      dispatch(setConfirmAction(false));
      dispatch(setExecuteAPICall(false));
    }
  }, [executeAPICall]);

  const modalBody = (
    <Text size={18} font="regular" sx={{ flex: 1 }} variant="subtitle1">
      {removeAccountMsg}
    </Text>
  );

  useEffect(() => {
    sessionService.loadSession().then((newSession) => {
      setSession(newSession);
    });
  }, [liveMode, idToRemove]);

  const handleActionClick = () => {
    if (idealTimeLimitReached(session.last_action_time)) {
      dispatch(setConfirmAction(true));
    } else {
      // api call of remove connect account
      handleRemoveAccount();
    }
  };

  const modalFooter = () => {
    return (
      <>
        <Button
          label={cancel}
          variant="outlined"
          onClick={handleClose}
          sx={{ marginRight: "8px" }}
        />
        <Button
          label={remove}
          loading={isModalLoader}
          variant="contained"
          color="error"
          onClick={handleActionClick}
        />
      </>
    );
  };

  return (
    <Modal
      maxWidth="xs"
      body={modalBody}
      handleClose={handleClose}
      open={open}
      title={removeAccount}
      footer={modalFooter()}
    />
  );
};

export default RemoveAccountModal;
