import React from "react";
import GetGuides from "./GetGuides";

const InvoiceGuide = () => {
  return (
    <GetGuides
      moduleName="invoice"
      redirectUrl="/invoices"
      guideApiPath="invoice-guide"
      getStartedApiPath="Invoice"
      needHelpGuidePath="docs/receive-payments/invoicing"
    />
  );
};

export default InvoiceGuide;
