import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";

const MultiEmailTag = ({
  emails,
  emailPlaceholder,
  handleEmailChange,
  validateEmail,
  getEmailLabel,
  ...props
}) => {
  return (
    <ReactMultiEmail
      placeholder={emailPlaceholder}
      emails={emails}
      onChange={handleEmailChange}
      validateEmail={validateEmail}
      getLabel={getEmailLabel}
      {...props}
    />
  );
};
export default MultiEmailTag;
