import { Box, Grid } from "@mui/material";
import Label from "@speed/common/src/components/Label/Label";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  maximumPresetLabel,
  minLessThanMax,
  minimumPresetLabel,
  setLimitsLabel,
} from "../messages";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import InputMask from "@speed/common/src/components/MaskInput";
import {
  checkDisableButton,
  getExchangeRate,
  showSatsValidate,
} from "../Common/AllLinkFormConstant";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import {
  amountValidation,
  showAmountExceedError,
  validationCheck,
} from "@speed/common/src/components/constants";
import {
  presetAmount,
  presetAmountGrater,
  presetAmountLesser,
} from "@speed/common/src/messages";

const PresetAmount = forwardRef(
  (
    {
      formikValues,
      linkType,
      handleButtonDisable,
      isCheckedOtherInfo,
      parentDisable,
      selectedAssetCurrencyState,
      currentExchangeRate,
      setCurrentExchangeRate,
    },
    ref
  ) => {
    const [exchangeRate, setExchangeRate] = useState(false);
    const [satsPresetValidate, setSatsPresetValidate] = useState("");
    const [satsMinValidate, setSatsMinValidate] = useState("");
    const [satsMaxValidate, setSatsMaxValidate] = useState("");
    const [amountExceedPreset, setAmountExceedPreset] = useState("");
    const [amountExceedMin, setAmountExceedMin] = useState("");
    const [amountExceedMax, setAmountExceedMax] = useState("");
    const [currentAmountType, setCurrentAmountType] = useState("");

    const presetAmountRef = useRef(null);
    const minAmountRef = useRef(null);
    const maxAmountRef = useRef(null);
    const currencyCode = formikValues?.currency?.symbol;

    // Custom validation for Preset amount value
    const presetValidation = (value, parent) => {
      const { minimumAmount, maximumAmount, limits } = parent;
      if (value < minimumAmount && limits) {
        return {
          isValid: false,
          errorMessage: presetAmountGrater,
        };
      } else if (value > maximumAmount && limits) {
        return {
          isValid: false,
          errorMessage: presetAmountLesser,
        };
      } else {
        return { isValid: true };
      }
    };

    // Custom validation for minimum amount value
    const minimumValidation = (value, parent) => {
      const { maximumAmount, limits } = parent;
      if (value >= maximumAmount && limits) {
        return {
          isValid: false,
          errorMessage: minLessThanMax,
        };
      } else {
        return { isValid: true };
      }
    };

    const presetAmountValidationSchema = amountValidation
      .required("")
      .test("validation-preset-amount", function (value) {
        const validation = presetValidation(value, this.parent);
        if (!validation.isValid) {
          return this.createError({
            path: this.path,
            message: validation.errorMessage,
          });
        } else {
          return true;
        }
      });

    const minAmountValidationSchema = amountValidation.when(["limits"], {
      is: (limits) => limits,
      then: amountValidation
        .required("")
        .test("validation-min-amount", function (value) {
          const validation = minimumValidation(value, this.parent);
          if (!validation.isValid) {
            return this.createError({
              path: this.path,
              message: validation.errorMessage,
            });
          } else {
            return true;
          }
        }),
      otherwise: amountValidation.notRequired(""),
    });

    const maximumAmountValidation = amountValidation.when(["limits"], {
      is: (limits) => limits,
      then: amountValidation.required(""),
      otherwise: amountValidation.notRequired(),
    });

    const validationSchema = yup.object({
      presetAmount: presetAmountValidationSchema,
      minimumAmount: minAmountValidationSchema,
      maximumAmount: maximumAmountValidation,
    });

    const formik = useFormik({
      initialValues: {
        presetAmount: "",
        minimumAmount: "",
        maximumAmount: "",
        limits: false,
      },
      validationSchema: validationSchema,
    });

    const { values, errors, isValid, isSubmitting, dirty, setFieldValue } =
      formik;

    const formValues = { ...formikValues, ...values };

    useEffect(() => {
      if (!values.limits) {
        setFieldValue("minimumAmount", "");
        setFieldValue("maximumAmount", "");
      }
    }, [values.limits]);

    useImperativeHandle(ref, () => ({
      getPresetAmount: () => {
        return {
          presetAmountRef,
          presetAmountData: {
            ...values,
          },
        };
      },
      getPresetPayload: () => {
        let params = {
          amount: values.presetAmount,
          type: "preset",
        };
        if (values.limits) {
          params["preset"] = {
            min_amount: values.minimumAmount,
            max_amount: values.maximumAmount,
          };
        }
        return params;
      },
    }));

    useEffect(() => {
      const defaultCheck = !(isValid && dirty) || isSubmitting;
      const disable = defaultCheck || parentDisable;
      const amountExceedMsg =
        amountExceedPreset || amountExceedMin || amountExceedMax;
      const satsMsg = satsPresetValidate || satsMinValidate || satsMaxValidate;

      checkDisableButton({
        amountExceed: amountExceedMsg,
        satsValidate: satsMsg,
        values,
        exchangeRate,
        handleButtonDisable,
        from: "preset",
        defaultCheck: disable,
      });
    }, [
      dirty,
      isValid,
      isSubmitting,
      formValues,
      exchangeRate,
      isCheckedOtherInfo,
    ]);

    useEffect(() => {
      const timeOut = setTimeout(() => {
        if (values) {
          let amountRef;
          if (currentAmountType === "presetAmount") amountRef = presetAmountRef;
          else if (currentAmountType === "minimumAmount")
            amountRef = minAmountRef;
          else if (currentAmountType === "maximumAmount")
            amountRef = maxAmountRef;

          const valuesObj = {
            presetAmountData: { ...values },
            ...formikValues,
          };

          getExchangeRate({
            amountRef,
            values: valuesObj,
            errors,
            setCurrentExchangeRate,
            setExchangeRate,
            linkType,
            amountType: currentAmountType,
            presetAmountRef,
            targetCurrency: selectedAssetCurrencyState,
          });
        }
      }, 500);
      return () => clearTimeout(timeOut);
    }, [values, errors, selectedAssetCurrencyState]);

    const getValuesObj = (amount) => {
      return {
        currency: formValues.currency,
        amount,
      };
    };

    // SATS validation message for preset amount
    useEffect(() => {
      showSatsValidate(
        getValuesObj(values.presetAmount),
        setSatsPresetValidate
      );
    }, [values.presetAmount, formValues.currency]);

    // SATS validation message for minimum amount
    useEffect(() => {
      showSatsValidate(getValuesObj(values.maximumAmount), setSatsMaxValidate);
    }, [values.maximumAmount, formValues.currency]);

    // SATS validation message for maximum amount
    useEffect(() => {
      showSatsValidate(getValuesObj(values.minimumAmount), setSatsMinValidate);
    }, [values.minimumAmount, formValues.currency]);

    // BTC validation message for preset amount
    useEffect(() => {
      showAmountExceedError(
        getValuesObj(values.presetAmount),
        setAmountExceedPreset,
        currentExchangeRate,
        presetAmount
      );
    }, [values.presetAmount, currentExchangeRate, formValues.currency]);

    // BTC validation message for minimum amount
    useEffect(() => {
      showAmountExceedError(
        getValuesObj(values.minimumAmount),
        setAmountExceedMin,
        currentExchangeRate,
        minimumPresetLabel
      );
    }, [values.minimumAmount, currentExchangeRate, formValues.currency]);

    // BTC validation message for maximum amount
    useEffect(() => {
      showAmountExceedError(
        getValuesObj(values.maximumAmount),
        setAmountExceedMax,
        currentExchangeRate,
        maximumPresetLabel
      );
    }, [values.maximumAmount, currentExchangeRate, formValues.currency]);

    const handleKeyDown = (e) => {
      if (e.keyCode === 13) {
        setFieldValue("limits", !values.limits);
        setCurrentAmountType("presetAmount");
      }
    };

    const presetErrorCheck = validationCheck(
      "presetAmount",
      satsPresetValidate,
      amountExceedPreset,
      errors
    );
    const minErrorCheck = validationCheck(
      "minimumAmount",
      satsMinValidate,
      amountExceedMin,
      errors
    );
    const maxErrorCheck = validationCheck(
      "maximumAmount",
      satsMaxValidate,
      amountExceedMax,
      errors
    );

    // For preset, min & max amount input label
    const getLabel = (type) => {
      return (
        <>
          {type} ({currencyCode})
        </>
      );
    };

    // Show error messages
    const showErrorMessage = (message) => {
      return (
        <AlertMessage
          message={message}
          className="margin-top15"
          severity="error"
        />
      );
    };

    return (
      <Box className="preset-type-box">
        <Grid container>
          <Grid item lg={4} className="preset-amount-label">
            <Label>{getLabel(presetAmount)}</Label>
          </Grid>
          <Grid item lg={8}>
            <InputMask
              onChange={(e) => {
                setExchangeRate(true);
                presetAmountRef.current.amount = e.target.value;
                setFieldValue("presetAmount", e.target.value);
                setCurrentAmountType("presetAmount");
              }}
              ref={presetAmountRef}
              value={values.presetAmount}
              error={presetErrorCheck}
            />
            {presetErrorCheck &&
              showErrorMessage(
                errors.presetAmount || satsPresetValidate || amountExceedPreset
              )}
            <CustomCheckbox
              checked={values.limits}
              name="limits"
              label={setLimitsLabel}
              sx={{ marginTop: "25px" }}
              onKeyDown={handleKeyDown}
              onChange={(_e, checked) => {
                setFieldValue("limits", checked);
                setCurrentAmountType("presetAmount");
              }}
            />
            {values.limits && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "30px 0",
                }}
              >
                <Box width="49%">
                  <InputMask
                    onChange={(e) => {
                      setExchangeRate(true);
                      minAmountRef.current.amount = e.target.value;
                      setFieldValue("minimumAmount", e.target.value);
                      setCurrentAmountType("minimumAmount");
                    }}
                    ref={minAmountRef}
                    value={values.minimumAmount}
                    error={minErrorCheck}
                    label={getLabel(minimumPresetLabel)}
                  />
                  {minErrorCheck &&
                    showErrorMessage(
                      errors.minimumAmount || satsMinValidate || amountExceedMin
                    )}
                </Box>
                <Box width="49%">
                  <InputMask
                    onChange={(e) => {
                      setExchangeRate(true);
                      maxAmountRef.current.amount = e.target.value;
                      setFieldValue("maximumAmount", e.target.value);
                      setCurrentAmountType("maximumAmount");
                    }}
                    ref={maxAmountRef}
                    value={values.maximumAmount}
                    error={maxErrorCheck}
                    label={getLabel(maximumPresetLabel)}
                  />
                  {maxErrorCheck &&
                    showErrorMessage(
                      errors.maximumAmount || satsMaxValidate || amountExceedMax
                    )}
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
    );
  }
);

export default PresetAmount;
