import { Box } from "@mui/material";
import PaymentTable from "../Common/PaymentTable";

function AllPayments(props) {
  return (
    <Box className="section-wrapper">
      <Box className="payment-wrapper">
        <PaymentTable showHorizontalTable={false} tab={props.tab} />
      </Box>
    </Box>
  );
}

export default AllPayments;
