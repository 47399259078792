import React, { forwardRef } from "react";
import { AppBar, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Text from "@speed/common/src/components/Text/Text";
import Button from "@speed/common/src/components/Button/Button";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import { loadingMsg } from "@speed/common/src/components/messages";
import { create, createPaymentAddress } from "../messages";
import PaymentAddressRequestDetails from "./PaymentAddressRequestDetails";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreatePaymentAddressModal = (props) => {
  const {
    openModal,
    handleModalClose,
    isValid,
    dirty,
    isSubmitting,
    handleSubmit,
    values,
    setFieldValue,
    touched,
    setTouched,
    errors,
    defaultErrorMessageProps,
    addNewPaymentAddress,
    initialDomains,
    domainSkeleton,
    loadMoreDomains,
    hasMoreDomains,
  } = props;

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={openModal}
      TransitionComponent={Transition}
      className="fullscreen-modal"
      onClose={handleModalClose}
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1 }}
            className="default-text divider"
            variant="h6"
          >
            {createPaymentAddress}
          </Text>
          <Button
            label={create}
            variant="contained"
            color="primary"
            className="payment-link-btn"
            onClick={handleSubmit}
            disabled={!(isValid && dirty) || isSubmitting}
          />
        </Toolbar>
      </AppBar>
      <PaymentAddressRequestDetails
        values={values}
        setFieldValue={setFieldValue}
        touched={touched}
        setTouched={setTouched}
        errors={errors}
        defaultErrorMessageProps={defaultErrorMessageProps}
        addNewPaymentAddress={addNewPaymentAddress}
        initialDomains={initialDomains}
        domainSkeleton={domainSkeleton}
        loadMoreDomains={loadMoreDomains}
        hasMoreDomains={hasMoreDomains}
        isSubmitting={isSubmitting}
      />
      <BackdropLoader
        open={isSubmitting}
        alt="Loading..."
        customClass="loading-in-auth"
        text={loadingMsg}
      />
    </Dialog>
  );
};

export default CreatePaymentAddressModal;
