import React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import Text from "@speed/common/src/components/Text/Text";
import PayoutWalletSetupModal from "./PayoutWalletSetupModal";
import { completeSetup } from "../../../messages";
import { Box } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PayoutWalletSetupDialog = ({ isModalOpen, handleClose }) => {
  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={isModalOpen}
      onClose={handleClose}
      TransitionComponent={Transition}
      className="fullscreen-modal"
    >
      <AppBar
        sx={{ position: "relative", zIndex: 0 }}
        className="modal-app-bar"
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1 }}
            className="default-text divider"
            variant="h6"
          >
            {completeSetup}
          </Text>
        </Toolbar>
      </AppBar>
      <Box className="fullscreen-modal-box">
        <PayoutWalletSetupModal />
      </Box>
    </Dialog>
  );
};

export default PayoutWalletSetupDialog;
