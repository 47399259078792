import Text from "@speed/common/src/components/Text/Text";
import React from "react";
import {
  backToDashboard,
  paymentProcessingUpdate,
  paymentProcessingUpdateSubText,
} from "../../messages";
import { paymentProcessingImg } from "../../images";
import { Box } from "@mui/material";
import Button from "@speed/common/src/components/Button/Button";

const PaymentProcess = ({ handleModalClose }) => (
  <Box mt={"30px"} display="flex" flexDirection="column" alignItems="center">
    <img
      style={{ marginBottom: "16px" }}
      height="54px"
      width="54px"
      src={paymentProcessingImg}
      alt=""
    />
    <Text size={20} font="bold" className="default-text" variant="h6">
      {paymentProcessingUpdate}
    </Text>
    <Text
      size={16}
      font="regular"
      className="grey-text"
      variant="h6"
      sx={{ mt: "15px", width: "492px", textAlign: "center" }}
    >
      {paymentProcessingUpdateSubText}
    </Text>
    <Button
      label={backToDashboard}
      onClick={() => {
        handleModalClose();
      }}
      sx={{
        width: "500px",
        margin: "50px 0px 40px 0px",
        padding: "15px 22px !important",
      }}
      type="submit"
    />
  </Box>
);

export default PaymentProcess;
