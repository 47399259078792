import { useEffect, useState } from "react";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import { allowedRedirectionHostname } from "./constants";
import { sessionService } from "redux-react-session";
import history from "@speed/common/src/components/history";
import AES from "crypto-js/aes";
import { redirectingToURL } from "./messages";
import { authenticatingURL } from "@speed/common/src/messages";

const SessionSharing = () => {
  const [loadingText, setLoadingText] = useState(authenticatingURL);

  const redirectToUrl = async (uri, isFirestore) => {
    const { session, firebase_token, refresh_token } =
      await sessionService.loadSession();
    const params = {};
    const cipherSession = AES.encrypt(
      session,
      process.env.REACT_APP_SESSION_SHARING_SECRET_KEY
    ).toString();
    const cipherRefreshToken = AES.encrypt(
      refresh_token,
      process.env.REACT_APP_SESSION_SHARING_SECRET_KEY
    ).toString();
    params.session = cipherSession;
    params.refresh_token = cipherRefreshToken;

    if (isFirestore === "true") {
      const cipherFsToken = AES.encrypt(
        firebase_token,
        process.env.REACT_APP_SESSION_SHARING_SECRET_KEY
      ).toString();
      params.fs_token = cipherFsToken;
    }

    const queryParams = new URLSearchParams(params);

    window.open(`${uri}?${queryParams}`, "_self");
  };

  useEffect(() => {
    const redirect = sessionStorage.getItem("redirect");
    const isFirestore = sessionStorage.getItem("require_firebase_token");

    if (redirect) {
      sessionStorage.removeItem("redirect");
      sessionStorage.removeItem("require_firebase_token");
      const hostname = new URL(redirect).hostname;
      if (allowedRedirectionHostname.includes(hostname)) {
        setLoadingText(redirectingToURL);
        redirectToUrl(redirect, isFirestore);
        return;
      }
    }

    history.push("/dashboard");
  }, []);

  return (
    <BackdropLoader
      open={true}
      alt="Loading..."
      customClass="loading-in-auth"
      text={loadingText}
    />
  );
};

export default SessionSharing;
