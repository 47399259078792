import React from "react";
import { doneText, skipForNow, skipText, undoSkip } from "../messages";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import Text from "@speed/common/src/components/Text/Text";
import Button from "@speed/common/src/components/Button/Button";
import { Box, CircularProgress } from "@mui/material";
import history from "@speed/common/src/components/history";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

const ListInitialSetupSteps = ({
  filteredOnboardingSteps,
  getStatus,
  handleDeleteUserResponse,
  handleStepCompleted,
  guideStepsIds,
  setCurrentStepId,
  currentStepId,
  stepCompletedLoading,
  setSkipLoading,
  setStepCompletedLoading,
  skipLoading,
  isListLoading,
}) => {
  const checkStatus = (doneOrSkip, stepId) => {
    return (
      guideStepsIds?.includes(stepId.toString()) &&
      getStatus(stepId) === doneOrSkip
    );
  };
  return filteredOnboardingSteps?.map(
    (steps, index) =>
      !isListLoading && (
        <React.Fragment key={steps?.id}>
          <Box
            paddingBottom="40px"
            display="flex"
            flexDirection="row"
            paddingTop="40px"
          >
            {checkStatus(doneText, steps?.id) ? (
              <CheckCircleIcon className="success-icon" />
            ) : (
              <RadioButtonUncheckedIcon className="empty-icon" />
            )}
            <Box paddingLeft="16px">
              <Box
                sx={{
                  cursor: "pointer",
                  width: "fit-content",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onClick={() => {
                  if (getStatus(steps?.id) !== skipText) {
                    history.push(steps?.button_comp?.redirect_url);
                    setCurrentStepId(steps?.id);
                    handleStepCompleted(
                      steps?.id,
                      steps?.step_id,
                      doneText,
                      setStepCompletedLoading
                    );
                  }
                }}
              >
                <CustomLink
                  size="Large"
                  style={{
                    fontSize: "22px",
                    color: "#0A193E",
                    fontWeight: "600px",
                    fontFamily: "Inter-SemiBold",
                  }}
                >
                  {steps?.title}
                </CustomLink>
                <KeyboardArrowRightIcon
                  style={{
                    height: 24,
                    width: 24,
                    color: "#848B9E",
                  }}
                />
              </Box>
              <Text
                variant="body1"
                font="regular"
                className="grey-text"
                size={18}
                style={{ width: "700px" }}
              >
                {steps?.subtitle}
              </Text>
              {steps?.allowed_skip && getStatus(steps?.id) !== doneText && (
                <Box
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  marginTop="40px"
                >
                  {getStatus(steps?.id) !== skipText && (
                    <Button
                      label={steps?.button_comp?.title}
                      variant="contained"
                      color="primary"
                      className="payment-link-btn"
                      loading={
                        currentStepId === steps?.id
                          ? stepCompletedLoading
                          : false
                      }
                      onClick={() => {
                        setCurrentStepId(steps?.id);
                        handleStepCompleted(
                          steps?.id,
                          steps?.step_id,
                          doneText,
                          setStepCompletedLoading
                        );
                      }}
                      disabled={skipLoading || stepCompletedLoading}
                    />
                  )}
                  {currentStepId === steps?.id && skipLoading ? (
                    <CircularProgress
                      style={{
                        width: "15px",
                        height: "15px",
                        color: "#848b9e",
                        margin: "0px 5px 10px 60px",
                      }}
                    />
                  ) : (
                    <Text
                      style={{
                        lineHeight: "140%",
                        color: "#848B9E",
                        paddingLeft:
                          getStatus(steps?.id) !== skipText ? "24px" : "",
                        cursor: "pointer",
                        pointerEvents:
                          skipLoading || stepCompletedLoading ? "none" : "",
                      }}
                      font="semibold"
                      size={18}
                      onClick={() => {
                        setCurrentStepId(steps?.id);
                        if (guideStepsIds?.includes(steps?.id?.toString())) {
                          handleDeleteUserResponse(steps?.id);
                        } else {
                          handleStepCompleted(
                            steps?.id,
                            steps?.step_id,
                            skipText,
                            setSkipLoading
                          );
                        }
                      }}
                    >
                      {checkStatus(skipText, steps?.id) ? undoSkip : skipForNow}
                    </Text>
                  )}
                </Box>
              )}
            </Box>
          </Box>
          {index !== filteredOnboardingSteps?.length - 1 && (
            <Box
              className="steps-border"
              borderBottom="1px solid #E4E9EE"
              width="1100px"
            />
          )}
        </React.Fragment>
      )
  );
};

export default ListInitialSetupSteps;
