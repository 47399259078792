import React from "react";
import CommonMobileView from "../../Common/CommonMobileView";
import RenderConnectPreview from "@speed/common/src/components/Connect/RenderConnectPreview";
import { dummyConnectDomain } from "../../messages";

const ConnectMobileViewDisplay = (props) => {
  const params = {
    ...props,
    contentClassName: "connect-mobile-content",
    customClass: "connect-mobile-view",
    visibleInConnectPage: false,
    deviceType: "mobile",
  };

  return (
    <CommonMobileView
      marginTop="30px"
      marginBottom="200px"
      dummyDomain={dummyConnectDomain}
    >
      <RenderConnectPreview {...params} />
    </CommonMobileView>
  );
};

export default ConnectMobileViewDisplay;
