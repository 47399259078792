import React, { useState } from "react";
import { Box, Skeleton } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import { addAmount, addAmountOrNote, copiedToClipboard } from "../../messages";
import { CustomTooltip } from "@speed/common/src/components/Tooltip/Tooltip";
import Button from "@speed/common/src/components/Button/Button";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { actionsArray } from "../../constants";
import { toBlob, toPng } from "html-to-image";
import { copyBlobToClipboard } from "copy-image-clipboard";
import { useSelector } from "react-redux";

const ReceiveFooter = ({
  isLoading = false,
  setIsEnterCustomAmount = null,
  amount = null,
  setStep = null,
  receiveResponse = null,
  isPaymentAddress = false,
  rowPaymentAddress = null,
  getGeneratedAddress = null,
  divRef,
  isDisplayGeneratedAddress = false,
  selectedTab = 1,
}) => {
  const walletPaymentAddress = useSelector(
    (state) => state.common.walletPaymentAddress
  );

  const [copy, setCopy] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const handleDownload = () => {
    toPng(divRef.current, { skipFonts: true, cacheBust: true })
      .then((dataUrl) => {
        const link = document.createElement("a");
        const file = receiveResponse?.id || "qr-code";

        link.download = `${file}.png`;
        link.crossOrigin = "anonymous";
        link.href = dataUrl;
        link.click();
      })
      .catch((_err) => {});
  };

  const handleCopy = () => {
    toBlob(divRef.current, { skipFonts: true, cacheBust: true })
      .then((blob) => {
        return copyBlobToClipboard(blob);
      })
      .then(() => {
        setCopy(true);
        setTimeout(() => {
          setCurrentId("");
          setCopy(false);
        }, 3000);
      })
      .catch((_err) => {});
    setCopy(false);
  };

  const handleCopyInvoice = () => {
    let text;
    if (isPaymentAddress) {
      text = walletPaymentAddress;
    } else {
      text = isDisplayGeneratedAddress
        ? getGeneratedAddress()
        : rowPaymentAddress;
    }
    setCopy(true);
    setTimeout(() => {
      setCurrentId("");
      setCopy(false);
    }, 3000);
    navigator.clipboard.writeText(text);
  };

  const handleAction = (id) => {
    setCurrentId(id);
    switch (id) {
      case "copyQrCode":
        handleCopy();
        break;
      case "copyInvoice":
        handleCopyInvoice();
        break;
      case "downloadQR":
        handleDownload();
        break;
      default:
        break;
    }
  };
  return (
    <Box className="footer-section-receive">
      <Box className="action-section">
        {actionsArray.map((feature) => {
          return (
            <CustomTooltip
              text={copiedToClipboard}
              position="top-start"
              open={feature?.id === currentId && copy}
              key={feature?.id}
            >
              <Box
                className="actions"
                onClick={() => handleAction(feature?.id)}
              >
                {isLoading ? (
                  <Skeleton
                    className="actions"
                    animation={"wave"}
                    variant="circular"
                    width={50}
                    height={50}
                  />
                ) : (
                  <>
                    {feature.icon}
                    <Text className="default-text" size={16} font="semibold">
                      {feature.text}
                    </Text>
                  </>
                )}
              </Box>
            </CustomTooltip>
          );
        })}
      </Box>
      {!isPaymentAddress && (
        <>
          <CustomDivider />
          {isLoading ? (
            <Skeleton
              width={580}
              style={{ margin: "24px" }}
              animation={"wave"}
            />
          ) : (
            (amount === 0 || amount === null) && (
              <Button
                className="add-amount-note"
                label={selectedTab === 1 ? addAmountOrNote : addAmount}
                variant="outlined"
                onClick={() => {
                  setIsEnterCustomAmount(true);
                  setStep("2");
                }}
              />
            )
          )}
        </>
      )}
    </Box>
  );
};

export default ReceiveFooter;
