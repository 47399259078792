import React, { useContext, useEffect, useState } from "react";
import { ConnectContext } from "../../../contexts/contexts";
import history from "../../history";
import {
  connect,
  goBack,
  platFormCan,
  platFormCannot,
} from "../../../messages";
import {
  callAPIInterfaceConnect,
  callStrapiConnectAPIInterface,
} from "@speed/connect/src/components/constants";
import { Box } from "@mui/material";
import Text from "../../Text/Text";
import AccountLoader from "./AccountLoader";

const StandardCapabilities = () => {
  const [platformCanData, setPlatformCanData] = useState([]);
  const [platformCantData, setPlatformCantData] = useState([]);
  const [loader, setLoader] = useState(false);

  // Get context value using consumer
  const connectPageData = useContext(ConnectContext);

  useEffect(() => {
    sessionStorage.getItem("connect-account-success") &&
      history.push("/connect-account-selection");
  }, []);

  useEffect(() => {
    connectPageData?.setFirstBtnLabel(goBack);
    connectPageData?.setSecondBtnLabel(connect);
    connectPageData?.setButtonDisabled(false);
  }, []);

  const selectedConnectAccount = sessionStorage.getItem("account-details");

  useEffect(() => {
    const account =
      selectedConnectAccount && JSON.parse(selectedConnectAccount);
    connectPageData?.setSelectedAccountId(account);
  }, [selectedConnectAccount]);

  const setAccountDetails = () => {
    const selectedAccountDetails =
      selectedConnectAccount && JSON.parse(selectedConnectAccount);
    const id = selectedAccountDetails?.id;
    if (id) {
      connectPageData?.setButtonLoader(true);
      const session = connectPageData?.session;
      session &&
        connectPageData
          ?.setSession({ ...session, speed_acc_id: id })
          .then(() => {
            callAPIInterfaceConnect("GET", "/select-account")
              .then(() => {
                handleAccountSelection();
              })
              .catch(() => {
                connectPageData?.setButtonLoader(false);
              });
          });
    }
  };

  useEffect(() => {
    connectPageData?.setButtonDisabled(loader);
  }, [loader]);

  const handleAccountSelection = () => {
    const link = connectPageData?.sessionUser?.connectionLink;
    callAPIInterfaceConnect("POST", "/connect", { verification_link: link })
      .then(() => {
        connectPageData?.setButtonLoader(false);
        sessionStorage.removeItem("account-details");
        connectPageData?.setIsButtonClicked(false);
        sessionStorage.setItem("connect-account-success", true);
        history.push("/connect-account-success");
      })
      .catch(() => {
        connectPageData?.setButtonLoader(false);
        connectPageData?.setIsButtonClicked(false);
      });
  };

  useEffect(() => {
    if (connectPageData?.isButtonClicked && !loader) {
      setAccountDetails();
    }
  }, [connectPageData?.isButtonClicked, loader]);

  useEffect(() => {
    if (connectPageData?.goBackClicked) {
      sessionStorage.removeItem("account-details");
      history.push("/connect-account-selection");
    }
  }, [connectPageData?.goBackClicked]);

  useEffect(() => {
    setLoader(true);
    callStrapiConnectAPIInterface("GET", "/api/connected-guidelines?populate=*")
      .then((res) => {
        setLoader(false);
        setPlatformCanData(res.data[0]?.attributes?.platform_can);
        setPlatformCantData(res.data[0]?.attributes?.platform_cant);
      })
      .catch(() => {
        setLoader(false);
      });
  }, []);

  const showTable = () => {
    const arr = [];
    for (
      let i = 0;
      i < Math.max(platformCanData?.length, platformCantData?.length);
      i++
    ) {
      const boxStyle =
        i === 0
          ? { borderTopLeftRadius: "4px", borderTopRightRadius: "4px" }
          : i ===
              Math.max(platformCanData?.length, platformCantData?.length) -
                1 && {
              borderBottomLeftRadius: "4px",
              borderBottomRightRadius: "4px",
            };
      arr.push(
        <Box
          className="content-table"
          display="flex"
          justifyContent="space-between"
          sx={{ ...boxStyle }}
        >
          <Text
            size={14}
            font="regular"
            className="grey-text static-text"
            variant="subtitle1"
          >
            {platformCanData[i]?.text}
          </Text>
          <Text
            size={14}
            font="regular"
            className="grey-text static-text"
            variant="subtitle1"
          >
            {platformCantData[i]?.text}
          </Text>
        </Box>
      );
    }
    return arr;
  };

  return (
    <Box display="flex" width={800} marginBottom="44px" flexDirection="column">
      {loader ? (
        <Box display="flex" justifyContent="space-between">
          <AccountLoader customClass="partition" />
          <AccountLoader customClass="partition" />
        </Box>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between">
            <Text
              size={14}
              font="regular"
              className="grey-text"
              variant="subtitle1"
              sx={{ width: "50%" }}
            >
              {platFormCan}
            </Text>
            <Text
              size={14}
              font="regular"
              className="grey-text"
              variant="subtitle1"
              sx={{ width: "50%", marginLeft: "35px" }}
            >
              {platFormCannot}
            </Text>
          </Box>
          {platformCanData?.length > 0 && platformCantData?.length > 0 && (
            <Box className="standard-capabilities-box">{showTable()}</Box>
          )}
        </>
      )}
    </Box>
  );
};

export default StandardCapabilities;
