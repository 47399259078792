import Button from "@speed/common/src/components/Button/Button";
import { confirmBtn, verify } from "../../messages";
import { Grid } from "@mui/material";
import { QRCodeAndEnterTextToggle } from "../../constants";
import { continueBtn } from "@speed/common/src/messages";

const TwoFAModalFooter = (props) => {
  const {
    loading,
    isAppBtnDisabled,
    handleClose,
    handleTextChange,
    setIsAppContinue,
    isSMSBtnDisabled,
    handleSubmitSMS,
    isSMSContinue,
    selectedType,
    isAppContinue,
    byQRCode,
    handleSubmitOTP,
    isCaptchaVerified,
    handleCaptchaVerify,
  } = props;

  const getLabel = () => {
    if (!isCaptchaVerified) {
      return verify;
    }

    return isSMSContinue ? confirmBtn : continueBtn;
  };

  const handleOnClick = () => {
    if (!isCaptchaVerified) {
      handleCaptchaVerify();
      return;
    }

    return isSMSContinue ? handleSubmitOTP() : handleSubmitSMS();
  };

  const getSMSFooter = () => (
    <Button
      loading={loading}
      disabled={isSMSBtnDisabled}
      onClick={() => handleOnClick()}
      label={getLabel()}
    />
  );

  const getGoogleAuthFooter = () => (
    <Grid container justifyContent="space-between" alignItems="center">
      <Button
        disabled={isAppContinue ? isAppBtnDisabled : false}
        onClick={isAppContinue ? handleClose : setIsAppContinue}
        label={isAppContinue ? confirmBtn : continueBtn}
      />
      {!isAppContinue && (
        <QRCodeAndEnterTextToggle
          byQRCode={byQRCode}
          onClick={handleTextChange}
        />
      )}
    </Grid>
  );

  const renderFooterContent = (mfaType) => {
    switch (mfaType) {
      case "sms":
        return getSMSFooter();

      case "authenticator":
        return getGoogleAuthFooter();

      default:
        return null;
    }
  };

  return renderFooterContent(selectedType);
};

export default TwoFAModalFooter;
