import { accessDenied } from "./images";
import NoDataAvailable from "./NoDataAvailable";
import { Table, TableContainer } from "@mui/material";
import { accessDeniedText, notAuthorizedText } from "./messages";

const AccessDenied = () => {
  return (
    <TableContainer style={{ height: "auto" }}>
      <Table stickyHeader>
        <NoDataAvailable
          text={accessDeniedText}
          image={accessDenied}
          subText={notAuthorizedText}
        />
      </Table>
    </TableContainer>
  );
};

export default AccessDenied;
