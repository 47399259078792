import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { automaticSetupSubText, setUpText } from "../../messages";
import AutoPayoutSetupModal from "./AutoPayoutSetupModal";
import {
  getScheduledPayout,
  payoutLoaderComponent,
  payoutSchedulingValidationSchema,
  setPayoutSchDefaultVal,
  setupAutoPayoutSchedulingFunc,
} from "../../constants";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Text from "@speed/common/src/components/Text/Text";
import Button from "@speed/common/src/components/Button/Button";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import { useFlags } from "launchdarkly-react-client-sdk";
import CommonBoxWrapper from "./CommonBoxWrapper";
import AlreadySetupPayoutComponent from "./AlreadySetupPayoutComponent";

const PayoutScheduling = () => {
  const [openAutoPayoutModal, setOpenAutoPayoutModal] = useState(false);
  const [scheduledPayoutData, setScheduledPayoutData] = useState([]);
  const [isPayoutCreating, setIsPayoutCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [payoutScheduleId, setPayoutScheduleId] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState("SATS");

  const liveMode = useSelector((state) => state.auth.liveMode);
  const accountAsset = useSelector((state) => state.common.accountAsset);

  const { pschCreateFe } = useFlags();

  useEffect(() => {
    setScheduledPayoutData([]);
    getScheduledPayout({
      setIsLoading,
      setScheduledPayoutData,
      accountAsset,
    });
  }, [liveMode]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      auto_payout_enabled: "",
      payout_type: "",
      fees_type: "high",
      wallet_address: "",
      min_payout_amount: "",
      minimum_balance: "0",
      currency_type_payout: "",
      currency_type_balance: "",
      is_boltz: false,
    },
    validationSchema: payoutSchedulingValidationSchema,
    onSubmit: (values) => {
      handleSetupAutoPayout(values);
    },
  });

  const { values, setFieldValue, resetForm, validateForm } = formik;

  useEffect(() => {
    validateForm();
  }, [values.payout_type]);

  useEffect(() => {
    if (scheduledPayoutData?.id) {
      setFieldValue("auto_payout_enabled", scheduledPayoutData?.status);
    }
  }, [scheduledPayoutData?.status]);

  useEffect(() => {
    setPayoutSchDefaultVal({
      setFieldValue,
      isEditing,
      scheduledPayoutData,
      selectedCurrency,
    });
  }, [scheduledPayoutData, isEditing]);

  useEffect(() => {
    isDeactivating && handleSetupAutoPayout(values);
  }, [values.auto_payout_enabled]);

  const handleSetupAutoPayout = (values) => {
    setupAutoPayoutSchedulingFunc({
      values,
      isDeactivating,
      isEditing,
      setIsPayoutCreating,
      setScheduledPayoutData,
      payoutScheduleId,
      scheduledPayoutData,
      parentCallback: handleCloseAutoPayoutModal,
      selectedCurrency,
    });
  };

  const handleCloseAutoPayoutModal = () => {
    if (isEditing || isDeactivating) {
      setIsEditing(false);
      setIsDeactivating(false);
      setPayoutScheduleId(null);
    }
    setOpenAutoPayoutModal(false);
    resetForm();
  };

  const setupAutoPayout = (currency) => {
    return (
      !isLoading && (
        <Box display="flex" alignItems="center">
          <Button
            className="payout-button"
            variant="contained"
            label={setUpText}
            onClick={() => {
              setSelectedCurrency(currency);
              setOpenAutoPayoutModal(true);
            }}
          />
        </Box>
      )
    );
  };

  const beforeSetupPayoutComponent = (asset) => {
    return (
      <CommonBoxWrapper footer={pschCreateFe && setupAutoPayout(asset?.uuid)}>
        <Box display="flex" alignItems="start" key={asset?.uuid}>
          <Box mr="20px">
            <img
              src={asset?.icon}
              alt="currency-icon"
              height="40px"
              width="40px"
            />
          </Box>
          <Box>
            <Box mb="8px" display="flex" alignItems="center">
              <Text
                className="card-text"
                size={20}
                font="bold"
                align="left"
                style={{ paddingRight: "8px" }}
              >
                {asset?.name}
              </Text>
            </Box>
            <Box display="flex" flexDirection="row">
              <TruncatedTextTooltip
                textProps={{
                  className: "grey-text",
                }}
                textValue={automaticSetupSubText(asset?.name)}
                cellWidth="325px"
              />
            </Box>
          </Box>
        </Box>
      </CommonBoxWrapper>
    );
  };

  const propsToPass = {
    isPayoutCreating,
    setIsDeactivating,
    setPayoutScheduleId,
    formik,
    setIsEditing,
    setOpenAutoPayoutModal,
    setSelectedCurrency,
    selectedCurrency,
  };
  const displayPayout = scheduledPayoutData?.map((payoutData) => {
    return payoutData?.wallet ? (
      <AlreadySetupPayoutComponent
        {...propsToPass}
        scheduledPayoutData={payoutData}
      />
    ) : (
      beforeSetupPayoutComponent(payoutData)
    );
  });

  return (
    <Box className="auto-payout-wrapper setup-verify-payout-wallet">
      <CustomDivider />
      <Box className="box-container">
        {isLoading ? payoutLoaderComponent() : displayPayout}
      </Box>

      <AutoPayoutSetupModal
        openAutoPayoutModal={openAutoPayoutModal}
        formik={formik}
        isLoading={isPayoutCreating}
        handleCloseAutoPayoutModal={handleCloseAutoPayoutModal}
        selectedCurrency={selectedCurrency}
      />
    </Box>
  );
};

export default PayoutScheduling;
