import { Box } from "@mui/material";
import { Input } from "@speed/common/src/components/Input/Input";
import Text from "@speed/common/src/components/Text/Text";
import { forwardRef } from "react";
import {
  addressLine1Placeholder,
  addressLine2Placeholder,
  cityPlaceholder,
  enterNamePlaceHolder,
  postalCodePlaceholder,
  statePlaceholder,
  enterZIPCodePlaceHolder,
  shippingMobileNumberLabel,
  shippingNameLabel,
  enterStatePlaceHolder,
  enterCityPlaceHolder,
  postalCodeValidate,
} from "../messages";
import PhoneNumberInput from "@speed/common/src/components/PhoneNumberInput/PhoneNumberInput";
import { Countries } from "@speed/common/src/components/country";
import { getCountryObj } from "@speed/common/src/components/constants";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import CustomerAccountInfoForm from "./CustomerAccountInfoForm";
import CustomerBillingShippingForm from "./CustomerBillingShippingForm";
import { countryLabel, countryPlaceholder } from "@speed/common/src/messages";

const CreateCustomer = forwardRef(
  (
    {
      isNextStep,
      handleButtonDisable,
      accountFormData,
      confirmRef,
      fromInvoiceModal = false,
    },
    ref
  ) => {
    const setIsoCode = (values, typeOfInfo, setCountryISOCode) => {
      let countryObj = getCountryObj("calling_code", values[typeOfInfo]);
      if (countryObj) setCountryISOCode(countryObj.iso2);
    };

    const infoTitleElement = (title, fontSize = fromInvoiceModal ? 16 : 18) => (
      <Box className="customer-form-info-title">
        <Text size={fontSize}>{title}</Text>
      </Box>
    );

    const commonMobileNumberInput = (
      formik,
      callingCode,
      phoneNumber,
      isoCode,
      label
    ) => {
      const { touched, errors, values, setFieldValue, setTouched } = formik;
      return (
        <Box className="customer-mobile-number margin-top30">
          <PhoneNumberInput
            name={phoneNumber}
            label={label}
            options={Countries}
            error={touched[phoneNumber] && Boolean(errors[phoneNumber])}
            selectValue={values[callingCode]}
            countryIsoCode={isoCode}
            MenuProps={{ id: "cusomer-select-calling-code" }}
            inputValue={values[phoneNumber]}
            showLabel={true}
            onOpen={() => {
              document.body.classList.add("select-popover");
            }}
            onClose={() =>
              setTimeout(() => {
                document.body.classList.remove("select-popover");
              }, 400)
            }
            onChangeSelect={(value) => {
              setFieldValue(
                callingCode,
                typeof value === "object" ? value[callingCode] : value
              );
            }}
            onBlur={() => setTouched({ ...touched, [phoneNumber]: true })}
            onChange={(value) => {
              setTouched({ ...touched, [phoneNumber]: false });
              setFieldValue(phoneNumber, value);
            }}
          />
        </Box>
      );
    };

    const commonAddressInfoSection = ({
      formik,
      line1,
      line2,
      country,
      setCountryIso,
      state,
      city,
      zipcode,
      isFromShipping = false,
      shippingMobileISOCode,
      resetAddressFormDetails,
    }) => {
      const { touched, errors, values, setFieldValue, setTouched } = formik;

      return (
        <>
          {isFromShipping && (
            <Input
              type="text"
              name="shipping_name"
              value={values.shipping_name}
              inputProps={{ maxLength: 250 }}
              showLabel
              label={shippingNameLabel}
              customClass="margin-top30"
              fullWidth
              placeholder={enterNamePlaceHolder}
              onBlur={() => setTouched({ ...touched, shipping_name: true })}
              onChange={(e) => {
                setFieldValue("shipping_name", e.target.value);
              }}
            />
          )}

          <CustomAutoComplete
            {...(fromInvoiceModal && { className: "auto-complete-icon" })}
            name={country}
            value={values[country]}
            allowedAutoComplete={true}
            customClass="margin-top30"
            placeholder={countryPlaceholder}
            showLabel
            label={countryLabel}
            options={Countries}
            getOptionLabel={(optionValues) => {
              let country = getCountryObj(
                "short_name",
                typeof optionValues === "object"
                  ? optionValues.short_name
                  : optionValues
              );
              return country ? country.short_name : "";
            }}
            onBlur={() => setTouched({ ...touched, [country]: true })}
            onChange={async (_e, changeCountryValues) => {
              if (changeCountryValues) {
                await setCountryIso(changeCountryValues.iso2);
              } else {
                await setCountryIso();
                resetAddressFormDetails();
              }
              setTouched({ ...touched, [country]: false });
              setFieldValue(
                country,
                changeCountryValues && typeof changeCountryValues === "object"
                  ? changeCountryValues.short_name
                  : changeCountryValues
              );
            }}
          />

          {isFromShipping &&
            commonMobileNumberInput(
              formik,
              "shipping_calling_code",
              "shipping_phone_number",
              shippingMobileISOCode,
              shippingMobileNumberLabel
            )}

          {values[country] && (
            <>
              <Input
                type="text"
                name={line1}
                value={values[line1]}
                inputProps={{ maxLength: 512 }}
                showLabel
                label={addressLine1Placeholder}
                customClass="margin-top30"
                fullWidth
                placeholder={addressLine1Placeholder}
                onBlur={() => setTouched({ ...touched, [line1]: true })}
                onChange={(e) => {
                  setFieldValue(line1, e.target.value);
                }}
              />

              <Input
                type="text"
                name={line2}
                value={values[line2]}
                inputProps={{ maxLength: 512 }}
                showLabel
                label={addressLine2Placeholder}
                customClass="margin-top30"
                fullWidth
                placeholder={addressLine2Placeholder}
                onChange={(e) => {
                  setFieldValue(line2, e.target.value);
                }}
              />

              <Input
                type="text"
                name={city}
                inputProps={{ maxLength: 40 }}
                showLabel
                label={cityPlaceholder}
                value={values[city]}
                customClass="margin-top30"
                fullWidth
                placeholder={enterCityPlaceHolder}
                onBlur={() => setTouched({ ...touched, [city]: true })}
                onChange={(e) => {
                  setFieldValue(city, e.target.value);
                }}
              />

              <Input
                type="text"
                name={state}
                inputProps={{ maxLength: 40 }}
                showLabel
                label={statePlaceholder}
                value={values[state]}
                customClass="margin-top30"
                fullWidth
                placeholder={enterStatePlaceHolder}
                onBlur={() => setTouched({ ...touched, [state]: true })}
                onChange={(e) => {
                  setFieldValue(state, e.target.value);
                }}
              />

              <Input
                type="text"
                name={zipcode}
                inputProps={{ maxLength: 10 }}
                value={values[zipcode]}
                showLabel
                label={postalCodePlaceholder}
                customClass="margin-top30"
                fullWidth
                placeholder={enterZIPCodePlaceHolder}
                onBlur={() => setTouched({ ...touched, [zipcode]: true })}
                onChange={(e) => {
                  setFieldValue(zipcode, e.target.value);
                }}
              />
              {touched[zipcode] && Boolean(errors[zipcode]) && (
                <AlertMessage
                  className="margin-top14"
                  severity="error"
                  message={postalCodeValidate}
                />
              )}
            </>
          )}
        </>
      );
    };

    const renderBillingShippingSection = () => (
      <CustomerBillingShippingForm
        fromInvoiceModal={fromInvoiceModal}
        ref={confirmRef}
        accountFormData={accountFormData}
        infoTitleElement={infoTitleElement}
        setIsoCode={setIsoCode}
        handleButtonDisable={handleButtonDisable}
        commonAddressInfoSection={commonAddressInfoSection}
        isNextStep={isNextStep}
      />
    );

    return (
      <Box className={!fromInvoiceModal && "create-customer-modal"}>
        <Box className={!fromInvoiceModal && "customer-form-wrapper"}>
          <CustomerAccountInfoForm
            fromInvoiceModal={fromInvoiceModal}
            ref={ref}
            infoTitleElement={infoTitleElement}
            commonMobileNumberInput={commonMobileNumberInput}
            setIsoCode={setIsoCode}
            handleButtonDisable={handleButtonDisable}
            isNextStep={isNextStep}
            renderBillingShippingSection={renderBillingShippingSection}
          />
          {!fromInvoiceModal && renderBillingShippingSection()}
        </Box>
      </Box>
    );
  }
);

export default CreateCustomer;
