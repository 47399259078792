import React, { useState } from "react";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import {
  sendReceipt,
  sendTestReceipt,
  sendEmails,
  deliverTo,
} from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import { Grid } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import MultiEmailWithAlert from "../MultiEmailWithAlert";

const CreateReceipt = ({ handleClose, page }) => {
  const [emails, setEmails] = useState([]);
  const [invalidEmailData, setInValidEmailData] = useState("");
  const [invalidEmailFlag, setInValidEmailFlag] = useState(false);
  const [limitEmail, setLimitEmail] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const handleReceipt = () => {
    setDisabled(true);
    handleClose();
  };

  const bodyContent = (
    <Grid container direction="column" component="form" autoComplete="off">
      <Text className="label-text" size={14} font="regular" variant="caption">
        {deliverTo}
      </Text>
      <MultiEmailWithAlert
        emails={emails}
        setEmails={setEmails}
        invalidEmailFlag={invalidEmailFlag}
        setInValidEmailFlag={setInValidEmailFlag}
        invalidEmailData={invalidEmailData}
        setInValidEmailData={setInValidEmailData}
        limitEmail={limitEmail}
        setLimitEmail={setLimitEmail}
        setDisabled={setDisabled}
      />
    </Grid>
  );

  return (
    <Modal
      maxWidth="xs"
      body={bodyContent}
      handleClose={handleClose}
      open={true}
      footer={
        <Button
          disabled={disabled}
          label={sendEmails}
          onClick={handleReceipt}
        />
      }
      title={page === "branding" ? sendTestReceipt : sendReceipt}
    />
  );
};
export default CreateReceipt;
