import React, { useEffect, useState } from "react";
import { AppBar, Box, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Text from "@speed/common/src/components/Text/Text";
import Button from "@speed/common/src/components/Button/Button";
import { back, next } from "@speed/common/src/components/messages";
import CreateEndpointForm from "./CreateEndpointForm";
import { addWebhookEndpoint, confirmDetails, selectEvents } from "../messages";
import EventForm from "./EventForm";
import ConfirmEndPointDetails from "./ConfirmEndPointDetails";
import { callAPIInterface } from "../constants";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateEndPointModal = (props) => {
  const {
    open,
    handleClose,
    createWebhookPage,
    handleBackButton,
    handleNextButton,
    events,
    isCheckAll,
    isGlobalCheckAll,
    handleChangeAccordion,
    expanded,
    list,
    handleClick,
    handleSelectAll,
    handleGlobalSelectAll,
    counts,
    values,
    setFieldValue,
    touched,
    setTouched,
    isValid,
    dirty,
    errors,
    disableConfirm,
    liveMode,
    eventsSkeleton,
  } = props;

  const [versions, setVersions] = useState(null);

  useEffect(() => {
    getAccountWebhookVersion();
  }, []);

  const getAccountWebhookVersion = async () => {
    await callAPIInterface("GET", "/account/webhooks-versions")
      .then((res) => {
        if (res) {
          setVersions(res);
        }
      })
      .catch((error) => {});
  };

  const requestBody = () => {
    if (createWebhookPage === 2) {
      return (
        <EventForm
          events={events}
          isCheckAll={isCheckAll}
          isGlobalCheckAll={isGlobalCheckAll}
          handleChangeAccordion={handleChangeAccordion}
          expanded={expanded}
          list={list}
          handleClick={handleClick}
          handleSelectAll={handleSelectAll}
          handleGlobalSelectAll={handleGlobalSelectAll}
          counts={counts}
          liveMode={liveMode}
          eventsSkeleton={eventsSkeleton}
        />
      );
    } else if (createWebhookPage === 3) {
      return (
        <ConfirmEndPointDetails
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          setTouched={setTouched}
          handleBackButton={handleBackButton}
          handleClick={handleClick}
          errors={errors}
          versions={versions}
          createWebhookPage={createWebhookPage}
        />
      );
    } else {
      return (
        <CreateEndpointForm
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          setTouched={setTouched}
          errors={errors}
          versions={versions}
          createWebhookPage={createWebhookPage}
        />
      );
    }
  };
  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      className="fullscreen-modal"
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1 }}
            className="default-text divider"
            variant="h6"
          >
            {createWebhookPage === 2
              ? selectEvents
              : createWebhookPage === 3
              ? confirmDetails
              : addWebhookEndpoint}
          </Text>
          {createWebhookPage === 2 ? (
            <>
              <Button
                variant="outlined"
                color="primary"
                label={back}
                onClick={handleBackButton}
                sx={{ marginRight: "18px" }}
              />
              <Button
                label={next}
                variant="contained"
                color="primary"
                className="payment-link-btn"
                onClick={handleNextButton}
                disabled={events?.length <= 0}
              />
            </>
          ) : createWebhookPage === 3 ? (
            <>
              <Button
                variant="outlined"
                color="primary"
                label={back}
                onClick={handleBackButton}
                sx={{ marginRight: "18px" }}
                disabled={disableConfirm}
              />
              <Button
                label={addWebhookEndpoint}
                variant="contained"
                color="primary"
                className="payment-link-btn"
                onClick={handleNextButton}
                disabled={
                  !(isValid && dirty) || events?.length <= 0 || disableConfirm
                }
              />
            </>
          ) : (
            <Button
              label={next}
              variant="contained"
              color="primary"
              className="payment-link-btn"
              onClick={handleNextButton}
              disabled={!(isValid && dirty)}
            />
          )}
        </Toolbar>
      </AppBar>
      <Box className="fullscreen-modal-box">
        <Box className="modal-container-grid" sx={{ display: "flex" }}>
          <Box className="inner-grid" width="50%" overflow="auto" pb="30px">
            <Box pl={12.5} pr={20}>
              {versions && requestBody()}
            </Box>
          </Box>
          <Box
            pl="70px"
            className="inner-grid"
            width="50%"
            overflow="auto"
          ></Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateEndPointModal;
