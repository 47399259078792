import React from "react";
import GetGuides from "./GetGuides";

const PointOfSaleGuide = () => {
  return (
    <GetGuides
      moduleName="point_of_sale"
      redirectUrl="/speed-point-of-sale"
      guideApiPath="point-of-sale"
      getStartedApiPath="Point"
    />
  );
};

export default PointOfSaleGuide;
