import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import { useFlags } from "launchdarkly-react-client-sdk";

import LinkNotFound from "@speed/common/src/components/LinkNotFound";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import InstantSendSkeleton from "./InstantSendSkeleton";
import Text from "@speed/common/src/components/Text/Text";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import {
  clipboardElement,
  generateBreadcrumbs,
  getCurrencyObj,
  redirectWhenEntityNotFound,
} from "@speed/common/src/components/constants";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  instantSendStatus,
  ISWithdrawMethodsObj,
  RouteBreadcrumbs,
} from "../constants";
import {
  createdOn,
  details,
  entityNotFoundMessage,
  failureReason,
  instantSendLabel,
  instantSendNotFoundMsg,
  instantSendNotFoundSubMsg,
  networkFees,
  note,
  sendTo,
  status,
  type,
} from "../messages";
import TooltipOverText from "../Common/TooltipOverText";

const InstantSendDetail = (props) => {
  const liveMode = useSelector((state) => state.auth.liveMode);
  const urlParams = useParams();
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const { isDetailinstantsendFe } = useFlags();

  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [data, setData] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(false);

  const getInstantSendDetail = async () => {
    setData(null);
    setIsShowNotFound(false);
    setShowSkeleton(true);
    try {
      const result = await callAPIInterface("GET", `/send/${urlParams.id}`);
      setData(result);
    } catch (e) {
      setIsShowNotFound(redirectWhenEntityNotFound(e));
    }
    setShowSkeleton(false);
  };

  useEffect(() => {
    isDetailinstantsendFe && getInstantSendDetail();
  }, [liveMode, isDetailinstantsendFe]);

  const headerContent = () => (
    <Box className="header-content">
      <Text className="default-text" variant="h1" size={24}>
        {instantSendLabel}
      </Text>
      <Text
        className="default-text"
        sx={{ color: "#2A67FF !important", mt: "12px" }}
        variant="subtitle1"
        font="semibold"
        size={20}
      >
        {`${data?.target_amount} ${data?.target_currency}`}
      </Text>
      {data?.target_currency !== data?.currency && (
        <Text
          className="grey-text"
          size={16}
          font="medium"
          sx={{ mt: "12px" }}
          variant="subtitle"
        >
          ≈ {getCurrencyObj(data.currency)?.symbol}
          {data.amount}
        </Text>
      )}
      <Box marginTop="18px">
        {clipboardElement(
          data?.id,
          true,
          "instant-send-detail-id",
          "inputBox",
          false
        )}
      </Box>
    </Box>
  );

  const detailsTableData = () => {
    const leftSideArr = [
      {
        header: createdOn,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {dateTimeFormatInApp(data?.created)}
          </Text>
        ),
      },
      {
        header: status,
        cell: (
          <Tag
            text={instantSendStatus[data?.status]?.label}
            variant={instantSendStatus[data?.status]?.variant}
          />
        ),
      },
      {
        header: sendTo,
        cell: clipboardElement(
          data.withdraw_request,
          true,
          "instant-send-to-clipboard detail",
          "inputBox",
          false
        ),
      },
    ];

    data?.failure_reason &&
      leftSideArr.push({
        header: failureReason,
        cell: (
          <TooltipOverText
            textToHover={data?.failure_reason}
            customClass="instant-send-note"
            length={122}
          />
        ),
      });

    const rightSideArr = [
      {
        header: type,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {ISWithdrawMethodsObj[data?.withdraw_type]?.label}
          </Text>
        ),
      },
      {
        header: networkFees,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {data?.fees || 0} {data?.target_currency}
          </Text>
        ),
      },
    ];

    data?.note &&
      rightSideArr.push({
        header: note,
        cell: (
          <TooltipOverText
            textToHover={data?.note}
            customClass="instant-send-note"
            length={122}
          />
        ),
      });

    return {
      rowData: leftSideArr,
      rightSideRowData: rightSideArr,
    };
  };

  const showData = () => {
    return data ? (
      <>
        {headerContent()}
        <Box className="details-content">
          <VerticalTable label={details} {...detailsTableData()} />
        </Box>
      </>
    ) : (
      showSkeleton && <InstantSendSkeleton props={props} />
    );
  };

  const detailContent = () => {
    return !isShowNotFound ? (
      <Grid className="payments-detail-wrapper" container spacing={1}>
        <Box className="section-wrapper" sx={{ position: "relative" }}>
          <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
          {showData()}
        </Box>
      </Grid>
    ) : (
      <Box className="not-found-wrapper">
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        <LinkNotFound
          icon={<WarningIcon className="warning-icon" />}
          title={entityNotFoundMessage("Instant send")}
          subTitle={instantSendNotFoundMsg(urlParams.id)}
          btnText={instantSendNotFoundSubMsg(liveMode)}
        />
      </Box>
    );
  };

  return isDetailinstantsendFe ? detailContent() : <AccessDenied />;
};

export default InstantSendDetail;
