import { Box, MenuItem } from "@mui/material";
import CustomSelect from "@speed/common/src/components/Select/Select";
import React from "react";
import { accountType, staticText2, staticTextConnect } from "../../messages";
import Text from "@speed/common/src/components/Text/Text";
import InfoIcon from "@mui/icons-material/Info";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import { CustomLink } from "@speed/common/src/components/Link/Link";

const ConnectModalBody = ({
  typeSelected,
  generatedLink,
  setSelectedValue,
  selectedValue,
}) => {
  return (
    <Box className="connect-account-create-modal">
      {!typeSelected ? (
        <CustomSelect
          native={false}
          showLabel={true}
          fullWidth={true}
          label={accountType}
          value={selectedValue}
          onChange={(e) => setSelectedValue(e.target.value)}
          MenuProps={{
            id: "account-type-select",
          }}
          sx={{
            mb: "16px",
          }}
        >
          {["Standard"].map((item) => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </CustomSelect>
      ) : (
        <>
          <Box
            className="static-text-box"
            sx={{
              height: "41px",
              background: "#E4E9EE",
            }}
          >
            <Text
              withIcon="start"
              size={12}
              font="regular"
              variant="h6"
              className="default-text"
            >
              <InfoIcon
                sx={{
                  height: "24px",
                  width: "24px",
                }}
              />
              {staticTextConnect}
            </Text>
          </Box>
          <Text
            size={14}
            font="regular"
            variant="h6"
            className="default-text"
            sx={{ mt: "20px", mb: "24px" }}
          >
            {staticText2}
          </Text>
          <Box className="connect-link-box-wrapper">
            <Box className="connect-link-box">
              <CustomLink size="large" className="connect-link">
                {generatedLink}
              </CustomLink>
            </Box>
            <Clipboard text={generatedLink} />
          </Box>
        </>
      )}
    </Box>
  );
};

export default ConnectModalBody;
