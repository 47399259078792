import React from "react";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import { Box } from "@mui/system";
import VerticalTable from "@speed/common/src/components/VerticalTable";

const PaymentsSkeleton = () => {
  const headerContent = () => {
    return (
      <Box className="header-content">
        <CommonSkeleton
          color="#c4ccd2"
          width={168}
          height={39}
          sx={{ marginTop: "17px", borderRadius: "17px" }}
        />
        <Box className="header-price-content">
          <CommonSkeleton
            width={53}
            sx={{ marginTop: "4px", borderRadius: "8px" }}
          />
          <Box>
            <CommonSkeleton
              width={212}
              height={31}
              sx={{ marginTop: "4px", borderRadius: "15px" }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  const detailsTableData = () => {
    return [
      {
        header: (
          <CommonSkeleton
            width={52}
            sx={{ marginTop: "9px", borderRadius: "8px" }}
          />
        ),
        cell: (
          <CommonSkeleton
            width={75}
            sx={{ marginTop: "9px", borderRadius: "8px" }}
            color="#c4ccd2"
          />
        ),
      },
      {
        header: <CommonSkeleton width={102} sx={{ borderRadius: "8px" }} />,
        cell: (
          <CommonSkeleton
            width={176}
            color="#c4ccd2"
            sx={{ borderRadius: "8px" }}
          />
        ),
      },
      {
        header: <CommonSkeleton width={102} sx={{ borderRadius: "8px" }} />,
        cell: (
          <CommonSkeleton
            width={176}
            color="#c4ccd2"
            sx={{ borderRadius: "8px" }}
          />
        ),
      },
    ];
  };

  return (
    <>
      {headerContent()}

      {
        <Box className="details-content" marginTop={"48px !important"}>
          <VerticalTable
            label={
              <CommonSkeleton
                width={160}
                height={18}
                color="#c4ccd2"
                sx={{ borderRadius: "8px" }}
              />
            }
            rowData={detailsTableData()}
            color="#c4ccd2"
          />
        </Box>
      }
    </>
  );
};

export default PaymentsSkeleton;
