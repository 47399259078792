import React, { useState } from "react";
import PricingModalSelectComponent from "./PricingModalSelectComponent";
import PriceComponent from "./PriceComponent";
import { Box, Grid } from "@mui/material";
import Button from "@speed/common/src/components/Button/Button";
import { add, cancel } from "../messages";
import { callAPIInterface } from "../constants";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";

const SinglePriceModal = ({
  setIsModalOpen,
  formik,
  getProductPriceList,
  urlParams,
  initialValues,
  title,
  isModalOpen,
}) => {
  const {
    values,
    isValid,
    dirty,
    isSubmitting,
    resetForm,
    setFieldValue,
    errors,
  } = formik;

  const [addPriceLoader, setAddPriceLoader] = useState(false);

  const renderAddPriceModalBody = () => {
    return (
      <Box>
        <PricingModalSelectComponent
          value={values.modal}
          name="modal"
          formik={formik}
        />
        <PriceComponent
          currencyName="currency"
          currencyValue={values.currency}
          amountName="amount"
          amountValue={values.amount}
          handleChangeCurrency={(val) => setFieldValue("currency", val)}
          formik={formik}
          className="add-currency"
        />
        {errors?.amount && (
          <AlertMessage
            message={errors?.amount}
            className="margin-top15"
            severity="error"
          />
        )}
      </Box>
    );
  };

  const handleAddPrice = () => {
    const params = {
      currency: values.currency.code,
      product: urlParams?.id,
      unit_amount: values.amount,
    };

    setAddPriceLoader(true);
    callAPIInterface("POST", "/prices", params)
      .then(() => {
        setIsModalOpen(false);
        setAddPriceLoader(false);
        getProductPriceList();
        resetForm(initialValues);
      })
      .catch(() => {
        setAddPriceLoader(false);
      });
  };

  const onCloseForm = () => {
    resetForm(initialValues);
    setIsModalOpen(false);
  };

  const renderFooter = () => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="flex-start"
        display="flex"
      >
        <Button
          variant="outlined"
          style={{ width: "102px" }}
          label={cancel}
          onClick={onCloseForm}
        />
        <Button
          label={add}
          color="primary"
          sx={{ marginLeft: "16px" }}
          loading={addPriceLoader}
          onClick={handleAddPrice}
          disabled={!(isValid && dirty) || isSubmitting}
        />
      </Grid>
    );
  };
  return (
    <Modal
      body={renderAddPriceModalBody()}
      footer={renderFooter()}
      handleClose={onCloseForm}
      open={isModalOpen}
      title={title}
    />
  );
};

export default SinglePriceModal;
