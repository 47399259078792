import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import CommonHeader from "../Common/CommonHeader";
import VerifiedUserModal from "../Common/VerifiedUserModal";
import { sessionService } from "redux-react-session";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import DashboardCard from "./DashboardCard";
import "../../assets/styles/dashboard.scss";
import { loadingMsg } from "@speed/common/src/components/messages";
import { useSelector } from "react-redux";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import { callAPIInterface, getTimeZoneAndDateFormat } from "../constants";
import history from "@speed/common/src/components/history";
import RecentSummaryTable from "./RecentSummaryTable";
import {
  viewBalance,
  accountBalance,
  balance,
  payments,
  payouts,
  pendingPayoutsBalance,
  recentPayments,
  recentPayouts,
  recentTransactions,
  recentWithdrawals,
  transactionChart,
  transactions,
  transactionSummary,
  withdraws,
} from "../messages";
import {
  recentPayoutTableHeader,
  recentPaymentTableHeader,
  recentWithdrawalTableHeader,
  recentTransactionTableHeader,
} from "./utilities";
import { utcTimestampForDashboard } from "@speed/common/src/components/constants";
import TransactionChart from "./TransactionChart";
import JoinInvite from "../Common/JoinInvite";

function Dashboard() {
  const [loading, setLoading] = useState(false);
  const [openUserModal, setOpenUserModal] = useState(false);
  const [paymentRows, setPaymentRows] = useState([]);
  const [payoutRows, setPayoutRows] = useState([]);
  const [withdrawRows, setWithdrawRows] = useState([]);
  const [transactionRows, setTransactionRows] = useState([]);
  const [balanceSummary, setBalanceSummary] = useState();
  const [txnChartData, setTxnChartData] = useState();
  const [dashboardLoader, setDashboardLoader] = useState({
    [payments]: false,
    [payouts]: false,
    [withdraws]: false,
    [transactions]: false,
    [balance]: false,
    [pendingPayoutsBalance]: false,
    [transactionSummary]: false,
  });
  const liveMode = useSelector((state) => state.auth.liveMode);
  const { timezone: timezoneToUse } = getTimeZoneAndDateFormat();
  const timezone = timezoneToUse || "UTC";
  const handleUserClose = () => {
    history.push("/initial-setup-guide");
    setOpenUserModal(false);
  };

  const handleRedirection = () => {
    const searchURL = history.location.state?.searchURL;
    if (searchURL) {
      const data = new URLSearchParams(searchURL);
      const from = data.get("from");
      const redirect = data.get("redirect");
      if (from === "documentation" && redirect) {
        history.push("/documentation", {
          redirectDetails: { redirect },
        });
      }
    }
  };

  const transactionSummaryDetails = () => {
    // Get 30 days ago start of day timestamp
    const startTime = utcTimestampForDashboard({
      timezone,
      isStartDay: true,
      days: 29,
      operation: "subtract",
    });
    // Get today's end of the day timestmap
    const endTime = utcTimestampForDashboard({
      timezone,
    });
    const payload = {
      timezone,
      target_currency: "SATS",
      start_time: startTime,
      end_time: endTime,
    };
    setDashboardLoader((prev) => ({ ...prev, [transactionSummary]: true }));
    setTxnChartData();
    callAPIInterface("POST", "/dashboard/transaction_summary", payload)
      .then((res) => {
        if (res) {
          setDashboardLoader((prev) => ({
            ...prev,
            [transactionSummary]: false,
          }));
          setTxnChartData(res);
        }
      })
      .catch((err) => {
        setDashboardLoader((prev) => ({
          ...prev,
          [transactionSummary]: false,
        }));
      });
  };

  const accountBalanceDetails = () => {
    // Get 7Days ago timestamp in UTC
    const balance_as_on_date = utcTimestampForDashboard({
      timezone,
      operation: "subtract",
      days: 7,
    });
    const balanceSummaryData = {
      balance_as_on_date: balance_as_on_date,
      target_currency: "SATS",
    };
    setDashboardLoader((prev) => ({ ...prev, [balance]: true }));
    setBalanceSummary();
    callAPIInterface("POST", "/dashboard/balance_summary", balanceSummaryData)
      .then((res) => {
        setDashboardLoader((prev) => ({ ...prev, [balance]: false }));
        setBalanceSummary(res);
      })
      .catch((err) => {
        setDashboardLoader((prev) => ({ ...prev, [balance]: false }));
      });
  };

  const recentPaymentsDetails = () => {
    const paymentData = {
      status: ["paid"],
      limit: 10,
      ending_before: "",
    };
    setPaymentRows([]);
    setDashboardLoader((prev) => ({ ...prev, [payments]: true }));
    callAPIInterface("POST", "/payments/filter", paymentData)
      .then((res) => {
        if (res && res.data) {
          setDashboardLoader((prev) => ({ ...prev, [payments]: false }));
          setPaymentRows(res.data);
        }
      })
      .catch((err) => {
        setDashboardLoader((prev) => ({ ...prev, [payments]: false }));
      });
  };

  const recentWithdrawalsDetails = () => {
    const withdrawalsData = {
      status: ["paid"],
      limit: 10,
      ending_before: "",
    };
    setWithdrawRows([]);
    setDashboardLoader((prev) => ({ ...prev, [withdraws]: true }));
    callAPIInterface("POST", "/withdraws/filter", withdrawalsData)
      .then((res) => {
        if (res && res.data) {
          setDashboardLoader((prev) => ({ ...prev, [withdraws]: false }));
          setWithdrawRows(res.data);
        }
      })
      .catch((err) => {
        setDashboardLoader((prev) => ({ ...prev, [withdraws]: false }));
      });
  };

  const recentPayoutsDetails = () => {
    const payoutData = {
      status: "paid",
    };
    setPayoutRows([]);
    setDashboardLoader((prev) => ({ ...prev, [payouts]: true }));
    callAPIInterface("POST", "/payouts/filter?limit=10", payoutData)
      .then((res) => {
        if (res && res.data) {
          setDashboardLoader((prev) => ({ ...prev, [payouts]: false }));
          setPayoutRows(res.data);
        }
      })
      .catch((err) => {
        setDashboardLoader((prev) => ({ ...prev, [payouts]: false }));
      });
  };

  const recentTransactionsDetails = () => {
    setTransactionRows([]);
    setDashboardLoader((prev) => ({ ...prev, [transactions]: true }));
    callAPIInterface("GET", "/balance-transactions?limit=10")
      .then((res) => {
        if (res && res.data) {
          setDashboardLoader((prev) => ({
            ...prev,
            [transactions]: false,
          }));
          setTransactionRows(res.data);
        }
      })
      .catch((err) => {
        setDashboardLoader((prev) => ({ ...prev, [transactions]: false }));
      });
  };

  const loadRecentSummary = () => {
    // Transaction Summary
    transactionSummaryDetails();
    // Account Balance
    accountBalanceDetails();
    // Recent Payments
    recentPaymentsDetails();
    // Recent Withdrawals
    recentWithdrawalsDetails();
    // Recent Payouts
    recentPayoutsDetails();
    // Recent Transactions
    recentTransactionsDetails();
  };

  useEffect(() => {
    handleRedirection();
  }, []);

  useEffect(() => {
    // fetch recent summary
    loadRecentSummary();
  }, [liveMode]);

  useEffect(async () => {
    sessionService.loadSession().then(async (res) => {
      if (res.is_user_verified) {
        setOpenUserModal(true);
        delete res.is_user_verified;
        await sessionService.saveSession(res);
      }
    });
  });
  const handleNavigate = (module) => {
    switch (module) {
      case payments:
      case payouts:
      case withdraws:
        history.push(`/${module}?status=paid`);
        break;
      case transactions:
        history.push(`/${module}`);
        break;
      case pendingPayoutsBalance:
        history.push(`/payouts`);
        break;
      case balance:
        history.push("/balances");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <JoinInvite setLoading={setLoading} />
      <VerifiedUserModal open={openUserModal} handleClose={handleUserClose} />
      <Box className="section-wrapper">
        <CommonHeader />
        <CustomDivider />
        <Box className="dashboard-wrapper box-container">
          <BackdropLoader
            open={loading}
            alt="Loading..."
            text={loadingMsg}
            customClass="loading-in-auth"
          />

          <>
            <Box className="first-row-wrapper">
              <TransactionChart
                title={transactionChart}
                customStyle={{ borderRadius: "0px" }}
                height={356}
                width={967}
                data={txnChartData}
                loader={dashboardLoader.transactionSummary}
              />
              <Box>
                <DashboardCard
                  title={accountBalance}
                  height={356}
                  width={305}
                  customStyle={{ borderRadius: "0px" }}
                  buttonLabel={viewBalance}
                  handleButtonClick={() => handleNavigate(balance)}
                  showButton
                  data={balanceSummary}
                  loader={dashboardLoader.balance}
                />
              </Box>
            </Box>

            <Box className="first-row-wrapper" gap={2}>
              <RecentSummaryTable
                columns={recentPaymentTableHeader}
                rows={paymentRows}
                handleViewAll={() => handleNavigate(payments)}
                showViewAll
                chartTitle={recentPayments}
                module={payments}
                loader={dashboardLoader[payments]}
              />
              <RecentSummaryTable
                columns={recentWithdrawalTableHeader}
                rows={withdrawRows}
                handleViewAll={() => handleNavigate(withdraws)}
                showViewAll
                chartTitle={recentWithdrawals}
                module={withdraws}
                loader={dashboardLoader[withdraws]}
              />
              <RecentSummaryTable
                columns={recentPayoutTableHeader}
                rows={payoutRows}
                handleViewAll={() => handleNavigate(payouts)}
                showViewAll
                chartTitle={recentPayouts}
                module={payouts}
                loader={dashboardLoader[payouts]}
              />
            </Box>

            <Box className="second-row-wrapper">
              <RecentSummaryTable
                columns={recentTransactionTableHeader}
                rows={transactionRows}
                handleViewAll={() => handleNavigate(transactions)}
                showViewAll
                chartTitle={recentTransactions}
                module={transactions}
                width="1272px"
                loader={dashboardLoader[transactions]}
              />
            </Box>
          </>
        </Box>
      </Box>
    </>
  );
}

export default Dashboard;
