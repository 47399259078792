import React, { Fragment, useCallback, useEffect, useState } from "react";
import { Box, MenuItem, Skeleton, TextField } from "@mui/material";
import { Info } from "@mui/icons-material";
import {
  addNewLnAddress,
  addNewOnchainAddress,
  enterMinimumPayout,
  enterTheBalance,
  ln,
  maintainMinimumBalance,
  maintainMinumumPayout,
  minimumBalanceMsg,
  onchain,
  onchainWallet,
  payoutTypeErrorMsg,
  selectAddress,
  selectPayoutType,
  selectWalletAddress,
  minimumPayoutAmountMsg,
  walletAddressErrorMsg,
  walletAddressLNInfoMsg,
  walletAddressOnchainInfoMsg,
  cantLessThanMsg,
  lightningMinimumPayoutAmountMsg,
} from "../../messages";
import {
  addNewPayoutWallet,
  callAPIInterface,
  getListObj,
  noOfRecords,
  payoutSchedulingCurrency,
  payoutType,
  payoutVerticalLine,
} from "../../constants";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import Text from "@speed/common/src/components/Text/Text";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import { Input } from "@speed/common/src/components/Input/Input";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import CustomSelect from "@speed/common/src/components/Select/Select";
import {
  convertExponentialToDecimal,
  linkStatus,
} from "@speed/common/src/components/constants";
import { useSelector } from "react-redux";

const AutoPayoutSetupCommon = (props) => {
  const { setSelectedPayout, formik, setButtonDisabled } = props;

  const queryParam = "?limit=" + noOfRecords;
  const [searchQuery, setSearchQuery] = useState("");
  const initialQueryParamOfSearch = queryParam + "&name=" + searchQuery;

  const [walletSkeleton, setWalletSkeleton] = useState(false);
  const [hasMoreWallets, setHasMoreWallets] = useState(true);
  const [hasMoreWalletsFromResult, setHasMoreWalletsFromResult] =
    useState(false);
  const [wallets, setWallets] = useState([]);
  const [queryParamsOfWallet, setQueryParamsOfWallet] = useState(queryParam);
  const [walletsFromResult, setWalletsFromResult] = useState([]);
  const [queryParamsOfWalletForSearch, setQueryParamsOfWalletForSearch] =
    useState(initialQueryParamOfSearch);

  const [anchorEl, setAnchorEl] = useState(null);
  const [infoId, setInfoId] = useState(null);
  const [description, setDescription] = useState(null);
  const [walletAddresses, setWalletAddresses] = useState([]);
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false);
  const [openDropdownMenuBalance, setOpenDropdownMenuBalance] = useState(false);

  const liveMode = useSelector((state) => state.auth.liveMode);

  const { values, errors, setFieldValue, touched, setTouched, resetForm } =
    formik;

  const getPayoutWallets = (lines, params) => {
    setWalletSkeleton(true);
    callAPIInterface("POST", `/payout-wallets/filter${params}`, {
      status: linkStatus.verified.value,
    })
      .then((res) => {
        if (res) {
          setWalletSkeleton(false);
          if (!res.has_more) {
            setHasMoreWallets(false);
          } else {
            setQueryParamsOfWallet(
              queryParam + "&ending_before=" + res.data[res.data.length - 1].id
            );
          }
          setWallets(lines.concat(res?.data));
        }
      })
      .catch(() => {
        setWalletSkeleton(false);
      });
  };

  const fetchPayoutWallets = () => {
    setWallets([]);
    setHasMoreWallets(true);
    getPayoutWallets([], queryParamsOfWallet);
  };

  useEffect(() => {
    fetchPayoutWallets();
    return () => setSearchQuery("");
  }, [liveMode]);

  useEffect(() => {
    setButtonDisabled(walletSkeleton);
  }, [walletSkeleton]);

  const getPayoutWalletsFromResult = (lines, params) => {
    setWalletSkeleton(true);
    callAPIInterface("POST", `/payout-wallets/filter${params}`, {
      status: linkStatus.verified.value,
    })
      .then((walletRes) => {
        setWalletSkeleton(false);
        if (!walletRes.has_more) {
          setHasMoreWalletsFromResult(false);
        } else {
          setQueryParamsOfWalletForSearch(
            initialQueryParamOfSearch +
              "&ending_before=" +
              walletRes.data[walletRes.data.length - 1].id
          );
        }
        setWalletsFromResult(lines.concat(walletRes.data));
      })
      .catch(() => {
        setWalletSkeleton(false);
      });
  };

  const fetchPayoutWalletsResult = () => {
    setWalletsFromResult([]);
    setHasMoreWalletsFromResult(true);
    setQueryParamsOfWalletForSearch(initialQueryParamOfSearch);
    getPayoutWalletsFromResult([], initialQueryParamOfSearch);
  };

  useEffect(() => {
    if (searchQuery) {
      const timeout = setTimeout(() => {
        fetchPayoutWalletsResult();
      }, 500);

      return () => clearTimeout(timeout);
    }
  }, [searchQuery]);

  const walletsCopy = searchQuery ? walletsFromResult : wallets;

  const hasMoreWalletsCopy = searchQuery
    ? hasMoreWalletsFromResult
    : hasMoreWallets;

  const loadMoreWallets = useCallback(() => {
    getPayoutWallets(walletsCopy, queryParamsOfWallet);
  }, [walletsCopy]);

  const loadMoreWalletsFromResult = useCallback(() => {
    getPayoutWalletsFromResult(walletsFromResult, queryParamsOfWalletForSearch);
  }, [walletsFromResult]);

  const loadMoreWalletsCopy = searchQuery
    ? loadMoreWalletsFromResult
    : loadMoreWallets;

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    const filteredWallet = walletsCopy.filter(
      (wallet) => wallet.type === values.payout_type
    );
    setWalletAddresses(filteredWallet);
  }, [values.payout_type, walletsCopy]);

  const handleClick = (event, id, msg) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setInfoId(id);
    setDescription(msg);
  };

  const customPopperElement = (id) => {
    return (
      <CustomPopper
        disablePortal={true}
        open={open}
        anchorEl={anchorEl}
        position="top"
        id={infoId}
        handleClose={() => setAnchorEl(null)}
      >
        <Text
          style={{ whiteSpace: "wrap" }}
          variant="inherit"
          font="regular"
          size={12}
        >
          {description}
        </Text>
      </CustomPopper>
    );
  };

  const labelComponent = (label, msg, id) => {
    return (
      <Box display="flex" alignItems="center">
        <Text className="grey-text" size={14} font="regular" variant="body1">
          {label}
        </Text>
        <Info
          className="label-with-icon info-icon"
          onClick={(e) => handleClick(e, id, msg)}
          style={{
            height: 18,
            width: 18,
            color: "#848b9e",
          }}
        />
        {id === infoId && customPopperElement(id)}
      </Box>
    );
  };

  const currencyTypePayoutSats = values.currency_type_payout === "SATS";
  const currencyTypeBalanceSats = values.currency_type_balance === "SATS";

  const handleBalanceChange = (balanceType, value, isSats) => {
    setTouched({ ...touched, [balanceType]: false });
    if (isSats) {
      const cleanedValue = value.replace(/[^\d]/g, ""); // keep only numbers and remove fraction (e.g. 1.5050 -> 15050)
      const splicedValue = cleanedValue.slice(0, 9);
      setFieldValue(balanceType, splicedValue);
    } else {
      let splicedValue = value.slice(0, 10);
      if (splicedValue) {
        splicedValue = Math.floor(splicedValue * 100000000);
      }
      setFieldValue(balanceType, splicedValue);
    }
  };

  const isTypeOnchain = values.payout_type === onchain;

  return (
    <>
      <CustomAutoComplete
        className={`auto-complete-payout ${
          !(touched.payout_type && Boolean(!values.payout_type)) &&
          "margin-bottom30"
        }`}
        name="payout_type"
        options={payoutType}
        label={selectPayoutType}
        getOptionLabel={(option) => {
          if (typeof option === "object") {
            return option?.name || "";
          } else {
            const payout = getListObj("value", option, payoutType);
            return payout?.name || "";
          }
        }}
        placeholder={selectPayoutType}
        onBlur={() => setTouched({ ...touched, payout_type: true })}
        onChange={(e, option) => {
          if (option) {
            setTouched({ ...touched, payout_type: false });
            const payoutValue =
              typeof option === "object" ? option.value : option;
            resetForm({});
            setFieldValue("payout_type", payoutValue);
            setFieldValue(
              "min_payout_amount",
              payoutValue === onchain ? "50000" : "1"
            );
            setSelectedPayout(payoutValue);
          }
        }}
        value={values.payout_type}
        showLabel={true}
        disableClearable={true}
      />
      {touched.payout_type && Boolean(!values.payout_type) && (
        <AlertMessage
          className="margin-top14 margin-bottom30"
          severity="error"
          message={payoutTypeErrorMsg}
        />
      )}
      {/* {isTypeOnchain && (
            <>
              <CustomAutoComplete
                className={`auto-complete-payout ${
                  !(touched.fees_type && Boolean(!values.fees_type)) &&
                  "margin-bottom30"
                }`}
                label={labelComponent(fees, thresholdAmountMsg, "0")}
                onBlur={() => setTouched({ ...touched, fees_type: true })}
                onChange={(e, option) => {
                  if (option) {
                    setTouched({ ...touched, fees_type: false });
                    setFieldValue(
                      "fees_type",
                      option && typeof option === "object"
                        ? option.value
                        : option
                    );
                  }
                }}
                options={feesType}
                disableClearable={true}
                value={values.fees_type}
                getOptionLabel={(option) => {
                  let fees = getListObj(
                    "value",
                    typeof option === "object" ? option.value : option,
                    feesType
                  );
                  return fees ? fees.name : "";
                }}
                placeholder={selectFeesType}
              />
              {touched.fees_type && Boolean(!values.fees_type) && (
                <AlertMessage
                  className="margin-top14 margin-bottom30"
                  severity="error"
                  message={feesTypeErrorMsg}
                />
              )}
            </>
          )} */}
      {values.payout_type && (
        <>
          <CustomAutoComplete
            label={labelComponent(
              selectAddress(isTypeOnchain ? onchainWallet : ln),
              isTypeOnchain
                ? walletAddressOnchainInfoMsg
                : walletAddressLNInfoMsg,
              "1"
            )}
            className={`auto-complete-payout ${
              !(touched.wallet_address && Boolean(!values.wallet_address)) &&
              "margin-bottom30"
            }`}
            options={walletAddresses}
            disableClearable={true}
            onBlur={() => setTouched({ ...touched, wallet_address: true })}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) =>
              walletSkeleton ? (
                <Box height="45px" display="flex" alignItems="center">
                  <Skeleton
                    animation="wave"
                    width="100%"
                    sx={{ bgcolor: "#eaeef1 !important" }}
                  />
                </Box>
              ) : (
                <TextField
                  {...params}
                  placeholder={selectWalletAddress(
                    isTypeOnchain ? onchain : ln
                  )}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              )
            }
            noOptionsText={
              <Box style={{ padding: 0 }}>
                {addNewPayoutWallet(
                  isTypeOnchain ? addNewOnchainAddress : addNewLnAddress
                )}
                {walletSkeleton && (
                  <Box marginY={1} padding={1} borderRadius={1}>
                    <Skeleton
                      animation="wave"
                      width="50%"
                      sx={{ bgcolor: "#eaeef1 !important" }}
                    />
                    <Skeleton
                      animation="wave"
                      sx={{ bgcolor: "#eaeef166 !important" }}
                    />
                  </Box>
                )}
              </Box>
            }
            value={values.wallet_address}
            getOptionLabel={(option) => {
              if (typeof option === "object") {
                return option?.name || "";
              } else {
                const wallet = getListObj("id", option, walletAddresses);
                return wallet?.name || "";
              }
            }}
            renderOption={(optionProps, option, state) => {
              const index = state.index;
              const lastIndex = walletAddresses.length - 1;
              return (
                <Fragment key={option.name}>
                  {index === 0 &&
                    addNewPayoutWallet(
                      isTypeOnchain ? addNewOnchainAddress : addNewLnAddress
                    )}
                  <Box
                    marginY={1}
                    padding={1}
                    className="pointer-cursor"
                    onClick={(e) => {
                      setTouched({ ...touched, wallet_address: false });
                      setFieldValue("wallet_address", option.id);
                      optionProps.onClick(e);
                    }}
                    borderRadius={1}
                    sx={{
                      ":hover": {
                        bgcolor: "#f1f4f6",
                      },
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Text
                      style={{ paddingRight: "10px", wordBreak: "break-word" }}
                      className="default-text"
                      size={14}
                      variant="body1"
                    >
                      {option.name}
                    </Text>
                    <Text
                      className="grey-text"
                      size={14}
                      variant="body1"
                      sx={{ wordBreak: "break-word" }}
                    >
                      {option.address}
                    </Text>
                  </Box>
                  {walletSkeleton && index === lastIndex && (
                    <Box
                      marginY={1}
                      padding={1}
                      borderRadius={1}
                      sx={{ display: "flex", flexDirection: "row" }}
                    >
                      <Skeleton
                        animation="wave"
                        width="150px"
                        height="14px"
                        sx={{
                          bgcolor: "#eaeef1 !important",
                          marginRight: "10px",
                        }}
                      />
                      <Skeleton
                        width="200px"
                        height="14px"
                        animation="wave"
                        sx={{ bgcolor: "#eaeef166 !important" }}
                      />
                    </Box>
                  )}
                </Fragment>
              );
            }}
            ListboxProps={{
              role: "list-box",
              onScroll: (event) => {
                const listboxNode = event.currentTarget;
                if (
                  listboxNode.scrollTop + listboxNode.clientHeight ===
                  listboxNode.scrollHeight
                ) {
                  if (hasMoreWalletsCopy) {
                    loadMoreWalletsCopy ?? loadMoreWalletsCopy();
                  }
                }
              },
            }}
          />
          {touched.wallet_address && Boolean(!values.wallet_address) && (
            <AlertMessage
              className="margin-top14 margin-bottom30"
              severity="error"
              message={walletAddressErrorMsg}
            />
          )}
        </>
      )}

      {values.payout_type && (
        <Box className="margin-bottom30">
          <label className="input-with-dropdown-label">
            {labelComponent(
              maintainMinumumPayout,
              values.payout_type === onchain
                ? minimumPayoutAmountMsg
                : lightningMinimumPayoutAmountMsg,
              "2"
            )}
          </label>
          <Box className="dropdown-input-wrapper">
            <CustomSelect
              onKeyDownCapture={(event) => {
                if (event.key === "Tab") {
                  event.stopPropagation();
                }
                if (event.key === "Escape") {
                  setOpenDropdownMenu(false);
                }
              }}
              open={openDropdownMenu}
              onClose={() => {
                setOpenDropdownMenu(false);
              }}
              onOpen={() => {
                setOpenDropdownMenu(true);
              }}
              value={values.currency_type_payout}
              showLabel={false}
              MenuProps={{
                id: "custom-filter-select",
              }}
              renderValue={(value) => value || ""}
              onChange={(e) => {
                setTouched({ ...touched, currency_type_payout: false });
                setFieldValue("currency_type_payout", e.target.value);
                setOpenDropdownMenu(false);
              }}
            >
              {payoutSchedulingCurrency.map((option) => (
                <MenuItem
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  key={option.id}
                  value={option.value}
                >
                  <Box> {option.name} </Box>
                </MenuItem>
              ))}
            </CustomSelect>
            {payoutVerticalLine({
              m: "auto",
            })}
            <Input
              tabIndex={0}
              type="number"
              showLabel={false}
              className="auto-payout-amount-input"
              placeholder={enterMinimumPayout}
              value={
                !currencyTypePayoutSats && values.min_payout_amount
                  ? convertExponentialToDecimal(
                      +(values.min_payout_amount / 100000000)
                    )
                  : values.min_payout_amount
              }
              onWheel={(e) => e.target.blur()}
              onBlur={() => setTouched({ ...touched, min_payout_amount: true })}
              error={
                touched.min_payout_amount && Boolean(errors.min_payout_amount)
              }
              onChange={(e) => {
                handleBalanceChange(
                  "min_payout_amount",
                  e.target.value,
                  currencyTypePayoutSats
                );
              }}
            />
          </Box>
          <Text
            size={14}
            font="regular"
            variant="body1"
            className="grey-text"
            style={{ float: "right", marginTop: "6px" }}
          >
            {cantLessThanMsg(currencyTypePayoutSats, values.payout_type)}
          </Text>
          <InputErrorMessage
            {...defaultErrorMessageProps}
            inputName="min_payout_amount"
            style={{ marginTop: "30px" }}
          />
        </Box>
      )}

      {values.payout_type && (
        <Box className="margin-bottom30">
          <label className="input-with-dropdown-label">
            {labelComponent(maintainMinimumBalance, minimumBalanceMsg, "3")}
          </label>
          <Box className="dropdown-input-wrapper">
            <CustomSelect
              onKeyDownCapture={(event) => {
                if (event.key === "Tab") {
                  event.stopPropagation();
                }
                if (event.key === "Escape") {
                  setOpenDropdownMenuBalance(false);
                }
              }}
              open={openDropdownMenuBalance}
              onClose={() => {
                setOpenDropdownMenuBalance(false);
              }}
              onOpen={() => {
                setOpenDropdownMenuBalance(true);
              }}
              value={values.currency_type_balance}
              showLabel={false}
              MenuProps={{
                id: "custom-filter-select",
              }}
              renderValue={(value) => value || ""}
              onChange={(e) => {
                setTouched({ ...touched, currency_type_balance: false });
                setFieldValue("currency_type_balance", e.target.value);
                setOpenDropdownMenuBalance(false);
              }}
            >
              {payoutSchedulingCurrency.map((option) => (
                <MenuItem
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  key={option.id}
                  value={option.value}
                >
                  <Box> {option.name} </Box>
                </MenuItem>
              ))}
            </CustomSelect>
            {payoutVerticalLine({
              m: "auto",
            })}
            <Input
              tabIndex={0}
              type="number"
              showLabel={false}
              className="auto-payout-amount-input"
              placeholder={enterTheBalance}
              value={
                !currencyTypeBalanceSats && values.minimum_balance
                  ? convertExponentialToDecimal(
                      +(values.minimum_balance / 100000000)
                    )
                  : values.minimum_balance
              }
              onWheel={(e) => e.target.blur()}
              onBlur={() => setTouched({ ...touched, minimum_balance: true })}
              error={touched.minimum_balance && Boolean(errors.minimum_balance)}
              onChange={(e) => {
                handleBalanceChange(
                  "minimum_balance",
                  e.target.value,
                  currencyTypeBalanceSats
                );
              }}
            />
          </Box>
          <InputErrorMessage
            {...defaultErrorMessageProps}
            inputName="minimum_balance"
          />
        </Box>
      )}
    </>
  );
};

export default AutoPayoutSetupCommon;
