import React from "react";
import { Box, Grid } from "@mui/material";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import CustomDivider from "@speed/common/src/components/Divider/Divider";

const AttemptTableSkeleton = ({ sx = {} }) => {
  const tableRowsSkeleton = (
    <Grid container display="flex" mt="14px">
      <Grid item lg={1.5}>
        <CommonSkeleton width={73} height={25} color="#F4F4F4" />
      </Grid>
      <Grid item lg={5.5}>
        <CommonSkeleton width={327} height={25} color="#F4F4F4" />
      </Grid>
      <Grid item lg={2.4}>
        <CommonSkeleton width={135} height={25} color="#F4F4F4" />
      </Grid>
      <Grid item lg={2.2} display="flex" justifyContent="center">
        <CommonSkeleton width={35} height={25} color="#F4F4F4" />
      </Grid>
      <Grid item lg={0.2}>
        <CommonSkeleton width={15} height={25} color="#F4F4F4" />
      </Grid>
    </Grid>
  );

  return (
    <Box sx={sx}>
      <Grid container display="flex" padding="8px 0">
        <Grid item lg={7} pl="89px">
          <CommonSkeleton width={161} height={40} color="#F4F4F4" />
        </Grid>
        <Grid item lg={3}>
          <CommonSkeleton width={99} height={40} color="#F4F4F4" />
        </Grid>
        <Grid item lg={2}>
          <CommonSkeleton width={99} height={40} color="#F4F4F4" />
        </Grid>
      </Grid>
      <CustomDivider />
      {tableRowsSkeleton}
      {tableRowsSkeleton}
    </Box>
  );
};

export default AttemptTableSkeleton;
