import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import history from "@speed/common/src/components/history";
import { redirectWhenEntityNotFound } from "@speed/common/src/components/constants";
import { redirectingToURL } from "../../messages";
import {
  callAPIInterface,
  handleRemoveVerifyPayoutWallet,
} from "../../constants";
import {
  setPayoutModalData,
  setPayoutModalOpen,
} from "../../../redux/common/actions";
import { authenticatingURL } from "@speed/common/src/messages";

const VerifyPayoutWallet = () => {
  const dispatch = useDispatch();
  const searchParams = {};
  const params = new URLSearchParams(
    history?.location?.search?.replace(/\+/g, "%2B")
  );
  for (const [key, value] of params.entries()) {
    searchParams[key] = value;
  }

  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [loadingText, setLoadingText] = useState(authenticatingURL);

  const getVerifyLink = () => {
    return new Promise((resolve, reject) => {
      callAPIInterface(
        "POST",
        `/payout-wallets/${searchParams?.wallet_id}/verify`,
        { speedUser: true }
      )
        .then((response) => {
          resolve(response);
        })
        .catch((_error) => {
          reject(_error);
        });
    });
  };

  const isFromOnboarding = searchParams.from === "onboarding";
  const onboardingRedirection = (params) => {
    history.push({
      pathname: "/settings/payout-wallets",
    });
    dispatch(setPayoutModalData(params));
    dispatch(setPayoutModalOpen(true));
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (user?.id === searchParams?.owner_id) {
        getVerifyLink()
          .then((res) => {
            if (res) {
              setLoadingText(redirectingToURL);
              if (isFromOnboarding) {
                onboardingRedirection({ isVerified: true });
              } else {
                history.push({
                  pathname: "/settings/payout-wallets",
                  state: { isVerified: true },
                });
              }
            }
          })
          .catch((error) => {
            const errors = error?.response?.data?.errors;
            const errorMessage = errors?.[0]?.message;
            const unverifiedErr = errorMessage.includes("unverified");
            if (redirectWhenEntityNotFound(error)) {
              const params = {
                showLinkExpiredModal: !unverifiedErr,
                alreadyVerified: unverifiedErr,
              };
              if (isFromOnboarding) {
                onboardingRedirection(params);
              } else {
                history.push({
                  pathname: "/settings/payout-wallets",
                  state: params,
                });
              }
            }
          })
          .finally(() => handleRemoveVerifyPayoutWallet());
      } else {
        if (isFromOnboarding) {
          onboardingRedirection({ showLinkExpiredModal: true });
        } else {
          history.push({
            pathname: "/settings/payout-wallets",
            state: {
              showLinkExpiredModal: true,
            },
          });
        }
        handleRemoveVerifyPayoutWallet();
      }
    } else {
      sessionStorage.setItem(
        "verify-payout-wallet",
        `${history.location.pathname}${history.location.search}`
      );
      history.push({
        pathname: "/login",
      });
    }
  }, []);

  return (
    <BackdropLoader
      open={true}
      alt="Loading..."
      customClass="loading-in-auth"
      text={loadingText}
    />
  );
};

export default VerifyPayoutWallet;
