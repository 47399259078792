import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import "./vertical-stepper.scss";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Text from "../Text/Text";
import { getStepsBeforeIndex } from "../constants";

const defaultSteps = [
  {
    label: "Step 1",
    completeStepIcon: "",
    activateStepIcon: "",
    remainingStepIcon: "",
    parentOnClick: () => {},
    children: [
      { label: "Child 1 of Step 1", component: <>Child 1 of Step 1</> },
      { label: "Child 2 of Step 1", component: <>Child 2 of Step 2</> },
      { label: "Child 3 of Step 1", component: <>Child 3 of Step 3</> },
    ],
  },
  {
    label: "Step 2",
    completeStepIcon: "",
    activateStepIcon: "",
    remainingStepIcon: "",
    parentOnClick: () => {},
    children: [
      {
        label: "Child 1 of Step 2",
        component: <>Child 1 of Step 2</>,
        childOnClick: () => {},
      },
      {
        label: "Child 2 of Step 2",
        component: <>Child 2 of Step 2</>,
        childOnClick: () => {},
      },
    ],
  },
  {
    label: "Step 3",
    completeStepIcon: "",
    activateStepIcon: "",
    remainingStepIcon: "",
    parentOnClick: () => {},
    children: [
      {
        label: "Child 1 of Step 1",
        component: <>Child 1 of Step 3</>,
        childOnClick: () => {},
      },
    ],
  },
  {
    label: "Step 4",
    completeStepIcon: "",
    activateStepIcon: "",
    remainingStepIcon: "",
    parentOnClick: () => {},
    parentComponent: <>This is parent component</>,
    children: [],
  },
];

export const VerticalStepperComponent = forwardRef((props, ref) => {
  const {
    steps,
    activeStep,
    setActiveStep,
    childActiveStep,
    setChildActiveStep,
    childCompletedStep,
    setChildCompletedStep,
    parentCompletedStep,
    disableNextStep,
  } = props;

  function ColorlibStepIcon(props) {
    const { active, completed, activeIcon, completedIcon, remainingIcon } =
      props;
    let icon;
    if (completed) icon = completedIcon;
    else if (active) icon = activeIcon;
    else icon = remainingIcon;
    return icon;
  }

  const roundBox = <Box className="dot-circle"></Box>;
  const completedIcon = <CheckCircleIcon className="tick-icon" />;
  const activeIcon = <CheckCircleIcon className="tick-icon" />;
  const remainingIcon = roundBox;

  const subLabelText = ({ label, active, childIndex, mainIndex }) => {
    return (
      <Box className="sub-label-box">
        <Box
          className="sub-header-label-bullet"
          sx={{ background: active ? "#2A67FF" : "#848B9E" }}
        ></Box>
        <Box
          className="sub-header-label pointer-cursor"
          component="div"
          onClick={() => {
            setChildActiveStep({ [mainIndex]: childIndex });
            setChildCompletedStep(
              getStepsBeforeIndex(steps, mainIndex, childIndex)
            );
          }}
        >
          <Text
            size={16}
            variant="subtitle1"
            font="medium"
            sx={{
              color: active ? "#2A67FF !important " : "#848B9E !important",
            }}
          >
            {label}
          </Text>
        </Box>
      </Box>
    );
  };

  const handleNext = () => {
    if (
      steps[activeStep]?.children?.length - 1 ===
      childActiveStep[activeStep]
    ) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setChildActiveStep({ [activeStep + 1]: 0 });
      setChildCompletedStep({ ...childCompletedStep, [activeStep + 1]: [0] });
    } else if (
      steps[activeStep]?.children?.length > childActiveStep[activeStep]
    ) {
      setChildActiveStep({
        [activeStep]: childActiveStep[activeStep] + 1,
      });
      const completedObj = {
        ...childCompletedStep,
        [activeStep]: [
          ...childCompletedStep[activeStep],
          childActiveStep[activeStep] + 1,
        ],
      };
      setChildCompletedStep(completedObj);
    }
  };

  const handleBack = () => {
    let tempObj = childCompletedStep;
    const lastEntry = Object.entries(tempObj).pop();
    if (lastEntry) {
      const [lastKey, lastValue] = lastEntry;
      if (Array.isArray(lastValue) && lastValue.length > 0) {
        const poppedValue = lastValue.pop();
        if (poppedValue > 0 && childActiveStep[lastKey] === poppedValue) {
          setChildActiveStep({ [lastKey]: poppedValue - 1 });
        }
        if (lastValue.length === 0) {
          const prevValOfCompeteStep = tempObj[lastKey - 1];
          prevValOfCompeteStep?.length > 0 &&
            setChildActiveStep({
              [lastKey - 1]:
                prevValOfCompeteStep?.[prevValOfCompeteStep?.length - 1],
            });
          if (Number(lastKey) === 0 && tempObj[lastKey]?.length === 0)
            tempObj = { 0: [0] };
          else delete tempObj[lastKey];
        }
      }
    }
    setChildCompletedStep(tempObj);
  };

  useEffect(() => {
    childActiveStep && setActiveStep(Number(Object.keys(childActiveStep)?.[0]));
  }, [childActiveStep]);

  useImperativeHandle(ref, () => ({
    handleNext,
    handleBack,
  }));

  return (
    <Box sx={{ maxWidth: 400 }} className="vertical-stepper-box">
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((step, index) => {
          // Add +1 to parentCompletedStep to enable the next step on completion of previous step
          const shouldClickable =
            !disableNextStep ||
            (disableNextStep && parentCompletedStep + 1 >= index);
          return (
            <Step key={step.label}>
              <StepLabel
                onClick={() => {
                  if (shouldClickable) {
                    setActiveStep(index);
                    setChildActiveStep({ [index]: 0 });
                    setChildCompletedStep(getStepsBeforeIndex(steps, index));
                  }
                }}
                className={`${shouldClickable && "pointer-cursor"} ${
                  index === 0
                    ? "first-element"
                    : index === steps.length - 1 &&
                      activeStep !== index &&
                      "last-element"
                }`}
                StepIconComponent={ColorlibStepIcon}
                StepIconProps={{
                  completedIcon: step.completeStepIcon || completedIcon,
                  activeIcon: step.activateStepIcon || activeIcon,
                  remainingIcon: step.remainingStepIcon || remainingIcon,
                }}
              >
                {step.label}
              </StepLabel>

              <StepContent
                className={
                  index === steps.length - 1 &&
                  activeStep === index &&
                  "last-element"
                }
              >
                {step.children?.length > 0 &&
                  step.children.map((child, childIndex) => {
                    return subLabelText({
                      childIndex,
                      mainIndex: index,
                      label: child.label,
                      active: childActiveStep?.[index] === childIndex,
                    });
                  })}
              </StepContent>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
});

export const propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.object.isRequired,
  setActiveStep: PropTypes.object.isRequired,
  childActiveStep: PropTypes.object.isRequired,
  setChildActiveStep: PropTypes.object.isRequired,
  childCompletedStep: PropTypes.object.isRequired,
  setChildCompletedStep: PropTypes.object.isRequired,
  disableNextStep: PropTypes.bool,
};

const defaultProps = {
  steps: defaultSteps,
  disableNextStep: false,
};

VerticalStepperComponent.propTypes = propTypes;
VerticalStepperComponent.defaultProps = defaultProps;
