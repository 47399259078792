import React, { forwardRef, useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import CloseIcon from "@mui/icons-material/Close";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ArrowDropDownCircleIcon from "@mui/icons-material/ArrowDropDownCircle";
import WarningIcon from "@mui/icons-material/Warning";
import { sessionService } from "redux-react-session";
import { useFlags } from "launchdarkly-react-client-sdk";
import Text from "@speed/common/src/components/Text/Text";
import Button from "@speed/common/src/components/Button/Button";
import {
  accessDeniedText,
  back,
  next,
} from "@speed/common/src/components/messages";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import {
  affiliatePartnerData,
  callAPIInterface,
  getTimestamp,
  isAffiliatePartnerDataAvailable,
  memberBenefits,
} from "../constants";
import {
  accountAlreadyAffiliated,
  affiliatePartnerBenefits,
  affiliatePartnerBenefitsHeader,
  connect,
  connectWithAffiliatePartner,
  onlyWalletAccountAffiliateError,
  partnerConnectForbiddenMessage,
} from "../messages";
import { generateToken, getUser } from "../../redux/auth/actions";
import { showToast } from "../../redux/common/actions";
import { accountTitle, selectAnAccount } from "@speed/common/src/messages";
import AddEditAccount from "@speed/common/src/components/AddEditAccount";
import SelectAccount from "@speed/common/src/components/SelectAccount";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const PartnerConnect = () => {
  const { aflConnectmemberFe } = useFlags();
  const dispatch = useDispatch();
  const history = useSelector((state) => state.common.history);
  const user = useSelector((state) => state.auth.user);
  const [goToNextPage, setGoToNextPage] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState();
  const [accountSelected, setAccountSelected] = useState();
  const [expanded, setExpanded] = useState(1);
  const [open, setOpen] = useState(false);
  const [openForbiddenModal, setOpenForbiddenModal] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [accountList, setAccountList] = useState([]);

  const fromPartnerDataAvailable = isAffiliatePartnerDataAvailable();

  useEffect(() => !fromPartnerDataAvailable && history.push("/dashboard"), []);

  useEffect(() => {
    if (fromPartnerDataAvailable) {
      const result =
        user?.accounts_details?.length > 0 &&
        user?.accounts_details.map((item) => item.account);
      setAccountList(result);
    }
  }, [fromPartnerDataAvailable, user?.accounts_details]);

  const handleModalClose = () => {
    sessionStorage.removeItem("affiliate-partner-invitee");
    history.push("/dashboard");
  };

  const setAccountDetails = async (id) => {
    let session = await sessionService.loadSession();
    session &&
      sessionService.saveSession({ ...session, speed_acc_id: id }).then(() => {
        callAPIInterface("GET", "/select-account").catch(() => {});
      });
  };

  const handleConnect = async () => {
    setDisableSubmit(true);
    await setAccountDetails(accountSelected?.id);
    const partnerConnectData = {
      affiliate_invitation_id: affiliatePartnerData()?.id,
    };
    const partnerConnectStaticLinkData = {
      account: affiliatePartnerData()?.account_id,
    };
    callAPIInterface(
      "POST",
      "/affiliate-partner/connect",
      JSON.stringify(
        affiliatePartnerData()?.is_default
          ? partnerConnectStaticLinkData
          : partnerConnectData
      )
    )
      .then(() => {
        dispatch(getUser());
        setDisableSubmit(false);
        handleModalClose();
      })
      .catch((err) => {
        setDisableSubmit(false);
        const errorType = err?.response?.data?.errors?.[0]?.type?.toLowerCase();
        if (errorType === "invalid_permission") {
          dispatch(
            showToast({
              isToastOpen: false,
            })
          );
          setOpenForbiddenModal(true);
        } else {
          handleModalClose();
        }
      });
  };

  const handleBackButton = () => setGoToNextPage(false);
  const handleNextButton = () => setGoToNextPage(true);

  const handleChangeAccordion = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAddAccount = async (accountData) => {
    if (accountData) {
      const { token_response: updatedTokens } = accountData;
      if (updatedTokens && Object.keys(updatedTokens).length !== 0) {
        let session = await sessionService.loadSession();
        await sessionService.saveSession({
          ...session,
          access_token: updatedTokens.access_token,
          id_token: updatedTokens.id_token,
          timestamp: getTimestamp(),
        });
      }
    }
  };

  const requestBody = () => {
    if (!goToNextPage) {
      return (
        <Box className="margin-top30 content-center" flexDirection="column">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginBottom="30px"
            width={600}
          >
            <Text className="default-text" size={20} variant="h2">
              {selectAnAccount}
            </Text>
            <Button
              icon="addIcon"
              className="add-icon"
              label={accountTitle(false)}
              variant="outlined"
              color="primary"
              onClick={() => setOpen(true)}
            />
          </Box>
          {user && accountList && (
            <SelectAccount
              selectedRadio={selectedRadio}
              setSelectedRadio={setSelectedRadio}
              setAccountSelected={setAccountSelected}
              walletAcctMsg={onlyWalletAccountAffiliateError}
              alreadyConnectedMsg={accountAlreadyAffiliated}
              accountsList={accountList}
              from="affiliate-partner-connect-account"
            />
          )}
          <AddEditAccount
            open={open}
            isEdit={false}
            handleClose={() => {
              setOpen(false);
            }}
            handleAddAccount={handleAddAccount}
            callAPIInterface={callAPIInterface}
            generateToken={generateToken}
            dispatch={dispatch}
          />
        </Box>
      );
    } else {
      return (
        <Box
          display="flex"
          justifyContent="center"
          maxHeight="calc(100% - 70px)"
          overflow="hidden auto"
        >
          <Box width={804} className="margin-top30">
            <Box className="content-center" gap={3}>
              <TruncatedTextTooltip
                textValue={affiliatePartnerData()?.account_name}
                cellWidth="250px"
                className="custom-tooltip"
                textProps={{
                  className: "default-text",
                  font: "semibold",
                  variant: "h5",
                  size: 24,
                }}
              />
              <SyncAltIcon />
              <TruncatedTextTooltip
                textValue={accountSelected?.name}
                cellWidth="250px"
                className="custom-tooltip"
                textProps={{
                  className: "default-text",
                  font: "semibold",
                  variant: "h5",
                  size: 24,
                }}
              />
            </Box>
            <Text
              className="grey-text"
              sx={{ mt: 2, maxWidth: "60%", mx: "auto" }}
              size={14}
              align="center"
              variant="subtitle1"
              font="regular"
            >
              {affiliatePartnerBenefitsHeader}
            </Text>
            <Text className="default-text margin-top30">
              {affiliatePartnerBenefits}
            </Text>
            {memberBenefits?.map((listItem) => {
              return (
                <Accordion
                  key={listItem?.id}
                  expanded={expanded === listItem?.id}
                  onChange={handleChangeAccordion(listItem?.id)}
                  className="accordion-wrapper"
                  sx={{
                    border: "0 !important",
                    borderBottom:
                      expanded !== listItem?.id &&
                      "solid 1px #e4e9ee !important",
                    borderRadius: "0 !important",
                  }}
                >
                  <AccordionSummary
                    sx={{
                      py: "16px !important",
                    }}
                    expandIcon={
                      <ArrowDropDownCircleIcon
                        sx={{
                          transform:
                            expanded === listItem?.id
                              ? "rotate(180deg)"
                              : "rotate(-90deg)",
                          color: "#848B9E !important",
                        }}
                      />
                    }
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Box display="flex" alignItems="center" gap={1.5}>
                      <TaskAltIcon sx={{ color: "#848B9E !important" }} />
                      <Text className="default-text" size={18}>
                        {listItem?.title}
                      </Text>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Text
                      className="grey-text"
                      variant="subtitle1"
                      font="regular"
                      size={16}
                      sx={{ ml: "36.5px" }}
                    >
                      {listItem?.description}
                    </Text>
                  </AccordionDetails>
                </Accordion>
              );
            })}
          </Box>
        </Box>
      );
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={true}
      TransitionComponent={Transition}
      className="fullscreen-modal"
      onClose={handleModalClose}
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1 }}
            className="default-text divider"
            variant="h6"
          >
            {connectWithAffiliatePartner}
          </Text>
          {goToNextPage ? (
            <>
              <Button
                variant="outlined"
                color="primary"
                label={back}
                onClick={handleBackButton}
                sx={{ mr: "18px" }}
                disabled={!aflConnectmemberFe || disableSubmit}
              />
              <Button
                label={connect}
                variant="contained"
                color="primary"
                className="payment-link-btn"
                onClick={handleConnect}
                disabled={!aflConnectmemberFe || disableSubmit}
              />
            </>
          ) : (
            <Button
              label={next}
              variant="contained"
              color="primary"
              className="payment-link-btn"
              onClick={handleNextButton}
              disabled={!(selectedRadio && aflConnectmemberFe)}
            />
          )}
        </Toolbar>
      </AppBar>
      {aflConnectmemberFe ? requestBody() : <AccessDenied />}
      <Modal
        maxWidth="xs"
        body={
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            alignItems="center"
          >
            <WarningIcon sx={{ color: "#E74C3C", height: 60, width: 60 }} />
            <Text
              align="center"
              className="default-text"
              font="regular"
              size={18}
              variant="body1"
            >
              {partnerConnectForbiddenMessage("connect")}
            </Text>
          </Box>
        }
        open={openForbiddenModal}
        title={accessDeniedText}
        handleClose={() => setOpenForbiddenModal(false)}
      />
    </Dialog>
  );
};

export default PartnerConnect;
