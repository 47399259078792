import { Box, Drawer } from "@mui/material";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Text from "@speed/common/src/components/Text/Text";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@speed/common/src/components/Button/Button";
import { applyFilter } from "@speed/common/src/messages";
import { filter } from "../messages";

const CommonAdvanceWalletFilter = ({
  anchor,
  handleClose,
  handleApplyFiltersButton,
  disableApplyButton,
  advanceFilterBody,
}) => {
  const advanceFilterHeader = () => {
    return (
      <Box className="filter-header">
        <Text className="default-text" size={18} variant="h3">
          {filter}
        </Text>
        <Box
          height={20}
          width={20}
          onClick={handleClose}
          component="span"
          className="pointer-cursor"
        >
          <CloseIcon sx={{ height: 20, width: 20 }} htmlColor="#848b9e" />
        </Box>
      </Box>
    );
  };

  const advanceFilterFooter = () => (
    <>
      <CustomDivider />
      <Box className="filter-footer">
        <Button
          label={applyFilter}
          onClick={handleApplyFiltersButton}
          type="submit"
          disabled={disableApplyButton}
        />
      </Box>
    </>
  );
  return (
    <Drawer className="accounts-filter-wrapper" anchor="right" open={anchor}>
      <Box className="filter-box-wrapper" role="presentation">
        {advanceFilterHeader()}
        <CustomDivider />
        {advanceFilterBody()}
        {advanceFilterFooter()}
      </Box>
    </Drawer>
  );
};

export default CommonAdvanceWalletFilter;
