import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { find } from "lodash";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import { checkout, paymentAddressText } from "../../messages";
import CustomCheckoutDomain from "../CustomCheckoutDomain";
import CustomPaymentDomain from "../CustomPaymentDomain";

const CustomDomain = () => {
  const history = useSelector((state) => state.common.history);
  const [selectedTab, setSelectedTab] = useState(false);

  const tabData = [
    {
      label: checkout,
      value: "1",
      tabContent: <CustomCheckoutDomain />,
      route: "/settings/custom-domain",
    },
    {
      label: paymentAddressText[1],
      value: "2",
      tabContent: <CustomPaymentDomain />,
      route: "/settings/custom-payment-domain",
    },
  ];

  const handleChange = (_event, newValue) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (history?.location?.pathname) {
      let currentRoute = find(tabData, { route: history.location.pathname });
      if (currentRoute) {
        setSelectedTab(currentRoute.value);
      }
    }
  }, [history?.location?.pathname]);

  return (
    <Box className="section-wrapper payment-links-wrapper">
      <CustomTab
        onChange={handleChange}
        tabData={tabData}
        selectedTab={selectedTab}
        setTabRoute={(routeUrl) => history.push(routeUrl)}
        sx={{
          minHeight: "unset",
          "& .MuiTabPanel-root": {
            minHeight: "unset",
            padding: 0,
          },
        }}
      />
    </Box>
  );
};

export default CustomDomain;
