import React from "react";
import { Box } from "@mui/material";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import VerticalTable from "@speed/common/src/components/VerticalTable";

const headerContent = () => {
  return (
    <Box className="header-content">
      <CommonSkeleton
        color="#c4ccd2"
        width={175}
        height={35}
        sx={{ mt: "17px", borderRadius: "17px" }}
      />
      <CommonSkeleton
        color="#8ba7ec"
        height={23}
        width={129}
        sx={{ mt: "12px", mb: "11px", borderRadius: "8px" }}
      />
      <CommonSkeleton width={60} sx={{ mt: "12px", borderRadius: "8px" }} />
      <CommonSkeleton
        width={212}
        height={31}
        sx={{ mt: "18px", borderRadius: "15px" }}
      />
    </Box>
  );
};

const detailsTableData = () => {
  return [
    {
      header: (
        <CommonSkeleton
          width={52}
          sx={{ marginTop: "9px", borderRadius: "8px" }}
        />
      ),
      cell: (
        <CommonSkeleton
          width={75}
          sx={{ marginTop: "9px", borderRadius: "8px" }}
          color="#c4ccd2"
        />
      ),
    },
    {
      header: <CommonSkeleton width={102} sx={{ borderRadius: "8px" }} />,
      cell: (
        <CommonSkeleton
          width={176}
          color="#c4ccd2"
          sx={{ borderRadius: "8px" }}
        />
      ),
    },
    {
      header: <CommonSkeleton width={102} sx={{ borderRadius: "8px" }} />,
      cell: (
        <CommonSkeleton
          width={176}
          color="#c4ccd2"
          sx={{ borderRadius: "8px" }}
        />
      ),
    },
  ];
};

const InstantSendSkeleton = () => {
  return (
    <>
      {headerContent()}
      {
        <Box className="details-content" marginTop="38px !important">
          <VerticalTable
            label={
              <CommonSkeleton
                width={160}
                height={18}
                color="#c4ccd2"
                sx={{ borderRadius: "8px" }}
              />
            }
            rowData={detailsTableData()}
            color="#c4ccd2"
          />
        </Box>
      }
    </>
  );
};

export default InstantSendSkeleton;
