import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  TextField,
} from "@mui/material";
import {
  addNewCustomer,
  customerLabel,
  customerPlaceHolder,
  customerRequiredMsg,
  switchCustomer,
  switchingCustomerCautionMsg,
} from "../messages";
import Text from "@speed/common/src/components/Text/Text";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import InfoIcon from "@mui/icons-material/Info";
import { useCallback, useEffect, useState } from "react";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import AddCustomerModal from "./AddCustomerModal";
import classNames from "classnames";
import MoreActionButton from "./MoreActionButton";
import { getWebSearchAPIResults } from "../constants";
import { useSelector } from "react-redux";
import { getCurrencyObj } from "@speed/common/src/components/constants";
import { CustomTooltip } from "@speed/common/src/components/Tooltip/Tooltip";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";

function InvoiceCusomer({
  saveLastSavedTime,
  formik,
  labelElement,
  createNewElement,
  disableEditFormElement,
  setDisableEditFormElement,
}) {
  const searchCutomerString = `is:customer`;
  const { values, setFieldValue, setTouched, touched, errors } = formik;
  const [customers, setCustomers] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openCustomerModal, setOpenCustomerModal] = useState(false);
  const [changeCustomer, setChangeCustomer] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchNextPage, setSearchNextPage] = useState(null);
  const [customersFromResult, setCustomersFromResult] = useState([]);
  const [hasMoreCustomerFromResult, setHasMoreCustomersFromResult] =
    useState(true);
  const [customerSkeleton, setCustomerSkeleton] = useState(false);
  const [hasMoreCustomers, setHasMoreCustomers] = useState(true);

  const hasMoreItem = searchQuery
    ? hasMoreCustomerFromResult
    : hasMoreCustomers;
  const liveMode = useSelector((state) => state.auth.liveMode);
  const getCustomers = (lines, initialAPICall = false) => {
    setCustomerSkeleton(true);
    const searchPage = initialAPICall ? null : searchNextPage;
    getWebSearchAPIResults(searchCutomerString, searchPage)
      .then((res) => {
        setCustomerSkeleton(false);
        if (!res.has_more) {
          setHasMoreCustomers(false);
          setSearchNextPage(null);
        } else {
          setSearchNextPage(res?.next_page);
        }
        setCustomers(lines.concat(res.data));
      })
      .catch(() => {
        setCustomerSkeleton(false);
      });
  };

  const fetchCustomers = () => {
    setCustomers([]);
    setHasMoreCustomers(true);
    getCustomers([], true);
  };

  const customerSearchResults = () => {
    return getWebSearchAPIResults(
      `${searchCutomerString} ${searchQuery}`,
      searchNextPage
    );
  };

  const getCustomersFromResult = (lines) => {
    setCustomerSkeleton(true);
    customerSearchResults()
      .then((res) => {
        setCustomerSkeleton(false);
        if (!res.has_more) {
          setHasMoreCustomersFromResult(false);
          setSearchNextPage(null);
        } else {
          setSearchNextPage(res?.next_page);
        }
        setCustomersFromResult(lines.concat(res.data));
      })
      .catch(() => {
        setCustomerSkeleton(false);
      });
  };

  const clearMemoizedCache = () => {
    getWebSearchAPIResults?.cache?.clear();
  };

  const loadMoreCustomers = useCallback(() => {
    getCustomers(customers);
  }, [customers]);

  const loadMoreCustomersFromResult = useCallback(() => {
    getCustomersFromResult(customersFromResult);
  }, [customersFromResult]);

  useEffect(() => {
    if (searchQuery) {
      setCustomerSkeleton(true);
      setSearchNextPage(null);
      setCustomersFromResult([]);
      setHasMoreCustomersFromResult(true);
      const apiDelayFunction = setTimeout(() => {
        // this function used to send Search API call after 500ms user typing
        getCustomersFromResult([]);
      }, 500);
      return () => clearTimeout(apiDelayFunction);
    } else {
      setCustomerSkeleton(false);
    }
  }, [searchQuery]);

  useEffect(() => {
    if (clearMemoizedCache) clearMemoizedCache();
  }, [liveMode]);

  const isShowCustomerDetailSection =
    values?.customer && Object.keys(values.customer).length > 0;

  useEffect(() => {
    if (!values.customer) {
      fetchCustomers();
      //to apply initial focus to autocomplete for first time trying to create new invoice and no customer selected
      document
        .querySelector(".invoice-customer-selection-menu .MuiButtonBase-root")
        ?.click();
    }
  }, []);

  useEffect(() => {
    if (changeCustomer) {
      //to apply initial focus to input element while adding new item click
      document.querySelector(".invoice-customer-input input")?.focus();
    }
  }, [changeCustomer]);

  const handleAddCustomer = () => {
    setOpenCustomerModal(true);
  };

  const handleChangeCustomer = (customer) => {
    saveLastSavedTime(values?.customer?.id !== customer?.id);
    setFieldValue("customer", customer || {});
    setFieldValue("currency", getCurrencyObj(customer?.currency || "USD"));
    setSearchQuery("");
    setSearchNextPage(null);
    setChangeCustomer(false);
    setDisableEditFormElement(false);
  };

  const renderSelectCusomterSection = () => {
    return (
      <>
        <CustomAutoComplete
          {...(changeCustomer && {
            open: true,
          })}
          disablePortal
          disableClearable
          customClass="invoice-customer-selection-menu"
          showLabel={false}
          options={searchQuery ? customersFromResult : customers}
          onChange={(_e, value) => handleChangeCustomer(value)}
          renderInput={(params) => {
            params.inputProps.value = searchQuery;
            return (
              <TextField
                {...params}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="invoice-customer-input"
                placeholder={customerPlaceHolder}
                onFocus={() => {
                  fetchCustomers();
                }}
                onBlur={() => {
                  setSearchNextPage(null);
                  setSearchQuery("");
                  setChangeCustomer(false);
                  setTouched({ ...touched, customer: true });
                }}
              />
            );
          }}
          noOptionsText={
            <>
              {createNewElement(addNewCustomer, handleAddCustomer)}
              {customerSkeleton && (
                <Box marginY={1} padding={1} borderRadius={1}>
                  <Skeleton
                    animation="wave"
                    width="50%"
                    sx={{ bgcolor: "#eaeef1 !important" }}
                  />
                </Box>
              )}
            </>
          }
          value={values.customer}
          getOptionLabel={(option) => option.name ?? ""}
          renderOption={(optionProps, option, state) => {
            const index = state.index;
            const customerResults = searchQuery
              ? customersFromResult
              : customers;
            return (
              <>
                {!index && (
                  <>
                    {createNewElement(addNewCustomer, handleAddCustomer)}
                    <CustomDivider sx={{ margin: "5px 0px" }} />
                  </>
                )}
                <Box
                  {...optionProps}
                  key={option?.id}
                  className="pointer-cursor customer-menu-item-box"
                >
                  <Text
                    size={14}
                    variant="h4"
                    className="default-text customer-name"
                  >
                    {option.name}
                  </Text>
                  <Text
                    font="regular"
                    size={14}
                    variant="h4"
                    className="grey-text customer-email-text"
                  >
                    {option?.email}
                  </Text>
                </Box>
                {customerSkeleton && index === customerResults.length - 1 && (
                  <Box
                    sx={{
                      margin: "10px",
                      borderRadius: "4px",
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      width="50%"
                      sx={{ bgcolor: "#eaeef1 !important" }}
                    />
                  </Box>
                )}
              </>
            );
          }}
          ListboxProps={{
            role: "list-box",
            onScroll: (event) => {
              const listboxNode = event.currentTarget;
              if (
                listboxNode.scrollTop + listboxNode.clientHeight >=
                listboxNode.scrollHeight - 10
              ) {
                if (hasMoreItem) {
                  searchQuery
                    ? loadMoreCustomersFromResult()
                    : loadMoreCustomers();
                }
              }
            },
          }}
        />
        {touched.customer && Boolean(errors.customer) && (
          <AlertMessage
            className="margin-top14"
            severity="error"
            message={customerRequiredMsg}
          />
        )}
      </>
    );
  };

  const renderCustomerDetailSection = () => {
    return (
      <Box className="customer-detail-box">
        <Box className="customer-name">
          <Text className="default-text" size={16}>
            {values.customer.name}
          </Text>
          <MoreActionButton
            buttonData={[
              {
                iconElement: <MoreHorizIcon />,
                onClick: (event) => {
                  event.stopPropagation();
                  setAnchorEl(anchorEl ? null : event.currentTarget);
                },
              },
            ]}
          />
          <CustomPopper
            disablePortal={true}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            position="bottom-end"
            handleClose={() => setAnchorEl(null)}
          >
            <List>
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => {
                    setChangeCustomer(true);
                    setAnchorEl(null);
                  }}
                >
                  <ListItemText
                    primary={
                      <>
                        {switchCustomer}
                        <CustomTooltip
                          arrow={true}
                          placement="right"
                          text={switchingCustomerCautionMsg}
                          className="custom-tooltip"
                        >
                          <InfoIcon className="change-customer-info-icon" />
                        </CustomTooltip>
                      </>
                    }
                  />
                </ListItemButton>
              </ListItem>
            </List>
          </CustomPopper>
        </Box>
        <Text
          variant="body1"
          className="customer-email-text"
          font="regular"
          size={14}
        >
          {values.customer.email}
        </Text>
      </Box>
    );
  };

  return (
    <Box
      className={classNames(
        "invoice-customer",
        disableEditFormElement && values.customer && "disable-edit-section"
      )}
    >
      {labelElement(customerLabel)}
      {!changeCustomer && isShowCustomerDetailSection
        ? renderCustomerDetailSection()
        : renderSelectCusomterSection()}
      <AddCustomerModal
        setInvoiceCustomer={(customerData) => {
          handleChangeCustomer(customerData);
          if (customerData) {
            clearMemoizedCache();
            setSearchNextPage(null);
          }
        }}
        openModal={openCustomerModal}
        setOpenModal={(value) => setOpenCustomerModal(value)}
      />
    </Box>
  );
}

export default InvoiceCusomer;
