import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { Text } from '../Text/Text';
import { Button } from '../Button/Button';
import './reveal-key.scss';
import { secretKey } from '../images';

export const RevealKey = ({ revealKeyText, reavealKeyButtonText, show, handleButtonClick }) => {
    const content = (
        <Box
            className="revealkey-wrapper"
        >
            <div className='revealKey-text-wrapper'>
                <Text
                    variant='body1'
                    font='regular'
                    className="desc-text"
                    size={16}
                >
                    {show ? <Box sx={{ paddingRight: '15px', margin: '2.5px 0px' }}>{revealKeyText}</Box> : <img
                        alt="default-secretKey-image"
                        src={secretKey}
                        style={{ width: '300px', height: '26px' }}
                    />}
                </Text>
                {!show &&
                    <Button
                        className="revealkey-btn"
                        label={reavealKeyButtonText}
                        onClick={handleButtonClick}
                        variant="default"
                    />}
            </div>
        </Box>
    );
    return (
        <>
            {content}
        </>
    );
};

export const propTypes = {
    revealKeyText: PropTypes.string,
    reavealKeyButtonText: PropTypes.string.isRequired,
    show: PropTypes.bool.isRequired
};

const defaultProps = {
    revealKeyText: '',
    reavealKeyButtonText: '',
    show: false
};

RevealKey.propTypes = propTypes;
RevealKey.defaultProps = defaultProps;