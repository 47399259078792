import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import React, { useState, useEffect } from "react";
import EmailDomains from "./EmailDomains";
import EmailPreferences from "./EmailPreferences";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import _ from "lodash";
import { domains, preferences } from "../../messages";

function Emails() {
  const [selectedTab, setSelectedTab] = useState("");

  const history = useSelector((state) => state.common.history);

  const tabData = [
    {
      label: domains,
      value: "1",
      tabContent: <EmailDomains />,
      tabName: "Domains",
      route: "/settings/emails",
    },
    {
      label: preferences,
      value: "2",
      tabContent: <EmailPreferences />,
      tabName: "preferences",
      route: "/settings/email-preferences",
    },
  ];

  useEffect(() => {
    if (history) {
      let currentRoute = _.find(tabData, { route: history.location.pathname });
      if (currentRoute) {
        setSelectedTab(currentRoute.value);
      }
    }
  }, [history, history && history.location.pathname]);

  const handleChange = (_event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <Box className="email-wrapper">
      <CustomTab
        onChange={handleChange}
        tabData={tabData}
        selectedTab={selectedTab}
        noScroll={true}
        setTabRoute={(routeUrl) => history.push(routeUrl)}
      />
    </Box>
  );
}

export default Emails;
