import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";
import React from "react";
import { Link } from "react-router-dom";
import {
  bitcoin,
  buttonTextForIdNotFound,
  details,
  entityNotFoundMessage,
  sats,
  subtitleForIdNotFound,
} from "../messages";
import WarningIcon from "@mui/icons-material/Warning";
import {
  clipboardElement,
  generateBreadcrumbs,
} from "@speed/common/src/components/constants";
import { RouteBreadcrumbs } from "../constants";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import Text from "@speed/common/src/components/Text/Text";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import LogsSkeleton from "../Logs/LogsSkeleton";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import { currencyBTC } from "../images";

const AssetsDetail = (props) => {
  const urlParams = useParams();
  const { liveMode } = useSelector((state) => state.auth);
  const isLinkNotFound = false;

  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });

  const headerContent = () => {
    return (
      <Box className="header-content" marginTop={3.25}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text className="default-text" size={24}>
            {bitcoin}
          </Text>
          <Box display="flex" className="rest-key-copy" gap={1}>
            {clipboardElement(
              "e691cbdd501f4e88229a1c1cb66es...",
              true,
              "all-assets-details-clipboard",
              "inputBox",
              true
            )}
          </Box>
        </Box>
        <Box mt="12px">
          <Text
            size={20}
            font="semibold"
            variant="subtitle"
            style={{ color: "#2A67FF" }}
          >
            {sats}
          </Text>
        </Box>
      </Box>
    );
  };

  if (isLinkNotFound) {
    return (
      <Box className="not-found-wrapper">
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        <LinkNotFound
          icon={<WarningIcon className="warning-icon" />}
          title={entityNotFoundMessage("Link")}
          subTitle={`Asset ${urlParams.id} is not found.`}
          btnText={`View ${(liveMode && "live") || "test"} mode links`}
        />
      </Box>
    );
  }

  const showDetailPage = () => {
    return true ? (
      <Grid
        pt="18px"
        display="table-cell"
        width="1080px !important"
        container
        spacing={1}
        className="payment-links-wrapper restricted-key-detail-page"
      >
        <Box className="section-wrapper" sx={{ position: "relative" }}>
          <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
          {true ? (
            <Box display="flex" flexDirection="column">
              <Box>
                {headerContent()}
                <Box className="details-content" marginTop="64px">
                  <Text size={20} className="default-text">
                    {details}
                  </Text>
                </Box>
              </Box>
              <CustomDivider sx={{ mt: "12px" }} />
              <Box display="flex">
                <Box width="525px">
                  <Box display="flex" alignItems="center" pt="18px">
                    <Text
                      className="grey-text"
                      size={16}
                      variant="body1"
                      font="regular"
                      style={{ width: "170px" }}
                    >
                      Status
                    </Text>
                    <Text
                      className="default-text"
                      size={14}
                      variant="body1"
                      font="regular"
                    >
                      Active
                    </Text>
                  </Box>
                  <Box display="flex" alignItems="center" pt="18px">
                    <Text
                      className="grey-text"
                      size={16}
                      variant="body1"
                      font="regular"
                      style={{ width: "170px" }}
                    >
                      Asset name
                    </Text>
                    <Text
                      className="default-text"
                      size={14}
                      variant="body1"
                      font="regular"
                    >
                      Bitcoin
                    </Text>
                  </Box>
                  <Box display="flex" alignItems="center" pt="18px">
                    <Text
                      className="grey-text"
                      size={16}
                      variant="body1"
                      font="regular"
                      style={{ width: "170px" }}
                    >
                      Asset type
                    </Text>
                    <Text
                      className="default-text"
                      size={14}
                      variant="body1"
                      font="regular"
                    >
                      Currency
                    </Text>
                  </Box>
                  <Box display="flex" alignItems="center" pt="18px">
                    <Text
                      className="grey-text"
                      size={16}
                      variant="body1"
                      font="regular"
                      style={{ width: "170px" }}
                    >
                      Currency Accronym
                    </Text>
                    <Text
                      className="default-text"
                      size={14}
                      variant="body1"
                      font="regular"
                    >
                      SAT
                    </Text>
                  </Box>
                  <Box display="flex" alignItems="center" pt="18px">
                    <Text
                      className="grey-text"
                      size={16}
                      variant="body1"
                      font="regular"
                      style={{ width: "170px" }}
                    >
                      Asset Id
                    </Text>
                    <Box display="flex" alignItems="center">
                      <Text
                        className="default-text"
                        size={16}
                        variant="body1"
                        font="regular"
                        sx={{ width: "300px", paddingRight: "6px" }}
                      >
                        e691cbdd501f4e88229a1c1cb66es...
                      </Text>
                      <Clipboard text={"e691cbdd501f4e88229a1c1cb66es..."} />
                    </Box>
                  </Box>
                  <Box display="flex" alignItems="center" pt="18px">
                    <Text
                      className="grey-text"
                      size={16}
                      variant="body1"
                      font="regular"
                      style={{ width: "170px" }}
                    >
                      Currency Supply
                    </Text>
                    <Text
                      className="default-text"
                      size={14}
                      variant="body1"
                      font="regular"
                    >
                      1
                    </Text>
                  </Box>
                  <Box display="flex" alignItems="center" pt="18px">
                    <Text
                      className="grey-text"
                      size={16}
                      variant="body1"
                      font="regular"
                      style={{ width: "170px" }}
                    >
                      Description
                    </Text>
                    <Text
                      className="default-text"
                      size={14}
                      variant="body1"
                      font="regular"
                    >
                      Bitcoin - The base currency
                    </Text>
                  </Box>
                </Box>
                <Box ml="30px">
                  <Box display="flex" flexDirection="column">
                    <Text
                      className="grey-text"
                      size={16}
                      variant="body1"
                      font="regular"
                      style={{ paddingTop: "18px", paddingBottom: "4px" }}
                    >
                      Asset Icon
                    </Text>
                    <img
                      src={currencyBTC}
                      alt=""
                      style={{
                        width: "190px",
                        height: "190px",
                      }}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          ) : (
            <LogsSkeleton />
          )}
        </Box>
      </Grid>
    ) : (
      <AccessDenied />
    );
  };

  return !isLinkNotFound ? (
    showDetailPage()
  ) : (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage("Asset")}
        subTitle={subtitleForIdNotFound("Asset", urlParams.id)}
        btnText={buttonTextForIdNotFound("Asset", liveMode)}
      />
    </Box>
  );
};

export default AssetsDetail;
