import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import GetStartedHeader from "@speed/common/src/components/GetStartedHeader/GetStartedHeader";
import { callStrapiAPIInterface } from "../constants";
import { setIsModalOpen } from "../../redux/common/actions";
import NeedHelp from "./NeedHelp";
import GuideHeader from "./GuideHeader";
import history from "@speed/common/src/components/history";

const GetGuides = ({
  moduleName,
  redirectUrl,
  guideApiPath,
  getStartedApiPath,
  needHelpGuidePath = "",
  showSteps = true,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isGetHeaderInfoLoading, setGetHeaderInfoLoading] = useState(true);
  const [guideData, setGuideData] = useState(null);
  const [onboardingSteps, setOnboardingSteps] = useState(null);

  useEffect(() => {
    getGuideData();
    showSteps && getOnboardingSteps();
  }, []);

  // Api call for guide header
  const getGuideData = () => {
    setGetHeaderInfoLoading(true);
    callStrapiAPIInterface(
      "GET",
      `/api/${guideApiPath}?populate[header][populate][use_cases][populate]=*`
    )
      .then((response) => {
        setGuideData(response?.data?.attributes?.header);
        setGetHeaderInfoLoading(false);
      })
      .catch((error) => {
        setGetHeaderInfoLoading(false);
      });
  };

  // Filtered Api call for get started
  const getOnboardingSteps = () => {
    setIsLoading(true);
    callStrapiAPIInterface(
      "GET",
      `/api/get-started-speeds?filters[title][$containsi]=${getStartedApiPath}&[populate]=*`
    )
      .then((response) => {
        setOnboardingSteps(response?.data?.[0]?.attributes);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  return (
    <Box className="section-wrapper" bgcolor="#f7fafc">
      <Box className="main-content-initial-setup">
        {history.location.pathname !== "/speed-point-of-sale" && (
          <GetStartedHeader
            getHeaderInfo={guideData}
            loading={isGetHeaderInfoLoading}
            setIsModalOpen={setIsModalOpen}
            redirectUrl={redirectUrl}
          />
        )}

        {showSteps && (
          <Box paddingTop="46px" className="main-content-initial-setup">
            <GuideHeader
              loading={isLoading}
              onBoardingSteps={onboardingSteps}
              moduleName={moduleName}
            />
          </Box>
        )}
        <NeedHelp
          readGuideUrl={`${process.env.REACT_APP_SPEED_DEV_URL}${needHelpGuidePath}`}
        />
      </Box>
    </Box>
  );
};

export default GetGuides;
