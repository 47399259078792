import React from "react";
import { mainLogo } from "@speed/common/src/components/images";
import { Box } from "@mui/material";
import Translation from "@speed/common/src/components/Translation";
import Text from "@speed/common/src/components/Text/Text";

function FooterWithLogo({ fontStyle }) {
  return (
    <Box className="logo-with-footer">
      <Text
        size={12}
        className="grey-text"
        sx={{ fontFamily: fontStyle?.fontFamily, marginRight: "11px" }}
        font="semibold"
        variant="subtitle1"
      >
        <Translation value="poweredBy" />
      </Text>
      <img src={mainLogo} alt="logo" height={26} width={85} />
    </Box>
  );
}

export default FooterWithLogo;
