import { Grid, Box } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import Text from "@speed/common/src/components/Text/Text";
import { whiteLogoImage } from "../constants";
import {
  welcomeSubText,
  signUpText,
  affiliateInvitationMessage,
  affiliateInvitationBy,
} from "../messages";

const WelcomeBox = ({ data = {} }) => {
  const { isMobile, history } = useSelector((state) => state.common);
  const isFromAffiliatePartnerJoin =
    history?.location?.pathname === "/partners/affiliate-partners/join";
  return (
    !isMobile && (
      <Grid
        item
        elevation={6}
        className="sign-up-welcome-card"
        flexDirection="column"
      >
        <Box className="welcome-box">
          <Box sx={{ paddingBottom: "40px" }}>{whiteLogoImage}</Box>
          <Text size={58} variant="h2" className="default-text welcome-text">
            {isFromAffiliatePartnerJoin
              ? affiliateInvitationBy(data?.account_name)
              : signUpText()}
          </Text>
          <Box>
            <Text
              size={20}
              variant="h4"
              className="default-text welcome-subtext"
              font="regular"
              sx={{ lineHeight: "160% !important" }}
            >
              {isFromAffiliatePartnerJoin
                ? affiliateInvitationMessage(data, true)
                : welcomeSubText()}
            </Text>
          </Box>
        </Box>
      </Grid>
    )
  );
};
export default WelcomeBox;
