import { Skeleton, Box } from "@mui/material";
import Button from "@speed/common/src/components/Button/Button";
import { OTPInput } from "@speed/common/src/components/OTPInput/OTPInput";
import Text from "@speed/common/src/components/Text/Text";
import { callAPIInterface } from "../constants";
import { useEffect, useState } from "react";
import { tryAgainOtpMessage, verifyItsYouTextMsg } from "../messages";
import { useDispatch, useSelector } from "react-redux";
import {
  setExecuteAPICall,
  setLoading,
  showToast,
} from "../../redux/common/actions";
import { verifyEmailOTP } from "../../redux/auth/actions";
import {
  DidNotGetCodeLink,
  MFAOTPTimer,
  getCurrentTimeInSecond,
} from "@speed/common/src/components/constants";
import { authenticate } from "@speed/common/src/messages";

const CommonVerifyOTPSection = ({
  footerAuthenticationButton = false,
  setPropOtp,
  setDisableOTPFooterBtn,
}) => {
  const [otp, setOtp] = useState("");
  const [sendOTPLoader, setSendOTPLoader] = useState(true);
  const [alreadySentEmail, setAlreadySentEmail] = useState(false);
  const [timerCount, setTimerCount] = useState();
  const [expiresAt, setExpiresAt] = useState();
  const [isExpiredOTP, setIsExpiredOTP] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isLoading = useSelector((state) => state.common.isLoading);

  const handleOtpChange = (changedOtp) => {
    setPropOtp?.(changedOtp);
    setOtp(changedOtp);
    const isValidOTP = timerCount && changedOtp.length >= 6;
    setDisableOTPFooterBtn?.(!isValidOTP);
    setDisabled(!isValidOTP);
  };

  const confirmOTP = () => {
    dispatch(setLoading(true));
    dispatch(
      verifyEmailOTP({
        otp,
      })
    ).then((res) => {
      if (res) dispatch(setExecuteAPICall(true));
      else dispatch(setLoading(false));
    });
  };

  const setTimerStates = (timerExpireAt) => {
    const currentSeconds = getCurrentTimeInSecond(timerExpireAt);
    setTimerCount(currentSeconds);
    setIsExpiredOTP(currentSeconds === 0);
  };

  const sendEmailOTP = async () => {
    setSendOTPLoader(true);
    try {
      const response = await callAPIInterface(
        "POST",
        "/critical-operation/otp",
        {}
      );
      setAlreadySentEmail(response.email_sent);
      setExpiresAt(response?.otp_expires_at);
      setTimerStates(response?.otp_expires_at);
      setSendOTPLoader(false);
    } catch {
      setSendOTPLoader(false);
      dispatch(
        showToast({
          isToastOpen: true,
          toastMessage: tryAgainOtpMessage,
          toastVariant: "error",
          toastAutoHideDuration: 5000,
        })
      );
    }
  };

  useEffect(() => {
    sendEmailOTP();
  }, []);

  useEffect(() => {
    if (expiresAt && timerCount > 0) {
      const timer = setInterval(() => {
        setTimerStates(expiresAt);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [expiresAt, timerCount]);

  return (
    <>
      <Text
        align="center"
        className="default-text"
        size={16}
        font="regular"
        variant="caption"
      >
        {sendOTPLoader ? (
          <Box sx={{ display: "grid", justifyItems: "center" }}>
            <Skeleton animation="wave" width={385} height={25} />
            <Skeleton animation="wave" width={330} height={25} />
          </Box>
        ) : (
          <>
            {verifyItsYouTextMsg[alreadySentEmail ? 1 : 0]}{" "}
            <Text component="span" size={16}>
              {user?.email}
            </Text>
          </>
        )}
      </Text>

      <OTPInput
        value={otp}
        onChange={handleOtpChange}
        numInputs={6}
        className="margin-top25"
        containerStyle={{ justifyContent: "space-between", width: "100%" }}
      />
      {!footerAuthenticationButton && (
        <Button
          onClick={confirmOTP}
          loading={isLoading}
          type="submit"
          className="margin-top25"
          fullWidth
          label={authenticate}
          variant="contained"
          disabled={!timerCount || disabled}
        />
      )}
      {sendOTPLoader ? (
        <Skeleton
          sx={{ marginTop: "25px" }}
          animation="wave"
          width={200}
          height={20}
        />
      ) : (
        <>
          {timerCount > 0 && <MFAOTPTimer timerCount={timerCount} />}
          {isExpiredOTP && (
            <DidNotGetCodeLink
              isFromAuth
              textProps={{
                className: "expired-email-otp",
              }}
              handleResendCode={() => {
                sendEmailOTP();
                setOtp("");
                setPropOtp?.("");
              }}
            />
          )}
        </>
      )}
    </>
  );
};

export default CommonVerifyOTPSection;
