import React, { useState, useEffect } from "react";
import _, { isNull } from "lodash";
import CustomTable from "@speed/common/src/components/Table/Table";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Text from "@speed/common/src/components/Text/Text";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  idealTimeLimitReached,
  updateSessionLastActionTime,
  callAPIInterface,
  secretKeyCount,
  setShowCrucialActionModal,
  dateTimeFormatInApp,
} from "../constants";
import { setLoading, setExecuteAPICall } from "../../redux/common/actions";
import { Button } from "@speed/common/src/components/Button/Button";
import {
  createSecretKey,
  revealSecretKey,
  revealPublishableKey,
  reveal,
  secret,
  publishable,
  hide,
  publishableType,
  secretType,
  rotateKey,
} from "../messages";
import { useSelector, useDispatch } from "react-redux";
import { sessionService } from "redux-react-session";
import { RevealKey } from "@speed/common/src/components/RevealKey/revealKey";
import CreateKey from "./CreateKey";
import KeyNote from "./KeyNote";
import { Info } from "@mui/icons-material";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import CommonHeader from "../Common/CommonHeader";
import { loaderSpinner } from "@speed/common/src/components/images";
const Standard = () => {
  const showReveal = false;
  const showSecretDefault = false;
  const [showPublishable, setShowPublishable] = useState(false);
  const [rows, setRows] = useState([]);
  const [currentRow, setCurrentRow] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [openSecretKeyModal, setOpenSecretKeyModal] = useState(false);
  const [openApiKeyModal, setOpenApiKeyModal] = useState(false);
  const [session, setSession] = useState(null);
  const [apiKey, setApiKey] = useState(null);
  const [rowId, setRowId] = useState(null);
  const [rowLoader, setRowLoader] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [description, setDescription] = useState("");
  const [actionAnchorElm, setActionAnchorElm] = useState(null);
  const [rotateKeyLoader, setRotateKeyLoader] = useState(false);

  const dispatch = useDispatch();

  const executeAPICall = useSelector((state) => state.common.executeAPICall);
  const { user, liveMode } = useSelector((state) => state.auth);
  const handleCloseApiModal = () => {
    setOpenApiKeyModal(false);
    setRows([]);
    getListKeys();
  };
  const handleCloseSecretModal = () => {
    setOpenSecretKeyModal(false);
  };

  const handleSecretCount = () => {
    const secretCount = _.filter(rows, {
      type: secretType,
      default_key: false,
    }).length;
    if (secretCount >= secretKeyCount) {
      setDisabled(true);
    }
  };

  useEffect(() => {
    handleSecretCount();
  }, [rows]);

  useEffect(() => {
    sessionService.loadSession().then((newSession) => {
      setSession(newSession);
    });
    setShowPublishable(false);
    setRows([]);
    getListKeys();
  }, [liveMode]);

  const handleKeys = (id, key) => {
    setRowId(id);
    setRowLoader(true);
    (key === publishable || key === secret) && createDefaultKeys(key);
    key === reveal && revealSecretKeyData(id);
  };

  const handleClick = (id, key) => {
    if (!showReveal || !showSecretDefault || !showPublishable) {
      if (idealTimeLimitReached(session.last_action_time))
        setShowCrucialActionModal(user, true);
      else {
        handleKeys(id, key);
      }
    }
  };
  const handleHide = (id) => {
    setRowId(null);
    setRowLoader(true);
    const findRow = _.find(rows, (row) => {
      return row.id === id;
    });
    findRow["api_key"] = null;
    const updatedRows = _.map(rows, (row) => {
      return row.id === id ? findRow : row;
    });
    setRows(updatedRows);
    setRowLoader(false);
  };

  useEffect(() => {
    if (executeAPICall) {
      // need to call api
      const newSession = updateSessionLastActionTime();
      setSession(newSession);
      const keyType =
        currentRow?.id !== 2 && currentRow?.type === secretType
          ? reveal
          : currentRow?.name;
      handleKeys(currentRow?.id, keyType);
      dispatch(setLoading(false));
      setShowCrucialActionModal(user, false);
      dispatch(setExecuteAPICall(false));
    }
  }, [executeAPICall]);

  const createDefaultKeys = (name) => {
    const keyData = {
      name: name,
      description: "",
      default_key: true,
    };
    let method,
      data,
      path = "";
    if (name === publishable) {
      method = "POST";
      data = keyData;
      path = "/api-key/publishable-key";
    } else {
      method = "POST";
      data = keyData;
      path = "/api-key/secret-key";
    }
    return callAPIInterface(method, path, data)
      .then((response) => {
        if (response) {
          setRows([]);
          const updatedRows =
            name === publishable
              ? _.map(rows, (row) => {
                  return row.id === 1 ? response : row;
                })
              : _.map(rows, (row) => {
                  return row.id === 2 ? response : row;
                });
          if (name === publishable) setShowPublishable(true);
          setRows(updatedRows);
          setRowLoader(false);
        }
      })
      .catch((_e) => false);
  };

  const revealSecretKeyData = (id) => {
    return callAPIInterface("GET", "/api-key/reveal/" + id, "")
      .then((response) => {
        if (response) {
          const updatedRows = _.map(rows, (row) => {
            return row.id === response.id ? response : row;
          });
          setRowId(id);
          setRows(updatedRows);
          setRowLoader(false);
        }
      })
      .catch((_e) => false);
  };

  const columns = [
    { title: "Name", width: "260px", paddingLeft: "38px !important" },
    { title: "Token", width: "500px" },
    { title: "Created On", width: "235px" },
    { title: "Action", width: "120px" },
  ];
  const rowsDefaultData = [
    {
      id: 1,
      name: "Publishable key",
      api_key: null,
      type: publishableType,
      default_key: true,
      created: null,
      modified: null,
    },
    {
      id: 2,
      name: "Secret key",
      api_key: null,
      type: secretType,
      default_key: true,
      created: null,
      modified: null,
    },
  ];

  const sortedData = (dataSorted, dataConcat, secret) => {
    const defaultData = _.sortBy([dataSorted].concat(dataConcat), ["type"]);
    return secret ? defaultData.concat(secret) : defaultData;
  };

  const getListKeys = async (dispatch) => {
    setTableRowSkeleton(true);
    return callAPIInterface("GET", "/api-key/list-keys", "")
      .then(async (response) => {
        if (response) {
          setTableRowSkeleton(false);
          const resKeys = response?.data || [];
          const standardKeys = resKeys.filter((key) =>
            [publishableType, secretType].includes(key.type)
          );

          if (standardKeys?.length) {
            const publishable = _.find(standardKeys, {
              type: publishableType,
            });
            const secretDefault = _.find(standardKeys, {
              type: secretType,
              default_key: true,
            });
            const secret = _.filter(standardKeys, {
              type: secretType,
              default_key: false,
            });

            let sortedResponse;
            if (publishable && secretDefault) {
              sortedResponse = sortedData(publishable, secretDefault, secret);
            } else if (secretDefault) {
              sortedResponse = sortedData(
                rowsDefaultData[0],
                secretDefault,
                secret
              );
            } else if (publishable) {
              sortedResponse = sortedData(
                rowsDefaultData[1],
                publishable,
                secret
              );
            } else if (secret.length) {
              sortedResponse = rowsDefaultData.concat(secret);
            }
            dispatch(setRows(sortedResponse));
          } else {
            dispatch(setRows(rowsDefaultData));
          }
        }
      })
      .catch((_e) => false);
  };

  const handleRotateKey = () => {
    setRotateKeyLoader(true);
    return callAPIInterface("POST", `/api-key/secret-key/rotate/${rowId}`, {})
      .then((response) => {
        if (response) {
          setApiKey(response.api_key);
          setOpenApiKeyModal(true);
          setActionAnchorElm(null);
          setRotateKeyLoader(false);
        }
      })
      .catch(() => {
        setActionAnchorElm(null);
        setRotateKeyLoader(false);
      });
  };

  const columnsData = columns.map((column) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{ minWidth: column.width, paddingLeft: column.paddingLeft }}
    >
      {column.title}
    </TableCell>
  ));

  const customPopperElement = () => {
    return (
      <CustomPopper
        disablePortal={true}
        open={currentRow.id === rowId ? Boolean(anchorEl) : false}
        anchorEl={anchorEl}
        position="top"
        id={rowId}
        handleClose={() => setAnchorEl(null)}
      >
        <Text variant="inherit" font="regular" size={16}>
          {description}
        </Text>
      </CustomPopper>
    );
  };

  const publishableData = (rowItem) =>
    rowItem.api_key === null ? (
      <Box sx={{ margin: "3px 0px 0px 0px" }}>
        <RevealKey
          revealKeyText={rowItem.api_key}
          revealKeyType={rowItem.type}
          reavealKeyButtonText={revealPublishableKey(liveMode)}
          show={showPublishable}
          handleButtonClick={() => handleClick(rowItem.id, rowItem.name)}
        />
      </Box>
    ) : (
      <Box className="flex-inline">
        <Box sx={{ paddingRight: "15px", margin: "2.5px 0px" }}>
          {rowItem.api_key}
        </Box>
        <Clipboard text={rowItem.api_key} />
      </Box>
    );

  const renderSecretKeyData = (rowItem) => {
    if (rowItem.type === secretType && rowItem.id === 2) {
      return (
        <Box sx={{ margin: "3px 0px 0px 0px" }}>
          <RevealKey
            revealKeyText={rowItem.api_key}
            reavealKeyButtonText={revealSecretKey(liveMode)}
            show={showSecretDefault}
            handleButtonClick={() => handleClick(rowItem.id, secret)}
          />
        </Box>
      );
    }

    if (rowItem.type === secretType && isNull(rowItem.api_key)) {
      return (
        <Box sx={{ margin: "3px 0px 0px 0px" }}>
          <RevealKey
            revealKeyText={rowItem.api_key}
            revealKeyType={rowItem.type}
            reavealKeyButtonText={revealSecretKey(liveMode)}
            show={showReveal}
            handleButtonClick={() => handleClick(rowItem.id, reveal)}
          />
        </Box>
      );
    }

    return (
      <Box className="flex-inline">
        <Box sx={{ paddingRight: "15px", margin: "2.5px 0px" }}>
          {rowItem.api_key}
        </Box>
        <Clipboard text={rowItem.api_key} />
        <Box sx={{ paddingLeft: "10px" }}>
          <Button
            className="secret-hide-btn"
            label={hide}
            onClick={() => handleHide(rowItem.id)}
            variant="default"
          />
        </Box>
      </Box>
    );
  };

  const handleClickActionBtn = (event, id) => {
    setActionAnchorElm(actionAnchorElm ? null : event.currentTarget);
    setRowId(id);
  };

  const renderActionPopper = () => (
    <CustomPopper
      disablePortal={true}
      open={Boolean(actionAnchorElm)}
      anchorEl={actionAnchorElm}
      position="bottom"
      handleClose={() => {
        setActionAnchorElm(null);
      }}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton disabled={rotateKeyLoader} onClick={handleRotateKey}>
            <ListItemText primary={rotateKey} />
            {rotateKeyLoader && (
              <img
                style={{ marginLeft: "10px" }}
                src={loaderSpinner}
                alt="Loader"
                width={15}
                height={15}
              />
            )}
          </ListItemButton>
        </ListItem>
      </List>
    </CustomPopper>
  );

  const rowCellData = (rowItem) => (
    <TableCell>
      {rowItem.type === publishableType && rowItem.default_key
        ? publishableData(rowItem)
        : renderSecretKeyData(rowItem)}
    </TableCell>
  );

  const rowsData =
    rows &&
    rows.map((rowItem) => (
      <TableRow key={rowItem.id} onClick={() => setCurrentRow(rowItem)}>
        {rowItem.id === rowId && rowLoader ? (
          columns.map((column) => {
            return (
              <TableCell key={column.title} sx={{ width: column.width }}>
                <Skeleton />
              </TableCell>
            );
          })
        ) : (
          <>
            <TableCell sx={{ paddingLeft: "38px !important" }}>
              <Box className="flex-inline">
                <Box>{rowItem.name}</Box>
                {rowItem.description && (
                  <Box className="info-icon">
                    <Info
                      className="label-with-icon"
                      onClick={(e) => {
                        setAnchorEl(anchorEl ? null : e.currentTarget);
                        setRowId(rowItem.id);
                        setDescription(rowItem.description);
                      }}
                      style={{
                        height: 16,
                        width: 16,
                        color: "#848b9e",
                        marginTop: "5px",
                      }}
                    />
                    {customPopperElement()}
                  </Box>
                )}
              </Box>
            </TableCell>
            {rowCellData(rowItem)}
            <TableCell>
              {rowItem.modified && dateTimeFormatInApp(rowItem.modified)}
            </TableCell>
            <TableCell>
              {rowItem.type === secretType && rowItem.id !== 2 && (
                <MoreHorizIcon
                  className="horizontal-dots-icon pointer-cursor"
                  tabIndex={0}
                  onClick={(e) => handleClickActionBtn(e, rowItem.id)}
                />
              )}
            </TableCell>
          </>
        )}
      </TableRow>
    ));

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    className: "link-table",
    tableRowSkeleton: tableRowSkeleton,
  };
  return (
    <Box className="section-wrapper payment-links-wrapper">
      <CommonHeader />
      <CustomDivider />
      {liveMode && (
        <Box className="action-btn-wrapper mui-fixed">
          <Button
            icon="addIcon"
            className="add-icon"
            disabled={disabled}
            label={createSecretKey}
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpenSecretKeyModal(true);
            }}
          />{" "}
        </Box>
      )}
      <Box className="main-content">
        <CustomTable {...tableProps} />
      </Box>
      {renderActionPopper()}
      {openSecretKeyModal && (
        <CreateKey
          openSecretKeyModal={openSecretKeyModal}
          handleCloseSecretModal={handleCloseSecretModal}
          setOpenApiKeyModal={() => setOpenApiKeyModal(true)}
          setApiKey={setApiKey}
        />
      )}
      {openApiKeyModal && apiKey !== null && (
        <KeyNote
          openApiKeyModal={openApiKeyModal}
          handleCloseApiModal={handleCloseApiModal}
          apiKey={apiKey}
        />
      )}
    </Box>
  );
};
export default Standard;
