import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { Box, Grid } from "@mui/material";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import { useFormik } from "formik";
import {
  oneQRDescription,
  oneQRDescriptionLabel,
  oneQRLabel,
  oneQRNameRequired,
  QRTypeLabel,
} from "../messages";
import * as yup from "yup";
import { QRTypeArray, renderOneQRType } from "../constants";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import { Input } from "@speed/common/src/components/Input/Input";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { setBrandingPageUI } from "../../redux/common/actions";

const OneQRLinkForm = forwardRef((props, ref) => {
  const commonStyle = { ml: "2px", width: "99%" };

  const brandingPageUI = useSelector((state) => state.common.brandingPageUI);

  const dispatch = useDispatch();

  const validationSchema = yup.object({
    oneqr_method: yup.mixed().required(),
    name: yup.mixed().required(oneQRNameRequired),
  });

  const formik = useFormik({
    initialValues: {
      oneqr_method: { name: "Lightning QR code", value: "Lightning QR code" },
      name: "",
      description: "",
      showPayVia: { name: "USA top wallets", value: "USA top wallets" },
    },
    validationSchema: validationSchema,
  });

  const {
    values,
    errors,
    isValid,
    isSubmitting,
    dirty,
    setFieldValue,
    setTouched,
    touched,
  } = formik;

  useEffect(() => {
    const shouldDisable = !(isValid && dirty) || isSubmitting;
    props.handleButtonDisable(shouldDisable);
    dispatch(
      setBrandingPageUI({
        ...brandingPageUI,
        qr_type: values.oneqr_method.value,
      })
    );
  }, [isValid, isSubmitting, values]);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      return {
        oneqr_method: values.oneqr_method.value,
        name: values.name,
        description: values.description,
        showPayVia: values.showPayVia,
      };
    },
  }));

  return (
    <Box
      className="apply-auto-scroll"
      component="form"
      autoComplete="off"
      sx={{ display: props.isOneQRLink ? "none" : "block" }}
    >
      <Box sx={{ width: "480px" }}>
        <Grid marginLeft="1px">
          <CustomAutoComplete
            name="oneqr_method"
            options={QRTypeArray}
            getOptionLabel={(option) => {
              return `${option.value}`;
            }}
            value={values.oneqr_method}
            showLabel
            label={QRTypeLabel}
            sx={commonStyle}
            onChange={(_e, value) => {
              setFieldValue("oneqr_method", value);
            }}
            disableClearable
            fullWidth
            renderOption={renderOneQRType}
          />
          <Box className="margin-top30" sx={commonStyle}>
            <Input
              customClass="margin-top30"
              showLabel
              value={values.name}
              name="name"
              label={oneQRLabel}
              type="text"
              fullWidth
              inputProps={{ maxLength: 40 }}
              onChange={(e, value) => {
                setTouched({ ...touched, name: false });
                setFieldValue("name", e.target.value);
              }}
              onBlur={() => setTouched({ ...touched, name: true })}
            />
            {errors.name && Boolean(errors.name) && touched.name && (
              <AlertMessage
                message={errors.name}
                className="margin-top15"
                severity="error"
              />
            )}
          </Box>
          <Box className="margin-top30" sx={commonStyle}>
            <TextAreaComponent
              showLabel
              value={values.description}
              maxRows={3}
              minRows={3}
              maxLength={255}
              fullWidth
              resize={false}
              label={oneQRDescriptionLabel}
              error={Boolean(errors.description)}
              onChange={(e) => {
                setFieldValue("description", e.target.value);
              }}
              placeholder={oneQRDescription}
            />
          </Box>
        </Grid>
      </Box>
    </Box>
  );
});

export default OneQRLinkForm;
