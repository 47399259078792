import { Box } from "@mui/material";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import Text from "@speed/common/src/components/Text/Text";
import {
  addFollowingRecords,
  ccdDNSName,
  ccdDNSTtl,
  ccdDNSType,
  ccdDNSValue,
  instructions,
  step1,
  step1Msg,
  step2,
  verified,
} from "../../messages";
import RecordValues from "./RecordValues";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const CustomDomainDNSRecords = ({ dnsRecords }) => {
  const renderDNSRecords = (
    { short_name, name, expected_value, type, valid },
    index
  ) => {
    return (
      <Box className="dns-records-box" key={index}>
        <Text
          font="semibold"
          size={16}
          variant="subtitle1"
          className="default-text"
        >
          {name}{" "}
          {valid && (
            <Tag
              sx={{ marginLeft: "5px" }}
              text={verified}
              variant="success"
              deleteIcon={
                <CheckCircleIcon className="custom-domain-active-icon" />
              }
              onDelete={() => {}}
            />
          )}
        </Text>
        <RecordValues keyName={ccdDNSName} keyValue={short_name} />
        <RecordValues keyName={ccdDNSType} keyValue={type} />
        <RecordValues keyName={ccdDNSValue} keyValue={expected_value} />
        <RecordValues keyName={ccdDNSTtl} keyValue={300} />
      </Box>
    );
  };
  const renderSteps = ({ number, text }, index) => {
    return (
      <Box display="flex" sx={{ marginTop: "30px" }} key={index}>
        <Box className="step-number-box">
          <Text
            size={12}
            font="semibold"
            variant="subtitle1"
            className="default-text"
          >
            {number}
          </Text>
        </Box>
        {text}
      </Box>
    );
  };

  const stepsContent = [
    {
      number: 1,
      text: (
        <Text
          size={16}
          font="regular"
          variant="subtitle1"
          className="default-text"
        >
          {dnsRecords.ns_provider ? (
            <>
              {step1[0]}
              <CustomLink bold={true} size="large">
                {dnsRecords.ns_provider}
              </CustomLink>
              {step1[1]}
            </>
          ) : (
            step1Msg
          )}
        </Text>
      ),
    },
    {
      number: 2,
      text: (
        <Box>
          <Text
            size={16}
            font="regular"
            variant="subtitle1"
            className="default-text"
          >
            {step2}
          </Text>
          <CustomLink
            size="small"
            bold={true}
            target="_blank"
            href={`${process.env.REACT_APP_SPEED_DEV_URL}docs/business-operations/custom-domain/custom-checkout-domain`}
          >
            {instructions}
          </CustomLink>
        </Box>
      ),
    },
    {
      number: 3,
      text: (
        <Text
          size={16}
          font="regular"
          variant="subtitle1"
          className="default-text"
        >
          {addFollowingRecords}
        </Text>
      ),
    },
  ];

  return (
    <Box>
      {stepsContent.map((item, index) => {
        return renderSteps({ ...item }, index);
      })}

      {dnsRecords.dns_records.map((item, index) => {
        return renderDNSRecords({ ...item }, index);
      })}
    </Box>
  );
};

export default CustomDomainDNSRecords;
