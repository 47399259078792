import { Box, Grid } from "@mui/material";
import React from "react";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import TransactionJson from "./TransactionJson";
import { useSelector } from "react-redux";
import WalletTransactionsList from "./WalletTransactionsList";

const AllTransaction = ({ tab = "" }) => {
  const walletSelectedTransaction = useSelector(
    (state) => state.common.walletSelectedTransaction
  );

  return (
    <Box className="wallet-transaction-container">
      <Grid container>
        <Grid
          item
          xs={walletSelectedTransaction ? 6.49 : 12}
          overflow="auto"
          bgcolor="#fff"
        >
          <WalletTransactionsList fromMyAssets={false} tab={tab} />
        </Grid>
        {walletSelectedTransaction && (
          <>
            <CustomDivider flexItem orientation="vertical" />
            <TransactionJson tab={tab} />
          </>
        )}
      </Grid>
    </Box>
  );
};

export default AllTransaction;
