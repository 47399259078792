import React, { useEffect, useState } from "react";
import {
  getDefaultImage,
  getLightColor,
  isLightColor,
  truncateStringWithTooltip,
  validateColor,
} from "../constants";
import { Box } from "@mui/material";
import { CustomAvatar } from "../Avatar/Avatar";
import TruncatedTextTooltip from "../TruncatedTextTooltip";
import { selectedAccountStaticText, staticText } from "../../messages";
import Text from "../Text/Text";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import Translation from "../Translation";

const ShowDefaultLogo = ({ previewColor, image }) => {
  return (
    <Box
      className="account-logo-default connect-logo"
      sx={{
        backgroundColor: `${getLightColor(previewColor, 0.2)} !important`,
      }}
    >
      {image}
    </Box>
  );
};

const ShowDynamicLogo = ({ image, previewColor, icons, defaultImage }) => {
  return !icons ? (
    <ShowDefaultLogo previewColor={previewColor} image={defaultImage} />
  ) : (
    <CustomAvatar
      size="small"
      src={image}
      className="account-icon-style connect-icon-style"
    />
  );
};

const ConnectHeader = ({
  connectBrandingDetails,
  previewColor,
  textColor,
  textFontFamily,
  currentAccount,
  liveMode,
  deviceType,
}) => {
  const [selectedAccountTextColor, setSelectedAccountTextColor] =
    useState("#fff");
  const [selectedAccount, setSelectedAccount] = useState(null);

  const [defaultImage, setDefaultImage] = useState(
    getDefaultImage(textColor, currentAccount)
  );
  const [accountImage, setAccountImage] = useState(defaultImage);

  const [selectedAccountDefaultImage, setSelectedAccountDefaultImage] =
    useState(getDefaultImage(selectedAccountTextColor, selectedAccount));
  const [selectedConnectedAccountImage, setSelectedConnectedAccountImage] =
    useState(selectedAccountDefaultImage);
  const [selectedAccountPreviewColor, setSelectedAccountPreviewColor] =
    useState("#2250A1");

  useEffect(() => {
    const defaultImageUpdate = getDefaultImage(textColor, currentAccount);
    setDefaultImage(defaultImageUpdate);
  }, [textColor, currentAccount]);

  useEffect(() => {
    const defaultImageUpdate = getDefaultImage(
      selectedAccountTextColor,
      selectedAccount
    );
    setSelectedAccountDefaultImage(defaultImageUpdate);
  }, [selectedAccountTextColor]);

  useEffect(() => {
    if (connectBrandingDetails) {
      const { icons } = connectBrandingDetails;
      if (icons) {
        setAccountImage(icons.original || defaultImage);
      } else {
        setAccountImage(defaultImage);
      }
    }
  }, [connectBrandingDetails]);

  useEffect(() => {
    const accountDetails = sessionStorage.getItem("account-details");
    setSelectedAccount(accountDetails ? JSON.parse(accountDetails) : null);
  }, [sessionStorage.getItem("account-details")]);

  useEffect(() => {
    if (selectedAccount?.account_public_info?.branding) {
      const { icons, primary_color } =
        selectedAccount.account_public_info.branding;
      if (icons) {
        setSelectedConnectedAccountImage(
          icons.original || selectedAccountDefaultImage
        );
      } else setSelectedConnectedAccountImage(selectedAccountDefaultImage);
      setSelectedAccountPreviewColor(primary_color || "#2250A1");
      const selectedColor = validateColor(primary_color)
        ? primary_color
        : "#2250A1";

      isLightColor(selectedColor)
        ? setSelectedAccountTextColor("#0a193e")
        : setSelectedAccountTextColor("#fff");
    }
  }, [selectedAccount]);

  const displayBusinessName = (name) => {
    return (
      <TruncatedTextTooltip
        textValue={name}
        textProps={{
          color: textColor,
          fontFamily: `${textFontFamily} !important`,
          className: "connect-business-name account-name-text",
        }}
        boxProps={{
          className: `business-name-box ${
            selectedAccount && "selected-account"
          }`,
        }}
      />
    );
  };

  const updatedAccountName =
    connectBrandingDetails?.business_name + " " + staticText;

  const commonTextProps = {
    size: 28,
    font: "semibold",
    variant: "subtitle1",
    sx: {
      color: textColor,
      fontFamily: `${textFontFamily} !important`,
      fontWeight: selectedAccount ? 400 : 700,
      fontSize: selectedAccount && "24px !important",
      wordBreak: "break-word",
      lineHeight: selectedAccount ? "140% !important" : "124% !important",
    },
    className: "connect-account-subtext",
  };

  return (
    <>
      <Box display="flex" alignItems="center">
        {connectBrandingDetails?.icons ? (
          <ShowDynamicLogo
            image={accountImage}
            previewColor={previewColor}
            icons={connectBrandingDetails?.icons}
            defaultImage={defaultImage}
          />
        ) : (
          <ShowDefaultLogo previewColor={previewColor} image={defaultImage} />
        )}
        {displayBusinessName(connectBrandingDetails?.business_name)}
        {selectedAccount && (
          <>
            <SyncAltIcon sx={{ color: textColor, margin: "0 14px" }} />
            {selectedAccount?.account_public_info?.branding ? (
              <ShowDynamicLogo
                image={selectedConnectedAccountImage}
                previewColor={selectedAccountPreviewColor}
                icons={selectedAccount?.account_public_info?.branding?.icons}
                defaultImage={selectedAccountDefaultImage}
              />
            ) : (
              <ShowDefaultLogo
                previewColor={selectedAccountPreviewColor}
                image={selectedAccountDefaultImage}
              />
            )}
            {displayBusinessName(selectedAccount?.name)}
          </>
        )}
        {!liveMode && deviceType === "web" && (
          <Box className="content-center test-mode-badge">
            <Text
              size={14}
              className="default-text"
              font="regular"
              variant="subtitle"
              sx={{ fontFamily: textFontFamily }}
            >
              <Translation value="testMode" />
            </Text>
          </Box>
        )}
      </Box>

      {selectedAccount ? (
        <Text {...commonTextProps}>{selectedAccountStaticText}</Text>
      ) : (
        truncateStringWithTooltip({
          maxLength: 90,
          str: updatedAccountName,
          textProps: commonTextProps,
        })
      )}
    </>
  );
};

export default ConnectHeader;
