import React from "react";
import { Box } from "@mui/material";
import CustomDivider from "./Divider/Divider";
import Button from "./Button/Button";
import Slide from "@mui/material/Slide";

const CommonSettingsFooter = ({ firstBtnProps, secondBtnProps }) => {
  const { label, disabled, loader, color, handleFirstBtnClick } = firstBtnProps;
  const {
    label: secondBtnLabel,
    disabled: secondBtnDisabled,
    handleSecondBtnClick,
  } = secondBtnProps;

  return (
    <Slide direction="up" in={true}>
      <Box className="footer-wrapper">
        <CustomDivider />
        <Box className="form-footer">
          <Button
            variant="contained"
            loading={loader}
            disabled={disabled}
            label={label}
            color={color}
            onClick={handleFirstBtnClick}
          />
          <Button
            disabled={secondBtnDisabled}
            variant="outlined"
            style={{ margin: "0 0 0 28px" }}
            label={secondBtnLabel}
            onClick={handleSecondBtnClick}
          />
        </Box>
      </Box>
    </Slide>
  );
};

export default CommonSettingsFooter;
