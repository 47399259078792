import { Modal } from "@speed/common/src/components/Modal/Modal";
import React from "react";
import { Box } from "@mui/material";
import {
  bitcoin,
  choosePaymentMode,
  lightning,
  lightningCaps,
  selectPaymentMode,
} from "../../messages";
import Text from "@speed/common/src/components/Text/Text";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import { bitCoinSymbol } from "../../images";
import { fill } from "@speed/common/src/components/images";

const PaymentModeModal = ({ handleModalClose, openModal, setSelectedMode }) => {
  const commonPaymentMode = (name, imgSrc, text) => (
    <Box
      className="select-payment-mode"
      onClick={(e) => {
        setSelectedMode(name.toLowerCase());
      }}
    >
      <Box display="flex" alignItems="center">
        <img src={imgSrc} style={{ height: "24px", width: "24px" }} alt="" />
        <Text sx={{ ml: "8px" }} size={16} font="bold">
          {text}
        </Text>
      </Box>
      <KeyboardArrowDownOutlinedIcon
        sx={{
          marginLeft: "14px",
          color: "#848B9E",
          transform: "rotate(270deg)",
        }}
      />
    </Box>
  );

  const renderModalContent = () => (
    <Box key={`payment_mode_modal`}>
      <Text size={16} className="grey-text" font="regular" variant="subtitle1">
        {choosePaymentMode}
      </Text>
      {commonPaymentMode(bitcoin, bitCoinSymbol, bitcoin)}
      {commonPaymentMode(lightning, fill, lightningCaps)}
    </Box>
  );

  return (
    <Modal
      maxWidth="xs"
      open={openModal}
      body={renderModalContent()}
      handleClose={handleModalClose}
      title={selectPaymentMode}
      closeIcon
    />
  );
};

export default PaymentModeModal;
