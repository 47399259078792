import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import CustomButton from "@speed/common/src/components/Button/Button";
import React, { useState } from "react";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Text from "@speed/common/src/components/Text/Text";
import classNames from "classnames";
import { addNewPrice, noDataAvailable, viewAll } from "../messages";
import TableSkeleton from "./Table/TableSkeleton";

const HorizontalTable = ({
  label,
  columns,
  rows,
  rowsPerPage,
  className,
  isShowPagination,
  tableBodyClassName,
  isColumnShown,
  isShowButton,
  displayShowButton,
  handleViewAllClick,
  viewAllButtonLabel,
  viewAllButtonIcon,
  tableRowSkeleton,
  isAddRow,
  handleAction,
  isDisable,
}) => {
  const [page, setPage] = useState(0);

  const handleChangePage = (_event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box className={classNames("horizontal-table-wrapper", className)}>
      {label && (
        <>
          <Box className="align-left-right-content">
            <Text size={20} className="default-text">
              {label}
            </Text>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {isAddRow && (
                <Text
                  size={14}
                  font="semibold"
                  variant="h6"
                  sx={{
                    color: !isDisable ? "#2A67FF" : "#c4cada",
                    cursor: !isDisable ? "pointer" : "",
                    marginRight: isShowButton ? "25px" : "",
                  }}
                  onClick={!isDisable ? handleAction : ""}
                >
                  {addNewPrice}
                </Text>
              )}

              {(displayShowButton || isColumnShown) && isShowButton && (
                <CustomButton
                  style={{ color: "#2A67FF", fontSize: "14px" }}
                  label={viewAllButtonLabel || viewAll}
                  variant="default"
                  icon={viewAllButtonIcon || "visibilityIcon"}
                  onClick={handleViewAllClick}
                />
              )}
            </Box>
          </Box>
          <CustomDivider sx={{ marginTop: "12px" }} />
        </>
      )}
      <TableContainer>
        {isColumnShown ? (
          <Table>
            <TableHead>
              <TableRow>
                {columns &&
                  columns.length > 0 &&
                  columns.map((column, index) => (
                    <TableCell
                      align={column.align}
                      key={index}
                      sx={{ width: column.width }}
                    >
                      {column.title}
                    </TableCell>
                  ))}
              </TableRow>
            </TableHead>
            <TableBody className={classNames(tableBodyClassName)}>
              {rows.length == 0 || tableRowSkeleton ? (
                <TableSkeleton columns={columns} />
              ) : (
                rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              )}
            </TableBody>
          </Table>
        ) : (
          <Text
            size={16}
            font="regular"
            variant="subtitle1"
            className="no-data-available-text"
          >
            {noDataAvailable}
          </Text>
        )}
      </TableContainer>
      {isShowPagination && (
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          labelDisplayedRows={() => {
            return;
          }}
          backIconButtonProps={{ title: "" }}
          nextIconButtonProps={{ title: "" }}
        />
      )}
    </Box>
  );
};

export default HorizontalTable;
