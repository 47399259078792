import React from "react";
import GetGuides from "./GetGuides";

const CustomersGuide = () => {
  return (
    <GetGuides
      moduleName="customers"
      redirectUrl="/customers"
      guideApiPath="customers-guide"
      getStartedApiPath="Customers"
      needHelpGuidePath="docs/receive-payments/customers"
    />
  );
};

export default CustomersGuide;
