import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import InfoIcon from "@mui/icons-material/Info";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Text from "@speed/common/src/components/Text/Text";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { Input } from "@speed/common/src/components/Input/Input";
import { CustomSwitch } from "@speed/common/src/components/Switch/Switch";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import {
  branding,
  businessInfo,
  businessInfoMSg,
  daysAfterInvoiceSent,
  daysAllow,
  defaultFooter,
  defaultFooterSub,
  defaultMemo,
  defaultMemoSub,
  defaultPaymentTerms,
  defaultPaymentTermsMessage,
  defaultReminderMessage,
  defaultReminderTitle,
  emptyDays,
  invoiceBrandingMsg,
  invoicePDFMsg,
  invoicePDFSubMsg,
  invoicePDFs,
  invoicePrefix,
  invoicePrefixMessage,
  invoicePrefixValidation,
  previewSubMsg,
  reminderUnique,
} from "../messages";
import DefaultReminder from "./DefaultReminder";
import {
  setSettingFormCancel,
  setSettingFormChange,
  setSettingFormSubmit,
  setSettingFormValid,
} from "../../redux/common/actions";
import { callAPIInterface, symbolsToExcludeForNumberInput } from "../constants";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import { uniqueMethod } from "@speed/common/src/components/constants";

const Invoice = () => {
  // const [selectedRadio, setSelectedRadio] = useState("customer");
  const { history, settingFormSubmit, settingFormCancel } = useSelector(
    (state) => state.common
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [info, setInfo] = useState("");
  const [data, setData] = useState(null);
  const [
    reminderDataForDeletionProcedure,
    setReminderDataForDeletionProcedure,
  ] = useState([]);
  const [loading, setLoading] = useState(true);

  const loadDetailPage = () => {
    setData(null);
    getInvoiceSettingsDetail();
  };

  const getInvoiceSettingsDetail = () => {
    setLoading(true);
    callAPIInterface("GET", "/account-invoice-settings")
      .then((res) => res && setData(res))
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => loadDetailPage(), []);

  uniqueMethod();

  const validationSchema = yup.object({
    invoice_prefix: yup.string().required(invoicePrefixValidation),
    payment_terms: yup
      .number()
      .max(365, daysAllow(365, "more"))
      .min(0, daysAllow(0, "less"))
      .required(emptyDays),
    reminders: yup.array().of(
      yup
        .object()
        .shape({
          interval_value: yup.number().when("due_type", {
            is: (due_type) => due_type === "on",
            then: yup.number().required(emptyDays),
            otherwise: yup
              .number()
              .max(30, daysAllow(30, "more"))
              .min(1, daysAllow(1, "less"))
              .required(emptyDays),
          }),
        })
        .uniqueProperty("key", reminderUnique)
    ),
  });

  const initialValues = {
    invoice_prefix: data?.invoice_prefix || "",
    // invoice_live_sequence: "",
    // invoice_test_sequence: "",
    is_pdf_attached: data?.is_pdf_attached,
    // invoice_numbering: "",
    memo: data?.memo || "",
    footer: data?.footer || "",
    payment_terms: data?.payment_terms,
    reminders:
      data?.reminders?.map((reminder) => {
        return {
          ...reminder,
          key: `${reminder.interval_value}_${reminder.due_type}`,
        };
      }) || [],
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const dispatch = useDispatch();

  // const handleRadioChange = (value) => {
  //   setSelectedRadio(value);
  // };

  const {
    values,
    setFieldValue,
    setTouched,
    handleChange,
    isValid,
    dirty,
    isSubmitting,
    errors,
    touched,
    resetForm,
  } = formik;

  const onChangeFormField = (fieldname, value) => {
    setFieldValue(fieldname, value);
    handleChange(value);
  };

  useEffect(() => {
    dispatch(setSettingFormChange(dirty));
  }, [values]);

  useEffect(() => {
    dispatch(setSettingFormValid(!(isValid && dirty) || isSubmitting));
  }, [isValid, dirty, isSubmitting]);

  useEffect(() => {
    if (settingFormCancel) {
      resetForm(initialValues);
      dispatch(setSettingFormCancel(false));
    }
  }, [settingFormCancel]);

  const updateInvoiceSettings = (invoiceData) => {
    callAPIInterface(
      "POST",
      "/account-invoice-settings",
      JSON.stringify(invoiceData)
    )
      .then((response) => {
        response && setData(response);
        setReminderDataForDeletionProcedure([]);
        dispatch(setSettingFormSubmit(false));
        dispatch(setSettingFormChange(false));
      })
      .catch(() => {
        setReminderDataForDeletionProcedure([]);
        dispatch(setSettingFormSubmit(false));
        dispatch(setSettingFormChange(false));
      });
  };

  useEffect(() => {
    if (settingFormSubmit) {
      let remindersData = values?.reminders?.concat(
        reminderDataForDeletionProcedure
      );

      const invoiceData = {
        invoice_prefix: values?.invoice_prefix,
        memo: values?.memo,
        footer: values?.footer,
        payment_terms: values?.payment_terms,
        is_pdf_attached: values?.is_pdf_attached,
        reminders: remindersData,
      };

      const getChangedValues = (values, initialValues) => {
        return Object.entries(values).reduce((acc, [key, value]) => {
          if (Array.isArray(value)) {
            const differenceInArray = value.filter(
              (object1) =>
                !initialValues[key].some(
                  (object2) =>
                    object1.key === object2.key &&
                    object1.channel === object2.channel
                )
            );

            const truncatedArray = differenceInArray.map((differenceObject) => {
              if (differenceObject.id) {
                const initialObject = initialValues[key].find(
                  (initialObj) => differenceObject.id === initialObj.id
                );
                return {
                  id: differenceObject.id,
                  ...getChangedValues(differenceObject, initialObject),
                };
              } else {
                return differenceObject;
              }
            });
            if (truncatedArray.length > 0) {
              acc[key] = truncatedArray;
            }
          } else {
            if (initialValues[key] !== value) {
              acc[key] = value;
            }
          }
          return acc;
        }, {});
      };

      const truncatedInvoiceData = getChangedValues(invoiceData, initialValues);
      truncatedInvoiceData?.reminders?.forEach((x) => delete x.key);
      updateInvoiceSettings(truncatedInvoiceData);
    }
  }, [settingFormSubmit]);

  const handleClick = (event, information) => {
    setInfo(information);
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const defaultErrorMessageProps = {
    touched,
    errors,
    sx: { width: "fit-content" },
  };

  if (loading) {
    return (
      <Box className="section-wrapper two-factor-container two-factor-loader">
        <CommonSkeleton
          width={250}
          height={28}
          color="#c4ccd2"
          sx={{ borderRadius: "8px", marginBottom: "18px" }}
        />
        <CommonSkeleton width={470} height={20} sx={{ borderRadius: "8px" }} />
      </Box>
    );
  }

  return (
    <>
      <CustomDivider />
      <Box className="box-container">
        <Text font="bold" size={18} className="default-text">
          {branding}
        </Text>
        <Text
          size={14}
          font="regular"
          className="grey-text"
          variant="subtitle1"
          sx={{ marginTop: "10px" }}
        >
          {invoiceBrandingMsg}
          <CustomLink
            size="small"
            bold
            onClick={() => {
              history.push("/settings/invoice-payment-page");
            }}
          >
            {previewSubMsg[1]}
          </CustomLink>
        </Text>
        <Text font="bold" size={18} className="default-text mt-40">
          {businessInfo}
        </Text>
        <Text
          size={14}
          font="regular"
          className="grey-text"
          variant="subtitle1"
          sx={{ marginTop: "10px" }}
        >
          {businessInfoMSg[0]}
          <CustomLink
            size="small"
            bold
            onClick={() => {
              history.push("/settings/public-info");
            }}
          >
            {businessInfoMSg[1]}
          </CustomLink>
        </Text>
        <Box display="flex" alignItems="center">
          <Text font="bold" size={18} className="default-text mt-40">
            {invoicePrefix}
          </Text>
          <InfoIcon
            className="label-with-icon mt-40"
            sx={{
              height: "18px !important",
              width: "18px !important",
            }}
            onClick={(e) => handleClick(e, invoicePrefixMessage)}
          />
        </Box>
        {/* <Box display="flex" flexDirection="column"> */}
        {/* <RadioButton
            checked={selectedRadio === customerRadioBtnValue}
            label={invoiceRadioButtonMsg}
            onChange={() => {
              onChangeFormField("invoice_numbering", customerRadioBtnValue);
              handleRadioChange(customerRadioBtnValue);
            }}
            value={customerRadioBtnValue}
            className="customer-radio-label"
          /> */}
        {/* <RadioButton
            checked={selectedRadio === accountRadioBtnValue}
            label={invoiceRadioButtonMsg2}
            onChange={() => {
              handleRadioChange(accountRadioBtnValue);
            }}
            value={accountRadioBtnValue}
            className="customer-radio-label"
          /> */}
        {/* </Box> */}
        <Input
          customClass="margin-top15"
          showLabel={false}
          value={values.invoice_prefix}
          name="invoice_prefix"
          type="text"
          inputProps={{ maxLength: 12 }}
          onBlur={() => setTouched({ ...touched, invoice_prefix: true })}
          onChange={(e) => {
            onChangeFormField("invoice_prefix", e.target.value);
          }}
          isIcon={true}
          iconContent={
            <Text
              className="default-text"
              variant="subtitle1"
              font="regular"
              size={14}
              sx={{ width: "244px" }}
            >
              {"Invoice prefix"}
            </Text>
          }
          icon={
            <InfoIcon
              style={{
                color: "#848b9e",
                marginLeft: "7px",
              }}
            />
          }
          error={Boolean(errors?.invoice_prefix)}
        />
        <InputErrorMessage
          {...defaultErrorMessageProps}
          inputName="invoice_prefix"
        />
        {/* <Input
              customClass="margin-top15 ml-30"
              showLabel
              value={values.invoice_live_sequence}
              name="invoice_live_sequence"
              label={invoiceLiveSequence}
              type="number"
              onBlur={() =>
                setTouched({ ...touched, invoice_live_sequence: true })
              }
              onChange={(e) => {
                onChangeFormField("invoice_live_sequence", e.target.value);
              }}
              isIcon={true}
              iconContent={
                <Text
                  className="default-text"
                  variant="subtitle1"
                  font="regular"
                  size={14}
                  sx={{ width: "244px" }}
                >
                  {"Invoice live sequence"}
                </Text>
              }
              icon={
                <InfoIcon
                  style={{
                    color: "#848b9e",
                    marginLeft: "7px",
                  }}
                />
              }
            />
            <Input
              customClass="margin-top15 ml-30"
              showLabel
              value={values.invoice_test_sequence}
              name="invoice_test_sequence"
              label={invoiceTestSequence}
              type="number"
              onBlur={() =>
                setTouched({ ...touched, invoice_test_sequence: true })
              }
              onChange={(e) => {
                onChangeFormField("invoice_test_sequence", e.target.value);
              }}
              isIcon={true}
              iconContent={
                <Text
                  className="default-text"
                  variant="subtitle1"
                  font="regular"
                  size={14}
                  sx={{ width: "244px" }}
                >
                  {"Invoice test sequence"}
                </Text>
              }
              icon={
                <InfoIcon
                  style={{
                    color: "#848b9e",
                    marginLeft: "7px",
                  }}
                />
              }
            /> */}
        <Text font="bold" size={18} className="default-text mt-40">
          {invoicePDFs}
        </Text>
        <Box className="invoice-pdf margin-top15" display="flex">
          <CustomSwitch
            checked={values.is_pdf_attached}
            onChange={(_e, checked) =>
              setFieldValue("is_pdf_attached", checked)
            }
          />
          <Text font="regular" size={16} className="default-text ml-15">
            {invoicePDFMsg}
          </Text>
        </Box>
        <Text
          font="regular"
          size={14}
          variant="subtitle"
          className="grey-text ml-60"
        >
          {invoicePDFSubMsg}
        </Text>
        <Text
          font="regular"
          size={18}
          className="default-text margin-top30"
          sx={{ marginBottom: "15px" }}
        >
          {defaultMemo}
        </Text>
        <TextAreaComponent
          placeholder=""
          showLabel={false}
          value={values.memo}
          maxRows={3}
          minRows={3}
          maxLength={500}
          style={{ width: "55%" }}
          error={Boolean(errors.memo)}
          onBlur={(_e) => setTouched({ ...touched, memo: true })}
          onChange={(e) => {
            onChangeFormField("memo", e.target.value);
          }}
        />

        <Text font="regular" variant="h6" size={14} className="grey-text">
          {defaultMemoSub}
        </Text>
        <Text
          font="regular"
          size={18}
          className="default-text margin-top30"
          sx={{ marginBottom: "15px" }}
        >
          {defaultFooter}
        </Text>
        <TextAreaComponent
          placeholder=""
          showLabel={false}
          value={values.footer}
          maxRows={3}
          minRows={3}
          maxLength={500}
          style={{ width: "55%" }}
          error={Boolean(errors.footer)}
          onBlur={(_e) => setTouched({ ...touched, footer: true })}
          onChange={(e) => {
            onChangeFormField("footer", e.target.value);
          }}
        />

        <Text font="regular" variant="h6" size={14} className="grey-text">
          {defaultFooterSub}
        </Text>
        <Box display="flex" alignItems="center">
          <Text font="regular" size={18} className="default-text margin-top30">
            {defaultPaymentTerms}
          </Text>
          <InfoIcon
            className="label-with-icon margin-top30"
            sx={{
              height: "18px !important",
              width: "18px !important",
            }}
            onClick={(e) => handleClick(e, defaultPaymentTermsMessage)}
          />
        </Box>
        <Box
          className="terms"
          display="flex"
          alignItems="center"
          marginTop="15px"
        >
          <Input
            customClass="payment-term-drop-down"
            showLabel={false}
            value={values.payment_terms}
            name="payment_terms"
            type="number"
            onWheel={(e) => e.target.blur()}
            onKeyDown={(e) => {
              if (
                symbolsToExcludeForNumberInput.includes(e.key) ||
                e.which === 38 ||
                e.which === 40
              ) {
                e.preventDefault();
              }
            }}
            onBlur={() => setTouched({ ...touched, payment_terms: true })}
            onChange={(e) => {
              onChangeFormField("payment_terms", e.target.value);
            }}
            error={Boolean(errors.payment_terms)}
          />
          <Text
            font="regular"
            size={14}
            variant="subtitle"
            className="grey-text ml-15"
          >
            {daysAfterInvoiceSent}
          </Text>
        </Box>
        <InputErrorMessage
          {...defaultErrorMessageProps}
          inputName="payment_terms"
        />
        <Box display="flex" alignItems="center">
          <Text font="regular" size={18} className="default-text margin-top30">
            {defaultReminderTitle}
          </Text>
          <InfoIcon
            className="label-with-icon margin-top30"
            sx={{
              height: "18px !important",
              width: "18px !important",
            }}
            onClick={(e) => handleClick(e, defaultReminderMessage)}
          />
        </Box>
        <Box marginTop="15px">
          <DefaultReminder
            values={values}
            formik={formik}
            handleChange={handleChange}
            dirty={dirty}
            errors={errors}
            setReminderDataForDeletionProcedure={
              setReminderDataForDeletionProcedure
            }
            setFieldValue={setFieldValue}
          />
        </Box>
        <CustomPopper
          disablePortal={true}
          open={open}
          anchorEl={anchorEl}
          position="bottom"
          handleClose={handleClose}
        >
          <Text
            className="grey-text"
            size={14}
            variant="subtitle1"
            font="normal"
          >
            {info}
          </Text>
        </CustomPopper>
      </Box>
    </>
  );
};

export default Invoice;
