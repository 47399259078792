import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import React, { useState } from "react";
import {
  apiKeyNotExist,
  confirmPasswordLabel,
  deleteApiKeyText,
  deleteApiKeyTitle,
  deleteKey,
  editKey,
  enterPassword,
  error,
  okay,
} from "../messages";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import Button from "@speed/common/src/components/Button/Button";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import Text from "@speed/common/src/components/Text/Text";
import { Input } from "@speed/common/src/components/Input/Input";
import {
  setOpenRestrictedKeyCreateModal,
  showToast,
} from "../../redux/common/actions";
import { useDispatch, useSelector } from "react-redux";
import { callAPIInterface } from "../constants";
import history from "@speed/common/src/components/history";
import { verifyEmailOTP, verifyUserPassword } from "../../redux/auth/actions";
import * as yup from "yup";
import { useFormik } from "formik";
import ErrorIcon from "@mui/icons-material/Error";
import CommonVerifyOTPSection from "../Common/CommonVerifyOTPSection";

const RestrictedKeysCustomPopper = ({
  setAnchor,
  anchor,
  openPopper,
  setIsUpdating,
  id,
  setRowLoader,
  setRowId,
  setRows,
  rows,
  fromDetailPage,
}) => {
  const [deleteKeyModal, setDeleteKeyModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [linkNotFound, setLinkNotFound] = useState(false);
  const [disableOTPFooterBtn, setDisableOTPFooterBtn] = useState(true);
  const [restrictedKeyOTP, setRestrictedKeyOTP] = useState(true);

  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const isForSocialMediaLogin = user?.signup_method !== "email";
  const validationSchema = yup.object({
    confirm_password: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      confirm_password: "",
    },
    validationSchema: validationSchema,
  });

  const {
    values,
    setFieldValue,
    dirty,
    isValid,
    resetForm,
    setTouched,
    touched,
    errors,
  } = formik;

  const handleModalClose = () => {
    resetForm();
    setDeleteKeyModal(false);
    setRestrictedKeyOTP("");
    setDisableOTPFooterBtn(true);
    setIsLoading(false);
  };

  const handleDeleteKey = () => {
    const verificationAPIMethod = isForSocialMediaLogin
      ? verifyEmailOTP
      : verifyUserPassword;
    setIsLoading(true);
    const postObject = isForSocialMediaLogin
      ? {
          otp: restrictedKeyOTP,
        }
      : {
          password: values.confirm_password,
        };

    dispatch(verificationAPIMethod(postObject)).then((res) => {
      if (res) {
        if (!fromDetailPage) {
          setRowId(id);
          setRowLoader(true);
        }
        callAPIInterface("DELETE", `/api-key/restricted-key/${id}`)
          .then(() => {
            setAnchor(null);
            if (fromDetailPage) {
              history.push("/apikeys/restricted-keys");
            } else {
              const result = rows.filter((row) => row.id !== id);
              setRows(result);
              setRowLoader(false);
            }
          })
          .catch((error) => {
            const errors = error?.response?.data?.errors;
            const errorMsg = errors?.[0]?.message.toLowerCase();
            const errorType = errors?.[0]?.type.toLowerCase();
            if (!fromDetailPage) {
              setRowLoader(false);
            }
            if (errorType === "invalid_request_error") {
              if (errorMsg.includes("invalid key")) {
                setLinkNotFound(true);
                dispatch(
                  showToast({
                    isToastOpen: false,
                    toastVariant: "error",
                  })
                );
              } else {
                dispatch(
                  showToast({
                    isToastOpen: true,
                    toastMessage: errorMsg,
                    toastVariant: "error",
                    toastAutoHideDuration: 7000,
                  })
                );
              }
            }
          })
          .finally(() => {
            setIsLoading(false);
            handleModalClose();
          });
      } else {
        setIsLoading(false);
        resetForm();
      }
    });
  };

  const confirmPassword = (
    <Grid component="form" autoComplete="off" p="24px 30px 30px 30px">
      <Input
        fullWidth={true}
        label={confirmPasswordLabel}
        type="password"
        name="confirm_password"
        showLabel
        inputProps={{ maxLength: 255 }}
        value={values.confirm_password}
        placeholder={enterPassword}
        onKeyPress={(event) => {
          if (event.key === "Enter") {
            event.preventDefault();
          }
        }}
        onBlur={() => setTouched({ ...touched, confirm_password: true })}
        onChange={(e) => setFieldValue("confirm_password", e.target.value)}
        error={touched.email && Boolean(errors.confirm_password)}
      />
    </Grid>
  );

  const deleteKeyModalContent = (
    <>
      <Box m="15px 30px">
        <AlertMessage
          className="margin-top14 alert-delete-icon"
          severity="warning"
          message={
            <Text size={14} variant="caption" font="regular">
              {deleteApiKeyText}
            </Text>
          }
        />
      </Box>
      {isForSocialMediaLogin ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          p="0px 30px 30px 30px"
        >
          <CommonVerifyOTPSection
            footerAuthenticationButton
            setPropOtp={setRestrictedKeyOTP}
            setDisableOTPFooterBtn={setDisableOTPFooterBtn}
          />
        </Grid>
      ) : (
        confirmPassword
      )}
    </>
  );

  const deleteKeyModalFooter = (
    <Button
      label={deleteKey}
      loading={isLoading}
      variant="contained"
      color="error"
      onClick={handleDeleteKey}
      disabled={
        isForSocialMediaLogin ? disableOTPFooterBtn : !(isValid && dirty)
      }
      className="deactivate-btn"
    />
  );

  const handleEditKey = () => {
    dispatch(setOpenRestrictedKeyCreateModal(true));
    setIsUpdating(true);
  };

  const linkNotFoundContent = () => {
    return (
      <Grid alignItems="center" display="flex" flexDirection="column">
        <ErrorIcon
          style={{ width: "60px", height: "60px", color: "#E74C3C" }}
        />
        <Text
          variant="subtitle1"
          font="regular"
          size={18}
          className="default-text margin-top14"
        >
          {apiKeyNotExist}
        </Text>
      </Grid>
    );
  };

  const linkNotFoundFooter = (
    <Button
      label={okay}
      variant="outlined"
      onClick={() => {
        setLinkNotFound(false);
      }}
    />
  );

  return (
    <CustomPopper
      disablePortal={true}
      open={openPopper}
      anchorEl={anchor}
      position="bottom"
      handleClose={() => {
        setAnchor(null);
      }}
    >
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={() => handleEditKey()}>
            <ListItemText primary={editKey} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton onClick={() => setDeleteKeyModal(true)}>
            <ListItemText primary={deleteKey} className="text-danger" />
          </ListItemButton>
        </ListItem>
      </List>
      <Modal
        maxWidth="xs"
        body={linkNotFoundContent()}
        handleClose={() => {
          setLinkNotFound(false);
        }}
        footer={linkNotFoundFooter}
        open={linkNotFound}
        title={error}
      />
      <Modal
        maxWidth="xs"
        className="restricted-key-roll-api"
        body={deleteKeyModalContent}
        footer={deleteKeyModalFooter}
        handleClose={handleModalClose}
        open={deleteKeyModal}
        title={deleteApiKeyTitle}
      />
    </CustomPopper>
  );
};

export default RestrictedKeysCustomPopper;
