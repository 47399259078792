import Button from "@speed/common/src/components/Button/Button";
import Text from "@speed/common/src/components/Text/Text";
import Grid from "@mui/material/Grid";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import LockIcon from "@mui/icons-material/Lock";
import { googleAuthenticator, remove, smsAuthentication } from "../../messages";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { concatedMobileNumber } from "../../constants";

const Enabled2FABox = ({
  type,
  mfaDetails,
  handleRemoveMfa,
  mfaRemovemfa,
  loading,
}) => {
  let typeIcon, typeTitle;
  const iconStyle = { height: 42, width: 42, color: "#848b9e" };
  switch (type) {
    case "sms":
      typeIcon = <PhoneAndroidIcon sx={iconStyle} />;
      typeTitle = smsAuthentication;
      break;
    case "authenticator":
      typeIcon = <LockIcon sx={iconStyle} />;
      typeTitle = googleAuthenticator;
      break;
  }

  return (
    <>
      <Grid
        className="activated-2fa-container"
        container
        item
        xs={12}
        sm={8}
        md={6}
        lg={6}
      >
        <Grid display="flex" item lg={1.1}>
          {typeIcon}
        </Grid>
        <Grid item flexDirection="column" alignItems="center" lg={8.6}>
          <Text font="semibold" size={16} variant="h5" className="default-text">
            {typeTitle}
          </Text>
          {type === "sms" && (
            <Text
              font="regular"
              size={16}
              variant="subtitle1"
              className="grey-text"
            >
              {formatPhoneNumberIntl(
                concatedMobileNumber(
                  mfaDetails.mfa_calling_code,
                  mfaDetails.mfa_phone_number
                )
              )}
            </Text>
          )}
        </Grid>
        {mfaRemovemfa && (
          <Grid item lg={1}>
            <Button
              loading={loading}
              color="error"
              label={remove}
              onClick={() => handleRemoveMfa(type)}
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default Enabled2FABox;
