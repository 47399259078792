import React from "react";
import { Box, MenuItem } from "@mui/material";
import { Input } from "@speed/common/src/components/Input/Input";
import CustomSelect from "@speed/common/src/components/Select/Select";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import {
  account,
  connectAccEvent,
  currentVersionText,
  description,
  endpointURLPlaceholder,
  latestVersionText,
  listenTo,
  listenToWHType,
  versionLabel,
  webhookDescriptionPlaceholder,
  webhookEndpointURLabel,
  yourAccEvent,
} from "../messages";
import { RadioButton } from "@speed/common/src/components/RadioButton/RadioButton";
import Label from "@speed/common/src/components/Label/Label";

const CommonWebhookForm = (props) => {
  const {
    values,
    setFieldValue,
    touched,
    setTouched,
    errors,
    defaultErrorMessageProps,
    versions,
    createWebhookPage,
  } = props;
  const versionList = [];

  for (const key in versions) {
    if (key === account) {
      versionList.push({
        name: `${currentVersionText} (${versions[key]})`,
        value: versions[key],
      });
    } else {
      versionList.push({
        name: `${latestVersionText} (${versions[key]})`,
        value: versions[key],
      });
    }
  }

  const displayRadioButton = (label, type, sx = {}) => {
    return (
      <RadioButton
        checked={values.listenTo === type}
        label={label}
        value={type}
        onChange={(e) => {
          setFieldValue("listenTo", e.target.value);
        }}
        sx={sx}
      />
    );
  };

  return (
    <>
      <Input
        type="text"
        name="url"
        inputProps={{ maxLength: 2048 }}
        value={values.url}
        onChange={(e) => {
          setTouched({ ...touched, url: false });
          setFieldValue("url", e.target.value);
        }}
        customClass="margin-top30"
        showLabel
        label={webhookEndpointURLabel}
        fullWidth
        placeholder={endpointURLPlaceholder}
        onBlur={() => setTouched({ ...touched, url: true })}
        error={errors.url && Boolean(errors.url)}
      />
      <InputErrorMessage {...defaultErrorMessageProps} inputName="url" />

      <Box className="margin-top30">
        <TextAreaComponent
          showLabel
          resize={false}
          label={description}
          maxLength={255}
          minRows={3}
          maxRows={3}
          name="description"
          placeholder={webhookDescriptionPlaceholder}
          fullWidth
          value={values.description}
          onChange={(e) => {
            setTouched({ ...touched, description: false });
            setFieldValue("description", e.target.value);
          }}
          onBlur={() => setTouched({ ...touched, description: true })}
        />
      </Box>

      {createWebhookPage === 1 && (
        <>
          <Label className="margin-top30">{listenTo}</Label>
          <Box display="flex" flexDirection="column">
            {displayRadioButton(yourAccEvent, listenToWHType[0])}
            {displayRadioButton(connectAccEvent, listenToWHType[1], {
              mt: "14px",
            })}
          </Box>
        </>
      )}
      {versions?.account !== versions?.latest && createWebhookPage !== 3 && (
        <CustomSelect
          showLabel
          label={versionLabel}
          customClass="margin-top30"
          value={
            Object.keys(values?.version).length > 0
              ? values.version?.value
              : setFieldValue("version", versionList[0])
          }
          MenuProps={{
            id: "payout-wallet-address",
            disableScrollLock: true,
          }}
          onChange={(e) => {
            const currentlySelected = versionList?.find(
              (version) => version?.value === e.target.value
            );
            setFieldValue("version", currentlySelected);
          }}
          renderValue={() => {
            if (values?.version) {
              return values?.version?.name;
            }
          }}
        >
          {versionList?.map((dropDownValue, dropDownIndex) => (
            <MenuItem value={dropDownValue?.value} key={dropDownIndex}>
              <Box>{dropDownValue?.name}</Box>
            </MenuItem>
          ))}
        </CustomSelect>
      )}
    </>
  );
};

export default CommonWebhookForm;
