import React from "react";
import Text from "@speed/common/src/components/Text/Text";
import { satsToBtcAmount } from "@speed/common/src/components/constants";
import Button from "@speed/common/src/components/Button/Button";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import {
  insufficientBalanceMsgPayout,
  insufficientFund,
  tryAgain,
} from "../messages";

const InsufficientBalanceModal = ({
  open,
  totalFees,
  handleTryAgain,
  handleClose,
  type,
}) => {
  const balanceModalBody = (
    <Text size={14} variant="subtitle1" font="regular" className="default-text">
      {insufficientBalanceMsgPayout(satsToBtcAmount(totalFees), type)}
    </Text>
  );

  const balanceModalFooter = (
    <Button label={tryAgain} onClick={handleTryAgain} />
  );

  return (
    <Modal
      maxWidth="xs"
      open={open}
      body={balanceModalBody}
      footer={balanceModalFooter}
      handleClose={handleClose}
      title={insufficientFund}
    />
  );
};

export default InsufficientBalanceModal;
