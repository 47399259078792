import React from "react";
import { Grid } from "@mui/material";
import { useSelector } from "react-redux";
import PaymentLinkWebsiteView from "@speed/common/src/components/PaymentLinkWebsiteView";
import PreviewHeader from "./PreviewHeader";

const CommonWebPreview = (props) => {
  const { marginTop, marginBottom, dummyDomain } = props;
  const { redirectToWebsitePreview } = useSelector((state) => state.common);

  return (
    <Grid
      className="payment-web-preview"
      item
      lg={12}
      sx={{
        backgroundColor: "#fff !important",
        marginTop: `${marginTop} !important`,
        marginBottom: `${marginBottom} !important`,
        marginLeft: "5px",
      }}
    >
      <PreviewHeader from="web" dummyDomain={dummyDomain} />
      {redirectToWebsitePreview ? (
        <PaymentLinkWebsiteView from="web" />
      ) : (
        props.children
      )}
    </Grid>
  );
};

export default CommonWebPreview;
