import { createRef, useCallback, useEffect, useState } from "react";
import { Box, ListItem, List } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import InfiniteScroll from "react-infinite-scroll-component";
import Text from "@speed/common/src/components/Text/Text";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import { loaderSpinner } from "@speed/common/src/components/images";
import Button from "@speed/common/src/components/Button/Button";
import { cancel, needHelp } from "@speed/common/src/components/messages";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { Input } from "@speed/common/src/components/Input/Input";
import {
  defaultDomain,
  connectingMsg,
  paymentDomainPreviewURL,
  dnsRecordModalHeader,
  instructions,
  readInstruction,
  reconnectVerifyDomain,
  paymentDomainInstructionHeader,
  connectViaAPIKey,
  configureDomainTitle,
  cloudFareSubTitle,
  apiTokenLabel,
  addApiToken,
  zoneLabel,
  addZone,
  addDomain,
  emptyKey,
  verifyDomain,
  addNewDomain,
  connectDomain,
  unableToVerifyPaymentDomain,
} from "../../messages";
import {
  callAPIInterface,
  customPaymentDomainStatus,
  noOfRecords,
  paymentDomainServers,
} from "../../constants";
import AddPaymentDomainForm from "./AddPaymentDomainForm";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import { openIntercomChatModal } from "@speed/common/src/components/constants";

const CustomPaymentDomain = () => {
  const queryParam = "?limit=" + noOfRecords;
  const [paymentDomains, setPaymentDomains] = useState([]);
  const [skeletonLoader, setSkeletonLoader] = useState(false);
  const [addDomainBtnLoader, setAddDomainBtnLoader] = useState(false);
  const [showAddDomain, setShowAddDomain] = useState(false);
  const [openInstructionModal, setOpenInstructionModal] = useState(false);
  const [openConfigureDomainModal, setOpenConfigureDomainModal] =
    useState(false);
  const [btnDisable, setBtnDisable] = useState(false);
  const [verifyDomainBtnLoader, setVerifyDomainBtnLoader] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentSelected, setCurrentSelected] = useState();

  const ref = createRef();

  const validationSchema = yup.object({
    token: yup.string().required(emptyKey("Token")),
    zone: yup.string().required(emptyKey("Zone")),
  });

  const initialValues = {
    token: "",
    zone: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (value) => {
      setIsSubmitting(true);
      const domainConfigureValue = {
        ...value,
        domain: currentSelected.domain,
      };

      callAPIInterface(
        "POST",
        "/settings/payment-domains/configure",
        JSON.stringify(domainConfigureValue)
      )
        .then((res) => {
          if (res) {
            setIsSubmitting(false);
            handleCloseConfigureDomain();
            handleCloseInstructionModal();
            setPaymentDomains(paymentDomains.concat(res));
          }
        })
        .catch(() => {
          setIsSubmitting(false);
        });
    },
  });

  const {
    values,
    setFieldValue,
    touched,
    setTouched,
    isValid,
    dirty,
    handleSubmit,
    errors,
    resetForm,
  } = formik;

  const handleCloseInstructionModal = () => {
    setOpenInstructionModal(false);
    setCurrentSelected();
    setShowAddDomain(false);
  };

  const handleCloseConfigureDomain = () => {
    setOpenConfigureDomainModal(false);
    resetForm(initialValues);
  };

  useEffect(() => fetchCustomDomains(), []);

  const fetchCustomDomains = () => {
    setPaymentDomains([]);
    setHasMore(true);
    getCustomDomains([], queryParam);
  };

  const getCustomDomains = (lines, params) => {
    setSkeletonLoader(true);
    callAPIInterface("GET", "/settings/payment-domains" + params)
      .then((res) => {
        setSkeletonLoader(false);
        if (!res.has_more) {
          setHasMore(false);
        } else {
          setQueryParams(
            queryParam + "&ending_before=" + res.data[res.data.length - 1].id
          );
        }
        setPaymentDomains(lines.concat(res.data));
      })
      .catch(() => {
        setSkeletonLoader(false);
      });
  };

  const loadMore = useCallback(() => {
    getCustomDomains(paymentDomains, queryParams);
  }, [paymentDomains]);

  const showTag = (status) => (
    <Tag
      text={customPaymentDomainStatus[status]?.label}
      variant={customPaymentDomainStatus[status]?.variant}
      deleteIcon={customPaymentDomainStatus[status]?.icon}
      onDelete={() => {}}
    />
  );

  const showText = (value) => (
    <Text
      font="semibold"
      size={20}
      className="default-text"
      sx={{ mr: 2 }}
      variant="inline"
    >
      {value}
    </Text>
  );

  const handleConnectCustomDomain = () => {
    setAddDomainBtnLoader(true);
    const values = ref?.current?.handleSubmit();
    setCurrentSelected(values);
    callAPIInterface("POST", "/settings/payment-domains", {
      domain: values.domain,
    })
      .then((res) => {
        setAddDomainBtnLoader(false);
        setPaymentDomains(paymentDomains.concat(res));
        res?.status !== customPaymentDomainStatus.ready.value &&
          setOpenInstructionModal(true);
        setShowAddDomain(false);
      })
      .catch((err) => {
        const errMessage = err?.response?.data?.errors?.[0]?.message;
        setAddDomainBtnLoader(false);
        if (errMessage === unableToVerifyPaymentDomain) {
          setOpenInstructionModal(true);
        }
      });
  };

  const handleVerifyDomain = (id) => {
    setVerifyDomainBtnLoader(true);
    const verifyData = { id };
    callAPIInterface(
      "POST",
      "/settings/payment-domains/verify",
      JSON.stringify(verifyData)
    )
      .then((res) => {
        if (res) {
          setVerifyDomainBtnLoader(false);
          const rowIndexToUpdate = paymentDomains?.findIndex(
            (row) => row?.id === id
          );
          const rs = [...paymentDomains];
          rs?.splice(rowIndexToUpdate, 1, res);
          setPaymentDomains(rs);
          res?.status !== customPaymentDomainStatus.ready.value &&
            setOpenInstructionModal(true);
          setShowAddDomain(false);
        }
      })
      .catch(() => {
        setVerifyDomainBtnLoader(false);
      });
  };

  const handleAddAndConnect = () => {
    if (showAddDomain) {
      handleConnectCustomDomain();
    } else {
      setShowAddDomain(true);
    }
  };

  const instructionsBody = (
    <Box pt={3}>
      <Text className="default-text" font="regular" size={16} variant="body1">
        {paymentDomainInstructionHeader}
      </Text>
      {paymentDomainServers?.map((server, index) => (
        <Box className="margin-top30" key={server?.title}>
          <Text
            className="default-text"
            size={16}
            variant="h4"
            color="#000 !important"
          >
            {server?.title}
          </Text>
          <Text
            className="grey-text"
            font="regular"
            size={14}
            variant="body1"
            sx={{ mt: 1 }}
          >
            {server?.description}
          </Text>
          <Box display="flex" gap={2} alignItems="center" mt={2}>
            <Box
              display="flex"
              className="pointer-cursor"
              gap={1}
              alignItems="end"
            >
              <CustomLink
                size="small"
                bold={true}
                target="_blank"
                href={`${process.env.REACT_APP_SPEED_DEV_URL}docs/business-operations/custom-domain/custom-payment-domain#${server?.route}`}
              >
                {instructions}
              </CustomLink>
              <KeyboardArrowRightIcon
                style={{
                  height: 20,
                  width: 20,
                  color: "#2a67ff",
                }}
              />
            </Box>
            {index === 0 &&
              currentSelected &&
              currentSelected.status !==
                customPaymentDomainStatus.failed.value && (
                <Button
                  label={connectViaAPIKey}
                  variant="outlined"
                  onClick={() => setOpenConfigureDomainModal(true)}
                />
              )}
          </Box>
        </Box>
      ))}
    </Box>
  );

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const configureDomainsBody = (
    <Box>
      <Text size={16} variant="body1" font="regular">
        {cloudFareSubTitle[0]}{" "}
        <CustomLink
          size="large"
          href="https://dash.cloudflare.com/login"
          target="_blank"
        >
          {cloudFareSubTitle[1]}
        </CustomLink>{" "}
        {cloudFareSubTitle[2]}
      </Text>
      <Box className="margin-top30">
        <Input
          type="text"
          name="token"
          value={values.token}
          onChange={(e) => {
            setTouched({ ...touched, token: false });
            setFieldValue("token", e.target.value);
          }}
          showLabel
          label={apiTokenLabel}
          fullWidth
          placeholder={addApiToken}
          onBlur={() => setTouched({ ...touched, token: true })}
          error={errors.token && Boolean(errors.token)}
        />
        <InputErrorMessage {...defaultErrorMessageProps} inputName="token" />
      </Box>
      <Box className="margin-top30">
        <Input
          type="text"
          name="zone"
          value={values.zone}
          onChange={(e) => {
            setTouched({ ...touched, zone: false });
            setFieldValue("zone", e.target.value);
          }}
          showLabel
          label={zoneLabel}
          fullWidth
          placeholder={addZone}
          onBlur={() => setTouched({ ...touched, zone: true })}
          error={errors.zone && Boolean(errors.zone)}
        />
        <InputErrorMessage {...defaultErrorMessageProps} inputName="zone" />
      </Box>
    </Box>
  );

  const instructionsFooter = (
    <Text size={16} variant="body1" font="regular" align="right">
      <CustomLink size="large" bold={true} onClick={openIntercomChatModal}>
        {needHelp}
      </CustomLink>
    </Text>
  );

  const configureDomainsFooter = (
    <Box display="flex" alignItems="center" justifyContent="space-between">
      <Button
        label={addDomain}
        loading={verifyDomainBtnLoader}
        onClick={handleSubmit}
        disabled={!(isValid && dirty) || isSubmitting}
      />
      <Text size={16} variant="body1" font="regular" align="right">
        <CustomLink size="large" bold={true} onClick={openIntercomChatModal}>
          {needHelp}
        </CustomLink>
      </Text>
    </Box>
  );

  const compareThreeMinutesBuffer = (created) => {
    const createdDate = new Date(created);
    createdDate?.setSeconds(createdDate?.getSeconds() + 180);
    const nowTime = new Date();
    return createdDate < nowTime;
  };

  return (
    <>
      <Modal
        className="dns-records-modal"
        body={instructionsBody}
        maxWidth="sm"
        footer={instructionsFooter}
        handleClose={handleCloseInstructionModal}
        open={openInstructionModal}
        title={dnsRecordModalHeader}
        closeIcon={true}
      />
      <Modal
        body={configureDomainsBody}
        maxWidth="sm"
        footer={configureDomainsFooter}
        handleClose={handleCloseConfigureDomain}
        open={openConfigureDomainModal}
        title={configureDomainTitle}
        closeIcon={!isSubmitting}
      />
      <Box className="box-container">
        <Box className="custom-domain-wrapper" maxHeight="unset !important">
          <List disablePadding>
            <ListItem disablePadding>
              <Box>
                {showText(paymentDomainPreviewURL)}
                {showTag(customPaymentDomainStatus.ready.value)}
                <Text
                  className="grey-text"
                  sx={{
                    mt: "10px",
                    ml: 0,
                  }}
                  size={16}
                  font="regular"
                  variant="subtitle1"
                >
                  {defaultDomain}
                </Text>
              </Box>
            </ListItem>
            <InfiniteScroll
              dataLength={paymentDomains?.length}
              next={loadMore}
              hasMore={hasMore}
              loader={null}
            >
              {paymentDomains?.map((domain) => {
                return (
                  <ListItem key={domain?.id} disablePadding>
                    <Box>
                      <Box display="flex" alignItems="center">
                        {showText(domain?.domain)}
                        {domain?.status ===
                        customPaymentDomainStatus.connecting.value ? (
                          <Box display="flex" alignItems="center">
                            <img
                              src={loaderSpinner}
                              alt="Loader"
                              width={15}
                              height={15}
                              style={{ marginRight: "5px" }}
                            />
                            <Text size={12} font="regular" variant="inline">
                              {connectingMsg}
                            </Text>
                          </Box>
                        ) : (
                          showTag(domain?.status)
                        )}
                      </Box>

                      <Text
                        className="grey-text"
                        sx={{
                          mt: "10px",
                          ml: 0,
                        }}
                        size={16}
                        font="regular"
                        variant="subtitle1"
                      >
                        {customPaymentDomainStatus[domain?.status]?.message}
                      </Text>
                      {domain?.status ===
                      customPaymentDomainStatus.failed.value ? (
                        <Box display="flex" mt={3} gap={2} alignItems="center">
                          <Button
                            label={reconnectVerifyDomain}
                            variant="outlined"
                            loading={verifyDomainBtnLoader}
                            onClick={() => handleVerifyDomain(domain?.id)}
                          />
                          <CustomLink
                            size="small"
                            bold={true}
                            onClick={() => {
                              setOpenInstructionModal(true);
                              setCurrentSelected(domain);
                            }}
                          >
                            {readInstruction}
                          </CustomLink>
                        </Box>
                      ) : domain?.status ===
                          customPaymentDomainStatus.connecting.value &&
                        compareThreeMinutesBuffer(domain?.created) ? (
                        <Button
                          sx={{ mt: 3 }}
                          label={verifyDomain}
                          variant="outlined"
                          loading={verifyDomainBtnLoader}
                          onClick={() => handleVerifyDomain(domain?.id)}
                        />
                      ) : null}
                    </Box>
                  </ListItem>
                );
              })}
              {skeletonLoader && (
                <Box padding="25px" borderBottom="1px solid #e4e9ee">
                  <CommonSkeleton
                    width={241}
                    height={27}
                    sx={{ mb: "17px", borderRadius: "7px" }}
                    color="#c4ccd2"
                  />
                  <CommonSkeleton
                    width={314}
                    height={18}
                    sx={{ borderRadius: "6px" }}
                  />
                </Box>
              )}
            </InfiniteScroll>
          </List>
          <Box sx={{ padding: "20px" }}>
            {showAddDomain && (
              <AddPaymentDomainForm ref={ref} setBtnDisable={setBtnDisable} />
            )}
            <Button
              loading={addDomainBtnLoader}
              label={showAddDomain ? connectDomain : addNewDomain}
              type="button"
              onClick={handleAddAndConnect}
              disabled={btnDisable}
              sx={{ textTransform: "capitalize !important" }}
            />
            {showAddDomain && (
              <Button
                sx={{ ml: "15px" }}
                label={cancel}
                type="button"
                variant="outlined"
                onClick={() => {
                  setShowAddDomain(false);
                  setBtnDisable(false);
                }}
              />
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CustomPaymentDomain;
