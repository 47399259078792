import Button from "@speed/common/src/components/Button/Button";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import { Box } from "@mui/material";
import { cancel } from "@speed/common/src/components/messages";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import { currency } from "@speed/common/src/components/currency";
import {
  currencyLabel,
  itemOptionLabel,
  switchingCurrencyCautionMsg,
  updateLabel,
} from "../messages";
import { renderCurrencyOption } from "../constants";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import { setLastInvoiceSavedIn } from "../../redux/common/actions";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useState } from "react";

const ChangeItemCurrencyModal = ({ formik, openModal, setOpenModal }) => {
  const { values, setFieldValue } = formik;
  const [selectedCurrency, setSelectedCurrency] = useState(values?.currency);
  const [disableUpdateCurrency, setDisableUpdateCurrency] = useState(true);
  const dispatch = useDispatch();

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleOnchangeCurrency = (_e, value) => {
    setSelectedCurrency(value);
    setDisableUpdateCurrency(values?.currency?.code === value?.code);
  };

  const renderModalContent = () => {
    return (
      <>
        <CustomAutoComplete
          name="currency"
          options={currency}
          getOptionLabel={(value) => {
            return `${value.code}`;
          }}
          onChange={handleOnchangeCurrency}
          value={selectedCurrency}
          showLabel
          label={currencyLabel}
          disableClearable
          renderOption={renderCurrencyOption}
        />
        {!disableUpdateCurrency && (
          <AlertMessage
            className="margin-top14"
            severity="error"
            message={switchingCurrencyCautionMsg}
          />
        )}
      </>
    );
  };

  const renderModalFooter = () => {
    return (
      <Box className="invoice-item-currency-modal-footer">
        <Button
          variant="outlined"
          type="submit"
          label={cancel}
          onClick={handleModalClose}
          disabled={false}
        />
        <Button
          className="update-btn"
          type="submit"
          label={updateLabel}
          onClick={() => {
            setFieldValue("currency", selectedCurrency);
            setOpenModal(false);
            dispatch(setLastInvoiceSavedIn(moment.now()));
          }}
          disabled={disableUpdateCurrency}
        />
      </Box>
    );
  };

  return (
    <Modal
      maxWidth="sm"
      className="invoice-item-currency-modal"
      open={openModal}
      body={renderModalContent()}
      footer={renderModalFooter()}
      handleClose={handleModalClose}
      title={itemOptionLabel}
    />
  );
};
export default ChangeItemCurrencyModal;
