import React from "react";
import { useDispatch } from "react-redux";
import { FileUploader } from "react-drag-drop-files";
import { Box } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import QrScanner from "qr-scanner";

import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import { allowedImageTypes } from "@speed/common/src/components/constants";
import Text from "@speed/common/src/components/Text/Text";
import {
  imageSizeMsg,
  noQrCodeFoundErr,
  uploadImageText,
} from "../../messages";
import { showToast } from "../../../redux/common/actions";
import { imageInvalid, imageSizeInvalid } from "@speed/common/src/messages";

const FileUploadJsx = ({ customClass }) => {
  return (
    <Box className={`image_upload-box ${customClass}`}>
      <UploadFileIcon
        sx={{
          height: "24px",
          width: "24px",
          color: "#848B9E",
          marginBottom: "14px",
        }}
      />
      <Text className="grey-text" size={14} font="semibold">
        {uploadImageText[0]}
        <span style={{ color: "#2A67FF" }}>{uploadImageText[1]}</span>
      </Text>
      <Text
        size={14}
        font="regular"
        variant="h6"
        sx={{
          mt: "4px",
          color: "#B5B9C5",
        }}
      >
        {imageSizeMsg}
      </Text>
    </Box>
  );
};

const SendUploadImage = ({
  handleSubmit,
  imageError,
  setImageError,
  customClass = "",
  disabled = false,
}) => {
  const dispatch = useDispatch();

  const onUpload = async (e) => {
    await QrScanner.scanImage(e, {
      returnDetailedScanResult: true,
      scanRegion: {
        downScaledHeight: 500,
        downScaledWidth: 500,
      },
      disallowCanvasResizing: false,
    })
      .then((res) => {
        setImageError("");
        handleSubmit(res?.data);
      })
      .catch((_err) => {
        dispatch(
          showToast({
            isToastOpen: true,
            toastMessage: noQrCodeFoundErr,
            toastVariant: "error",
          })
        );
      });
  };

  return (
    <Box>
      <FileUploader
        name="file"
        types={allowedImageTypes}
        maxSize={2000000}
        onSizeError={() => setImageError(imageSizeInvalid)}
        onTypeError={() => setImageError(imageInvalid)}
        dropMessageStyle={{ backgroundColor: "#E4E9EE" }}
        children={<FileUploadJsx customClass={customClass} />}
        onDrop={(e) => onUpload(e)}
        onSelect={(e) => onUpload(e)}
        hoverTitle=""
        required={false}
        disabled={disabled}
      />
      {imageError && (
        <AlertMessage
          message={imageError}
          severity="error"
          className="margin-top14"
        />
      )}
    </Box>
  );
};

export default SendUploadImage;
