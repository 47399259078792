import React, { useState, useEffect } from "react";
import { Box, Grid, MenuItem } from "@mui/material";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import {
  optionsLabel,
  addCustomField,
  addOptions,
  customFieldLabel,
  customFieldsPopperMsg,
  editOptions,
  markAsOptional,
  maxOptionLengthValidation,
  uniqueOptionsValue,
  valuePlaceHolder,
  invalidLabel,
} from "../messages";
import { commonStyle, handleClick, handleClose } from "./AllLinkFormConstant";
import { Info } from "@mui/icons-material";
import { CustomPopperElement } from "../constants";
import { useFormik, FieldArray, FormikProvider } from "formik";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Text from "@speed/common/src/components/Text/Text";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import { Input } from "@speed/common/src/components/Input/Input";
import CustomSelect from "@speed/common/src/components/Select/Select";
import AddIcon from "@mui/icons-material/Add";
import DropDownOptionsModal from "./DropDownOptionsModal";
import * as yup from "yup";
import { compact, isEmpty } from "lodash";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import {
  maxLengthValidation,
  whiteSpacePattern,
} from "@speed/common/src/messages";
import { uniqueMethod } from "@speed/common/src/components/constants";

const types = [
  { label: "Text", value: "text" },
  { label: "Numbers only", value: "number" },
  { label: "Dropdown", value: "dropdown" },
];

const CustomFieldsForm = ({
  parentsFormik,
  handleButtonDisable,
  parentDisable,
  setCFDisable,
  setCustomFieldDetails,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);
  const [saveButtonClicked, setSaveButtonClicked] = useState(false);
  const [removeClicked, setRemoveClicked] = useState(false);
  const [dataOnUpdate, setDataOnUpdate] = useState({});
  const [counter, setCounter] = useState(1);
  const [arrayHelpersObj, setArrayHelpers] = useState({});

  const open = Boolean(anchorEl);

  const optionsInitialValue = {
    option: "",
  };

  const customFieldInitialValue = {
    type: "text",
    label: "",
    optional: false,
    dropDownOptions: Array(2).fill(optionsInitialValue),
    id: counter,
  };

  // For unique field validation in formik
  uniqueMethod();

  const validationSchema = yup.object({
    customFieldCheckBox: yup.boolean(),
    customFieldsArr: yup.array().of(
      yup.object().shape({
        label: yup
          .string()
          .required("")
          .max(50, maxLengthValidation("Label", 50))
          .matches(whiteSpacePattern, invalidLabel("option"))
          .trim(),
        type: yup.string().required(""),
        dropDownOptions: yup.array().when(["type"], {
          is: (type) => type === "dropdown",
          then: yup.array().of(
            yup
              .object()
              .shape({
                option: yup
                  .string()
                  .required("")
                  .max(50, maxOptionLengthValidation)
                  .matches(whiteSpacePattern, invalidLabel("option"))
                  .trim(),
              })
              .uniqueProperty("option", uniqueOptionsValue)
          ),
        }),
      })
    ),
  });

  const formik = useFormik({
    initialValues: {
      customFieldsArr: Array(1).fill(customFieldInitialValue),
      customFieldCheckBox: false,
    },
    validationSchema: validationSchema,
  });

  const {
    values,
    isValid,
    errors,
    dirty,
    isSubmitting,
    setFieldValue,
    handleChange,
    resetForm,
  } = formik;

  const defaultCheck = !(isValid && dirty) || isSubmitting;

  useEffect(() => {
    if (values.customFieldCheckBox) {
      const reformedArr = values.customFieldsArr.map((item) => {
        return {
          label: item.label,
          type: item.type,
          options: item.dropDownOptions,
          is_optional: item.optional,
          id: item.id,
        };
      });
      setCustomFieldDetails(reformedArr);
    } else setCustomFieldDetails([]);
  }, [values]);

  useEffect(() => {
    if (values.customFieldCheckBox) {
      setCFDisable(defaultCheck);
      handleButtonDisable(parentDisable || defaultCheck);
    } else setCFDisable(false);
  }, [isValid, dirty, isSubmitting, parentDisable, values.customFieldCheckBox]);

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      parentsFormik.setFieldValue(
        "customFields",
        !parentsFormik.values.customFields
      );
      setFieldValue("customFields", !values.customFields);
    }
  };

  const removeField = (index, arrayHelpers) => {
    setRemoveClicked(true);
    if (values.customFieldsArr.length > 1) {
      arrayHelpers.remove(index);
    }
  };

  const handleKeypress = (e, index, arrayHelpers) => {
    if (e.keyCode === 13) {
      removeField(index, arrayHelpers);
    }
  };

  useEffect(() => {
    if (values.customFieldsArr && removeClicked) {
      const result = values.customFieldsArr.map((item) => item.dropDownOptions);
      setDataOnUpdate(result);
      setRemoveClicked(false);
    }
  }, [removeClicked]);

  useEffect(() => {
    if (values.customFieldsArr) {
      const result = values.customFieldsArr.map((item) => {
        return (
          item.dropDownOptions.length > 0 &&
          item.dropDownOptions.filter((optionItem) => {
            return !Object.values(optionItem).every((x) => x === "");
          })
        );
      });
      setDataOnUpdate(result);
    }
  }, [saveButtonClicked]);

  const handleParentSave = () => {
    const result = values.customFieldsArr.map((item) => {
      return {
        ...item,
        dropDownOptions: item.dropDownOptions.map((child) => {
          return {
            option: child.option?.trim(),
          };
        }),
      };
    });
    values.customFieldsArr = result;
    setSaveButtonClicked(true);
    setOpenModal(false);
  };

  const handleCancel = () => {
    setOpenModal(false);

    // Options Available Check: If it's true options will be reset else last added options values will be set
    const cancelValuesCheck =
      !dataOnUpdate[modalIndex] || dataOnUpdate[modalIndex]?.length === 0;

    setFieldValue(
      `customFieldsArr[${modalIndex}].dropDownOptions`,
      cancelValuesCheck
        ? Array(2).fill(optionsInitialValue)
        : dataOnUpdate[modalIndex]
    );
  };

  useEffect(() => {
    !isEmpty(arrayHelpersObj) &&
      arrayHelpersObj.push({
        ...customFieldInitialValue,
        id: counter,
      });
  }, [counter]);

  return (
    <Box className="margin-top20">
      <Box className="content-center" justifyContent="unset !important">
        <CustomCheckbox
          checked={values.customFieldCheckBox}
          name="customFields"
          label={customFieldLabel}
          className="options checkbox-label"
          sx={commonStyle}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            parentsFormik.setFieldValue("customFields", e.target.checked);
            setFieldValue("customFieldCheckBox", e.target.checked);
            if (!e.target.checked) {
              resetForm();
              setDataOnUpdate({});
            }
          }}
        />

        <Info
          className="label-with-icon"
          onClick={(e) => handleClick(e, setAnchorEl, anchorEl)}
          style={{
            height: 18,
            width: 18,
            color: "#848b9e",
          }}
        />
      </Box>

      <CustomPopperElement
        id="customField"
        desc={customFieldsPopperMsg}
        setAnchor={setAnchorEl}
        anchor={anchorEl}
        open={open}
        handleClose={handleClose}
      />

      {/* Custom Field Form */}
      {values.customFieldCheckBox && (
        <FormikProvider value={formik}>
          <Box>
            <FieldArray
              name="customFieldsArr"
              render={(arrayHelpers) => (
                <Box>
                  {values.customFieldsArr?.length > 0
                    ? values.customFieldsArr.map((option, index) => {
                        const result =
                          dataOnUpdate?.length > 0 &&
                          dataOnUpdate[index]?.map((item) => item.option);

                        const convertedOptionsToString =
                          (result?.length > 0 && compact(result)?.join(", ")) ||
                          "";

                        const errorCheck = errors?.customFieldsArr?.[index];

                        return (
                          <React.Fragment key={`custom_field_${index}`}>
                            <Grid
                              container
                              justifyContent="space-between"
                              className="margin-top15"
                              alignItems="center"
                            >
                              <Grid item lg={5.3}>
                                <CustomSelect
                                  customClass="currency-dropdown"
                                  name={`customFieldsArr[${index}].type`}
                                  showLabel={false}
                                  value={values.customFieldsArr[index].type}
                                  MenuProps={{
                                    id: "custom-field-arr-types",
                                  }}
                                  onChange={handleChange}
                                  fullWidth={true}
                                >
                                  {types.map((option) => (
                                    <MenuItem
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </CustomSelect>
                              </Grid>
                              <Grid item lg={5.3}>
                                <Input
                                  type="text"
                                  showLabel={false}
                                  fullWidth={true}
                                  placeholder={valuePlaceHolder}
                                  name={`customFieldsArr[${index}].label`}
                                  value={values.customFieldsArr[index].label}
                                  error={
                                    errorCheck && Boolean(errorCheck?.label)
                                  }
                                  onChange={handleChange}
                                />
                              </Grid>
                              <Grid item lg={0.5} sx={{ textAlign: "right" }}>
                                {values.customFieldsArr.length > 1 && (
                                  <RemoveCircleOutlinedIcon
                                    onClick={() =>
                                      removeField(index, arrayHelpers)
                                    }
                                    className="remove-circle-icon"
                                    tabIndex={0}
                                    onKeyUp={(e) =>
                                      handleKeypress(e, index, arrayHelpers)
                                    }
                                  />
                                )}
                              </Grid>
                            </Grid>
                            {errorCheck && Boolean(errorCheck?.label) && (
                              <AlertMessage
                                key={`cf_error_${errorCheck}`}
                                message={errorCheck?.label}
                                className="margin-top15"
                                severity="error"
                                sx={{
                                  margin: "auto",
                                }}
                              />
                            )}
                            {values.customFieldsArr[index].type ===
                              "dropdown" && (
                              <Box
                                width="100%"
                                className="dropdown-options-box"
                              >
                                <Text
                                  size={14}
                                  className="grey-text"
                                  variant="subtitle1"
                                  font="medium"
                                >
                                  {optionsLabel}
                                </Text>
                                <Text
                                  size={16}
                                  className="default-text"
                                  variant="subtitle1"
                                  font="regular"
                                  sx={{
                                    marginTop: "8px",
                                    maxWidth: "450px",
                                    wordBreak: "break-word",
                                  }}
                                >
                                  {convertedOptionsToString}
                                </Text>
                                <Text
                                  size={14}
                                  className="default-text pointer-cursor"
                                  variant="subtitle1"
                                  font="semibold"
                                  sx={{
                                    color: "#2A67FF !important",
                                    marginTop: "10px",
                                  }}
                                  onClick={() => {
                                    setModalIndex(index);
                                    setOpenModal(true);
                                    setSaveButtonClicked(false);
                                    setRemoveClicked(false);
                                  }}
                                >
                                  {convertedOptionsToString !== ""
                                    ? editOptions
                                    : addOptions}
                                </Text>
                              </Box>
                            )}
                            <CustomCheckbox
                              label={markAsOptional}
                              className="margin-top15"
                              checked={values.customFieldsArr[index].optional}
                              name={`customFieldsArr[${index}].optional`}
                              onChange={handleChange}
                            />
                            {index !== 2 && (
                              <CustomDivider sx={{ margin: "24px 0" }} />
                            )}
                          </React.Fragment>
                        );
                      })
                    : null}
                  {values.customFieldsArr.length < 3 && (
                    <Text
                      withIcon="start"
                      size={14}
                      font="semibold"
                      variant="subtitle1"
                      sx={{
                        color: "#2A67FF",
                        cursor: "pointer",
                        marginTop: "-8px",
                      }}
                      onClick={() => {
                        setArrayHelpers(arrayHelpers);
                        setCounter((counter) => counter + 1);
                      }}
                    >
                      <AddIcon
                        sx={{
                          height: "24px",
                          width: "24px",
                          color: "#2A67FF !important",
                        }}
                      />{" "}
                      {addCustomField}
                    </Text>
                  )}
                </Box>
              )}
            />
            {openModal && (
              <DropDownOptionsModal
                parentIndex={modalIndex}
                name={`customFieldsArr[${modalIndex}].dropDownOptions`}
                openModal={openModal}
                handleClose={handleCancel}
                optionsInitialValue={optionsInitialValue}
                handleKeypress={handleKeypress}
                handleParentSave={handleParentSave}
                isEdit={dataOnUpdate[modalIndex]?.length > 0}
              />
            )}
          </Box>
        </FormikProvider>
      )}
    </Box>
  );
};
export default CustomFieldsForm;
