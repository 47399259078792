import Button from "@speed/common/src/components/Button/Button";
import Text from "@speed/common/src/components/Text/Text";
import {
  activate,
  activate2FAMsg,
  activate2FASubMsg,
  confirm2faMsg,
  enabled2faMsg,
  smsAuthSuccessMsg,
  useAppAuthMsg,
  useAppMsg,
  useSMSAuthMsg,
  useSMSMsg,
  verificationCode,
  invalidCaptchaCode,
} from "../../messages";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import { Grid } from "@mui/material";
import TwoFAModalContent from "./2FAModalContent";
import TwoFAModalFooter from "./2FAModalFooter";
import { callAPIInterface, getLowerCamelCaseText } from "../../constants";
import {
  setProfileMfaAuthDetails,
  showToast,
} from "../../../redux/common/actions";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentTimeInSecond } from "@speed/common/src/components/constants";
import { validateCaptcha } from "react-simple-captcha";
import { mfaResendCodeMsg } from "@speed/common/src/messages";

const Activate2FA = forwardRef((props, ref) => {
  const modalRef = useRef();
  const profileMfaAuthDetails = useSelector(
    (state) => state.common.profileMfaAuthDetails
  );

  const getCaptchaVerifiedValue = (value) => {
    return !props.mfaCaptchaVerifyCode ? true : value;
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [isAppContinue, setIsAppContinue] = useState(false);
  const [isSMSContinue, setIsSMSContinue] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [byQRCode, setByQRCode] = useState(true);
  const [isAppBtnDisabled, setIsAppBtnDisabled] = useState(true);
  const [isSMSBtnDisabled, setIsSMSBtnDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [loadingMFATypes, setLoadingMFATypes] = useState(false);
  const [loading, setLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const [mfaTypes, setMfaTypes] = useState([]);
  const dispatch = useDispatch();
  const [expiresAt, setExpiresAt] = useState();
  const [timerCount, setTimerCount] = useState();
  const [isExpiredOTP, setIsExpiredOTP] = useState(false);
  const [isFromRemoveSMS, setIsFromRemoveSMS] = useState(false);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(
    getCaptchaVerifiedValue(false)
  );
  const [captchaCode, setCaptchaCode] = useState("");

  let modalTitle, confirmModalTitle;
  switch (selectedType) {
    case "sms":
      modalTitle = isSMSContinue ? verificationCode : useSMSAuthMsg;
      confirmModalTitle = useSMSAuthMsg;
      break;
    case "authenticator":
      modalTitle = useAppAuthMsg;
      confirmModalTitle = useAppAuthMsg;
      break;
  }

  const handleModalClose = () => {
    setTimerCount();
    setOpen(false);
    setIsFromRemoveSMS(false);
    setIsSMSBtnDisabled(true);
    setIsAppBtnDisabled(true);
    setSelectedType("");
    setIsCaptchaVerified(getCaptchaVerifiedValue(false));
    setCaptchaCode("");
  };

  const setMFAReducerDetails = (response) => {
    let updatedAuthData = profileMfaAuthDetails;
    const foundIndex = profileMfaAuthDetails.findIndex(
      (x) => x.mfa_type === response?.mfa_type
    );

    if (foundIndex !== -1) {
      updatedAuthData[foundIndex] = response;
    } else {
      if (response) updatedAuthData.push(response);
      else updatedAuthData = [];
    }
    dispatch(setProfileMfaAuthDetails(updatedAuthData));
  };

  const showSuccessToast = (message) => {
    dispatch(
      showToast({
        isToastOpen: true,
        toastMessage: message,
        toastVariant: "success",
      })
    );
  };

  const setTimerStates = (mfaData) => {
    setIsExpiredOTP(false);
    setExpiresAt(mfaData?.otp_expires_at);
    setTimerCount(getCurrentTimeInSecond(mfaData?.otp_expires_at));
    setMFAReducerDetails(mfaData);
    setIsSMSBtnDisabled(true);
  };

  const resetCaptchaStates = () => {
    setIsCaptchaVerified(getCaptchaVerifiedValue(false));
    setCaptchaCode("");
  };

  const handleActivateButtonClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    if (mfaTypes?.length === 0) {
      setLoadingMFATypes(true);
      return callAPIInterface("GET", "/mfa/type")
        .then((mfaTypeList) => {
          if (mfaTypeList?.mfa_types?.length) {
            setMfaTypes(mfaTypeList?.mfa_types);
            setLoadingMFATypes(false);
          }
        })
        .catch(() => {
          setLoadingMFATypes(false);
        });
    }
  };

  const handleSMSActivateButtonClick = () => {
    if (countries?.length === 0) {
      setLoadingMFATypes(true);
      setAnchorEl(null);
      return callAPIInterface("GET", "/mfa/calling-code")
        .then((countryList) => {
          if (countryList?.country_calling_codes?.length) {
            const countriesWithId = countryList?.country_calling_codes.map(
              (country, index) => {
                return { ...country, id: index + 1 };
              }
            );
            setCountries(countriesWithId);
            setLoadingMFATypes(false);
            handleTextClick("sms");
            resetCaptchaStates();
          }
        })
        .catch(() => {
          setLoadingMFATypes(false);
        });
    } else {
      handleTextClick("sms");
      resetCaptchaStates();
    }
  };

  const handleSubmitPhoneNumber = () => {
    setLoading(true);
    const phoneNumData =
      modalRef.current && modalRef.current.sendSMSVerificationAPIData();
    if (phoneNumData) {
      return callAPIInterface("POST", "/mfa/phone-number", phoneNumData)
        .then((response) => {
          setTimerStates(response);
          setIsSMSContinue(true);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleSubmitVerifyOTP = () => {
    setLoading(true);
    const OTPData =
      modalRef.current && modalRef.current.sendOTPVerificationAPIData();
    if (OTPData) {
      const apiEndpoint = isFromRemoveSMS
        ? "/mfa/phone-number/remove/verify"
        : "/mfa/phone-number/verify";
      return callAPIInterface("POST", apiEndpoint, OTPData)
        .then((response) => {
          showSuccessToast(
            isFromRemoveSMS
              ? smsAuthSuccessMsg("Removed")
              : smsAuthSuccessMsg("Enabled")
          );
          setMFAReducerDetails(response);
          handleModalClose();
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const handleResendCode = () => {
    setLoading(true);
    const apiEndpoint = isFromRemoveSMS
      ? "/mfa/phone-number/remove/resend-verification-code"
      : "/mfa/phone-number/resend-verification-code";

    return callAPIInterface("POST", apiEndpoint, {})
      .then((response) => {
        showSuccessToast(mfaResendCodeMsg());
        setTimerStates(response);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const handleDeleteSMSMfa = () => {
    const { smsConfigDetails } = props;
    if (smsConfigDetails) {
      setLoading(true);
      return callAPIInterface("DELETE", `/mfa/${props?.smsConfigDetails?.id}`)
        .then((response) => {
          setIsSMSContinue(true);
          setTimerStates(response);
          setLoading(false);
          setIsCaptchaVerified(getCaptchaVerifiedValue(true));
          // setIsFromRemoveSMS(true);
          // setSelectedType("sms");
          // setOpen(true);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (isSMSContinue && expiresAt && timerCount > 0) {
      const timer = setInterval(() => {
        const currentSeconds = getCurrentTimeInSecond(expiresAt);
        setTimerCount(currentSeconds);
        currentSeconds === 0 && setIsExpiredOTP(true);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [isSMSContinue, expiresAt, timerCount]);

  useEffect(() => {
    setSelectedType(props.type);
  }, [props.type]);

  const commonParams = {
    isAppContinue,
    isSMSContinue,
    selectedType,
    handleClose: handleModalClose,
    byQRCode,
  };

  const handleCaptchaVerify = () => {
    if (validateCaptcha(captchaCode)) {
      if (isFromRemoveSMS) {
        handleDeleteSMSMfa();
      } else {
        setIsCaptchaVerified(getCaptchaVerifiedValue(true));
        setIsSMSBtnDisabled(true);
      }
    } else {
      setCaptchaCode("");
      dispatch(
        showToast({
          isToastOpen: true,
          toastMessage: invalidCaptchaCode,
          toastVariant: "error",
          toastAutoHideDuration: 5000,
        })
      );
    }
  };

  const body = (
    <TwoFAModalContent
      ref={modalRef}
      smsConfigDetails={props.smsConfigDetails}
      mfaResendcodeLdFlag={
        isFromRemoveSMS ? props.mfaRemoveResendcode : props.mfaResendcode
      }
      setIsAppBtnDisabled={setIsAppBtnDisabled}
      setIsSMSBtnDisabled={setIsSMSBtnDisabled}
      isCaptchaVerified={isCaptchaVerified}
      captchaCode={captchaCode}
      setCaptchaCode={setCaptchaCode}
      handleResendCode={handleResendCode}
      isExpiredOTP={isExpiredOTP}
      countries={countries}
      timerCount={timerCount}
      isFromRemoveSMS={isFromRemoveSMS}
      {...commonParams}
    />
  );

  const footer = (
    <TwoFAModalFooter
      loading={loading}
      isAppBtnDisabled={isAppBtnDisabled}
      handleTextChange={() => setByQRCode(!byQRCode)}
      isCaptchaVerified={isCaptchaVerified}
      setIsAppContinue={() => {
        setIsAppContinue(true);
        setIsAppBtnDisabled(true);
      }}
      isSMSBtnDisabled={isSMSBtnDisabled}
      handleSubmitSMS={handleSubmitPhoneNumber}
      handleSubmitOTP={handleSubmitVerifyOTP}
      handleCaptchaVerify={handleCaptchaVerify}
      {...commonParams}
    />
  );

  const modalParams = {
    closeIcon: true,
    open: open,
    handleClose: () => {
      setTimerCount();
      setOpen(false);
    },
    title: modalTitle,
    body: body,
    footer: footer,
  };

  const confirmModalBody = (
    <Grid alignItems="center" display="flex" flexDirection="column">
      <CheckCircleIcon
        style={{ color: "#58c93f", height: "42px", width: "42px" }}
      />
      <Text
        variant="h5"
        font="semibold"
        size={16}
        className="default-text margin-top14"
      >
        {enabled2faMsg}
      </Text>
      <Text
        align="center"
        variant="subtitle1"
        font="regular"
        size={14}
        className="grey-text margin-top14 margin-bottom12"
      >
        {confirm2faMsg(selectedType)}
      </Text>
    </Grid>
  );

  const confirmParams = {
    closeIcon: true,
    open: openConfirm,
    handleClose: () => {
      setOpenConfirm(false);
      props.enable2FA && props.enable2FA(selectedType);
    },
    title: confirmModalTitle,
    body: confirmModalBody,
  };

  const handlePopperClose = () => {
    setAnchorEl(null);
  };

  const setToInitialState = (value) => {
    setOpen(true);
    if (value === "authenticator") {
      setByQRCode(true);
      setIsAppContinue(false);
    } else {
      setIsSMSContinue(false);
    }
  };

  const handleTextClick = (value) => {
    setToInitialState(value);
    setSelectedType(value);
    setAnchorEl(null);
  };

  useImperativeHandle(ref, () => ({
    handleModalOpen: (type) => {
      setToInitialState(type);
    },
    handleRemoveSMSMfa: () => {
      const { setRemoveMfaLoading, mfaCaptchaVerifyCode } = props;
      if (!mfaCaptchaVerifyCode) {
        handleDeleteSMSMfa().then(() => {
          setIsFromRemoveSMS(true);
          setRemoveMfaLoading(false);
          setSelectedType("sms");
          setOpen(true);
        });
      } else {
        setIsFromRemoveSMS(true);
        setRemoveMfaLoading(false);
        setSelectedType("sms");
        setOpen(true);
        resetCaptchaStates();
      }
    },
  }));

  const openPopper = Boolean(anchorEl);

  return (
    <>
      {props.showActiveSection ? (
        <>
          <Text font="semibold" size={20} variant="h5" className="default-text">
            {activate2FAMsg}
          </Text>
          <Text
            font="regular"
            size={16}
            variant="subtitle1"
            className="default-text margin-top15"
          >
            {activate2FASubMsg}
          </Text>
          <Button
            loading={loadingMFATypes}
            type="button"
            className="margin-top30"
            color="primary"
            label={activate}
            variant="contained"
            onClick={handleActivateButtonClick}
          />
          {mfaTypes?.length > 0 && (
            <CustomPopper
              open={openPopper}
              anchorEl={anchorEl}
              position="bottom-start"
              handleClose={handlePopperClose}
              sx={{ minWidth: "250px" }}
            >
              <Box className="box-content">
                {mfaTypes.map((mfaType, index) => {
                  const isSMSType = mfaType === "sms";
                  const textProps = {
                    size: 16,
                    className: `${
                      isSMSType ? "default-text" : "grey-text"
                    } pointer-cursor list-item`,
                    variant: "subtitle1",
                    sx: isSMSType && { fontFamily: "Inter-Medium !important" },
                  };

                  let itemTitle;
                  switch (getLowerCamelCaseText(mfaType)) {
                    case "sms":
                      itemTitle = useSMSMsg;
                      textProps.onClick = handleSMSActivateButtonClick;
                      break;
                    case "google_authenticator":
                      itemTitle = useAppMsg;
                      textProps.font = "regular";
                      break;
                  }

                  return (
                    <>
                      {isSMSType && (
                        <Text key={index} {...textProps}>
                          {itemTitle}
                        </Text>
                      )}
                    </>
                  );
                })}
              </Box>
            </CustomPopper>
          )}
        </>
      ) : null}
      <Modal {...modalParams} />
      <Modal {...confirmParams} />
    </>
  );
});

export default Activate2FA;
