import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import CustomTable from "@speed/common/src/components/Table/Table";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";
import Text from "@speed/common/src/components/Text/Text";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  callAPIInterface,
  emailStatus,
  idealTimeLimitReached,
  setShowCrucialActionModal,
  updateSessionLastActionTime,
} from "../../../components/constants";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import List from "@mui/material/List";
import { setExecuteAPICall, setLoading } from "../../../redux/common/actions";
import { sessionService } from "redux-react-session";
import {
  accountSetting,
  addDomain,
  addMoreDomainValidation,
  changeDomainBtn,
  domain,
  emailAddress,
  emailPlaceholder,
  remove,
  removeDomain,
  removeDomainBtn,
  removeDomainContentMsg,
  send,
  sending,
  sendingDomain,
  sendingDomainMsg,
  sendTestEmail,
  sendTestEmailTitle,
  setAsSendingDomain,
  validationMsg,
  validationMsgArr,
  validationMsgHeading,
  verifyDomain,
  viewDnsRecords,
} from "../../messages";
import Button from "@speed/common/src/components/Button/Button";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import Grid from "@mui/material/Grid";
import { useFormik } from "formik";
import * as yup from "yup";
import EmailDomainModalContent from "./EmailDomainContent";
import { isEmailDomainValid } from "../../constants";
import { CustomTooltip } from "@speed/common/src/components/Tooltip/Tooltip";
import { invalidEmail } from "@speed/common/src/components/messages";
import _ from "lodash";
import VerifyDnsRecordsModal from "./VerifyDnsRecordsModal";
import ConfirmationModal from "./ConfirmationModal";
import history from "@speed/common/src/components/history";

const defaultDomain = {
  id: "1",
  object: "email.domain",
  isDefault: true,
  status: "verified",
  domain: "tryspeed.com",
  valid: true,
  selected: true,
  dns_records: {
    mail_cname: {
      valid: true,
      type: "cname",
      host: "em6494.tryspeed.com",
      data: "u24123330.wl081.sendgrid.net",
    },
    dkim1: {
      valid: true,
      type: "cname",
      host: "s1._domainkey.tryspeed.com",
      data: "s1.domainkey.u24123330.wl081.sendgrid.net",
    },
    dkim2: {
      valid: true,
      type: "cname",
      host: "s2._domainkey.tryspeed.com",
      data: "s2.domainkey.u24123330.wl081.sendgrid.net",
    },
  },
};

const EmailDomains = (props) => {
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [rows, setRows] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [session, setSession] = useState(null);
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openAddRecordModal, setOpenAddRecordModal] = useState(false);
  const [openRemoveRecordModal, setOpenRemoveRecordModal] = useState(false);
  const [openSendingDomain, setOpenSendingDomain] = useState(false);
  const [openTestMailPopUp, setOpenTestMailPopUp] = useState(false);
  const [isValidateRecords, setIsValidateRecords] = useState(false);
  const [isError, setIsError] = useState(false);
  const [dnsRecords, setDnsRecords] = useState({});
  const [modalName, setModalName] = useState("");
  const [isDisplayContentHeader, setIsDisplayContentHeader] = useState(true);
  const [modalLoader, setModalLoader] = useState(false);
  const [failedValidationCount, setFailedValidationCount] = useState(0);
  const [errorObj, setErrorObj] = useState({});
  const [state, setState] = useState({});
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [testEmail, setTestEmail] = useState("");
  const [confirmationAction, setConfirmationAction] = useState("");

  const user = useSelector((state) => state.auth.user);
  const { executeAPICall } = useSelector((state) => state.common);

  let openPopper = Boolean(anchorEl);
  let actions = [];

  const dispatch = useDispatch();

  useEffect(() => {
    sessionService.loadSession().then((newSession) => {
      setSession(newSession);
    });
    getEmailDomainList();
  }, []);

  useEffect(() => {
    if (executeAPICall) {
      const newSession = updateSessionLastActionTime();
      setSession(newSession);
      if (state?.action === "setAsSending") {
        handleSetSendingDomain();
      }

      if (state?.action === "deleteDomain") {
        handleRemoveDomain();
      }

      dispatch(setLoading(false));
      setShowCrucialActionModal(user, false);
      dispatch(setExecuteAPICall(false));
    }
  }, [executeAPICall]);

  useEffect(() => {
    if (actions.length === 0) {
      setAnchorEl(null);
    }
  }, [openPopper]);

  const getEmailDomainList = () => {
    setTableRowSkeleton(true);
    callAPIInterface("GET", "/settings/email-domains")
      .then((response) => {
        setRows(response.data);
        setTableRowSkeleton(false);
        addDefaultDomain(response.data);
      })
      .catch((_error) => {
        setTableRowSkeleton(false);
      });
  };

  const addDefaultDomain = (rows) => {
    if (rows.length !== 0) {
      rows.find((o) => {
        if (o.selected) {
          defaultDomain.selected = false;
          setRows([...rows, defaultDomain]);
        } else {
          // defaultDomain.selected = true;
          setRows([...rows, defaultDomain]);
        }
      });
    } else {
      setRows([defaultDomain]);
    }
  };

  const validationSchema = yup.object({
    email_domain:
      modalName === "addDomain"
        ? yup.string().matches(isEmailDomainValid, "").required("")
        : yup.string().email().required(""),
  });

  const addCustomEmailDomain = (domain, resetForm) => {
    setModalLoader(true);
    callAPIInterface(
      "POST",
      "/settings/email-domains",
      JSON.stringify({ domain: domain.email_domain })
    )
      .then((response) => {
        setModalLoader(false);
        resetForm();
        setRows([response, ...rows]);
        setCurrentRow(response);
        setOpenAddModal(false);
        setDnsRecords(response);
        const afterVerify =
          response.dns_records["mail_cname"].hasOwnProperty("reason");
        if (response.status === emailStatus.verified.value && afterVerify) {
          setOpenAddRecordModal(false);
        } else if (
          response.status === emailStatus.pending_verification.value &&
          afterVerify
        ) {
          setOpenAddRecordModal(true);
          setIsError(true);
          setIsValidateRecords(true);
          const result = createErrorObj(response);
          setDnsRecords(result.updatedDnsRecords);
        } else {
          setOpenAddRecordModal(true);
          setIsDisplayContentHeader(true);
        }
      })
      .catch(() => {
        setModalLoader(false);
      });
  };

  const sendTestEmailFromDomain = (fields, resetForm) => {
    setModalLoader(true);
    callAPIInterface(
      "POST",
      "/settings/email-domains/send-test-email",
      JSON.stringify({
        id: currentRow.id,
        recipient_email: fields.email_domain,
      })
    )
      .then(() => {
        setTestEmail(fields.email_domain);
        setModalLoader(false);
        setAnchorEl(null);
        setOpenTestMailPopUp(false);
        setConfirmationAction("sendTestMail");
        setOpenConfirmation(true);
        resetForm();
      })
      .catch(() => {
        setModalLoader(false);
      });
  };

  const handleFormSubmit = async (field, { resetForm }) => {
    setIsValidateRecords(false);
    setIsError(false);
    if (modalName === "addDomain") {
      setState({ domain: field, resetForm: resetForm, action: "addDomain" });

      handleActionClick("addDomain", field, resetForm);
    } else {
      sendTestEmailFromDomain(field, resetForm);
    }
  };

  const formik = useFormik({
    initialValues: {
      email_domain: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleFormSubmit,
  });

  const {
    values,
    isValid,
    errors,
    touched,
    isSubmitting,
    dirty,
    setTouched,
    setSubmitting,
    setFieldValue,
  } = formik;

  const columns = [
    { title: "Domain", width: "700px", paddingLeft: "35px !important" },
    { title: "Status", width: "450px" },
    { title: "Action", width: "150px" },
  ];

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick(e, currentRow);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event, rowData) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setCurrentRow(rowData);
  };

  let columnsData = columns.map((column, index) => (
    <TableCell
      key={index}
      sx={{ width: column.width, paddingLeft: column.paddingLeft }}
    >
      {column.title}
    </TableCell>
  ));

  let rowsData =
    rows &&
    rows.map((rowItem) => {
      return (
        <TableRow key={rowItem.id}>
          <TableCell sx={{ paddingLeft: "35px !important" }}>
            <Text
              size={16}
              font="regular"
              className="default-text"
              variant="inline"
            >
              {rowItem?.domain}
            </Text>
            {rowItem.selected && (
              <Tag
                text={sending}
                variant="primary"
                className="current-status"
                sx={{ marginLeft: "5px" }}
              />
            )}
          </TableCell>
          <TableCell>
            <Tag
              text={emailStatus[rowItem.status].label}
              variant={emailStatus[rowItem.status].variant}
            />
          </TableCell>
          <TableCell>
            <MoreHorizIcon
              className="horizontal-dots-icon"
              sx={{ cursor: "pointer" }}
              tabIndex={0}
              onKeyDown={handleKeyDown}
              onClick={(event) => {
                event.stopPropagation();
                handleClick(event, rowItem);
              }}
            />
          </TableCell>
        </TableRow>
      );
    });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
  };

  //for crucial action
  const handleActionClick = (action, field, resetForm) => {
    if (
      action !== "addDomain" &&
      idealTimeLimitReached(session.last_action_time)
    ) {
      setShowCrucialActionModal(user, true);
    } else {
      if (action === "addDomain") {
        addCustomEmailDomain(field, resetForm);
      }
      if (action === "setAsSending") {
        handleSetSendingDomain();
      }
      if (action === "deleteDomain") {
        handleRemoveDomain();
      }
    }
  };

  const createErrorObj = (response) => {
    const updatedDnsRecords = response;
    let result = {};
    let count = 0;

    _.forEach(response.dns_records, function (value, key) {
      result = { ...result, [key]: value.valid };
      updatedDnsRecords.dns_records[key].valid = value.valid;

      if (!value.valid) {
        count = count + 1;
        setFailedValidationCount(count);
      }
    });

    setErrorObj(result);
    return { updatedDnsRecords, result };
  };

  //on verify dns records
  const handleOnClickContinue = () => {
    setModalLoader(true);
    callAPIInterface(
      "POST",
      `/settings/email-domains/verify-email-domain`,
      JSON.stringify({ id: currentRow.id })
    )
      .then((response) => {
        const result = createErrorObj(response);
        setModalLoader(false);
        setDnsRecords(result.updatedDnsRecords);

        if (response.status === emailStatus["verified"].value) {
          const result = rows.map((row) => {
            if (row.id === response.id) {
              return { ...row, status: response.status };
            } else {
              return row;
            }
          });
          setRows(result);
          setOpenAddRecordModal(false);
          setIsValidateRecords(false);
          setConfirmationAction("verifyDomain");
          setOpenConfirmation(true);
        } else {
          setIsError(true);
          setIsValidateRecords(true);
        }
      })
      .catch((_error) => {
        setModalLoader(false);
      });
  };

  // body of remove domain modal
  const removeDomainContent = (
    <Text size={16} font="regular" className="default-text" variant="inline">
      {removeDomainContentMsg}
    </Text>
  );

  // body of set sending domain modal
  const sendingDomainContent = (
    <Text size={16} font="regular" className="default-text" variant="inline">
      {sendingDomainMsg(
        rows.find((r) => r.selected)?.domain,
        currentRow?.domain
      )}
      <a
        onClick={() => history.push("/settings/email-preferences")}
        className="email-preference-info"
      >
        {accountSetting}
      </a>
    </Text>
  );

  //to set domain as sending domain
  const handleSetSendingDomain = () => {
    setModalLoader(true);
    callAPIInterface(
      "POST",
      "/settings/email-domains/set-email-domain",
      JSON.stringify({ id: currentRow.id })
    )
      .then(() => {
        setModalLoader(false);
        setOpenSendingDomain(false);

        const result = rows.map((row) => {
          return {
            ...row,
            selected: row.id === currentRow.id ? true : false,
          };
        });

        // To remain default domain selected when it is already true
        defaultDomain.selected =
          currentRow.id === defaultDomain.id ? true : false;
        setRows(result);
      })
      .catch((_error) => {
        setModalLoader(false);
      });
  };

  //add domain name modal footer
  const footer = (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      display="flex"
    >
      <Button
        style={{ width: "175px" }}
        label={addDomain}
        disabled={!(isValid && dirty) || isSubmitting}
        onClick={formik.handleSubmit}
        loading={modalLoader}
      />
    </Grid>
  );

  //remove domain modal footer
  const removeDomainFooter = (
    <Button
      style={{ width: "177px" }}
      label={removeDomainBtn}
      onClick={() => {
        setState({ action: "deleteDomain" });
        handleActionClick("deleteDomain");
      }}
      className="remove-domain-button"
      loading={modalLoader}
    />
  );

  //set sa sending domain modal footer
  const sendingDomainFooter = (
    <Button
      style={{ width: "176px" }}
      label={changeDomainBtn}
      onClick={() => {
        setState({ action: "setAsSending" });
        handleActionClick("setAsSending");
      }}
      className="remove-domain-button"
      loading={modalLoader}
    />
  );

  //send test email modal footer
  const setSendTestMailFooter = (
    <Button
      style={{ width: "176px" }}
      label={send}
      onClick={formik.handleSubmit}
      disabled={!(isValid && dirty) || isSubmitting}
      className="send-test-mail-button"
      loading={modalLoader}
    />
  );

  //to remove domain
  const handleRemoveDomain = () => {
    setModalLoader(true);
    callAPIInterface("DELETE", `/settings/email-domains/${currentRow.id}`, {})
      .then(() => {
        setRows(rows.filter((row) => row.id !== currentRow.id));
        setModalLoader(false);
        setOpenRemoveRecordModal(false);
      })
      .catch((_error) => {
        setModalLoader(false);
      });
  };

  //to open remove domain modal
  const onClickRemoveDomain = () => {
    setOpenAddRecordModal(false);
    setOpenRemoveRecordModal(true);
  };

  //to open verify dns record modal after add
  const handleOnClickVerifyDomain = (action) => {
    setIsDisplayContentHeader(action === "view" ? false : true);
    setOpenAddRecordModal(true);
    setIsError(false);
    setIsValidateRecords(false);
    setDnsRecords(currentRow);
  };
  //to open send test mail modal
  const handleOnClickSendTestMail = () => {
    formik.resetForm();
    setModalName("sendMail");
    setOpenTestMailPopUp(true);
  };

  //to open set as sending modal domain
  const handleSendingDomainOnClick = () => {
    setOpenSendingDomain(true);
  };

  const renderAction = () => {
    const actionsObj = {
      send_test_mail: {
        title: sendTestEmail,
        action: () => handleOnClickSendTestMail(),
      },
      view_dns_record: {
        title: viewDnsRecords,
        action: () => handleOnClickVerifyDomain("view"),
      },
      set_sending_Domain: {
        title: setAsSendingDomain,
        action: () => handleSendingDomainOnClick(),
      },
      verify_domain: {
        title: verifyDomain,
        action: () => handleOnClickVerifyDomain("verify"),
      },
      remove: {
        title: remove,
        action: () => onClickRemoveDomain(),
      },
    };

    switch (currentRow?.status) {
      case "verified":
        actions.push(actionsObj.send_test_mail);
        if (currentRow.status === "verified" && !currentRow?.isDefault) {
          actions.push(actionsObj.view_dns_record);
        }

        if (!currentRow?.selected) {
          actions.push(actionsObj.set_sending_Domain);
          !currentRow?.isDefault && actions.push(actionsObj.remove);
        }
        break;
      case "pending_verification":
        actions.push(actionsObj.verify_domain);
        actions.push(actionsObj.remove);
        break;
      default:
        return;
    }

    return actions.map((action, index) => {
      return (
        <ListItem disablePadding key={index}>
          <ListItemButton onClick={action.action}>
            <ListItemText
              primary={action.title}
              className={
                action.title === remove ? "action-remove" : "action-txt"
              }
            />
          </ListItemButton>
        </ListItem>
      );
    });
  };

  const renderButton = () => {
    return (
      <Button
        icon="addIcon"
        label={addDomain}
        variant="outlined"
        color="primary"
        className="add-icon"
        onClick={() => {
          formik.resetForm();
          setModalName("addDomain");
          setOpenAddModal(true);
        }}
        disabled={rows.length >= 4}
      />
    );
  };

  const renderDomainErrorMessage = () => {
    return (
      <span className="error-msg-add-domain">
        {validationMsgHeading}
        <br />
        <ol style={{ padding: "0px 0px 0px 19px" }}>
          {validationMsgArr.map((err, index) => {
            return <li key={index}>{err}</li>;
          })}
        </ol>
        {validationMsg}
      </span>
    );
  };

  return (
    <>
      <Box className="action-btn-wrapper mui-fixed">
        {rows.length >= 4 ? (
          <CustomTooltip
            arrow={true}
            placement="left"
            text={addMoreDomainValidation}
            className="custom-tooltip"
          >
            <Box sx={{ width: "fit-content", boxShadow: "none" }}>
              {renderButton()}
            </Box>
          </CustomTooltip>
        ) : (
          renderButton()
        )}
      </Box>
      <Box className="main-content">
        <CustomTable {...tableProps} />
      </Box>
      <CustomPopper
        disablePortal={true}
        open={openPopper}
        anchorEl={anchorEl}
        position="bottom"
        handleClose={handleClose}
      >
        <List>{renderAction()}</List>
      </CustomPopper>

      {/*Add domain modal */}
      <Modal
        className="add-email-domain"
        maxWidth="xs"
        body={
          <EmailDomainModalContent
            errorMsg={renderDomainErrorMessage()}
            values={values}
            handleSubmit={(_value) => setSubmitting(_value)}
            handleTouched={(_value) =>
              setTouched({ ...touched, email_domain: _value })
            }
            handleSetFieldValue={(_value) =>
              setFieldValue("email_domain", _value)
            }
            touched={touched}
            errors={errors}
            label={domain}
            disabled={modalLoader}
            handleEnterKey={(value) => {
              value === 13 && formik.handleSubmit();
            }}
          />
        }
        footer={footer}
        handleClose={() => {
          setOpenAddModal(false);
        }}
        open={openAddModal}
        title={addDomain}
        closeIcon={true}
      />

      {/*Dns records modal */}
      {openAddRecordModal && (
        <VerifyDnsRecordsModal
          isValidateRecords={isValidateRecords}
          isDisplayContentHeader={isDisplayContentHeader}
          handleOnClickContinue={handleOnClickContinue}
          setOpenAddRecordModal={(value) => setOpenAddRecordModal(value)}
          modalLoader={modalLoader}
          isError={isError}
          openAddRecordModal={openAddRecordModal}
          currentRow={currentRow || {}}
          failedValidationCount={failedValidationCount}
          dnsRecords={dnsRecords}
          errorObj={errorObj}
        />
      )}

      {/*Remove domain modal */}
      <Modal
        className="remove-domain"
        maxWidth="xs"
        body={removeDomainContent}
        footer={removeDomainFooter}
        handleClose={() => {
          setOpenRemoveRecordModal(false);
        }}
        open={openRemoveRecordModal}
        title={removeDomain(currentRow?.domain)}
        closeIcon={true}
      />

      {/*Send test mail domain*/}
      <Modal
        className="set-sending-mail"
        maxWidth="sm"
        body={
          <EmailDomainModalContent
            errorMsg={invalidEmail}
            values={values}
            handleSubmit={(_value) => setSubmitting(_value)}
            handleTouched={(value) =>
              setTouched({ ...touched, email_domain: value })
            }
            handleSetFieldValue={(value) =>
              setFieldValue("email_domain", value)
            }
            touched={touched}
            errors={errors}
            label={emailAddress}
            placeholder={emailPlaceholder}
            handleEnterKey={(value) => {
              value === 13 && formik.handleSubmit();
            }}
            disabled={modalLoader}
          />
        }
        footer={setSendTestMailFooter}
        handleClose={() => {
          setOpenTestMailPopUp(false);
        }}
        open={openTestMailPopUp}
        title={`${sendTestEmailTitle} "${currentRow?.domain}"`}
        closeIcon={true}
      />

      {/*Set as sending domain modal */}
      <Modal
        className="sending-domain"
        maxWidth="sm"
        body={sendingDomainContent}
        footer={sendingDomainFooter}
        handleClose={() => {
          setOpenSendingDomain(false);
        }}
        open={openSendingDomain}
        title={
          <span className="header-style">
            {sendingDomain(currentRow?.domain)}
          </span>
        }
        closeIcon={true}
      />

      <ConfirmationModal
        openConfirmation={openConfirmation}
        handleCloseModal={(value) => setOpenConfirmation(value)}
        domain={currentRow?.domain}
        email={testEmail}
        action={confirmationAction}
      />
    </>
  );
};

export default EmailDomains;
