import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import Text from "@speed/common/src/components/Text/Text";
import { Input } from "@speed/common/src/components/Input/Input";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import { currency } from "@speed/common/src/components/currency";
import { btcToSatsAmount } from "@speed/common/src/components/constants";
import {
  enterInvoicePlaceholder,
  invalidAddressMsg,
  invalidLnUrlErrMsg,
  lightning,
  lnurl,
  onchain,
  or,
  recipientLabel,
  sendPaymentLabel,
} from "../messages";
import {
  callAPIInterface,
  verifyAddress,
  handleSplitWithdrawRequest,
  instantSendAllowedLnUrl,
  instantSendSupportedCurrency,
  isValidAddress,
} from "../constants";
import {
  setInstantSendNextClicked,
  showToast,
} from "../../redux/common/actions";
import PaymentModeModal from "../MyAssets/SendPayment/PaymentModeModal";
import SendUploadImage from "../MyAssets/SendPayment/SendUploadImage";

const InstantSendFirstStep = ({
  formik,
  setDisableAmount,
  setDisableCurrency,
  setDecodeResponse,
  setStep1Complete,
  setBtnLoader,
  setAmountLimits,
  setSatsAmount,
  setIsUploadedFromQR,
  handleNext,
  btnLoader,
}) => {
  const dispatch = useDispatch();
  const liveMode = useSelector((state) => state.auth.liveMode);
  const instantSendNextClicked = useSelector(
    (state) => state.common.instantSendNextClicked
  );

  const [isOpenPaymentModeModal, setIsOpenPaymentModeModal] = useState(false);
  const [imageError, setImageError] = useState("");
  const [dataFromSplitURL, setDataFromSplitURL] = useState({});

  const { values, errors, setFieldValue, resetForm, setTouched, touched } =
    formik;

  const handleAddressVerificationOnDecode = (res) => {
    const { withdraw_method, withdraw_request, tag = "", currency } = res;
    const invalidBtc = withdraw_request.startsWith("tap");

    // Check for the invalid address in instant send
    if (!instantSendSupportedCurrency.includes(currency) || invalidBtc) {
      return { isAllowed: false, message: invalidAddressMsg };
    }

    // Helper function to check address expiration and env checks with network and selected mode i.e [test/live]
    const { isAllowed, message } = verifyAddress(res, liveMode);
    if (!isAllowed && message) {
      return { isAllowed, message };
    }

    // Check if withdraw_method of LN URL; Instant send only supports the payRequest.
    if (withdraw_method === lnurl && !instantSendAllowedLnUrl.includes(tag)) {
      return { isAllowed: false, message: invalidLnUrlErrMsg };
    }

    return { isAllowed: true, message: "" };
  };

  const setExtractedDataFromAddress = (address, amount, note) => {
    amount && setFieldValue("amount", amount);
    note && setFieldValue("note", note);
    setFieldValue("formatted_request", address);
  };

  const handleCasesForWithdrawMethod = (method, decodeAmt, res) => {
    switch (method) {
      case lightning:
        setDisableCurrency(Boolean(decodeAmt));
        setDisableAmount(Boolean(decodeAmt)); // To set boolean value to state for disabling the amount field
        !decodeAmt && setAmountLimits({ min: 1, max: btcToSatsAmount(5) });
        break;
      case onchain:
        setAmountLimits({ min: 1000, max: btcToSatsAmount(5) });
        break;
      case lnurl: {
        const { max_sendable, min_sendable } = res;
        if (max_sendable === min_sendable) {
          setDisableCurrency(true);
          setDisableAmount(true);
        } else setAmountLimits({ min: min_sendable, max: max_sendable });
        setFieldValue("amount", min_sendable);
        break;
      }
    }
  };

  const handleDecode = async (address, amount, note) => {
    setBtnLoader(true);
    const isValid = isValidAddress(address);
    if (isValid) {
      try {
        const data = { withdraw_request: address };
        const res = await callAPIInterface(
          "POST",
          "/withdraws/decode",
          JSON.stringify(data)
        );
        const { isAllowed, message } = handleAddressVerificationOnDecode(res);
        if (isAllowed) {
          const {
            withdraw_method: method,
            amount: decodeAmt,
            description,
          } = res;
          const finalAmount = decodeAmt || (amount && btcToSatsAmount(amount));
          setExtractedDataFromAddress(
            address,
            finalAmount,
            description || note
          );

          // store sats amount in state; For now amount will be always in SATS from the response
          finalAmount && setSatsAmount(finalAmount);
          setFieldValue("currency", currency[1]);
          setDecodeResponse({ ...res, decodeAmt: finalAmount });

          // Helper function to enable/disable Currency and Amount field; Also to set Validations messages
          handleCasesForWithdrawMethod(method, finalAmount, res);
          setStep1Complete(true);
        } else {
          dispatch(
            showToast({
              isToastOpen: true,
              toastMessage: message,
              toastVariant: "error",
            })
          );
          resetForm();
        }
      } catch (e) {}
      setBtnLoader(false);
    }
  };

  const processNextBtn = (invoice = "") => {
    if (invoice) setIsUploadedFromQR(true);
    const {
      amount = 0,
      addresses,
      note,
    } = handleSplitWithdrawRequest(values.withdraw_request || invoice);

    const dataObj = { amount: btcToSatsAmount(amount), note };
    if (Object.keys(addresses).length > 1) {
      setDataFromSplitURL({ addresses, ...dataObj });
      setIsOpenPaymentModeModal(true);
    } else {
      const extractedAddress = Object.values(addresses)[0];
      handleDecode(extractedAddress, amount, note);
    }
    dispatch(setInstantSendNextClicked(false));
  };

  useEffect(() => {
    instantSendNextClicked && processNextBtn();
  }, [instantSendNextClicked]);

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const handleSelectMode = (value) => {
    if (value) {
      const { addresses, amount, note } = dataFromSplitURL;
      const extractedAddress = addresses[value] || addresses?.default;
      setIsOpenPaymentModeModal(false);
      handleDecode(extractedAddress, amount, note);
    }
  };

  return (
    <Box component="div" display="flex" justifyContent="center">
      <Box
        component="div"
        width="599px"
        className="margin-top30 margin-bottom30"
      >
        <Text size={20} className="default-text" variant="h3" font="semibold">
          {sendPaymentLabel}
        </Text>
        <Input
          disabled={btnLoader}
          name="withdraw_request"
          value={values.withdraw_request}
          showLabel={true}
          label={recipientLabel}
          fullWidth
          onChange={(e) => setFieldValue("withdraw_request", e.target.value)}
          onKeyDown={(event) =>
            event.keyCode === 13 && values.withdraw_request && handleNext()
          }
          customClass={`margin-top30 ${
            !errors.withdraw_request && "margin-bottom30"
          }`}
          placeholder={enterInvoicePlaceholder}
          onBlur={() => setTouched({ ...touched, withdraw_request: true })}
          error={touched.withdraw_request && Boolean(errors.withdraw_request)}
        />
        <InputErrorMessage
          {...defaultErrorMessageProps}
          inputName="withdraw_request"
          sx={{ mb: "14px" }}
        />
        <Text
          size={16}
          font="regular"
          className="grey-text"
          variant="h6"
          align="center"
          sx={{ m: "20px 0" }}
        >
          {or}
        </Text>
        <SendUploadImage
          handleSubmit={processNextBtn}
          setImageError={setImageError}
          imageError={imageError}
          customClass="instant-send-upload-qr"
          disabled={btnLoader}
        />
        {isOpenPaymentModeModal && (
          <PaymentModeModal
            handleModalClose={() => setIsOpenPaymentModeModal(false)}
            openModal={isOpenPaymentModeModal}
            setSelectedMode={handleSelectMode}
          />
        )}
      </Box>
    </Box>
  );
};

export default InstantSendFirstStep;
