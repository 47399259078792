import { Grid } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import React, { useEffect, useState } from "react";
import { billFrom, billTo, shipTo } from "../messages";
import { useSelector } from "react-redux";
import { concatedMobileNumber, getAddressData } from "../constants";
import { isEmpty } from "lodash";
import { formatPhoneNumberIntl } from "react-phone-number-input";

const BillingInfo = ({ customer }) => {
  const currentAccount = useSelector((state) => state.auth.currentAccount);
  const [businessAddress, setBusinessAddress] = useState(null);

  useEffect(() => {
    if (!isEmpty(currentAccount?.account?.account_public_info)) {
      const {
        line1,
        line2,
        city,
        state,
        postal_code,
        country,
        email,
        phone_number,
        business_name,
      } = currentAccount.account.account_public_info;
      const businessAddress = {
        billing: {
          address: { line1, line2, city, state, zipcode: postal_code, country },
        },
        email,
        phone_number,
        business_name,
      };
      setBusinessAddress(businessAddress);
    }
  }, [currentAccount]);

  const renderBillingInfo = (
    heading,
    name,
    address,
    email = null,
    contactInfo = null
  ) => {
    return (
      <>
        <Text
          size={8}
          font="regular"
          variant="h6"
          className="grey-text"
          sx={{ marginBottom: "5px" }}
        >
          {heading}
        </Text>
        <Text
          size={11}
          font="semibold"
          variant="h6"
          className="default-text"
          sx={{ marginBottom: "5px" }}
        >
          {name}
        </Text>
        {address && (
          <Text
            size={10}
            font="regular"
            variant="h6"
            className="grey-text"
            sx={{ marginBottom: "5px" }}
          >
            {address
              ? (Object.values(address).filter((add) => add !== null).length &&
                  Object.values(address)
                    .filter((add) => add !== "")
                    .join(",")) ||
                "-"
              : "-"}
          </Text>
        )}
        {email && (
          <Text size={10} font="regular" variant="h6" className="default-text">
            {email}
          </Text>
        )}
        {contactInfo && (
          <Text
            size={10}
            font="regular"
            variant="h6"
            className="default-text"
            sx={{ marginBottom: "5px" }}
          >
            {contactInfo}
          </Text>
        )}
      </>
    );
  };

  return (
    <Grid container className="pl-20 billing-info">
      <Grid item lg={4} className="billing-info-col">
        {renderBillingInfo(
          billFrom,
          businessAddress?.business_name,
          businessAddress && getAddressData(businessAddress, "billing"),
          businessAddress?.email,
          formatPhoneNumberIntl(businessAddress?.phone_number)
        )}
      </Grid>
      <Grid item lg={4}>
        {renderBillingInfo(
          billTo,
          customer && customer?.name,
          customer && getAddressData(customer, "billing"),
          customer?.billing?.email,
          formatPhoneNumberIntl(
            concatedMobileNumber(customer?.calling_code, customer?.phone)
          )
        )}
      </Grid>

      <Grid item lg={4}>
        {renderBillingInfo(
          shipTo,
          customer && customer?.shipping?.name,
          customer && getAddressData(customer, "shipping"),
          customer?.shipping?.email,
          formatPhoneNumberIntl(
            concatedMobileNumber(
              customer?.shipping?.calling_code,
              customer?.shipping?.phone
            )
          )
        )}
      </Grid>
    </Grid>
  );
};

export default BillingInfo;
