import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import CommonHeader from "../Common/CommonHeader";
import AllWithdraws from "./AllWithdraws";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import "../../assets/styles/payments.scss";
import { useLocation } from "react-router-dom";
import {
  selectTabOnSearch,
  setQueryParamsForTab,
} from "@speed/common/src/components/constants";
import { withdrawStatus } from "../constants";

function Withdraw() {
  const search = useLocation().search;

  const [selectedTab, setSelectedTab] = useState("1");
  const history = useSelector((state) => state.common.history);

  const tabData = [
    {
      label: "All",
      value: "1",
      tabContent: <AllWithdraws />,
      tabName: "all",
    },
    {
      label: withdrawStatus.paid.label,
      value: "2",
      tabContent: <AllWithdraws tab={[withdrawStatus.paid.value]} />,
      tabName: withdrawStatus.paid.value,
    },
    {
      label: withdrawStatus.unpaid.label,
      value: "3",
      tabContent: <AllWithdraws tab={[withdrawStatus.unpaid.value]} />,
      tabName: withdrawStatus.unpaid.value,
    },
    {
      label: withdrawStatus.failed.label,
      value: "4",
      tabContent: <AllWithdraws tab={[withdrawStatus.failed.value]} />,
      tabName: withdrawStatus.failed.value,
    },
    // {
    //   label: withdrawStatus.cancelled.label,
    //   value: "4",
    //   tabContent: <AllWithdraws tab={[withdrawStatus.cancelled.value]} />,
    //   tabName: withdrawStatus.cancelled.value,
    // },
  ];

  const handleChange = (_event, newValue) => {
    setSelectedTab(newValue);
    const selectedTabName = setQueryParamsForTab(tabData, newValue);
    handleSetQueryParam(selectedTabName);
  };

  const handleSetQueryParam = (searchValue) => {
    history.push({
      pathname: "/withdraws",
      search: searchValue ? `?status=${searchValue}` : null,
    });
  };

  useEffect(() => {
    //used when user searches manually
    const name = new URLSearchParams(search).get("status");
    selectTabOnSearch(name || null, tabData, setSelectedTab);
    handleSetQueryParam(name || null); // To set query params
  }, []);

  return (
    <Box className="section-wrapper">
      <CommonHeader />
      <Box className="payments-wrapper">
        <CustomTab
          onChange={handleChange}
          tabData={tabData}
          selectedTab={selectedTab}
          noScroll={true}
          // setTabRoute={(routeUrl) => { history.push(routeUrl) }}
        />{" "}
      </Box>
    </Box>
  );
}

export default Withdraw;
