import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import {
  RouteBreadcrumbs,
  callAPIInterface,
  renderStatus,
  activateDeactivateCheckoutLink,
  updateMetadata,
  moduleMetaDataRows,
  getCashbackDetails,
  getTypeOfCashback,
  detailPageCFRender,
  dateTimeFormatInApp,
} from "../constants";
import {
  clipboardElement,
  linkStatus,
  getCurrencyObj,
  redirectWhenEntityNotFound,
  generateBreadcrumbs,
  showAmount,
} from "@speed/common/src/components/constants";
import { Grid, TableCell, TableRow } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import {
  payment,
  checkoutLink,
  deActivate,
  entityNotFoundMessage,
  events as eventsText,
  metaDataText,
  edit,
  add,
  cashbackLabel,
  cashbackIDLabel,
  billingAndShipping,
  billingText,
  yes,
  detailCustomFieldLabel,
  pageDescLabel,
  imageLabel,
  collectAddresses,
  collectEmailAddress,
  collectPhoneNumber,
  sats,
  btc,
} from "../messages";
import { useSelector } from "react-redux";
import "../../assets/styles/payment-links.scss";
import Button from "@speed/common/src/components/Button/Button";
import WarningIcon from "@mui/icons-material/Warning";
import { events } from "../Events/Events";
import { useFlags } from "launchdarkly-react-client-sdk";
import MetaDataForm from "../MetaDataForm";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import DetailPagePaymentSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import LinkSkeleton from "@speed/common/src/components/Skeleton/LinkSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";
import { pageTitleLabel } from "@speed/common/src/messages";
import PaymentDetailTitle from "@speed/common/src/components/PaymentDetailTitle";
import PaymentDetailDescription from "@speed/common/src/components/PaymentDetailDescription";

const paymentColumnData = [
  { title: "Payment ID", width: "480px" },
  { title: "Amount", width: "135px", align: "right" },
  { title: "Date", width: "230px" },
  { title: "Status", width: "120px" },
];

const PaymentLinkDetails = (props) => {
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const liveMode = useSelector((state) => state.auth.liveMode);
  const history = useSelector((state) => state.common.history);

  const [isLinkActive, setLinkActive] = useState(null);
  const [data, setData] = useState(null);
  const [paymentList, setPaymentList] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [paymentSkeleton, setPaymentSkeleton] = useState(false);
  const [eventSkeleton, setEventSkeleton] = useState(true);
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [metaDataSkeleton, setMetaDataSkeleton] = useState(true);
  const [isMetaDataEditable, setIsMetaDataEditable] = useState(false);
  const [metaDataList, setMetaDataList] = useState([]);
  const [cashbackData, setCashbackData] = useState([]);
  const [checkoutLinkCashbackLoader, setCheckoutLinkCashbackLoader] =
    useState(false);
  const [disableActionButton, setDisableActionButton] = useState(false);

  const urlParams = useParams();
  const { clEventFe, clPaymentlistFe, clMetadataFe, clCustomfieldsFe } =
    useFlags();

  const loadDetailPage = () => {
    setData(null);
    setIsShowNotFound(false);
    getCheckoutLinkDetail();
  };

  useEffect(() => {
    loadDetailPage();
  }, [liveMode, urlParams?.id, clEventFe]);

  useEffect(() => {
    if (clMetadataFe && data?.metadata) {
      const convertedMetaDataToArray = Object.entries(data?.metadata)?.map(
        (e) => ({
          key: e[0],
          value: e[1],
        })
      );
      setMetaDataList(convertedMetaDataToArray);
      setMetaDataSkeleton(false);
    }
  }, [clMetadataFe, data?.metadata]);

  const getPaymentList = () => {
    callAPIInterface("GET", `/checkout-links/${urlParams?.id}/payments`)
      .then((res) => {
        if (res) {
          setPaymentSkeleton(false);
          setPaymentList(res?.data);
        }
      })
      .catch(() => {
        setPaymentSkeleton(false);
      });
  };

  const getEventsList = () => {
    setEventSkeleton(true);
    const filterEvents = {
      ids: [urlParams?.id],
    };
    callAPIInterface("POST", "/events/filters", JSON.stringify(filterEvents))
      .then((res) => {
        if (res) {
          setEventSkeleton(false);
          setEventsList(res?.data);
        }
      })
      .catch(() => {
        setEventSkeleton(false);
      });
  };

  const getCheckoutLinkCashbackData = (cashbackId) => {
    setCheckoutLinkCashbackLoader(true);
    getCashbackDetails(cashbackId)
      .then((res) => {
        setCashbackData(res);
        setCheckoutLinkCashbackLoader(false);
      })
      .catch(() => {
        setCheckoutLinkCashbackLoader(false);
      });
  };

  const getCheckoutLinkDetail = () => {
    setPaymentSkeleton(true);
    setMetaDataSkeleton(true);
    callAPIInterface("GET", `/checkout-links/${urlParams.id}`)
      .then((res) => {
        if (res) {
          if (res?.cashback?.id) {
            getCheckoutLinkCashbackData(res.cashback.id);
          }

          clEventFe && getEventsList();
          if (res?.payment_id) {
            getPaymentList();
          } else {
            setPaymentSkeleton(false);
          }
          setData(res);
          setLinkActive(res?.status === "active" || false);
        }
      })
      .catch((err) => {
        setPaymentSkeleton(false);
        setPaymentSkeleton(false);

        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const handleActiveDeActive = (status) => {
    if (!status) {
      setDisableActionButton(true);
      activateDeactivateCheckoutLink(urlParams.id).then((res) => {
        if (res) {
          setDisableActionButton(false);
          loadDetailPage();
        }
      });
    }
  };

  const detailsTableLeftSideData = () => {
    const { title, title_description } = data;
    const arr = [
      {
        header: "Status",
        cell: (
          <Tag
            text={linkStatus[data?.status].label}
            variant={linkStatus[data?.status].variant}
          />
        ),
      },
    ];

    data?.cashback?.id &&
      arr.push({
        header: cashbackIDLabel,
        cell: clipboardElement(data.cashback.id, true, "", "text", true),
      });

    title &&
      arr.push({
        header: pageTitleLabel,
        headerStyle: { verticalAlign: "top" },
        cell: <PaymentDetailTitle pageTitle={title} fromDetails={true} />,
      });

    title_description &&
      arr.push({
        header: pageDescLabel,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <PaymentDetailDescription
            pageDescription={title_description}
            isFromDetail={true}
            boxStyle={{ maxHeight: "120px !important" }}
          />
        ),
      });

    return arr;
  };

  const headerContent = () => {
    return (
      <Box className="header-content">
        <Text className="default-text" size={28}>
          {checkoutLink}
        </Text>

        <Box className="header-price-content">
          <Box className="fiat-price">
            <Text className="default-text" size={20}>
              {getCurrencyObj(data.currency).symbol}
              {showAmount({
                amount: data?.amount,
                currency: data?.currency,
                multiplier: 1,
                showCommaSeparated: true,
              })}
            </Text>
          </Box>
        </Box>

        <Box sx={{ marginTop: "15px" }}>
          {clipboardElement(
            data?.url,
            isLinkActive,
            "branding-detail",
            "",
            true
          )}
        </Box>
      </Box>
    );
  };

  const rows =
    paymentList &&
    paymentList.map((rowItem) => (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/payments/${rowItem?.id}`)}
      >
        <TableCell>
          {clipboardElement(rowItem?.id, true, "branding-detail", "text", true)}
        </TableCell>
        <TableCell align="right">
          {showAmount({
            amount:
              rowItem.status === "paid"
                ? rowItem.target_amount_paid
                : rowItem.target_amount,
            currency: rowItem.target_currency,
            targetedCurrency:
              rowItem.target_currency === sats ? btc : rowItem.target_currency,
            appendCurrency: true,
          })}
        </TableCell>
        <TableCell align="left">
          {dateTimeFormatInApp(rowItem?.created)}
        </TableCell>
        <TableCell align="left">{renderStatus(rowItem?.status)}</TableCell>
      </TableRow>
    ));

  const eventRows = eventsList?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/events/${rowItem?.id}`)}
      >
        <TableCell>{events(rowItem)}</TableCell>
        <TableCell align="right" sx={{ color: "#848b9e" }}>
          {dateTimeFormatInApp(rowItem?.created)}
        </TableCell>
      </TableRow>
    );
  });

  const onSubmit = (payload) => {
    setMetaDataSkeleton(true);
    const finalPayload = { metadata: payload };
    updateMetadata(finalPayload, `/checkout-links/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setData(res);
        }
        setMetaDataSkeleton(false);
        setIsMetaDataEditable(false);
      })
      .catch((err) => {
        setMetaDataSkeleton(false);
        setIsMetaDataEditable(false);
      });
  };

  const onCancel = () => setIsMetaDataEditable(false);

  const renderCheckoutDetailContent = () => {
    const verticalTableData = {
      rowData: detailsTableLeftSideData(),
    };
    verticalTableData.rightSideRowData = [
      {
        header: "Date created",
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {dateTimeFormatInApp(data?.created)}
          </Text>
        ),
      },
    ];

    if (data?.cashback?.id) {
      const typeOfCashback = getTypeOfCashback(cashbackData);
      verticalTableData.rightSideRowData.push({
        header: cashbackLabel,
        cell:
          !checkoutLinkCashbackLoader && cashbackData?.name ? (
            <TruncatedTextTooltip
              textValue={`${cashbackData.name} - (${typeOfCashback})`}
              cellWidth="300px"
              textProps={{ className: "default-text" }}
            />
          ) : (
            <CommonSkeleton width="100%" sx={{ borderRadius: "8px" }} />
          ),
      });
    }
    data?.title_image &&
      verticalTableData.rightSideRowData.push({
        header: imageLabel,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <img
            src={data.title_image}
            className="pl-preview-image link-detail"
            alt="page-image"
          />
        ),
      });
    const resultArr = [];
    if (
      data?.customer_collections_status?.is_shipping_address_enabled ||
      data?.customer_collections_status?.is_billing_address_enabled
    ) {
      resultArr.push({
        header: collectAddresses,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <Text
            size={16}
            className="default-text"
            variant="subtitle1"
            font="regular"
          >
            {data?.customer_collections_status?.is_shipping_address_enabled
              ? billingAndShipping
              : billingText}
          </Text>
        ),
      });
    }
    if (data?.customer_collections_status?.is_email_enabled) {
      resultArr.push({
        header: collectEmailAddress,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <Text
            size={16}
            className="default-text"
            variant="subtitle1"
            font="regular"
          >
            {yes}
          </Text>
        ),
      });
    }
    if (data?.customer_collections_status?.is_phone_enabled) {
      resultArr.push({
        header: collectPhoneNumber,
        headerStyle: { verticalAlign: "top" },
        cell: (
          <Text
            size={16}
            className="default-text"
            variant="subtitle1"
            font="regular"
          >
            {yes}
          </Text>
        ),
      });
    }

    // custom field details
    if (data?.custom_fields?.length > 0 && clCustomfieldsFe)
      resultArr.push({
        header: detailCustomFieldLabel,
        headerStyle: { verticalAlign: "top" },
        cell: detailPageCFRender(data?.custom_fields),
      });

    verticalTableData.rowData = [...verticalTableData.rowData, ...resultArr];

    return (
      <Box className="details-content" marginTop="60px">
        <VerticalTable label="Details" {...verticalTableData} />
      </Box>
    );
  };

  const renderActivateDeActivateButton = () => {
    if (isLinkActive) {
      return (
        <Button
          icon="closeIcon"
          label={deActivate}
          variant="outlined"
          color="error"
          onClick={() => {
            handleActiveDeActive(false);
          }}
          disabled={disableActionButton}
        />
      );
    } else {
      return null;
      // return <Button
      //     icon="checkIcon"
      //     label={activate}
      //     variant="outlined"
      //     onClick={() => {
      //       handleActionClick(true);
      //     }}
      //   />
    }
  };

  return !isShowNotFound ? (
    <Grid className={`payment-link-detail-wrapper`} container spacing={1}>
      <Box className="section-wrapper" sx={{ position: "relative" }}>
        {data && (
          <Box
            className="action-btn-wrapper"
            style={{ top: "23px", right: "2px" }}
          >
            {renderActivateDeActivateButton()}
          </Box>
        )}{" "}
        {/*for future use */}
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        {data ? (
          <>
            {headerContent()}
            {renderCheckoutDetailContent()}
          </>
        ) : (
          <LinkSkeleton props={props} />
        )}
        {clPaymentlistFe && (
          <>
            {!paymentSkeleton ? (
              <Box className="product-content">
                <HorizontalTable
                  label={payment}
                  isColumnShown={rows.length > 0}
                  isShowButton={rows.length > 3}
                  columns={paymentColumnData}
                  rows={rows}
                  rowsPerPage={3}
                  isShowPagination={false}
                  tableBodyClassName="border-none"
                  handleViewAllClick={() => {
                    history.push(`/checkout-links/${data?.id}/payments`);
                  }}
                />
              </Box>
            ) : (
              <DetailPagePaymentSkeleton />
            )}
          </>
        )}
        {clMetadataFe && (
          <>
            {!metaDataSkeleton ? (
              <Box className="product-content meta-data-content">
                {isMetaDataEditable ? (
                  <MetaDataForm
                    metaDataList={metaDataList}
                    setIsMetaDataEditable={setIsMetaDataEditable}
                    onSubmit={onSubmit}
                    onCancel={onCancel}
                  />
                ) : (
                  <HorizontalTable
                    label={metaDataText}
                    rows={moduleMetaDataRows(metaDataList)}
                    rowsPerPage={51}
                    isColumnShown={moduleMetaDataRows(metaDataList)?.length > 0}
                    tableBodyClassName="border-none"
                    isShowButton={!isMetaDataEditable}
                    displayShowButton={true}
                    viewAllButtonLabel={metaDataList?.length > 0 ? edit : add}
                    viewAllButtonIcon="editIcon"
                    handleViewAllClick={() => setIsMetaDataEditable(true)}
                  />
                )}
              </Box>
            ) : (
              <DetailPagePaymentSkeleton />
            )}
          </>
        )}
        {clEventFe && (
          <>
            {!eventSkeleton ? (
              <Box className="product-content">
                <HorizontalTable
                  label={eventsText}
                  isColumnShown={eventRows?.length > 0}
                  rows={eventRows}
                  rowsPerPage={3}
                  handleViewAllClick={() => {
                    history.push(`/checkout-links/${urlParams?.id}/events`);
                  }}
                  isShowButton={eventRows?.length > 3}
                  tableBodyClassName="border-none"
                />
              </Box>
            ) : (
              <DetailPagePaymentSkeleton />
            )}
          </>
        )}
      </Box>
    </Grid>
  ) : (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage("Link")}
        subTitle={`Checkout link ${urlParams.id} is not found.`}
        btnText={`View ${(liveMode && "live") || "test"} mode links`}
      />
    </Box>
  );
};

export default PaymentLinkDetails;
