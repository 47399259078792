import { Box, CardHeader, Skeleton } from "@mui/material";
import Card from "@mui/material/Card";
import Button from "@speed/common/src/components/Button/Button";
import { satsToBtcAmount } from "@speed/common/src/components/constants";
import Text from "@speed/common/src/components/Text/Text";
import classNames from "classnames";
import { downArrowCircle, signoutIcon, upArrowCircle } from "../images";
import { accountBalance } from "../messages";

function DashboardCard({
  height,
  width,
  title,
  className,
  customStyle,
  data,
  loader,
  buttonLabel,
  handleButtonClick,
  showButton = true,
}) {
  const getImgSrc = (value) => {
    if (!value) {
      return signoutIcon;
    }

    return value > 0 ? upArrowCircle : downArrowCircle;
  };

  const getBalanceText = (balance) => {
    if (balance === undefined) {
      return "-";
    }

    return balance ? `${satsToBtcAmount(balance)} BTC` : "0 BTC";
  };

  const prepareChangeBalance = () => {
    const { change_in_val, change_in_per, balance_now } = data || {};
    if (balance_now || change_in_val) {
      return (
        <>
          <img
            src={getImgSrc(change_in_val)}
            alt="change-value"
            className="change-value-icon"
          />
          <Text
            variant="h2"
            font="regular"
            className="balance-card-change-text"
          >
            {change_in_val && change_in_per
              ? `${satsToBtcAmount(change_in_val)}(${change_in_per.toFixed(
                  2
                )}%)`
              : "0(0.00%)"}
          </Text>
          <Text
            variant="h2"
            size={12}
            font="regular"
            className="balance-card-change-days"
          >
            , In last 7 days
          </Text>
        </>
      );
    }

    return null;
  };

  const prepareCardContent = () => {
    switch (title) {
      case accountBalance:
        const { balance_now } = data || {};
        return (
          <Box sx={{ paddingBottom: "25px" }}>
            <Text
              variant="h2"
              size={20}
              font="semibold"
              className="balance-card-text"
            >
              {loader ? (
                <Skeleton animation="wave" width={150} height={20} />
              ) : (
                getBalanceText(balance_now)
              )}
            </Text>

            <Box display="flex" alignItems="center">
              {loader ? (
                <Skeleton animation="wave" width={250} height={22} />
              ) : (
                prepareChangeBalance()
              )}
            </Box>
          </Box>
        );

      default:
        return;
    }
  };
  return (
    <Card
      className={classNames("dashboard-card", className)}
      sx={{
        height: height || "298px",
        width: width || "404px",
        ...customStyle,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box>
        <CardHeader
          title={
            <Text
              variant="h2"
              font="semibold"
              size={20}
              className="dashboard-title"
            >
              {title}
            </Text>
          }
        />
        <Box
          sx={{
            padding: "0 25px",
          }}
        >
          {prepareCardContent()}
        </Box>
      </Box>
      {showButton && (
        <Button
          variant="outlined"
          style={{
            fontSize: "14px",
            padding: "8px 16px",
            margin: "auto  24px 24px",
            width: "fit-content",
          }}
          onClick={handleButtonClick}
          label={buttonLabel}
        />
      )}
    </Card>
  );
}

export default DashboardCard;
