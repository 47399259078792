import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Box, MenuItem } from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { Input } from "@speed/common/src/components/Input/Input";
import {
  cashbackAmountLabel,
  cashbackAmountPlaceholder,
  cashbackAmountRequired,
  cashbackDateRequired,
  cashbackDescriptionPlaceholder,
  cashbackEndDateLabel,
  cashbackExpireInLabel,
  cashbackExpireInPlaceholder,
  cashbackInvalidExpire,
  cashbackNameLabel,
  cashbackNameRequired,
  cashbackStartDateLabel,
  description,
  type,
  cashbackAmountRangevalidation,
  cashbackDatePlaceholder,
  cashbackPercentageLabel,
  expireInCashbackMsg,
  startDateRequiredMsg,
  enterValidDateMsg,
  enterValidPastDateMsg,
  greaterEndDateMsg,
} from "../messages";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import CustomSelect from "@speed/common/src/components/Select/Select";
import { cashbackTypesArray, getTimeZoneAndDateFormat } from "../constants";
import _ from "lodash";
import CustomDatePicker from "@speed/common/src/components/DatePicker";
import Text from "@speed/common/src/components/Text/Text";
import InputMask from "@speed/common/src/components/MaskInput";
import moment from "moment";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import { getTimezoneMomentObj } from "@speed/common/src/components/constants";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";

const CreateCashback = forwardRef((props, ref) => {
  const { timezone: timezoneToUse } = getTimeZoneAndDateFormat();
  const timezone = timezoneToUse || moment.tz.guess();

  const [expireInDaysValidationMessage, setExpireInDaysValidationMessage] =
    useState();
  const [amountValidationMessage, setAmountValidationMessage] = useState();
  const [startDateValidationMessage, setStartDateValidationMessage] =
    useState();
  const [endDateValidationMessage, setEndDateValidationMessage] = useState();
  const [disableStartDate, setDisableStartDate] = useState();
  const [disableEndDate, setDisableEndDate] = useState();

  const validatePercentType = (amountValue) => {
    if (amountValue >= 0.01 && amountValue <= 98.99) {
      return true;
    }
    setAmountValidationMessage(cashbackAmountRangevalidation(true));
  };

  const validateFixedType = (amountValue) => {
    if (amountValue > 0 && amountValue <= 100000000) {
      return true;
    }
    setAmountValidationMessage(cashbackAmountRangevalidation());
  };

  const validateAmount = (amountValue) => {
    if (amountValue) {
      return isPercentageType
        ? validatePercentType(+amountValue)
        : validateFixedType(+amountValue);
    } else {
      setAmountValidationMessage(cashbackAmountRequired(isPercentageType));
    }
  };

  const isValidDate = (momentObj) => moment(momentObj).isValid();
  const isPastDate = (momentObj) =>
    moment(momentObj).diff(moment().startOf("day")) < 0;

  const validateStartDateMoment = (startDateMomentObj) => {
    const isValid = !isValidDate(startDateMomentObj);
    if (isValid) {
      setStartDateValidationMessage(enterValidDateMsg());
      return;
    }

    const isPast = isPastDate(startDateMomentObj);
    if (isPast) {
      setStartDateValidationMessage(enterValidPastDateMsg());
      return;
    }

    return true;
  };

  const validateStartDate = (formkikObj) => {
    const startDateMomentObj = formkikObj?.parent?.start_at;
    if (startDateMomentObj) {
      return validateStartDateMoment(startDateMomentObj);
    } else {
      setStartDateValidationMessage(startDateRequiredMsg);
    }
  };

  const validateEndDateMoment = (endDateMomentObj, startDateMomentObj) => {
    const isValid = !isValidDate(endDateMomentObj);
    if (isValid) {
      setEndDateValidationMessage(enterValidDateMsg(true));
      return;
    }

    const isPast = isPastDate(endDateMomentObj);
    if (isPast) {
      setEndDateValidationMessage(enterValidPastDateMsg(true));
      return;
    }

    const dateDifference = moment(endDateMomentObj).diff(
      moment(startDateMomentObj),
      "days"
    );
    if (dateDifference < 0) {
      setEndDateValidationMessage(greaterEndDateMsg);
      return;
    }

    return true;
  };

  const validateEndDate = (formkikObj) => {
    const startDateMomentObj = formkikObj?.parent?.start_at;
    const endDateMomentObj = formkikObj?.parent?.end_at;

    if (endDateMomentObj) {
      return validateEndDateMoment(endDateMomentObj, startDateMomentObj);
    } else {
      return true;
    }
  };

  const validateExpireDays = (days) => {
    if (days > 0 && days <= 30) {
      return true;
    }
    setExpireInDaysValidationMessage(cashbackInvalidExpire);
  };

  const validateExpireInDays = (days) => {
    if (days) {
      return validateExpireDays(+days);
    } else {
      return true;
    }
  };

  const validationSchema = yup.object({
    name: yup.string().required(cashbackNameRequired).trim(),
    description: yup.string().trim(),
    amount: yup.mixed().test({
      name: "amount",
      test: (amount) => validateAmount(amount),
    }),
    start_at: yup.mixed().test({
      name: "start_at",
      test: function () {
        return validateStartDate(this);
      },
    }),
    end_at: yup.mixed().test({
      name: "end_at",
      test: function () {
        return validateEndDate(this);
      },
    }),
    expire_in_days: yup.mixed().test({
      name: "expire_in_days",
      test: (expire_in_days) => validateExpireInDays(expire_in_days),
    }),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      type: "fixed_amount",
      amount: null,
      start_at: null,
      end_at: null,
      expire_in_days: null,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    isValid,
    isSubmitting,
    dirty,
    setFieldValue,
    touched,
    setTouched,
  } = formik;

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const isPercentageType = values?.type === "percentage";
  const handleDateChange = (momentDateObj, setDisableDate) => {
    if (isValidDate(momentDateObj)) {
      setDisableDate(moment(momentDateObj));
    } else {
      setDisableDate();
    }
  };

  const datePickerElement = (
    labelText,
    dateValueType,
    setDisableDate,
    placeholder
  ) => {
    const customProps = {
      placeholder,
      disablePast: true,
      value: values[dateValueType],
      setValue: (value) => {
        setFieldValue(dateValueType, value);
      },
      handleDateChange: (momentObj) =>
        handleDateChange(momentObj, setDisableDate),
      onBlur: () => setTouched({ ...touched, [dateValueType]: true }),
    };

    if (timezone) {
      customProps.timezone = timezone;
    }

    if (dateValueType === "start_at") {
      customProps.maxDate = disableEndDate;
    }

    if (dateValueType === "end_at") {
      customProps.minDate = disableStartDate
        ? disableStartDate
        : moment().startOf("day");
    }

    return (
      <Box className="cashback-date-picker margin-top30">
        <Text
          className="grey-text input-with-dropdown-label"
          font="regular"
          size={14}
          sx={{ flex: 1 }}
          variant="h6"
        >
          {labelText}
        </Text>
        <CustomDatePicker {...customProps} />
      </Box>
    );
  };

  const maskInputElement = (
    showTypeBox,
    allowDecimal,
    placeholder,
    label,
    valueType,
    showLabelInfoPopup,
    numOfAllowedDigits
  ) => {
    let maskProps = {
      showTypeBox,
      allowDecimal,
      placeholder,
      label,
      onChange: (e) => setFieldValue(valueType, e.target.value),
      value: values[valueType],
      error: touched[valueType] && Boolean(errors[valueType]),
      onBlur: () => setTouched({ ...touched, [valueType]: true }),
      numOfAllowedDigits,
    };

    if (showTypeBox) {
      maskProps.typeValue = isPercentageType ? "%" : "SATS";
    }
    if (showLabelInfoPopup) {
      maskProps.showLabelInfoPopup = true;
      maskProps.popupContent = (
        <Text variant="inherit" font="regular" size={16}>
          {expireInCashbackMsg}
        </Text>
      );
    }

    return (
      <>
        <Box className="margin-top30">
          <InputMask {...maskProps} />
        </Box>
      </>
    );
  };

  const createFormData = () => {
    const {
      name,
      description,
      amount,
      type,
      start_at,
      end_at,
      expire_in_days,
    } = values;

    let formdata = {
      name,
      type,
      target_currency: "SATS",
      start_at: +getTimezoneMomentObj(start_at, timezone)
        .startOf("day")
        .format("x"),
      timezone,
    };
    if (description) formdata.description = description;

    if (type === "percentage") {
      let amountObj = {
        value: +amount,
      };
      formdata["percentage"] = amountObj;
    } else {
      let amountObj = {
        amount: +amount,
        currency: "SATS",
      };
      formdata["fixed_amount"] = amountObj;
    }
    if (end_at)
      formdata.end_at = +getTimezoneMomentObj(end_at, timezone)
        .endOf("day")
        .format("x");
    if (expire_in_days) formdata.expire_in_days = +expire_in_days;
    return formdata;
  };

  useEffect(() => {
    const disable = !(isValid && dirty) || isSubmitting;
    props.handleButtonDisable(disable);
  }, [dirty, isValid, isSubmitting, values]);

  useImperativeHandle(ref, () => ({
    handleSubmit: createFormData,
  }));

  return (
    <Box className="create-cashback-modal">
      <Box className="cashback-description-section">
        <Input
          type="text"
          name="name"
          inputProps={{ maxLength: 255 }}
          value={values.name}
          customClass="margin-top30"
          showLabel
          label={cashbackNameLabel}
          fullWidth
          placeholder={cashbackNameLabel}
          error={Boolean(errors.name)}
          onBlur={() => setTouched({ ...touched, name: true })}
          onChange={(e) => {
            setFieldValue("name", e.target.value);
          }}
        />
        <InputErrorMessage {...defaultErrorMessageProps} inputName="name" />
        <Box className="margin-top30">
          <TextAreaComponent
            showLabel
            label={description}
            value={values.description}
            maxRows={3}
            minRows={3}
            maxLength={255}
            fullWidth
            error={Boolean(errors.description)}
            onBlur={() => setTouched({ ...touched, description: true })}
            onChange={(e) => {
              setFieldValue("description", e.target.value);
            }}
            placeholder={cashbackDescriptionPlaceholder}
          />
        </Box>
        <CustomSelect
          name="type"
          displayEmpty
          showLabel
          label={type}
          customClass="margin-top30"
          value={values.type}
          MenuProps={{
            id: "cashback-type-select",
          }}
          onChange={(e) => {
            setFieldValue("type", e.target.value);
            setTouched({ ...touched, amount: false });
            setFieldValue("amount", null);
          }}
          renderValue={(value) => {
            if (value) {
              let typeObj = _.find(cashbackTypesArray, {
                value: typeof value === "object" ? value.id : value,
              });
              return typeObj?.label;
            }
          }}
        >
          {cashbackTypesArray.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </CustomSelect>
        {maskInputElement(
          true,
          isPercentageType,
          cashbackAmountPlaceholder(isPercentageType),
          isPercentageType ? cashbackPercentageLabel : cashbackAmountLabel,
          "amount",
          false,
          2
        )}

        {touched.amount &&
          Boolean(errors.amount) &&
          amountValidationMessage && (
            <AlertMessage
              className="margin-top14"
              severity="error"
              message={amountValidationMessage}
            />
          )}

        {datePickerElement(
          cashbackStartDateLabel,
          "start_at",
          setDisableStartDate,
          cashbackDatePlaceholder(true)
        )}

        {touched.start_at && Boolean(errors.start_at) && (
          <AlertMessage
            className="margin-top14"
            severity="error"
            message={
              startDateValidationMessage
                ? startDateValidationMessage
                : cashbackDateRequired()
            }
          />
        )}

        {datePickerElement(
          cashbackEndDateLabel,
          "end_at",
          setDisableEndDate,
          cashbackDatePlaceholder()
        )}
        {touched.end_at &&
          Boolean(errors.end_at) &&
          endDateValidationMessage && (
            <AlertMessage
              className="margin-top14"
              severity="error"
              message={endDateValidationMessage}
            />
          )}

        {maskInputElement(
          false,
          false,
          cashbackExpireInPlaceholder,
          cashbackExpireInLabel,
          "expire_in_days",
          true
        )}
        {touched.expire_in_days &&
          Boolean(errors.expire_in_days) &&
          expireInDaysValidationMessage && (
            <AlertMessage
              className="margin-top14"
              severity="error"
              message={expireInDaysValidationMessage}
            />
          )}
      </Box>
    </Box>
  );
});

export default CreateCashback;
