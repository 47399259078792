import { serverErrorStatusCodes } from "@speed/common/src/components/constants";
import * as Types from "./types";
import moment from "moment";
import {
  callAPIInterfaceConnect,
  expireSession,
  getTimestamp,
} from "../../components/constants";
import { sessionService } from "redux-react-session";
import history from "@speed/common/src/components/history";

export const verifyUser = (data) => async () => {
  return await callAPIInterfaceConnect(
    "POST",
    "/verify-user",
    JSON.stringify(data)
  )
    .then((res) => res)
    .catch((error) => {
      return serverErrorStatusCodes.includes(error) ? error : false;
    });
};

export const setUpdatedSessionData = async (response, authData = null) => {
  let currentSession = null;
  await sessionService
    .loadSession()
    .then((res) => (currentSession = res))
    .catch((_e) => {});
  const updatedSession = {
    ...currentSession,
    ...response,
    last_action_time: moment().valueOf(),
  };
  if (authData?.email) updatedSession.email = authData.email;
  await sessionService.saveSession(updatedSession);
};

export const setInitialState = (data) => async (dispatch) => {
  dispatch({
    type: Types.SET_INITIAL_DATA,
    payload: data,
  });
};

export const setIsLoggedIn = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_IS_LOGGED_IN,
    payload: data,
  });
};

export const updateUser = (data) => async (dispatch) => {
  dispatch({
    type: Types.SET_USER,
    payload: data,
  });
};

export const getUser = () => (dispatch) => {
  return callAPIInterfaceConnect("GET", "/user", "")
    .then(async (response) => {
      if (response) {
        dispatch(updateUser(response));
        const session = await sessionService.loadSession();
        await sessionService
          .saveSession({
            ...session,
            speed_acc_id: response?.accounts_details?.[0]?.account?.id,
          })
          .then(() => {
            dispatch(
              setInitialState({
                ...session,
                current_acc: response.accounts_details[0],
              })
            );
          });
      }
      return response;
    })
    .catch((_e) => false);
};

export const setLocalVariables = (response) => async (dispatch) => {
  localStorage.setItem("site_auth", "1");
  dispatch(setInitialState(response));
  dispatch(getUser()).then(async (res) => {
    if (res) {
      await dispatch(setIsLoggedIn(true));
      return res;
    }
  });
};

export const setAfterLoginProcess = async (response, dispatch) => {
  await dispatch(setLocalVariables(response));
};

export const afterConnectLoginFlow = async ({
  response,
  data = null,
  resolve = null,
  dispatch,
}) => {
  const isFromSocialMediaLogin = !data;
  response.timestamp = getTimestamp();
  await setUpdatedSessionData(response, data);
  dispatch({
    type: Types.SET_SHOULD_LOADER_SHOW,
    payload: false,
  });
  if (response?.mfa_enabled) {
    const redirect2faPage =
      response?.mfa_configurations && "/connect-two-factor";
    history.push({
      pathname: redirect2faPage,
      state: {
        from: "/connect-account",
        isFromSocialMediaLogin,
      },
    });
    resolve?.(true);
    return;
  }
  await setAfterLoginProcess(response, dispatch);
  resolve?.(true);
};
export const logInConnectAction = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    return callAPIInterfaceConnect("POST", "/login", JSON.stringify(data))
      .then(async (response) => {
        afterConnectLoginFlow({ dispatch, response, resolve, data });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const logOut = () => async () => {
  const { session } = await sessionService.loadSession();
  return callAPIInterfaceConnect("POST", "/logout", JSON.stringify({ session }))
    .then(() => {
      expireSession();
    })
    .catch(() => {});
};

export const generateToken = () => async (dispatch) => {
  const session = await sessionService.loadSession();
  return new Promise(async (resolve, reject) => {
    return await callAPIInterfaceConnect(
      "POST",
      "/generate-token",
      JSON.stringify({
        session: session.session,
      })
    )
      .then(async (response) => {
        const updatedSession = {
          refresh_token: session.refresh_token,
          access_token: response.access_token,
          id_token: response.id_token,
          timestamp: getTimestamp(),
        };
        await sessionService.saveSession({ ...session, ...updatedSession });
        dispatch(setInitialState({ ...session, ...updatedSession }));
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const showToast = (data) => (dispatch) => {
  dispatch({
    type: Types.SHOW_TOAST_MESSAGE,
    payload: data,
  });
};

export const hideToast = (data) => (dispatch) => {
  dispatch({
    type: Types.HIDE_TOAST_MESSAGE,
    payload: data,
  });
};

export const setConnectPageData = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_CONNECT_PAGE_DATA,
    payload: data,
  });
};

export const setConnectPageLoading = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_CONNECT_PAGE_LOADING,
    payload: data,
  });
};
