import React, {
  useImperativeHandle,
  forwardRef,
  useState,
  useEffect,
} from "react";
import { Box } from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import Text from "@speed/common/src/components/Text/Text";
import AddIcon from "@mui/icons-material/Add";
import InfoIcon from "@mui/icons-material/Info";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import { CustomTooltip } from "@speed/common/src/components/Tooltip/Tooltip";
import InvoiceCusomer from "./InvoiceCusomer";
import InvoiceItems from "./InvoiceItems";
import InvoiceDue from "./InvoiceDue";
import InvoiceDelivery from "./InvoiceDelivery";
import InvoiceAdditionalOptions from "./InvoiceAdditionalOptions";
import { useDispatch, useSelector } from "react-redux";
import {
  setInvoicePreviewData,
  setLastInvoiceSavedIn,
} from "../../redux/common/actions";
import moment from "moment";
import { getTimezoneMomentObj } from "@speed/common/src/components/constants";
import { callAPIInterface, getTimeZoneAndDateFormat } from "../constants";

const InvoiceForm = forwardRef(({ handleButtonDisable }, ref) => {
  const showInvoiceDueSection = false;
  const showDeliverySectoin = false;
  const [disableEditFormElement, setDisableEditFormElement] = useState(false);
  const [invoiceAmountExceeded, setInvoiceAmountExceeded] = useState(false);
  const dispatch = useDispatch();
  const { lastSavedInvoiceIn, selectedAssetCurrencyState } = useSelector(
    (state) => state.common
  );

  const { timezone } = getTimeZoneAndDateFormat();
  const givenDueDate = getTimezoneMomentObj(1687343641000, timezone);

  const validationSchema = yup.object({
    customer: yup.object().required(),
    products: yup.array().min(1).required(),
  });

  const formik = useFormik({
    initialValues: {
      customer: null,
      selectedItem: null,
      selectedItemPrice: null,
      currency: null,
      products: [],
      singleProduct: null,
      dueDate: "today",
      customDueDate: null,
      delivery: "emailInvoice",
      includeLink: false,
      includeRecipients: false,
      includeFooter: false,
      footerMessage: "",
      includeMemo: false,
      memoMessage: "",
      includeCustomField: false,
      doNotSendReminders: false,
      invoiceSettingData: null,
      currentExchangeRate: null,
      invoiceTotalAmount: 0,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const { values, isValid, dirty, isSubmitting, setFieldValue } = formik;

  const getInvoiceDefaultSettingDetails = () => {
    callAPIInterface("GET", "/account-invoice-settings")
      .then((res) => {
        if (res) {
          setFieldValue("invoiceSettingData", res);
          if (res?.footer) setFieldValue("includeFooter", true);
          if (res?.memo) setFieldValue("includeMemo", true);

          setFieldValue("footerMessage", res?.footer || "");
          setFieldValue("memoMessage", res?.memo || "");
        }
      })
      .catch(() => {});
  };
  useEffect(() => {
    //prevent editing other form element until customer selected
    if (!values.customer) setDisableEditFormElement(true);
    getInvoiceDefaultSettingDetails();
  }, []);

  useEffect(() => {
    const {
      customer,
      products,
      currency,
      invoiceSettingData,
      invoiceTotalAmount,
      currentExchangeRate,
      includeFooter,
      includeMemo,
      footerMessage,
      memoMessage,
    } = values;
    const invoiceProducts = products?.map((product) => {
      return {
        name: product?.name,
        quantity: product?.quantity,
        currency: currency?.code,
        row_total_amount: product?.quantity * product?.prices?.unit_amount,
        unit_amount: product?.prices?.unit_amount,
      };
    });

    const paymentInfo = {
      amount: invoiceTotalAmount,
      targetCurrency: selectedAssetCurrencyState,
      payment: { exchange_rate: currentExchangeRate },
      payment_request: "",
      ...(invoiceProducts && {
        invoice_line_items: invoiceProducts,
      }),
      currency: currency,
      ...(invoiceSettingData && {
        payment_terms: invoiceSettingData?.payment_terms,
        invoice_prefix: invoiceSettingData?.invoice_prefix,
        memo: includeMemo ? memoMessage : "",
        footer: includeFooter ? footerMessage : "",
      }),
      invoice_due_date: new Date().getTime(),
      customer,
    };
    dispatch(setInvoicePreviewData(paymentInfo));
  }, [values]);

  useEffect(() => {
    const disable =
      !(isValid && dirty) || isSubmitting || invoiceAmountExceeded;
    handleButtonDisable(disable);
  }, [isValid, dirty, isSubmitting, invoiceAmountExceeded]);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      return values;
    },
  }));

  const createNewElement = (label, handleClick) => (
    <Box
      className="pointer-cursor create-new-element-box"
      onClick={handleClick}
    >
      <AddIcon className="add-icon" />
      <Text className="default-text" size={14} variant="body1" component="span">
        {label}
      </Text>
    </Box>
  );

  const labelElement = (label) => (
    <Text className="default-text invoice-label-text">{label}</Text>
  );

  const checkboxElement = (
    tyoeOfValue,
    label,
    handleOnchange,
    childElement,
    tooltipContent,
    showInfoIcon = false
  ) => {
    return (
      <>
        <Box className="invoice-checkbox">
          <CustomCheckbox
            checked={values[tyoeOfValue]}
            label={label}
            onChange={handleOnchange}
          />
          {showInfoIcon && (
            <CustomTooltip
              arrow={true}
              placement="top"
              text={tooltipContent}
              className="custom-tooltip payments-status-tooltip"
            >
              <InfoIcon className="checkbox-info-icon" />
            </CustomTooltip>
          )}
        </Box>
        {childElement && (
          <Box className="invoice-checkbox-content">{childElement}</Box>
        )}
      </>
    );
  };

  const saveLastSavedTime = (isAvailableUpdatedTime) => {
    dispatch(
      setLastInvoiceSavedIn(
        isAvailableUpdatedTime
          ? moment.now()
          : lastSavedInvoiceIn || moment.now()
      )
    );
  };

  const commonProps = {
    saveLastSavedTime,
    formik,
    labelElement,
    createNewElement,
    disableEditFormElement,
    setDisableEditFormElement,
    selectedAssetCurrencyState,
  };

  return (
    <Box className="apply-auto-scroll invoice-create-form" autoComplete="off">
      <Box className="form-box-wrapper">
        <InvoiceCusomer {...commonProps} />

        <InvoiceItems
          invoiceAmountExceeded={invoiceAmountExceeded}
          setInvoiceAmountExceeded={setInvoiceAmountExceeded}
          {...commonProps}
        />

        {showInvoiceDueSection && (
          <InvoiceDue
            timezone={timezone}
            givenDueDate={givenDueDate}
            {...commonProps}
          />
        )}

        {showDeliverySectoin && (
          <InvoiceDelivery {...commonProps} checkboxElement={checkboxElement} />
        )}

        <InvoiceAdditionalOptions
          {...commonProps}
          checkboxElement={checkboxElement}
        />
      </Box>
    </Box>
  );
});

export default InvoiceForm;
