import {
  CodeTextInput,
  concatedMobileNumber,
  mobileNumberWithoutCallingCode,
  Setup2FASubText,
  validatePhoneNumber,
} from "../../constants";
import { QRCodeComponent } from "@speed/common/src/components/QRCode/QRCode";
import Text from "@speed/common/src/components/Text/Text";
import {
  appAuthMsg,
  captchaBlockBGColor,
  captchaCodeLabel,
  captchaCodeLength,
  captchaCodePlaceholder,
  googleAuthenticator,
} from "../../messages";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { useState, useEffect, forwardRef, useImperativeHandle } from "react";
import { OTPInput } from "@speed/common/src/components/OTPInput/OTPInput";
import { useFormik } from "formik";
import WithMobile2FA from "../../Common/WithMobile2FA";
import { Countries } from "@speed/common/src/components/country";
import * as yup from "yup";
import {
  DidNotGetCodeLink,
  getCountryObj,
  MFAOTPTimer,
  TwoFASubText,
} from "@speed/common/src/components/constants";
import { LoadCanvasTemplate, loadCaptchaEnginge } from "react-simple-captcha";
import { Input } from "@speed/common/src/components/Input/Input";

const TwoFAModalContent = forwardRef((props, ref) => {
  const {
    setIsAppBtnDisabled,
    setIsSMSBtnDisabled,
    byQRCode,
    isAppContinue,
    isSMSContinue,
    selectedType,
    handleResendCode,
    countries,
    smsConfigDetails,
    mfaResendcodeLdFlag,
    timerCount,
    isExpiredOTP,
    isCaptchaVerified,
    captchaCode,
    setCaptchaCode,
    isFromRemoveSMS,
  } = props;
  const [otp, setOtp] = useState("");
  const [countryISOCode, setCountryISOCode] = useState();

  const handleOtpChange = (changedOtp) => {
    setOtp(changedOtp);
    if (changedOtp.length >= 6) {
      selectedType === "sms"
        ? setIsSMSBtnDisabled(false)
        : setIsAppBtnDisabled(false);
    } else {
      selectedType === "sms"
        ? setIsSMSBtnDisabled(true)
        : setIsAppBtnDisabled(true);
    }
  };

  const validationSchema = yup.object({
    mobileNo: yup.mixed().test("mobileNo", validatePhoneNumber).required(""),
  });

  const mobileInputDetail = smsConfigDetails && {
    callingCode: smsConfigDetails?.mfa_calling_code,
    mobileNumber: concatedMobileNumber(
      smsConfigDetails?.mfa_calling_code,
      smsConfigDetails?.mfa_phone_number
    ),
  };

  const formik = useFormik({
    initialValues: {
      mobileDialCode: mobileInputDetail?.callingCode
        ? mobileInputDetail?.callingCode
        : "91",
      mobileNo: mobileInputDetail?.mobileNumber
        ? mobileInputDetail?.mobileNumber
        : "",
    },
    validationSchema: validationSchema,
  });

  const {
    values,
    setFieldValue,
    setTouched,
    isValid,
    dirty,
    isSubmitting,
    errors,
    touched,
  } = formik;

  const phoneNumWithoutCountryCode = mobileNumberWithoutCallingCode(
    values.mobileDialCode,
    values.mobileNo
  );

  useImperativeHandle(ref, () => ({
    sendSMSVerificationAPIData: () => {
      return {
        mfa_calling_code: values.mobileDialCode,
        mfa_phone_number: phoneNumWithoutCountryCode,
      };
    },
    sendOTPVerificationAPIData: () => {
      return {
        otp,
      };
    },
  }));

  useEffect(() => {
    if (selectedType === "sms" && !isCaptchaVerified) {
      loadCaptchaEnginge(captchaCodeLength, captchaBlockBGColor);
    }
  }, [selectedType]);

  useEffect(() => {
    if (!isFromRemoveSMS) {
      setIsSMSBtnDisabled(
        smsConfigDetails && mobileInputDetail.mobileNumber && isValid
          ? false
          : !(isValid && dirty) || isSubmitting
      );
    }
  }, [isValid, dirty, isSubmitting, isCaptchaVerified]);

  useEffect(() => {
    // Set country code according to calling_code
    let countryObj = getCountryObj(
      "calling_code",
      values.mobileDialCode,
      countries ? countries : Countries
    );

    setCountryISOCode(countryObj?.iso2);
  }, [values.mobileDialCode]);

  const CommonOtpInput = (
    <>
      <OTPInput
        value={otp}
        onChange={handleOtpChange}
        numInputs={6}
        className="margin-top25 margin-bottom12"
        containerStyle={{ justifyContent: "space-between" }}
      />
      {timerCount > 0 && <MFAOTPTimer timerCount={timerCount} />}
      {mfaResendcodeLdFlag && isExpiredOTP && (
        <DidNotGetCodeLink
          textProps={{
            className: "expired-2fa-otp",
          }}
          handleResendCode={async () => {
            await handleResendCode()
              .then(() => {
                setOtp("");
              })
              .catch(() => {});
          }}
        />
      )}
    </>
  );

  const getSMSModalContent = () =>
    isCaptchaVerified ? (
      <>
        {isSMSContinue ? (
          <TwoFASubText
            from="profile"
            usingPhone
            phoneNumber={{
              code: `+${values.mobileDialCode}`,
              number: phoneNumWithoutCountryCode,
            }}
          />
        ) : (
          <Setup2FASubText from="profile" usingPhone byQRCode={byQRCode} />
        )}
        {isSMSContinue ? (
          CommonOtpInput
        ) : (
          <WithMobile2FA
            setFieldValue={setFieldValue}
            setTouched={setTouched}
            countryISOCode={countryISOCode}
            values={values}
            Countries={countries ? countries : Countries}
            touched={touched}
            errors={errors}
          />
        )}
      </>
    ) : (
      <>
        <LoadCanvasTemplate />
        <Input
          fullWidth
          value={captchaCode}
          label={captchaCodeLabel}
          onChange={(e) => {
            setCaptchaCode(e.target.value);
            setIsSMSBtnDisabled(e.target.value ? false : true);
          }}
          type="text"
          inputProps={{ maxLength: 6 }}
          customClass="margin-top30"
          placeholder={captchaCodePlaceholder}
        />
      </>
    );

  const getGoogleAuthModalContent = () => (
    <>
      {isAppContinue ? (
        <Text
          className="default-text"
          font="regular"
          size={14}
          variant="caption"
        >
          {appAuthMsg[0]}
          <CustomLink size="small">{googleAuthenticator}</CustomLink>
          {appAuthMsg[1]}
        </Text>
      ) : (
        <Setup2FASubText from="profile" byQRCode={byQRCode} />
      )}
      {isAppContinue ? (
        CommonOtpInput
      ) : byQRCode ? (
        <QRCodeComponent
          value="https://app.zeplin.io/project/616e8348664fbf10b64cf986/screen/619e3a53085b17ac7c645888"
          size={200}
          icon
          iconSize={36}
        />
      ) : (
        <CodeTextInput value="uje6grortcozrml4avlsvlht" />
      )}
    </>
  );

  const renderModalContent = (mfaType) => {
    switch (mfaType) {
      case "sms":
        return getSMSModalContent();

      case "authenticator":
        return getGoogleAuthModalContent();

      default:
        return null;
    }
  };

  return renderModalContent(selectedType);
});

export default TwoFAModalContent;
