import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { useParams } from "react-router";
import "../../assets/styles/payment-links.scss";
import ModuleEventList from "../ModuleEventList";

const TransferDetailsEvents = (props) => {
  const urlParams = useParams();
  const { trEventFe } = useFlags();

  return (
    <ModuleEventList ldFlag={trEventFe} urlParams={urlParams} {...props} />
  );
};

export default TransferDetailsEvents;
