import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TableCell,
  TableRow,
} from "@mui/material";
import {
  clipboardElement,
  htmlDecode,
} from "@speed/common/src/components/constants";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sessionService } from "redux-react-session";
import { setExecuteAPICall, setLoading } from "../../redux/common/actions";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  getCustomerChooseStatus,
  idealTimeLimitReached,
  noOfRecords,
  receivedOnPaymentDetails,
  renderPaymentUnconfirmed,
  renderStatus,
  setShowCrucialActionModal,
  updateSessionLastActionTime,
} from "../constants";
import { emptyPaymentSubText, sendReceipt } from "../messages";
import { emptySubText } from "@speed/common/src/components/messages";
import { emptyPayment } from "../images";
import CustomTable from "@speed/common/src/components/Table/Table";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import CreateReceipt from "./CreateReceipt";
import HorizontalSearchPageTable from "./HorizontalSearchPageTable";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useFlags } from "launchdarkly-react-client-sdk";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";

const PaymentTable = ({
  tab,
  showHorizontalTable = false,
  searchQueryUrl,
  inputString,
  searchResultData,
  getModuleSearchResultData,
  totalNumOfSearchResults,
}) => {
  const { user, liveMode } = useSelector((state) => state.auth);
  const { executeAPICall, history } = useSelector((state) => state.common);
  const fromSearchPage = history.location.pathname === "/search";

  const { bdSendpaymentreceipt } = useFlags();

  const columns = [
    {
      title: "Payment ID",
      width: "170px",
      paddingLeft: "38px !important",
    },
    {
      title: "Amount",
      width: "170px",
      align: fromSearchPage ? "left" : "right",
    },
    {
      title: "Currency",
      width: "100px",
    },
    {
      title: "Status",
      width: "100px",
      paddingLeft: fromSearchPage ? "" : "30px !important",
    },
    { title: "Method", width: "150px", align: "left" },
    { title: "Received On", width: "150px", align: "left" },
    { title: "Description", width: "250px", align: "left" },
    { title: "Created on", width: "250px", align: "left" },
  ];

  bdSendpaymentreceipt &&
    columns.push({ title: "Actions", width: "125px", align: "center" });

  const queryParam = "?limit=" + noOfRecords;
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [session, setSession] = useState(null);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [rows, setRows] = useState([]);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [loadMoreId, setLoadMoreId] = useState("");
  const [receipt, setReceipt] = useState(false);

  const dispatch = useDispatch();

  const openPopper = Boolean(anchorEl);

  useEffect(() => {
    sessionService
      .loadSession()
      .then((newSession) => {
        setSession(newSession);
      })
      .catch((e) => {
        console.log("err:", e);
      });
  }, []);

  useEffect(() => {
    setRows([]);
    setHasMore(true);
    getPaymentsList([], queryParam);
  }, [liveMode]);

  const getPaymentsList = async (lines, params) => {
    if (fromSearchPage) {
      if (showHorizontalTable) {
        setRows(searchResultData);
      } else {
        setTableRowSkeleton(true);
        await getModuleSearchResultData(searchResultData)
          .then((result) => {
            setTableRowSkeleton(false);
            if (!result.hasMore) {
              setHasMore(false);
            }
            setRows(result?.concatedResultData);
          })
          .catch(() => {
            setTableRowSkeleton(false);
          });
      }
    } else {
      let method,
        data,
        path = "";
      //use method POST when tab is selected other than 'All'
      if (tab) {
        method = "POST";
        data = {
          status: tab,
          limit: noOfRecords,
          ending_before: loadMoreId,
        };
        path = "/payments/filter";
      } else {
        method = "GET";
        data = {};
        path = "/payments" + params;
      }

      setTableRowSkeleton(true);
      await callAPIInterface(method, path, data)
        .then((res) => {
          if (res) {
            setTableRowSkeleton(false);
            if (!res.has_more) {
              setHasMore(false);
            } else {
              setQueryParams(
                queryParam +
                  "&ending_before=" +
                  res.data[res.data.length - 1].id
              );
              setLoadMoreId(res.data[res.data.length - 1].id);
            }
            setRows(lines.concat(res.data));
          }
        })
        .catch((_err) => {
          setTableRowSkeleton(false);
        });
    }
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick(e, currentRow);
    }
  };

  const handleClick = (event, rowData) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setCurrentRow(rowData);
  };

  const handleActionClick = () => {
    if (idealTimeLimitReached(session.last_action_time)) {
      setShowCrucialActionModal(user, true);
    } else {
      // api call
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    if (executeAPICall) {
      const newSession = updateSessionLastActionTime();
      setSession(newSession);
      dispatch(setLoading(false));
      setShowCrucialActionModal(user, false);
      dispatch(setExecuteAPICall(false));
    }
  }, [executeAPICall]);

  const loadMore = useCallback(() => {
    getPaymentsList(rows, queryParams);
  }, [rows]);

  let columnsData = columns.map((column) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{ width: column.width, paddingLeft: column.paddingLeft }}
    >
      {column.title}
    </TableCell>
  ));

  let rowsData = rows?.map((rowItem) => {
    const { methodType, methodValue } = receivedOnPaymentDetails(rowItem);
    const paymentMethod = fromSearchPage
      ? rowItem.paid_payment_method
      : methodType;
    const paymentRequest = fromSearchPage
      ? rowItem.paid_payment_request
      : methodValue;

    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/payments/${rowItem.id}`)}
      >
        <TableCell sx={{ pl: "38px !important" }}>
          {clipboardElement(
            rowItem.id,
            true,
            "payments-listing-id-clipboard",
            "inputBox",
            false
          )}
        </TableCell>
        <TableCell
          style={{
            textAlign: fromSearchPage ? "left" : "right",
            minWidth: 170,
          }}
        >
          {getCustomerChooseStatus(
            rowItem?.status === "paid"
              ? rowItem?.target_amount_paid
              : rowItem?.target_amount,
            rowItem?.target_currency
          )}
        </TableCell>
        <TableCell>
          {rowItem?.target_currency === "SATS"
            ? "BTC"
            : rowItem?.target_currency}
        </TableCell>
        <TableCell style={{ paddingLeft: fromSearchPage ? "" : "30px" }}>
          <Box display="flex" gap={2} alignItems="end">
            {renderStatus(rowItem.status)}
            {renderPaymentUnconfirmed(rowItem)}
          </Box>
        </TableCell>
        <TableCell style={{ textAlign: "left" }}>
          <>{rowItem?.status === "paid" ? paymentMethod : "-"}</>
        </TableCell>
        <TableCell style={{ textAlign: "left" }}>
          {rowItem?.status === "paid"
            ? clipboardElement(
                paymentRequest,
                true,
                "payments-listing-id-clipboard",
                "inputBox",
                false
              )
            : "-"}
        </TableCell>
        <TableCell style={{ textAlign: "left" }}>
          <TruncatedTextTooltip
            textValue={htmlDecode(rowItem?.description || "-")}
            cellWidth="250px"
          />
        </TableCell>
        <TableCell style={{ textAlign: "left", minWidth: 250 }}>
          {dateTimeFormatInApp(rowItem.created)}
        </TableCell>
        {bdSendpaymentreceipt && (
          <TableCell style={{ textAlign: "center" }}>
            <MoreHorizIcon
              className="horizontal-dots-icon"
              tabIndex={0}
              onKeyDown={handleKeyDown}
              onClick={(event) => {
                event.stopPropagation();
                handleClick(event, rowItem);
              }}
            />
          </TableCell>
        )}
      </TableRow>
    );
  });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
    className: "payment-table",
    textOnNoData: emptySubText(liveMode, "payments", tab),
    subTextOnNoData: emptyPaymentSubText,
    noDataImage: emptyPayment,
  };

  return (
    <>
      {showHorizontalTable && fromSearchPage ? (
        <HorizontalSearchPageTable
          moduleName="payment"
          columns={columns}
          rows={tableProps.rows}
          searchQueryUrl={searchQueryUrl}
          inputString={inputString}
          totalNumOfSearchResults={totalNumOfSearchResults}
        />
      ) : (
        <Box className="main-content">
          <CustomTable {...tableProps} />
        </Box>
      )}

      <CustomPopper
        disablePortal={true}
        open={openPopper}
        anchorEl={anchorEl}
        position="bottom"
        handleClose={() => setAnchorEl(null)}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleActionClick()}>
              <ListItemText
                primary={sendReceipt}
                onClick={() => setReceipt(true)}
              />
            </ListItemButton>
          </ListItem>
        </List>
      </CustomPopper>
      {receipt && (
        <CreateReceipt handleClose={() => setReceipt(false)} page="payments" />
      )}
    </>
  );
};

export default PaymentTable;
