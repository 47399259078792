import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import {
  verifyItsYouText,
  confirmPasswordLabel,
  verifyItsYouTitle,
  confirmBtn,
} from "../messages";
import * as yup from "yup";
import { useFormik } from "formik";
import Text from "@speed/common/src/components/Text/Text";
import { Input } from "@speed/common/src/components/Input/Input";
import Button from "@speed/common/src/components/Button/Button";
import { verifyUserPassword } from "../../redux/auth/actions";
import {
  setLoading,
  setConfirmAction,
  setExecuteAPICall,
} from "../../redux/common/actions";
import { Grid } from "@mui/material";
import { passwordPlaceholder } from "@speed/common/src/components/messages";

function ConfirmPassword(_props) {
  const dispatch = useDispatch();
  const { openConfirmActionModal, isLoading } = useSelector(
    (state) => state.common
  );

  const validationSchema = yup.object({
    confirm_password: yup.string().required(),
  });

  const confirmPassword = async () => {
    dispatch(setLoading(true));
    let postObject = {
      password: values.confirm_password,
    };
    await dispatch(verifyUserPassword(postObject)).then((res) => {
      if (res) dispatch(setExecuteAPICall(true));
      else dispatch(setLoading(false));
    });
  };

  const formik = useFormik({
    initialValues: {
      confirm_password: "",
    },
    validationSchema: validationSchema,
    onSubmit: confirmPassword,
  });

  const {
    values,
    isValid,
    isSubmitting,
    resetForm,
    dirty,
    handleSubmit,
    setSubmitting,
    setFieldValue,
  } = formik;

  useEffect(() => {
    if (!openConfirmActionModal) {
      resetForm();
    }
  }, [openConfirmActionModal]);

  const bodyContent = (
    <Grid
      container
      direction="row"
      component="form"
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Text className="default-text" size={14} font="regular" variant="caption">
        {verifyItsYouText}
      </Text>

      <Input
        customClass="margin-top30"
        type="password"
        showLabel
        label={confirmPasswordLabel}
        value={values.confirm_password}
        name="confirm_password"
        fullWidth
        placeholder={passwordPlaceholder}
        inputProps={{
          autoComplete: "new-password",
        }}
        onChange={(e) => {
          setSubmitting(false);
          setFieldValue("confirm_password", e.target.value);
        }}
      />
    </Grid>
  );

  return (
    <Modal
      maxWidth="xs"
      body={bodyContent}
      footer={
        <Button
          loading={isLoading}
          disabled={!(isValid && dirty) || isSubmitting}
          type="submit"
          label={confirmBtn}
          onClick={handleSubmit}
        />
      }
      handleClose={() => {
        resetForm();
        dispatch(setConfirmAction(false));
      }}
      open={openConfirmActionModal}
      title={verifyItsYouTitle}
    />
  );
}

export default ConfirmPassword;
