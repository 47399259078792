import { Modal } from "@speed/common/src/components/Modal/Modal";
import {
  continueEditingLabel,
  emailCCLabel,
  limitForMultiEmail,
  multiEmailPlaceholder,
  sendInvoice,
} from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import MultiEmailTag from "../Common/MultiEmailTag";
import { isEmail } from "react-multi-email";
import { useState } from "react";
import { clearIcon } from "../images";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import { invalidEmail } from "@speed/common/src/components/messages";

function SendInvoiceModal({ openModal, setOpenModal }) {
  const noOfEmails = 5;
  const [emails, setEmails] = useState([]);
  const [invalidEmailData, setInValidEmailData] = useState("");
  const [invalidEmailFlag, setInValidEmailFlag] = useState(false);
  const [limitEmail, setLimitEmail] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const resetEmailStates = () => {
    setEmails([]);
    setInValidEmailData("");
    setInValidEmailFlag(false);
    setLimitEmail(false);
    setDisabled(true);
  };

  const validateEmail = (email) => {
    if (isEmail(email) === false) {
      if (email === "undefined" || !emails) setDisabled(true);
      else {
        setInValidEmailData(email);
        setInValidEmailFlag(true);
        setDisabled(true);
      }
    } else setDisabled(false);
    return isEmail(email); // return boolean
  };

  const handleEmailChange = (e) => {
    setEmails(e);
    setInValidEmailData("");
    setInValidEmailFlag(false);
    if (e.length <= noOfEmails) {
      if (e.length == 0) setDisabled(true);
      else setDisabled(false);
      setLimitEmail(false);
    } else {
      setDisabled(true);
      setLimitEmail(true);
    }
  };

  const multiInput = document
    .querySelector(".invoice-send-modal-body .react-multi-email")
    ?.getElementsByTagName("input")[0];

  multiInput?.addEventListener("keyup", (e) => {
    if (e.target.value && !isEmail(e.target.value)) {
      setInValidEmailData(e.target.value);
      setInValidEmailFlag(true);
      setDisabled(true);
    } else {
      setInValidEmailData("");
      setInValidEmailFlag(false);
      emails.length > 0 ? setDisabled(false) : setDisabled(true);
    }
  });

  const getEmailLabel = (email, index, removeEmail) => {
    return (
      <>
        {
          <div data-tag key={index} className="email-multi-tags">
            <span className="email-multi-tag-data">{email}</span>
            <span
              data-tag-handle
              onClick={() => removeEmail(index)}
              style={{ marginLeft: "5px" }}
            >
              <img
                src={clearIcon}
                alt="icons-clear"
                height="16px"
                width="16px"
                style={{ marginTop: "2px" }}
              />
            </span>
          </div>
        }
      </>
    );
  };

  const handleReceipt = () => {
    handleModalClose();
  };

  const handleModalClose = () => {
    resetEmailStates();
    setOpenModal(false);
  };

  const renderModalContent = () => {
    return (
      <Box className="invoice-send-modal-body">
        <Text size={16} className="default-text">
          Send invoice for $2326.50 to Speed App
        </Text>
        <Text
          size={16}
          variant="h4"
          className="grey-text"
          font="regular"
          sx={{ marginTop: "10px" }}
        >
          Invoices cant be edited after they’re been paid. Your customer will
          receive an email receipt after the invoice is paid.
        </Text>
        <Box className="send-email-input-section">
          <Text
            className="label-text"
            size={14}
            font="regular"
            variant="caption"
          >
            {emailCCLabel}
          </Text>
          <MultiEmailTag
            emails={emails}
            emailPlaceholder={multiEmailPlaceholder}
            handleEmailChange={handleEmailChange}
            validateEmail={(email) => validateEmail(email)}
            getEmailLabel={(email, index, removeEmail) =>
              getEmailLabel(email, index, removeEmail)
            }
          />
          {invalidEmailFlag && (
            <AlertMessage
              message={invalidEmail + ": " + invalidEmailData}
              className="margin-top15"
              severity="error"
            />
          )}
          {limitEmail && (
            <AlertMessage
              message={limitForMultiEmail}
              className="margin-top15"
              severity="error"
            />
          )}
        </Box>
      </Box>
    );
  };

  const renderModalFooter = () => {
    return (
      <Box className="invoice-send-modal-footer">
        <Button
          variant="outlined"
          type="submit"
          label={continueEditingLabel}
          onClick={handleModalClose}
          disabled={false}
        />
        <Button
          className="save-btn"
          type="submit"
          label={sendInvoice}
          onClick={handleReceipt}
          disabled={disabled}
        />
      </Box>
    );
  };

  return (
    <Modal
      maxWidth="sm"
      className="send-invoice-modal"
      open={openModal}
      body={renderModalContent()}
      footer={renderModalFooter()}
      handleClose={handleModalClose}
      title={sendInvoice}
    />
  );
}

export default SendInvoiceModal;
