import * as Types from "./types";

const initialState = {
  isLoggedIn: !!localStorage.getItem("site_auth"),
  user: null,
  accessToken: "",
  idToken: "",
  refreshToken: "",
  temp: null,
  liveMode: false,
  currentAccount: null,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case Types.SET_IS_LOGGED_IN:
      return {
        ...state,
        isLoggedIn: action.payload,
      };
    case Types.SET_INITIAL_DATA:
      const { access_token, id_token, refresh_token, liveMode, current_acc } =
        action.payload;
      let initialData = {
        ...state,
        accessToken: access_token,
        idToken: id_token,
        refreshToken: refresh_token,
      };
      if (current_acc) {
        initialData.currentAccount = current_acc;
      }
      if (typeof liveMode == "boolean") {
        initialData.liveMode = liveMode;
      }
      return initialData;
    case Types.SET_USER:
      const { first_name, last_name } = action.payload;
      return {
        ...state,
        user: { name: first_name + " " + last_name, ...action.payload },
      };
    case Types.SET_LIVE_MODE:
      const updatedAccounts = state.user.accounts_details?.map((acc) => {
        if (acc?.id === state.currentAccount.id) {
          return { ...acc, is_last_viewed_livemode: action.payload };
        }
        return acc;
      });
      return {
        ...state,
        liveMode: action.payload,
        user: { ...state.user, accounts_details: updatedAccounts },
        currentAccount: {
          ...state.currentAccount,
          account: {
            ...state.currentAccount.account,
            is_last_viewed_livemode: action.payload,
          },
        },
      };
    default:
      return { ...state };
  }
};

export default auth;
