import { Box } from "@mui/material";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
const AnswerSkeleton = () => {
  return (
    <Box className="answer-skeleton-wrapper">
      <CommonSkeleton className="question-skeleton" />
      <CommonSkeleton className="answer-skeleton" />
      <CommonSkeleton className="answer-skeleton" />
      <CommonSkeleton className="answer-skeleton" />
      <CommonSkeleton className="answer-skeleton" />
    </Box>
  );
};
export default AnswerSkeleton;
