import { Box } from "@mui/system";
import React, { useState, useEffect } from "react";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  RouteBreadcrumbs,
} from "../constants";
import {
  redirectWhenEntityNotFound,
  renderPayoutStatus,
  generateBreadcrumbs,
  satsToBtcAmount,
  satsToFiatAmount,
  excludedCode,
} from "@speed/common/src/components/constants";
import { Grid } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import {
  automaticText,
  dateCreated,
  entityNotFoundMessage,
  failureReason,
  instantText,
  networkRoutingFee,
  note,
  payoutAmount,
  payoutID,
  status,
  totalPayout,
  type,
  walletAddress,
  walletNameLabel,
} from "../messages";
import { useSelector } from "react-redux";
import "../../assets/styles/payment-links.scss";
import WarningIcon from "@mui/icons-material/Warning";
import { useFlags } from "launchdarkly-react-client-sdk";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import DetailPagePaymentSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import LinkSkeleton from "@speed/common/src/components/Skeleton/LinkSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { currency } from "@speed/common/src/components/currency";
import { speedFees } from "@speed/common/src/messages";
import TooltipOverText from "../Common/TooltipOverText";

const PayoutsDetails = (props) => {
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const liveMode = useSelector((state) => state.auth.liveMode);
  const [data, setData] = useState(null);
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const { poListpayoutrequest } = useFlags();

  const urlParams = useParams();

  const loadDetailPage = () => {
    setData(null);
    setIsShowNotFound(false);
    getPayoutRequestDetail();
  };

  useEffect(() => {
    loadDetailPage();
  }, [liveMode]);

  const getPayoutRequestDetail = async () => {
    await callAPIInterface("GET", `/payouts/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setData(res);
        }
      })
      .catch((err) => {
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const payoutDetailTableLeftSideData = () => {
    const resultArr = [
      {
        header: status,
        cell: renderPayoutStatus(data?.status),
      },
      {
        header: dateCreated,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {dateTimeFormatInApp(data?.created)}
          </Text>
        ),
      },
      {
        header: payoutID,
        cell: (
          <Text className="default-text" variant="h4" size={16} font="regular">
            {data?.id}
          </Text>
        ),
      },
      {
        header: type,
        cell: (
          <Text className="default-text" variant="h4" size={16} font="regular">
            {data?.automatic ? automaticText : instantText}
          </Text>
        ),
      },
    ];
    data?.note &&
      resultArr.push({
        header: note,
        cell: (
          <TooltipOverText
            textToHover={data?.note}
            customClass="instant-send-note"
            length={122}
          />
        ),
      });

    data?.failure_reason &&
      resultArr.push({
        header: failureReason,
        cell: (
          <Text className="default-text" variant="h4" size={16} font="regular">
            {data.failure_reason}
          </Text>
        ),
      });
    return resultArr;
  };

  const payoutDetailsTableRightSideData = () => {
    const resultArr = [];
    resultArr.push({
      header: payoutAmount,
      cell: (
        <Text className="default-text" variant="h4" size={16} font="regular">
          {satsToBtcAmount(data?.target_amount)} BTC
        </Text>
      ),
    });

    resultArr.push({
      header: networkRoutingFee,
      cell: (
        <Text className="default-text" variant="h4" size={16} font="regular">
          {data?.fee === 0
            ? "0 BTC"
            : `${satsToBtcAmount(data?.fee)} BTC` ?? "-"}
        </Text>
      ),
    });

    resultArr.push({
      header: speedFees,
      cell: (
        <Text className="default-text" variant="h4" size={16} font="regular">
          {data?.speed_fee === 0
            ? "0 BTC"
            : `${satsToBtcAmount(data?.speed_fee)} BTC` ?? "-"}
        </Text>
      ),
    });

    resultArr.push({
      header: totalPayout,
      cell: (
        <Text className="default-text" variant="h4" size={16} font="regular">
          {`${satsToBtcAmount(data?.total_amount)}`} BTC
        </Text>
      ),
    });

    return resultArr;
  };

  const verticalTablePayoutDetailsObj = {
    rowData: payoutDetailTableLeftSideData(),
    rightSideRowData: payoutDetailsTableRightSideData(),
  };

  const walletDetailsTableData = () => {
    return [
      {
        header: walletNameLabel,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {data?.wallet?.name}
          </Text>
        ),
      },
      {
        header: walletAddress,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {data?.wallet?.address}
          </Text>
        ),
      },
    ];
  };

  const headerContent = () => {
    return (
      <Box className="header-content">
        <Text className="default-text" size={28}>
          {satsToBtcAmount(data?.target_amount)} BTC
        </Text>
        {!excludedCode.includes(data?.currency) && (
          <Box className="header-price-content">
            <Box className="fiat-price">
              <Text className="default-text" size={20}>
                {`= ${
                  currency.find((item) => item.code === data?.currency)?.symbol
                }${satsToFiatAmount(data?.target_amount, data?.exchange_rate)}`}
              </Text>
            </Box>
          </Box>
        )}

        {data?.explorer_link && (
          <Box
            sx={{ marginTop: "15px", display: "flex", alignItems: "center" }}
          >
            <Box className="mempool-url-box">
              <Text
                size={14}
                font="regular"
                variant="subtitle1"
                className="default-text"
                sx={{
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {data?.explorer_link}
              </Text>
            </Box>
            <OpenInNewOutlinedIcon
              sx={{
                height: "24px",
                width: "24px",
                color: "#848B9E",
                marginLeft: "10px",
              }}
              onClick={() => window.open(data?.explorer_link, "_blank")}
            />
          </Box>
        )}
      </Box>
    );
  };

  return (
    <>
      {!isShowNotFound ? (
        <Grid className={`payment-link-detail-wrapper`} container spacing={1}>
          <Box className="section-wrapper" sx={{ position: "relative" }}>
            <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
            {poListpayoutrequest ? (
              <>
                {data ? (
                  <>
                    {headerContent()}
                    <Box className="details-content table-row-status">
                      <VerticalTable
                        label="Payout Details"
                        {...verticalTablePayoutDetailsObj}
                      />
                    </Box>
                  </>
                ) : (
                  <LinkSkeleton props={props} />
                )}
                {data ? (
                  <Box className="details-content">
                    <VerticalTable
                      label="Wallet Details"
                      rowData={walletDetailsTableData()}
                    />
                  </Box>
                ) : (
                  <DetailPagePaymentSkeleton />
                )}
              </>
            ) : (
              <AccessDenied />
            )}
          </Box>
        </Grid>
      ) : (
        <Box className="not-found-wrapper">
          <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
          <LinkNotFound
            icon={<WarningIcon className="warning-icon" />}
            title={entityNotFoundMessage("Payout")}
            subTitle={`Payout request ${urlParams.id} is not found.`}
            btnText={`View ${(liveMode && "live") || "test"} mode payouts`}
          />
        </Box>
      )}
    </>
  );
};

export default PayoutsDetails;
