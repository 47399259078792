import React, { useState, useCallback, useEffect } from "react";
import { Box, TableRow, TableCell } from "@mui/material";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as yup from "yup";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import { emptySubText } from "@speed/common/src/components/messages";
import Button from "@speed/common/src/components/Button/Button";
import { linkStatus } from "@speed/common/src/components/constants";
import {
  accountLabel,
  addWebhookEndpoint,
  connect,
  emptyWebhookText,
  latestVersionText,
  listenToWHType,
  urlValidate,
} from "../messages";
import CustomTable from "@speed/common/src/components/Table/Table";
import { callAPIInterface, noOfRecords, validateURL } from "../constants";
import { emptyWebhook } from "../images";
import CreateEndPointModal from "./CreateEndPointModal";
import "../../assets/styles/webhooks.scss";

const WebhookList = () => {
  const queryParam = "?limit=" + noOfRecords;
  const liveMode = useSelector((state) => state.auth.liveMode);
  const history = useSelector((state) => state.common.history);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [eventsSkeleton, setEventsSkeleton] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [rows, setRows] = useState([]);
  const [dataEvents, setDataEvents] = useState([]);
  const [open, setOpen] = useState(false);
  const [createWebhookPage, setCreateWebhookPage] = useState(1);
  const [isGlobalCheckAll, setIsGlobalCheckAll] = useState(false);
  const [isCheckAll, setIsCheckAll] = useState([]);
  const [expanded, setExpanded] = useState();
  const [disableConfirm, setDisableConfirm] = useState(false);

  useEffect(() => fetchWebHooks(), [liveMode]);

  const removeDuplicates = (arr) =>
    arr.filter((item, index) => arr.indexOf(item) === index);

  const validationSchema = yup.object({
    url: yup
      .mixed()
      .test({
        name: "url",
        message: urlValidate,
        test: (url) => validateURL(url, true, true),
      })
      .required(""),
    listenTo: yup.string().required(""),
  });

  const formik = useFormik({
    initialValues: {
      url: "",
      description: "",
      version: {
        name: `${latestVersionText} (2022-10-15)`,
        value: "2022-10-15",
      },
      events: [],
      listenTo: listenToWHType[0],
    },
    validationSchema: validationSchema,
    onSubmit: () => {
      if (createWebhookPage === 1) {
        fetchWebHookEvents();
        setCreateWebhookPage(createWebhookPage + 1);
      } else if (createWebhookPage === 2) {
        setCreateWebhookPage(createWebhookPage + 1);
      } else {
        createNewWebhook();
      }
    },
  });

  const createNewWebhook = () => {
    const webhookData = {
      url: values?.url,
      description: values?.description,
      api_version: values?.version?.value,
      enabled_events: values?.events,
      connect: values?.listenTo === "connected_account",
    };
    setDisableConfirm(true);
    callAPIInterface("POST", "/webhooks", JSON.stringify(webhookData))
      .then((result) => {
        result && history.push(`/webhooks/${result.id}`);
        fetchWebHooks();
        setDisableConfirm(false);
        handleClose();
      })
      .catch((_e) => {
        setDisableConfirm(false);
        handleClose();
      });
  };

  const {
    values,
    setFieldValue,
    resetForm,
    touched,
    errors,
    setTouched,
    isValid,
    dirty,
    handleSubmit,
  } = formik;

  const handleGlobalSelectAll = (e) => {
    setIsGlobalCheckAll(!isGlobalCheckAll);
    let result = [];
    let selectAllResult = [];
    dataEvents?.map((dataItem) => {
      selectAllResult.push(dataItem?.title);
      dataItem?.enabled_events?.map((event) =>
        result.push(event?.enabled_event)
      );
    });
    if (isGlobalCheckAll) {
      selectAllResult = [];
      result = [];
    }
    setIsCheckAll(selectAllResult);
    setFieldValue("events", removeDuplicates(result));
  };

  const handleSelectAll = (eventTitle, checked) => {
    let result = [];
    const filteredData = dataEvents?.filter(
      (item) => item?.title === eventTitle
    )[0];
    let filterDataEvents = values.events;
    if (checked) {
      result = isCheckAll.filter((item) => item !== eventTitle);
      filterDataEvents?.forEach((value) => {
        for (
          let index = 0;
          index < filteredData?.enabled_events.length;
          index++
        ) {
          const element = filteredData?.enabled_events[index];
          if (value === element?.enabled_event) {
            filterDataEvents = filterDataEvents?.filter(
              (filterDataEvent) => filterDataEvent !== value
            );
          }
        }
      });
      setFieldValue("events", removeDuplicates(filterDataEvents));
    } else {
      result = [...isCheckAll, eventTitle];
      filteredData?.enabled_events?.map((event) =>
        filterDataEvents.push(event?.enabled_event)
      );
      setFieldValue("events", removeDuplicates(filterDataEvents));
    }

    setIsGlobalCheckAll(result?.length === dataEvents?.length);
    setIsCheckAll(result);
  };

  const counts = {};

  dataEvents?.forEach((val) => {
    for (const num of val?.enabled_events) {
      counts[val?.title] =
        num?.enabled_event?.includes(val?.title) &&
        values?.events?.filter((x) => {
          const parentEvent = dataEvents?.filter((y) =>
            y?.enabled_events?.some((z) => z?.enabled_event === x)
          )[0];
          return parentEvent?.title === val?.title;
        })?.length;
    }
  });

  const checker = (arr, target) => target.every((v) => arr.includes(v));

  const handleClick = (enabled_event, checked) => {
    let result = [];
    let selectAllResult = isCheckAll;

    const parentEvent = dataEvents?.filter((x) =>
      x.enabled_events.some((y) => y.enabled_event === enabled_event)
    )[0];

    if (checked) {
      result = values.events.filter((item) => item !== enabled_event);
      selectAllResult = isCheckAll?.filter(
        (title) => title !== parentEvent?.title
      );
    } else {
      result = [...values.events, enabled_event];

      checker(
        result,
        parentEvent?.enabled_events?.map((val) => val?.enabled_event)
      ) && (selectAllResult = [...isCheckAll, parentEvent?.title]);
    }

    setIsCheckAll(selectAllResult);
    setIsGlobalCheckAll(
      result?.length ===
        dataEvents?.reduce((p, c) => p + c?.enabled_events?.length, 0)
    );
    setFieldValue("events", removeDuplicates(result));
  };

  const fetchWebHooks = () => {
    setRows([]);
    setHasMore(true);
    getWebhookList([], queryParam);
  };

  const fetchWebHookEvents = () => {
    setDataEvents([]);
    setIsGlobalCheckAll(false);
    setIsCheckAll([]);
    setFieldValue("events", []);
    setExpanded();
    getWebhookEvents(
      [],
      `?version=${values?.version?.value}&connect=${
        values.listenTo === "connected_account"
      }&limit=200`
    );
  };

  const handleClose = () => {
    setOpen(false);
    setCreateWebhookPage(1);
    setIsGlobalCheckAll(false);
    setIsCheckAll([]);
    resetForm({});
    setExpanded();
  };

  const handleBackButton = () => {
    setCreateWebhookPage(createWebhookPage - 1);
  };

  const getWebhookList = (lines, params) => {
    setTableRowSkeleton(true);
    callAPIInterface("GET", "/webhooks" + params)
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam + "&ending_before=" + res.data[res.data.length - 1].id
            );
          }
          setRows(lines.concat(res.data));
        }
      })
      .catch((error) => {
        setTableRowSkeleton(false);
      });
  };

  const getWebhookEvents = (lines, params) => {
    setEventsSkeleton(true);
    callAPIInterface("GET", "/webhooks/event-types" + params)
      .then((res) => {
        if (res) {
          setEventsSkeleton(false);
          setDataEvents(lines.concat(res.data));
        }
      })
      .catch((error) => {
        setEventsSkeleton(false);
      });
  };

  const columns = [
    {
      title: "Hosted endpoints URL",
      width: "600px",
      paddingLeft: "32px !important",
    },
    { title: "Status", width: "190px" },
    { title: "Type", width: "170px" },
  ];

  let columnsData = columns.map((column, index) => (
    <TableCell
      key={index}
      sx={{ width: column.width, paddingLeft: column.paddingLeft }}
    >
      {column.title}
    </TableCell>
  ));
  let rowsData = rows?.map((rowItem, index) => {
    return (
      <TableRow
        key={index}
        className="clickable"
        onClick={() => history.push(`/webhooks/${rowItem.id}`)}
      >
        <TableCell sx={{ paddingLeft: "32px !important" }}>
          {rowItem?.url}
        </TableCell>
        <TableCell>
          <Tag
            text={linkStatus[rowItem?.status]?.label}
            variant={linkStatus[rowItem?.status]?.variant}
          />
        </TableCell>
        <TableCell>{rowItem?.connect ? connect : accountLabel}</TableCell>
      </TableRow>
    );
  });

  const loadMore = useCallback(() => {
    getWebhookList(rows, queryParams);
  }, [rows]);

  const handleChangeAccordion = (panel) => (_event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleOpenModal = () => {
    setOpen(true);
  };

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
    textOnNoData: emptySubText(liveMode, "webhooks"),
    subTextOnNoData: emptyWebhookText,
    noDataImage: emptyWebhook,
  };

  return (
    <>
      <Box className="action-btn-wrapper mui-fixed">
        <Button
          icon="addIcon"
          className="add-icon"
          label={addWebhookEndpoint}
          variant="outlined"
          color="primary"
          onClick={handleOpenModal}
        />
      </Box>
      <Box className="main-content">
        <CustomTable {...tableProps} />
      </Box>
      <CreateEndPointModal
        open={open}
        handleClose={handleClose}
        createWebhookPage={createWebhookPage}
        handleBackButton={handleBackButton}
        handleNextButton={handleSubmit}
        expanded={expanded}
        events={values.events}
        isCheckAll={isCheckAll}
        isGlobalCheckAll={isGlobalCheckAll}
        handleChangeAccordion={handleChangeAccordion}
        list={dataEvents}
        handleClick={handleClick}
        handleSelectAll={handleSelectAll}
        handleGlobalSelectAll={handleGlobalSelectAll}
        counts={counts}
        values={values}
        setFieldValue={setFieldValue}
        touched={touched}
        setTouched={setTouched}
        isValid={isValid}
        dirty={dirty}
        errors={errors}
        disableConfirm={disableConfirm}
        liveMode={liveMode}
        eventsSkeleton={eventsSkeleton}
      />
    </>
  );
};

export default WebhookList;
