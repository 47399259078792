import React from "react";
import moment from "moment";
import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { useSelector } from "react-redux";
import { defaultDateFormat } from "@speed/common/src/components/constants";

const CustomDatePicker = ({
  value,
  placeholder,
  setValue,
  setIsError,
  onBlur,
  handleDateChange,
  disablePast = false,
  disableFuture = false,
  diablePastYearsForGivenYear,
  minDate,
  maxDate,
  timezone,
  readOnly,
  shouldDisableDate,
  handleDisableDate,
  popperProps,
  textFieldProps = {},
  datePickerProps = {},
}) => {
  if (timezone) {
    moment.tz.setDefault(timezone);
  } else {
    moment.tz.setDefault();
  }

  const user = useSelector((state) => state.auth.user);
  const userFormat = user?.date_format
    ? user.date_format.toUpperCase()
    : defaultDateFormat;

  const handleReadOnlyPickerToggle = () => {
    const pickerButtonElement = document.querySelector(
      ".custom-date-picker-textfield .MuiButtonBase-root"
    );
    if (pickerButtonElement) pickerButtonElement.click();
  };

  const handleChange = (val) => {
    setValue(val);
    if (handleDateChange) {
      handleDateChange(val);
    }
  };

  const customProps = {
    value,
    onChange: handleChange,
    inputFormat: userFormat,
    onError: (reason) => {
      if (setIsError) {
        reason ? setIsError(true) : setIsError(false);
      }
    },
    componentsProps: {
      leftArrowButton: {
        sx: {
          color: "#2a67ff",
        },
      },
      rightArrowButton: {
        sx: {
          color: "#2a67ff",
        },
      },
    },
    PaperProps: {
      className: "custom-date-picker",
    },
    PopperProps: {
      ...popperProps,
    },
    renderInput: (params) => {
      if (placeholder)
        params.inputProps.placeholder = placeholder + ` (${userFormat})`;
      if (onBlur) params.inputProps.onBlur = onBlur;
      if (readOnly) {
        params.onClick = handleReadOnlyPickerToggle;
        params.inputProps = {
          ...params.inputProps,
          readOnly: true,
        };
      }
      return (
        <TextField
          className="custom-date-picker-textfield"
          value={value}
          {...params}
          {...textFieldProps}
        />
      );
    },
  };

  if (disablePast) {
    customProps.disablePast = true;
  }

  if (disableFuture) {
    customProps.disableFuture = true;
  }

  if (shouldDisableDate && handleDisableDate) {
    customProps.shouldDisableDate = handleDisableDate;
  }

  if (minDate) {
    customProps.minDate = minDate;
  }

  if (maxDate) {
    customProps.maxDate = maxDate;
  }

  if (diablePastYearsForGivenYear) {
    customProps.shouldDisableYear = (date) =>
      date < moment(`${diablePastYearsForGivenYear}-01-01T00:00:00.000`);
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DatePicker {...customProps} {...datePickerProps} />
    </LocalizationProvider>
  );
};
export default CustomDatePicker;
