import { Box } from "@mui/material";
import InvoiceForm from "./InvoiceForm";
import InvoicePreviewSection from "./InvoicePreviewSection";
import { forwardRef } from "react";

const CreateInvoice = forwardRef(({ handleButtonDisable }, ref) => {
  return (
    <Box className="modal-container-grid" sx={{ display: "flex" }}>
      <Box className="inner-grid" sx={{ width: "30%" }}>
        <InvoiceForm ref={ref} handleButtonDisable={handleButtonDisable} />
      </Box>
      <Box
        paddingLeft="39px"
        className="inner-grid invoice-preview"
        sx={{ width: "55%" }}
      >
        <InvoicePreviewSection />
      </Box>
    </Box>
  );
});

export default CreateInvoice;
