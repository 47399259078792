import { Box, Grid } from "@mui/material";
import MainLogoLogin from "../Common/MainLogoLogin";
import {
  acceptInvitation,
  invalidUser,
  invitationDiffAccount,
  inviteForDiffAccount,
  inviteRedeem,
  invitee,
  joinTeam,
  signOutLockedIn,
  userInviteMsg,
} from "../messages";
import Text from "@speed/common/src/components/Text/Text";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { callAPIInterface, redirectToLogin } from "../constants";
import Button from "@speed/common/src/components/Button/Button";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import {
  setShowAutomaticLogoutModal,
  showToast,
} from "../../redux/common/actions";
import { logOut, setAfterLoginProcess } from "../../redux/auth/actions";
import WarningIcon from "@mui/icons-material/Warning";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { sessionService } from "redux-react-session";

function JoinInvitedUserAccount() {
  const dispatch = useDispatch();
  const invitee_details =
    localStorage.getItem(invitee) && JSON.parse(localStorage.getItem(invitee));
  const { inviteeEmail, inviteeAccountName, invitedByEmailId, inviteeId } = {
    ...invitee_details,
  };

  const { history, isMobile } = useSelector((state) => state.common);
  const [loading, setLoading] = useState(false);

  const { isOrphanUser, invitationForDiffAccount, invitationForRedeemAccount } =
    history?.location?.state || {};

  useEffect(() => {
    if (!isOrphanUser) redirectToLogin();
  }, []);

  const handleAcceptUserInvite = () => {
    setLoading(true);
    if (invitationForDiffAccount || invitationForRedeemAccount) {
      dispatch(setShowAutomaticLogoutModal(false));
      dispatch(logOut()).then(() => {
        setLoading(false);
      });
    } else {
      callAPIInterface("POST", "/invite-users/" + inviteeId + "/accept", "")
        .then(async () => {
          const session = await sessionService.loadSession();
          localStorage.setItem(
            invitee,
            JSON.stringify({
              inviteeStatus: acceptInvitation,
              inviteeEmail: inviteeEmail,
              inviteeAccountName: inviteeAccountName,
              invitedByEmailId: invitedByEmailId,
              inviteeId: inviteeId,
            })
          );
          setAfterLoginProcess(session, dispatch, !session.email, false);
        })
        .catch((err) => {
          setLoading(false);
          dispatch(
            showToast({
              isToastOpen: true,
              toastMessage: err?.response?.data?.errors?.[0]?.message,
              toastVariant: "error",
            })
          );
        });
    }
  };
  const emailFormat = (emailId) => {
    return <span style={{ color: "#2a67ff" }}>{emailId}</span>;
  };

  const renderInviteRedeemSection = () => (
    <Box textAlign="center">
      <WarningIcon className="warning-icon invite-warn-icon" />
      <Box sx={{ marginBottom: "10px" }}>
        <Text align="center" size={18} font="regular" className="default-text">
          {invalidUser}
        </Text>

        <Text
          align="center"
          size={18}
          variant="h4"
          font="regular"
          className="default-text margin-top15"
        >
          {inviteRedeem}
        </Text>
      </Box>
    </Box>
  );

  const renderJoinInviteSection = () => (
    <>
      <Text size={isMobile ? 22 : 30} font="bold">
        {invitationForDiffAccount
          ? inviteForDiffAccount
          : joinTeam(inviteeAccountName)}
      </Text>
      <Text
        size={14}
        font="regular"
        className="default-text text margin-top10"
        variant="subtitle1"
        sx={{ lineHeight: "140% !important" }}
      >
        {invitationForDiffAccount ? (
          <>
            {invitationDiffAccount(
              emailFormat(invitedByEmailId),
              emailFormat(inviteeEmail),
              inviteeAccountName
            )}
            <CustomDivider className="margin-top30" />
          </>
        ) : (
          <>
            {userInviteMsg(inviteeAccountName)}
            <CustomLink size="small">{invitedByEmailId}</CustomLink>
          </>
        )}
      </Text>
    </>
  );

  return (
    <>
      <MainLogoLogin />
      <Box className="container-layout">
        <Grid
          container
          alignItems="center"
          className="common-grid-wrapper common-mobile-grid signin-signup-container-layout"
        >
          <Grid item className="email-password-grid sign-in-grid">
            {invitationForRedeemAccount
              ? renderInviteRedeemSection()
              : renderJoinInviteSection()}
            <Button
              loading={loading}
              fullWidth
              className="submit-signin-btn margin-top30"
              label={
                invitationForDiffAccount || invitationForRedeemAccount
                  ? signOutLockedIn
                  : joinTeam()
              }
              iconPosition="end"
              icon="rightArrowIcon"
              type="submit"
              onClick={handleAcceptUserInvite}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default JoinInvitedUserAccount;
