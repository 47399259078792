import { Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import RightSideCommonPreview from "../../Common/RightSideCommonPreview";
import { content } from "../../constants";
import ConnectBrandingForm from "./ConnectBrandingForm";
import ConnectWebViewDisplay from "./ConnectWebViewDisplay";
import ConnectMobileViewDisplay from "./ConnectMobileViewDisplay";
import { useDispatch, useSelector } from "react-redux";
import { getConnectBrandingDetails } from "../../../redux/common/actions";
import PageLoader from "@speed/common/src/components/PageLoader";

const ConnectBranding = () => {
  const dispatch = useDispatch();

  const { currentAccount, liveMode } = useSelector((state) => state.auth);

  const connectBrandingDetails = useSelector(
    (state) => state.common.connectBrandingDetails
  );

  const [selected, setSelected] = useState("laptop");
  const [loader, setLoader] = useState(true);

  const formComponent = <ConnectBrandingForm />;

  useEffect(() => {
    dispatch(getConnectBrandingDetails())
      .then((res) => {
        res && setLoader(false);
      })
      .catch((_e) => {
        setLoader(false);
      });
  }, []);

  const handleChange = (_e, value) => {
    if (value) {
      setSelected(value);
    }
  };

  const commonProps = {
    currentAccount,
    connectBrandingDetails,
    livemode: liveMode,
  };

  const renderContent = () => {
    return selected === "laptop" ? (
      <ConnectWebViewDisplay {...commonProps} />
    ) : (
      <ConnectMobileViewDisplay {...commonProps} />
    );
  };

  const loaderComponent = (
    <PageLoader
      open={loader}
      alt="loading..."
      customClass="page-loader"
      sx={{ position: "absolute" }}
    />
  );

  const displayBranding = () => {
    return loader ? (
      loaderComponent
    ) : (
      <Box height="100vh" className="connect-branding-wrapper">
        <Box
          className="modal-container-grid"
          sx={{ display: "flex", height: "100%" }}
        >
          <Box className="inner-grid" sx={{ width: "16%", overflow: "auto" }}>
            {/* Left side form of respected link*/}
            {formComponent}
          </Box>
          <Box
            paddingLeft="39px"
            className="inner-grid"
            sx={{ width: "52%", backgroundColor: "#f7fafc" }}
          >
            <RightSideCommonPreview
              showPriceMsg={false}
              handleChange={handleChange}
              content={content}
              selected={selected}
              brandingURL="/settings/checkout-payment"
              infoIconVisible={false}
              customClass="connect-branding-preview"
            >
              {renderContent()}
            </RightSideCommonPreview>
          </Box>
        </Box>
      </Box>
    );
  };

  return displayBranding();
};

export default ConnectBranding;
