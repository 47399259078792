import { CustomLink } from "@speed/common/src/components/Link/Link";
import Text from "@speed/common/src/components/Text/Text";
import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import {
  domainNameValid,
  subdomainContentLink,
  subdomainContentMsg,
  subdomainName,
  subdomainPlaceholder,
} from "../../messages";
import { Input } from "@speed/common/src/components/Input/Input";
import InfoIcon from "@mui/icons-material/Info";
import { useFormik } from "formik";
import * as yup from "yup";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import { Grid } from "@mui/material";
import { isDomainValid } from "../../constants";

const AddSubDomainForm = forwardRef((props, ref) => {
  const subDomainContent = () => {
    return (
      <>
        <Text
          size={14}
          font="regular"
          variant="subtitle1"
          className="default-text"
        >
          {subdomainContentMsg}
        </Text>
        <Text
          className="grey-text"
          sx={{ marginTop: "12px" }}
          size={12}
          font="regular"
          variant="subtitle1"
        >
          {subdomainContentLink[0]}
          <CustomLink size="small" className="popper-link-content">
            {subdomainContentLink[1]}
          </CustomLink>
          {subdomainContentLink[2]}
        </Text>
      </>
    );
  };

  const validationSchema = yup.object({
    subdomain: yup
      .string()
      .matches(isDomainValid, domainNameValid)
      .required(""),
  });

  const formik = useFormik({
    initialValues: {
      subdomain: "",
    },
    validationSchema: validationSchema,
  });

  const {
    errors,
    touched,
    getFieldProps,
    setTouched,
    isValid,
    dirty,
    isSubmitting,
    values,
  } = formik;

  useEffect(() => {
    props.setBtnDisable(!(isValid && dirty) || isSubmitting);
  }, [isSubmitting, isValid, dirty]);

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      return {
        subdomain: values.subdomain.toLowerCase(),
      };
    },
  }));

  return (
    <Grid container>
      <Grid item lg={7.7}>
        <Input
          customClass="margin-top30"
          type="text"
          showLabel
          label={subdomainName}
          name="subdomain"
          error={touched.subdomain && Boolean(errors.subdomain)}
          onBlur={() => setTouched({ ...touched, subdomain: true })}
          {...getFieldProps("subdomain")}
          fullWidth
          inputProps={{ maxLength: 250 }}
          placeholder={subdomainPlaceholder}
          isIcon={true}
          iconContent={subDomainContent()}
          icon={
            <InfoIcon
              style={{
                height: 14,
                width: 14,
                color: "#848b9e",
                marginLeft: "7px",
              }}
            />
          }
          sx={{ marginBottom: "20px" }}
        />
        <InputErrorMessage {...{ touched, errors }} inputName="subdomain" />
      </Grid>
    </Grid>
  );
});

export default AddSubDomainForm;
