import React from "react";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Box } from "@mui/system";
import Text from "@speed/common/src/components/Text/Text";
import {
  domainVerification,
  domainVerified,
  sendTestEmailTitle,
  testEmailSent,
  testEmailSuccess,
  domainVerifySuccess,
} from "../../messages";

function ConfirmationModal({
  openConfirmation,
  handleCloseModal,
  domain,
  action,
  email,
}) {
  const renderTitle = () => {
    return (
      (action === "sendTestMail" ? sendTestEmailTitle : domainVerification) +
      " " +
      `"${domain}"`
    );
  };

  const renderBody = () => {
    return (
      <Box className="confirmation-box">
        <CheckCircleIcon
          style={{
            color: "#2ecc71",
            height: "62px",
            width: "62px",
            margin: "0px 0px 0px 0px",
          }}
        />
        <Text
          size={16}
          font="semibold"
          className="default-text"
          variant="inline"
          align="center"
          style={{ margin: "10px" }}
        >
          {action === "sendTestMail" ? testEmailSent : domainVerified}
        </Text>
        <Text
          size={14}
          font="regular"
          className="default-text"
          variant="inline"
          align="center"
          style={{ color: "#848b9e" }}
        >
          {action === "sendTestMail" ? (
            <>
              {`${testEmailSuccess} `}
              <b>{email}</b>
            </>
          ) : (
            <>{domainVerifySuccess}</>
          )}
        </Text>
      </Box>
    );
  };
  return (
    <Modal
      className="confirmation-modal"
      maxWidth="sm"
      body={renderBody()}
      handleClose={() => {
        handleCloseModal(false);
      }}
      open={openConfirmation}
      title={renderTitle()}
      closeIcon={true}
    />
  );
}

export default ConfirmationModal;
