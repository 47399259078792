import React, { memo } from "react";
import { Box } from "@mui/material";
import { showAmount } from "@speed/common/src/components/constants";
import EstimateFeesRowSection from "../Common/EstimateFeesRowSection";
import {
  btc,
  estimatedFeesMsg,
  feesDeclaration,
  lnurl,
  payoutTypeLightning,
  reqPayoutAmount,
  sats,
  speedFee as speedFeesTxt,
  totalPayoutDeduction,
  usdt,
} from "../messages";

const formatDisplayValues = ({
  amount,
  currency,
  targetedCurrency,
  multiplier,
}) => {
  const baseOptions = { amount, currency, targetedCurrency, multiplier };
  return {
    withComma: showAmount({ ...baseOptions, showCommaSeparated: true }),
    withoutComma: +showAmount({ ...baseOptions, showCommaSeparated: false }),
  };
};

const CommonEstimateFeesBox = ({
  estimatedFees,
  amount,
  exchangeRateValue,
  loader,
  speedFee,
  currencyCode,
  targetCurrency,
  payoutType,
}) => {
  const displayAmountCurrency = targetCurrency === sats ? btc : targetCurrency;
  const propsForEstimateSection = {
    amountInSats: amount,
    currencyCode,
    currencyToShow: displayAmountCurrency,
    exchangeRateValue,
    loader,
  };

  const formattedValues = {
    amount: formatDisplayValues({
      amount,
      currency: targetCurrency,
      targetedCurrency: displayAmountCurrency,
      multiplier: targetCurrency === usdt ? 1 : null,
    }),
    speedFee: formatDisplayValues({
      amount: speedFee,
      currency: targetCurrency,
      targetedCurrency: displayAmountCurrency,
    }),
    estimatedFees: formatDisplayValues({
      amount: estimatedFees,
      currency: targetCurrency,
      targetedCurrency: displayAmountCurrency,
    }),
  };

  // Total fees calculated based on estimatedFees, speedFeed and entered amount
  const showRangeForMethods = [payoutTypeLightning, lnurl];
  const totalFees =
    formattedValues?.estimatedFees?.withoutComma +
    formattedValues?.amount?.withoutComma +
    formattedValues?.speedFee?.withoutComma;

  // Creating the data to display fees
  const feesData = [
    {
      title: reqPayoutAmount,
      feesAmount: formattedValues?.amount?.withComma,
    },
    {
      title: speedFeesTxt,
      feesAmount: formattedValues?.speedFee?.withComma,
    },
    {
      title: estimatedFeesMsg(payoutType),
      feesAmount: formattedValues?.estimatedFees?.withComma,
      subtitle: feesDeclaration(targetCurrency),
      shouldRangeShow: showRangeForMethods.includes(payoutType), // Show range only when address is lightning .i.e "0-3 SATS"
    },
    {
      title: totalPayoutDeduction,
      feesAmount: showAmount({
        amount: totalFees,
        currency: targetCurrency,
        targetedCurrency: displayAmountCurrency,
        multiplier: 1,
        showCommaSeparated: true,
      }),
      isLast: true,
    },
  ];

  return (
    <Box className="est-fees-box">
      {feesData.map((item, index) => (
        <EstimateFeesRowSection
          key={`${item.title}_${index}`}
          {...item}
          {...propsForEstimateSection}
        />
      ))}
    </Box>
  );
};

export default memo(CommonEstimateFeesBox);
