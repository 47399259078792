import React from "react";
import GetGuides from "./GetGuides";

const TransferGuide = () => {
  return (
    <GetGuides
      moduleName="transfer"
      redirectUrl="/transfers"
      guideApiPath="transfer-guide"
      getStartedApiPath="Transfer"
      needHelpGuidePath="docs/send-payments/transfers"
    />
  );
};

export default TransferGuide;
