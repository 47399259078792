import { CustomLink } from "@speed/common/src/components/Link/Link";
import Text from "@speed/common/src/components/Text/Text";
import {
  listenToSpeedEvents,
  webhookSetupMessage,
  learnAboutWebhookMessage,
} from "../messages";
import CommonWebhookForm from "./CommonWebhookForm";

const CreateEndpointForm = (props) => {
  const {
    values,
    setFieldValue,
    touched,
    setTouched,
    errors,
    versions,
    createWebhookPage,
  } = props;
  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  return (
    <>
      <Text variant="subtitle1" size={20} className="margin-top30">
        {listenToSpeedEvents}
      </Text>
      <Text
        className="grey-text margin-top15"
        variant="subtitle1"
        size={16}
        font="regular"
      >
        {webhookSetupMessage}{" "}
        <CustomLink
          href={`${process.env.REACT_APP_SPEED_API_URL}reference/webhooks-and-events`}
          target="_blank"
        >
          {learnAboutWebhookMessage}
        </CustomLink>
      </Text>
      <CommonWebhookForm
        values={values}
        setFieldValue={setFieldValue}
        touched={touched}
        setTouched={setTouched}
        errors={errors}
        defaultErrorMessageProps={defaultErrorMessageProps}
        versions={versions}
        createWebhookPage={createWebhookPage}
      />
    </>
  );
};

export default CreateEndpointForm;
