import PropTypes from "prop-types";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import classNames from "classnames";
import "./radio-button.scss";

export const RadioButton = ({
  value,
  label,
  checked,
  disabled,
  className,
  ...props
}) => {
  return (
    <FormControlLabel
      {...props}
      className={classNames("radio-wrapper", className)}
      disabled={disabled}
      control={<Radio checked={checked} tabIndex={0} disableRipple />}
      value={value}
      label={label}
    />
  );
};

export const propTypes = {
  value: PropTypes.string,
  label: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

const defaultProps = {
  checked: false,
  disabled: false,
};

RadioButton.propTypes = propTypes;
RadioButton.defaultProps = defaultProps;
