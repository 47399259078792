import React, { useState, useEffect } from "react";
import CustomTable from "@speed/common/src/components/Table/Table";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { accountIcons } from "@speed/common/src/components/images";

const AssociatedAccounts = () => {
  const user = useSelector((state) => state.auth.user);
  const [rows, setRows] = useState([]);

  const columns = [
    { title: "Business Name", paddingLeft: "38px !important" },
    { title: "Your Role" },
    { title: "Account ID" },
    { title: "Affiliate Partner ID", align: "center" },
  ];

  const accountIconElement = (type) => {
    return (
      <Box className="associated-account-icon">
        {accountIcons()[type ? type : "Merchant"]}
      </Box>
    );
  };

  const generateRowItems = (accountDetails) => {
    return accountDetails.map((accountData) => {
      return {
        id: accountData?.account?.id,
        account_type: accountData?.account?.account_type,
        businessName: accountData?.account?.name,
        role: accountData?.role?.role_name,
        accountId: accountData?.account?.id,
        resellerPartnerId: accountData?.account?.reseller_partner_id,
      };
    });
  };

  useEffect(() => {
    if (user && user.accounts_details && user.accounts_details.length !== 0) {
      setRows(generateRowItems(user.accounts_details));
    }
  }, [user]);

  let columnsData = columns.map((column) => (
    <TableCell
      key={column?.title}
      align={column?.align}
      sx={{ pl: column?.paddingLeft }}
    >
      {column?.title}
    </TableCell>
  ));

  let rowsData = rows?.map((rowItem) => (
    <TableRow key={rowItem?.id}>
      <TableCell sx={{ pl: "38px !important" }}>
        <Box className="flex-inline">
          {accountIconElement(rowItem?.account_type)}
          <Box sx={{ pl: "15px" }}>{rowItem?.businessName}</Box>
        </Box>
      </TableCell>
      <TableCell>{rowItem?.role}</TableCell>
      <TableCell>
        <Box className="flex-inline">
          <Box sx={{ pr: "15px", m: "2.5px 0px" }}>{rowItem?.accountId}</Box>
          <Clipboard text={rowItem?.accountId} />
        </Box>
      </TableCell>
      <TableCell align="center">
        {rowItem?.resellerPartnerId || "---"}
      </TableCell>
    </TableRow>
  ));

  return (
    <>
      <CustomDivider />
      <Box className="main-content">
        <CustomTable columns={columnsData} rows={rowsData} />
      </Box>
    </>
  );
};
export default AssociatedAccounts;
