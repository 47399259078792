import { Box } from "@mui/material";
import CommonHeader from "../Common/CommonHeader";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import AllInvoices from "./AllInvoices";
import {
  invoiceStatus,
  selectTabOnSearch,
  setQueryParamsForTab,
} from "@speed/common/src/components/constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../assets/styles/invoices.scss";
import { useLocation } from "react-router-dom";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { callStrapiAPIInterface } from "../constants";
import {
  setCaughtErrorInStrapi,
  setIsDataAvailable,
  setIsModalOpen,
} from "../../redux/common/actions";
import GetStartedHeader from "@speed/common/src/components/GetStartedHeader/GetStartedHeader";

const Invoices = () => {
  const { search } = useLocation();
  const [selectedInvoiceTab, setSelectedInvoiceTab] = useState("1");
  const [invoiceGuide, setInvoiceGuide] = useState(null);
  const [isGetHeaderInfoLoading, setGetHeaderInfoLoading] = useState(true);

  const dispatch = useDispatch();
  const { liveMode } = useSelector((state) => state.auth);
  const { history, isDataAvailable, caughtErrorInStrapi, isModalOpen } =
    useSelector((state) => state.common);
  const showTabs = false;
  const invoiceTabData = [
    {
      label: "All",
      value: "1",
      tabContent: <AllInvoices />,
      tabName: "all",
    },
    {
      label: invoiceStatus.draft.label,
      value: "2",
      tabContent: <AllInvoices tab={invoiceStatus.draft.value} />,
      tabName: invoiceStatus.draft.value,
    },
    {
      label: invoiceStatus.outstanding.label,
      value: "3",
      tabContent: <AllInvoices tab={invoiceStatus.outstanding.value} />,
      tabName: invoiceStatus.outstanding.value,
    },
    {
      label: invoiceStatus.pastDue.label,
      value: "4",
      tabContent: <AllInvoices tab={invoiceStatus.pastDue.value} />,
      tabName: invoiceStatus.pastDue.value,
    },
    {
      label: invoiceStatus.paid.label,
      value: "5",
      tabContent: <AllInvoices tab={invoiceStatus.paid.value} />,
      tabName: invoiceStatus.paid.value,
    },
    {
      label: invoiceStatus.uncollectable.label,
      value: "6",
      tabContent: <AllInvoices tab={invoiceStatus.uncollectable.value} />,
      tabName: invoiceStatus.uncollectable.value,
    },
  ];

  useEffect(() => {
    const name = new URLSearchParams(search).get("status");
    selectTabOnSearch(name || null, invoiceTabData, setSelectedInvoiceTab);
    handleSetQueryParam(name || null);
  }, []);

  const getInvoiceGuide = () => {
    setGetHeaderInfoLoading(true);
    callStrapiAPIInterface(
      "GET",
      "/api/invoice-guide?populate[header][populate][use_cases][populate]=*"
    )
      .then((response) => {
        setInvoiceGuide(response?.data?.attributes?.header);
        setGetHeaderInfoLoading(false);
      })
      .catch(() => {
        dispatch(setCaughtErrorInStrapi(true));
        setGetHeaderInfoLoading(false);
      });
  };

  useEffect(() => {
    dispatch(setIsDataAvailable(true));
    getInvoiceGuide();
    return () => {
      dispatch(setIsModalOpen(false));
      dispatch(setCaughtErrorInStrapi(false));
    };
  }, [liveMode]);

  const handleChangeInvoiceTab = (_event, newValue) => {
    setSelectedInvoiceTab(newValue);
    const selectedInvoiceTabName = setQueryParamsForTab(
      invoiceTabData,
      newValue
    );
    handleSetQueryParam(selectedInvoiceTabName);
  };

  const handleSetQueryParam = (searchValue) => {
    history.push({
      pathname: "/invoices",
      search: searchValue ? `?status=${searchValue}` : null,
    });
  };

  const strapiHeaderCondition =
    !isDataAvailable && !caughtErrorInStrapi && !isModalOpen;

  return (
    <Box
      bgcolor={strapiHeaderCondition ? "#f7fafc" : ""}
      className="section-wrapper"
    >
      {strapiHeaderCondition ? (
        <GetStartedHeader
          getHeaderInfo={invoiceGuide}
          loading={isGetHeaderInfoLoading}
          setIsModalOpen={setIsModalOpen}
        />
      ) : (
        <>
          <CommonHeader />
          {!showTabs && <CustomDivider />}
          <Box className="payments-wrapper">
            {showTabs ? (
              <CustomTab
                onChange={handleChangeInvoiceTab}
                tabData={invoiceTabData}
                selectedTab={selectedInvoiceTab}
                noScroll={true}
              />
            ) : (
              <AllInvoices />
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
export default Invoices;
