import { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";

function useIntercomScript() {
  const { user, currentAccount } = useSelector(({ auth }) => auth);

  const intercomData = useMemo(() => {
    const data = {
      name: user?.name,
      id: user?.id,
      email: user?.email,
      phone: user?.phone_number
        ? `${user?.calling_code} ${user?.phone_number}`
        : undefined,
      user_timezone: user?.timezone,
      account_phone_number: currentAccount?.account?.phone_number
        ? `${currentAccount?.account?.calling_code} ${currentAccount?.account?.phone_number}`
        : undefined,
      account_name: currentAccount?.account?.name,
      account_ID: currentAccount?.account?.id,
      account_country: currentAccount?.account?.country,
      account_status: currentAccount?.account?.status,
    };
    const accountType = currentAccount?.account?.account_type;
    const appID =
      accountType === "Wallet"
        ? process.env.REACT_APP_WALLET_INTERCOM_APP_ID
        : process.env.REACT_APP_MERCHANT_INTERCOM_APP_ID;

    return { appID, ...data };
  }, [user, currentAccount]);

  useEffect(() => {
    if (process.env.REACT_APP_ENVIRONMENT === "production") {
      window?.intercomSetup(intercomData);
    }
  }, [intercomData]);
}

export default useIntercomScript;
