import React, { useContext, useEffect, useState } from "react";
import { Box } from "@mui/material";
import { authenticate, goBack, mfaResendCodeMsg } from "../../../messages";
import {
  DidNotGetCodeLink,
  MFAOTPTimer,
  TwoFASubText,
  getCurrentTimeInSecond,
} from "../../constants";
import { OTPInput } from "../../OTPInput/OTPInput";
import { ConnectContext } from "../../../contexts/contexts";
import { callAPIInterfaceConnect } from "@speed/connect/src/components/constants";
import history from "../../history";

const Connect2FA = ({ textFontFamily }) => {
  const [otp, setOtp] = useState("");
  const [timerCount, setTimerCount] = useState();
  const [isExpiredOTP, setIsExpiredOTP] = useState(false);
  const [expiresAt, setExpiresAt] = useState();
  const [loginMfaAuthDetails, setLoginMFADetails] = useState([]);
  const [loginEmail, setLoginEmail] = useState();

  // Get context value using consumer
  const connectPageData = useContext(ConnectContext);

  let timeOut;
  useEffect(() => {
    if (
      connectPageData?.session?.mfa_configurations &&
      connectPageData?.session?.otp_expires_at
    ) {
      const { email, mfa_configurations, otp_expires_at } =
        connectPageData.session;
      setLoginEmail(email);
      setLoginMFADetails(mfa_configurations);
      setExpiresAt(otp_expires_at);
      setTimerStates(otp_expires_at);
    } else {
      timeOut = setTimeout(() => {
        history.push("/connect-account");
      }, 100);
    }
    return () => clearTimeout(timeOut);
  }, [connectPageData?.session, timeOut]);

  const handleOtpChange = (newOtp) => {
    setOtp(newOtp);
    connectPageData?.setButtonDisabled(newOtp.length < 6);
  };

  const setTimerStates = (timerExpireAt) => {
    const currentSeconds = getCurrentTimeInSecond(timerExpireAt);
    setTimerCount(currentSeconds);
    currentSeconds === 0 && setIsExpiredOTP(true);
  };

  const smsConfigDetails =
    loginMfaAuthDetails?.length &&
    loginMfaAuthDetails?.filter((item) => item.mfa_type === "SMS")[0];

  useEffect(() => {
    if (expiresAt && timerCount > 0) {
      const timer = setInterval(() => {
        setTimerStates(expiresAt);
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [expiresAt, timerCount]);

  useEffect(() => {
    connectPageData?.setFirstBtnLabel(goBack);
    connectPageData?.setSecondBtnLabel(authenticate);
  }, []);

  useEffect(() => {
    if (connectPageData?.goBackClicked) {
      connectPageData?.deleteSession();
      history.push("/connect-account");
    }
  }, [connectPageData?.goBackClicked]);

  const handleResendCode = async () => {
    connectPageData?.setButtonLoader(true);
    if (connectPageData?.session) {
      const mfaAPIEndpoint = "/login/mfa/phone-number/resend-otp";
      await callAPIInterfaceConnect("POST", mfaAPIEndpoint, {
        session: connectPageData?.session?.session,
      })
        .then((response) => {
          if (response) {
            connectPageData?.setSession({
              ...connectPageData?.session,
              otp_expires_at: response.otp_expires_at,
            });
            setExpiresAt(response?.otp_expires_at);
            setTimerCount(getCurrentTimeInSecond(response?.otp_expires_at));
            connectPageData?.showToast({
              isToastOpen: true,
              toastMessage: mfaResendCodeMsg(),
              toastVariant: "success",
            });
            setIsExpiredOTP(false);
            setOtp("");
            connectPageData?.setButtonDisabled(true);
          }
        })
        .catch(() => {});
    }
    connectPageData?.setButtonLoader(false);
  };

  const handleSubmitVerifyOTP = async () => {
    connectPageData?.setButtonLoader(true);

    if (connectPageData?.session) {
      const OTPData = {
        session: connectPageData?.session?.session,
        otp,
      };

      const mfaAPIEndpoint = "/login/mfa/phone-number/verify-otp";
      callAPIInterfaceConnect("POST", mfaAPIEndpoint, OTPData)
        .then(async (response) => {
          await connectPageData?.setUpdatedSessionData(response);
          await connectPageData?.setAfterLoginProcess(response);
          connectPageData?.setIsButtonClicked(false);
          connectPageData?.setShouldLoader(false);
        })
        .catch(() => {
          connectPageData?.setButtonLoader(false);
          connectPageData?.setIsButtonClicked(false);
        });
    } else connectPageData?.setButtonLoader(false);
  };

  useEffect(() => {
    if (connectPageData?.isButtonClicked) {
      handleSubmitVerifyOTP();
    }
  }, [connectPageData?.isButtonClicked]);

  return (
    <Box
      component="form"
      display="flex"
      alignItems="center"
      width="600px"
      flexDirection="column"
      className="connect-2fa-box"
    >
      <TwoFASubText
        isFromAuth={true}
        usingPhone={true}
        phoneNumber={{
          code: `+${smsConfigDetails?.mfa_calling_code}`,
          number: smsConfigDetails?.mfa_phone_number || "",
        }}
        loginEmail={loginEmail}
        alignCenter={true}
        textFontFamily={textFontFamily}
      />
      <OTPInput
        value={otp}
        onChange={handleOtpChange}
        numInputs={6}
        containerStyle={{
          justifyContent: "space-between",
          marginTop: "40px !important",
        }}
      />
      {timerCount > 0 && (
        <MFAOTPTimer
          timerCount={timerCount}
          fromConnect={true}
          textFontFamily={textFontFamily}
        />
      )}
      {isExpiredOTP && (
        <DidNotGetCodeLink
          isFromAuth={true}
          textProps={{
            className: "expired-2fa-otp connect-two-fa",
            textFontFamily: {
              fontFamily: `${textFontFamily} !important`,
              fontWeight: 600,
            },
          }}
          handleResendCode={handleResendCode}
        />
      )}
    </Box>
  );
};

export default Connect2FA;
