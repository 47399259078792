import { Box, Grid, MobileStepper, CircularProgress } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import { RadioButton } from "@speed/common/src/components/RadioButton/RadioButton";
import Button from "@speed/common/src/components/Button/Button";
import { useState, useEffect } from "react";
import _ from "lodash";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import {
  fewDetailsQnA,
  littleBitAboutYou,
  quickQuestion,
  skipQuestion,
  submit,
} from "../messages";
import {
  otherLabel,
  back,
  next,
  radio,
  skip,
  initial,
  checkbox,
  pleaseSpecify,
  inProgress,
  Wallet,
} from "@speed/common/src/components/messages";
import { useDispatch, useSelector } from "react-redux";
import {
  callAPIInterface,
  callStrapiAPIInterface,
  whiteLogoImage,
} from "../constants";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import { Input } from "@speed/common/src/components/Input/Input";
import RadioGroup from "@mui/material/RadioGroup";
import history from "@speed/common/src/components/history";
import PageLoader from "@speed/common/src/components/PageLoader";
import { getUser } from "../../redux/auth/actions";
import AnswerSkeleton from "./AnswerSkeleton";

const OnBoarding = () => {
  const totalStep = 6;
  const isMobile = useSelector((state) => state.common.isMobile);
  const { user, currentAccount } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const [activeStep, setActiveStep] = useState();
  const [progress, setProgress] = useState(0);
  const [customerProfiling, setCustomerProfiling] = useState([]);
  const [selectedValues, setSelectedValues] = useState([]);
  const [nextDisabled, setNextDisabled] = useState(true);
  const [nextLoading, setNextLoading] = useState(false);
  const [skipLoading, setSkipLoading] = useState(false);
  const [getUserResponse, setGetUserResponse] = useState([]);
  const [isNextArrowDisabled, setIsNextArrowDisabled] = useState(false);
  const [editedAnswer, setEditedAnswer] = useState(false);
  const [answerLoading, setAnswerLoading] = useState(false);
  const [isSkipBtnVisible, setIsSkipBtnVisible] = useState(true);
  const isLoadingPage = customerProfiling?.length && activeStep;

  const getCustomerProfiling = () => {
    callStrapiAPIInterface(
      "GET",
      "/api/customer-profilings?populate[answers][populate]=*"
    )
      .then((response) => {
        setCustomerProfiling(response.data);
      })
      .catch(() => {});
  };

  const getQuestionnaireResponse = async (status = inProgress) => {
    return new Promise((resolve) => {
      setIsNextArrowDisabled(true);
      setAnswerLoading(true);
      callAPIInterface("GET", "/onboarding?module=customer_profiling")
        .then((response) => {
          if (response.data?.length > 0) {
            const currentStep = response.data[0]?.response?.length + 1;
            setGetUserResponse(response.data[0]?.response);
            setAnswerLoading(false);

            if (status === initial) {
              setActiveStep(currentStep);
            }
            setProgress(currentStep - 1);
          } else {
            setActiveStep(1);
            setAnswerLoading(false);
          }
        })
        .finally(() => {
          setIsNextArrowDisabled(false);
          resolve(true);
        });
    });
  };

  const userResponseData = (handle) => {
    setSelectedValues([]);
    setNextDisabled(true);
    setEditedAnswer(false);

    const currentStep = handle === back ? activeStep - 1 : activeStep + 1;
    const userID = "que_" + currentStep;
    getUserResponse?.map((usersResponse) => {
      if (usersResponse.id === userID) {
        const isDisbleNext = usersResponse.answers?.find(
          (value) => value.id === "ans_skip"
        );
        setSelectedValues(usersResponse.answers);
        setNextDisabled(isDisbleNext);
      }
    });
  };

  const handleBack = async () => {
    await getQuestionnaireResponse();
    userResponseData(back);
    setActiveStep((steps) => steps - 1);
  };

  const handleNext = async () => {
    await getQuestionnaireResponse();
    userResponseData(next);
    setActiveStep((steps) => steps + 1);
  };

  const checkingSelectedValue = (answerId, forInputTxt = false) => {
    const check = selectedValues?.find((value) => {
      return value.id === answerId;
    });
    return forInputTxt ? check?.text : check;
  };

  const setInputSelectedValue = (answerId, textData, type) => {
    const trimmedText = textData?.trim();
    setNextDisabled(!trimmedText);
    const answerData = {
      id: answerId,
      text: textData,
    };
    if (type === radio) {
      const values = [answerData];
      const prevAnsArray = getOlderAnsDetails();
      setSelectedValues(values);
      setEditedAnswer(!_.isEqual(values, prevAnsArray));
    } else {
      const values = [...selectedValues];
      const inputIndex = values?.findIndex((value) => {
        return value.id === answerId;
      });

      values.splice(inputIndex, 1, answerData);
      setSelectedValues(values);

      const prevAnsArray = getOlderAnsDetails();
      setEditedAnswer(
        !_.isEqual(
          _.sortBy(values, (o) => o.id),
          _.sortBy(prevAnsArray, (o) => o.id)
        )
      );
    }
  };

  const textInputOptions = (answer, btnType) => {
    return (
      <Input
        autoFocus
        showLabel={false}
        type="text"
        onChange={(e) => {
          setInputSelectedValue(answer?.answer_id, e.target.value, btnType);
        }}
        value={checkingSelectedValue(answer?.answer_id, true)}
        sx={{
          width: !isMobile ? "381px" : "auto",
        }}
        placeholder={pleaseSpecify}
        inputProps={{ maxLength: 100 }}
      />
    );
  };

  const getOlderAnsDetails = () => {
    const questionID = "que_" + activeStep;
    const answerArray = getUserResponse?.filter(
      (usersResponse) => usersResponse.id === questionID
    )?.[0]?.answers;
    return answerArray || [];
  };

  const handleChange = (type, answer) => {
    const answerId = answer.answer_id;
    const answerData = {
      id: answerId,
      text: answer.answer === otherLabel ? "" : answer.answer,
    };

    if (type == radio) {
      const values = [answerData];
      const prevAnsArray = getOlderAnsDetails();
      setSelectedValues(values);
      setNextDisabled(answer.answer === otherLabel);
      setEditedAnswer(!_.isEqual(values, prevAnsArray));
    } else {
      const values = [...selectedValues];
      const inputIndex = values?.findIndex((value) => {
        return value.id === answerId;
      });

      const textInputIndex = values?.findIndex((value) => {
        return value.text === "";
      });

      if (inputIndex > -1) {
        //calls while unchecking answers
        values.splice(inputIndex, 1);
        const valuesWoSkip = values.filter((v) => v.id !== "ans_skip");
        textInputIndex > -1 && answer.answer != otherLabel
          ? setNextDisabled(true)
          : setNextDisabled(valuesWoSkip.length === 0);
      } else {
        //calls while checking answers
        values.push(answerData);
        textInputIndex === -1 && setNextDisabled(answer.answer === otherLabel);
      }

      const prevAnsArray = getOlderAnsDetails();
      setEditedAnswer(
        !_.isEqual(
          _.sortBy(values, (o) => o.id),
          _.sortBy(prevAnsArray, (o) => o.id)
        )
      );
      setSelectedValues(values);
    }
  };

  const saveUserResponse = (type, question, activeStep) => {
    setNextDisabled(true);
    setIsNextArrowDisabled(true);
    if (type === skip) {
      setSkipLoading(true);
    } else {
      setNextLoading(true);
    }

    const skipAnswer = [
      {
        id: "ans_skip",
        text: "skip",
      },
    ];

    const data = {
      id: question,
      module: "customer_profiling",
      answers:
        type === next
          ? selectedValues.filter((v) => v.id !== "ans_skip")
          : skipAnswer,
    };

    callAPIInterface("POST", "/onboarding", JSON.stringify(data))
      .then(() => {
        if (type === skip) {
          setSkipLoading(false);
        }
        if (activeStep === totalStep) {
          setIsSkipBtnVisible(false);
          callAPIInterface("POST", "/signup/customer-profiling/submit").then(
            () => {
              type === next ? setNextLoading(false) : setSkipLoading(false);
              dispatch(getUser());
              history.push("/dashboard");
            }
          );
        } else {
          type === next ? setNextLoading(false) : setSkipLoading(false);
          handleNext();
        }
      })
      .catch(() => {
        setSkipLoading(false);
        setNextLoading(false);
        setNextDisabled(true);
        setIsNextArrowDisabled(false);
      });
  };

  useEffect(() => {
    const moveToDashboard =
      user?.profiling_completed ||
      currentAccount?.account?.account_type === Wallet;
    if (moveToDashboard) {
      history.push("/dashboard");
    }

    getCustomerProfiling();
    getQuestionnaireResponse(initial);
  }, []);

  return isLoadingPage ? (
    <Box className="ums-auth-cotaniner">
      <Box className={`onboarding-qna ${isMobile && "onboarding-qna-mobile"}`}>
        {whiteLogoImage}
        <Text variant="h2" font="regular" className="onboarding-header">
          {fewDetailsQnA}
        </Text>
        <Grid
          container
          className="common-grid-wrapper onboarding-container-layout"
        >
          <Box className="onboarding-box-layout">
            <Text size={24} font="regular" className="quick-question-header">
              {quickQuestion}
            </Text>
            <Text
              size={18}
              font="regular"
              variant="h4"
              sx={{
                lineHeight: "140% !important",
              }}
            >
              {littleBitAboutYou}
            </Text>
          </Box>
          {customerProfiling?.map((customerProfile, index) => {
            const prevAnsArray = getOlderAnsDetails();
            const showSkipButton =
              !answerLoading &&
              ((!selectedValues?.length && !prevAnsArray?.length) ||
                (selectedValues?.length === 1 &&
                  selectedValues?.find((value) => value.id === "ans_skip")));

            if (index + 1 === activeStep)
              return (
                <>
                  <Box className="onboarding-questions">
                    {answerLoading ? (
                      <AnswerSkeleton />
                    ) : (
                      <>
                        <Text
                          size="18"
                          font="regular"
                          className="onboarding-question-text"
                        >
                          {customerProfile?.attributes?.question}
                        </Text>
                        <Box className="onboarding-options">
                          {customerProfile?.attributes?.answers.map(
                            (answer, index) => {
                              const isSelectedOption = checkingSelectedValue(
                                answer?.answer_id
                              );
                              const inputType =
                                customerProfile?.attributes?.inputType;

                              return inputType === radio ? (
                                <>
                                  <RadioGroup>
                                    <RadioButton
                                      label={answer?.answer}
                                      name="answer"
                                      onChange={() => {
                                        handleChange(inputType, answer);
                                      }}
                                      className={
                                        isSelectedOption &&
                                        `selected-onboarding-option`
                                      }
                                      sx={{
                                        width: "fit-content",
                                        paddingBottom:
                                          customerProfile?.attributes?.answers
                                            .length -
                                            1 ===
                                          index
                                            ? "9px"
                                            : "14px",
                                      }}
                                      checked={isSelectedOption}
                                    />
                                  </RadioGroup>
                                  {answer?.is_text_input &&
                                    isSelectedOption &&
                                    textInputOptions(answer, radio)}
                                </>
                              ) : (
                                <>
                                  <CustomCheckbox
                                    label={answer?.answer}
                                    sx={{
                                      paddingBottom: "10px",
                                      width: "fit-content",
                                    }}
                                    onChange={() => {
                                      handleChange(inputType, answer);
                                    }}
                                    className={
                                      isSelectedOption &&
                                      `selected-onboarding-option`
                                    }
                                    checked={isSelectedOption}
                                  />
                                  {answer?.is_text_input &&
                                    isSelectedOption &&
                                    textInputOptions(answer, checkbox)}
                                </>
                              );
                            }
                          )}
                        </Box>
                      </>
                    )}
                  </Box>
                  <Box className="onboarding-footer">
                    <Box className="onbaording-mobile-stepper">
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Button
                          className="onboarding-arrow"
                          label={<KeyboardArrowLeft />}
                          type="button"
                          variant="default"
                          onClick={handleBack}
                          disabled={activeStep === 1 || answerLoading}
                        />
                        <Box className="pagination-onboarding">
                          {activeStep}/{totalStep}
                        </Box>
                        <Button
                          className="onboarding-arrow"
                          label={<KeyboardArrowRight />}
                          type="button"
                          variant="default"
                          disabled={
                            activeStep === totalStep ||
                            isNextArrowDisabled ||
                            answerLoading
                          }
                          onClick={() => {
                            activeStep <= getUserResponse?.length
                              ? handleNext()
                              : saveUserResponse(
                                  skip,
                                  customerProfile?.attributes?.question_id,
                                  activeStep
                                );
                          }}
                        />
                      </Box>
                      <MobileStepper
                        variant="progress"
                        steps={7}
                        position="static"
                        activeStep={progress}
                        sx={{ flexGrow: 1 }}
                        className="mobile-stepper"
                      />
                    </Box>
                    <Box className="onboarding-footer-button">
                      <Box sx={{ width: !isMobile ? "200px" : "auto" }}>
                        {showSkipButton && isSkipBtnVisible && (
                          <Button
                            className="goback-btn onboarding-skip-btn"
                            sx={{ width: !isMobile ? "200px" : "100%" }}
                            label={
                              skipLoading ? (
                                <CircularProgress
                                  style={{
                                    width: "15px",
                                    height: "15px",
                                    color: "#848b9e",
                                    margin: "7px 0 7px 0",
                                  }}
                                />
                              ) : (
                                skipQuestion
                              )
                            }
                            type="button"
                            variant="default"
                            onClick={() => {
                              saveUserResponse(
                                skip,
                                customerProfile?.attributes?.question_id,
                                activeStep
                              );
                            }}
                            loading={skipLoading}
                          />
                        )}
                      </Box>
                      <Box
                        sx={{
                          width: !isMobile ? "138px" : "auto",
                          height: "42px",
                        }}
                      >
                        <Button
                          label={activeStep === 6 ? submit : next}
                          fullWidth
                          className="onboarding-next-btn"
                          iconPosition="end"
                          icon="rightArrowIcon"
                          onClick={() => {
                            !editedAnswer &&
                            activeStep <= getUserResponse?.length
                              ? handleNext()
                              : saveUserResponse(
                                  next,
                                  customerProfile?.attributes?.question_id,
                                  activeStep
                                );
                          }}
                          disabled={nextDisabled}
                          loading={nextLoading}
                        />
                      </Box>
                    </Box>
                  </Box>
                </>
              );
          })}
        </Grid>
      </Box>
    </Box>
  ) : (
    <PageLoader
      open={!isLoadingPage}
      alt="loading..."
      customClass="page-loader"
    />
  );
};
export default OnBoarding;
