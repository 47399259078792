import React from "react";
import { StyledEngineProvider } from "@mui/material/styles";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import "./assets/styles/main.scss";
import Layout from "./container/Layout";

function App() {
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <Layout />
      </StyledEngineProvider>
    </Provider>
  );
}

export default App;
