import React from "react";
import Text from "@speed/common/src/components/Text/Text";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { Button } from "@speed/common/src/components/Button/Button";
import ReportProblemRoundedIcon from "@mui/icons-material/ReportProblemRounded";
import { sessionService } from "redux-react-session";
import { useDispatch, useSelector } from "react-redux";
import {
  continueDashboard,
  expiredLinkMsg,
  expiredLinkSubMsg,
  linkAlreadyVerifiedMsg,
  passwordLinkExpired,
  passwordLinkExpiredSubMsg,
  resettingPassword,
} from "../messages";
import { resendVerification } from "../../redux/auth/actions";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { linkAlreadySubText } from "../constants";
import MainLogoLogin from "./MainLogoLogin";
import { resendMsg } from "@speed/common/src/messages";

const LinkStatus = ({ from, type, ...props }) => {
  const dispatch = useDispatch();
  const history = useSelector((state) => state.common.history);

  const handleVerifyEmail = () => {
    if (type === "used") {
      sessionService.deleteUser();
      history.push("/dashboard");
    } else if (type === "expired") {
      dispatch(resendVerification({ email: props.email }));
    }
  };

  const handleResetPassword = () => {
    history.push("/reset-password");
    sessionService.deleteUser();
  };

  let title = "";
  let text = "";
  let actionButton = null;

  const checkLinkType = () => {
    if (type === "expired") {
      title = expiredLinkMsg;
      text = expiredLinkSubMsg;
      actionButton = (
        <Button
          label={resendMsg}
          fullWidth
          className="margin-top30"
          onClick={handleVerifyEmail}
        />
      );
    } else {
      title = linkAlreadyVerifiedMsg;
      text = linkAlreadySubText(props.email);
      actionButton = (
        <Button
          label={continueDashboard}
          fullWidth
          className="margin-top58"
          onClick={handleVerifyEmail}
        />
      );
    }
  };

  switch (from) {
    case "verifyEmail":
      checkLinkType();
      break;
    case "resetPassword":
      title = passwordLinkExpired;
      text = passwordLinkExpiredSubMsg;
      actionButton = (
        <Button
          label={resettingPassword}
          fullWidth
          className="margin-top58"
          onClick={handleResetPassword}
        />
      );
      break;
    case "confirmEmail":
      checkLinkType();
      break;
  }

  return (
    <>
      <MainLogoLogin />
      <Box className="container-layout">
        <Grid
          container
          alignItems="center"
          className="common-grid-wrapper signin-signup-container-layout"
        >
          {type === "used" &&
          (from === "verifyEmail" || from === "confirmEmail") ? (
            <CheckCircleIcon
              style={{
                color: "#2ecc71",
                height: "62px",
                width: "62px",
                margin: "30px 0px 30px 0",
              }}
            />
          ) : (
            <Box className="expire-icon-box">
              <ReportProblemRoundedIcon
                sx={{ height: "59px", width: "60px", color: "#e74c3c" }}
              />
            </Box>
          )}
          <Text
            size={20}
            align="center"
            font="semibold"
            className="default-text"
          >
            {title}
          </Text>
          <Text
            size={16}
            align="center"
            font="regular"
            className="default-text margin-top20"
            variant="subtitle1"
          >
            {" "}
            {text}
          </Text>
          {actionButton}
        </Grid>
      </Box>
    </>
  );
};

export default LinkStatus;
