import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import { active, all, deActivated, paid } from "../messages";
import CommonHeader from "./CommonHeader";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import { useLocation } from "react-router-dom";
import CheckoutLinkList from "../CheckoutLinks/LinksList";
import PaymentLinks from "../PaymentLinks/LinksList";
import {
  selectTabOnSearch,
  setQueryParamsForTab,
} from "@speed/common/src/components/constants";
import WithdrawalLinks from "../WithdrawalLinks/LinkList";
import CashbackList from "../Cashback/CashbackList";
import GetStartedHeader from "@speed/common/src/components/GetStartedHeader/GetStartedHeader";
import { callStrapiAPIInterface } from "../constants";
import {
  setCaughtErrorInStrapi,
  setIsDataAvailable,
  setIsModalOpen,
} from "../../redux/common/actions";
import SessionsList from "../Sessions/SessionsList";

const CommonLinkTabs = ({ from }) => {
  const dispatch = useDispatch();
  const allowedPaidModules = ["checkout-link", "withdrawal-link", "sessions"];
  const { search, state } = useLocation();
  const { liveMode } = useSelector((state) => state.auth);
  const { history, isDataAvailable, caughtErrorInStrapi, isModalOpen } =
    useSelector((state) => state.common);

  const [selectedTab, setSelectedTab] = useState("1");
  const [guideLinkInfoData, setGuideLinkInfoData] = useState(null);
  const [isGetHeaderInfoLoading, setGetHeaderInfoLoading] = useState(true);

  useEffect(() => {
    //used when user searches manually
    const name = new URLSearchParams(search).get("status");
    selectTabOnSearch(name || null, tabData, setSelectedTab); //To set the tab
    handleSetQueryParam(name || null); // To set query params
  }, []);

  useEffect(() => {
    if (!state || state?.setIsDataAvailable) {
      dispatch(setIsDataAvailable(true));
    }
    getLinksGuideInfoData();
    return () => {
      dispatch(setIsModalOpen(false));
      dispatch(setCaughtErrorInStrapi(false));
    };
  }, [liveMode]);

  const getLinksGuideInfoData = () => {
    setGetHeaderInfoLoading(true);
    callStrapiAPIInterface(
      "GET",
      `/api/${from}-guide?populate[header][populate][use_cases][populate]=*`
    )
      .then((response) => {
        setGuideLinkInfoData(response?.data?.attributes?.header);
        setGetHeaderInfoLoading(false);
      })
      .catch((error) => {
        dispatch(setCaughtErrorInStrapi(true));
        setGetHeaderInfoLoading(false);
      });
  };

  const renderComponent = (from, tab) => {
    switch (from) {
      case "checkout-link":
        return {
          component: <CheckoutLinkList tab={tab} />,
          path: "/checkout-links",
        };
      case "payment-link":
        return {
          component: <PaymentLinks tab={tab} />,
          path: "/payment-links",
        };
      case "withdrawal-link":
        return {
          component: <WithdrawalLinks tab={tab} />,
          path: "/withdrawal-links",
        };
      case "cashbacks":
        return {
          component: <CashbackList tab={tab} />,
          path: "/cashbacks",
        };
      case "sessions":
        return {
          component: <SessionsList tab={tab} />,
          path: "/sessions",
        };
      default:
        return null;
    }
  };

  const tabData = [
    {
      label: all,
      value: "1",
      tabContent: renderComponent(from).component,
      tabName: "all",
    },
    {
      label: active,
      value: "3",
      tabContent: renderComponent(from, "active").component,
      tabName: "active",
    },
    {
      label: deActivated,
      value: "4",
      tabContent: renderComponent(from, "deactivated").component,
      tabName: "deactivate",
    },
  ];

  if (allowedPaidModules.includes(from))
    tabData.splice(1, 0, {
      label: paid,
      value: "2",
      tabContent: renderComponent(from, "paid").component,
      tabName: "paid",
    });

  const handleChange = (_event, newValue) => {
    dispatch(setIsDataAvailable(true));
    setSelectedTab(newValue);
    const selectedTabName = setQueryParamsForTab(tabData, newValue);
    handleSetQueryParam(selectedTabName);
  };

  const handleSetQueryParam = (searchValue) => {
    history.push({
      pathname: renderComponent(from).path,
      search: searchValue ? `?status=${searchValue}` : null,
    });
  };

  const strapiHeaderCondition =
    !isDataAvailable &&
    !caughtErrorInStrapi &&
    selectedTab === "1" &&
    !isModalOpen;

  return (
    <Box
      bgcolor={strapiHeaderCondition ? "#f7fafc" : ""}
      className="section-wrapper"
    >
      {strapiHeaderCondition ? (
        <GetStartedHeader
          component={renderComponent(from).component}
          getHeaderInfo={guideLinkInfoData}
          loading={isGetHeaderInfoLoading}
          setIsModalOpen={setIsModalOpen}
        />
      ) : (
        <>
          <CommonHeader />
          <Box className="payment-links-wrapper">
            <CustomTab
              onChange={handleChange}
              tabData={tabData}
              selectedTab={selectedTab}
              noScroll={true}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default CommonLinkTabs;
