import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Skeleton } from "@mui/material";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import { Input } from "@speed/common/src/components/Input/Input";
import Text from "@speed/common/src/components/Text/Text";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import UploadFile from "@speed/common/src/components/UploadFile";
import { useFormik } from "formik";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import * as yup from "yup";
import { callAPIInterface } from "../constants";
import {
  productDescription,
  productDetails,
  productName,
  productNamePlaceHolder,
  productPhoto,
  remove,
  uploadImageInfo,
} from "../messages";
import { showToast } from "../../redux/common/actions";

const ProductTID = forwardRef((props, ref) => {
  const [imageLoader, setImageLoader] = useState(false);

  const validationSchema = yup.object({
    name: yup.string().required(""),
  });

  const formik = useFormik({
    initialValues: {
      image: "",
      description: "",
      name: "",
    },
    validationSchema: validationSchema,
  });

  const {
    values,
    errors,
    isValid,
    isSubmitting,
    dirty,
    setFieldValue,
    touched,
    setTouched,
  } = formik;

  useEffect(() => {
    const disable = !(isValid && dirty) || isSubmitting;
    props.setDisabled(disable);
  }, [dirty, isValid, isSubmitting, values]);

  const handleEnterKeyPress = (event) => {
    if (event.keyCode === 13) {
      event.currentTarget.click();
    }
  };

  useImperativeHandle(ref, () => ({
    handleSubmit: () => {
      const params = {
        image: values.image,
        description: values.description,
        name: values.name,
      };
      return params;
    },
  }));

  const responseImage = (product) => {
    setFieldValue("image", product.file_name);
    setImageLoader(false);
  };

  const handleUploadTempImage = async (file, fileName) => {
    try {
      const apiRes = await callAPIInterface(
        "POST",
        "/upload-image",
        null,
        file,
        fileName
      );
      responseImage(apiRes);
    } catch (error) {
      setImageLoader(false);
    }
  };

  return (
    <Box
      className="product-info"
      width="30%"
      component="form"
      autoComplete="off"
      sx={props.isCreateProduct ? { display: "none" } : { display: "block" }}
    >
      <Text size={18} font="semibold" sx={{ margin: "15px 0px" }}>
        {productDetails}
      </Text>

      <Box className="product-img-box" display="flex" alignItems="center">
        {values.image && (
          <Box className="product-image-preview">
            <img src={values.image} className="product-img link-form" />
            <Box
              tabIndex={0}
              onKeyDown={handleEnterKeyPress}
              title={remove}
              className="remove-icon-product"
              onClick={() => {
                setFieldValue("image", "");
              }}
            >
              <CloseIcon />
            </Box>
          </Box>
        )}
        <label htmlFor="image_upload" style={{ display: "block" }}>
          <UploadFile
            showLoader={(status) => setImageLoader(status)}
            imgDimension={200}
            apiFunc={handleUploadTempImage}
            id="image_upload"
            showToast={showToast}
          />
          {imageLoader ? (
            <Skeleton
              sx={{ height: "120px", width: "120px" }}
              variant="rectangular"
              animation="wave"
            />
          ) : (
            !values.image && (
              <Box
                component="div"
                onKeyDown={handleEnterKeyPress}
                className="image-upload-box link-form"
              >
                <AddIcon className="add-icon" />
              </Box>
            )
          )}
        </label>
        <Text
          size={16}
          font="semibold"
          sx={{ width: "212px", marginLeft: "25px" }}
        >
          {productPhoto}
          <Text size={14} font="regular" variant="h6" className="grey-text">
            {uploadImageInfo}
          </Text>
        </Text>
      </Box>
      <Input
        fullWidth
        customClass="mt-24"
        label={productName}
        placeholder={productNamePlaceHolder}
        name="name"
        value={values.name}
        type="name"
        onBlur={() => setTouched({ ...touched, name: true })}
        inputProps={{ maxLength: 255 }}
        onChange={(e) => {
          setTouched({ ...touched, name: false });
          setFieldValue("name", e.target.value);
        }}
        error={touched.name && Boolean(errors.name)}
      />
      {touched.name && Boolean(errors.name) && (
        <AlertMessage
          message={errors.name}
          severity="error"
          className="margin-top14"
        />
      )}
      <Box marginTop="24px">
        <TextAreaComponent
          showLabel
          label={productDescription}
          value={values.description}
          maxRows={3}
          minRows={3}
          maxLength={255}
          fullWidth
          error={Boolean(errors.description)}
          onBlur={() => setTouched({ ...touched, description: true })}
          onChange={(e) => {
            setFieldValue("description", e.target.value);
          }}
        />
      </Box>
    </Box>
  );
});

export default ProductTID;
