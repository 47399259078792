import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/system";
import { InputBase } from "@mui/material";
import classNames from "classnames";
import "./color-picker.scss";
import { validateColor } from "../constants";

export const ColorPicker = ({ color, getSelectedColor, id, className }) => {
  const [selectedColor, setSelectedColor] = useState(color);
  const [focusedInput, setFocusInput] = useState(false);
  const [errorInput, setErrorInput] = useState(false);

  const colorWithOutHex = (colorWithHex) => {
    return colorWithHex.replace("#", "");
  };

  const rgbToHexColor = (rgbColor) => {
    var a = rgbColor.split("(")[1].split(")")[0];
    a = a.split(",");
    var hexColor = a.map((x, index) => {
      if (index < 3) {
        x = parseInt(x).toString(16);
        return x.length == 1 ? "0" + x : x;
      }
    });
    hexColor = hexColor.join("");
    return hexColor;
  };

  const getPreviewColor = () => {
    let savedRGBColor = document.querySelector(`#${id}`)?.style?.background;
    return "#" + rgbToHexColor(savedRGBColor);
  };

  useEffect(() => {
    if (color) {
      let isValidColor = validateColor(color);
      isValidColor && setErrorInput(false);
      setSelectedColor(colorWithOutHex(color));
      getSelectedColor && getSelectedColor(color, color);
    }
  }, [color]);

  const onChangeColor = (e, isInputChange) => {
    let inputColor = colorWithOutHex(e.target.value);
    if (inputColor.length > 6) {
      return;
    } else {
      let hexInputColor = "#" + inputColor;
      let previewColor = getPreviewColor();
      let isValidColor = validateColor(hexInputColor);
      isValidColor ? setErrorInput(false) : setErrorInput(true);
      setSelectedColor(inputColor);
      getSelectedColor &&
        getSelectedColor(
          inputColor ? hexInputColor : "",
          isInputChange ? previewColor : hexInputColor
        );
    }
  };

  const handleColorBlur = (_e) => {
    setFocusInput(false);
    if (!selectedColor && id) {
      setErrorInput(false);
      let previewColor = getPreviewColor();
      setSelectedColor(colorWithOutHex(previewColor));
      getSelectedColor && getSelectedColor(previewColor, previewColor);
    }
  };

  return (
    <Box
      className={classNames(
        "color-picker-wrapper",
        focusedInput && "focused",
        errorInput && "input-error",
        className
      )}
    >
      <label className="color-selector">
        <Box
          component="span"
          id={id}
          className="rectangle-color-preview"
          style={{ background: "#" + selectedColor }}
        />
        <input
          type="color"
          value={"#" + selectedColor}
          onChange={(e) => onChangeColor(e)}
          className="hidden"
        />
      </label>
      <Box component="span" className="hash-element">
        #
      </Box>
      <InputBase
        onBlur={handleColorBlur}
        onFocus={(event) => {
          event.target.select();
          setFocusInput(true);
        }}
        value={selectedColor}
        onChange={(e) => onChangeColor(e, true)}
        sx={{ fontSize: "14px" }}
      />
    </Box>
  );
};

export const propTypes = {
  color: PropTypes.string,
  id: PropTypes.string,
};

const defaultProps = {
  color: "#f9df00",
  id: "color-picker",
};

ColorPicker.propTypes = propTypes;
ColorPicker.defaultProps = defaultProps;
