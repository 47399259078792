import React from "react";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { Box } from "@mui/system";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";

const DetailPagePaymentSkeleton = () => {
  return (
    <>
      <Box className="product-content" marginTop={"50px !important"}>
        <CommonSkeleton
          width={89}
          height={18}
          color="#c4ccd2"
          sx={{ borderRadius: "8px" }}
        />
      </Box>

      <CustomDivider sx={{ marginTop: "32px" }} />

      <Box display={"flex"} marginTop={"9px"}>
        <CommonSkeleton
          width={78}
          sx={{ marginRight: "42%", borderRadius: "8px" }}
        />
        <CommonSkeleton
          width={78}
          sx={{ marginRight: "5%", borderRadius: "8px" }}
        />
        <CommonSkeleton
          width={49}
          sx={{ marginRight: "14%", borderRadius: "8px" }}
        />
        <CommonSkeleton width={49} sx={{ borderRadius: "8px" }} />
      </Box>

      <CustomDivider sx={{ marginTop: "10px" }} />

      <Box display={"flex"} marginTop={"15px"}>
        <CommonSkeleton
          width={210}
          sx={{ marginRight: "29%", borderRadius: "8px" }}
          color="#c4ccd2"
        />
        <CommonSkeleton
          width={56}
          sx={{ marginRight: "7%", borderRadius: "8px" }}
          color="#c4ccd2"
        />
        <CommonSkeleton
          width={154}
          sx={{ marginRight: "4%", borderRadius: "8px" }}
          color="#c4ccd2"
        />
        <CommonSkeleton width={62} />
      </Box>
    </>
  );
};

export default DetailPagePaymentSkeleton;
