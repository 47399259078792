import React, { useEffect, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import { callStrapiAPIInterface } from "../constants";
import "../../assets/styles/get-started.scss";
import Text from "@speed/common/src/components/Text/Text";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import NeedHelp from "./NeedHelp";
import GuideHeader from "./GuideHeader";
import { moduleNameIntialSetup } from "../messages";

const InitialSetupGuide = () => {
  const [onboardingInitialSetupSteps, setOnboardingInitialSetupSteps] =
    useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    getOnboardingInitialSetupGuide();
  }, []);

  const getOnboardingInitialSetupGuide = () => {
    setIsLoading(true);
    callStrapiAPIInterface(
      "GET",
      "/api/get-started-speeds?filters[title][$containsi]=Initial&[populate]=*"
    )
      .then((response) => {
        setOnboardingInitialSetupSteps(response?.data?.[0]?.attributes);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const listInitialSetupLoader = () =>
    [...Array(7).keys()]?.map((steps) => (
      <React.Fragment key={steps?.id}>
        <Box display="flex" flexDirection="row" paddingTop="40px">
          <Skeleton
            animation="wave"
            variant="circular"
            width={25}
            height={25}
            sx={{ bgcolor: "#EAEEF1 !important", margin: "2px 14px 0px 0px" }}
          />
          <Box paddingLeft="16px" className="header-text">
            <Box display="flex" flexDirection="row" alignItems="center">
              <CustomLink
                size="Large"
                style={{
                  fontSize: "22px",
                  color: "#0A193E",
                  fontWeight: "600px",
                  fontFamily: "Inter-SemiBold",
                }}
              >
                <Skeleton
                  animation="wave"
                  width={307}
                  height={20}
                  sx={{
                    bgcolor: "#C4CCD2 !important",
                  }}
                />
              </CustomLink>
            </Box>
            <Text
              variant="body1"
              font="regular"
              className="grey-text"
              size={18}
              style={{ width: "700px", paddingBottom: "40px" }}
            >
              <Skeleton
                animation="wave"
                width={700}
                height={14}
                sx={{
                  bgcolor: "#EAEEF1 !important",
                }}
              />
            </Text>
          </Box>
        </Box>
        {steps !== [...Array(7).keys()].length - 1 && (
          <Box
            className="steps-border"
            borderBottom="1px solid #E4E9EE"
            width="1100px"
          />
        )}
      </React.Fragment>
    ));

  const initialSetupLoader = () => {
    return (
      <Box className="section-wrapper" bgcolor="#f7fafc">
        <Box paddingTop="46px" className="main-content-initial-setup">
          <Box className="guide-header">
            <Box display="flex" flexDirection="row">
              <Skeleton
                animation="wave"
                variant="circular"
                width={100}
                height={100}
                sx={{
                  bgcolor: "#EAEEF1 !important",
                  margin: "2px 14px 0px 0px",
                }}
              />
              <Box paddingLeft="26px" className="header-text">
                <Text font="semi-bold" size={24}>
                  <Skeleton
                    animation="wave"
                    width={307}
                    height={20}
                    sx={{
                      bgcolor: "#C4CCD2 !important",
                    }}
                  />
                </Text>
                <Text
                  variant="body1"
                  font="regular"
                  className="grey-text"
                  size={18}
                >
                  <Skeleton
                    animation="wave"
                    width={700}
                    height={14}
                    sx={{
                      bgcolor: "#EAEEF1 !important",
                    }}
                  />
                </Text>
              </Box>
            </Box>
            <Box className="steps-border" paddingTop="50px" width="1100px">
              <Box display="flex" flexDirection="row" paddingBottom="8px">
                <Text
                  variant="body1"
                  font="regular"
                  className="grey-text"
                  size={18}
                  align="left"
                  style={{ paddingRight: "4px" }}
                >
                  <Skeleton
                    animation="wave"
                    width={172}
                    height={14}
                    sx={{
                      bgcolor: "#C4CCD2 !important",
                    }}
                  />
                </Text>
              </Box>
              <Skeleton
                className="steps-border"
                animation="wave"
                width={1094}
                height={14}
                sx={{
                  bgcolor: "#EAEEF1 !important",
                }}
              />
            </Box>
            {listInitialSetupLoader()}
          </Box>
        </Box>
      </Box>
    );
  };

  if (isLoading) {
    return initialSetupLoader();
  }

  return (
    <Box className="section-wrapper" bgcolor="#f7fafc">
      <Box paddingTop="46px" className="main-content-initial-setup">
        <GuideHeader
          loading={isLoading}
          onBoardingSteps={onboardingInitialSetupSteps}
          moduleName={moduleNameIntialSetup}
          loaderFn={listInitialSetupLoader}
        />
        <NeedHelp readGuideUrl={`${process.env.REACT_APP_SPEED_DEV_URL}docs`} />
      </Box>
    </Box>
  );
};

export default InitialSetupGuide;
