import React, { useCallback, useEffect, useState } from "react";
import { sessionService } from "redux-react-session";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "@speed/common/src/components/Table/Table";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TableCell,
  TableRow,
} from "@mui/material";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  noOfRecords,
  returnQRCodeMethod,
  updateSessionLastActionTime,
} from "../constants";
import {
  setConfirmAction,
  setExecuteAPICall,
  setIsDataAvailable,
  setLoading,
} from "../../redux/common/actions";
import {
  clipboardElement,
  linkStatus,
} from "@speed/common/src/components/constants";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { emptySubText } from "@speed/common/src/components/messages";
import { emptyOneQR } from "../images";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import {
  deActivate,
  deactivateModalBodyText,
  deActivateOneQR,
  emptyOneQRSubText,
  viewOneQR,
} from "../messages";
import classNames from "classnames";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import Button from "@speed/common/src/components/Button/Button";
import Text from "@speed/common/src/components/Text/Text";
import ViewQRModal from "../OneQR/ViewQRModal";

const OneQRTable = () => {
  const { executeAPICall, history, isDataAvailable, caughtErrorInStrapi } =
    useSelector((state) => state.common);
  const liveMode = useSelector((state) => state.auth.liveMode);

  const columns = [
    { title: "OneQR ID", width: "170px", paddingLeft: "38px !important" },
    {
      title: "Name",
      width: "300px",
      align: "left",
    },
    { title: "Type", width: "200px", align: "left" },
    { title: "Created on", width: "250px", align: "left" },
    { title: "Status", width: "200px", align: "left" },
    { title: "Actions", width: "25px", align: "center" },
  ];

  const queryParam = "?limit=" + noOfRecords;
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [session, setSession] = useState(null);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [rows, setRows] = useState([]);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [loadMoreId, setLoadMoreId] = useState("");
  const [viewQR, setViewQR] = useState(false);
  const [isOpenDeactivatePopUp, setIsOpenDeactivatePopUp] = useState(false);
  const [isModalLoader, setIsModalLoader] = useState(false);

  const openPopper = Boolean(anchorEl);
  let actions = [];

  const dispatch = useDispatch();

  useEffect(() => {
    sessionService.loadSession().then((newSession) => {
      setSession(newSession);
    });
  }, []);

  useEffect(() => {
    if (actions.length === 0) {
      setAnchorEl(null);
    }
  }, [openPopper]);

  useEffect(() => {
    setRows([]);
    setHasMore(true);
    getOneQrList([], queryParam);
  }, [liveMode]);

  const getOneQrList = async (lines, params) => {
    setTableRowSkeleton(true);
    await callAPIInterface("GET", "/oneqrs" + params, {})
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam + "&ending_before=" + res.data[res.data.length - 1].id
            );
            setLoadMoreId(res.data[res.data.length - 1].id);
          }
          setRows(lines.concat(res.data));
          dispatch(setIsDataAvailable(res.data.length > 0));
        }
      })
      .catch((_err) => {
        setTableRowSkeleton(false);
      });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick(e, currentRow);
    }
  };

  const handleClick = (event, rowData) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setCurrentRow(rowData);
  };

  const handleDeactivate = (id) => {
    setIsModalLoader(true);
    callAPIInterface("POST", `/oneqrs/${id}/deactivate`, {})
      .then((res) => {
        if (res) {
          setIsModalLoader(false);
          setAnchorEl(null);
          setIsOpenDeactivatePopUp(false);
          const result = rows.map((row) => {
            return {
              ...row,
              status: row.id === currentRow.id ? res.status : row.status,
            };
          });
          setRows(result);
        }
      })
      .catch((_err) => {
        setIsModalLoader(false);
      });
  };

  const handleActionClick = (action) => {
    if (action === "deactivate") {
      setIsOpenDeactivatePopUp(true);
    }
  };

  useEffect(() => {
    if (executeAPICall) {
      const newSession = updateSessionLastActionTime();
      setSession(newSession);
      dispatch(setLoading(false));
      dispatch(setConfirmAction(false));
      dispatch(setExecuteAPICall(false));
    }
  }, [executeAPICall]);

  const loadMore = useCallback(() => {
    getOneQrList(rows, queryParams);
  }, [rows]);

  let columnsData = columns.map((column, index) => (
    <TableCell
      key={index}
      align={column.align}
      sx={{ width: column.width, paddingLeft: column.paddingLeft }}
    >
      {column.title}
    </TableCell>
  ));

  let rowsData = rows?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem?.id}
        className="clickable"
        onClick={() => history.push(`/one-qr/${rowItem?.id}`)}
      >
        <TableCell sx={{ paddingLeft: "38px !important" }}>
          {clipboardElement(
            rowItem?.id,
            true,
            "payments-listing-id-clipboard",
            "inputBox",
            false
          )}
        </TableCell>
        <TableCell
          style={{
            wordBreak: "break-word",
          }}
        >
          {rowItem.name}
        </TableCell>
        <TableCell>
          {
            returnQRCodeMethod.find(
              (item) => item.method === rowItem.oneqr_method
            )?.label
          }
        </TableCell>
        <TableCell>{dateTimeFormatInApp(rowItem.created)}</TableCell>
        <TableCell>
          <Tag
            text={linkStatus[rowItem?.status]?.label}
            variant={linkStatus[rowItem?.status]?.variant}
            className="text-capitalize"
          />
        </TableCell>
        <TableCell style={{ textAlign: "center" }}>
          <MoreHorizIcon
            className="horizontal-dots-icon"
            tabIndex={0}
            onKeyDown={handleKeyDown}
            onClick={(event) => {
              event.stopPropagation();
              handleClick(event, rowItem);
            }}
          />
        </TableCell>
      </TableRow>
    );
  });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
    className: "oneQR-table",
    textOnNoData: emptySubText(liveMode, "One QR"),
    subTextOnNoData: emptyOneQRSubText,
    noDataImage: emptyOneQR,
  };

  const renderDeactivateModalContent = () => {
    return (
      <Box className="deactivate-qr">
        <Text
          size={16}
          font="regular"
          sx={{ flex: 1 }}
          className="default-text divider"
          variant="h6"
        >
          {deactivateModalBodyText}
        </Text>
      </Box>
    );
  };

  const renderAction = () => {
    const actionsObj = {
      view_QR: {
        title: viewOneQR,
        action: () => setViewQR(true),
      },
      deactivate_QR: {
        title: deActivate,
        action: () => handleActionClick("deactivate"),
      },
    };

    actions.push(actionsObj.view_QR);

    if (
      currentRow?.status === "active" &&
      currentRow?.oneqr_method === "lnurl"
    ) {
      actions.push(actionsObj.deactivate_QR);
    }

    return actions.map((action, index) => {
      return (
        <ListItem disablePadding key={index}>
          <ListItemButton onClick={action.action}>
            <ListItemText
              primary={action.title}
              className={
                action.title === viewOneQR
                  ? "text-grey"
                  : classNames("text-danger")
              }
            />
          </ListItemButton>
        </ListItem>
      );
    });
  };

  const renderDeactivateFooter = () => {
    return (
      <Button
        label={deActivate}
        loading={isModalLoader}
        variant="contained"
        color="primary"
        className="deactivate-btn"
        onClick={() => handleDeactivate(currentRow?.id)}
      />
    );
  };

  return (
    <>
      <Box className="main-content">
        {(isDataAvailable || caughtErrorInStrapi) && (
          <CustomTable {...tableProps} />
        )}
      </Box>
      <CustomPopper
        disablePortal={true}
        open={openPopper}
        anchorEl={anchorEl}
        position="bottom"
        handleClose={() => setAnchorEl(null)}
      >
        <List>{renderAction()}</List>
      </CustomPopper>

      <ViewQRModal
        data={currentRow}
        handleClose={() => setViewQR(false)}
        viewQR={viewQR}
        className="view-qr-modal"
      />
      <Modal
        maxWidth="xs"
        body={renderDeactivateModalContent()}
        handleClose={() => setIsOpenDeactivatePopUp(false)}
        open={isOpenDeactivatePopUp}
        title={deActivateOneQR}
        className="deactivate-qr-modal"
        footer={renderDeactivateFooter()}
      />
    </>
  );
};

export default OneQRTable;
