import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import history from "@speed/common/src/components/history";
import { Cookies } from "react-cookie";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import { authenticatingURL } from "@speed/common/src/messages";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import {
  oneClickInvalidParamURLMessage,
  oneClickInvalidParamsMessage,
  oneClickInvalidParamsTitle,
  redirectingToURL,
} from "../messages";
import { oneClickCommonModalBody, validateURL } from "../constants";

const OneClickIntegration = () => {
  const { maConnectappFe } = useFlags();
  const ldClient = useLDClient();
  const [loadingText, setLoadingText] = useState(authenticatingURL);
  const [hasInvalidData, setHasInvalidData] = useState(false);
  const [hasInvalidURL, setHasInvalidURL] = useState(false);
  const searchParams = {};
  const searchUrl =
    new URLSearchParams(
      history.location.search?.replace(/\+/g, "%2B")
    )?.entries() || [];

  for (const [key, value] of searchUrl) {
    searchParams[key] = value;
  }

  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  useEffect(() => {
    const ldUser = {
      key: "anonymous-anonymous",
    };
    ldClient.identify(ldUser);
  }, []);

  useEffect(() => {
    if (maConnectappFe) {
      setLoadingText(redirectingToURL);
      if (!searchParams?.client_id || !searchParams?.redirect_url) {
        setHasInvalidData(true);
        return;
      }
      if (!validateURL(searchParams?.redirect_url, true, true)) {
        setHasInvalidURL(true);
        return;
      }
      const cookies = new Cookies();
      cookies.set("oneclick-authorize-data", searchParams, { maxAge: 600 });
      history.push(isLoggedIn ? "/authorize/connect" : "/login");
    } else {
      history.push("/dashboard");
    }
  }, []);

  return (
    <>
      <BackdropLoader
        open={true}
        alt="Loading..."
        customClass="loading-in-auth"
        text={loadingText}
      />
      <Modal
        maxWidth="xs"
        body={oneClickCommonModalBody(
          hasInvalidURL
            ? oneClickInvalidParamURLMessage
            : oneClickInvalidParamsMessage
        )}
        open={hasInvalidData || hasInvalidURL}
        handleClose={() => {
          hasInvalidData && setHasInvalidData(false);
          hasInvalidURL && setHasInvalidURL(false);
          history.push("/dashboard");
        }}
        title={oneClickInvalidParamsTitle}
      />
    </>
  );
};

export default OneClickIntegration;
