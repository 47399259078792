import { emptyPaymentLinkSubText, paymentLinkCreate } from "../messages";
import { emptySubText } from "@speed/common/src/components/messages";
import { emptyPaymentLink } from "../images";
import { useSelector } from "react-redux";
import PaymentAndCheckoutLinkTable from "./PaymentAndCheckoutLinkTable";

const tableHeader = [
  { title: "Payment Links", width: "450px", paddingLeft: "38px !important" },
  { title: "Amount", width: "200px", align: "right" },
  { title: "Created on", width: "300px" },
  { title: "Status", width: "250px" },
  { title: "Actions", width: "150px" },
];

const PaymentLinkTable = ({
  tab,
  showHorizontalTable = false,
  searchQueryUrl,
  inputString,
  searchResultData,
  getModuleSearchResultData,
  totalNumOfSearchResults,
}) => {
  const liveMode = useSelector((state) => state.auth.liveMode);

  return (
    <PaymentAndCheckoutLinkTable
      moduleName="payment_link"
      tableHeader={tableHeader}
      linkPath="payment-links"
      tab={tab}
      showHorizontalTable={showHorizontalTable}
      getModuleSearchResultData={getModuleSearchResultData}
      searchQueryUrl={searchQueryUrl}
      inputString={inputString}
      searchResultData={searchResultData}
      totalNumOfSearchResults={totalNumOfSearchResults}
      textOnNoData={emptySubText(liveMode, "payment link")}
      subTextOnNoData={emptyPaymentLinkSubText}
      noDataImage={emptyPaymentLink}
      createButtonText={paymentLinkCreate}
    />
  );
};

export default PaymentLinkTable;
