import React, { forwardRef } from "react";
import { withdrawalLink } from "../messages";
import WithdrawalLinkDisplay from "./WithdrawalLinkDisplay";
import CommonLinkForm from "../CheckoutLinks/CommonLinkForm";
import LinkModalContent from "../Common/LinkModalContent";

const WithdrawalLinkModal = forwardRef((props, ref) => {
  const {
    handleButtonDisable,
    isPaymentLink,
    confirmRef,
    setDisableCreateButton,
    isFromWithdrawalLink,
  } = props;

  let rightSectionsProps = {
    confirmPayment: isPaymentLink,
  };

  const formComponent = (
    <CommonLinkForm
      handleButtonDisable={handleButtonDisable}
      isPaymentLink={isPaymentLink}
      isFromWithdrawalLink={isFromWithdrawalLink}
      ref={ref}
      linkType={withdrawalLink}
    />
  );

  const previewComponent = <WithdrawalLinkDisplay {...rightSectionsProps} />;
  return (
    <LinkModalContent
      formComponent={formComponent}
      previewComponent={previewComponent}
      linkConfirmationProps={{
        isPaymentLink: isPaymentLink,
        setDisableCreateButton: setDisableCreateButton,
        ref: confirmRef,
      }}
    />
  );
});

export default WithdrawalLinkModal;
