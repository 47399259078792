import React, { useState } from "react";
import Box from "@mui/material/Box";
import { Card, CardContent, Grid } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import Button from "@speed/common/src/components/Button/Button";
import { Info } from "@mui/icons-material";
import { payout, swapText, transfer } from "../messages";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import "../../assets/styles/balance.scss";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import {
  fetchPrecisedInteger,
  satsToBtcAmount,
} from "@speed/common/src/components/constants";

const BalanceCard = ({
  text,
  icon,
  isSwap,
  isPayout,
  isTransfer,
  msg,
  balanceData,
  isLoading,
  isBalanceOtherThanSATS,
  fiatAccountBalance,
  ...props
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const balanceLoader = () => {
    return (
      <>
        <CommonSkeleton
          className="card-sub-text"
          width={130}
          sx={{ borderRadius: "8px" }}
          color="#c4ccd2"
        />
        {!isBalanceOtherThanSATS && (
          <CommonSkeleton
            className="card-gbp-text"
            width={130}
            sx={{ borderRadius: "8px" }}
            color="#eaeef1"
          />
        )}
      </>
    );
  };

  const balanceDetails = () => {
    let satsAmount = balanceData?.amount || 0;
    return (
      <>
        {!isBalanceOtherThanSATS ? (
          <>
            <Text className="card-sub-text" size={20} font="bold" align="left">
              {`${satsToBtcAmount(satsAmount)}`}
            </Text>
            <Text
              className="card-gbp-text"
              size={16}
              font="regular"
              align="left"
            >
              {`( ${fetchPrecisedInteger(satsAmount, 2)} ${
                balanceData?.target_currency || "SATS"
              } )`}
            </Text>
          </>
        ) : (
          <Text className="card-sub-text" size={20} font="bold" align="left">
            {balanceData?.target_currency === "USDT"
              ? fetchPrecisedInteger(satsAmount / 100, 2)
              : fetchPrecisedInteger(satsAmount, 8)}
          </Text>
        )}
      </>
    );
  };

  const displayFiatBalance = () =>
    fiatAccountBalance ? (
      <Text className="fiat-balnce-text" size={16} font="regular">
        ≈ {fiatAccountBalance}
      </Text>
    ) : (
      ""
    );

  const actionButtonCommonProps = {
    variant: "outlined",
    className: "balance-action-button",
  };

  return (
    <Card className="balance-main-card">
      <Grid display="flex" flexDirection="column" gap="10px">
        <Grid item lg={10}>
          <CardContent className="card-body">
            <Box className="card-icon">{icon}</Box>
            <Text className="grey-text" size={18} font="bold" align="left">
              {text}
            </Text>
            {!isBalanceOtherThanSATS && (
              <Box className="info-icon">
                <Info
                  className="label-with-icon"
                  onClick={handleClick}
                  style={{ height: 20, width: 20, color: "#2a67ff" }}
                />
                <CustomPopper
                  disablePortal={true}
                  open={open}
                  anchorEl={anchorEl}
                  position="top"
                  handleClose={handleClose}
                >
                  <Text variant="inherit" font="regular" size={16}>
                    {msg}
                  </Text>
                </CustomPopper>
              </Box>
            )}
          </CardContent>
          <CardContent className="card-body">
            {isLoading ? balanceLoader() : balanceDetails()}
          </CardContent>
          <CardContent className="card-body" sx={{ height: "30px" }}>
            {isLoading ? (
              <CommonSkeleton
                className="fiat-balnce-text"
                width={100}
                sx={{ borderRadius: "8px" }}
                color="#eaeef1"
              />
            ) : (
              displayFiatBalance()
            )}
          </CardContent>
        </Grid>
        <Grid item lg={2} gap={2} display="flex" ml="46px">
          {isSwap && (
            <Button
              label={swapText}
              onClick={props.handleOnClickSwap}
              {...actionButtonCommonProps}
            />
          )}
          {isPayout && (
            <Button
              label={payout}
              onClick={props.handleOnClickPayout}
              {...actionButtonCommonProps}
            />
          )}
          {isTransfer && (
            <Button
              label={transfer}
              onClick={props.handleOnClickTransfer}
              {...actionButtonCommonProps}
            />
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default BalanceCard;
