import React from "react";
import GetGuides from "./GetGuides";

const ConnectGuide = () => {
  return (
    <GetGuides
      moduleName="connect"
      redirectUrl="/connect"
      guideApiPath="connect-guide"
      getStartedApiPath="Connect"
      needHelpGuidePath="docs/receive-payments/connect"
    />
  );
};

export default ConnectGuide;
