import React from "react";
import { Text } from "@speed/common/src/components/Text/Text";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import UploadBrandingDetails from "../Settings/Branding/UploadBrandingDetails";
import Team2faAuthentication from "../Settings/UsersAccounts/Team2faAuthentication";
import { applyToAllText } from "../messages";
import { CustomLink } from "@speed/common/src/components/Link/Link";

const CommonHeader = ({ childrenWithHeader = false }) => {
  const currentAccount = useSelector((state) => state.auth.currentAccount);

  const currentRouteDetails = useSelector(
    (state) => state.common.currentRouteDetails
  );
  const showApplyBtn = false;
  const isFromNotificationTab =
    currentRouteDetails?.header === "Notification Preferences" &&
    currentAccount?.account?.name;

  if (currentRouteDetails && Object.keys(currentRouteDetails).length > 0) {
    return (
      <>
        <Box className="setting-header-wrapper">
          {childrenWithHeader ? (
            <Box display="flex" alignItems="center" gap={2}>
              <Text variant="h6" font="semibold" size={20} withIcon="end">
                {currentRouteDetails.header}
              </Text>
              {childrenWithHeader}
            </Box>
          ) : (
            <Text variant="h6" font="semibold" size={20} withIcon="end">
              {currentRouteDetails.header}
            </Text>
          )}

          <Text
            variant="body1"
            className="grey-text"
            font="regular"
            size={14}
            style={{ marginTop: "5px" }}
          >
            {currentRouteDetails.subHeader +
              (isFromNotificationTab
                ? ` ${currentAccount.account.name}. `
                : "")}
            {showApplyBtn && isFromNotificationTab && (
              <CustomLink size="small" bold>
                {applyToAllText}
              </CustomLink>
            )}
          </Text>
        </Box>
        {currentRouteDetails?.header === "Branding" && currentAccount && (
          <UploadBrandingDetails />
        )}
        {currentRouteDetails?.header === "Users & Teams" && (
          <Team2faAuthentication />
        )}
      </>
    );
  } else {
    return <></>;
  }
};

export default CommonHeader;
