import React, { useState, useEffect, useCallback } from "react";
import { Box } from "@mui/system";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import {
  RouteBreadcrumbs,
  callAPIInterface,
  dateTimeFormatInApp,
} from "../constants";
import {
  clipboardElement,
  generateBreadcrumbs,
  redirectWhenEntityNotFound,
} from "@speed/common/src/components/constants";
import { Grid } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import {
  entityNotFoundMessage,
  eventData,
  viewAllLinesLabel,
  time,
  source,
  eventSubTitleMsg,
  eventBtnText,
  details,
  hideLinesLabel,
  webhookAttemptsLabel,
  connectedAccountLabel,
  account,
} from "../messages";
import { useSelector } from "react-redux";
import "../../assets/styles/payments.scss";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import WarningIcon from "@mui/icons-material/Warning";
import { useFlags } from "launchdarkly-react-client-sdk";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import jsonDataToShow from "../jsonLogic";
import PaymentsSkeleton from "@speed/common/src/components/Skeleton/PaymentsSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CommonWebhookAttempt from "./CommonWebhookAttempt";
import InfiniteScroll from "react-infinite-scroll-component";
import AttemptTableSkeleton from "./AttemptTableSkeleton";

const EventsDetail = (props) => {
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const liveMode = useSelector((state) => state.auth.liveMode);
  const {
    evEventlistdetails,
    weEventattemptsmessagewise,
    weEventattemptsmessages,
  } = useFlags();

  const queryParam = "?limit=" + 10;

  const [data, setData] = useState(null);
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [versions, setVersions] = useState([]);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [selectedTab, setSelectedTab] = useState("1");
  const [selectedVersion, setSelectedVersion] = useState("");
  const [eventDataShow, setEventDataShow] = useState(false);
  const urlParams = useParams();
  const [webhookEvent, setWebhookEvent] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [eventVersionsRows, setEventVersionsRows] = useState([]);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [connectEventSelectedVersion, setConnectEventSelectedVersion] =
    useState({});
  const [connectEventSelectedTab, setConnectEventSelectedTab] = useState({});
  const [connectWebhookEvent, setConnectWebhookEvent] = useState({});

  let tempWHObj = connectWebhookEvent || {};

  useEffect(() => localStorage.removeItem("platformAccData"), []);

  const getConnectEventData = (lines, params) => {
    callAPIInterface(
      "GET",
      `/webhooks/event/${urlParams.id}/platform/versions${params}`
    ).then((res) => {
      if (res) {
        if (!res.has_more) {
          setHasMore(false);
        } else {
          setQueryParams(
            queryParam +
              "&ending_before=" +
              res.data[res.data?.length - 1]?.account_id
          );
        }
        const result = lines.concat(res.data);
        let obj = {};
        let tabObj = {};
        result.forEach((item) => {
          obj = { ...obj, [item.account_id]: item.api_version[0] };
          tabObj = { ...tabObj, [item.account_id]: 1 };
        });
        setConnectEventSelectedVersion(obj);
        setConnectEventSelectedTab(tabObj);
        setEventVersionsRows(result);
      }
    });
  };

  useEffect(() => {
    setData(null);
    setSelectedTab(1);
    setIsShowNotFound(false);
    getEventVersion();
    getEventsDetail();
    getConnectEventData([], queryParam);
  }, [liveMode]);

  const loadMore = useCallback(() => {
    getConnectEventData(eventVersionsRows, queryParams);
  }, [eventVersionsRows]);

  const getEventVersion = async () => {
    await callAPIInterface(
      "GET",
      `/webhooks/event/${urlParams.id}/versions`
    ).then((res) => {
      if (res) {
        setVersions(res.data);
        setSelectedVersion(res.data[0]);
      }
    });
  };

  const getEventsDetail = async () => {
    setShowSkeleton(true);
    await callAPIInterface("GET", `/events/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setData(res);
        }
        setShowSkeleton(false);
      })
      .catch((err) => {
        setShowSkeleton(false);
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const detailsTableData = () => {
    let details = [
      {
        header: time,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {dateTimeFormatInApp(data?.created)}
          </Text>
        ),
      },
    ];
    data?.request?.event_source &&
      details.push({
        header: source,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {data?.request?.event_source}
          </Text>
        ),
      });
    return details;
  };

  const headerContent = () => {
    return (
      <Box
        className="header-content"
        sx={{ display: "flex", justifyContent: "space-between" }}
      >
        <Box>
          <Text className="btc-amount" variant="h1" size={28}>
            {data.event_type}
          </Text>
        </Box>

        <Box sx={{ paddingLeft: "440px" }}>
          {clipboardElement(data?.id, true, "payments-detail", "text", true)}
        </Box>
        <Box className="header-price-content"></Box>
      </Box>
    );
  };

  const oneLineLabel = ({
    label,
    iconLabel,
    shouldIconTextVisible,
    handleClick,
    customStyle,
  }) => {
    return (
      <Box display="flex" justifyContent="space-between" sx={customStyle}>
        <Text size={20} className="default-text">
          {label}
        </Text>
        {shouldIconTextVisible && (
          <Text
            size={14}
            variant="regular"
            sx={{ color: "#2A67FF", cursor: "pointer" }}
            withIcon="start"
            onClick={handleClick}
          >
            <VisibilityIcon
              sx={{
                color: "#2A67FF !important",
                height: "17px !important",
                width: "17px !important",
              }}
            />
            {iconLabel}
          </Text>
        )}
      </Box>
    );
  };

  return evEventlistdetails ? (
    !isShowNotFound ? (
      <Grid className="payments-detail-wrapper" container spacing={1}>
        <Box className="section-wrapper" sx={{ position: "relative" }}>
          <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
          {data ? (
            <>
              {headerContent()}
              <Box className="details-content" sx={{ width: "1000px" }}>
                <VerticalTable label={details} rowData={detailsTableData()} />
                <Box
                  className={`event-data-wrapper ${
                    !eventDataShow && "event-data-wrapper-hide"
                  }`}
                >
                  {oneLineLabel({
                    label: eventData,
                    iconLabel: eventDataShow
                      ? hideLinesLabel
                      : viewAllLinesLabel,
                    shouldIconTextVisible: true,
                    customStyle: { paddingTop: "12px" },
                    handleClick: () => setEventDataShow(!eventDataShow),
                  })}
                  <CustomDivider sx={{ marginTop: "12px" }} />
                  <Box
                    className={`event-data ${
                      !eventDataShow && "event-data-hide"
                    }`}
                  >
                    {jsonDataToShow(data?.data?.object)}
                  </Box>
                </Box>
                <Box className="jsonDisplay-showMoreFade"></Box>
                {weEventattemptsmessages && weEventattemptsmessagewise && (
                  <>
                    <CommonWebhookAttempt
                      attemptLabel={`${webhookAttemptsLabel} ${account}`}
                      selectedVersion={selectedVersion}
                      setWebhookEvent={setWebhookEvent}
                      urlParams={urlParams}
                      versions={versions}
                      webhookEvent={webhookEvent}
                      setSelectedVersion={setSelectedVersion}
                      selectedTab={selectedTab}
                      setSelectedTab={setSelectedTab}
                      from="account"
                      oneLineLabel={oneLineLabel}
                    />
                    <InfiniteScroll
                      dataLength={eventVersionsRows?.length}
                      next={loadMore}
                      hasMore={hasMore}
                      loader={<AttemptTableSkeleton sx={{ mt: "30px" }} />}
                    >
                      {eventVersionsRows.length > 0 &&
                        eventVersionsRows.map((version) => {
                          return (
                            <CommonWebhookAttempt
                              from="connect"
                              platformAccID={version.account_id}
                              key={version.account_id}
                              attemptLabel={`${webhookAttemptsLabel} ${connectedAccountLabel}-${version.account_id}`}
                              selectedVersion={
                                connectEventSelectedVersion[version.account_id]
                              }
                              setWebhookEvent={(data) => {
                                tempWHObj = {
                                  ...tempWHObj,
                                  [version.account_id]: data,
                                };
                                setConnectWebhookEvent(tempWHObj);
                              }}
                              urlParams={urlParams}
                              versions={version.api_version}
                              webhookEvent={
                                connectWebhookEvent[version.account_id]
                              }
                              setSelectedVersion={(value) =>
                                setConnectEventSelectedVersion({
                                  ...connectEventSelectedVersion,
                                  [version.account_id]: value,
                                })
                              }
                              selectedTab={
                                connectEventSelectedTab[version.account_id]
                              }
                              setSelectedTab={(value) => {
                                setConnectEventSelectedTab({
                                  ...connectEventSelectedTab,
                                  [version.account_id]: value,
                                });
                              }}
                              oneLineLabel={oneLineLabel}
                            />
                          );
                        })}
                    </InfiniteScroll>
                  </>
                )}
              </Box>
            </>
          ) : (
            showSkeleton && <PaymentsSkeleton props={props} />
          )}
        </Box>
      </Grid>
    ) : (
      <Box className="not-found-wrapper">
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        <LinkNotFound
          icon={<WarningIcon className="warning-icon" />}
          title={entityNotFoundMessage("Event")}
          subTitle={eventSubTitleMsg(urlParams.id)}
          btnText={eventBtnText(liveMode)}
        />
      </Box>
    )
  ) : (
    <AccessDenied />
  );
};

export default EventsDetail;
