import React, { useState } from "react";
import RightSideCommonPreview from "../Common/RightSideCommonPreview";
import { withdrawPreviewURL, withdrawalLinkPriceMsg } from "../messages";
import { content } from "../constants";
import RenderLinkWebPreview from "../Common/RenderLinkWebPreview";
import { useSelector } from "react-redux";
import {
  dummyLnurl,
  withdrawalLinkDummyId,
} from "@speed/common/src/components/constants";
import {
  lightningLabel,
  lnUrlPay,
} from "@speed/common/src/components/messages";
import RenderLinkMobilePreview from "../Common/RenderLinkMobilePreview";

const WithdrawalLinkDisplay = (props) => {
  const { linkType, confirmPayment } = props;

  const [selected, setSelected] = useState("laptop");

  const paymentInfo = useSelector((state) => state.common.paymentInfo);

  const handleChange = (_e, value) => {
    if (value) {
      setSelected(value);
    }
  };

  const commonProps = {
    confirmPayment,
    linkType,
  };

  const commonPropsForBothPreview = {
    onChainAvailable: false,
    lightningLabel: (
      <>
        {lightningLabel} {lnUrlPay}
      </>
    ),
    dummyLightningAddress: dummyLnurl,
    confirmPageProps: {
      confirmPageLinkID: withdrawalLinkDummyId,
      successMessage: "congratulationMessage",
      amountTypeTitle: "withdrawal",
      confirmMessage: "withdrawSubMessage",
      idTitle: "withdrawalId",
      shouldShowSubTitle: true,
    },
  };

  const respectedLinkWebProps = {
    ...commonPropsForBothPreview,
  };

  const respectedLinkMobileProps = {
    ...commonPropsForBothPreview,
  };

  const renderProps = {
    componentProps: commonProps,
    dummyDomain: withdrawPreviewURL,
    paymentInfo: paymentInfo,
    showPageLoader: false,
    marginTop: "24px",
    marginBottom: 0,
  };

  const renderContent = () => {
    return selected === "laptop" ? (
      <RenderLinkWebPreview
        {...renderProps}
        respectedLinksProps={respectedLinkWebProps}
      />
    ) : (
      <RenderLinkMobilePreview
        {...renderProps}
        respectedLinksProps={respectedLinkMobileProps}
      />
    );
  };

  return (
    <RightSideCommonPreview
      handleChange={handleChange}
      priceMsg={withdrawalLinkPriceMsg}
      content={content}
      selected={selected}
      brandingURL="/settings/withdrawal-link"
    >
      {renderContent()}
    </RightSideCommonPreview>
  );
};

export default WithdrawalLinkDisplay;
