import React from "react";
import { Box } from "@mui/material";
import classNames from "classnames";

const DiagonalSection = ({ color, isFromWeb, isFromCheckout }) => {
  const parentBoxClass = classNames(
    "diagonal-div",
    isFromWeb && "web",
    isFromCheckout && "checkout"
  );
  const childBoxClass = classNames(
    "diagonal-sub-div",
    isFromWeb && "web",
    isFromCheckout && "checkout"
  );

  return (
    <>
      <Box
        className={parentBoxClass}
        sx={{
          backgroundColor: color,
        }}
      ></Box>
      <Box
        className={childBoxClass}
        sx={{
          backgroundColor: color,
        }}
      ></Box>
    </>
  );
};

export default DiagonalSection;
