import { Box } from "@mui/material";
import { CustomSwitch } from "@speed/common/src/components/Switch/Switch";
import Text from "@speed/common/src/components/Text/Text";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sessionService } from "redux-react-session";
import { setInitialState } from "../../../redux/auth/actions";
import {
  setExecuteAPICall,
  setLoading,
  setSettingFormSubmit,
} from "../../../redux/common/actions";
import {
  callAPIInterface,
  idealTimeLimitReached,
  setShowCrucialActionModal,
  updateSessionLastActionTime,
} from "../../constants";
import { team2faSubTitleMsg, team2faTitleMsg } from "../../messages";

function Team2faAuthentication() {
  const [isLoading, setIsLoading] = useState(false);
  const [session, setSession] = useState(null);
  const dispatch = useDispatch();
  const { user, currentAccount } = useSelector((state) => state.auth);
  const [isEnabled2fa, setIsEnabled2fa] = useState(
    currentAccount?.account?.is_account_mfa_enabled
      ? currentAccount?.account?.is_account_mfa_enabled
      : false
  );
  const { executeAPICall, settingFormSubmit } = useSelector(
    (state) => state.common
  );
  const allowedRolesArray = ["Owner", "Administrator"];

  const resetActions = () => {
    setIsLoading(false);
    dispatch(setLoading(false));
    setShowCrucialActionModal(user, false);
    dispatch(setSettingFormSubmit(false));
  };

  const updateMFADetails = async () => {
    const newSession = await updateSessionLastActionTime();
    setSession(newSession);
    setIsLoading(true);
    callAPIInterface("POST", "/mfa/team")
      .then((response) => {
        if (response) {
          setIsEnabled2fa(response.is_account_mfa_enabled);
          const updatedCurrentAccount = {
            ...currentAccount,
            account: {
              ...currentAccount?.account,
              is_account_mfa_enabled: response.is_account_mfa_enabled,
            },
          };
          sessionService.loadSession().then((session) => {
            dispatch(
              setInitialState({
                ...session,
                current_acc: updatedCurrentAccount,
              })
            );
          });
          resetActions();
        }
      })
      .catch(() => {
        resetActions();
      });
  };

  useEffect(() => {
    sessionService.loadSession().then((newSession) => {
      setSession(newSession);
    });
  }, []);

  useEffect(() => {
    if (settingFormSubmit) {
      if (idealTimeLimitReached(session?.last_action_time)) {
        dispatch(setSettingFormSubmit(false));
        setShowCrucialActionModal(user, true);
      } else {
        updateMFADetails();
      }
    }
  }, [settingFormSubmit]);

  useEffect(() => {
    if (executeAPICall) {
      updateMFADetails();
      dispatch(setExecuteAPICall(false));
    }
  }, [executeAPICall]);

  const handleToggleTeamMFA = () => {
    dispatch(setSettingFormSubmit(true));
  };

  const switchTextContent = (
    <>
      <Text size={16} className="default-text" variant="h3">
        {team2faTitleMsg}
      </Text>
      <Text
        size={14}
        className="switch-sub-text grey-text"
        font="regular"
        variant="h3"
      >
        {team2faSubTitleMsg}
      </Text>
    </>
  );

  return (
    <>
      {allowedRolesArray.includes(currentAccount.role.role_name) && (
        <Box className="team-2fa-auth-section">
          <CustomSwitch
            checked={isEnabled2fa}
            onChange={handleToggleTeamMFA}
            disabled={isLoading}
          />
          <Box className="2fa-switch-text-box">{switchTextContent}</Box>
        </Box>
      )}
    </>
  );
}

export default Team2faAuthentication;
