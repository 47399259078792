import Button from "@speed/common/src/components/Button/Button";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import Text from "@speed/common/src/components/Text/Text";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import WarningIcon from "@mui/icons-material/Warning";
import {
  revokeInvite,
  inviteRedeem,
  joinTeam,
  userInviteMsg,
  invitationDiffAccount,
  signOutLockedIn,
  inviteForDiffAccount,
  invitation,
  invitationForDiffAccount,
  invitationRedeem,
  invitationRedeemForDiffAccount,
  invitationExpired,
  viewDashboard,
  welcomeTeam,
  acceptMsg,
  acceptInvitation,
  account,
} from "../messages";
import { CustomLink } from "@speed/common/src/components/Link/Link";

const Invite = ({
  status,
  accountName,
  invitedByEmail,
  email,
  handleClose,
  open,
  handleButtonClick,
  accountCount,
}) => {
  const emailFormat = (emailId) => {
    return <span style={{ color: "#2a67ff" }}>{emailId}</span>;
  };

  const accountLink = () => {
    return (
      <CustomLink href="/settings/associated-accounts">
        {accountCount > 1 ? account + "(s)" : account}
      </CustomLink>
    );
  };

  let content = null;

  if (status === invitation)
    content = (
      <Box textAlign="center">
        <Text
          className="default-text"
          align="center"
          size={16}
          font="regular"
          sx={{ wordBreak: "break-word" }}
          variant="subtitle1"
        >
          {userInviteMsg(accountName)}
          <Text size={16} variant="caption" font="semibold">
            {invitedByEmail}
          </Text>
        </Text>
      </Box>
    );
  else {
    if (
      status === invitationRedeem ||
      status === invitationRedeemForDiffAccount ||
      status === invitationExpired
    )
      content = (
        <Box>
          <WarningIcon className="warning-icon invite-warn-icon" />
          <Box sx={{ marginBottom: "10px" }}>
            <Text
              align="center"
              size={16}
              font="regular"
              className="default-text"
              sx={{ fontFamily: "Inter-Regular !important" }}
            >
              {status === invitationRedeem ||
              status === invitationRedeemForDiffAccount
                ? inviteRedeem
                : revokeInvite}
            </Text>
          </Box>
        </Box>
      );
    else if (status === acceptInvitation)
      content = (
        <Box textAlign="center">
          <Text
            className="default-text"
            align="center"
            size={16}
            font="regular"
            variant="subtitle1"
            sx={{ wordBreak: "break-word" }}
          >
            {acceptMsg(accountName, accountCount, accountLink())}
          </Text>
        </Box>
      );
    else
      content = (
        <Text
          className="default-text"
          size={16}
          font="regular"
          variant="subtitle1"
          sx={{ wordBreak: "break-word" }}
        >
          {invitationDiffAccount(
            emailFormat(invitedByEmail),
            emailFormat(email),
            accountName
          )}{" "}
        </Text>
      );
  }

  const footer = (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      display="flex"
    >
      <Button
        style={{ width: "400px" }}
        label={
          status === invitation
            ? joinTeam()
            : status === acceptInvitation
            ? viewDashboard
            : signOutLockedIn
        }
        onClick={status === acceptInvitation ? handleClose : handleButtonClick}
      />
    </Grid>
  );
  return (
    <Modal
      maxWidth="xs"
      body={content}
      footer={
        status === invitation ||
        status === invitationForDiffAccount ||
        status === acceptInvitation
          ? footer
          : ""
      }
      open={open}
      title={
        status === invitation || status === invitationRedeem
          ? joinTeam(accountName)
          : status === acceptInvitation
          ? welcomeTeam
          : inviteForDiffAccount
      }
      titleTextProps={{ sx: { wordBreak: "break-word" } }}
      handleClose={handleClose}
    />
  );
};

export default Invite;
