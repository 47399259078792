import { AppBar, Box, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Text from "@speed/common/src/components/Text/Text";
import React, { forwardRef, useState, useEffect } from "react";
import { Input } from "@speed/common/src/components/Input/Input";
import Button from "@speed/common/src/components/Button/Button";
import {
  enterValidAddress,
  lightning,
  pay,
  send,
  sendAddressInputText,
  getCurrencyName,
  sats,
  lnurl,
  onchain,
  paymentFailedHeading,
  paymentProcessingHeading,
  invalidAddressInvoice,
  invalidAddressMsg,
  invalidQrCode,
  ethereum,
  usdt,
  redemptionFailed,
  btc,
  eth,
  trx,
  tronLabel,
} from "../../messages";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  callAPIInterface,
  commonCustomDivider,
  verifyAddress,
  handleSplitWithdrawRequest,
  displaySelectedCurrencyAmount,
  getCurrencyAmount,
  BTCToFiatAmount,
  getExchangeRate,
} from "../../constants";
import SendPayment from "./SendPayment";
import {
  setPaymentProcessingRecallApis,
  setWalletSendModalClose,
} from "../../../redux/common/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  btcToSatsAmount,
  fetchPrecisedInteger,
  validAddress,
} from "@speed/common/src/components/constants";
import SendUploadImage from "./SendUploadImage";
import RedemptionFailed from "./RedemptionFailed";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import InvaliAddressOrQrcode from "./InvaliAddressOrQrcode";
import PaymentModeModal from "@speed/common/src/components/PaymentModeModal";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const SendModal = ({
  openSendModal,
  setOpenSendModal,
  selectedCurrencyType,
}) => {
  const dispatch = useDispatch();
  const uuid = selectedCurrencyType?.uuid || "";
  const walletDefaultCurrency = JSON.parse(
    localStorage.getItem("account_default_currency")
  );
  const liveMode = useSelector((state) => state.auth.liveMode);
  const showBtcAmount = useSelector((state) => state.common.showBtcAmount);

  const [isPayClicked, setIsPayClicked] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [decodeResponse, setDecodeResponse] = useState(null);
  const [amount, setAmount] = useState(null);
  const [fiatAmount, setFiatAmount] = useState(null);
  const [exchangeRateLoading, setExchangeRateLoading] = useState(false);
  const [exchangeRate, setExchangeRate] = useState(1);
  const [isOpenPaymentModeModal, setIsOpenPaymentModeModal] = useState(false);
  const [selectedMode, setSelectedMode] = useState("");
  const [secondBitcoinAddress, setSecondBitcoinAddress] = useState("");
  const [secondLightningAddress, setSecondLightningAddress] = useState("");
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [isPaymentFailed, setIsPaymentFailed] = useState(false);
  const [isErrorDecode, setIsErrorDecode] = useState(false);
  const [receiveWithdrawError, setReceiveWithdrawError] = useState("");
  const [imageError, setImageError] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [errorSubMessage, setErrorSubMessage] = useState("");
  const [noteFromURL, setNoteFromURL] = useState(null);
  const [decodedCurrency, setDecodedCurrency] = useState(null);
  const [lightningUsdtExchangeRate, setLightningUsdtExchangeRate] = useState(1);

  useEffect(() => {
    if (selectedMode !== "") {
      const selectedAddress =
        selectedMode === "bitcoin"
          ? secondBitcoinAddress
          : secondLightningAddress;

      handleDecode(selectedAddress, amount);
    }
  }, [selectedMode]);

  const handleModalClose = () => {
    dispatch(setWalletSendModalClose(true));
    setIsPayClicked(false);
    setIsSubmitting(false);
    setOpenSendModal(false);
    setDecodeResponse(null);
    setExchangeRateLoading(false);
    setAmount(null);
    setFiatAmount(null);
    resetForm();
    setExchangeRate(1);
    setIsPaymentFailed(false);
    setIsErrorDecode(false);
    setIsPaymentProcessing(false);
    setReceiveWithdrawError("");
    setErrorMsg("");
    setErrorSubMessage("");
    setSelectedMode("");
    setImageError("");
    setNoteFromURL(null);
    setLightningUsdtExchangeRate(1);
    dispatch(setPaymentProcessingRecallApis(true));
  };

  const validationSchema = yup.object({
    withdraw_request: yup.string().required(enterValidAddress),
  });

  const formik = useFormik({
    initialValues: {
      withdraw_request: "",
    },
    validationSchema: validationSchema,
  });

  const sendPaymentProps = {
    decodeResponse,
    amount,
    fiatAmount,
    exchangeRateLoading,
    selectedCurrencyType,
    setAmount,
    setFiatAmount,
    handleModalClose,
    exchangeRate,
    isPaymentFailed,
    isPaymentProcessing,
    setIsPaymentFailed,
    setIsPaymentProcessing,
    setReceiveWithdrawError,
    setErrorMsg,
    errorMsg,
    noteFromURL,
    decodedCurrency,
    lightningUsdtExchangeRate,
  };

  const {
    values,
    isValid,
    dirty,
    setFieldValue,
    resetForm,
    errors,
    touched,
    setTouched,
    setErrors,
  } = formik;

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const handleAddressVerification = (res) => {
    const invalidAddress = values.withdraw_request
      ? invalidAddressInvoice
      : invalidQrCode;

    // Helper function to check address expiration and env checks with network and selected mode i.e [test/live]
    const {
      isAllowed: isValid,
      message: mainMsg,
      subMsg,
    } = verifyAddress(res, liveMode, "send_modal", invalidAddress);

    return {
      isAllowed: isValid || true, // Default to `true` if `isValid` is `undefined`
      message: mainMsg || invalidAddress,
      subMessage: subMsg || "",
    };
  };

  const getAmount = ({
    withdrawMethod,
    decodeAmt,
    amountFromUrl,
    finalCurrency,
  }) => {
    switch (withdrawMethod) {
      case onchain: {
        if (finalCurrency === sats) {
          return showBtcAmount
            ? amountFromUrl
            : btcToSatsAmount(amountFromUrl) || decodeAmt;
        } else if (finalCurrency === usdt) {
          return getCurrencyAmount(decodeAmt, showBtcAmount, finalCurrency);
        }
        break;
      }
      case ethereum.toLowerCase():
        return Number(amountFromUrl);
      case lightning:
        return displaySelectedCurrencyAmount(
          decodeAmt,
          finalCurrency === sats && showBtcAmount
        );
      case tronLabel:
        return Number(amountFromUrl);
      default:
        return decodeAmt || amountFromUrl;
    }
  };

  const getLnUrlAmount = (tag, res) => {
    return displaySelectedCurrencyAmount(
      tag === "withdrawRequest" ? res?.min_withdrawable : res?.min_sendable,
      showBtcAmount
    );
  };

  const handleDecode = async (address, amountFromUrl) => {
    setIsOpenPaymentModeModal(false);

    if (!validAddress.test(address)) {
      handleDecodeError(!values.withdraw_request);
      return;
    }

    try {
      const res = await callAPIInterface(
        "POST",
        "/withdraws/decode",
        JSON.stringify({ withdraw_request: address })
      );

      const { isAllowed, message, subMessage } = handleAddressVerification(res);

      if (!isAllowed) {
        handleAddressNotAllowed(message, subMessage);
        return;
      }

      const {
        withdraw_method: withdrawMethod,
        amount: decodeAmt,
        tag,
        currency,
      } = res;

      const isUsdt =
        (currency === sats && uuid === usdt && withdrawMethod === lightning) ||
        currency === eth ||
        currency === trx;

      const finalCurrency = isUsdt ? usdt : currency;
      setDecodedCurrency(finalCurrency);

      const amt = await calculateAmount({
        withdrawMethod,
        tag,
        decodeAmt,
        amountFromUrl,
        finalCurrency,
        isUsdt,
        res,
      });

      const fiatAmount = await fetchFiatAmount(
        amt,
        finalCurrency,
        currency,
        withdrawMethod
      );
      setFiatAmount(fiatAmount);

      setAmount(amt);
      setDecodeResponse(res);
      setIsSubmitting(false);
      setIsPayClicked(true);
    } catch (err) {
      handleDecodeError(!values.withdraw_request);
    }
  };

  const handleDecodeError = (isQrCodeError) => {
    setErrors({ withdraw_request: enterValidAddress });
    setIsSubmitting(false);
    setIsErrorDecode(true);
    setErrorMsg(isQrCodeError ? invalidQrCode : invalidAddressInvoice);
    setErrorSubMessage(invalidAddressMsg);
  };

  const handleAddressNotAllowed = (message, subMessage) => {
    setIsSubmitting(false);
    setIsErrorDecode(true);
    setErrorMsg(message);
    setErrorSubMessage(subMessage);
  };

  const calculateAmount = async ({
    withdrawMethod,
    tag,
    decodeAmt,
    amountFromUrl,
    finalCurrency,
    isUsdt,
    res,
  }) => {
    let amt =
      withdrawMethod === lnurl
        ? getLnUrlAmount(tag, res)
        : getAmount({
            withdrawMethod,
            decodeAmt,
            amountFromUrl,
            finalCurrency,
          });

    if (isUsdt && withdrawMethod === lightning) {
      setExchangeRateLoading(true);
      try {
        const rateRes = await getExchangeRate(sats, finalCurrency);
        setLightningUsdtExchangeRate(rateRes?.target_lowest_rate);
        amt *= rateRes?.target_lowest_rate;
      } finally {
        setExchangeRateLoading(false);
      }
    }

    return amt;
  };

  const fetchFiatAmount = async (
    amt,
    finalCurrency,
    currency,
    withdrawMethod
  ) => {
    setExchangeRateLoading(true);
    try {
      const rateRes = await getExchangeRate(
        walletDefaultCurrency?.code || "USD",
        finalCurrency
      );
      setExchangeRate(rateRes?.target_lowest_rate);

      const fiatAmt = amt / rateRes?.target_lowest_rate;
      let formattedFiatAmount = fetchPrecisedInteger(fiatAmt, 2);

      if (formattedFiatAmount === "0") {
        formattedFiatAmount = fiatAmt.toFixed(8);
      }

      return showBtcAmount &&
        currency === sats &&
        (uuid === sats || withdrawMethod === onchain)
        ? BTCToFiatAmount(amt, rateRes?.target_lowest_rate)
        : formattedFiatAmount;
    } finally {
      setExchangeRateLoading(false);
    }
  };

  const handleSubmit = (invoiceUrl) => {
    setIsSubmitting(true);
    const { amount, addresses, note } = handleSplitWithdrawRequest(invoiceUrl);
    setNoteFromURL(note);
    setSecondLightningAddress(addresses?.lightning || addresses?.default);
    setSecondBitcoinAddress(addresses?.bitcoin || addresses?.default);
    if (Object.keys(addresses).length > 1) {
      setIsOpenPaymentModeModal(true);
      setAmount(amount);
    } else {
      const extractedAddress = Object.values(addresses)[0];
      handleDecode(extractedAddress, amount);
    }
  };

  const getHeaderTitle = (uuid) => `${send} ${uuid === sats ? btc : usdt}`;

  const modalHeaderText = () => {
    if (isErrorDecode) return "";
    if (isPaymentFailed) return paymentFailedHeading;
    if (isPaymentProcessing) return paymentProcessingHeading;
    if (receiveWithdrawError) return redemptionFailed;
    return getHeaderTitle(decodedCurrency || uuid);
  };

  const handlePayClicked = () => handleSubmit(values.withdraw_request);

  const sendPaymentRender = () => {
    if (isPayClicked) {
      return <SendPayment {...sendPaymentProps} />;
    }

    const renderCurrencyDetails = () => (
      <>
        <img
          src={selectedCurrencyType?.icon}
          alt="currency-btc-icon"
          style={{ width: "54px", height: "54px" }}
        />
        <Text
          size={20}
          font="semibold"
          className="default-text"
          variant="h6"
          sx={{ pt: "14px", mb: "6px" }}
        >
          {getCurrencyName(selectedCurrencyType?.name)}
        </Text>
      </>
    );

    const renderInputField = () => (
      <>
        <Input
          fullWidth
          placeholder={sendAddressInputText}
          onChange={(e) => setFieldValue("withdraw_request", e.target.value)}
          value={values.withdraw_request}
          type="text"
          className="send-input-address"
          label={false}
          onBlur={() => setTouched({ ...touched, withdraw_request: true })}
          error={touched.withdraw_request && Boolean(errors.withdraw_request)}
        />
        <InputErrorMessage
          {...defaultErrorMessageProps}
          inputName="withdraw_request"
          sx={{ width: "520px" }}
        />
      </>
    );

    const renderUploadSection = () => (
      <>
        <Text
          size={16}
          font="regular"
          className="grey-text"
          variant="h6"
          sx={{ pt: "24px", pb: "30px" }}
        >
          or
        </Text>
        <SendUploadImage
          handleSubmit={handleSubmit}
          setImageError={setImageError}
          imageError={imageError}
        />
      </>
    );

    const renderPayButton = () => (
      <Button
        label={pay}
        className="send-amt-btn"
        onClick={handlePayClicked}
        type="submit"
        loading={isSubmitting}
        disabled={!(isValid && dirty) || isSubmitting}
      />
    );

    return (
      <>
        {renderCurrencyDetails()}
        {renderInputField()}
        {renderUploadSection()}
        {commonCustomDivider("40px")}
        {renderPayButton()}
      </>
    );
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={openSendModal}
      TransitionComponent={Transition}
      className="fullscreen-modal send-modal-dialog send-modal-dialog-section"
      onClose={handleModalClose}
      sx={{ overflow: "unset" }}
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1 }}
            className="default-text divider"
            variant="h6"
          >
            {modalHeaderText()}
          </Text>
        </Toolbar>
      </AppBar>
      <Box className="send-modal-container">
        <Box className="send-modal-box">
          {receiveWithdrawError ? (
            <RedemptionFailed
              receiveWithdrawError={receiveWithdrawError}
              handleModalClose={handleModalClose}
            />
          ) : (
            <Box
              className="send-modal-icon-text"
              pt={isPayClicked ? "" : "50px"}
            >
              {isErrorDecode ? (
                <InvaliAddressOrQrcode
                  handleModalClose={handleModalClose}
                  errorMsg={errorMsg}
                  errorSubMessage={errorSubMessage}
                />
              ) : (
                sendPaymentRender()
              )}
            </Box>
          )}
        </Box>
      </Box>
      {isOpenPaymentModeModal && (
        <PaymentModeModal
          handleModalClose={() => setIsOpenPaymentModeModal(false)}
          openModal={isOpenPaymentModeModal}
          setSelectedMode={setSelectedMode}
        />
      )}
    </Dialog>
  );
};

export default SendModal;
