import { Modal } from '@speed/common/src/components/Modal/Modal'
import Text from '@speed/common/src/components/Text/Text';
import Box from '@mui/material/Box';
import { successMsg, welcomeMsg } from '../messages';
import { handSvg } from '../images';
import Button from '@speed/common/src/components/Button/Button';

const VerifiedUserModal = (props) => {
    const content = <Box textAlign="center" padding="9px 0">
        <img src={handSvg} alt="hand-emoji" height="52px" width="52px" style={{ marginTop: "12px" }} />
        <Text className="default-text margin-top15" align="center" size={16} font="semibold" variant="subtitle1">{welcomeMsg}</Text>
        <Text marginBottom="21px" className="grey-text margin-top15" align="center" size={16} font="regular" variant="subtitle1">{successMsg}</Text>
        <Button sx={{ marginTop: "7px" }} label="Get Started" fullWidth onClick={props.handleClose} />
    </Box>

    return (
        <Modal
            body={content}
            handleClose={props.handleClose}
            open={props.open}
            closeIcon={true}
        />
    );
};

export default VerifiedUserModal;