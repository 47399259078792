import React, { useCallback, useEffect, useState } from "react";
import { Box, TableCell, TableRow } from "@mui/material";
import { Link, useParams } from "react-router-dom";

import {
  callAPIInterface,
  checkStatus,
  dateTimeFormatInApp,
  noOfRecords,
  renderStatus,
} from "../constants";
import CustomTable from "@speed/common/src/components/Table/Table";
import {
  clipboardElement,
  getAmountByCurrency,
  getCurrencyObj,
} from "@speed/common/src/components/constants";
import history from "@speed/common/src/components/history";
import { useSelector } from "react-redux";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import CustomDivider from "@speed/common/src/components/Divider/Divider";

const columns = [
  { title: "Price", width: "315px !important" },
  { title: "Price ID", width: "135px" },
  { title: "Created on", width: "230px" },
  { title: "Status", width: "120px" },
  { title: "Action", with: "100px" },
];

const ProductPricesList = () => {
  const urlParams = useParams();
  const queryParam = { limit: noOfRecords };
  const breadcrumbs = [
    { title: "Products", route: "/products" },
    { title: "Details", route: `/products/${urlParams?.id}` },
    { title: "Prices", route: "/prices" },
  ];

  const [rows, setRows] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const liveMode = useSelector((state) => state.auth.liveMode);

  const loadMore = useCallback(() => {
    getPrices(rows, queryParams);
  }, [rows]);

  useEffect(() => {
    setRows([]);
    setHasMore(true);
    getPrices([], queryParam);
  }, [liveMode]);

  const handleClick = (event, from) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const getPrices = (lines, params) => {
    setTableRowSkeleton(true);
    callAPIInterface("POST", `/prices/filter`, {
      product: urlParams?.id,
      ...params,
    })
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams({
              ...queryParam,
              ending_before: res.data[res.data.length - 1].id,
            });
          }
          setRows(lines.concat(res.data));
        }
      })
      .catch(() => {
        setTableRowSkeleton(false);
      });
  };

  const columnsData = columns.map((column, index) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{ minWidth: column.width }}
    >
      {column.title}
    </TableCell>
  ));

  const rowsData = rows?.map((rowItem) => (
    <TableRow
      key={rowItem?.id}
      className="clickable"
      onClick={() => history.push(`/prices/${rowItem?.id}`)}
    >
      <TableCell>
        {getCurrencyObj(rowItem?.currency).symbol}
        {getAmountByCurrency(rowItem?.unit_amount, rowItem?.currency)}
      </TableCell>
      <TableCell>
        {clipboardElement(rowItem?.id, true, "product-detail", "text", true)}
      </TableCell>
      <TableCell align="left">
        {dateTimeFormatInApp(rowItem?.created)}
      </TableCell>
      <TableCell align="left">{renderStatus(checkStatus(rowItem))}</TableCell>
      <TableCell>
        <MoreHorizIcon
          className="horizontal-dots-icon pointer-cursor"
          tabIndex={0}
          onClick={(event) => {
            event.stopPropagation();
            handleClick(event, "price");
          }}
        />
      </TableCell>
    </TableRow>
  ));

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
    className: "product-prices-table",
  };

  return (
    <Box className="section-wrapper">
      <Box className="product-prices-header">
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      </Box>
      <CustomDivider />
      <Box className="main-content">
        <CustomTable {...tableProps} />
      </Box>
    </Box>
  );
};

export default ProductPricesList;
