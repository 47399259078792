import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import WarningIcon from "@mui/icons-material/Warning";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Grid, TableCell, TableRow, Box } from "@mui/material";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import {
  clipboardElement,
  redirectWhenEntityNotFound,
  linkStatus,
  satsToBtcAmount,
  generateBreadcrumbs,
} from "@speed/common/src/components/constants";
import Text from "@speed/common/src/components/Text/Text";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import Button from "@speed/common/src/components/Button/Button";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import {
  callAPIInterface,
  RouteBreadcrumbs,
  renderStatus,
  dateTimeFormatInApp,
} from "../constants";
import {
  activate,
  buttonTextForIdNotFound,
  deActivate,
  details,
  entityNotFoundMessage,
  latestPaymentLabel,
  paymentAddressText,
  subtitleForIdNotFound,
  events as eventsText,
} from "../messages";
import "../../assets/styles/payment-links.scss";
import DetailPagePaymentSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import LinkSkeleton from "@speed/common/src/components/Skeleton/LinkSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";
import { events } from "../Events/Events";

const latestPaymentColumnData = [
  { title: "Payment ID", width: "480px" },
  { title: "Amount (BTC)", width: "135px", align: "right" },
  { title: "Date", width: "230px" },
  { title: "Status", width: "120px" },
];

const PaymentAddressDetails = (props) => {
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const liveMode = useSelector((state) => state.auth.liveMode);
  const history = useSelector((state) => state.common.history);
  const [data, setData] = useState(null);
  const [paymentSkeleton, setPaymentSkeleton] = useState(false);
  const [latestPaymentRowData, setLatestPaymentData] = useState([]);
  const [eventSkeleton, setEventSkeleton] = useState(true);
  const [eventsList, setEventsList] = useState([]);
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const {
    paListallpaymentaddressFe,
    paFetchpaymentaddressFe,
    paActivatepaymentaddressFe,
    paDeactivatepaymentaddressFe,
    paListofpaymentsFe,
    paEventFe,
  } = useFlags();

  const urlParams = useParams();

  const loadDetailPage = () => {
    setData(null);
    setIsShowNotFound(false);
    getPaymentAddressDetail();
  };

  useEffect(
    () =>
      paListallpaymentaddressFe && paFetchpaymentaddressFe && loadDetailPage(),
    [liveMode, paFetchpaymentaddressFe, paListallpaymentaddressFe, paEventFe]
  );

  const getPaymentAddressDetail = () => {
    setPaymentSkeleton(true);
    setEventSkeleton(true);
    callAPIInterface("GET", `/payment-addresses/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setData(res);
          paListofpaymentsFe
            ? getLatestPayment(res.id)
            : setPaymentSkeleton(false);
          paEventFe ? getPaymentAddressEventsList() : setEventSkeleton(false);
        }
      })
      .catch((err) => {
        setPaymentSkeleton(false);
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const getLatestPayment = (id) => {
    callAPIInterface("GET", `/payment-addresses/${id}/payments`)
      .then((res) => {
        if (res) {
          setPaymentSkeleton(false);
          setLatestPaymentData(res.data);
        }
      })
      .catch(() => {
        setPaymentSkeleton(false);
      });
  };

  const getPaymentAddressEventsList = () => {
    setEventSkeleton(true);
    const filterEvents = {
      ids: [urlParams?.id],
    };
    callAPIInterface("POST", "/events/filters", JSON.stringify(filterEvents))
      .then((res) => {
        if (res) {
          setEventSkeleton(false);
          setEventsList(res?.data);
        }
      })
      .catch(() => {
        setEventSkeleton(false);
      });
  };

  const latestPaymentRows = latestPaymentRowData?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/payments/${rowItem?.id}`)}
      >
        <TableCell>
          {clipboardElement(rowItem?.id, true, "branding-detail", "text", true)}
        </TableCell>
        <TableCell align="right">
          {satsToBtcAmount(
            rowItem?.status === linkStatus.paid.value
              ? rowItem?.target_amount_paid
              : rowItem?.target_amount
          )}
        </TableCell>
        <TableCell align="left">
          {dateTimeFormatInApp(rowItem?.created)}
        </TableCell>
        <TableCell align="left">{renderStatus(rowItem?.status)}</TableCell>
      </TableRow>
    );
  });

  const eventRows = eventsList?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/events/${rowItem?.id}`)}
      >
        <TableCell>{events(rowItem)}</TableCell>
        <TableCell align="right" sx={{ color: "#848b9e" }}>
          {dateTimeFormatInApp(rowItem?.created)}
        </TableCell>
      </TableRow>
    );
  });

  const detailsTableData = () => {
    return [
      {
        header: "Status",
        cell: (
          <Tag
            text={linkStatus[data?.status].label}
            variant={linkStatus[data?.status].variant}
          />
        ),
      },
      {
        header: "Date created",
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {dateTimeFormatInApp(data?.created)}
          </Text>
        ),
      },
      {
        header: "Payment Address ID",
        cell: clipboardElement(data?.id, true, "", "inputBox", false),
      },
    ];
  };

  const headerContent = () => {
    return (
      <Box className="header-content">
        <Text className="default-text" size={28}>
          {paymentAddressText[0]}
        </Text>
        <Box mt="18px">
          {clipboardElement(
            data?.address,
            data?.status === linkStatus.active.value,
            "",
            "inputBox",
            false
          )}
        </Box>
      </Box>
    );
  };

  const renderUpdateButton = () => {
    const isActive = data?.status === linkStatus.active.value;
    return (
      <Button
        icon={isActive ? "closeIcon" : "checkIcon"}
        label={isActive ? deActivate : activate}
        variant="outlined"
        color={isActive ? "error" : "primary"}
        onClick={handleUpdate}
      />
    );
  };

  const handleUpdate = () => {
    setData(null);
    setPaymentSkeleton(true);
    setEventSkeleton(true);
    const method =
      data?.status === linkStatus.active.value ? "deactivate" : "activate";
    callAPIInterface("POST", `/payment-addresses/${data.id}/${method}`, {})
      .then((res) => {
        if (res) {
          setData(res);
          paListofpaymentsFe
            ? getLatestPayment(res.id)
            : setPaymentSkeleton(false);
          paEventFe ? getPaymentAddressEventsList() : setEventSkeleton(false);
        }
      })
      .catch(() => {
        loadDetailPage();
      });
  };

  return (
    <>
      {!isShowNotFound ? (
        <Grid className={`payment-link-detail-wrapper`} container spacing={1}>
          <Box className="section-wrapper" sx={{ position: "relative" }}>
            {data && (
              <Box
                className="action-btn-wrapper"
                style={{ top: "23px", right: "2px" }}
              >
                {(data?.status === linkStatus.active.value
                  ? paDeactivatepaymentaddressFe
                  : paActivatepaymentaddressFe) && renderUpdateButton()}
              </Box>
            )}
            <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
            {paListallpaymentaddressFe && paFetchpaymentaddressFe ? (
              <>
                {data ? (
                  <>
                    {headerContent()}
                    <Box className="details-content">
                      <VerticalTable
                        label={details}
                        className="transfer-details-wrapper"
                        rowData={detailsTableData()}
                      />
                    </Box>
                  </>
                ) : (
                  <LinkSkeleton props={props} />
                )}
                {paListofpaymentsFe && (
                  <>
                    {!paymentSkeleton ? (
                      <Box className="product-content latest-payment-content">
                        <HorizontalTable
                          tableBodyClassName="pointer-cursor"
                          label={latestPaymentLabel}
                          columns={latestPaymentColumnData}
                          rows={latestPaymentRows}
                          rowsPerPage={3}
                          isColumnShown={latestPaymentRows?.length > 0}
                          handleViewAllClick={() =>
                            history.push(
                              `/payment-addresses/${urlParams.id}/payments`
                            )
                          }
                          isShowButton={latestPaymentRows?.length > 3}
                        />
                      </Box>
                    ) : (
                      <DetailPagePaymentSkeleton />
                    )}
                  </>
                )}
                {paEventFe && (
                  <>
                    {!eventSkeleton ? (
                      <Box className="product-content">
                        <HorizontalTable
                          label={eventsText}
                          isColumnShown={eventRows?.length > 0}
                          rows={eventRows}
                          rowsPerPage={3}
                          handleViewAllClick={() => {
                            history.push(
                              `/payment-addresses/${urlParams?.id}/events`
                            );
                          }}
                          isShowButton={eventRows?.length > 3}
                          tableBodyClassName="border-none"
                        />
                      </Box>
                    ) : (
                      <DetailPagePaymentSkeleton />
                    )}
                  </>
                )}
              </>
            ) : (
              <AccessDenied />
            )}
          </Box>
        </Grid>
      ) : (
        <Box className="not-found-wrapper">
          <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
          <LinkNotFound
            icon={<WarningIcon className="warning-icon" />}
            title={entityNotFoundMessage("Payment Address")}
            subTitle={subtitleForIdNotFound("Payment Address", urlParams.id)}
            btnText={buttonTextForIdNotFound("payment address", liveMode)}
          />
        </Box>
      )}
    </>
  );
};

export default PaymentAddressDetails;
