import React from "react";
import Text from "@speed/common/src/components/Text/Text";
import { showAmount } from "@speed/common/src/components/constants";
import Button from "@speed/common/src/components/Button/Button";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import {
  btc,
  insufficientBalanceMsgPayout,
  insufficientFund,
  sats,
  tryAgain,
} from "../messages";

const InsufficientBalanceModal = ({
  open,
  totalAmount,
  handleTryAgain,
  handleClose,
  type,
  targetCurrency,
}) => {
  const formatDisplayValues = (amount) =>
    showAmount({
      amount,
      currency: targetCurrency,
      targetedCurrency: targetCurrency === sats ? btc : targetCurrency,
      showCommaSeparated: true,
      appendCurrency: true,
    });

  const balanceModalBody = (
    <Text size={14} variant="subtitle1" font="regular" className="default-text">
      {insufficientBalanceMsgPayout(formatDisplayValues(totalAmount), type)}
    </Text>
  );

  const balanceModalFooter = (
    <Button label={tryAgain} onClick={handleTryAgain} />
  );

  return (
    <Modal
      maxWidth="xs"
      open={open}
      body={balanceModalBody}
      footer={balanceModalFooter}
      handleClose={handleClose}
      title={insufficientFund}
    />
  );
};

export default InsufficientBalanceModal;
