import { Box, Skeleton } from "@mui/material";
import React from "react";
import Text from "@speed/common/src/components/Text/Text";
import "./get-started-header.scss";
import Button from "@speed/common/src/components/Button/Button";
import { useDispatch } from "react-redux";
import history from "../history";
import { onboardingVectorBgImage, speedLogoOnboarding } from "../images";

const UseCaseLoading = () => (
  <Box marginBottom="50px" width="456px" marginRight="60px">
    <Box className="use-case-list-heading">
      <Skeleton
        variant="circular"
        animation="wave"
        width={24}
        height={24}
        sx={{
          bgcolor: "#eaeef12b !important",
          marginRight: "14px",
        }}
      />
      <Skeleton
        animation="wave"
        width={290}
        height={18}
        sx={{
          bgcolor: "#eaeef12b !important",
        }}
      />
    </Box>
    <Text
      variant="body1"
      className="use-case-list-desc"
      font="regular"
      size={16}
    >
      <Skeleton
        animation="wave"
        width={418}
        height={10}
        sx={{
          bgcolor: "#eaeef12b !important",
        }}
      />
      <Skeleton
        animation="wave"
        width={418}
        height={10}
        sx={{
          bgcolor: "#eaeef12b !important",
        }}
      />
      <Skeleton
        animation="wave"
        width={418}
        height={10}
        sx={{
          bgcolor: "#eaeef12b !important",
        }}
      />
      <span style={{ display: "none" }}>Hidden text</span>
    </Text>
  </Box>
);

const UseCaseList = ({ useCase }) => {
  return (
    <Box marginBottom="50px" width="456px" marginRight="60px">
      <Box className="use-case-list-heading">
        <img
          src={useCase?.icon?.data?.attributes?.url}
          height={24}
          width={24}
          alt="use-case-icon"
          style={{ marginRight: "14px" }}
        />
        <Text font="semi-bold" size={22} style={{ lineHeight: "130%" }}>
          {useCase?.title}
        </Text>
      </Box>
      <Text
        variant="body1"
        className="use-case-list-desc"
        font="regular"
        size={16}
      >
        {useCase?.subtitle}
      </Text>
    </Box>
  );
};

const GetStartedHeader = ({
  loading = true,
  component = null,
  getHeaderInfo,
  setIsModalOpen,
  redirectUrl = null,
}) => {
  const dispatch = useDispatch();
  return (
    <>
      {component}
      <Box className="get-started-section">
        <Box className="get-started-header">
          <Box
            display="flex"
            style={{
              backgroundImage: `url(${speedLogoOnboarding})`,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "right",
            }}
          >
            <Box width="72%">
              <Box paddingLeft="50px">
                <Text className="get-started-header-text card-text" font="bold">
                  {loading ? (
                    <Skeleton
                      animation="wave"
                      width={241}
                      height={36}
                      sx={{ bgcolor: "#C4CCD2 !important" }}
                    />
                  ) : (
                    <>{getHeaderInfo?.title}</>
                  )}
                </Text>
                <Text
                  variant="body1"
                  className="grey-text"
                  font="regular"
                  size={20}
                  style={{
                    marginTop: "10px",
                    paddingRight: "126px",
                    lineHeight: "160%",
                  }}
                >
                  {loading ? (
                    <>
                      <Skeleton animation="wave" width={670} height={14} />
                      <Skeleton animation="wave" width={670} height={14} />
                    </>
                  ) : (
                    <>{getHeaderInfo?.subtitle}</>
                  )}
                </Text>
                {loading ? (
                  <Skeleton
                    animation="wave"
                    width={217}
                    height={18}
                    sx={{ bgcolor: "#8BA7EC !important", marginTop: "24px" }}
                  />
                ) : (
                  getHeaderInfo?.button_comp && (
                    <Button
                      style={{ marginTop: "24px" }}
                      label={getHeaderInfo.button_comp.title}
                      variant="contained"
                      color="primary"
                      className="payment-link-btn"
                      onClick={() => {
                        if (redirectUrl) {
                          history.push(redirectUrl);
                        }
                        dispatch(setIsModalOpen(true));
                      }}
                    />
                  )
                )}
              </Box>
            </Box>
          </Box>
          <Box
            className="get-started-use-cases"
            style={{
              backgroundImage: `url(${onboardingVectorBgImage})`,
            }}
          >
            <Text
              variant="h5"
              className="use-case-text"
              font="semibold"
              size={18}
            >
              {loading ? (
                <Skeleton
                  animation="wave"
                  width={110}
                  height={22}
                  sx={{ bgcolor: "#eaeef12b !important" }}
                />
              ) : (
                <>{getHeaderInfo?.use_case_title.toUpperCase()}</>
              )}
            </Text>
            <Box className="use-case-list">
              {loading
                ? [...Array(4).keys()].map((val) => (
                    <UseCaseLoading key={val} />
                  ))
                : getHeaderInfo?.use_cases?.map((useCase) => (
                    <UseCaseList key={useCase?.id} useCase={useCase} />
                  ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default GetStartedHeader;
