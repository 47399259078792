import React from "react";
import Text from "@speed/common/src/components/Text/Text";
import { Box, Grid } from "@mui/material";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import { RouteBreadcrumbs, getWhAttemptDetails } from "../constants";
import { RequestEndpointLabel, details } from "../messages";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import jsonDataToShow from "../jsonLogic";
import { useLocation, Link } from "react-router-dom";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import { generateBreadcrumbs } from "@speed/common/src/components/constants";

const WebhookAttemptDetails = (props) => {
  const locationData = useLocation();
  const { data: isDetailSelected, version } = { ...locationData?.state };

  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });

  const detailsTableData = getWhAttemptDetails(isDetailSelected);

  return (
    <Grid className="payments-detail-wrapper" container spacing={1}>
      <Breadcrumbs
        component={Link}
        breadcrumbData={breadcrumbs}
        sx={{ paddingBottom: "20px" }}
        queryParam={`?api_version=${version}`}
      />
      <Text
        font="semibold"
        variant="subtitle"
        className="default-text"
        size={20}
      >
        {isDetailSelected?.endpoint_url}
      </Text>
      <Text
        sx={{ mt: "55px" }}
        size={20}
        font="semibold"
        variant="subtitle1"
        className="default-text"
      >
        {details}
      </Text>
      <CustomDivider sx={{ m: "22px 0 17px" }} />
      <VerticalTable
        rowData={detailsTableData}
        className="users-vertical-data"
      />
      <CustomDivider sx={{ m: "50px 0 26px" }} />
      <Box display="flex" flexDirection="column">
        <Text size={18} variant="h1" component="h2">
          {RequestEndpointLabel} :
        </Text>
        {jsonDataToShow(isDetailSelected?.request)}
      </Box>
    </Grid>
  );
};

export default WebhookAttemptDetails;
