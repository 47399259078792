import React, { forwardRef } from "react";
import { AppBar, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Text from "@speed/common/src/components/Text/Text";
import { confirmSwap, createSwap } from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import { back, next } from "@speed/common/src/components/messages";
import SwapRequestDetails from "./SwapRequestDetails";
import SwapRequestSummary from "./SwapRequestSummary";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateSwapModal = (props) => {
  const {
    openModal,
    handleModalClose,
    goToNextPage,
    handleBackButton,
    handleNextButton,
    disableConfirm,
    handleSubmit,
    isChecked,
    setIsChecked,
    isValid,
    dirty,
    values,
    setFieldValue,
    touched,
    setTouched,
    amountRef,
    errors,
    setErrors,
    defaultErrorMessageProps,
    balanceLoading,
    balanceData,
    disableNext,
    handleDisableNext,
    resetForm,
    targetAmountLoading,
    minimumAmount,
  } = props;

  const requestBody = () => {
    if (!goToNextPage) {
      return (
        <SwapRequestDetails
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          setTouched={setTouched}
          amountRef={amountRef}
          errors={errors}
          setErrors={setErrors}
          defaultErrorMessageProps={defaultErrorMessageProps}
          balanceLoading={balanceLoading}
          balanceData={balanceData}
          handleDisableNext={handleDisableNext}
          isValid={isValid}
          dirty={dirty}
          resetForm={resetForm}
          minimumAmount={minimumAmount}
        />
      );
    } else {
      return (
        <SwapRequestSummary
          values={values}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          targetAmountLoading={targetAmountLoading}
        />
      );
    }
  };

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={openModal}
      TransitionComponent={Transition}
      className="fullscreen-modal"
      onClose={handleModalClose}
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1 }}
            className="default-text divider"
            variant="h6"
          >
            {createSwap}
          </Text>
          {goToNextPage ? (
            <>
              <Button
                variant="outlined"
                color="primary"
                label={back}
                onClick={handleBackButton}
                sx={{ marginRight: "18px" }}
                disabled={disableConfirm}
              />
              <Button
                label={confirmSwap}
                variant="contained"
                color="primary"
                className="payment-link-btn"
                onClick={handleSubmit}
                disabled={!isChecked || disableConfirm}
              />
            </>
          ) : (
            <Button
              label={next}
              variant="contained"
              color="primary"
              className="payment-link-btn"
              onClick={handleNextButton}
              disabled={disableNext}
            />
          )}
        </Toolbar>
      </AppBar>
      {requestBody()}
    </Dialog>
  );
};

export default CreateSwapModal;
