import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, TableCell, TableRow } from "@mui/material";
import { useFormik } from "formik";
import { useFlags } from "launchdarkly-react-client-sdk";
import * as yup from "yup";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import Button from "@speed/common/src/components/Button/Button";
import {
  clipboardElement,
  fetchPrecisedInteger,
  fetchRawBalanceData,
  getCurrencyObj,
  integerWithCurrency,
} from "@speed/common/src/components/constants";
import { emptySwap } from "@speed/common/src/components/images";
import { emptySubText } from "@speed/common/src/components/messages";
import CustomTable from "@speed/common/src/components/Table/Table";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  minimumSatsAmountToSwap,
  minimumUsdcAmountToSwap,
  noOfRecords,
} from "../constants";
import {
  createNew,
  createSwap,
  emptySwapSubText,
  moduleAmountQty,
} from "../messages";
import CreateSwapModal from "./CreateSwapModal";
import { validAmount } from "@speed/common/src/messages";
import { setIsDataAvailable, setIsModalOpen } from "../../redux/common/actions";

const SwapList = () => {
  const amountRef = useRef(null);
  const queryParam = "?limit=" + noOfRecords;
  const [rows, setRows] = useState([]);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [targetAmountLoading, setTargetAmountLoading] = useState(false);
  const [queryParams, setQueryParams] = useState(queryParam);
  const { swListingswapFe, swCreateFe } = useFlags();
  const [balanceData, setBalanceData] = useState();
  const [goToNextPage, setGoToNextPage] = useState(false);
  const [disableConfirm, setDisableConfirm] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [disableNext, setDisableNext] = useState(true);
  const [disableCreateButton, setDisableCreateButton] = useState(false);

  const liveMode = useSelector((state) => state.auth.liveMode);
  const { history, isDataAvailable, isModalOpen, caughtErrorInStrapi } =
    useSelector((state) => state.common);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isModalOpen) {
      handleCreateSwap();
    }
  }, [isModalOpen]);

  const fetchSwaps = () => {
    setRows([]);
    setHasMore(true);
    getSwaps([], queryParam);
  };

  const loadMore = useCallback(() => {
    getSwaps(rows, queryParams);
  }, [rows]);

  const getSwaps = (lines, params) => {
    setTableRowSkeleton(true);
    callAPIInterface("GET", "/balances/swap" + params, {})
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam + "&ending_before=" + res.data[res.data.length - 1].id
            );
          }
          setRows(lines.concat(res.data));
          dispatch(setIsDataAvailable(res.data.length > 0));
        }
      })
      .catch((error) => {
        setTableRowSkeleton(false);
      });
  };

  useEffect(() => swListingswapFe && fetchSwaps(), [liveMode, swListingswapFe]);

  useEffect(() => {
    setDisableCreateButton(true);
    const timer = setTimeout(() => setDisableCreateButton(false), 500);
    return () => clearTimeout(timer);
  }, [liveMode]);

  const columns = [
    {
      title: "Swap ID",
      paddingLeft: "38px !important",
      width: "200px",
    },
    { title: "From", width: "72px" },
    { title: "To", width: "72px" },
    { title: "Swap Amount", align: "right", width: "230px" },
    { title: "You Got", align: "right", width: "230px" },
    { title: "Created on", width: "235px" },
  ];

  let columnsData = columns.map((column) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{
        minWidth: column.width,
        height: "50px",
        paddingLeft: column.paddingLeft,
      }}
    >
      {column.title}
    </TableCell>
  ));

  let rowsData = rows?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem?.id}
        className="clickable"
        onClick={() => history.push(`/swaps/${rowItem?.id}`)}
      >
        <TableCell sx={{ paddingLeft: "38px !important" }}>
          {clipboardElement(
            rowItem?.id,
            true,
            "payments-listing-id-clipboard",
            "inputBox",
            false
          )}
        </TableCell>
        <TableCell sx={[{ height: "50px" }]}>{rowItem?.currency}</TableCell>
        <TableCell>{rowItem?.target_currency}</TableCell>
        <TableCell>
          <Box display="flex" justifyContent="end" gap={0.6}>
            {integerWithCurrency(rowItem?.currency, rowItem?.amount, true)}
          </Box>
        </TableCell>
        <TableCell>
          <Box display="flex" justifyContent="end" gap={0.6}>
            {integerWithCurrency(
              rowItem?.target_currency,
              rowItem?.target_amount,
              true
            )}
          </Box>
        </TableCell>
        <TableCell>{dateTimeFormatInApp(rowItem?.created)}</TableCell>
      </TableRow>
    );
  });

  const validationSchema = yup.object({
    amount: yup.number().when(["balance", "currency"], (balance, currency) => {
      if (balance && currency) {
        const minimumAmount =
          currency?.code === "SATS"
            ? minimumSatsAmountToSwap
            : minimumUsdcAmountToSwap;
        return yup
          .number()
          .typeError(validAmount)
          .min(
            minimumAmount,
            moduleAmountQty(
              minimumAmount,
              fetchPrecisedInteger(balance, 8),
              "Swap",
              currency?.code
            )
          )
          .max(
            balance,
            moduleAmountQty(
              minimumAmount,
              fetchPrecisedInteger(balance, 8),
              "Swap",
              currency?.code
            )
          )
          .required("");
      }
    }),
  });

  const initialValues = {
    amount: "",
    currency: getCurrencyObj("SATS", true),
    target_amount: "",
    target_currency: getCurrencyObj("USDC", true),
    balance: "",
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (value) => {
      setDisableConfirm(true);
      const swapData = {
        amount: value?.amount,
        currency: value?.currency?.code,
        target_currency: value?.target_currency?.code,
      };
      callAPIInterface("POST", "/balances/swap", JSON.stringify(swapData))
        .then((result) => {
          result && history.push(`/swaps/${result.id}`);
          setDisableConfirm(false);
          handleModalClose();
        })
        .catch((err) => {
          setDisableConfirm(false);
          handleModalClose();
        });
    },
  });

  const {
    values,
    setFieldValue,
    touched,
    setTouched,
    isValid,
    dirty,
    handleSubmit,
    errors,
    setErrors,
    resetForm,
  } = formik;

  const getExchangeRate = async () => {
    setTargetAmountLoading(true);
    const enteredAmount = values?.amount;
    const data = {
      currency: values?.currency.code,
      target_currency: values?.target_currency.code,
    };
    if (enteredAmount && _.isEmpty(errors)) {
      await callAPIInterface("POST", "/utility/exchange-rate", data).then(
        (res) => {
          setTargetAmountLoading(false);
          const exchangeRate = res?.target_lowest_rate;
          const targetAmount = values?.amount * exchangeRate;
          setFieldValue("target_amount", targetAmount);
        }
      );
    }
  };

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const getBalance = () => {
    return new Promise((resolve, reject) => {
      callAPIInterface("GET", "/balances")
        .then((response) => {
          resolve(response);
        })
        .catch((_error) => {
          reject(_error);
        });
    });
  };

  const handleCreateSwap = () => {
    setBalanceLoading(true);
    dispatch(setIsModalOpen(true));
    getBalance()
      .then((res) => {
        setBalanceLoading(false);
        const resultData = fetchRawBalanceData(res);
        const data = resultData.map((item) => {
          return { ...item, amount: Math.floor(item.amount) };
        });
        setBalanceData(data);
        const amountBalance =
          data?.find(
            (value) => value?.target_currency === values?.currency?.code
          )?.amount || 0;
        setFieldValue("balance", amountBalance);
      })
      .catch((error) => {
        setBalanceLoading(false);
      });
  };

  const handleModalClose = () => {
    dispatch(setIsModalOpen(false));
    resetForm(initialValues);
    setBalanceData();
    setGoToNextPage(false);
    setIsChecked(false);
    setDisableNext(true);
  };

  const handleBackButton = () => setGoToNextPage(false);
  const handleNextButton = () => {
    getExchangeRate();
    setGoToNextPage(true);
  };

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    textOnNoData: emptySubText(liveMode, "Swap"),
    subTextOnNoData: emptySwapSubText,
    noDataImage: emptySwap,
    createButtonText: swCreateFe && disableCreateButton && createSwap,
    handleCreate: handleCreateSwap,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
  };
  return (
    <>
      <Box className="action-btn-wrapper mui-fixed">
        {swCreateFe
          ? (isDataAvailable || caughtErrorInStrapi) && (
              <Button
                icon="addIcon"
                className="add-icon"
                label={createNew}
                variant="outlined"
                color="primary"
                onClick={() => dispatch(setIsModalOpen(true))}
                disabled={disableCreateButton}
              />
            )
          : null}
      </Box>
      <Box className="main-content">
        {swListingswapFe ? (
          (isDataAvailable || caughtErrorInStrapi) && (
            <CustomTable {...tableProps} />
          )
        ) : (
          <AccessDenied />
        )}
        <CreateSwapModal
          openModal={isModalOpen}
          handleModalClose={handleModalClose}
          goToNextPage={goToNextPage}
          handleBackButton={handleBackButton}
          handleNextButton={handleNextButton}
          disableConfirm={disableConfirm}
          handleSubmit={handleSubmit}
          isChecked={isChecked}
          setIsChecked={setIsChecked}
          isValid={isValid}
          dirty={dirty}
          values={values}
          setFieldValue={setFieldValue}
          touched={touched}
          setTouched={setTouched}
          amountRef={amountRef}
          errors={errors}
          setErrors={setErrors}
          defaultErrorMessageProps={defaultErrorMessageProps}
          balanceLoading={balanceLoading}
          balanceData={balanceData}
          disableNext={disableNext}
          handleDisableNext={(value) => setDisableNext(value)}
          resetForm={resetForm}
          targetAmountLoading={targetAmountLoading}
          minimumAmount={
            values?.currency?.code === "SATS"
              ? minimumSatsAmountToSwap
              : minimumUsdcAmountToSwap
          }
        />
      </Box>
    </>
  );
};

export default SwapList;
