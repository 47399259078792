import SyntaxHighlighter from "react-syntax-highlighter";

const customTheme = {
  hljs: {
    display: "block",
    overflowX: "auto",
    padding: "0.5em",
    color: "#0a193e",
    background: "transparent",
  },
  "hljs-comment": {
    color: "#408080",
    fontStyle: "italic",
  },
  "hljs-quote": {
    color: "#408080",
    fontStyle: "italic",
  },
  "hljs-keyword": {
    color: "#2a67ff",
  },
  "hljs-selector-tag": {
    color: "#2a67ff",
  },
  "hljs-literal": {
    color: "#2ecc71",
  },
  "hljs-subst": {
    color: "#e74c3c",
  },
  "hljs-number": {
    color: "#e74c3c",
  },
  "hljs-string": {
    color: "#2ecc71",
  },
  "hljs-doctag": {
    color: "#219161",
  },
  "hljs-selector-id": {
    color: "#19469d",
  },
  "hljs-selector-class": {
    color: "#19469d",
  },
  "hljs-section": {
    color: "#19469d",
  },
  "hljs-type": {
    color: "#19469d",
  },
  "hljs-params": {
    color: "#00f",
  },
  "hljs-title": {
    color: "#458",
    fontWeight: "bold",
  },
  "hljs-tag": {
    color: "#000080",
    fontWeight: "normal",
  },
  "hljs-name": {
    color: "#000080",
    fontWeight: "normal",
  },
  "hljs-attribute": {
    color: "#000080",
    fontWeight: "normal",
  },
  "hljs-variable": {
    color: "#008080",
  },
  "hljs-template-variable": {
    color: "#008080",
  },
  "hljs-regexp": {
    color: "#b68",
  },
  "hljs-link": {
    color: "#b68",
  },
  "hljs-symbol": {
    color: "#990073",
  },
  "hljs-bullet": {
    color: "#990073",
  },
  "hljs-built_in": {
    color: "#0086b3",
  },
  "hljs-builtin-name": {
    color: "#0086b3",
  },
  "hljs-meta": {
    color: "#999",
    fontWeight: "bold",
  },
  "hljs-deletion": {
    background: "#fdd",
  },
  "hljs-addition": {
    background: "#dfd",
  },
  "hljs-emphasis": {
    fontStyle: "italic",
  },
  "hljs-strong": {
    fontWeight: "bold",
  },
  "hljs-attr": {
    color: "#2a67ff",
  },
};

const jsonDataToShow = (propsData) => {
  return (
    <SyntaxHighlighter
      language="json"
      style={customTheme}
      showLineNumbers
      lineNumberStyle={{ color: "#848b9e" }}
    >
      {propsData && JSON.stringify(propsData, null, 2)}
    </SyntaxHighlighter>
  );
};

export default jsonDataToShow;
