import { useState, useEffect, useCallback } from "react";
import { Box, Grid, TableCell, TableRow } from "@mui/material";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import CustomTable from "@speed/common/src/components/Table/Table";
import {
  succeeded,
  fail,
  success,
  failed,
  RequestEndpointLabel,
  emptyWebhookSubText,
} from "../messages";
import {
  callAPIInterface,
  renderWebhookStatus,
  noOfRecords,
  getWhAttemptDetails,
  dateTimeFormatInApp,
} from "../constants";
import { useSelector } from "react-redux";
import { emptyWebhook } from "../images";
import { Text } from "@speed/common/src/components/Text/Text";
import jsonDataToShow from "../jsonLogic";
import { emptySubText } from "@speed/common/src/components/messages";
import { emptyEvents } from "@speed/common/src/components/images";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import NoDataAvailable from "@speed/common/src/components/NoDataAvailable";
import OpenInNewOutlinedIcon from "@mui/icons-material/OpenInNewOutlined";
import { useHistory } from "react-router-dom";

const WebhookAttemptList = ({
  tab,
  version,
  eventId,
  from = "account",
  platformAccID = "",
}) => {
  const liveMode = useSelector((state) => state.auth.liveMode);
  const queryParam = "&limit=" + noOfRecords;
  const [queryParams, setQueryParams] = useState(queryParam);
  const [currentRow, setCurrentRow] = useState({});
  const [rows, setRows] = useState([]);
  const [detail, setDetail] = useState(false);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  let urlWidth = detail ? "300px" : "700px";

  const history = useHistory();

  useEffect(() => {
    setRows([]);
    setHasMore(true);
    getWebhookEvent([], queryParam);
  }, [liveMode, version, tab]);

  const textData = emptySubText(
    true,
    "",
    tab
      ? tab == succeeded.toLowerCase()
        ? succeeded + " Webhook attempts"
        : failed + " Webhook attempts"
      : "Webhook attempts"
  );

  const columns = [
    { title: "", width: "150px", paddingLeft: "38px !important" },
    { title: "Webhook URL", width: urlWidth },
    { title: "Date", width: "250px" },
  ];

  const loadMore = useCallback(() => {
    getWebhookEvent(rows, queryParams);
  }, [rows]);

  const getWebhookEvent = async (lines, param) => {
    const commonPath = `/webhooks/event/${eventId}/attempts?`;
    const path =
      from === "connect"
        ? `${commonPath}merchant_account_id=${platformAccID}&`
        : commonPath;

    setTableRowSkeleton(true);
    let method, params;
    if (tab) {
      method = "GET";
      const tabData = tab[0] == succeeded.toLowerCase() ? success : fail;
      params = `api_version=${version}&response_status=${tabData}${param}`;
    } else {
      method = "GET";
      params = `api_version=${version}${param}`;
    }
    await callAPIInterface(method, path + params, {})
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam +
                `&ending_before=${res.data[res.data.length - 1].attempt_id}`
            );
          }
          setRows(lines.concat(res?.data));
        }
      })
      .catch((error) => {
        setTableRowSkeleton(false);
      });
  };
  let rowsData = rows?.map((rowItem, index) => {
    return (
      <TableRow
        key={index}
        className="clickable"
        selected={currentRow?.attempt_id === rowItem?.attempt_id}
        sx={{
          "&.Mui-selected": {
            bgcolor: "#f0f7ff !important",
          },
        }}
        onClick={() => {
          setCurrentRow(rowItem);
          setDetail(true);
        }}
      >
        <TableCell sx={{ width: "150px", paddingLeft: "38px !important" }}>
          {renderWebhookStatus(
            rowItem?.response_status.toLowerCase() == success
              ? succeeded.toLowerCase()
              : failed.toLowerCase()
          )}
        </TableCell>
        <TableCell sx={{ width: urlWidth }}>
          <TruncatedTextTooltip
            textValue={rowItem?.endpoint_url}
            cellWidth={detail ? "300px" : "700px"}
          />
        </TableCell>
        <TableCell sx={{ width: "250px" }}>
          {dateTimeFormatInApp(rowItem?.timestamp)}
        </TableCell>
      </TableRow>
    );
  });

  let columnsData = columns.map((column, index) => (
    <TableCell
      key={index}
      align={column?.align}
      sx={{ width: column?.width, paddingLeft: column.paddingLeft }}
    >
      {column?.title}
    </TableCell>
  ));

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
    textOnNoData: textData,
    subTextOnNoData: emptyWebhookSubText,
    noDataImage: emptyEvents,
    className: "events-attempts-table",
  };

  const detailsTableData = getWhAttemptDetails(currentRow);

  return (
    <Box className="webhhok-attempt-container">
      {!rows?.length && !tableRowSkeleton ? (
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="white"
        >
          <NoDataAvailable
            text={textData}
            subText={emptyWebhookSubText}
            image={emptyWebhook}
          />
        </Box>
      ) : (
        <>
          <Grid container height="100%">
            <Grid
              item
              lg={detail ? 6.5 : 12}
              sx={{ backgroundColor: "#fff" }}
              height="100%"
            >
              <CustomTable {...tableProps} />
            </Grid>
            <CustomDivider flexItem orientation="vertical" />
            {detail && (
              <Grid
                item
                lg={5.49}
                bgcolor="white"
                height="100%"
                overflow="auto"
                position="relative"
                zIndex={5}
              >
                {detail ? (
                  <Box display="flex" flexDirection="column">
                    <Box padding={2}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap={10}
                      >
                        <TruncatedTextTooltip
                          textValue={currentRow?.endpoint_url}
                          cellWidth="410px"
                          textProps={{ fontSize: "24px !important" }}
                        />
                        <Box
                          className="open-in-new-box"
                          component="div"
                          onClick={() => {
                            history.push({
                              pathname: `/events/${eventId}/webhook-attempts/${currentRow?.attempt_id}`,
                              state: {
                                data: currentRow,
                                version,
                              },
                            });
                          }}
                        >
                          <OpenInNewOutlinedIcon
                            sx={{
                              width: "20px",
                              height: "20px",
                              color: "#848B9E",
                            }}
                          />
                        </Box>
                      </Box>
                      <VerticalTable
                        rowData={detailsTableData}
                        className="users-vertical-data"
                      />
                      <CustomDivider />
                      <Box display="flex" flexDirection="column" padding={2}>
                        <Text size={18} variant="h1" component="h2">
                          {RequestEndpointLabel} :
                        </Text>
                        <Box marginY={3} className="event-data">
                          {jsonDataToShow(currentRow?.request)}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box />
                )}
              </Grid>
            )}
          </Grid>
        </>
      )}
    </Box>
  );
};

export default WebhookAttemptList;
