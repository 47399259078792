import PropTypes from "prop-types";
import Text from "../Text/Text";
import classNames from "classnames";
import { Box } from "@mui/material";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import CheckIcon from "@mui/icons-material/Check";
import "./status.scss";

function StatusComponent({
  severity,
  label,
  className,
  textClassName,
  ...props
}) {
  let icon;
  switch (severity) {
    case "skipped":
      icon = (
        <KeyboardDoubleArrowRightIcon
          fontSize="small"
          className="skip-completed-icon"
          style={{ background: "#848B9E" }}
        />
      );
      break;
    case "completed":
      icon = (
        <CheckIcon
          fontSize="small"
          className="skip-completed-icon"
          style={{ background: "#2ECC71" }}
        />
      );
      break;
    default:
      break;
  }
  return (
    <Box className={classNames("status-wrapper", className)}>
      {icon}
      <Text
        size={14}
        variant="body1"
        className={textClassName}
        font="semibold"
        align="right"
      >
        {label}
      </Text>
    </Box>
  );
}

const propTypes = {
  label: PropTypes.oneOf(["Skipped", "Completed"]),
  severity: PropTypes.oneOf(["skipped", "completed"]),
  textClassName: PropTypes.oneOf(["skipped-text", "completed-text"]),
};

const defaultProps = {
  label: "completed",
  severity: "completed",
  textClassName: "completed-text",
};

StatusComponent.propTypes = propTypes;
StatusComponent.defaultProps = defaultProps;

export default StatusComponent;
