import React, { forwardRef, useState } from "react";
import Text from "@speed/common/src/components/Text/Text";
import Grid from "@mui/material/Grid";
import { Input } from "@speed/common/src/components/Input/Input";
import {
  forgotPasswordMsg,
  resetMsg,
  //   signInMagicLink,
} from "../messages";
import PasswordPolicy from "@speed/common/src/components/PasswordPolicy";
import InfoIcon from "@mui/icons-material/Info";
import {
  loadingMsg,
  passwordLabel,
  passwordPlaceholder,
  signIn,
} from "@speed/common/src/components/messages";
import { Button } from "@speed/common/src/components/Button/Button";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import * as yup from "yup";
import { CustomAvatar } from "@speed/common/src/components/Avatar/Avatar";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import Collapse from "@mui/material/Collapse";
import { useDispatch, useSelector } from "react-redux";
import { logIn } from "../../redux/auth/actions";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import { serverErrorStatusCodes } from "../constants";
import { CustomTooltip } from "@speed/common/src/components/Tooltip/Tooltip";

const UserBox = ({ handleEmailScreen, user, isMobile }) => {
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleEmailScreen();
    }
  };

  const { profile_images, email, name } = user;
  const inlineStyle = isMobile
    ? { overflow: "scroll", whiteSpace: "nowrap" }
    : {};
  const [showTooltip, setShowTooltip] = useState(false);
  const handleMouseEnter = (event) => {
    let element = event.target;
    setShowTooltip(element.offsetWidth < element.scrollWidth);
  };

  const emailText = (
    <Text
      size={12}
      noWrap={isMobile ? false : true}
      font="regular"
      style={{ opacity: 0.25, ...inlineStyle }}
    >
      {email}
    </Text>
  );

  const nameText = (
    <Text
      size={16}
      font="regular"
      noWrap={isMobile ? false : true}
      style={inlineStyle}
    >
      {name}
    </Text>
  );

  const ShowTooltip = ({ text, content }) => {
    return (
      <CustomTooltip
        text={text}
        position="right"
        disableHoverListener={!showTooltip}
      >
        <Box onMouseEnter={(event) => handleMouseEnter(event)}>{content}</Box>
      </CustomTooltip>
    );
  };

  return (
    <Box className="user-box">
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={1}>
          <CustomAvatar
            src={(profile_images && profile_images.large) || ""}
            userName={name}
            className="user-profile-image"
          />
        </Grid>
        <Grid item xs={7} sm={8}>
          {isMobile ? nameText : <ShowTooltip text={name} content={nameText} />}
          {isMobile ? (
            emailText
          ) : (
            <ShowTooltip text={email} content={emailText} />
          )}
        </Grid>
        <Grid item xs={1} className="edit-icon-box">
          <EditSharpIcon
            className="edit-icon"
            onClick={() => handleEmailScreen()}
            tabIndex={0}
            onKeyUp={handleKeypress}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

const PasswordScreen = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);

  const { history, isMobile } = useSelector((state) => state.common);

  const validationSchema = yup.object({
    password: yup.string().required(),
  });

  const handleLogin = (loginValues) => {
    setLoading(true);
    let postObject = {
      email: props.email,
      password: loginValues.password,
    };
    dispatch(logIn(postObject)).catch((e) => {
      setLoading(false);
      if (serverErrorStatusCodes.includes(e)) props.handleEmailScreen();
    });
  };

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleLogin,
  });

  const {
    values,
    isValid,
    isSubmitting,
    dirty,
    handleSubmit,
    setSubmitting,
    setFieldValue,
  } = formik;

  return (
    <Collapse orientation="horizontal" in={props.checked} ref={ref}>
      <Grid
        className="email-password-grid sign-in-grid"
        component="form"
        onSubmit={handleSubmit}
      >
        <UserBox
          user={{ email: props.email, ...props.verifiedUserData }}
          handleEmailScreen={props.handleEmailScreen}
          isMobile={isMobile}
        />
        <BackdropLoader
          open={isLoading}
          alt="loading..."
          text={loadingMsg}
          customClass="loading-in-auth"
        />
        <Input
          autoFocus
          customClass="margin-top30"
          type="password"
          isPasswordVisible={true}
          onChange={(e) => {
            setSubmitting(false);
            setFieldValue("password", e.target.value);
          }}
          showLabel
          label={passwordLabel}
          value={values.password}
          name="password"
          fullWidth
          placeholder={passwordPlaceholder}
          isIcon={true}
          iconContent={<PasswordPolicy from="signup" isMobile={isMobile} />}
          icon={
            <InfoIcon
              style={{
                height: 14,
                width: 14,
                color: "#848b9e",
                marginLeft: "7px",
              }}
            />
          }
        />
        <Button
          fullWidth
          className="submit-signin-btn margin-top30"
          label={signIn}
          type="submit"
          iconPosition="end"
          icon="rightArrowIcon"
          disabled={!(isValid && dirty) || isSubmitting}
        />
        {/* <Button
                    fullWidth
                    variant="default"
                    className="margin-top30"
                    label={signInMagicLink}
                    type="button"
                    icon="magicLink"
                    textClass="signin-button-text"
                    onClick={props.handleMagicLink}
                /> */}
        <Text
          size={16}
          font="semibold"
          align="center"
          className="default-text margin-top30 margin-bottom20"
        >
          {forgotPasswordMsg}
          <CustomLink
            size="large"
            bold
            className="margin-left10"
            onClick={() =>
              history.push({
                pathname: "/reset-password",
                state: {
                  isFromLoginScreen: true,
                },
              })
            }
          >
            {resetMsg}
          </CustomLink>
        </Text>
      </Grid>
    </Collapse>
  );
});

export default PasswordScreen;
