import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import Button from "@speed/common/src/components/Button/Button";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import {
  RouteBreadcrumbs,
  callAPIInterface,
  moduleMetaDataRows,
  sendInvoiceAndEmail,
  renderStatus,
  finalizeInvoice,
  dateTimeFormatInApp,
} from "../constants";
import {
  clipboardElement,
  getCurrencyObj,
  redirectWhenEntityNotFound,
  getAmountByCurrency,
  invoiceStatus,
  satsToBtcAmount,
  generateBreadcrumbs,
} from "@speed/common/src/components/constants";
import ImageIcon from "@mui/icons-material/Image";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  Grid,
  TableCell,
  TableRow,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import {
  status,
  dateCreated,
  entityNotFoundMessage,
  events as eventsText,
  metaDataText,
  edit,
  add,
  details,
  sendInvoice,
  timelineLabel,
  addNotes,
  invoiceNotePlaceholder,
  invoicePdf,
  billToTitle,
  idTitle,
  billFromTitle,
  currencyLabel,
  dateFinalisedTitle,
  memoTitle,
  paymentPageTitle,
  productLabel,
  deleteText,
  historyLabel,
  viewProductDetails,
  viewPriceDetails,
  paymentsLabel,
  finalizeInvoiceMsg,
  emailSentToastMsg,
} from "../messages";
import { useDispatch, useSelector } from "react-redux";
import WarningIcon from "@mui/icons-material/Warning";
import { events } from "../Events/Events";
import MetaDataForm from "../MetaDataForm";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import Timeline from "@speed/common/src/components/Timeline/Timeline";
import PaymentsIcon from "@mui/icons-material/Payments";
import EmailIcon from "@mui/icons-material/Email";
import RefreshIcon from "@mui/icons-material/Refresh";
import CancelIcon from "@mui/icons-material/Cancel";
import NotesIcon from "@mui/icons-material/Notes";
import ReceiptIcon from "@mui/icons-material/Receipt";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import { cancel } from "@speed/common/src/components/messages";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import SendInvoiceModal from "./SendInvoiceModal";
import ChangeInvoiceStatusModal from "./ChangeInvoiceStatusModal";
import { KeyValueGrid } from "@speed/common/src/components/KeyValueGrid";
import { showToast } from "../../redux/common/actions";
import { footerTitle } from "@speed/common/src/messages";
import { saveAs } from "file-saver";
import { loaderSpinner } from "@speed/common/src/components/images";
import DetailPagePaymentSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import LinkSkeleton from "@speed/common/src/components/Skeleton/LinkSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";

const InvoiceDetails = (props) => {
  const invoicePaymentColumnData = [
    { title: "Payment ID", width: "480px" },
    { title: "Amount (BTC)", width: "135px" },
    { title: "Date", width: "230px" },
    { title: "Status", width: "120px" },
  ];

  const productTableColumns = [
    { title: "Name", width: "300px" },
    { title: "Qty.", width: "200px", align: "right" },
    {
      title: "Unit Price",
      width: "200px",
      align: "right",
    },
    { title: "Total", width: "200px", align: "right" },
    { title: "Action", width: "50px", align: "right" },
  ];

  const invoiceHistoryColumns = [
    { title: "Invoice", width: "450px" },
    { title: "Status", width: "50px" },
    { title: "Amount", width: "50px" },
    { title: "Created on", width: "250px" },
    { title: "Due", width: "250px" },
    { title: "Actions", width: "50px" },
  ];

  const urlParams = useParams();
  const showTimeLine = false;
  const showHisory = false;
  // States
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const { liveMode, currentAccount } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const history = useSelector((state) => state.common.history);
  const [isDisabled, setIsDisabled] = useState(false);
  const [invoiceDetails, setInvoiceDetails] = useState(null);
  const [isLinkNotFound, setIsLinkNotFound] = useState(false);
  const [eventsList, setEventsList] = useState([]);
  const [invoicePaymentSkeleton, setInvoicePaymentSkeleton] = useState(false);
  const [invoicePaymentList, setInvoicePaymentList] = useState([]);
  const [eventSkeleton, setEventSkeleton] = useState(true);
  const [metaDataSkeleton, setMetaDataSkeleton] = useState(true);
  const [isMetaDataEditable, setIsMetaDataEditable] = useState(false);
  const [metaDataList, setMetaDataList] = useState([]);
  const [enabledAddNote, setEnabledAddNote] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [productAnchorEl, setProductAnchorEl] = useState(null);
  const [noteAnchorEl, setNoteAnchorEl] = useState(null);
  const [invoiceHistoryAnchorEl, setInvoiceHistoryAnchorEl] = useState(null);
  const [openSendInvoiceModal, setOpenSendInvoiceModal] = useState(false);
  const [openChangeInvoiceStatusModal, setChangeInvoiceStatusModal] =
    useState(false);
  const [showFinalizeLoader, setShowFinalizeLoader] = useState(false);
  const isFromDraftInvoice = invoiceDetails?.status?.toLowerCase() === "draft";
  const isInvoicePaid = invoiceDetails?.status?.toLowerCase() === "paid";

  const getEventsList = async () => {
    setEventSkeleton(true);
    const filterEvents = {
      ids: [urlParams?.id],
    };
    await callAPIInterface(
      "POST",
      "/events/filters",
      JSON.stringify(filterEvents)
    )
      .then((res) => {
        if (res) {
          setEventSkeleton(false);
          setEventsList(res?.data);
        }
      })
      .catch(() => {
        setEventSkeleton(false);
      });
  };

  const getInvoicePaymentList = () => {
    callAPIInterface("GET", `/invoices/${urlParams?.id}/payments`)
      .then((res) => {
        if (res) {
          setInvoicePaymentSkeleton(false);
          setInvoicePaymentList(res?.data);
        }
      })
      .catch(() => {
        setInvoicePaymentSkeleton(false);
      });
  };

  const getInvoiceDetails = () => {
    setInvoicePaymentSkeleton(true);
    setMetaDataSkeleton(true);
    callAPIInterface("GET", `/invoices/${urlParams.id}`)
      .then((res) => {
        if (res) {
          getEventsList();
          getInvoicePaymentList();
          setInvoiceDetails(res);
        }
      })
      .catch((err) => {
        setMetaDataSkeleton(false);
        setIsLinkNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const loadDetailPage = () => {
    setInvoiceDetails(null);
    setIsLinkNotFound(false);
    getInvoiceDetails();
  };

  useEffect(() => loadDetailPage(), [liveMode, urlParams?.id]);

  useEffect(() => {
    if (invoiceDetails?.metadata) {
      const convertedMetaDataToArray = Object.entries(
        invoiceDetails?.metadata
      )?.map((e) => ({
        key: e[0],
        value: e[1],
      }));
      setMetaDataList(convertedMetaDataToArray);
      setMetaDataSkeleton(false);
    }
  }, [invoiceDetails?.metadata]);

  const eventRows = eventsList?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/events/${rowItem?.id}`)}
      >
        <TableCell>{events(rowItem)}</TableCell>
        <TableCell align="right" sx={{ color: "#848b9e" }}>
          {dateTimeFormatInApp(rowItem?.created)}
        </TableCell>
      </TableRow>
    );
  });

  const commonActionListElement = (actionList) => {
    return actionList?.map((action) => (
      <ListItem key={actionList.actionName} disablePadding>
        <ListItemButton
          onClick={action.onClickAction}
          disabled={action.disabled}
        >
          <ListItemText primary={action.actionName} />
          {action?.isLoadingSectionRequired && action?.isLoading && (
            <img
              style={{ marginLeft: "10px" }}
              src={loaderSpinner}
              alt="Loader"
              width={15}
              height={15}
            />
          )}
        </ListItemButton>
      </ListItem>
    ));
  };

  const commonActionButton = ({
    popperElement,
    onClickActionButton,
    handleClosePopper,
    actionData,
  }) => {
    const iconProps = {};
    if (onClickActionButton) {
      iconProps.onClick = onClickActionButton;
    }

    return (
      actionData?.length > 0 && (
        <>
          <MoreHorizIcon
            className="horizontal-dots-icon pointer-cursor"
            onClick={onClickActionButton}
          />
          <CustomPopper
            disablePortal={true}
            open={Boolean(popperElement)}
            anchorEl={popperElement}
            position="bottom"
            handleClose={handleClosePopper}
            sx={{ minWidth: "max-content" }}
          >
            <List>{commonActionListElement(actionData)}</List>
          </CustomPopper>
        </>
      )
    );
  };

  const headerContent = (invoiceDetails) => {
    const { invoice_number, invoice_amount, hosted_invoice_url, currency } =
      invoiceDetails;
    return (
      <Box className="header-content">
        <Text className="default-text" size={24}>
          {invoice_number ? `#${invoice_number}` : "-"}
        </Text>
        <Box className="header-price-content">
          <Box className="fiat-price">
            <Text className="default-text" size={20}>
              {getCurrencyObj(currency).symbol}
              {getAmountByCurrency(invoice_amount, currency)}
            </Text>
          </Box>
        </Box>
        {hosted_invoice_url && (
          <Box className="invoice-copy-url">
            {clipboardElement(
              hosted_invoice_url,
              !isInvoicePaid,
              "branding-detail",
              "",
              true
            )}
          </Box>
        )}
      </Box>
    );
  };

  const timelineContent = () => {
    let graphData = [];
    const setTimeLineGraphData = (title, icon, date) => {
      let graphObj = {
        title,
        icon,
      };
      if (date) graphObj.date = date;

      graphData.push(graphObj);
    };

    setTimeLineGraphData(
      <Text className="default-text" size={16}>
        $2326.50 payment successful
      </Text>,
      <PaymentsIcon />,
      <Text className="grey-text" variant="h4" size={14} font="regular">
        27/06/2022, 5:21 PM by Vincent Porter
      </Text>
    );
    setTimeLineGraphData(
      <Text className="default-text" size={16}>
        Invoice was sent to accounts@speedaap.com
      </Text>,
      <EmailIcon />,
      <Text className="grey-text" variant="h4" size={14} font="regular">
        27/06/2022, 5:21 PM
      </Text>
    );
    setTimeLineGraphData(
      <Text className="default-text" size={16}>
        Invoice was created from TRYSPT-0007
      </Text>,
      <RefreshIcon />
    );
    setTimeLineGraphData(
      <Text className="default-text" size={16}>
        Invoice was voided
      </Text>,
      <CancelIcon sx={{ color: "#E74C3C !important" }} />
    );
    setTimeLineGraphData(
      <Box display="flex" justifyContent="space-between">
        <Text className="default-text" size={16}>
          Client requested to add one more line item
        </Text>
        {commonActionButton({
          popperElement: noteAnchorEl,
          onClickActionButton: (event) => {
            event.stopPropagation();
            setNoteAnchorEl(noteAnchorEl ? null : event.currentTarget);
          },
          handleClosePopper: () => setNoteAnchorEl(null),
          actionData: [
            {
              actionName: edit,
              onClickAction: () => {
                setNoteAnchorEl(null);
              },
            },
            {
              actionName: deleteText,
              onClickAction: () => {
                setNoteAnchorEl(null);
              },
            },
          ],
        })}
      </Box>,
      <NotesIcon />,
      <Text className="grey-text" variant="h4" size={14} font="regular">
        27/06/2022, 5:21 PM by Vincent Porter
      </Text>
    );
    setTimeLineGraphData(
      <Text className="default-text" size={16}>
        Invoice finalised
      </Text>,
      <ReceiptIcon />,
      <Text className="grey-text" variant="h4" size={14} font="regular">
        "27/06/2022, 5:21 PM
      </Text>
    );
    setTimeLineGraphData(
      <Text className="default-text" size={16}>
        Invoice payment page was created
      </Text>,
      <ReceiptIcon />
    );
    setTimeLineGraphData(
      <Text className="default-text" size={16}>
        Invoice was created from TRYSPT-0007
      </Text>,
      <RefreshIcon />
    );

    return (
      <Box className="invoice-timeline-wrapper">
        <Box className="invoice-timeline-header">
          <Text size={20} className="default-text">
            {timelineLabel}
          </Text>
          {!enabledAddNote && (
            <Box
              className="pointer-cursor add-metadata-container"
              onClick={() => {
                setEnabledAddNote(true);
              }}
            >
              <NotesIcon className="add-note-icon" />
              <Text
                className="default-text"
                size={14}
                variant="body1"
                component="span"
              >
                {addNotes}
              </Text>
            </Box>
          )}
        </Box>
        <CustomDivider />
        {enabledAddNote && (
          <Box className="add-note-input-section margin-top30">
            <TextAreaComponent
              fullWidth
              showLabel={false}
              minRows={5}
              name="text-area-name"
              onChange={(e) => {}}
              placeholder={invoiceNotePlaceholder}
            />
            <Box className="save-notes">
              <Button
                variant="outlined"
                label={cancel}
                onClick={() => setEnabledAddNote(false)}
              />
              <Button
                style={{ margin: "0 0 0 28px" }}
                variant="contained"
                label={"Add note"}
                onClick={() => setEnabledAddNote(false)}
              />
            </Box>
            <CustomDivider />
          </Box>
        )}
        <Box className="time-line-content">
          <Timeline
            className="invoice-timeline-graph"
            timelineData={graphData}
          />
        </Box>
      </Box>
    );
  };

  const detailContent = () => {
    return (
      <Box className="invoice-details-wrapper">
        <Box className="invoice-details-header">
          <Text size={20} className="default-text">
            {details}
          </Text>
          {invoiceDetails?.invoice_pdf && (
            <Box
              className="pointer-cursor add-metadata-container"
              onClick={() => {
                saveAs(
                  invoiceDetails.invoice_pdf,
                  `${invoiceDetails.invoice_number}.pdf`
                );
              }}
            >
              <>
                <SaveAltIcon className="download-invoice-pdf-icon" />
                <Text
                  className="default-text download-txt"
                  size={14}
                  variant="body1"
                  component="span"
                >
                  {invoicePdf}
                </Text>
              </>
            </Box>
          )}
        </Box>
        <CustomDivider />
        <Box className="detail-box">
          {invoiceDetails.status && (
            <KeyValueGrid
              keyName={status}
              value={
                <Tag
                  text={
                    invoiceStatus[invoiceDetails.status.toLowerCase()]?.label
                  }
                  variant={
                    invoiceStatus[invoiceDetails.status.toLowerCase()]?.variant
                  }
                />
              }
            />
          )}
          {invoiceDetails?.customer?.email && (
            <KeyValueGrid
              keyName={billToTitle}
              value={clipboardElement(
                invoiceDetails?.customer?.email,
                true,
                "",
                "text",
                true
              )}
            />
          )}
          {invoiceDetails?.id && (
            <KeyValueGrid keyName={idTitle} value={invoiceDetails.id} />
          )}
          <KeyValueGrid
            keyName={billFromTitle}
            value={currentAccount?.account?.account_public_info?.business_name}
          />
          {invoiceDetails?.created && (
            <KeyValueGrid
              keyName={dateCreated}
              value={dateTimeFormatInApp(invoiceDetails.created)}
            />
          )}
          {invoiceDetails?.currency && (
            <KeyValueGrid
              keyName={currencyLabel}
              value={invoiceDetails.currency}
            />
          )}
          {invoiceDetails?.status_transition?.finalized_at && (
            <KeyValueGrid
              keyName={dateFinalisedTitle}
              value={dateTimeFormatInApp(
                invoiceDetails.status_transition.finalized_at
              )}
            />
          )}
          {invoiceDetails?.footer && (
            <KeyValueGrid keyName={footerTitle} value={invoiceDetails.footer} />
          )}
          {invoiceDetails?.memo && (
            <KeyValueGrid keyName={memoTitle} value={invoiceDetails.memo} />
          )}
          {invoiceDetails?.hosted_invoice_url && (
            <KeyValueGrid
              keyName={paymentPageTitle}
              value={
                <CustomLink
                  href={invoiceDetails.hosted_invoice_url}
                  target="_blank"
                  bold={true}
                >
                  Payment page link
                </CustomLink>
              }
            />
          )}
        </Box>
      </Box>
    );
  };

  const handleSendInvoiceClick = () => {
    setIsDisabled(true);
    sendInvoiceAndEmail(urlParams?.id)
      .then((res) => {
        if (res) {
          dispatch(
            showToast({
              isToastOpen: true,
              toastMessage: emailSentToastMsg,
              toastVariant: "success",
            })
          );
          setIsDisabled(false);
          isFromDraftInvoice && loadDetailPage();
        }
      })
      .catch(() => {
        setIsDisabled(false);
      });
  };

  const handleClickFinalizeInvoice = () => {
    setShowFinalizeLoader(true);
    finalizeInvoice(urlParams?.id)
      .then((res) => {
        if (res) {
          loadDetailPage();
          setShowFinalizeLoader(false);
          setAnchorEl(null);
        }
      })
      .catch(() => {
        setShowFinalizeLoader(false);
        setAnchorEl(null);
      });
  };

  const renderMainSection = () => {
    const productDataContent = () => {
      const rightAlign = {
        style: {
          textAlign: "right",
        },
      };
      const productTableRows = invoiceDetails?.invoice_line_items?.map(
        (rowItem) => (
          <TableRow key={rowItem.id}>
            <TableCell>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {rowItem?.product_images?.[0] ? (
                  <img
                    src={rowItem?.product_images?.[0]}
                    width="40px"
                    height="40px"
                    style={{ marginRight: "15px" }}
                  />
                ) : (
                  <Box
                    height="40px"
                    width="40px"
                    className="content-center"
                    sx={{
                      color: "#b8bfc7",
                      marginRight: "15px",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <ImageIcon className="image-icon" />
                  </Box>
                )}
                <TruncatedTextTooltip
                  cellWidth="300px"
                  textValue={rowItem.name}
                />
              </Box>
            </TableCell>
            <TableCell {...rightAlign}>{rowItem.quantity}</TableCell>
            <TableCell {...rightAlign}>
              {getCurrencyObj(invoiceDetails.currency)?.symbol}
              {getAmountByCurrency(
                rowItem.unit_amount,
                invoiceDetails.currency
              )}
            </TableCell>
            <TableCell {...rightAlign}>
              {getCurrencyObj(invoiceDetails.currency)?.symbol}
              {getAmountByCurrency(
                rowItem.row_total_amount,
                invoiceDetails.currency
              )}
            </TableCell>
            <TableCell {...rightAlign}>
              {commonActionButton({
                popperElement: productAnchorEl,
                onClickActionButton: (event) => {
                  event.stopPropagation();
                  setProductAnchorEl(
                    productAnchorEl ? null : event.currentTarget
                  );
                },
                handleClosePopper: () => setProductAnchorEl(null),
                ...(rowItem?.type === "line_item" && {
                  actionData: [
                    {
                      actionName: viewPriceDetails,
                      onClickAction: () => {
                        rowItem?.price_id &&
                          history.push(`/prices/${rowItem?.price_id}`);
                        setProductAnchorEl(null);
                      },
                    },
                    {
                      actionName: viewProductDetails,
                      onClickAction: () => {
                        rowItem?.price_id &&
                          history.push(`/products/${rowItem?.product_id}`);
                        setProductAnchorEl(null);
                      },
                    },
                  ],
                }),
              })}
            </TableCell>
          </TableRow>
        )
      );

      const productTotalSection = () => {
        const singleTotalBox = (
          typeOfAmount,
          amount,
          currency,
          isDueAmount = false
        ) => {
          const textProps = {
            className: "default-text",
            size: 16,
            variant: "h4",
          };

          if (!isDueAmount) textProps.font = "regular";

          return (
            <Box display="flex" margin="20px 85px 0px 0px">
              <Grid item xs={7}>
                <Text {...textProps}>{typeOfAmount}</Text>
              </Grid>
              <Grid item xs={5} sx={{ display: "flex", justifyContent: "end" }}>
                <Text {...textProps}>
                  {getCurrencyObj(currency)?.symbol}
                  {getAmountByCurrency(amount, currency)}
                </Text>
              </Grid>
            </Box>
          );
        };

        return (
          <Box className="invoice-total-section margin-top30">
            {invoiceDetails?.invoice_amount &&
              singleTotalBox(
                "Total",
                invoiceDetails.invoice_amount,
                invoiceDetails.currency
              )}
          </Box>
        );
      };

      return (
        <Box className="invoice-product-data-content">
          <HorizontalTable
            label={productLabel}
            isColumnShown={productTableRows.length > 0}
            isShowButton={productTableRows.length > 3}
            columns={productTableColumns}
            rows={productTableRows}
            rowsPerPage={3}
            isShowPagination={false}
            tableBodyClassName="border-none"
            handleViewAllClick={() => {
              history.push(`/invoices/${urlParams?.id}/products`);
            }}
          />
          {productTotalSection()}
        </Box>
      );
    };

    const renderPaymentsSection = () => {
      const paymentRows = invoicePaymentList?.map((rowItem) => (
        <TableRow
          key={rowItem.id}
          className="clickable"
          onClick={() => history.push(`/payments/${rowItem?.id}`)}
        >
          <TableCell>
            {clipboardElement(
              rowItem?.id,
              true,
              "branding-detail",
              "text",
              true
            )}
          </TableCell>
          <TableCell align="center">
            {satsToBtcAmount(rowItem.target_amount_paid)}
          </TableCell>
          <TableCell align="left">
            {dateTimeFormatInApp(rowItem?.created)}
          </TableCell>
          <TableCell align="left">
            {renderStatus(rowItem?.status || "paid")}
          </TableCell>
        </TableRow>
      ));

      return !invoicePaymentSkeleton ? (
        <Box className="invoice-payments-data-content">
          <HorizontalTable
            label={paymentsLabel}
            isColumnShown={paymentRows.length > 0}
            isShowButton={paymentRows.length > 3}
            columns={invoicePaymentColumnData}
            rows={paymentRows}
            rowsPerPage={3}
            isShowPagination={false}
            tableBodyClassName="border-none"
            handleViewAllClick={() => {
              history.push(`/invoices/${urlParams?.id}/payments`);
            }}
          />
        </Box>
      ) : (
        <DetailPagePaymentSkeleton />
      );
    };

    const invoiceHistorySection = () => {
      const invoiceHistoryTabRows = invoiceDetails?.invoiceHistoryData?.map(
        (rowItem) => (
          <TableRow key={rowItem.id}>
            <TableCell>{rowItem.invoice_number}</TableCell>
            <TableCell>
              <Tag
                text={invoiceStatus[rowItem?.status?.toLowerCase()].label}
                variant={invoiceStatus[rowItem?.status.toLowerCase()].variant}
                className="text-capitalize"
              />
            </TableCell>
            <TableCell>{rowItem.invoice_amount}</TableCell>
            <TableCell>{dateTimeFormatInApp(rowItem.created)}</TableCell>
            <TableCell>{dateTimeFormatInApp(rowItem.due)}</TableCell>
            <TableCell className="invoice-action-table-cell">
              <Box className="invoice-row-actions">
                {commonActionButton({
                  popperElement: invoiceHistoryAnchorEl,
                  onClickActionButton: (event) => {
                    event.stopPropagation();
                    setInvoiceHistoryAnchorEl(
                      invoiceHistoryAnchorEl ? null : event.currentTarget
                    );
                  },
                  handleClosePopper: () => setInvoiceHistoryAnchorEl(null),
                  actionData: [
                    {
                      actionName: edit,
                      onClickAction: () => {
                        setInvoiceHistoryAnchorEl(null);
                      },
                    },
                  ],
                })}
              </Box>
            </TableCell>
          </TableRow>
        )
      );

      return (
        <Box className="invoice-history-data-content">
          <HorizontalTable
            label={historyLabel}
            isColumnShown={invoiceHistoryTabRows.length > 0}
            isShowButton={invoiceHistoryTabRows.length > 3}
            columns={invoiceHistoryColumns}
            rows={invoiceHistoryTabRows}
            rowsPerPage={3}
            isShowPagination={false}
            tableBodyClassName="border-none"
            handleViewAllClick={() => {}}
          />
        </Box>
      );
    };

    const renderMetaDataTable = () => (
      <Box className="invoice-meta-data-content">
        {isMetaDataEditable ? (
          <MetaDataForm
            metaDataList={metaDataList}
            setIsMetaDataEditable={setIsMetaDataEditable}
          />
        ) : (
          <HorizontalTable
            label={metaDataText}
            rows={moduleMetaDataRows(metaDataList)}
            rowsPerPage={51}
            isColumnShown={moduleMetaDataRows(metaDataList)?.length > 0}
            tableBodyClassName="border-none"
            isShowButton={false}
            displayShowButton={false}
            viewAllButtonLabel={metaDataList?.length > 0 ? edit : add}
            viewAllButtonIcon="editIcon"
            handleViewAllClick={() => setIsMetaDataEditable(true)}
          />
        )}
      </Box>
    );

    const renderMetaDataContent = () => {
      return !metaDataSkeleton ? (
        renderMetaDataTable()
      ) : (
        <DetailPagePaymentSkeleton />
      );
    };

    const renderEventDataTable = () => (
      <Box className="invoice-event-data-content">
        <HorizontalTable
          label={eventsText}
          isColumnShown={eventRows?.length > 0}
          rows={eventRows}
          rowsPerPage={3}
          handleViewAllClick={() => {
            history.push(`/invoices/${urlParams?.id}/events`);
          }}
          isShowButton={eventRows?.length > 3}
          tableBodyClassName="border-none"
        />
      </Box>
    );

    const renderEventContent = () => {
      return !eventSkeleton ? (
        renderEventDataTable()
      ) : (
        <DetailPagePaymentSkeleton />
      );
    };

    const handleClickInvoiceAction = (type) => {
      switch (type) {
        case "sendReminder": {
          break;
        }
        case "duplicate": {
          break;
        }
        case "changeStatus": {
          setChangeInvoiceStatusModal(true);
          break;
        }
        case "finalizeInvoice": {
          handleClickFinalizeInvoice();
          break;
        }
        default:
          setAnchorEl(null);
      }
    };

    const renderHeaderActions = () => (
      <Box className="action-btn-wrapper">
        {!isInvoicePaid && (
          <Button
            disabled={isDisabled || showFinalizeLoader}
            sx={{ marginLeft: "15px" }}
            icon="emailIcon"
            label={sendInvoice}
            variant="outlined"
            onClick={handleSendInvoiceClick}
          />
        )}
        <SendInvoiceModal
          openModal={openSendInvoiceModal}
          setOpenModal={(value) => setOpenSendInvoiceModal(value)}
        />
        {isFromDraftInvoice &&
          commonActionButton({
            popperElement: anchorEl,
            onClickActionButton: (event) => {
              event.stopPropagation();
              setAnchorEl(anchorEl ? null : event.currentTarget);
            },
            handleClosePopper: () => setAnchorEl(null),
            actionData: [
              {
                actionName: finalizeInvoiceMsg,
                onClickAction: () =>
                  handleClickInvoiceAction("finalizeInvoice"),
                isLoadingSectionRequired: true,
                isLoading: showFinalizeLoader,
                disabled: showFinalizeLoader || isDisabled,
              },
              // {
              //   actionName: "Send Reminder",
              //   onClickAction: () => handleClickInvoiceAction("sendReminder"),
              // },
              // {
              //   actionName: "Duplicate Invoice",
              //   onClickAction: () => handleClickInvoiceAction("duplicate"),
              // },
              // {
              //   actionName: "Change Invoice Status",
              //   onClickAction: () => handleClickInvoiceAction("changeStatus"),
              // },
            ],
          })}
        <ChangeInvoiceStatusModal
          openModal={openChangeInvoiceStatusModal}
          setOpenModal={(value) => setChangeInvoiceStatusModal(value)}
        />
      </Box>
    );

    return (
      <Grid className="invoice-detail-wrapper" container spacing={1}>
        <Box className="section-wrapper">
          <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
          {invoiceDetails ? (
            <>
              {renderHeaderActions()}
              {headerContent(invoiceDetails)}
              {showTimeLine && timelineContent()}
              {detailContent()}
              {productDataContent()}
            </>
          ) : (
            <LinkSkeleton props={props} />
          )}
          {renderPaymentsSection()}
          {showHisory && invoiceHistorySection()}
          {renderMetaDataContent()}
          {renderEventContent()}
        </Box>
      </Grid>
    );
  };

  const linkNotFoundSection = () => (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage("Invoice")}
        subTitle={`Invoice ${urlParams.id} is not found.`}
        btnText={`View ${liveMode ? "live" : "test"} mode Invoices`}
      />
    </Box>
  );

  return !isLinkNotFound ? renderMainSection() : linkNotFoundSection();
};

export default InvoiceDetails;
