import React from "react";
import GetGuides from "./GetGuides";

const OneQRGuide = () => {
  return (
    <GetGuides
      moduleName="one_qr"
      redirectUrl="/one-qr"
      guideApiPath="one-qr-guide"
      getStartedApiPath="One"
      needHelpGuidePath="docs/receive-payments/one-qrs"
    />
  );
};

export default OneQRGuide;
