import React, { useEffect, useState } from "react";
import { find } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { Box, MenuItem } from "@mui/material";
import { useFlags } from "launchdarkly-react-client-sdk";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import Button from "@speed/common/src/components/Button/Button";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Label from "@speed/common/src/components/Label/Label";
import InputSelectionBox from "@speed/common/src/components/InputSelectionBox/InputSelectionBox";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import {
  copyAndShareInviteLink,
  inviteMerchant,
  inviteText,
  invited,
  memberEmail,
  merchants,
  or,
  partnerConnectForbiddenMessage,
} from "../messages";
import Merchants from "./Merchants";
import Invited from "./Invited";
import CommonHeader from "../Common/CommonHeader";
import {
  callAPIInterface,
  callStrapiAPIInterface,
  currencySupportedByPartners,
  keyEventListenerForMultiEmail,
} from "../constants";
import MultiEmailWithAlert from "../MultiEmailWithAlert";
import {
  setCaughtErrorInStrapi,
  setIsDataAvailable,
  setIsModalOpen,
} from "../../redux/common/actions";
import GetStartedHeader from "@speed/common/src/components/GetStartedHeader/GetStartedHeader";
import Text from "@speed/common/src/components/Text/Text";
import WarningIcon from "@mui/icons-material/Warning";
import CustomSelect from "@speed/common/src/components/Select/Select";

const AffiliatePartners = () => {
  const [currency, setCurrency] = useState("SATS");
  const { aflInviteFe, aflGetstaticinvitelinkFe } = useFlags();
  const [selectedTab, setSelectedTab] = useState(false);
  const [inviteLink, setInviteLink] = useState("");
  const [emails, setEmails] = useState([]);
  const [invalidEmailData, setInValidEmailData] = useState("");
  const [invalidEmailFlag, setInValidEmailFlag] = useState(false);
  const [limitEmail, setLimitEmail] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [shouldUpdateData, setShouldUpdateData] = useState(false);
  const [affiliatePartnersGuide, setAffiliatePartnersGuide] = useState(null);
  const [isGetHeaderInfoLoading, setGetHeaderInfoLoading] = useState(true);
  const { history, isDataAvailable, caughtErrorInStrapi, isModalOpen } =
    useSelector((state) => state.common);
  const { liveMode, currentAccount } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const allowedRoles = new Set(["role_owner", "role_admin"]);
  const shouldBeAbleToInvite = allowedRoles.has(currentAccount?.role?.id);

  useEffect(() => {
    dispatch(setIsDataAvailable(true));
    getAffiliatePartnersGuide();
    return () => {
      dispatch(setIsModalOpen(false));
      dispatch(setCaughtErrorInStrapi(false));
    };
  }, [liveMode]);

  const getAffiliatePartnersGuide = () => {
    setGetHeaderInfoLoading(true);
    callStrapiAPIInterface(
      "GET",
      "/api/affiliate-partners-guide?populate[header][populate][use_cases][populate]=*"
    )
      .then((response) => {
        setAffiliatePartnersGuide(response?.data?.attributes?.header);
        setGetHeaderInfoLoading(false);
      })
      .catch(() => {
        dispatch(setCaughtErrorInStrapi(true));
        setGetHeaderInfoLoading(false);
      });
  };

  const getInviteLink = () =>
    shouldBeAbleToInvite &&
    callAPIInterface("GET", "/affiliate-partner/invite/link", {})
      .then((res) => {
        res && setInviteLink(res?.link);
      })
      .catch(() => {});

  const tabData = [
    {
      label: merchants,
      value: "1",
      tabContent: <Merchants targetCurrency={currency} />,
      route: "/partners/affiliate-partners",
    },
    {
      label: invited,
      value: "2",
      tabContent: <Invited shouldUpdateData={shouldUpdateData} />,
      route: "/partners/affiliate-partners-invited",
    },
  ];

  useEffect(
    () => aflGetstaticinvitelinkFe && getInviteLink(),
    [aflGetstaticinvitelinkFe]
  );

  useEffect(() => {
    if (history?.location?.pathname) {
      let currentRoute = find(tabData, { route: history.location.pathname });
      if (currentRoute) {
        setSelectedTab(currentRoute.value);
      }
    }
  }, [history?.location?.pathname]);

  const onKeyUpListener = (e) =>
    keyEventListenerForMultiEmail(
      e,
      setInValidEmailData,
      setInValidEmailFlag,
      setDisabled,
      emails
    );

  useEffect(() => {
    const multiInput = document
      .getElementsByClassName("react-multi-email")?.[0]
      ?.getElementsByTagName("input")[0];
    multiInput?.addEventListener("keyup", onKeyUpListener);
    return () => {
      multiInput?.removeEventListener("keyup", onKeyUpListener);
    };
  }, [emails?.length]);

  const closeModal = () => {
    setEmails([]);
    setInValidEmailData("");
    setInValidEmailFlag(false);
    setLimitEmail(false);
    setDisabled(true);
    dispatch(setIsModalOpen(false));
  };

  const handleSubmit = () => {
    setDisabled(true);
    const emailsData = {
      emails,
    };
    callAPIInterface(
      "POST",
      "/affiliate-partner/invite",
      JSON.stringify(emailsData)
    )
      .then(() => {
        closeModal();
        if (
          history.location.pathname === "/partners/affiliate-partners-invited"
        ) {
          setShouldUpdateData(true);
        } else {
          history.push("/partners/affiliate-partners-invited");
        }
      })
      .catch(() => {
        closeModal();
      });
  };

  const handleChangeCurrency = (e) => {
    setCurrency(e.target.value);
  };

  const inviteModalContent = (
    <Box>
      {shouldBeAbleToInvite ? (
        <>
          <Label>{memberEmail}</Label>
          <MultiEmailWithAlert
            emails={emails}
            setEmails={setEmails}
            invalidEmailFlag={invalidEmailFlag}
            setInValidEmailFlag={setInValidEmailFlag}
            invalidEmailData={invalidEmailData}
            setInValidEmailData={setInValidEmailData}
            limitEmail={limitEmail}
            setLimitEmail={setLimitEmail}
            setDisabled={setDisabled}
          />
          {aflGetstaticinvitelinkFe && shouldBeAbleToInvite && (
            <>
              <CustomDivider text={or} sx={{ my: "20px" }} />
              <Label sx={{ fontWeight: 500, color: "#000 !important" }}>
                {copyAndShareInviteLink}
              </Label>
              <Box display="flex" alignItems="center" width="100%">
                <InputSelectionBox
                  text={inviteLink}
                  className="affiliate-partner-invitation-link-copy"
                />
                <Box
                  ml="15px"
                  pt="4px"
                  onClick={(event) => event.stopPropagation()}
                >
                  <Clipboard text={inviteLink} />
                </Box>
              </Box>
            </>
          )}
        </>
      ) : (
        <>
          <WarningIcon
            sx={{
              color: "#E74C3C",
              height: 60,
              width: 60,
              display: "flex",
              mx: "auto",
              mb: 3,
            }}
          />
          <Text size={14} font="medium" align="center" className="grey-text">
            {partnerConnectForbiddenMessage("invite")}
          </Text>
        </>
      )}
    </Box>
  );

  const inviteModalFooter = (
    <Button
      style={{ width: "100px" }}
      label={inviteText}
      onClick={handleSubmit}
      disabled={disabled}
    />
  );

  const strapiHeaderCondition =
    !isDataAvailable && !caughtErrorInStrapi && !isModalOpen;

  return (
    <Box
      bgcolor={strapiHeaderCondition ? "#f7fafc" : ""}
      className="section-wrapper payment-links-wrapper"
    >
      {strapiHeaderCondition ? (
        <GetStartedHeader
          component={
            selectedTab === "2" ? tabData[1].tabContent : tabData[0].tabContent
          }
          getHeaderInfo={affiliatePartnersGuide}
          loading={isGetHeaderInfoLoading}
          setIsModalOpen={setIsModalOpen}
        />
      ) : (
        <>
          <CommonHeader
            childrenWithHeader={
              <CustomSelect
                showLabel={false}
                customClass="transaction-custom-currency-dropdown"
                value={currency}
                MenuProps={{
                  id: "payout-wallet-address",
                  disableScrollLock: true,
                }}
                onChange={handleChangeCurrency}
                renderValue={() => currency}
              >
                {currencySupportedByPartners?.map((dropDownValue) => (
                  <MenuItem value={dropDownValue} key={dropDownValue}>
                    <Box>{dropDownValue}</Box>
                  </MenuItem>
                ))}
              </CustomSelect>
            }
          />
          {aflInviteFe && shouldBeAbleToInvite && (
            <Box className="action-btn-wrapper mui-fixed">
              <Button
                icon="addIcon"
                className="add-icon"
                label={inviteMerchant}
                variant="outlined"
                color="primary"
                onClick={() => dispatch(setIsModalOpen(true))}
              />
            </Box>
          )}
          <CustomTab
            sx={{
              mt: "20px",
              minHeight: "unset",
              "& .MuiTabPanel-root": {
                minHeight: "unset",
                p: 0,
              },
            }}
            onChange={(_event, newValue) => setSelectedTab(newValue)}
            tabData={tabData}
            selectedTab={selectedTab}
            setTabRoute={(routeUrl) => history.push(routeUrl)}
          />
        </>
      )}
      <Modal
        maxWidth={shouldBeAbleToInvite ? "sm" : "xs"}
        body={inviteModalContent}
        footer={shouldBeAbleToInvite && inviteModalFooter}
        handleClose={closeModal}
        open={isModalOpen}
        title={inviteMerchant}
      />
    </Box>
  );
};

export default AffiliatePartners;
