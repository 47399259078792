import React from "react";
import { Input } from "@speed/common/src/components/Input/Input";
import { Box } from "@mui/system";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";

function EmailDomainModalContent({
  errorMsg,
  values,
  handleTouched,
  handleSubmit,
  handleSetFieldValue,
  label,
  touched,
  errors,
  handleEnterKey,
  disabled,
  placeholder,
}) {
  return (
    <Box className="add-domain-modal">
      <Input
        type="text"
        showLabel
        label={label}
        value={values.email_domain}
        name="email_domain"
        fullWidth
        autoComplete="off"
        placeholder={placeholder}
        autoFocus={true}
        onBlur={() => handleTouched(true)}
        onChange={(e) => {
          handleSubmit(false);
          handleTouched(false);
          handleSetFieldValue(e.target.value);
        }}
        onKeyDown={(e) => handleEnterKey(e.keyCode)}
        disabled={disabled}
      />
      {touched.email_domain && Boolean(errors.email_domain) && (
        <AlertMessage
          className="margin-top14"
          severity="error"
          message={errorMsg}
        />
      )}
    </Box>
  );
}

export default EmailDomainModalContent;
