import { Box, Grid } from "@mui/material";
import classNames from "classnames";
import Text from "@speed/common/src/components/Text/Text";

const PreviewHeader = ({ from, hidePreviewUrl, dummyDomain }) => {
  const classes = classNames(
    "preview-header",
    from === "mobile" && "mobile-preview-header",
    hidePreviewUrl && "video-preview"
  );

  return (
    <Box className={classes} paddingRight={from !== "web" && "72px !important"}>
      <Grid container>
        <Grid item lg={from === "web" ? 1 : 2.5} display="flex">
          <Box className="dot" sx={{ backgroundColor: "#fd7373" }}></Box>
          <Box className="dot" sx={{ backgroundColor: "#ffdb50" }}></Box>
          <Box className="dot" sx={{ backgroundColor: "#58d0c1" }}></Box>
        </Grid>
        {hidePreviewUrl ? null : (
          <Grid item lg={from === "web" ? 11 : 9.5}>
            <Box className="domain-input">
              <Text
                size={10}
                font="regular"
                className="default-text"
                variant="subtitle1"
                align="center"
              >
                {dummyDomain}
              </Text>
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default PreviewHeader;
