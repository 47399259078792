import { useState } from "react";
import { Box, Divider } from "@mui/material";
import Button from "@speed/common/src/components/Button/Button";
import {
  receipt,
  receiptFrom,
  summary,
  amountPaid,
  datePaid,
  testEmailReceipt,
  chargedAmount,
  paymentDescription,
} from "../../messages";
import { leftOrnaments, rightOrnaments } from "../../images";
import { useSelector } from "react-redux";
import CreateReceipt from "../../Common/CreateReceipt";
import {
  isLightColor,
  validateColor,
} from "@speed/common/src/components/constants";

const EmailReceipt = () => {
  const brandingPageUI = useSelector((state) => state.common.brandingPageUI);
  const currentAccount = useSelector((state) => state.auth.currentAccount);
  const primaryThemeColor = "#2250A1";
  const [receiptFlag, setReceiptFlag] = useState(false);

  return (
    <>
      <Box className="email-receipt">
        <Box
          sx={{
            backgroundColor: brandingPageUI
              ? brandingPageUI.primary_color
              : primaryThemeColor,
          }}
          className="email-receipt-header"
        >
          <Box sx={{ display: "inline-flex" }}>
            <Box sx={{ paddingTop: "61px" }}>
              <img src={leftOrnaments} alt="left-ornaments" />
            </Box>
            <Box
              className="email-receipt-header-info"
              sx={{
                color: isLightColor(
                  validateColor(brandingPageUI?.primary_color)
                    ? brandingPageUI?.primary_color
                    : primaryThemeColor
                )
                  ? "#0a193e"
                  : "#fff",
              }}
            >
              <Box>
                <Box>{receiptFrom}</Box>
                <Box sx={{ fontSize: "24px", lineHeight: "1.67" }}>
                  {currentAccount?.account?.account_public_info?.business_name}
                </Box>
              </Box>
              <Box className="receipt">{receipt} #0000-0000</Box>
            </Box>
            <Box sx={{ paddingLeft: "50px" }}>
              <img src={rightOrnaments} alt="right-ornaments" />
            </Box>
            <Box sx={{ paddingTop: "15px" }}>
              <Button
                label={testEmailReceipt}
                variant="default"
                icon="sendIcon"
                className="email-receipt-header-button"
                onClick={() => setReceiptFlag(true)}
              />
            </Box>
          </Box>
        </Box>
        <Box className="email-receipt-body">
          <Box className="email-receipt-body-content">
            <Box sx={{ paddingLeft: "33px" }}>
              {amountPaid}
              <Box style={{ color: "#0a193e" }}>₹19.99</Box>
            </Box>
            <Box sx={{ paddingLeft: "100px" }}>
              {datePaid}
              <Box style={{ color: "#0a193e" }}>November 19, 2021</Box>
            </Box>
          </Box>
          <Box className="email-receipt-body-summary">{summary}</Box>
          <Box className="email-receipt-body-summary-box">
            <Box className="summary-row">
              <Box>{paymentDescription}</Box>
              <Box sx={{ paddingLeft: "185px" }}>₹19.99</Box>
            </Box>
            <Divider />
            <Box className="summary-row" sx={{ fontWeight: "bold" }}>
              <Box>{chargedAmount}</Box>
              <Box sx={{ paddingLeft: "200px" }}>₹19.99</Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {receiptFlag && (
        <CreateReceipt
          handleClose={() => setReceiptFlag(false)}
          page="email-receipt"
        />
      )}
    </>
  );
};

export default EmailReceipt;
