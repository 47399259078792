import React, { Fragment } from "react";
import { Box, Grid, MenuItem } from "@mui/material";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import AddIcon from "@mui/icons-material/Add";
import { FieldArray, FormikProvider } from "formik";
import { Input } from "@speed/common/src/components/Input/Input";
import CustomSelect from "@speed/common/src/components/Select/Select";
import Text from "@speed/common/src/components/Text/Text";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import { addReminder } from "../messages";
import { symbolsToExcludeForNumberInput } from "../constants";

const DefaultReminder = ({
  values,
  formik,
  handleChange,
  errors,
  setReminderDataForDeletionProcedure,
  setFieldValue,
}) => {
  const daysArr = [
    { label: "day after due date", value: "after" },
    { label: "day before due date", value: "before" },
    { label: "on due date", value: "on" },
  ];

  const sendMethods = [
    { label: "Email", value: "email" },
    { label: "SMS", value: "sms" },
    { label: "Email & SMS", value: "sms_email" },
  ];

  const handleReminders = (index, value, dueType) => {
    const reminders = JSON.parse(JSON.stringify(values.reminders));
    value = dueType === "on" ? 0 : value;

    reminders[index].key = `${Number(value)}_${dueType}`;
    reminders[index].interval_value = value;
    reminders[index].due_type = dueType;

    return reminders;
  };

  return (
    <FormikProvider value={formik}>
      <FieldArray name="reminders">
        {(arrayHelpers) => (
          <>
            {values.reminders.map((reminder, index) => {
              return (
                <Fragment key={index}>
                  <Grid container>
                    <Grid item lg={2}>
                      <Input
                        className="reminder-day reminder-fields"
                        showLabel={false}
                        value={reminder.interval_value}
                        disabled={reminder.due_type === "on"}
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        onKeyDown={(e) => {
                          if (
                            symbolsToExcludeForNumberInput.includes(e.key) ||
                            e.which === 38 ||
                            e.which === 40
                          ) {
                            e.preventDefault();
                          }
                        }}
                        name={`reminders[${index}].interval_value`}
                        onChange={(val) => {
                          const re = handleReminders(
                            index,
                            val.target.value,
                            reminder?.due_type
                          );
                          setFieldValue("reminders", re);
                        }}
                        error={
                          errors?.reminders &&
                          Boolean(errors.reminders[index]?.interval_value)
                        }
                      />
                      {errors?.reminders &&
                        Boolean(errors.reminders[index]?.interval_value) && (
                          <AlertMessage
                            sx={{ mb: "10px" }}
                            message={errors.reminders[index]?.interval_value}
                            severity="error"
                          />
                        )}
                    </Grid>

                    <Grid item lg={2} marginLeft="20px">
                      <CustomSelect
                        className="reminder-drop-down reminder-fields"
                        onChange={(val) => {
                          const re = handleReminders(
                            index,
                            reminder?.interval_value,
                            val?.target?.value
                          );
                          setFieldValue("reminders", re);
                        }}
                        MenuProps={{ id: "reminder-drop-down" }}
                        value={reminder.due_type}
                        name={`reminders[${index}].due_type`}
                      >
                        {daysArr.map((item) => {
                          return (
                            <MenuItem value={item.value} key={item.value}>
                              <Box>{item.label}</Box>
                            </MenuItem>
                          );
                        })}
                      </CustomSelect>
                    </Grid>
                    <Grid item lg={2} marginLeft="20px">
                      <CustomSelect
                        className="reminder-drop-down reminder-fields"
                        onChange={(val) => {
                          handleChange(val);
                        }}
                        MenuProps={{ id: "reminder-send-down" }}
                        value={reminder.channel}
                        name={`reminders[${index}].channel`}
                      >
                        {sendMethods.map((item) => {
                          return (
                            <MenuItem value={item.value} key={item.value}>
                              <Box>{item.label}</Box>
                            </MenuItem>
                          );
                        })}
                      </CustomSelect>
                    </Grid>
                    <Grid
                      item
                      lg={4}
                      className="remove-icon ml-15"
                      display="flex"
                      justifyContent="flex-start"
                      alignItems="center"
                      height="fit-content"
                      width="fit-content"
                      mt={1}
                    >
                      <RemoveCircleOutlinedIcon
                        className="pointer-cursor"
                        sx={{ height: 28, width: 28 }}
                        onClick={() => {
                          if (reminder?.id) {
                            setReminderDataForDeletionProcedure((prev) => [
                              ...prev,
                              {
                                id: reminder.id,
                                is_removed: true,
                              },
                            ]);
                          }
                          arrayHelpers.remove(index);
                        }}
                      />
                    </Grid>
                  </Grid>
                  {errors?.reminders &&
                    Boolean(errors.reminders[index]?.key) && (
                      <AlertMessage
                        sx={{ mb: "10px", width: "fit-content" }}
                        message={errors.reminders[index]?.key}
                        severity="error"
                      />
                    )}
                </Fragment>
              );
            })}
            {values.reminders.length < 10 && (
              <Box
                onClick={() => {
                  arrayHelpers.push({
                    interval_value: 0,
                    due_type: "on",
                    channel: "email",
                    interval_type: "day",
                    key: "0_on",
                  });
                }}
                className="add-reminder"
                width={125}
              >
                <AddIcon
                  sx={{
                    width: "16px",
                    height: "16px",
                    color: "inherit",
                  }}
                />
                <Text
                  className="default-text"
                  size={16}
                  variant="body1"
                  sx={{
                    color: "inherit !important",
                  }}
                  component="span"
                >
                  {addReminder}
                </Text>
              </Box>
            )}
          </>
        )}
      </FieldArray>
    </FormikProvider>
  );
};

export default DefaultReminder;
