import React from "react";
import { Box } from "@mui/material";
import LinkConfirmation from "./LinkConfirmation";

const LinkModalContent = ({
  formComponent,
  previewComponent,
  linkConfirmationProps,
}) => {
  return (
    <Box className="modal-container-grid" sx={{ display: "flex" }}>
      <Box className="inner-grid" sx={{ width: "30%" }}>
        {/* Left side form of respected link*/}
        {formComponent}

        {/* For confirmation view when click on next button while creating the link */}
        <LinkConfirmation {...linkConfirmationProps} />
      </Box>
      <Box
        paddingLeft="39px"
        className="inner-grid"
        sx={{ width: "55%", backgroundColor: "#f7fafc" }}
      >
        {/* right side preview of respected link */}
        {previewComponent}
      </Box>
    </Box>
  );
};

export default LinkModalContent;
