import React, { useEffect, useState } from "react";
import SetupCommonHeader from "./SetupCommonHeader";
import {
  cancel,
  onchain,
  payoutSchSubText,
  payoutScheduling,
} from "../../../messages";
import { useFormik } from "formik";
import {
  PSOnchainValidationSchema,
  PSValidationSchema,
  getScheduledPayout,
  payoutLoaderComponent,
  setPayoutSchDefaultVal,
  setupAutoPayoutSchedulingFunc,
} from "../../../constants";
import { useSelector } from "react-redux";
import AutoPayoutSetupCommon from "../../PayoutScheduling/AutoPayoutSetupCommon";
import Button from "@speed/common/src/components/Button/Button";
import { save } from "@speed/common/src/messages";
import { Box } from "@mui/material";
import AlreadySetupPayoutComponent from "../../PayoutScheduling/AlreadySetupPayoutComponent";

let timeout;
const SetupAutoPaySchedule = ({ handleNext, handleBack }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isDeactivating, setIsDeactivating] = useState(false);
  const [payoutScheduleId, setPayoutScheduleId] = useState(null);
  const [isPayoutCreating, setIsPayoutCreating] = useState(false);
  const [scheduledPayoutData, setScheduledPayoutData] = useState(null);
  const [selectedPayout, setSelectedPayout] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const liveMode = useSelector((state) => state.auth.liveMode);

  useEffect(() => {
    setScheduledPayoutData(null);
    getScheduledPayout({ setIsLoading, setScheduledPayoutData });
  }, [liveMode]);

  const handleButtonCallback = () => {
    setButtonLoader(false);
    isEditing && setIsEditing(false);
  };

  useEffect(() => {
    if (scheduledPayoutData && !isEditing) {
      timeout = setTimeout(() => {
        handleNext();
      }, 5000);
    } else clearTimeout(timeout);
  }, [scheduledPayoutData, isEditing]);

  const handleSetupAutoPayout = (values) => {
    setButtonLoader(true);
    setupAutoPayoutSchedulingFunc({
      values,
      isDeactivating,
      isEditing,
      setIsPayoutCreating,
      setScheduledPayoutData,
      payoutScheduleId,
      scheduledPayoutData,
      parentCallback: handleButtonCallback,
    });
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      auto_payout_enabled: "",
      payout_type: "",
      fees_type: "high",
      wallet_address: "",
      min_payout_amount: "50000",
      minimum_balance: "0",
      currency_type_payout: "BTC",
      currency_type_balance: "BTC",
    },
    validationSchema:
      selectedPayout === onchain
        ? PSOnchainValidationSchema
        : PSValidationSchema,

    onSubmit: handleSetupAutoPayout,
  });

  const { values, setFieldValue, validateForm, handleSubmit, isValid, dirty } =
    formik;

  useEffect(() => {
    setPayoutSchDefaultVal({
      setFieldValue,
      isEditing,
      setSelectedPayout,
      scheduledPayoutData,
    });
  }, [scheduledPayoutData, isEditing]);

  useEffect(() => {
    validateForm();
  }, [values.payout_type]);

  useEffect(() => {
    isDeactivating && handleSetupAutoPayout(values);
  }, [values.auto_payout_enabled]);

  const propsToPass = {
    scheduledPayoutData,
    isPayoutCreating,
    setIsDeactivating,
    setPayoutScheduleId,
    setSelectedPayout,
    formik,
    setIsEditing,
  };

  const shouldFormVisible = (isEditing || !scheduledPayoutData) && !isLoading;

  const payoutScheduledCard = isLoading ? (
    payoutLoaderComponent()
  ) : (
    <AlreadySetupPayoutComponent {...propsToPass} />
  );

  return (
    <>
      <SetupCommonHeader
        header={payoutScheduling}
        subHeader={payoutSchSubText}
      />

      <Box
        width="450px"
        mt="50px"
        className="auto-payout-wrapper auto-pay-schedule"
      >
        {shouldFormVisible ? (
          <>
            <AutoPayoutSetupCommon
              setSelectedPayout={setSelectedPayout}
              setButtonDisabled={setButtonDisabled}
              formik={formik}
            />
            <Button
              label={save}
              loading={buttonLoader}
              onClick={handleSubmit}
              type="submit"
              disabled={!(isValid && dirty) || buttonDisabled}
            />
            <Button
              label={cancel}
              disabled={buttonLoader}
              variant="outlined"
              sx={{ ml: "16px" }}
              onClick={() => (isEditing ? setIsEditing(false) : handleBack())}
            />
          </>
        ) : (
          payoutScheduledCard
        )}
      </Box>
    </>
  );
};

export default SetupAutoPaySchedule;
