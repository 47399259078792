import { useEffect, useState } from "react";
import { Input } from "@speed/common/src/components/Input/Input";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  addressLine1Placeholder,
  addressLine2Placeholder,
  businessWebsiteLabel,
  cityPlaceholder,
  enterURLPlaceholder,
  enterWebsiteURLPlaceholder,
  invalidLabel,
  postalCodePlaceholder,
  postalCodeValidate,
  privacyPolicyLabel,
  publicBusinessNameInfoMsg,
  publicBusinessNameLabel,
  publicInfoMsg,
  showPhoneNumberMsg,
  statePlaceholder,
  supportAddressLabel,
  supportPhoneNumberLabel,
  supportWebsiteLabel,
  termsOfServiceLabel,
  urlValidate,
  urlWithHttpValidate,
} from "../../messages";
import { Countries } from "@speed/common/src/components/country";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import { Box } from "@mui/system";
import {
  setSettingFormCancel,
  setSettingFormChange,
  setSettingFormValid,
  updatePublicInfo,
  setLoading,
} from "../../../redux/common/actions";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import { Grid } from "@mui/material";
import {
  validatePhoneNumber,
  validatePostalCode,
  validateURL,
  isAlphabet,
  concatedMobileNumber,
  mobileNumberWithoutCallingCode,
} from "../../constants";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import PhoneNumberInput from "@speed/common/src/components/PhoneNumberInput/PhoneNumberInput";
import { CustomSwitch } from "@speed/common/src/components/Switch/Switch";
import Text from "@speed/common/src/components/Text/Text";
import { sessionService } from "redux-react-session";
import { getCountryObj } from "@speed/common/src/components/constants";
import { setInitialState } from "../../../redux/auth/actions";
import {
  invalidEmail,
  emailLabel,
  emailPlaceholder,
} from "@speed/common/src/components/messages";
import { countryPlaceholder } from "@speed/common/src/messages";

function PublicInfo({ publicData }) {
  const [countryISOForZIP, setCountryISOForZIP] = useState();
  const [countryISOForMobileNumber, setCountryISOForMobileNumber] = useState();
  const { settingFormSubmit, settingFormCancel } = useSelector(
    (state) => state.common
  );
  const currentAccount = useSelector((state) => state.auth.currentAccount);
  const [formData, setFormData] = useState({});
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    business_name: yup.string().required(),
    email: yup.string().required("").email(invalidEmail),
    country: yup.string().required(),
    email_customers_show_phone: yup.boolean(),
    phone_number: yup.mixed().when("email_customers_show_phone", {
      is: (showPhoneReceipt) => showPhoneReceipt,
      then: yup.mixed().required().test("phone_number", validatePhoneNumber),
      otherwise: yup.mixed().test("phone_number", validatePhoneNumber),
    }),
    city: yup.string().matches(isAlphabet).trim(),
    state: yup.string().matches(isAlphabet).trim(),
    postal_code: yup.mixed().test({
      name: "postal_code",
      test: (postal_code) => validatePostalCode(postal_code, countryISOForZIP),
    }),
    business_url: yup.mixed().test({
      name: "business_url",
      test: (business_url) => validateURL(business_url, false),
    }),
    support_url: yup.mixed().test({
      name: "support_url",
      test: (support_url) => validateURL(support_url, true),
    }),
    privacy_policy_url: yup.mixed().test({
      name: "privacy_policy_url",
      test: (privacy_policy_url) => validateURL(privacy_policy_url, true),
    }),
    terms_of_service_url: yup.mixed().test({
      name: "terms_of_service_url",
      test: (terms_of_service_url) => validateURL(terms_of_service_url, true),
    }),
  });

  const formik = useFormik({
    initialValues: {
      business_name: publicData.business_name ? publicData.business_name : "",
      email: publicData.email ? publicData.email : "",
      phone_number: publicData.phone_number
        ? concatedMobileNumber(publicData.calling_code, publicData.phone_number)
        : "",
      calling_code: publicData.calling_code ? publicData.calling_code : "91",
      email_customers_show_phone: publicData.email_customers_show_phone
        ? publicData.email_customers_show_phone
        : false,
      country: publicData.country ? publicData.country : null,
      line1: publicData.line1 ? publicData.line1 : "",
      line2: publicData.line2 ? publicData.line2 : "",
      city: publicData.city ? publicData.city : "",
      state: publicData.state ? publicData.state : "",
      postal_code: publicData.postal_code ? publicData.postal_code : "",
      business_url: publicData.business_url ? publicData.business_url : "",
      support_url: publicData.support_url ? publicData.support_url : "",
      privacy_policy_url: publicData.privacy_policy_url
        ? publicData.privacy_policy_url
        : "",
      terms_of_service_url: publicData.terms_of_service_url
        ? publicData.terms_of_service_url
        : "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setTouched,
    resetForm,
    isValid,
    dirty,
    isSubmitting,
  } = formik;

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const onChangeFormField = (fieldname, value) => {
    setFieldValue(fieldname, value);
    formData[fieldname] = value;
    setFormData(formData);
  };

  useEffect(() => {
    if (values.country) {
      const countryObj = getCountryObj("short_name", values.country);
      if (countryObj) setCountryISOForZIP(countryObj.iso2);
    }
  }, [values.country]);

  useEffect(() => {
    // Set country code according to calling_code
    let countryObj = getCountryObj("calling_code", values.calling_code);
    if (countryObj) setCountryISOForMobileNumber(countryObj.iso2);
  }, [values.calling_code]);

  useEffect(() => {
    dispatch(setSettingFormValid(!(isValid && dirty) || isSubmitting));
  }, [isValid, dirty, isSubmitting]);

  useEffect(() => {
    dispatch(setSettingFormChange(dirty));
  }, [values]);

  const updatePublicDetails = async () => {
    let submitFormData = formData;
    if (formData.phone_number) {
      submitFormData = {
        ...formData,
        calling_code: values?.calling_code,
        phone_number: mobileNumberWithoutCallingCode(
          values?.calling_code,
          values.phone_number
        ),
      };
    }
    await dispatch(updatePublicInfo(submitFormData)).then(
      async (updatedPublicInfoData) => {
        if (updatedPublicInfoData) {
          const updatedcurrentAccountData = {
            ...currentAccount,
            account: {
              ...currentAccount?.account,
              account_public_info: updatedPublicInfoData,
            },
          };
          sessionService.loadSession().then((updatedSession) => {
            dispatch(
              setInitialState({
                ...updatedSession,
                current_acc: updatedcurrentAccountData,
              })
            );
          });
        }
        setFormData({});
        dispatch(setLoading(false));
      }
    );
  };

  useEffect(() => {
    // get values and call action to update this change into DB
    if (
      Object.keys(formData).length !== 0 &&
      isValid &&
      dirty &&
      settingFormSubmit
    ) {
      updatePublicDetails();
    }
  }, [settingFormSubmit]);

  useEffect(() => {
    if (settingFormCancel) {
      setFormData({});
      resetForm();
      dispatch(setSettingFormCancel(false));
    }
  }, [settingFormCancel]);

  return (
    <Grid sx={{ padding: "20px 30px 100px 24px" }} container spacing={1}>
      <Grid container>
        <Text
          className="grey-text"
          font="regular"
          size={16}
          variant="subtitle1"
        >
          {publicInfoMsg}
        </Text>
      </Grid>
      <Grid style={{ width: "400px" }}>
        <Input
          type="text"
          name="business_name"
          value={values.business_name}
          inputProps={{ maxLength: 240 }}
          customClass="margin-top30"
          showLabel
          label={publicBusinessNameLabel}
          fullWidth
          placeholder={publicBusinessNameLabel}
          isIcon={true}
          iconContent={
            <Text
              className="default-text"
              variant="subtitle1"
              font="regular"
              size={14}
              sx={{ width: "244px" }}
            >
              {publicBusinessNameInfoMsg}
            </Text>
          }
          icon={
            <InfoIcon
              style={{
                height: 14,
                width: 14,
                color: "#848b9e",
                marginLeft: "7px",
              }}
            />
          }
          onBlur={() => setTouched({ ...touched, business_name: true })}
          onChange={(e) => {
            onChangeFormField("business_name", e.target.value);
          }}
        />

        <Input
          customClass="margin-top30"
          showLabel
          label={emailLabel}
          inputProps={{ maxLength: 250 }}
          type="email"
          fullWidth
          name="email"
          value={values.email}
          error={touched.email && Boolean(errors.email)}
          onBlur={() => setTouched({ ...touched, email: true })}
          onChange={(e) => {
            setTouched({ ...touched, email: false });
            onChangeFormField("email", e.target.value);
          }}
          placeholder={emailPlaceholder}
        />
        <InputErrorMessage {...defaultErrorMessageProps} inputName="email" />

        <Box className="margin-top25">
          <PhoneNumberInput
            name="phone_number"
            options={Countries}
            error={touched.phone_number && Boolean(errors.phone_number)}
            selectValue={values.calling_code}
            countryIsoCode={countryISOForMobileNumber}
            inputValue={values.phone_number}
            label={supportPhoneNumberLabel}
            showLabel={true}
            onOpen={() => document.body.classList.add("select-popover")}
            MenuProps={{ id: "select-calling-code" }}
            onClose={() =>
              setTimeout(() => {
                document.body.classList.remove("select-popover");
              }, 400)
            }
            onChangeSelect={(value) => {
              onChangeFormField(
                "calling_code",
                typeof value === "object" ? value.calling_code : value
              );
            }}
            onBlur={() => setTouched({ ...touched, phone_number: true })}
            onChange={(value) => {
              setTouched({ ...touched, phone_number: false });
              onChangeFormField("phone_number", value);
            }}
          />
        </Box>

        <Box className="margin-top20" sx={{ display: "flex" }}>
          <Text
            variant="subtitle1"
            font="regular"
            size={16}
            sx={{ marginRight: "auto" }}
          >
            {showPhoneNumberMsg}
          </Text>
          <CustomSwitch
            checked={values.email_customers_show_phone}
            onChange={(_e, checked) =>
              onChangeFormField("email_customers_show_phone", checked)
            }
          />
        </Box>

        <CustomAutoComplete
          allowedAutoComplete={true}
          name="country"
          value={values.country}
          customClass="margin-top30"
          placeholder={countryPlaceholder}
          showLabel
          label={supportAddressLabel}
          options={Countries}
          getOptionLabel={(optionValues) => {
            let country = getCountryObj(
              "short_name",
              typeof optionValues === "object"
                ? optionValues.short_name
                : optionValues
            );
            return country ? country.short_name : "";
          }}
          onBlur={() => setTouched({ ...touched, country: true })}
          onChange={async (_e, changeCountryValues) => {
            if (changeCountryValues) {
              await setCountryISOForZIP(changeCountryValues.iso2);
            } else {
              await setCountryISOForZIP();
            }
            setTouched({ ...touched, country: false });
            onChangeFormField(
              "country",
              changeCountryValues && typeof changeCountryValues === "object"
                ? changeCountryValues.short_name
                : changeCountryValues
            );
          }}
        />

        <Input
          type="text"
          name="line1"
          inputProps={{ maxLength: 250 }}
          showLabel={false}
          value={values.line1}
          customClass="margin-top20"
          fullWidth
          placeholder={addressLine1Placeholder}
          onBlur={() => setTouched({ ...touched, line1: true })}
          onChange={(e) => {
            onChangeFormField("line1", e.target.value);
          }}
        />

        <Input
          type="text"
          name="line2"
          inputProps={{ maxLength: 250 }}
          value={values.line2}
          showLabel={false}
          customClass="margin-top20"
          fullWidth
          placeholder={addressLine2Placeholder}
          onBlur={() => setTouched({ ...touched, line2: true })}
          onChange={(e) => {
            onChangeFormField("line2", e.target.value);
          }}
        />

        <Input
          type="text"
          name="city"
          inputProps={{ maxLength: 250 }}
          value={values.city}
          showLabel={false}
          customClass="margin-top20"
          fullWidth
          placeholder={cityPlaceholder}
          onBlur={() => setTouched({ ...touched, city: true })}
          onChange={(e) => {
            onChangeFormField("city", e.target.value);
          }}
        />
        {touched.city && Boolean(errors.city) && (
          <AlertMessage
            className="margin-top14"
            severity="error"
            message={invalidLabel(cityPlaceholder)}
          />
        )}

        <Input
          type="text"
          name="state"
          inputProps={{ maxLength: 250 }}
          value={values.state}
          showLabel={false}
          customClass="margin-top20"
          fullWidth
          placeholder={statePlaceholder}
          onBlur={() => setTouched({ ...touched, state: true })}
          onChange={(e) => {
            onChangeFormField("state", e.target.value);
          }}
        />
        {touched.state && Boolean(errors.state) && (
          <AlertMessage
            className="margin-top14"
            severity="error"
            message={invalidLabel(statePlaceholder)}
          />
        )}

        <Input
          type="text"
          name="postal_code"
          inputProps={{ maxLength: 250 }}
          value={values.postal_code}
          showLabel={false}
          customClass="margin-top20"
          fullWidth
          placeholder={postalCodePlaceholder}
          onBlur={() => setTouched({ ...touched, postal_code: true })}
          onChange={(e) => {
            onChangeFormField("postal_code", e.target.value);
          }}
        />
        {touched.postal_code && Boolean(errors.postal_code) && (
          <AlertMessage
            className="margin-top14"
            severity="error"
            message={postalCodeValidate}
          />
        )}

        <Input
          type="text"
          name="business_url"
          inputProps={{ maxLength: 250 }}
          value={values.business_url}
          customClass="margin-top30"
          showLabel
          label={businessWebsiteLabel}
          fullWidth
          placeholder={enterWebsiteURLPlaceholder}
          onBlur={() => setTouched({ ...touched, business_url: true })}
          onChange={(e) => {
            onChangeFormField("business_url", e.target.value);
          }}
        />
        {touched.business_url && Boolean(errors.business_url) && (
          <AlertMessage
            className="margin-top14"
            severity="error"
            message={urlValidate}
          />
        )}

        <Input
          type="text"
          name="support_url"
          inputProps={{ maxLength: 250 }}
          value={values.support_url}
          customClass="margin-top30"
          showLabel
          label={supportWebsiteLabel}
          fullWidth
          placeholder={enterWebsiteURLPlaceholder}
          onBlur={() => setTouched({ ...touched, support_url: true })}
          onChange={(e) => {
            onChangeFormField("support_url", e.target.value);
          }}
        />
        {touched.support_url && Boolean(errors.support_url) && (
          <AlertMessage
            className="margin-top14"
            severity="error"
            message={urlWithHttpValidate}
          />
        )}

        <Input
          type="text"
          name="privacy_policy_url"
          inputProps={{ maxLength: 250 }}
          value={values.privacy_policy_url}
          customClass="margin-top30"
          showLabel
          label={privacyPolicyLabel}
          fullWidth
          placeholder={enterURLPlaceholder}
          onBlur={() => setTouched({ ...touched, privacy_policy_url: true })}
          onChange={(e) => {
            onChangeFormField("privacy_policy_url", e.target.value);
          }}
        />
        {touched.privacy_policy_url && Boolean(errors.privacy_policy_url) && (
          <AlertMessage
            className="margin-top14"
            severity="error"
            message={urlWithHttpValidate}
          />
        )}

        <Input
          type="text"
          name="terms_of_service_url"
          inputProps={{ maxLength: 250 }}
          value={values.terms_of_service_url}
          customClass="margin-top30"
          showLabel
          label={termsOfServiceLabel}
          fullWidth
          placeholder={enterURLPlaceholder}
          onBlur={() => setTouched({ ...touched, terms_of_service_url: true })}
          onChange={(e) => {
            onChangeFormField("terms_of_service_url", e.target.value);
          }}
        />
        {touched.terms_of_service_url &&
          Boolean(errors.terms_of_service_url) && (
            <AlertMessage
              className="margin-top14"
              severity="error"
              message={urlWithHttpValidate}
            />
          )}
      </Grid>
    </Grid>
  );
}

export default PublicInfo;
