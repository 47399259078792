import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";

function TestModeHeader({buttonText, captionText}) {
    return (
        <Box className="test-mode-header">
            <Box className="mode-badge">{buttonText}</Box>
            <Text variant='caption' className='mode-text' style={{ alignSelf: 'center' }} size={14}>{captionText}</Text> 
        </Box>
    )
}

export default TestModeHeader;