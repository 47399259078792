import { Box, Grid } from "@mui/material";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { RadioButton } from "@speed/common/src/components/RadioButton/RadioButton";
import Text from "@speed/common/src/components/Text/Text";
import { createRef, useEffect, useState } from "react";
import {
  addCustomDomain,
  connectCustomDomain,
  customDomainConnected,
  customDomainSubMsg,
  defaultDomain,
  disconnectDomain,
  disconnectModalHeader,
  dnsRecordModalHeader,
  domainDisconnectMsg,
  previewURL,
  readInstruction,
  setupDNSRecord,
  verifyDomain,
  connectingMsg,
  disconnectLabel,
  active,
  customDomainActive,
} from "../../messages";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Button from "@speed/common/src/components/Button/Button";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import CustomDomainDNSRecords from "./CustomDomainDNSRecords";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import AddSubDomainForm from "./AddSubDomainForm";
import { cancel, needHelp } from "@speed/common/src/components/messages";
import { useDispatch, useSelector } from "react-redux";
import {
  callAPIInterface,
  idealTimeLimitReached,
  setShowCrucialActionModal,
  updateSessionLastActionTime,
} from "../../constants";
import { setExecuteAPICall, setLoading } from "../../../redux/common/actions";
import { sessionService } from "redux-react-session";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import { loaderSpinner } from "@speed/common/src/components/images";
import { openIntercomChatModal } from "@speed/common/src/components/constants";

const alllowedCheckoutDomainActions = ["disconnectDomain", "setCustomDomain"];
const CustomCheckoutDomain = () => {
  const [isDefaultDomainChecked, setIsDefaultDomainChecked] = useState(true);
  const [showAddDomain, setShowAddDomain] = useState(false);
  const [btnDisable, setBtnDisable] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDisconnectModal, setOpenDisconnectModal] = useState(false);
  const [dnsRecords, setDnsRecords] = useState(null);
  const [addDomainBtnLoader, setAddDomainBtnLoader] = useState(false);
  const [verifyDomainBtnLoader, setVerifyDomainBtnLoader] = useState(false);
  const [verifyDomainBtnModalLoader, setVerifyDomainBtnModalLoader] =
    useState(false);
  const [disconnectBtnLoader, setDisconnectBtnLoader] = useState(false);
  const [isDNSRecordsVerified, setIsDNSRecordsVerified] = useState(false);
  const [session, setSession] = useState(null);
  const [currentState, setCurrentState] = useState({});
  const [skeletonLoader, setSkeletonLoader] = useState(false);

  const { user, liveMode } = useSelector((state) => state.auth);
  const executeAPICall = useSelector((state) => state.common.executeAPICall);

  const ref = createRef();

  const dispatch = useDispatch();

  // Launch Darkly Flags

  // Get custom domain
  useEffect(() => {
    sessionService.loadSession().then((newSession) => {
      setSession(newSession);
    });
    getCustomDomains();
  }, []);

  useEffect(() => {
    if (executeAPICall) {
      const newSession = updateSessionLastActionTime();
      setSession(newSession);
      if (currentState?.action === "disconnectDomain") {
        handleDisconnect();
      } else if (currentState?.action === "setCustomDomain") {
        handleDomainSelection(
          currentState?.target,
          currentState?.selectedDomain
        );
      }

      dispatch(setLoading(false));
      setShowCrucialActionModal(user, false);
      dispatch(setExecuteAPICall(false));
    }
  }, [executeAPICall]);

  const handleActionClick = (action) => {
    if (
      alllowedCheckoutDomainActions.includes(action) &&
      idealTimeLimitReached(session.last_action_time)
    ) {
      setShowCrucialActionModal(user, true);
    } else {
      if (action === "connectDomain") {
        handleConnectCustomDomain();
      } else if (action === "verifyDomain") {
        handleVerifyDomain();
      } else if (action === "disconnectDomain") {
        handleDisconnect();
      } else if (action === "setCustomDomain") {
        handleDomainSelection(
          currentState?.target,
          currentState?.selectedDomain
        );
      }
    }
  };

  useEffect(() => {
    if (dnsRecords) {
      setSkeletonLoader(true);
      setTimeout(() => {
        setSkeletonLoader(false);
      }, 500);
    }
  }, [liveMode]);

  useEffect(() => {
    if (dnsRecords) {
      if (
        (liveMode && dnsRecords.selected_in_livemode) ||
        (!liveMode && dnsRecords.selected_in_testmode)
      ) {
        setIsDefaultDomainChecked(false);
      } else {
        setIsDefaultDomainChecked(true);
      }
    }
  }, [dnsRecords, liveMode]);

  useEffect(() => {
    if (dnsRecords) {
      const { dns_records } = dnsRecords;
      if (dns_records[0]?.valid && dns_records[1]?.valid) {
        setIsDNSRecordsVerified(true);
        if (openModal) setOpenModal(false);
      } else setIsDNSRecordsVerified(false);
    }
  }, [dnsRecords]);

  useEffect(() => {
    if (openModal && isDNSRecordsVerified) {
      setTimeout(() => {
        setOpenModal(false);
      }, 2000);
    }
  }, [openModal, isDNSRecordsVerified]);

  const getCustomDomains = () => {
    setSkeletonLoader(true);
    callAPIInterface("GET", "/settings/checkout-domains")
      .then((res) => {
        setSkeletonLoader(false);
        if (res.length > 0) {
          setDnsRecords(res[0]);
          setBtnDisable(true);
        } else {
          setBtnDisable(false);
        }
      })
      .catch(() => {
        setSkeletonLoader(false);
        setBtnDisable(false);
      });
  };

  const handleDomainSelection = (_e, value) => {
    callAPIInterface("POST", "/settings/checkout-domains/set-checkout-domain", {
      id: dnsRecords.id,
    })
      .then(() => {
        setIsDefaultDomainChecked(value !== "dynamic");
        const result = dnsRecords;
        if (liveMode) {
          result.selected_in_livemode = !dnsRecords.selected_in_livemode;
        } else {
          result.selected_in_testmode = !dnsRecords.selected_in_testmode;
        }
        setDnsRecords(result);
      })
      .catch(() => {});
  };

  const handleRadioChange = (_e, value) => {
    if (idealTimeLimitReached(session.last_action_time)) {
      setShowCrucialActionModal(user, true);
    } else {
      handleDomainSelection(_e, value);
    }
  };

  const handleConnectCustomDomain = () => {
    setAddDomainBtnLoader(true);
    // Get value of subdomain using ref
    const values = ref?.current?.handleSubmit();

    // Call add custom domain api
    callAPIInterface("POST", "/settings/checkout-domains", {
      domain: values.subdomain,
    })
      .then((res) => {
        setBtnDisable(true);
        setAddDomainBtnLoader(false);
        setDnsRecords(res);
        setOpenModal(true);
        setShowAddDomain(false);
      })
      .catch(() => {
        setAddDomainBtnLoader(false);
      });
  };

  const handleAddAndConnect = () => {
    if (showAddDomain) {
      setCurrentState({ action: "connectDomain" });
      handleActionClick("connectDomain");
    } else {
      setBtnDisable(true);
      setShowAddDomain(true);
    }
  };

  const handleCloseVerifyDomain = () => {
    setOpenModal(false);
    setShowAddDomain(false);
    setBtnDisable(true);
  };

  const handleVerifyDomain = () => {
    openModal
      ? setVerifyDomainBtnModalLoader(true)
      : setVerifyDomainBtnLoader(true);

    callAPIInterface(
      "POST",
      "/settings/checkout-domains/verify-checkout-domain",
      { id: dnsRecords.id }
    )
      .then((res) => {
        openModal
          ? setVerifyDomainBtnModalLoader(false)
          : setVerifyDomainBtnLoader(false);
        setDnsRecords(res);
      })
      .catch(() => {
        openModal
          ? setVerifyDomainBtnModalLoader(false)
          : setVerifyDomainBtnLoader(false);
      });
  };

  const footer = (
    <Box className="content-center" justifyContent="space-between !important">
      <Button
        loading={verifyDomainBtnModalLoader}
        label={verifyDomain}
        onClick={() => {
          setCurrentState({ action: "verifyDomain" });
          handleActionClick("verifyDomain");
        }}
      />
      <Text size={16} variant="inline" font="regular">
        <CustomLink size="large" onClick={openIntercomChatModal}>
          {needHelp}
        </CustomLink>
      </Text>
    </Box>
  );

  const openPopper = Boolean(anchorEl);

  const handleDisconnect = () => {
    setDisconnectBtnLoader(true);
    callAPIInterface(
      "DELETE",
      `/settings/checkout-domains/${dnsRecords?.id}`,
      {}
    )
      .then(() => {
        setDisconnectBtnLoader(false);
        setDnsRecords(null);
        setBtnDisable(false);
        setIsDefaultDomainChecked(true);
        setOpenDisconnectModal(false);
      })
      .catch(() => {
        setOpenDisconnectModal(false);
        setDisconnectBtnLoader(false);
      });
  };

  const showTag = () => {
    return (
      <Tag
        text={active}
        variant="success"
        deleteIcon={<CheckCircleIcon className="custom-domain-active-icon" />}
        onDelete={() => {}}
      />
    );
  };

  const dynamicDomainRadio = () => {
    const isVerified = dnsRecords?.status === "ready";
    return (
      <Grid item lg={11}>
        <Box display="flex" alignItems="center">
          <RadioButton
            onChange={(e) => {
              setCurrentState({
                action: "setCustomDomain",
                target: e,
                selectedDomain: "dynamic",
              });
              handleRadioChange(e, "dynamic");
            }}
            checked={!isDefaultDomainChecked}
            label={dnsRecords?.name}
            className="custom-domain-radio-label"
            disabled={!isVerified}
          />
          {!isDefaultDomainChecked
            ? showTag()
            : dnsRecords?.status === "connecting" && (
                <Box display="flex" alignItems="center">
                  <img
                    src={loaderSpinner}
                    alt="Loader"
                    width={15}
                    height={15}
                    style={{ marginRight: "5px" }}
                  />
                  <Text size={12} font="regular" variant="inline">
                    {connectingMsg}
                  </Text>
                </Box>
              )}
        </Box>

        <Text
          className="grey-text"
          sx={{ marginTop: "10px", marginLeft: "35px" }}
          size={16}
          font="regular"
          variant="subtitle1"
        >
          {!isVerified
            ? setupDNSRecord
            : isDefaultDomainChecked
            ? customDomainConnected
            : customDomainActive}
        </Text>
        {!isVerified ? (
          <Box sx={{ marginTop: "23px" }}>
            <Button
              label={verifyDomain}
              variant="outlined"
              sx={{
                margin: "0 20px 0",
                marginLeft: "35px",
              }}
              loading={verifyDomainBtnLoader}
              onClick={() => {
                setCurrentState({ action: "verifyDomain" });
                handleActionClick("verifyDomain");
              }}
            />
            {!isDNSRecordsVerified && (
              <CustomLink
                size="small"
                bold={true}
                onClick={() => setOpenModal(true)}
              >
                {readInstruction}
              </CustomLink>
            )}
          </Box>
        ) : null}
      </Grid>
    );
  };

  return (
    <>
      {/* Modal for disconnect custom domain */}
      <Modal
        body={
          <Text
            size={16}
            className="default-text"
            variant="subtitle1"
            font="regular"
          >
            {domainDisconnectMsg(dnsRecords?.name)}
          </Text>
        }
        footer={
          <Button
            color="error"
            loading={disconnectBtnLoader}
            label={disconnectLabel}
            onClick={() => {
              setCurrentState({ action: "disconnectDomain" });
              handleActionClick("disconnectDomain");
            }}
          />
        }
        handleClose={() => setOpenDisconnectModal(false)}
        open={openDisconnectModal}
        title={disconnectModalHeader}
        closeIcon={true}
      />

      {/* Open popper for disconnect custom domain */}
      <CustomPopper
        disablePortal={true}
        open={openPopper}
        anchorEl={anchorEl}
        position="bottom"
        handleClose={() => setAnchorEl(null)}
      >
        <Text
          size={14}
          variant="subtitle1"
          font="semibold"
          sx={{ color: "#e74c3c !important" }}
          className="pointer-cursor"
          onClick={() => setOpenDisconnectModal(true)}
        >
          {disconnectDomain}
        </Text>
      </CustomPopper>

      {/* Modal for showing dns records  */}
      <Modal
        className="dns-records-modal"
        body={<CustomDomainDNSRecords dnsRecords={dnsRecords} />}
        maxWidth="sm"
        footer={footer}
        handleClose={handleCloseVerifyDomain}
        open={openModal}
        title={dnsRecordModalHeader}
        closeIcon={true}
      />
      <Box className="box-container">
        <Text
          className="grey-text"
          sx={{ marginTop: "10px" }}
          size={16}
          font="regular"
          variant="subtitle1"
        >
          {customDomainSubMsg[0]}
          <CustomLink size="large" bold={true}>
            {customDomainSubMsg[1]}
          </CustomLink>
          {customDomainSubMsg[2]}
        </Text>
        <Box className="custom-domain-wrapper">
          {/* List of default and custom domain */}
          <List disablePadding>
            <ListItem disablePadding>
              <Box>
                {skeletonLoader ? (
                  <CommonSkeleton
                    width={241}
                    height={27}
                    sx={{ marginBottom: "17px", borderRadius: "7px" }}
                    color="#c4ccd2"
                  />
                ) : (
                  <RadioButton
                    onChange={(e) => {
                      setCurrentState({
                        action: "setCustomDomain",
                        target: e,
                        selectedDomain: "default",
                      });
                      handleRadioChange(e, "default");
                    }}
                    checked={isDefaultDomainChecked}
                    label={previewURL}
                    className="custom-domain-radio-label"
                  />
                )}
                {!skeletonLoader && isDefaultDomainChecked && showTag()}
                {skeletonLoader ? (
                  <CommonSkeleton
                    width={314}
                    height={18}
                    sx={{ borderRadius: "6px" }}
                  />
                ) : (
                  <Text
                    className="grey-text"
                    sx={{
                      marginTop: "10px",
                      marginLeft: "35px",
                    }}
                    size={16}
                    font="regular"
                    variant="subtitle1"
                  >
                    {defaultDomain}
                  </Text>
                )}
              </Box>
            </ListItem>
            {!skeletonLoader && dnsRecords && (
              <ListItem disablePadding>
                <Grid container>
                  {dynamicDomainRadio()}
                  <Grid item lg={1} display="flex" justifyContent="flex-end">
                    <MoreHorizIcon
                      className="horizontal-dots-icon pointer-cursor"
                      sx={{ height: "30px", width: "30px" }}
                      onClick={(event) =>
                        setAnchorEl(anchorEl ? null : event.currentTarget)
                      }
                    />
                  </Grid>
                </Grid>
              </ListItem>
            )}
          </List>

          {/* Action buttons for add and cancel custom domain */}

          <Box sx={{ padding: skeletonLoader ? "7px 20px" : "20px" }}>
            {skeletonLoader ? (
              <CommonSkeleton
                width={202}
                height={68}
                color="#d8dce1"
                sx={{ marginLeft: "5px", borderRadius: "6px" }}
              />
            ) : (
              <>
                {showAddDomain && (
                  <AddSubDomainForm ref={ref} setBtnDisable={setBtnDisable} />
                )}
                <Button
                  loading={addDomainBtnLoader}
                  disabled={btnDisable}
                  label={showAddDomain ? connectCustomDomain : addCustomDomain}
                  type="button"
                  onClick={handleAddAndConnect}
                />
                {showAddDomain && (
                  <Button
                    sx={{ marginLeft: "15px" }}
                    label={cancel}
                    type="button"
                    variant="outlined"
                    onClick={() => {
                      setShowAddDomain(false);
                      setBtnDisable(false);
                    }}
                  />
                )}
              </>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default CustomCheckoutDomain;
