import React, { memo } from "react";
import { Box } from "@mui/material";

import {
  excludedCode,
  satsToBtcAmount,
} from "@speed/common/src/components/constants";
import EstimateFeesRowSection from "../Common/EstimateFeesRowSection";
import {
  estimatedNetworkFees,
  estimatedRoutingFees,
  feedDeclaration,
  reqPayoutAmount,
  speedFee,
  totalPayoutDeduction,
} from "../messages";

const CommonEstimateFeesBox = ({
  estimatedFees,
  satsAmount,
  exchangeRateValue,
  loader,
  speedFeesInSats,
  isDecodedResMethodOnchain,
  currencyCode,
  amount,
}) => {
  const isSelectedCurrencyBTC = currencyCode === "BTC";
  const isSelectedCurrencyFiat = !excludedCode.includes(currencyCode);
  const checkForBTCAndFiatCurr =
    isSelectedCurrencyBTC || isSelectedCurrencyFiat;

  const propsForEstimateSection = {
    amountInSats: satsAmount,
    currencyCode,
    currencyToShow: checkForBTCAndFiatCurr ? "BTC" : "SATS",
    exchangeRateValue,
    isSelectedCurrencyBTC,
    isSelectedCurrencyFiat,
    loader,
  };

  /**
   *  For speed fee; Added check if fee is 0 or not
   *  @if true  it will convert btc and fiat amount values to sats
   *  @else it will directly show the sats of entered amount
   */
  const checkForBtc = currencyCode === "BTC" ? "0.00000000" : "0";
  const checkForFiat = checkForBTCAndFiatCurr
    ? satsToBtcAmount(speedFeesInSats)
    : speedFeesInSats?.toFixed(8);
  const speedFeeAmount = speedFeesInSats === 0 ? checkForBtc : checkForFiat;

  const estFiatCheck = checkForBTCAndFiatCurr
    ? satsToBtcAmount(estimatedFees)
    : estimatedFees;

  // Total fees calculated based on estimatedFees, speedFeed and entered amount
  const totalFees = estimatedFees + satsAmount + speedFeesInSats;

  // Creating the data to display fees
  const feesData = [
    {
      title: reqPayoutAmount,
      feesAmount:
        satsAmount && checkForBTCAndFiatCurr
          ? satsToBtcAmount(satsAmount)
          : satsAmount,
    },
    {
      title: speedFee,
      feesAmount: speedFeeAmount,
    },
    {
      title: `~${
        isDecodedResMethodOnchain ? estimatedNetworkFees : estimatedRoutingFees
      }`,
      feesAmount: estFiatCheck,
      subtitle: feedDeclaration,
      shouldRangeShow: !isDecodedResMethodOnchain, // Show range only when address is lightning .i.e "0-3 SATS"
    },
    {
      title: totalPayoutDeduction,
      feesAmount: checkForBTCAndFiatCurr
        ? satsToBtcAmount(totalFees)
        : totalFees,
      isLast: true,
    },
  ];

  return (
    <Box className="est-fees-box">
      {feesData.map((item, index) => (
        <EstimateFeesRowSection
          key={`${item.title}_${index}`}
          {...item}
          {...propsForEstimateSection}
        />
      ))}
    </Box>
  );
};

export default memo(CommonEstimateFeesBox);
