import React from "react";
import { Box } from "@mui/material";
import Translation from "@speed/common/src/components/Translation";
import Text from "@speed/common/src/components/Text/Text";
import { logoWhite } from "../images";

function FooterWithWhiteLogo({ fontStyle, textColor }) {
  return (
    <Box className="logo-with-white-footer" display="flex">
      <Text
        size={12}
        sx={{
          fontFamily: fontStyle?.fontFamily,
          marginRight: "11px",
          color: textColor,
        }}
        font="regular"
        variant="subtitle1"
      >
        <Translation value="poweredBy" />
      </Text>
      <img src={logoWhite} alt="logo" height={15} />
    </Box>
  );
}

export default FooterWithWhiteLogo;
