import { useFlags } from "launchdarkly-react-client-sdk";
import React from "react";
import { useParams } from "react-router";
import "../../assets/styles/payment-links.scss";
import ModuleEventList from "../ModuleEventList";

const WithdrawDetailsEvents = (props) => {
  const urlParams = useParams();
  const { wiEventFe } = useFlags();

  return (
    <ModuleEventList ldFlag={wiEventFe} urlParams={urlParams} {...props} />
  );
};

export default WithdrawDetailsEvents;
