import { Box, Grid } from "@mui/material";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import { Input } from "@speed/common/src/components/Input/Input";
import { RadioButton } from "@speed/common/src/components/RadioButton/RadioButton";
import Text from "@speed/common/src/components/Text/Text";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import { getTrimmedString, validateURL } from "../constants";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  updatePaymentMessage,
  setPaymentRedirectWebPreview,
  setPaymentConfirmationOption,
} from "../../redux/common/actions";
import {
  confirmationPageTitle,
  showConfirmationPreviewLabel,
  redirectToWebsite,
  enterWebsiteURLPlaceholder,
  messagePlaceholder,
  websiteLabel,
  customMessageLabel,
  customMessageError,
  urlWithHttpValidate,
  value,
} from "../messages";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import { useDispatch } from "react-redux";
import _ from "lodash";

const PaymentConfirmation = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [showPreviewOption, setShowPreviewOption] = useState(true);
  const [checkCustomMessage, setCheckCustomMessage] = useState(false);
  const CustomMessageValidationSchema = yup.object({
    custom_message: yup.string().required(customMessageError).trim(),
  });

  const websiteUrlValidationSchema = yup.object({
    website_url: yup
      .mixed()
      .test({
        name: "website_url",
        message: urlWithHttpValidate,
        test: (website_url) => validateURL(website_url, true),
      })
      .required("Enter website URL"),
  });

  const formik = useFormik({
    initialValues: {
      custom_message: "",
      website_url: "",
    },
    validationSchema: showPreviewOption
      ? CustomMessageValidationSchema
      : websiteUrlValidationSchema,
    enableReinitialize: true,
  });

  const {
    values,
    setFieldValue,
    resetForm,
    setTouched,
    setErrors,
    errors,
    touched,
  } = formik;
  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  useEffect(() => {
    if (
      (checkCustomMessage && values.custom_message === "") ||
      (!showPreviewOption && values.website_url === "")
    ) {
      props.setDisableCreateButton(true);
    } else {
      if (_.isEmpty(errors)) {
        props.setDisableCreateButton(false);
      } else {
        props.setDisableCreateButton(true);
      }
    }
  }, [values, errors, showPreviewOption, checkCustomMessage]);

  useEffect(() => {
    setErrors({});
  }, [checkCustomMessage]);

  const onChangeOption = (newShowPreviewOption) => {
    setShowPreviewOption(newShowPreviewOption);
    setErrors({});
    resetForm();
  };

  const handleRadioChange = (event, option) => {
    switch (option) {
      case "confirmation":
        dispatch(setPaymentRedirectWebPreview(false));
        dispatch(setPaymentConfirmationOption("confirmation"));
        onChangeOption(true);
        break;
      case "redirect":
        event.preventDefault();
        setCheckCustomMessage(false);
        onChangeOption(false);
        dispatch(updatePaymentMessage(""));
        dispatch(setPaymentRedirectWebPreview(true));
        dispatch(setPaymentConfirmationOption("redirect"));
        break;
    }
  };

  const handleChangeMessage = (value) => {
    setFieldValue("custom_message", value);
    dispatch(updatePaymentMessage(getTrimmedString(value)));
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      setCheckCustomMessage(!checkCustomMessage);
    }
  };

  useImperativeHandle(ref, () => ({
    handleSubmitConfirm: () => {
      let submitObj = {};
      if (checkCustomMessage && values.custom_message) {
        submitObj.success_message = getTrimmedString(values.custom_message);
      }
      if (values.website_url) {
        submitObj.success_url = values.website_url;
      }

      return submitObj;
    },
  }));

  return (
    <Grid container className="apply-auto-scroll">
      <Box sx={{ width: "480px" }}>
        <Grid
          padding="28px 3px"
          sx={!props.isPaymentLink ? { display: "none" } : { display: "block" }}
        >
          <Text
            font="semibold"
            className="default-text"
            style={{ marginBottom: "22px" }}
            size={20}
            variant="h2"
          >
            {confirmationPageTitle}
          </Text>

          <Box
            className={`confirmation-box ${showPreviewOption && "active"}`}
            onClick={(event) =>
              !showPreviewOption && handleRadioChange(event, "confirmation")
            }
          >
            <RadioButton
              checked={showPreviewOption}
              label={showConfirmationPreviewLabel}
            />
            {showPreviewOption && (
              <Box sx={{ marginLeft: "35px", marginTop: "12px" }}>
                <CustomCheckbox
                  style={{ marginBottom: "8px" }}
                  checked={checkCustomMessage}
                  label={customMessageLabel}
                  className="checkbox-wrapper"
                  onKeyDown={handleKeyDown}
                  onChange={() => {
                    setCheckCustomMessage(!checkCustomMessage);
                    if (checkCustomMessage) {
                      dispatch(updatePaymentMessage(""));
                    } else {
                      dispatch(
                        updatePaymentMessage(
                          getTrimmedString(values.custom_message)
                        )
                      );
                    }
                  }}
                />
                {checkCustomMessage && (
                  <>
                    <TextAreaComponent
                      showLabel={false}
                      value={values.custom_message}
                      autoFocus={checkCustomMessage}
                      maxRows={3}
                      minRows={3}
                      maxLength={255}
                      fullWidth
                      error={Boolean(errors.custom_message)}
                      onBlur={(_e) =>
                        setTouched({ ...touched, custom_message: true })
                      }
                      onChange={(e) => {
                        handleChangeMessage(e.target.value);
                      }}
                      placeholder={messagePlaceholder}
                    />
                    <InputErrorMessage
                      {...defaultErrorMessageProps}
                      inputName="custom_message"
                    />
                  </>
                )}
              </Box>
            )}
          </Box>

          <Box
            className={`confirmation-box ${!showPreviewOption && "active"}`}
            style={{ marginTop: "20px" }}
            onClick={(event) => {
              showPreviewOption && handleRadioChange(event, "redirect");
            }}
          >
            <RadioButton
              checked={!showPreviewOption}
              label={redirectToWebsite}
            />
            {!showPreviewOption && (
              <Box sx={{ marginLeft: "30px", marginTop: "15px" }}>
                <>
                  <Input
                    showLabel
                    value={values.website_url}
                    autoFocus={true}
                    name="website_url"
                    label={websiteLabel}
                    type="text"
                    fullWidth
                    error={Boolean(errors.website_url)}
                    placeholder={enterWebsiteURLPlaceholder}
                    onBlur={(_e) => {
                      setTouched({ ...touched, website_url: true });
                    }}
                    onChange={(e) =>
                      setFieldValue("website_url", e.target.value)
                    }
                  />

                  <InputErrorMessage
                    {...defaultErrorMessageProps}
                    inputName="website_url"
                  />
                </>
              </Box>
            )}
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
});

export default PaymentConfirmation;
