import React, { useContext, useEffect, useState } from "react";
import SelectAccount from "../../SelectAccount";
import { alreadyConnectAccount, onlyWalletInConnect } from "../../../messages";
import AddEditAccount from "../../AddEditAccount";
import {
  callAPIInterfaceConnect,
  getTimestamp,
} from "@speed/connect/src/components/constants";
import { generateToken } from "@speed/connect/src/redux/authConnect/actions";
import { ConnectContext } from "../../../contexts/contexts";
import { cancel } from "../../messages";
import history from "../../history";
import AccountLoader from "./AccountLoader";

const ConnectAccountSelection = ({ open, setOpen }) => {
  const [selectedRadio, setSelectedRadio] = useState();
  const [accountSelected, setAccountSelected] = useState();
  const [loader, setLoader] = useState(false);
  const [accountList, setAccountList] = useState([]);

  // Get context value using consumer
  const connectPageData = useContext(ConnectContext);

  useEffect(() => {
    sessionStorage.removeItem("connect-account-success");
    sessionStorage.removeItem("account-details");
  }, []);

  useEffect(() => {
    setSelectedRadio(connectPageData?.selectedConnectAccount?.id);
    setAccountSelected(connectPageData?.selectedConnectAccount);
  }, [connectPageData?.selectedConnectAccount]);

  const handleAddAccount = async (accountData) => {
    if (accountData) {
      const { token_response: updatedTokens } = accountData;
      if (updatedTokens && Object.keys(updatedTokens).length !== 0) {
        await connectPageData?.setSession({
          ...connectPageData?.session,
          access_token: updatedTokens.access_token,
          id_token: updatedTokens.id_token,
          timestamp: getTimestamp(),
        });
      }
      getConnectAccountDetails();
    }
  };

  const platformAccId = connectPageData?.sessionUser?.accId;

  const getConnectAccountDetails = () => {
    setLoader(true);
    callAPIInterfaceConnect(
      "GET",
      `/user/accounts?platform_account_id=${platformAccId}&limit=50`
    )
      .then((res) => {
        setLoader(false);
        const result =
          res.data.length > 0 &&
          res.data.filter((item) => item.id !== platformAccId);
        setAccountList(result);
      })
      .catch(() => setLoader(false));
  };

  useEffect(() => {
    platformAccId && getConnectAccountDetails();
  }, [platformAccId]);

  useEffect(() => connectPageData?.setFirstBtnLabel(cancel), []);

  useEffect(() => {
    connectPageData?.setButtonDisabled(!selectedRadio);
  }, [selectedRadio]);

  useEffect(() => {
    if (connectPageData?.isButtonClicked) {
      if (accountSelected) {
        sessionStorage.setItem(
          "account-details",
          JSON.stringify(accountSelected)
        );
        history.push("/connect-standard-capabilities");
      }
    }
  }, [connectPageData?.isButtonClicked]);

  useEffect(async () => {
    if (connectPageData?.goBackClicked) {
      connectPageData?.setFirstButtonLoader(true);
      await connectPageData?.logOut();
      connectPageData?.setFirstButtonLoader(false);
    }
  }, [connectPageData?.goBackClicked]);

  return (
    <>
      {loader ? (
        <AccountLoader />
      ) : (
        <SelectAccount
          selectedRadio={selectedRadio}
          setSelectedRadio={(id) => {
            setSelectedRadio(id);
            const accFromId =
              accountList.length > 0 &&
              accountList.find((item) => item.id === id);
            connectPageData?.setSelectedAccountId(accFromId);
          }}
          setAccountSelected={setAccountSelected}
          walletAcctMsg={onlyWalletInConnect}
          alreadyConnectedMsg={alreadyConnectAccount}
          listCustomClass="connect-list-selection"
          from="connect-account"
          platformAccId={platformAccId}
          accountsList={accountList}
        />
      )}
      <AddEditAccount
        open={open}
        isEdit={false}
        handleClose={() => {
          setOpen(false);
        }}
        handleAddAccount={handleAddAccount}
        callAPIInterface={callAPIInterfaceConnect}
        generateToken={generateToken}
        dispatch={connectPageData?.dispatch}
      />
    </>
  );
};

export default ConnectAccountSelection;
