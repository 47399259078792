import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { Box, Skeleton } from "@mui/material";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import { Input } from "@speed/common/src/components/Input/Input";
import Label from "@speed/common/src/components/Label/Label";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import Text from "@speed/common/src/components/Text/Text";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import UploadFile from "@speed/common/src/components/UploadFile";
import { pageTitleLabel } from "@speed/common/src/messages";
import React, { useState } from "react";
import { callAPIInterface } from "../constants";
import {
  addOtherInfoLabel,
  imageIconMsg,
  pageDescLabel,
  pageDescPlaceholder,
  pageImageLabel,
  pageTitlePlaceholder,
} from "../messages";
import { showToast } from "../../redux/common/actions";

const TIDForm = ({
  commonStyle,
  values,
  touched,
  setTouched,
  setFieldValue,
  errors,
  handleClick,
  handleClose,
  open,
  anchorEl,
  isCheckedOtherInfo,
  setIsCheckedOtherInfo,
  ldKey,
}) => {
  const [imageLoader, setImageLoader] = useState(false);
  const showPageImage = () => {
    const handleEnterKeyPress = (event) => {
      if (event.keyCode === 13) {
        event.currentTarget.click();
      }
    };
    const responseImage = (profile) => {
      setFieldValue("image", profile.file_name);
      setImageLoader(false);
    };

    const handleUploadTempImage = async (file, fileName) => {
      try {
        const apiRes = await callAPIInterface(
          "POST",
          "/upload-image",
          null,
          file,
          fileName
        );
        responseImage(apiRes);
      } catch (error) {
        setImageLoader(false);
      }
    };

    return (
      <Box className="pl-image-upload-box">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Label>{pageImageLabel}</Label>
          <InfoIcon
            className="label-with-icon"
            sx={{
              height: "20px !important",
              width: "20px !important",
              marginBottom: "14px",
            }}
            onClick={handleClick}
          />
          <CustomPopper
            disablePortal={true}
            open={open}
            anchorEl={anchorEl}
            position="bottom"
            handleClose={handleClose}
          >
            <Text className="grey-text" size={14} variant="subtitle1">
              {imageIconMsg}
            </Text>
          </CustomPopper>
        </Box>
        {values.image && (
          <Box className="pl-preview-image-box">
            <img src={values.image} className="pl-preview-image link-form" />
            <Box
              tabIndex={0}
              onKeyDown={handleEnterKeyPress}
              title="Remove"
              className="remove-icon-pl"
              onClick={() => {
                setFieldValue("image", "");
              }}
            >
              <CloseIcon />
            </Box>
          </Box>
        )}
        <label
          htmlFor="image_upload"
          style={{ width: "148px", display: "block" }}
        >
          <UploadFile
            showLoader={(status) => setImageLoader(status)}
            imgDimension={200}
            apiFunc={handleUploadTempImage}
            id="image_upload"
            showToast={showToast}
          />
          {imageLoader ? (
            <Skeleton
              sx={{ height: "148px", width: "148px" }}
              variant="rectangular"
              animation="wave"
            />
          ) : (
            !values.image && (
              <Box
                component="div"
                onKeyDown={handleEnterKeyPress}
                className="image-upload-box link-form"
              >
                <AddIcon className="add-icon" />
              </Box>
            )
          )}
        </label>
      </Box>
    );
  };

  const handleKeyDownTID = (e) => {
    if (e.keyCode === 13) {
      setIsCheckedOtherInfo(!isCheckedOtherInfo);
      setFieldValue("otherInfo", !values.otherInfo);
    }
  };

  return (
    <>
      {ldKey && (
        <CustomCheckbox
          checked={isCheckedOtherInfo}
          name="otherInfo"
          label={addOtherInfoLabel}
          className="options"
          sx={commonStyle}
          onKeyDown={handleKeyDownTID}
          onChange={(e) => {
            setIsCheckedOtherInfo(!isCheckedOtherInfo);
            setFieldValue("otherInfo", e.target.checked);
          }}
        />
      )}
      {isCheckedOtherInfo && (
        <>
          <Input
            sx={commonStyle}
            fullWidth
            customClass="margin-top30"
            label={pageTitleLabel}
            placeholder={pageTitlePlaceholder}
            name="pageTitle"
            value={values.pageTitle}
            type="text"
            inputProps={{ maxLength: 250 }}
            onBlur={() => setTouched({ ...touched, pageTitle: true })}
            onChange={(e) => {
              setTouched({ ...touched, pageTitle: false });
              setFieldValue("pageTitle", e.target.value);
            }}
            error={touched.pageTitle && Boolean(errors.pageTitle)}
          />
          {touched.pageTitle && Boolean(errors.pageTitle) ? (
            <AlertMessage
              message={errors.pageTitle}
              className="margin-top15"
              severity="error"
            />
          ) : null}
          {showPageImage()}
          <TextAreaComponent
            style={commonStyle}
            showLabel={true}
            label={pageDescLabel}
            value={values.pageDescription}
            maxRows={3}
            minRows={3}
            maxLength={250}
            fullWidth
            error={touched.pageDescription && Boolean(errors.pageDescription)}
            onBlur={(_e) => setTouched({ ...touched, pageDescription: true })}
            onChange={(e) => {
              setFieldValue("pageDescription", e.target.value);
            }}
            placeholder={pageDescPlaceholder}
          />
          {touched.pageDescription && Boolean(errors.pageDescription) && (
            <AlertMessage
              message={errors.pageDescription}
              className="margin-top15"
              severity="error"
            />
          )}
        </>
      )}
    </>
  );
};

export default TIDForm;
