import React, { useCallback, useEffect, useState } from "react";
import { Box, TableCell, TableRow } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import CustomTable from "@speed/common/src/components/Table/Table";
import { emptySubText } from "@speed/common/src/components/messages";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import {
  getCurrencyObj,
  linkStatus,
} from "@speed/common/src/components/constants";
import { emptyPartner } from "@speed/common/src/components/images";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import {
  callAPIInterface,
  dateFromTimestampInApp,
  getFixedFeeAmountByCurrency,
  noOfRecords,
} from "../constants";
import { setIsDataAvailable } from "../../redux/common/actions";

const Merchants = ({ targetCurrency }) => {
  const queryParam = "?limit=" + noOfRecords;
  const { aflGetlistofinvitedmemberFe } = useFlags();
  const { history, isDataAvailable, caughtErrorInStrapi } = useSelector(
    (state) => state.common
  );
  const [rows, setRows] = useState([]);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [queryParams, setQueryParams] = useState(queryParam);
  const dispatch = useDispatch();

  const fetchPartners = () => {
    setRows([]);
    setHasMore(true);
    getPartners([], queryParam);
  };

  const loadMore = useCallback(() => {
    getPartners(rows, queryParams);
  }, [rows]);

  const getPartners = (lines, params) => {
    setTableRowSkeleton(true);
    const affiliatePartnerPromise = new Promise((resolve, reject) => {
      callAPIInterface("GET", "/affiliate-partner" + params, {})
        .then((res) => {
          if (res) {
            setTableRowSkeleton(false);
            if (!res.has_more) {
              setHasMore(false);
            } else {
              setQueryParams(
                queryParam +
                  "&ending_before=" +
                  res.data[res.data.length - 1].id
              );
            }
            setRows(lines.concat(res.data));
          }
          resolve(res);
        })
        .catch((e) => reject(e));
    });

    const invitePartnersGuide = new Promise((resolve, reject) => {
      callAPIInterface("GET", "/affiliate-partner/invite?limit=1", {})
        .then((resInvitee) => {
          resolve(resInvitee);
        })
        .catch((e) => reject(e));
    });

    Promise.all([affiliatePartnerPromise, invitePartnersGuide])
      .then((res) => {
        if (!res?.[1]?.data?.length) {
          dispatch(setIsDataAvailable(res?.[0]?.data?.length > 0));
        }
      })
      .catch(() => {
        setTableRowSkeleton(false);
      });
  };

  useEffect(
    () => aflGetlistofinvitedmemberFe && fetchPartners(),
    [aflGetlistofinvitedmemberFe]
  );

  const columns = [
    {
      title: "Account ID",
      paddingLeft: "38px !important",
      width: "300px",
    },
    { title: "Account Name", width: "200px" },
    { title: "Account Owner Email", width: "300px" },
    { title: "Commission (%)", width: "150px" },
    {
      title: `Fixed Fee (${targetCurrency})`,
      width: "150px",
    },
    { title: "Start Date", width: "120px" },
    { title: "End Date", width: "120px" },
    { title: "Status", width: "110px" },
  ];

  let columnsData = columns.map((column) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{
        minWidth: column.width,
        height: "50px",
        pl: column.paddingLeft,
      }}
    >
      {column.title}
    </TableCell>
  ));

  let rowsData = rows?.map((rowItem) => {
    const { amount, percentage } = getFixedFeeAmountByCurrency({
      feesArray: rowItem?.fees,
      targetCurrency,
    });

    return (
      <TableRow
        key={rowItem?.id}
        className="clickable"
        onClick={() =>
          history.push(`/partners/affiliate-partners/${rowItem.id}`)
        }
      >
        <TableCell sx={{ pl: "38px !important" }}>
          <Box className="flex-inline">
            <Box sx={{ pr: "15px", m: "2.5px 0px" }}>{rowItem?.account_id}</Box>
            <Box onClick={(event) => event.stopPropagation()}>
              <Clipboard text={rowItem?.account_id} />
            </Box>
          </Box>
        </TableCell>
        <TableCell sx={[{ height: "50px" }]}>
          <TruncatedTextTooltip
            textValue={rowItem?.account_name}
            className="custom-tooltip"
            cellWidth="200px"
          />
        </TableCell>
        <TableCell>{rowItem?.owner_email}</TableCell>
        <TableCell>{percentage || "-"}</TableCell>
        <TableCell>
          {amount ? (
            <>
              {getCurrencyObj(targetCurrency)?.symbol} {amount}
            </>
          ) : (
            "-"
          )}
        </TableCell>
        <TableCell>{dateFromTimestampInApp(rowItem?.start_date)}</TableCell>
        <TableCell>{dateFromTimestampInApp(rowItem?.end_date)}</TableCell>
        <TableCell>
          <Tag
            text={linkStatus[rowItem?.status?.toLowerCase()]?.label}
            variant={linkStatus[rowItem?.status?.toLowerCase()]?.variant}
          />
        </TableCell>
      </TableRow>
    );
  });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    textOnNoData: emptySubText(true, "partners"),
    noDataImage: emptyPartner,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
  };
  return (
    <Box className="main-content">
      {aflGetlistofinvitedmemberFe ? (
        (isDataAvailable || caughtErrorInStrapi) && (
          <CustomTable {...tableProps} />
        )
      ) : (
        <AccessDenied />
      )}
    </Box>
  );
};

export default Merchants;
