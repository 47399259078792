import React from "react";
import Text from "@speed/common/src/components/Text/Text";
import { priceLabel, select } from "../messages";
import { Box } from "@mui/material";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import { currency } from "@speed/common/src/components/currency";
import { renderCurrencyOption } from "../constants";
import InputMask from "@speed/common/src/components/MaskInput";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";

const PriceComponent = ({
  currencyName,
  currencyValue,
  amountName,
  amountValue,
  handleChangeCurrency,
  className,
  formik,
  error,
}) => {
  const { errors, touched, setFieldValue, setTouched } = formik;
  return (
    <>
      <Text
        size={14}
        font="regular"
        className="grey-text"
        margin="15px 0px 10px"
      >
        {priceLabel}
      </Text>
      <Box className="product-price" display="flex">
        <CustomAutoComplete
          name={currencyName}
          options={currency}
          getOptionLabel={(value) => {
            return `${value?.code}`;
          }}
          onChange={(_event, val) => handleChangeCurrency(val)}
          value={currencyValue}
          showLabel={false}
          sx={{ ml: "1px" }}
          disableClearable
          renderOption={renderCurrencyOption}
          customClass={className}
          placeholder={select}
        />
        <InputMask
          showLabel={false}
          onChange={(e) => {
            setTouched({ ...touched, amountName: false });
            setFieldValue(amountName, e.target.value);
          }}
          value={amountValue}
          error={touched.amountValue && Boolean(errors.amountValue)}
          onBlur={() => setTouched({ ...touched, amountValue: true })}
        />
      </Box>
      {error && (
        <AlertMessage
          message={error}
          className="margin-top15"
          severity="error"
        />
      )}
    </>
  );
};

export default PriceComponent;
