import { Switch, Redirect, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import Dashboard from "../components/Dashboard";
import Balances from "../components/Balances";
import Payouts from "../components/Payouts";
// import ManageApps from "../components/ManageApps";
import Products from "../components/Products";
import Payments from "../components/Payments";
import Withdraws from "../components/Withdraws";
import Auth from "../components/IntegrationAuth";
import SignUp from "../components/SignUp";
import SignIn from "../components/SignIn";
import ResetPassword from "../components/ResetPassword/ForgotPassword";
import NewPassword from "../components/ResetPassword/NewPassword";
//import EmailSent from '../components/ResetPassword/EmailSent';
import TwoFactorAuthentication from "../components/2fa";
import Settings from "../components/Settings";
import Setup2FA from "../components/2fa/Setup2FA";
import PaymentLinkDetails from "../components/PaymentLinks/LinkDetails";
import CheckoutLinkDetails from "../components/CheckoutLinks/LinkDetails";
import WithdrawalLinkDetails from "../components/WithdrawalLinks/LinkDetail";
import VerifyEmail from "../components/2fa/VerifyEmail";
import ConfirmEmail from "../components/Settings/ConfirmEmail";
import AcceptInvite from "../components/Settings/UsersAccounts/AcceptInvite";
import Transactions from "../components/Transactions";
import CheckoutLinks from "../components/CheckoutLinks";
import Logs from "../components/Logs";
import Events from "../components/Events";
import LogDetails from "../components/Logs/LogDetails";
import PaymentsDetail from "../components/Payments/PaymentsDetail";
import PaymentList from "../components/PaymentLinks/PaymentList";
import PaymentLinks from "../components/PaymentLinks";
import WithdrawalLinks from "../components/WithdrawalLinks";
import Documentation from "../components/Documentation";
import PayoutsDetails from "../components/Payouts/PayoutDetails";
import WithdrawDetail from "../components/Withdraws/WithdrawDetail";
import EventsDetail from "../components/Events/EventsDetail";
import WebhookAttempt from "../components/Events/WebhookAttempt";
// import Shopify from "../components/ManageApps/Shopify";
import OneQR from "../components/OneQR";
import OneQRDetail from "../components/OneQR/OneQRDetail";
// import PaymentLinks from "../components/PaymentLinks";
import SearchDetails from "../components/SearchDetails";
import Webhook from "./../components/Webhook";
import WebHookDetails from "../components/Webhook/WebHookDetails";
import { useFlags } from "launchdarkly-react-client-sdk";
import CheckoutLinkDetailsEvents from "../components/CheckoutLinks/CheckoutLinkDetailsEvents";
import AllOneQRPayments from "../components/OneQR/AllOneQRPayments.";
import PaymentLinkDetailsEvents from "../components/PaymentLinks/PaymentLinkDetailsEvents";
import PaymentDetailsEvents from "../components/Payments/PaymentDetailsEvents";
import WithdrawDetailsEvents from "../components/Withdraws/WithdrawDetailsEvents";
import CheckoutLinkDetailPayments from "../components/CheckoutLinks/PaymentList";
// import ShopifyOAuth from "../components/ManageApps/ShopifyOAuth";
import Swap from "../components/Swap";
import SwapDetails from "../components/Swap/SwapDetails";
import SessionSharing from "../components/SessionSharing";
import Partners from "../components/Partners";
import InitialSetupGuide from "../components/GetStarted/InitialSetupGuide";
import CheckoutLinkGuide from "../components/GetStarted/CheckoutLinkGuide";
import PaymentLinkGuide from "../components/GetStarted/PaymentLinkGuide";
import OneQRGuide from "../components/GetStarted/OneQRGuide";
import PayoutGuide from "../components/GetStarted/PayoutGuide";
import Cashback from "../components/Cashback";
import CashbackDetails from "../components/Cashback/CashbackDetails";
import WithdrawList from "../components/Cashback/WithdrawList";
import Transfers from "../components/Transfers";
import TransferDetail from "../components/Transfers/TransferDetail";
import PaymentAddress from "../components/PaymentAddress";
import ProductDetail from "../components/Products/ProductDetail";
import PriceDetail from "../components/Products/PriceDetail";
import ProductPricesList from "../components/Products/ProductPricesList";
import Invoices from "../components/Invoices";
import InvoiceDetails from "../components/Invoices/InvoiceDetails";
import InvoicePayments from "../components/Invoices/PaymentList";
import AcceptAffiliatePartnerInvite from "../components/Partners/AcceptAffiliatePartnerInvite";
import JoinAffiliatePartner from "../components/Partners/JoinAffiliatePartner";
import WithdrawalLinkGuide from "../components/GetStarted/WithdrawalLinkGuide";
import SwapsGuide from "../components/GetStarted/SwapsGuide";
import TransferGuide from "../components/GetStarted/TransferGuide";
// import PointOfSaleGuide from "../components/GetStarted/PointOfSaleGuide";
import AffiliatePartnersGuide from "../components/GetStarted/AffiliatePartnersGuide";
import InvoiceGuide from "../components/GetStarted/InvoiceGuide";
import CashbackGuide from "../components/GetStarted/CashbackGuide";
import Standard from "../components/Developers/Standard";
import Restricted from "../components/Developers/Restricted";
import RestrictedKeyDetails from "../components/Developers/RestrictedKeyDetails";
import InvoiceDetailsEvents from "../components/Invoices/InvoiceDetailsEvents";
import OnBoarding from "../components/OnBoarding";
import PaymentDetailTransfers from "../components/Payments/PaymentDetailTransfers";
import WalletApp from "../components/WalletApp";
import PaymentAddressDetails from "../components/PaymentAddress/PaymentAddressDetails";
import PaymentAddressPaymentList from "../components/PaymentAddress/PaymentAddressPaymentList";
import Customer from "../components/Customer";
import CustomerDetails from "../components/Customer/CustomerDetails";
import CustomersGuide from "../components/GetStarted/CustomersGuide";
import ProductsGuide from "../components/GetStarted/ProductsGuide";
import InvoiceDetailProducts from "../components/Invoices/InvoiceDetailProducts";
import PaymentAddressGuide from "../components/GetStarted/PaymentAddressGuide";
import TransferDetailsEvents from "../components/Transfers/TransferDetailsEvents";
import VerifyPayoutWallet from "../components/Settings/PayoutWallets/VerifyPayoutWallet";
import ConnectAccounts from "../components/Connect/ConnectAccounts";
import ConnectAccountDetails from "../components/Connect/ConnectAccounts/ConnectAccountDetails";
import ConnectGuide from "../components/GetStarted/ConnectGuide";
import MyAssets from "../components/MyAssets";
import AllAssets from "../components/AllAssets";
import WalletTransaction from "../components/WalletTransaction";
import AssetsDetail from "../components/AllAssets/AssetsDetail";
import PaymentSuccessful from "../components/MyAssets/PaymentSuccessful";
import SocialMediaAuth from "../components/SignUp/SocialMediaAuth";
import CreateUserAccount from "../components/SignIn/CreateUserAccount";
import JoinInvitedUserAccount from "../components/SignIn/JoinInvitedUserAccount";
import WebhookAttemptDetails from "../components/Events/WebhookAttemptDetails";
import Sessions from "../components/Sessions";
import SessionsDetails from "../components/Sessions/SessionsDetails";
import SessionDetailsEvents from "../components/Sessions/SessionDetailsEvents";
import SessionDetailsPaymentList from "../components/Sessions/SessionDetailsPaymentList";
import SessionsGuide from "../components/GetStarted/SessionsGuide";
import OneClickIntegration from "../components/OneClickIntegration";
import OneClickIntegrationConnect from "../components/OneClickIntegration/OneClickIntegrationConnect";
import PaymentAddressDetailsEvents from "../components/PaymentAddress/PaymentAddressDetailsEvents";
import BuyBitcoin from "../components/BuyBitcoin";
import InstantSend from "../components/InstantSend";
import InstantSendDetail from "../components/InstantSend/InstantSendDetail";
import SelectCountry from "../components/MyAssets/SelectCountry";
import InstantSendGuide from "../components/GetStarted/InstantSendGuide";
import UnderMaintenance from "../components/Common/UnderMaintenance";
import WLAllFilesImportList from "../components/WithdrawalLinks/WLAllFilesImportList";
import WLSingleFilesImportList from "../components/WithdrawalLinks/WLSingleFilesImportList";

const Main = () => {
  const { gserBar } = useFlags();

  return (
    <Switch>
      <Redirect exact from="/" to="/login" />
      <PublicRoute exact path={["/", "/login"]} component={SignIn} />
      <PublicRoute exact path="/register" component={SignUp} />
      <PublicRoute exact path="/google-auth" component={SocialMediaAuth} />
      <PublicRoute exact path="/apple-auth" component={SocialMediaAuth} />
      <PublicRoute exact path="/create-account" component={CreateUserAccount} />
      <PublicRoute exact path="/join-team" component={JoinInvitedUserAccount} />
      <PublicRoute
        exact
        path="/partners/affiliate-partners/join"
        component={JoinAffiliatePartner}
      />
      {/* <PublicRoute exact path="/magic-link" component={EmailSent} /> */}
      <PublicRoute exact path="/reset-password" component={ResetPassword} />
      {/* <Redirect exact from="/apikeys" to="/apikeys/standard-keys" /> */}
      {/* <PublicRoute exact path="/reset-email" component={EmailSent} /> */}
      <Route exact path="/two-factor" component={TwoFactorAuthentication} />
      <Route exact path="/two-factor-setup" component={Setup2FA} />
      <Redirect exact from="/settings" to="/settings/profile" />
      {gserBar && (
        <PrivateRoute exact path="/search" component={SearchDetails} />
      )}
      <PrivateRoute exact path="/login-success" component={SessionSharing} />
      <PrivateRoute exact path="/dashboard" component={Dashboard} />
      <PrivateRoute exact path="/checkout-links" component={CheckoutLinks} />
      <PrivateRoute
        exact
        path="/checkout-links/:id"
        component={CheckoutLinkDetails}
      />
      <PrivateRoute
        exact
        path="/checkout-links/:id/payments"
        component={CheckoutLinkDetailPayments}
      />
      <PrivateRoute
        exact
        path="/checkout-links/:id/events"
        component={CheckoutLinkDetailsEvents}
      />
      <PrivateRoute exact path="/payment-links" component={PaymentLinks} />
      <PrivateRoute
        exact
        path="/payment-links/:id"
        component={PaymentLinkDetails}
      />
      <PrivateRoute
        exact
        path="/payment-links/:id/payments"
        component={PaymentList}
      />
      <PrivateRoute
        exact
        path="/payment-links/:id/events"
        component={PaymentLinkDetailsEvents}
      />
      <PrivateRoute
        exact
        path="/payment-addresses"
        component={PaymentAddress}
      />
      <PrivateRoute
        exact
        path="/payment-addresses/:id"
        component={PaymentAddressDetails}
      />
      <PrivateRoute
        exact
        path="/payment-addresses/:id/events"
        component={PaymentAddressDetailsEvents}
      />
      <PrivateRoute
        exact
        path="/payment-addresses/:id/payments"
        component={PaymentAddressPaymentList}
      />
      <PrivateRoute exact path="/invoices" component={Invoices} />
      <PrivateRoute exact path="/invoices/:id" component={InvoiceDetails} />
      <PrivateRoute
        exact
        path="/invoices/:id/payments"
        component={InvoicePayments}
      />
      <PrivateRoute
        exact
        path="/invoices/:id/products"
        component={InvoiceDetailProducts}
      />
      <PrivateRoute
        exact
        path="/invoices/:id/events"
        component={InvoiceDetailsEvents}
      />
      <PrivateRoute exact path="/balances" component={Balances} />
      <PrivateRoute
        exact
        path="/withdrawal-links"
        component={WithdrawalLinks}
      />
      <PrivateRoute
        exact
        path="/withdrawal-links/import"
        component={WLAllFilesImportList}
      />
      <PrivateRoute
        exact
        path="/withdrawal-links/import/:id"
        component={WLSingleFilesImportList}
      />
      <PrivateRoute
        exact
        path="/withdrawal-links/:id"
        component={WithdrawalLinkDetails}
      />
      <PrivateRoute exact path="/payouts" component={Payouts} />
      <PrivateRoute exact path="/cashbacks" component={Cashback} />
      <PrivateRoute exact path="/cashbacks/:id" component={CashbackDetails} />
      <PrivateRoute
        exact
        path="/cashbacks/:id/withdraws"
        component={WithdrawList}
      />
      <PrivateRoute exact path="/payouts/:id" component={PayoutsDetails} />
      <PrivateRoute exact path="/swaps" component={Swap} />
      <PrivateRoute exact path="/swaps/:id" component={SwapDetails} />
      <PrivateRoute exact path="/transfers" component={Transfers} />
      <PrivateRoute exact path="/transfers/:id" component={TransferDetail} />
      <PrivateRoute
        exact
        path="/transfers/:id/events"
        component={TransferDetailsEvents}
      />
      <PrivateRoute exact path="/connect" component={ConnectAccounts} />
      <PrivateRoute
        exact
        path="/connect/:id"
        component={ConnectAccountDetails}
      />
      <PrivateRoute exact path="/products" component={Products} />
      <PrivateRoute exact path="/products/:id" component={ProductDetail} />
      <PrivateRoute
        exact
        path="/products/:id/prices"
        component={ProductPricesList}
      />
      <PrivateRoute exact path="/prices/:id" component={PriceDetail} />
      <PrivateRoute exact path="/payments" component={Payments} />
      <PrivateRoute exact path="/withdraws" component={Withdraws} />
      <PrivateRoute exact path="/payments/:id" component={PaymentsDetail} />
      <PrivateRoute
        exact
        path="/payments/:id/events"
        component={PaymentDetailsEvents}
      />
      <PrivateRoute
        exact
        path="/payments/:id/payment-transfers"
        component={PaymentDetailTransfers}
      />
      <PrivateRoute exact path="/withdraws/:id" component={WithdrawDetail} />
      <PrivateRoute
        exact
        path="/withdraws/:id/events"
        component={WithdrawDetailsEvents}
      />
      <PrivateRoute exact path="/transactions" component={Transactions} />
      <PrivateRoute exact path="/customers" component={Customer} />
      <PrivateRoute exact path="/customers/:id" component={CustomerDetails} />
      <PrivateRoute exact path="/sessions" component={Sessions} />
      <PrivateRoute exact path="/sessions/:id" component={SessionsDetails} />
      <PrivateRoute
        exact
        path="/sessions/:id/cs-events"
        component={SessionDetailsEvents}
      />
      <PrivateRoute
        exact
        path="/sessions/:id/payments"
        component={SessionDetailsPaymentList}
      />
      <PrivateRoute exact path="/webhooks" component={Webhook} />
      <PrivateRoute exact path="/webhooks/:id" component={WebHookDetails} />
      <PrivateRoute exact path="/logs" component={Logs} />
      <PrivateRoute exact path="/events" component={Events} />
      <PrivateRoute exact path="/events/:id" component={EventsDetail} />
      <PrivateRoute
        exact
        path="/events/:id/webhook-attempts"
        component={WebhookAttempt}
      />
      <PrivateRoute
        exact
        path="/events/:id/webhook-attempts/:attempt_id"
        component={WebhookAttemptDetails}
      />
      <PrivateRoute exact path="/logs/:id" component={LogDetails} />
      <PrivateRoute exact path="/auth/callback" component={Auth} />
      <PrivateRoute exact path="/apikeys" component={Standard} />
      <PrivateRoute
        exact
        path="/apikeys/restricted-keys"
        component={Restricted}
      />
      <PrivateRoute
        exact
        path="/apikeys/restricted-keys/:id"
        component={RestrictedKeyDetails}
      />
      <PrivateRoute
        path="/settings"
        component={Settings}
        isWalletAllowedRoute={true}
      />
      <PrivateRoute
        path="/partners"
        component={Partners}
        isWalletAllowedRoute={true}
      />
      <PrivateRoute exact path="/instant-sends" component={InstantSend} />
      <PrivateRoute
        exact
        path="/instant-sends/:id"
        component={InstantSendDetail}
      />
      {/* <PrivateRoute exact path="/manage-apps" component={ManageApps} /> */}
      {/* <PrivateRoute path="/oauth/request_grant" component={ShopifyOAuth} /> */}
      {/* <PrivateRoute path="/manage-apps/shopify/:step" component={Shopify} /> */}
      <PrivateRoute exact path="/one-qr" component={OneQR} />
      <PrivateRoute exact path="/one-qr/:id" component={OneQRDetail} />
      <PrivateRoute
        exact
        path="/one-qr/:id/payments"
        component={AllOneQRPayments}
      />
      <PrivateRoute exact path="/documentation" component={Documentation} />
      <PrivateRoute
        exact
        path="/initial-setup-guide"
        isGuideUrl={true}
        component={InitialSetupGuide}
      />
      <PrivateRoute
        exact
        path="/checkout-link-guide"
        isGuideUrl={true}
        component={CheckoutLinkGuide}
      />
      <PrivateRoute
        exact
        path="/payment-link-guide"
        isGuideUrl={true}
        component={PaymentLinkGuide}
      />
      <PrivateRoute
        exact
        path="/oneqr-guide"
        isGuideUrl={true}
        component={OneQRGuide}
      />
      <PrivateRoute
        exact
        path="/payout-guide"
        isGuideUrl={true}
        component={PayoutGuide}
      />
      <PrivateRoute
        exact
        path="/swaps-guide"
        isGuideUrl={true}
        component={SwapsGuide}
      />
      <PrivateRoute
        exact
        path="/transfer-guide"
        isGuideUrl={true}
        component={TransferGuide}
      />
      <PrivateRoute
        exact
        path="/withdrawal-link-guide"
        isGuideUrl={true}
        component={WithdrawalLinkGuide}
      />
      {/* <PrivateRoute
        exact
        path="/point-of-sale-guide"
        isGuideUrl={true}
        component={PointOfSaleGuide}
      /> */}
      <PrivateRoute
        exact
        path="/affiliate-partners-guide"
        isGuideUrl={true}
        component={AffiliatePartnersGuide}
      />
      <PrivateRoute
        exact
        path="/cashback-guide"
        isGuideUrl={true}
        component={CashbackGuide}
      />
      <PrivateRoute
        exact
        path="/invoice-guide"
        isGuideUrl={true}
        component={InvoiceGuide}
      />
      <PrivateRoute
        exact
        path="/customers-guide"
        isGuideUrl={true}
        component={CustomersGuide}
      />
      <PrivateRoute
        exact
        path="/products-guide"
        isGuideUrl={true}
        component={ProductsGuide}
      />
      <PrivateRoute
        exact
        path="/payment-address-guide"
        isGuideUrl={true}
        component={PaymentAddressGuide}
      />
      <PrivateRoute
        exact
        path="/connect-guide"
        isGuideUrl={true}
        component={ConnectGuide}
      />
      <PrivateRoute
        exact
        path="/session-guide"
        isGuideUrl={true}
        component={SessionsGuide}
      />
      <PrivateRoute
        exact
        path="/instant-send-guide"
        isGuideUrl={true}
        component={InstantSendGuide}
      />
      {/* <PrivateRoute
        exact
        path="/speed-point-of-sale"
        component={PointOfSaleGuide}
      /> */}
      {/* Wallet app routes */}
      <PrivateRoute
        exact
        path="/select-country"
        component={SelectCountry}
        isWalletAllowedRoute={true}
      />
      <PrivateRoute
        exact
        path="/download"
        component={WalletApp}
        isWalletAllowedRoute={true}
      />
      <PrivateRoute
        exact
        path="/my-assets"
        component={MyAssets}
        isWalletAllowedRoute={true}
      />
      <PrivateRoute
        exact
        path="/assets"
        component={AllAssets}
        isWalletAllowedRoute={true}
      />
      <PrivateRoute
        exact
        path="/buy-bitcoin"
        component={BuyBitcoin}
        isWalletAllowedRoute={true}
      />
      <PrivateRoute
        exact
        path="/assets/:id"
        component={AssetsDetail}
        isWalletAllowedRoute={true}
      />
      <PrivateRoute
        exact
        path="/wallet-transactions"
        component={WalletTransaction}
        isWalletAllowedRoute={true}
      />
      <PrivateRoute
        exact
        path="/success-page"
        component={PaymentSuccessful}
        isWalletAllowedRoute={true}
      />
      <PrivateRoute
        exact
        path="/under-maintenance"
        component={UnderMaintenance}
        isWalletAllowedRoute={true}
      />
      <Route path="/new-password" component={NewPassword} />
      <Route exact path="/onboarding" component={OnBoarding} />
      <Route path="/accept-invite" component={AcceptInvite} />
      <Route
        exact
        path="/affiliate-partners/accept-invite"
        component={AcceptAffiliatePartnerInvite}
      />
      <Route
        exact
        path="/verify-payout-wallet"
        component={VerifyPayoutWallet}
      />
      <Route exact path="/verify-email" component={VerifyEmail} />
      <Route exact path="/confirm-email" component={ConfirmEmail} />
      <Route exact path="/authorize" component={OneClickIntegration} />
      <PrivateRoute
        exact
        path="/authorize/connect"
        component={OneClickIntegrationConnect}
        isWalletAllowedRoute={true}
      />
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default Main;
