import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import React, { useState, createRef, useEffect } from "react";
import Text from "@speed/common/src/components/Text/Text";
import { Button } from "@speed/common/src/components/Button/Button";
import {
  createNew,
  paymentLinkCreate,
  testModeText,
  testModeTitle,
} from "../messages";
import TestModeHeader from "../Payments/TestModeHeader";
import { useSelector, useDispatch } from "react-redux";
import {
  setPaymentRedirectWebPreview,
  setPaymentConfirmationOption,
  updatePaymentMessage,
  setIsModalOpen,
  updatePaymentInfo,
} from "../../redux/common/actions";
import "../../assets/styles/payment-links.scss";
import PaymentLinkTable from "../Common/PaymentLinkTable";
import { callAPIInterface } from "../constants";
import { back, next } from "@speed/common/src/components/messages";
import { useFlags } from "launchdarkly-react-client-sdk";
import PaymentLinkModal from "./PaymentLinkModal";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

// Payment links table
const LinksList = (props) => {
  const ref = createRef();
  const confirmRef = createRef();
  const [disabled, setDisabled] = useState(true);
  const [isPaymentLink, setIsPaymentLink] = useState(false);
  const [formDataValue, setFormDataValue] = useState(null);
  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const [disableBackButton, setDisableBackButton] = useState(false);
  const {
    paymentConfirmationOption,
    isDataAvailable,
    history,
    isModalOpen,
    caughtErrorInStrapi,
  } = useSelector((state) => state.common);
  const dispatch = useDispatch();

  const liveMode = useSelector((state) => state.auth.liveMode);

  const { plConfirmpaymentlink } = useFlags();

  useEffect(() => {
    if (!plConfirmpaymentlink) {
      setIsPaymentLink(false);
      dispatch(setPaymentRedirectWebPreview(false));
    }
  }, [plConfirmpaymentlink]);

  const handleNext = () => {
    setFormDataValue(ref.current && ref.current.handleSubmit());
    setIsPaymentLink(true);
    if (paymentConfirmationOption == "redirect") {
      dispatch(setPaymentRedirectWebPreview(true));
    }
  };

  const handleClose = () => {
    setIsPaymentLink(false);
    dispatch(setIsModalOpen(false));
    dispatch(updatePaymentMessage(""));
    dispatch(setPaymentRedirectWebPreview(false));
    dispatch(setPaymentConfirmationOption("confirmation"));
  };

  const handleBackButton = () => {
    setIsPaymentLink(false);
    setDisabled(false);
    dispatch(setPaymentRedirectWebPreview(false));
  };

  const handleSubmit = () => {
    // return the values of form while submitting the form
    const confirmData =
      confirmRef.current && confirmRef.current.handleSubmitConfirm();

    const formData = ref.current && ref.current.handleSubmit();

    let paymentLinkData = null;

    if (plConfirmpaymentlink) {
      paymentLinkData = {
        ...formDataValue,
        ...confirmData,
      };
    } else {
      paymentLinkData = {
        ...formData,
      };
    }

    if (paymentLinkData) {
      if (plConfirmpaymentlink) {
        setDisableCreateButton(true);
        setDisableBackButton(true);
      } else setDisabled(true);
      callAPIInterface(
        "POST",
        "/payment-links",
        JSON.stringify(paymentLinkData)
      )
        .then((response) => {
          response && history.push(`/payment-links/${response.id}`);
          dispatch(setPaymentConfirmationOption("confirmation"));
          dispatch(setPaymentRedirectWebPreview(false));
          dispatch(updatePaymentMessage(""));
          setTimeout(() => dispatch(updatePaymentInfo(null)), 100);
        })
        .catch((_error) => {
          if (plConfirmpaymentlink) {
            setDisableCreateButton(false);
            setDisableBackButton(false);
          } else setDisabled(false);
        });
    }
  };

  const renderCreateConfirmButtons = () => {
    return !isPaymentLink ? (
      <Button
        label={next}
        variant="contained"
        color="primary"
        className="payment-link-btn"
        disabled={disabled}
        onClick={handleNext}
      />
    ) : (
      <React.Fragment>
        <Button
          variant="outlined"
          color="primary"
          label={back}
          onClick={handleBackButton}
          sx={{ marginRight: "18px" }}
          disabled={disableBackButton}
        ></Button>
        <Button
          label={paymentLinkCreate}
          variant="contained"
          color="primary"
          className="payment-link-btn"
          onClick={handleSubmit}
          disabled={disableCreateButton}
        />
      </React.Fragment>
    );
  };

  const renderCreateLinkButton = () => {
    return (
      <Button
        label={paymentLinkCreate}
        variant="contained"
        color="primary"
        className="payment-link-btn"
        onClick={handleSubmit}
        disabled={disabled}
      />
    );
  };

  const createNewLinkButton = () => {
    return (
      <Box className="action-btn-wrapper mui-fixed">
        <Button
          icon="addIcon"
          className={`add-icon`}
          label={createNew}
          variant="outlined"
          color="primary"
          onClick={() => {
            dispatch(setIsModalOpen(true));
          }}
        />
      </Box>
    );
  };

  return (
    <>
      {isDataAvailable
        ? createNewLinkButton()
        : ((!isDataAvailable && props.tab) || caughtErrorInStrapi) &&
          createNewLinkButton()}
      <PaymentLinkTable tab={props.tab} />
      <Dialog
        disableEscapeKeyDown
        fullScreen
        open={isModalOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        className="fullscreen-modal"
      >
        {!liveMode && (
          <TestModeHeader
            buttonText={testModeTitle}
            captionText={testModeText}
          />
        )}
        <AppBar
          sx={{ position: "relative", zIndex: 0 }}
          className="modal-app-bar"
        >
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Text
              size={20}
              font="semibold"
              sx={{ flex: 1 }}
              className="default-text divider"
              variant="h6"
            >
              {paymentLinkCreate}
            </Text>
            {plConfirmpaymentlink
              ? renderCreateConfirmButtons()
              : renderCreateLinkButton()}
            {/* <Button
              label={paymentLinkCreate}
              variant="contained"
              color="primary"
              className="payment-link-btn"
              onClick={handleSubmit}
              disabled={disabled}
            /> */}
          </Toolbar>
        </AppBar>
        <Box className="fullscreen-modal-box">
          <PaymentLinkModal
            ref={ref}
            confirmRef={confirmRef}
            handleButtonDisable={(value) => setDisabled(value)}
            isPaymentLink={isPaymentLink}
            setDisableCreateButton={setDisableCreateButton}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default LinksList;
