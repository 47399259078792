import { useSelector } from "react-redux";
import { createCheckoutLinkMsg, emptyCheckoutLinkSubText } from "../messages";
import { emptySubText } from "@speed/common/src/components/messages";
import { emptyCheckoutLink } from "../images";
import PaymentAndCheckoutLinkTable from "./PaymentAndCheckoutLinkTable";

const tableHeader = [
  { title: "Links", width: "446px", paddingLeft: "38px !important" },
  { title: "Description", width: "245px" },
  { title: "Amount", width: "230px", align: "right" },
  // { title: "Price (BTC)", width: '160px', align: 'right' },
  { title: "Created on", width: "235px" },
  { title: "Status", width: "138px" },
  { title: "Actions", width: "25px" },
];

const CheckoutLinkTable = ({
  tab,
  showHorizontalTable = false,
  searchQueryUrl,
  inputString,
  searchResultData,
  getModuleSearchResultData,
  totalNumOfSearchResults,
}) => {
  const liveMode = useSelector((state) => state.auth.liveMode);

  return (
    <PaymentAndCheckoutLinkTable
      moduleName="checkout_link"
      tableHeader={tableHeader}
      linkPath="checkout-links"
      tab={tab}
      showHorizontalTable={showHorizontalTable}
      getModuleSearchResultData={getModuleSearchResultData}
      searchQueryUrl={searchQueryUrl}
      inputString={inputString}
      searchResultData={searchResultData}
      totalNumOfSearchResults={totalNumOfSearchResults}
      textOnNoData={emptySubText(liveMode, "checkout link", tab)}
      subTextOnNoData={emptyCheckoutLinkSubText}
      noDataImage={emptyCheckoutLink}
      createButtonText={createCheckoutLinkMsg}
    />
  );
};

export default CheckoutLinkTable;
