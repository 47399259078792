import { Grid } from "@mui/material";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import Text from "@speed/common/src/components/Text/Text";
import { ccdDNSName, ccdDNSValue } from "../../messages";

const RecordValues = ({ keyName, keyValue }) => {
  return (
    <Grid container marginTop="15px">
      <Grid item lg={2}>
        <Text
          font="regular"
          size={16}
          variant="subtitle1"
          className="grey-text"
        >
          {keyName}
        </Text>
      </Grid>
      <Grid
        item
        lg={10}
        className="content-center"
        justifyContent="space-between !important"
      >
        <Text
          font="regular"
          size={16}
          variant="subtitle1"
          className="default-text"
          sx={{ maxWidth: "296px", wordBreak: "break-word" }}
        >
          {keyValue}
        </Text>
        {(keyName === ccdDNSName || keyName === ccdDNSValue) && (
          <Clipboard size={16} text={keyValue} />
        )}
      </Grid>
    </Grid>
  );
};

export default RecordValues;
