import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, MenuItem, TableCell, TableRow } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import * as yup from "yup";
import WarningIcon from "@mui/icons-material/Warning";
import { useFormik } from "formik";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import Button from "@speed/common/src/components/Button/Button";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import Text from "@speed/common/src/components/Text/Text";
import {
  generateBreadcrumbs,
  integerWithCurrency,
  linkStatus,
  maxSat,
  maxUsdt,
  redirectWhenEntityNotFound,
  showAmount,
} from "@speed/common/src/components/constants";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import InputMask from "@speed/common/src/components/MaskInput";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import { cancel } from "@speed/common/src/components/messages";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import {
  accountID,
  accountOwnerEmail,
  amountExceedPartnerFee,
  commissionDetail,
  changeCommission,
  commissionWithPercentage,
  details,
  endDate,
  enterAmountPlaceholder,
  entityNotFoundMessage,
  fixedFee,
  latestTransfersIn,
  partnerCommissionPercentageValidation,
  selectCurrencyLabel,
  startDate,
  status,
  subtitleForIdNotFound,
  totalCommissionEarned,
  usdt,
  viewPartners,
} from "../messages";
import {
  RouteBreadcrumbs,
  callAPIInterface,
  dateFromTimestampInApp,
  dateTimeFormatInApp,
  getFixedFeeAmountByCurrency,
  maximumCommissionAmountForAffiliatePartner,
  minimumCommissionAmountForAffiliatePartner,
  renderNeedHelpWithContactUs,
} from "../constants";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import { KeyValueGrid } from "@speed/common/src/components/KeyValueGrid";
import DetailPagePaymentSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import LinkSkeleton from "@speed/common/src/components/Skeleton/LinkSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";
import { save } from "@speed/common/src/messages";
import CustomSelect from "@speed/common/src/components/Select/Select";

const MerchantDetail = (props) => {
  const {
    aflGetlistofinvitedmemberFe,
    aflGetmemberdetailbyidFe,
    aflUpdatememberbyidFe,
    aflListoftransferlnsFe,
  } = useFlags();
  const commissionRef = useRef(null);
  const commissionFixedAmountRef = useRef(null);
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [data, setData] = useState(null);
  const [transferSkeleton, setTransferSkeleton] = useState(false);
  const [transferList, setTransferList] = useState([]);
  const [openCommissionModal, setOpenCommissionModal] = useState(false);
  const [currencyOptions, setCurrencyOptions] = useState([]);
  const [initialValues, setInitialValues] = useState();

  const urlParams = useParams();
  const { history, accountAsset } = useSelector((state) => state.common);
  const liveMode = useSelector((state) => state.auth.liveMode);
  const loadDetailPage = () => {
    setData(null);
    setIsShowNotFound(false);
    getPartnerDetail();
  };

  const getTransferList = () => {
    callAPIInterface("GET", `/affiliate-partner/${urlParams?.id}/transfers`)
      .then((res) => {
        if (res) {
          setTransferSkeleton(false);
          setTransferList(res?.data);
        }
      })
      .catch(() => {
        setTransferSkeleton(false);
      });
  };

  const getPartnerDetail = () => {
    setTransferSkeleton(true);
    callAPIInterface("GET", `/affiliate-partner/${urlParams?.id}`)
      .then((res) => {
        if (res) {
          aflListoftransferlnsFe
            ? getTransferList()
            : setTransferSkeleton(false);
          setData(res);
        }
      })
      .catch((err) => {
        setTransferSkeleton(false);
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const setInitialValueByCurrency = (data, currency = "SATS") => {
    const { amount, percentage } = getFixedFeeAmountByCurrency({
      feesArray: data?.fees,
      targetCurrency: currency,
    });

    return {
      currency,
      amount: percentage?.toString() || "",
      fixed_amount: amount ?? "",
    };
  };

  useEffect(() => {
    const currencyList = accountAsset?.map((assetData) => ({
      value: assetData.uuid,
    }));
    setCurrencyOptions(currencyList);
    resetFormikForm();
  }, [data]);

  useEffect(
    () =>
      aflGetlistofinvitedmemberFe &&
      aflGetmemberdetailbyidFe &&
      loadDetailPage(),
    [aflGetlistofinvitedmemberFe, aflGetmemberdetailbyidFe, liveMode]
  );

  const validationMessageForAmount = partnerCommissionPercentageValidation(
    minimumCommissionAmountForAffiliatePartner,
    maximumCommissionAmountForAffiliatePartner
  );

  const validationSchema = yup.object({
    amount: yup
      .number()
      .min(
        minimumCommissionAmountForAffiliatePartner,
        validationMessageForAmount
      )
      .max(
        maximumCommissionAmountForAffiliatePartner,
        validationMessageForAmount
      ),
    fixed_amount: yup.number().when(["currency"], {
      is: (currency) => currency === "SATS",
      then: yup.number().max(maxSat, amountExceedPartnerFee()),
      otherwise: yup.number().max(maxUsdt, amountExceedPartnerFee("USDT")),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (value) => {
      const { currency, amount, fixed_amount } = { ...value };
      const commissionData = {
        fees: [
          {
            target_currency: currency,
            commission: {
              percentage: amount || 0,
              fixed_amount: showAmount({
                currency,
                amount: fixed_amount || 0,
                divideAmount: currency === usdt,
              }),
            },
          },
        ],
      };
      callAPIInterface(
        "POST",
        `/affiliate-partner/${urlParams?.id}`,
        JSON.stringify(commissionData)
      )
        .then(() => {
          loadDetailPage();
          closeModal();
        })
        .catch(() => {
          closeModal();
        });
    },
  });

  const {
    values,
    setFieldValue,
    resetForm,
    touched,
    errors,
    setTouched,
    isValid,
    dirty,
    handleSubmit,
    isSubmitting,
  } = formik;

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const headerContent = () => {
    return (
      <Box className="header-content">
        <Text className="default-text" size={24}>
          {data?.account_name}
        </Text>
      </Box>
    );
  };

  const transferColumnData = [
    { title: "Transfer ID", width: "520px" },
    { title: "Amount", width: "175px", align: "right" },
    { title: "Date", width: "270px" },
  ];

  const rows = transferList?.map((rowItem) => (
    <TableRow
      key={rowItem.id}
      className="clickable"
      onClick={() => history.push(`/transfers/${rowItem?.id}`)}
    >
      <TableCell>{rowItem?.id}</TableCell>
      <TableCell>
        <Box display="flex" justifyContent="end" gap={0.6}>
          {integerWithCurrency(rowItem?.currency, rowItem?.amount)}
        </Box>
      </TableCell>
      <TableCell>{dateTimeFormatInApp(rowItem?.created)}</TableCell>
    </TableRow>
  ));

  const commissionColumnData = [
    { title: "Currency", width: "400px" },
    { title: "Commission(%)", width: "250px" },
    { title: "Commission(Fixed Fee)", width: "270px" },
  ];

  const commissionRows = data?.fees?.map((rowItem) => (
    <TableRow key={rowItem.target_currency}>
      <TableCell>{rowItem?.target_currency}</TableCell>
      <TableCell>
        {rowItem?.percentage ? rowItem.percentage + "%" : "-"}
      </TableCell>
      <TableCell>
        {rowItem?.fixed_amount
          ? showAmount({
              amount: rowItem.fixed_amount,
              currency: rowItem.target_currency,
            })
          : "-"}
      </TableCell>
    </TableRow>
  ));

  const commissionEarnedColumnData = [
    { title: "Currency", width: "400px" },
    { title: "Total commission earned", width: "250px" },
  ];

  const commissionEarnedRows = data?.total_commission_earned?.map((rowItem) => (
    <TableRow key={rowItem.target_currency}>
      <TableCell>{rowItem.target_currency}</TableCell>
      <TableCell>
        {rowItem?.commission_earned
          ? showAmount({
              amount: rowItem.commission_earned,
              currency: rowItem.target_currency,
            })
          : "0"}
      </TableCell>
    </TableRow>
  ));

  const handleChangeAmount = (e, ref, name) => {
    setTouched({ ...touched, name: false });
    ref.current.amount = e.target.value;
    setFieldValue(name, e.target.value);
  };

  const commissionModalContent = () => (
    <>
      <CustomSelect
        disabled={!currencyOptions?.length}
        name="currency"
        displayEmpty
        showLabel
        label={selectCurrencyLabel}
        value={values?.currency}
        placeholder={selectCurrencyLabel}
        MenuProps={{
          id: "currency-select",
        }}
        onChange={(e) => {
          setFieldValue("currency", e.target.value);
          setInitialValues(setInitialValueByCurrency(data, e.target.value));
        }}
        renderValue={(value) => {
          return value || selectCurrencyLabel;
        }}
      >
        {currencyOptions?.map((option) => (
          <MenuItem key={option.value} value={option?.value}>
            {option?.value}
          </MenuItem>
        ))}
      </CustomSelect>
      <Box className="margin-top30" mt={0.75}>
        <InputMask
          label={commissionWithPercentage}
          onChange={(e) => handleChangeAmount(e, commissionRef, "amount")}
          ref={commissionRef}
          value={values?.amount}
          error={errors?.amount && Boolean(errors.amount)}
          onBlur={() => setTouched({ ...touched, amount: true })}
          placeholder={enterAmountPlaceholder}
          numOfAllowedDigits={2}
        />
        <InputErrorMessage {...defaultErrorMessageProps} inputName="amount" />
      </Box>
      <Box className="margin-top30" mb={2}>
        <InputMask
          label={fixedFee}
          onChange={(e) =>
            handleChangeAmount(e, commissionFixedAmountRef, "fixed_amount")
          }
          ref={commissionFixedAmountRef}
          value={values?.fixed_amount}
          error={errors?.fixed_amount && Boolean(errors.fixed_amount)}
          onBlur={() => setTouched({ ...touched, fixed_amount: true })}
          placeholder={enterAmountPlaceholder}
          allowDecimal={values?.currency === usdt}
        />
        <InputErrorMessage
          {...defaultErrorMessageProps}
          inputName="fixed_amount"
        />
      </Box>
    </>
  );

  const commissionModalFooter = () => (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box>
        <Button
          variant="outlined"
          style={{ width: "80px" }}
          label={cancel}
          onClick={() => resetFormikForm()}
          disabled={isSubmitting}
        />
        <Button
          style={{ width: "80px", marginLeft: "20px" }}
          label={save}
          onClick={handleSubmit}
          disabled={!(isValid && dirty) || isSubmitting}
        />
      </Box>
      {renderNeedHelpWithContactUs()}
    </Box>
  );

  const resetFormikForm = () => {
    setInitialValues(setInitialValueByCurrency(data, "SATS"));
    resetForm();
  };
  const closeModal = () => {
    setOpenCommissionModal(false);
    resetFormikForm();
  };

  const detailKeyValueData = [
    {
      key: status,
      value: (
        <Tag
          text={linkStatus[data?.status?.toLowerCase()]?.label}
          variant={linkStatus[data?.status?.toLowerCase()]?.variant}
        />
      ),
    },
    {
      key: accountID,
      value: data?.account_id,
    },
    {
      key: startDate,
      value: dateFromTimestampInApp(data?.start_date),
    },
    {
      key: accountOwnerEmail,
      value: data?.owner_email,
    },
    {
      key: endDate,
      value: dateFromTimestampInApp(data?.end_date),
    },
  ];

  return !isShowNotFound ? (
    <Grid
      className="payment-link-detail-wrapper merchant-detail-wrapper"
      container
      spacing={1}
    >
      <Box className="section-wrapper" sx={{ position: "relative" }}>
        {data && (
          <Box className="action-btn-wrapper">
            {aflGetlistofinvitedmemberFe &&
              aflGetmemberdetailbyidFe &&
              aflUpdatememberbyidFe &&
              data?.status?.toLowerCase() === linkStatus.active.value && (
                <Button
                  icon="editIcon"
                  label={changeCommission}
                  variant="outlined"
                  onClick={() => setOpenCommissionModal(true)}
                />
              )}
          </Box>
        )}
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        {aflGetlistofinvitedmemberFe && aflGetmemberdetailbyidFe ? (
          <>
            {data ? (
              <>
                {headerContent()}
                <Box className="details-content">
                  <Text size={20} className="default-text">
                    {details}
                  </Text>
                </Box>
                <CustomDivider sx={{ my: "12px" }} />
                <Box
                  gap={2.75}
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-between"
                >
                  {detailKeyValueData?.map((detailData) => (
                    <KeyValueGrid
                      key={detailData?.key}
                      keyName={detailData?.key}
                      value={detailData?.value}
                      keyNameCellWidth={6}
                      valueCellWidth={6}
                      preventTextOverflow
                    />
                  ))}
                </Box>
                {aflUpdatememberbyidFe && (
                  <Modal
                    maxWidth="sm"
                    body={commissionModalContent()}
                    footer={commissionModalFooter()}
                    handleClose={closeModal}
                    open={openCommissionModal}
                    title={changeCommission}
                  />
                )}
              </>
            ) : (
              <LinkSkeleton props={props} />
            )}
            {data ? (
              <Box className="product-content">
                <HorizontalTable
                  label={commissionDetail}
                  isColumnShown={commissionRows.length > 0}
                  isShowButton={commissionRows.length > 3}
                  columns={commissionColumnData}
                  rows={commissionRows}
                  rowsPerPage={2}
                  isShowPagination={false}
                  tableBodyClassName="border-none"
                />
              </Box>
            ) : (
              <DetailPagePaymentSkeleton />
            )}
            {data ? (
              <Box className="product-content">
                <HorizontalTable
                  label={totalCommissionEarned}
                  isColumnShown={commissionEarnedRows.length > 0}
                  isShowButton={commissionEarnedRows.length > 3}
                  columns={commissionEarnedColumnData}
                  rows={commissionEarnedRows}
                  rowsPerPage={2}
                  isShowPagination={false}
                  tableBodyClassName="border-none"
                />
              </Box>
            ) : (
              <DetailPagePaymentSkeleton />
            )}
            {aflListoftransferlnsFe && (
              <>
                {!transferSkeleton ? (
                  <Box className="product-content">
                    <HorizontalTable
                      label={latestTransfersIn}
                      isColumnShown={rows.length > 0}
                      isShowButton={rows.length > 3}
                      columns={transferColumnData}
                      rows={rows}
                      rowsPerPage={3}
                      isShowPagination={false}
                      tableBodyClassName="border-none"
                      handleViewAllClick={() =>
                        history.push(
                          `/partners/affiliate-partners/${data?.id}/transfers`
                        )
                      }
                    />
                  </Box>
                ) : (
                  <DetailPagePaymentSkeleton />
                )}
              </>
            )}
          </>
        ) : (
          <AccessDenied />
        )}
      </Box>
    </Grid>
  ) : (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage("Partner")}
        subTitle={subtitleForIdNotFound("Partner", urlParams.id)}
        btnText={viewPartners}
        redirect={() => history.push("/partners/affiliate-partners")}
      />
    </Box>
  );
};

export default MerchantDetail;
