import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import Text from "@speed/common/src/components/Text/Text";
import {
  accountStatus,
  capitalizeFirstChar,
  getManageRestrictionModulesLists,
} from "@speed/common/src/components/constants";
import { Wallet } from "@speed/common/src/components/messages";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import { accountRestrictionMsg } from "../../messages";
import { callAPIInterface } from "../../constants";
import { Tag } from "@speed/common/src/components/Tag/Tag";

function AccountPermission() {
  const { currentAccount, liveMode } = useSelector((state) => state.auth);
  const isWalletAccount = currentAccount?.account?.account_type === Wallet;
  const staticModuleList = getManageRestrictionModulesLists(!isWalletAccount);

  const [isLoadingList, setIsLoadingList] = useState(true);
  const [restrictionModuleList, setRestrictionModuleList] = useState([]);

  const getUpdatedModuleList = (accountRestrictionList) =>
    staticModuleList?.map((staticModule) => {
      const restrictedModule = accountRestrictionList.find(
        (obj) => obj.module === staticModule.module
      );

      if (restrictedModule) {
        staticModule["is_restricted"] = restrictedModule.is_restricted;
        staticModule["id"] = restrictedModule.id;
        staticModule["error_message"] = restrictedModule.error_message;
        staticModule["reason"] = restrictedModule.reason;
      }

      return staticModule;
    });

  const headerText = () => (
    <Grid container>
      <Text
        className="grey-text"
        sx={{ lineHeight: "26px" }}
        font="regular"
        size={16}
        variant="subtitle1"
      >
        {accountRestrictionMsg[0]}
        <br />
        {accountRestrictionMsg[1]}
      </Text>
    </Grid>
  );

  const renderSelectModuleSection = () => (
    <Grid width="100%">
      {restrictionModuleList?.length > 0 &&
        restrictionModuleList?.map((module, index) => {
          const moduleName =
            liveMode && module?.is_restricted ? "restricted" : "active";
          const isRestrictedModule = moduleName === "restricted";
          const showBorderBottom = !(
            index === restrictionModuleList?.length - 1 && isRestrictedModule
          ) && {
            borderBottom: "1px solid #e4e9ee",
          };
          return (
            <Box
              sx={{
                padding: "24px 0px",
                ...showBorderBottom,
              }}
              key={module?.module}
            >
              <Box
                sx={{
                  alignItems: "center",
                  width: "380px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Text className="default-text" size={18} variant="body1">
                  {module?.name}
                </Text>
                <Tag
                  text={accountStatus?.[moduleName]?.label}
                  variant={accountStatus?.[moduleName]?.variant}
                  sx={
                    !isRestrictedModule && {
                      backgroundColor: "#2A67FF !important",
                    }
                  }
                />
              </Box>
              {isRestrictedModule && (
                <Box
                  sx={{
                    marginTop: "16px",
                    padding: "16px",
                    width: "380px",
                    background: "#FEFBF0",
                  }}
                >
                  <Text className="default-text" size={14} variant="body1">
                    {capitalizeFirstChar(module?.reason?.replace(/_/g, " "))}
                  </Text>
                  <Text
                    className="grey-text"
                    font="regular"
                    size={14}
                    variant="body1"
                  >
                    {module?.error_message}
                  </Text>
                </Box>
              )}
            </Box>
          );
        })}
    </Grid>
  );

  const moduleListLoader = (
    <Box>
      <CommonSkeleton
        width={450}
        height={25}
        sx={{ borderRadius: "8px", marginBottom: "5px" }}
      />
      <CommonSkeleton width={400} height={25} sx={{ borderRadius: "8px" }} />
      {staticModuleList?.length > 0 &&
        staticModuleList?.map((module) => (
          <Box
            key={module?.module}
            sx={{
              display: "flex",
              padding: "25px 0px",
            }}
          >
            <CommonSkeleton
              width={300}
              height={25}
              sx={{ borderRadius: "8px" }}
            />
          </Box>
        ))}
    </Box>
  );

  useEffect(() => {
    setIsLoadingList(true);
    callAPIInterface("GET", "/account-restrictions")
      .then((res) => {
        const updatedModuleList = getUpdatedModuleList(
          res?.account_restrictions
        );
        setRestrictionModuleList(updatedModuleList);
        setIsLoadingList(false);
      })
      .catch(() => {
        setRestrictionModuleList(getUpdatedModuleList([]));
        setIsLoadingList(false);
      });
  }, []);

  return (
    <Grid sx={{ padding: "20px 30px 100px 24px" }} container spacing={1}>
      {isLoadingList ? (
        moduleListLoader
      ) : (
        <>
          {headerText()}
          {renderSelectModuleSection()}
        </>
      )}
    </Grid>
  );
}

export default AccountPermission;
