import { Box, Grid } from "@mui/material";
import Button from "@speed/common/src/components/Button/Button";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import Text from "@speed/common/src/components/Text/Text";
import React, { useEffect } from "react";
import {
  archivePrice,
  archiveProduct,
  cancel,
  deletePrice,
  deleteProduct,
} from "../messages";
import WarningIcon from "@mui/icons-material/Warning";
import { isFromPRoduct } from "../constants";

const CommonModal = ({
  message,
  handleModalClose,
  isModalOpen,
  title,
  handleAction,
  isDisplayFooter,
  setIsDisplayFooter,
  deleteProductError,
  productId,
  action,
  from,
  actionLoader,
}) => {
  useEffect(() => {
    if (isModalOpen) {
      setIsDisplayFooter(true);
    }
  }, [isModalOpen]);

  const renderModalBody = () => {
    return (
      <>
        {deleteProductError && (
          <Box display="flex" justifyContent="center">
            <WarningIcon className="warning-icon" height="60px" width="60px" />
          </Box>
        )}
        <Box display="flex">
          <Text size={18} font="regular" variant="h6">
            {message}
          </Text>
          {productId && (
            <Text
              size={18}
              font="regular"
              variant="h6"
              className="grey-text"
              sx={{ marginLeft: "5px" }}
            >
              {productId}
            </Text>
          )}
        </Box>
      </>
    );
  };

  const buttonLabel = () => {
    if (action === "archive") {
      return isFromPRoduct.includes(from) ? archiveProduct : archivePrice;
    } else {
      return isFromPRoduct.includes(from) ? deleteProduct : deletePrice;
    }
  };

  const renderFooter = () => {
    return (
      isDisplayFooter && (
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          display="flex"
        >
          <Button
            variant="outlined"
            style={{ width: "175px" }}
            label={cancel}
            onClick={handleModalClose}
          />
          <Button
            label={buttonLabel()}
            color={action === "archive" ? "primary" : "error"}
            onClick={() => handleAction()}
            loading={actionLoader}
          />
        </Grid>
      )
    );
  };
  return (
    <Modal
      body={renderModalBody()}
      footer={renderFooter()}
      handleClose={handleModalClose}
      open={isModalOpen}
      title={title}
    />
  );
};

export default CommonModal;
