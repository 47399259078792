import React, { useEffect, useRef, useState } from "react";
import { Box } from "@mui/material";
import { VerticalStepperComponent } from "@speed/common/src/components/VerticalStepper/VerticalStepper";
import SetupPayoutWallet from "./SetupPayoutWallet";
import {
  completion,
  payoutScheduling,
  payoutWalletLabel,
  setupPW,
  setupPayoutScheduling,
  verifyPW,
} from "../../../messages";
import SetupVerifyPayoutWallet from "./SetupVerifyPayoutWallet";
import SetupAutoPaySchedule from "./SetupAutoPaySchedule";
import SetupCompletion from "./SetupCompletion";
import {
  callAPIInterface,
  noOfRecords,
  payoutLoaderComponent,
} from "../../../constants";
import { getStepsBeforeIndex } from "@speed/common/src/components/constants";
import { useSelector } from "react-redux";

const PayoutWalletSetupModal = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [childActiveStep, setChildActiveStep] = useState({ 0: 0 });
  const [childCompletedStep, setChildCompletedStep] = useState({
    0: [0],
  });
  const [isLoading, setIsLoading] = useState(true);
  const [parentCompletedStep, setParentCompletedStep] = useState();
  const [currencies, setCurrencies] = useState([]);
  const [currentAccountAsset, setCurrentAccountAsset] = useState([]);

  const { payoutModalData, accountAsset } = useSelector(
    (state) => state.common
  );

  const stepperRef = useRef();

  const handleNextFunc = () => stepperRef?.current?.handleNext?.();
  const handleBackFunc = () => stepperRef?.current?.handleBack?.();

  const handleVerifyNext = () => {
    handleNextFunc();
    setParentCompletedStep(0);
  };

  const handlePayoutSchedule = () => {
    setParentCompletedStep(1);
    handleNextFunc();
  };

  useEffect(() => {
    if (accountAsset && currencies) {
      const result = accountAsset?.filter((asset) => {
        return currencies?.includes(asset?.uuid);
      });

      setCurrentAccountAsset(result);
    }
  }, [currencies, accountAsset]);

  const steps = [
    {
      label: payoutWalletLabel,
      completeStepIcon: "",
      activateStepIcon: "",
      remainingStepIcon: "",
      children: [
        {
          label: setupPW,
          component: <SetupPayoutWallet handleNext={handleNextFunc} />,
        },
        {
          label: verifyPW,
          component: <SetupVerifyPayoutWallet handleNext={handleVerifyNext} />,
        },
      ],
    },
    {
      label: payoutScheduling,
      completeStepIcon: "",
      activateStepIcon: "",
      remainingStepIcon: "",
      children: [
        {
          label: setupPayoutScheduling,
          component: (
            <SetupAutoPaySchedule
              handleBack={handleBackFunc}
              handleNext={handlePayoutSchedule}
              currencies={currencies}
              accountAsset={currentAccountAsset}
            />
          ),
        },
      ],
    },
    {
      label: completion,
      completeStepIcon: "",
      activateStepIcon: "",
      remainingStepIcon: "",
      parentComponent: <SetupCompletion />,
      children: [],
    },
  ];

  const getPayoutWallets = (params) => {
    let method = "POST",
      data = {},
      path = "/payout-wallets/filter";
    callAPIInterface(method, path + params, data)
      .then((res) => {
        const walletsData = res.data;
        const isVerifiedWalletAvailable =
          walletsData?.length > 0 &&
          walletsData.filter((wallet) => wallet.status === "verified");
        const currencyOfVerifiedWallets = [
          ...new Set(isVerifiedWalletAvailable?.map((data) => data?.currency)),
        ];
        setCurrencies(currencyOfVerifiedWallets);
        if (
          payoutModalData ||
          (isVerifiedWalletAvailable.length === 0 && walletsData.length > 0)
        ) {
          setActiveStep(0);
          setChildActiveStep({ 0: 1 });
          setChildCompletedStep(getStepsBeforeIndex(steps, 0, 1));
        } else if (isVerifiedWalletAvailable.length > 0) {
          setParentCompletedStep(0);
          setActiveStep(1);
          setChildActiveStep({ 1: 0 });
          setChildCompletedStep(getStepsBeforeIndex(steps, 1, 0));
        }
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const queryParam = "?limit=" + noOfRecords;
    getPayoutWallets(queryParam);
  }, []);

  const renderSteps = () => {
    const parentRender = (item, mainIndex) =>
      activeStep === mainIndex ? item.parentComponent : null;

    return steps.map((item, mainIndex) => {
      return item.children?.length > 0
        ? item.children.map(
            (child, childIndex) =>
              childIndex === childActiveStep[mainIndex] && child.component
          )
        : parentRender(item, mainIndex);
    });
  };

  return (
    <Box className="payout-setup-modal-wrapper">
      <Box width="30%" sx={{ background: "#f7fafc" }}>
        <Box className="payout-left-section">
          <VerticalStepperComponent
            steps={steps}
            activeStep={activeStep}
            setActiveStep={setActiveStep}
            childActiveStep={childActiveStep}
            setChildActiveStep={setChildActiveStep}
            childCompletedStep={childCompletedStep}
            setChildCompletedStep={setChildCompletedStep}
            ref={stepperRef}
            parentCompletedStep={parentCompletedStep}
            disableNextStep={true}
          />
        </Box>
      </Box>
      <Box width="70%" sx={{ overflow: "auto" }}>
        <Box className="payout-right-section">
          {isLoading ? payoutLoaderComponent() : renderSteps()}
        </Box>
      </Box>
    </Box>
  );
};

export default PayoutWalletSetupModal;
