import { combineReducers } from "redux"
import auth from "./auth/reducer"
import common from "./common/reducer"
import { sessionReducer } from 'redux-react-session';

const appReducer = combineReducers({
    auth: auth,
    common: common,
    session: sessionReducer
})


const rootReducer = (state, action) => {
    if (action.type === 'RESET_STORE') {
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}

export default rootReducer;