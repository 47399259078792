import React from "react";
import "../../assets/styles/payment-links.scss";
import { useFlags } from "launchdarkly-react-client-sdk";
import ModuleEventList from "../ModuleEventList";
import { useParams } from "react-router";

const CheckoutLinkDetailsEvents = (props) => {
  const urlParams = useParams();

  const { clEventFe } = useFlags();

  return (
    <ModuleEventList ldFlag={clEventFe} urlParams={urlParams} {...props} />
  );
};

export default CheckoutLinkDetailsEvents;
