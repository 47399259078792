import { Box, Grid } from "@mui/material";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import {
  clipboardElement,
  fetchPrecisedInteger,
  generateBreadcrumbs,
  redirectWhenEntityNotFound,
} from "@speed/common/src/components/constants";
import Text from "@speed/common/src/components/Text/Text";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  RouteBreadcrumbs,
} from "../constants";
import WarningIcon from "@mui/icons-material/Warning";
import {
  dateCreated,
  entityNotFoundMessage,
  exchangeRate,
  from,
  swapAmount,
  swapDetailsText,
  to,
  youGot,
} from "../messages";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { useFlags } from "launchdarkly-react-client-sdk";
import { KeyValueGrid } from "@speed/common/src/components/KeyValueGrid";
import PaymentsSkeleton from "@speed/common/src/components/Skeleton/PaymentsSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";

const SwapDetails = (props) => {
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const [data, setData] = useState(null);
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const liveMode = useSelector((state) => state.auth.liveMode);
  const urlParams = useParams();
  const { swListingswapFe } = useFlags();

  const loadDetailPage = () => {
    setData(null);
    setIsShowNotFound(false);
    getSwapDetail();
  };

  const getSwapDetail = () => {
    callAPIInterface("GET", `/balances/swap/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setData(res);
        }
      })
      .catch((err) => {
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  useEffect(
    () => swListingswapFe && loadDetailPage(),
    [swListingswapFe, liveMode]
  );

  const headerContent = () => {
    return (
      <Box
        className="header-content"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text className="btc-amount" variant="h1" size={28}>
          {`${fetchPrecisedInteger(data?.target_amount, 8)} (${
            data?.target_currency
          })`}
        </Text>
        <Box className="header-price-content" mt="0px !important">
          {clipboardElement(data?.id, true, "payments-detail", "text", true)}
        </Box>
      </Box>
    );
  };

  return !isShowNotFound ? (
    <Grid className="payments-detail-wrapper" container spacing={1}>
      <Box className="section-wrapper" sx={{ position: "relative" }}>
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        {swListingswapFe ? (
          <>
            {data ? (
              <>
                {headerContent()}
                <Box className="details-content">
                  <Text size={20} className="default-text">
                    {swapDetailsText}
                  </Text>
                </Box>
                <CustomDivider sx={{ my: "12px" }} />
                <Box
                  gap={2.75}
                  display="flex"
                  flexWrap="wrap"
                  justifyContent="space-between"
                >
                  <KeyValueGrid keyName={from} value={data?.currency} />
                  <KeyValueGrid
                    keyName={dateCreated}
                    value={dateTimeFormatInApp(data?.created)}
                  />
                  <KeyValueGrid keyName={to} value={data?.target_currency} />
                  <KeyValueGrid
                    keyName={youGot}
                    value={`${fetchPrecisedInteger(data?.target_amount, 8)} (${
                      data?.target_currency
                    })`}
                  />
                  <KeyValueGrid
                    keyName={swapAmount}
                    value={`${fetchPrecisedInteger(data?.amount, 8)} (${
                      data?.currency
                    })`}
                  />
                  <KeyValueGrid
                    keyName={exchangeRate}
                    value={`1 ${data?.currency} = ${1 * data?.exchange_rate} ${
                      data?.target_currency
                    }`}
                  />
                </Box>
              </>
            ) : (
              <PaymentsSkeleton props={props} />
            )}
          </>
        ) : (
          <AccessDenied />
        )}
      </Box>
    </Grid>
  ) : (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage("Swap")}
        subTitle={`Swap ${urlParams.id} is not found.`}
        btnText={`View ${(liveMode && "live") || "test"} mode swaps`}
      />
    </Box>
  );
};

export default SwapDetails;
