import React from "react";
import GetGuides from "./GetGuides";

const SessionsGuide = () => {
  return (
    <GetGuides
      moduleName="sessions"
      redirectUrl=""
      guideApiPath="sessions-guide"
      showSteps={false}
      needHelpGuidePath="docs/receive-payments/checkout-session"
    />
  );
};

export default SessionsGuide;
