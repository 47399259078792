import { useLayoutEffect, useRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { times } from "lodash";
import "./table.scss";
import TableSkeleton from "./TableSkeleton";
import NoDataAvailable from "../NoDataAvailable";

export const CustomTable = (props) => {
  const tableContainerRef = useRef();
  const tableRef = useRef();
  const refElement = props.isScrollTableElement
    ? tableContainerRef?.current
    : window.document.documentElement;

  const [scrollElement, setScrollElement] = useState(null);

  const scrollListener = () => {
    if (refElement) {
      const distanceBottom = Math.round(
        (refElement.scrollHeight - refElement.clientHeight) * 0.8
      );
      if (
        refElement.scrollTop > distanceBottom &&
        props.hasMore &&
        !props.tableRowSkeleton &&
        props.loadMore
      ) {
        props.loadMore();
      }
    }
  };

  useEffect(() => {
    const element = props.isScrollTableElement
      ? tableContainerRef.current
      : window;
    setScrollElement(element);
  }, []);

  useEffect(() => {
    let tableClientHeight, tableContainerClientHeight;
    if (props.isScrollTableElement) {
      tableClientHeight = tableRef?.current?.clientHeight;
      tableContainerClientHeight = tableContainerRef?.current.clientHeight;
    } else {
      tableClientHeight = tableContainerRef?.current?.clientHeight;
      tableContainerClientHeight = window.document.documentElement.clientHeight;
    }

    if (
      tableClientHeight < tableContainerClientHeight &&
      props.hasMore &&
      props.rows?.length &&
      !props.tableRowSkeleton &&
      props.loadMore
    ) {
      props.loadMore();
    }
  }, [props.rows?.length]);

  useLayoutEffect(() => {
    if (scrollElement) {
      scrollElement.addEventListener("scroll", scrollListener);
      return () => {
        scrollElement.removeEventListener("scroll", scrollListener);
      };
    }
  }, [scrollElement, props.hasMore, props.loadMore, props.tableRowSkeleton]);

  return (
    <TableContainer
      ref={tableContainerRef}
      style={{ height: "auto" }}
      className={props.className}
    >
      <Table ref={tableRef} stickyHeader onScroll={scrollListener}>
        {!props.rows.length &&
          !props?.tableRowSkeleton &&
          !props.hideNoData && (
            <NoDataAvailable
              text={props.textOnNoData}
              image={props?.noDataImage}
              subText={props?.subTextOnNoData}
              buttonText={props?.createButtonText}
              handleCreate={props?.handleCreate}
            />
          )}
        <TableHead>
          <TableRow>{props.columns}</TableRow>
        </TableHead>
        <TableBody>
          {props.rows}
          {props.tableRowSkeleton && <TableSkeleton columns={props.columns} />}
          {!props.rows.length &&
            props.loadingRowsCount &&
            times(props.loadingRowsCount, (index) => (
              <TableSkeleton key={index} columns={props.columns} />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const propTypes = {
  rows: PropTypes.array.isRequired,
  columns: PropTypes.array.isRequired,
  loading: PropTypes.bool,
};

CustomTable.propTypes = propTypes;

export default CustomTable;
