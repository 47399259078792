import WarningIcon from "@mui/icons-material/Warning";
import { Grid, TableCell, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import Text from "@speed/common/src/components/Text/Text";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import {
  clipboardElement,
  generateBreadcrumbs,
  getCurrencyObj,
  redirectWhenEntityNotFound,
  satsToBtcAmount,
} from "@speed/common/src/components/constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import "../../assets/styles/payments.scss";
import { events } from "../Events/Events";
import DetailPageWithdrawSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import {
  RouteBreadcrumbs,
  callAPIInterface,
  dateTimeFormatInApp,
  getWithdrawPaymentMethod,
  renderWithdrawStatus,
} from "../constants";
import { entityNotFoundMessage, events as eventsText } from "../messages";
import PaymentsSkeleton from "@speed/common/src/components/Skeleton/PaymentsSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";

const WithdrawDetail = (props) => {
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const liveMode = useSelector((state) => state.auth.liveMode);
  const history = useSelector((state) => state.common.history);
  const { wiFetchwithdraw } = useFlags();
  const [data, setData] = useState(null);
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [eventSkeleton, setEventSkeleton] = useState(true);
  const [eventsList, setEventsList] = useState([]);

  const { wiEventFe } = useFlags();

  const urlParams = useParams();

  const getWithdrawsDetail = async () => {
    setShowSkeleton(true);
    await callAPIInterface("GET", `/withdraws/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setData(res);
        }
        setShowSkeleton(false);
      })
      .catch((err) => {
        setShowSkeleton(false);
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const getEventsList = async () => {
    setEventSkeleton(true);
    const filterEvents = {
      ids: [urlParams?.id],
    };
    await callAPIInterface(
      "POST",
      "/events/filters",
      JSON.stringify(filterEvents)
    )
      .then((res) => {
        if (res) {
          setEventSkeleton(false);
          setEventsList(res?.data);
        }
      })
      .catch(() => {
        setEventSkeleton(false);
      });
  };

  useEffect(() => {
    setData(null);
    getWithdrawsDetail();
    setIsShowNotFound(false);
    wiEventFe && getEventsList();
  }, [liveMode, urlParams?.id, wiEventFe]);

  const detailsTableData = () => {
    return [
      {
        header: "Status",
        cell: renderWithdrawStatus(data?.status),
      },
      {
        header: "Date created",
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {dateTimeFormatInApp(data?.created)}
          </Text>
        ),
      },
      {
        header: "Date modified",
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {dateTimeFormatInApp(data?.modified)}
          </Text>
        ),
      },
      {
        header: "Method",
        cell: getWithdrawPaymentMethod(data),
      },
      {
        header: "Withdraw Request",
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {clipboardElement(
              data?.withdraw_request,
              true,
              "webhook-id-copy",
              "",
              true
            )}
          </Text>
        ),
      },
    ];
  };

  const eventRows = eventsList?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/events/${rowItem?.id}`)}
      >
        <TableCell>{events(rowItem)}</TableCell>
        <TableCell align="right" sx={{ color: "#848b9e" }}>
          {dateTimeFormatInApp(rowItem?.created)}
        </TableCell>
      </TableRow>
    );
  });

  const headerContent = () => {
    return (
      <Box className="header-content">
        <Text className="btc-amount" variant="h1" size={28}>
          {`${satsToBtcAmount(
            data.status === "paid"
              ? data.target_amount_paid
              : data.target_amount
          )} BTC`}
        </Text>
        <Box className="header-price-content">
          <Text
            className="grey-text"
            size={16}
            font="regular"
            variant="subtitle"
          >
            ≈ {getCurrencyObj(data.currency)?.symbol}
            {data.amount}
          </Text>
          <Box>
            {clipboardElement(data?.id, true, "payments-detail", "text", true)}
          </Box>
        </Box>
      </Box>
    );
  };

  return wiFetchwithdraw ? (
    !isShowNotFound ? (
      <Grid className="payments-detail-wrapper" container spacing={1}>
        <Box className="section-wrapper" sx={{ position: "relative" }}>
          <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
          {data ? (
            <>
              {headerContent()}

              {
                <Box className="details-content">
                  <VerticalTable
                    label="Withdraw Details"
                    rowData={detailsTableData()}
                  />
                </Box>
              }
            </>
          ) : (
            showSkeleton && <PaymentsSkeleton props={props} />
          )}
          {wiEventFe && (
            <>
              {!eventSkeleton ? (
                <Box className="product-content">
                  <HorizontalTable
                    label={eventsText}
                    isColumnShown={eventRows?.length > 0}
                    rows={eventRows}
                    rowsPerPage={3}
                    handleViewAllClick={() => {
                      history.push(`/withdraws/${urlParams?.id}/events`);
                    }}
                    isShowButton={eventRows?.length > 3}
                    tableBodyClassName="border-none"
                  />
                </Box>
              ) : (
                <DetailPageWithdrawSkeleton />
              )}
            </>
          )}
        </Box>
      </Grid>
    ) : (
      <Box className="not-found-wrapper">
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        <LinkNotFound
          icon={<WarningIcon className="warning-icon" />}
          title={entityNotFoundMessage("Withdraw")}
          subTitle={`Withdraw ${urlParams.id} is not found.`}
          btnText={`View ${(liveMode && "live") || "test"} mode withdraws`}
        />
      </Box>
    )
  ) : (
    <AccessDenied />
  );
};

export default WithdrawDetail;
