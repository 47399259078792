import Text from "@speed/common/src/components/Text/Text";
import { Box, Grid } from "@mui/material";
import { Input } from "@speed/common/src/components/Input/Input";
import {
  confirmEmailMsg,
  confirmEmailSubMsg,
  forgotPasswordMsg,
  confirmEmailSubMsg2,
  resetMsg,
} from "../messages";
import { Button } from "@speed/common/src/components/Button/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import { useEffect, useState } from "react";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { sessionService } from "redux-react-session";
import { checkLinkStatus, verifyChangeEmail } from "../../redux/auth/actions";
import LinkStatus from "../Common/LinkStatus";
import {
  loadingMsg,
  passwordLabel,
  passwordPlaceholder,
} from "@speed/common/src/components/messages";
import MainLogoLogin from "../Common/MainLogoLogin";
import { continueBtn } from "@speed/common/src/messages";

const ConfirmEmail = () => {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [confirmEmailScreen, setConfirmEmailScreen] = useState(false);
  const [linkStatus, setLinkStatus] = useState("");
  const [link, setLink] = useState("");
  const [oldEmail, setOldEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");

  const { history, isMobile } = useSelector((state) => state.common);

  const validationSchema = yup.object({
    password: yup.string().required(),
  });

  const handleRedirection = async (user) => {
    const searchURL = history.location.search.replace("+", "%2B");
    const data = new URLSearchParams(searchURL);
    const verifyLink = data.get("id") || user?.verification_link;
    const email = data.get("email") || user?.email;
    setLink(verifyLink);
    if (verifyLink) {
      setLoading(true);
      await sessionService
        .saveUser({ verification_link: verifyLink, email: email })
        .then((_res) => {
          history.push("/confirm-email");
        });
      dispatch(checkLinkStatus(verifyLink))
        .then((res) => {
          if (res) {
            setOldEmail(res.old_email);
            setNewEmail(res.new_email);
            setLoading(false);
            setConfirmEmailScreen(true);
          }
        })
        .catch((e) => {
          setIsLink(true);
          const errors = e.response?.data?.errors;
          setNewEmail(email);
          const errorType = errors?.[0]?.type?.toLowerCase();
          if (errorType === "link_expired") setLinkStatus("expired");
          else if (errorType === "link_already_used") setLinkStatus("used");
          else history.push("/dashboard");
          setLoading(false);
        });
    } else {
      history.push("/login");
    }
  };

  useEffect(async () => {
    const user = await sessionService
      .loadUser()
      .then((res) => res)
      .catch(() => {});
    handleRedirection(user);
  }, []);

  const handleConfirmEmail = (confirmEmailValues) => {
    setLoading(true);
    let params = {
      verification_link: link,
      password: confirmEmailValues.password,
    };
    dispatch(verifyChangeEmail(params)).then((res) => {
      setLoading(false);
      if (res) history.push("/dashboard");
    });
  };

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleConfirmEmail,
  });

  const {
    values,
    isValid,
    isSubmitting,
    dirty,
    handleSubmit,
    setSubmitting,
    setFieldValue,
  } = formik;

  const oldEmailText = (
    <Text
      font="semibold"
      size={isMobile ? 14 : 18}
      variant="inline"
      className="default-text"
    >
      {oldEmail}
    </Text>
  );
  const newEmailText = (
    <Text
      font="semibold"
      size={isMobile ? 14 : 18}
      variant="inline"
      className="default-text"
    >
      {newEmail}
    </Text>
  );

  return (
    <>
      <BackdropLoader
        open={isLoading}
        alt="loading..."
        text={loadingMsg}
        customClass="loading-in-auth"
      />
      {isLink
        ? !isLoading && (
            <LinkStatus
              from="confirmEmail"
              type={linkStatus}
              email={newEmail}
            />
          )
        : confirmEmailScreen && (
            <>
              <MainLogoLogin />
              <Box className="container-layout">
                <Grid
                  container
                  className="common-grid-wrapper common-mobile-grid signin-signup-container-layout"
                >
                  <BackdropLoader
                    open={isLoading}
                    alt="loading..."
                    text={loadingMsg}
                    customClass="loading-in-auth"
                  />
                  <Text
                    size={isMobile ? 22 : 30}
                    font="bold"
                    sx={{ width: "100%" }}
                  >
                    {confirmEmailMsg}
                  </Text>

                  <Grid
                    item
                    className="email-password-grid"
                    component="form"
                    onSubmit={handleSubmit}
                  >
                    <Text
                      size={isMobile ? 14 : 18}
                      font="regular"
                      className="default-text text margin-top10"
                      variant="subtitle1"
                      sx={{ lineHeight: "140% !important" }}
                    >
                      {confirmEmailSubMsg(oldEmailText, newEmailText)}
                    </Text>
                    <Text
                      size={isMobile ? 14 : 18}
                      font="regular"
                      className="default-text text margin-top20"
                      variant="subtitle1"
                      sx={{ lineHeight: "140% !important" }}
                    >
                      {confirmEmailSubMsg2}
                    </Text>
                    <Input
                      autoFocus
                      customClass="margin-top36"
                      type="password"
                      onChange={(e) => {
                        setSubmitting(false);
                        setFieldValue("password", e.target.value);
                      }}
                      showLabel
                      label={passwordLabel}
                      value={values.password}
                      name="password"
                      fullWidth
                      placeholder={passwordPlaceholder}
                    />
                    <Button
                      fullWidth
                      className="margin-top30"
                      label={continueBtn}
                      type="submit"
                      disabled={!(isValid && dirty) || isSubmitting}
                    />
                    <Text
                      size={16}
                      font="semibold"
                      align="center"
                      className="default-text margin-top58"
                    >
                      {forgotPasswordMsg}
                      <CustomLink
                        size="large"
                        bold
                        className="margin-left10"
                        onClick={() => history.push("/reset-password")}
                      >
                        {resetMsg}
                      </CustomLink>
                    </Text>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
    </>
  );
};

export default ConfirmEmail;
