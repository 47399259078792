import React, { useEffect, useMemo, useState } from "react";
import { Box } from "@mui/material";
import Text from "../Text/Text";
import {
  addNewAccount,
  continueBtn,
  getStartedText,
  selectAnAccount,
  standardCapabilities,
  twoFAMsg,
} from "../../messages";
import Button from "../Button/Button";
import ConnectLogin from "./Login/ConnectLogin";
import { ConnectContext } from "../../contexts/contexts";
import Connect2FA from "./TwoFactorConnect/Connect2FA";
import ConnectAccountSelection from "./AccountSelection/ConnectAccountSelection";
import { cancel } from "../messages";
import StandardCapabilities from "./AccountSelection/StandardCapabilities";
import SuccessConnect from "./AccountSelection/SuccessConnect";

const ConnectCard = ({
  textFontFamily,
  accentColor,
  accentTextColor,
  commonProps,
}) => {
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [goBackClicked, setGoBackClicked] = useState(false);
  const [disabled, setButtonDisabled] = useState(true);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [firstButtonLoader, setFirstButtonLoader] = useState(false);
  const [contentObj, setContentObj] = useState({
    headerLabel: "",
    component: null,
  });
  const [open, setOpen] = useState(false);
  const [firstBtnLabel, setFirstBtnLabel] = useState(cancel);
  const [secondBtnLabel, setSecondBtnLabel] = useState(continueBtn);
  const [shouldGoBackVisible, setShouldGoBackVisible] = useState(true);

  const contextValues = useMemo(() => {
    return {
      isButtonClicked,
      setIsButtonClicked,
      setButtonDisabled,
      setButtonLoader,
      goBackClicked,
      setGoBackClicked,
      setFirstBtnLabel,
      setSecondBtnLabel,
      setShouldGoBackVisible,
      setFirstButtonLoader,
      ...commonProps,
    };
  }, [isButtonClicked, goBackClicked, commonProps]);

  const customBtnStyle =
    !disabled && !buttonLoader
      ? {
          backgroundColor: `${accentColor} !important`,
          color: `${accentTextColor} !important`,
        }
      : {};

  useEffect(() => {
    switch (commonProps?.fromType) {
      case "login":
        setContentObj({
          headerLabel: getStartedText,
          footer: true,
          component: <ConnectLogin textFontFamily={textFontFamily} />,
        });
        break;
      case "two-factor":
        setContentObj({
          headerLabel: twoFAMsg,
          footer: true,
          component: <Connect2FA textFontFamily={textFontFamily} />,
        });
        break;
      case "account-selection":
        setContentObj({
          headerLabel: selectAnAccount,
          footer: true,
          component: <ConnectAccountSelection setOpen={setOpen} open={open} />,
        });
        break;
      case "standard-capabilities":
        setContentObj({
          headerLabel: standardCapabilities,
          footer: true,
          component: <StandardCapabilities />,
        });
        break;
      case "connect-success":
        setContentObj({
          headerLabel: "",
          footer: false,
          component: <SuccessConnect />,
        });
        break;
      default:
        setContentObj({
          headerLabel: getStartedText,
          component: <ConnectLogin textFontFamily={textFontFamily} />,
          footer: true,
        });
        break;
    }
  }, [commonProps, open]);

  return (
    <Box className="content-wrapper-box">
      {contentObj.headerLabel && (
        <Box
          className="wrapper-header"
          sx={
            commonProps?.fromType === "account-selection"
              ? { display: "flex", justifyContent: "space-between" }
              : {}
          }
        >
          <Text
            font="bold"
            variant="subtitle1"
            className="default-text wrapper-header-text"
            sx={{ fontFamily: `${textFontFamily} !important`, fontWeight: 700 }}
          >
            {contentObj.headerLabel}
          </Text>

          {commonProps?.fromType === "account-selection" && (
            <Button
              icon="addIcon"
              className="add-icon"
              label={addNewAccount}
              variant="outlined"
              color="primary"
              onClick={() => setOpen(true)}
            />
          )}
        </Box>
      )}
      <Box className="body-content">
        <ConnectContext.Provider value={contextValues}>
          {contentObj.component}
        </ConnectContext.Provider>
      </Box>
      {contentObj.footer && (
        <Box className="wrapper-footer">
          {commonProps?.visibleInConnectPage && shouldGoBackVisible && (
            <Button
              label={firstBtnLabel}
              variant="outlined"
              loading={firstButtonLoader}
              onClick={() => setGoBackClicked(true)}
              sx={{
                fontFamily: `${textFontFamily} !important`,
                marginRight: "16px",
                wordBreak: "break-word",
              }}
            />
          )}
          <Button
            label={secondBtnLabel}
            disabled={disabled}
            variant="contained"
            loading={buttonLoader}
            onClick={() =>
              commonProps?.visibleInConnectPage && setIsButtonClicked(true)
            }
            sx={{
              fontFamily: `${textFontFamily} !important`,
              wordBreak: "break-word",
              ...customBtnStyle,
            }}
          />
        </Box>
      )}
    </Box>
  );
};

export default ConnectCard;
