import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";
import CommonHeader from "../Common/CommonHeader";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import SwapList from "./SwapList";
import { callStrapiAPIInterface } from "../constants";
import GetStartedHeader from "@speed/common/src/components/GetStartedHeader/GetStartedHeader";
import {
  setCaughtErrorInStrapi,
  setIsDataAvailable,
  setIsModalOpen,
} from "../../redux/common/actions";

const Swap = () => {
  const [swapsGuide, setSwapsGuide] = useState(null);
  const [isGetHeaderInfoLoading, setGetHeaderInfoLoading] = useState(true);
  const { isDataAvailable, caughtErrorInStrapi } = useSelector(
    (state) => state.common
  );
  const liveMode = useSelector((state) => state.auth.liveMode);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsDataAvailable(true));
    getSwapsGuide();
    return () => {
      dispatch(setIsModalOpen(false));
      dispatch(setCaughtErrorInStrapi(false));
    };
  }, [liveMode]);

  const getSwapsGuide = () => {
    setGetHeaderInfoLoading(true);
    callStrapiAPIInterface(
      "GET",
      "/api/swaps-guide?populate[header][populate][use_cases][populate]=*"
    )
      .then((response) => {
        setSwapsGuide(response?.data?.attributes?.header);
        setGetHeaderInfoLoading(false);
      })
      .catch((error) => {
        dispatch(setCaughtErrorInStrapi(true));
        setGetHeaderInfoLoading(false);
      });
  };

  return (
    <Box
      bgcolor={!isDataAvailable && !caughtErrorInStrapi ? "#f7fafc" : ""}
      className="section-wrapper payment-links-wrapper"
    >
      {isDataAvailable || caughtErrorInStrapi ? (
        <>
          <CommonHeader />
          <CustomDivider />
        </>
      ) : (
        <GetStartedHeader
          setIsModalOpen={setIsModalOpen}
          loading={isGetHeaderInfoLoading}
          getHeaderInfo={swapsGuide}
        />
      )}
      <SwapList />
    </Box>
  );
};

export default Swap;
