import React from "react";
import { backToDashboard } from "../../messages";
import Button from "@speed/common/src/components/Button/Button";
import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import { errorImg } from "../../images";
import { setPaymentProcessingRecallApis } from "../../../redux/common/actions";
import { useDispatch } from "react-redux";

const InvaliAddressOrQrcode = ({
  handleModalClose,
  errorMsg,
  errorSubMessage,
}) => {
  const dispatch = useDispatch();
  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <img
        style={{ marginBottom: "16px" }}
        height="54px"
        width="54px"
        src={errorImg}
        alt=""
      />
      {errorMsg && (
        <Text size={22} font="bold" className="default-text" variant="h6">
          {errorMsg}
        </Text>
      )}
      {errorSubMessage && (
        <Text
          size={16}
          font="regular"
          className="grey-text"
          variant="h6"
          sx={{
            mt: "10px",
            width: "520px",
            textAlign: "center",
            wordBreak: "break-all",
          }}
        >
          {errorSubMessage}
        </Text>
      )}
      <Box display="flex" mt={"146px"} mb={"30px"}>
        <Button
          label={backToDashboard}
          onClick={() => {
            dispatch(setPaymentProcessingRecallApis(true));
            handleModalClose();
          }}
          type="submit"
          className="payment-failed"
        />
      </Box>
    </Box>
  );
};

export default InvaliAddressOrQrcode;
