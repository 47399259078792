import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import { CustomTooltip } from "@speed/common/src/components/Tooltip/Tooltip";
import React, { memo } from "react";

const TooltipOverText = ({ textToHover, customClass = "", length }) => {
  const textToHoverText = (
    <Text
      variant="h4"
      size={16}
      className={`default-text ${
        textToHover?.length > length && `multi-line-truncate ${customClass}`
      }`}
      font="regular"
    >
      {textToHover}
    </Text>
  );

  return textToHover?.length > length ? (
    <CustomTooltip
      enterTouchDelay={0}
      arrow={true}
      placement="bottom"
      text={
        <Text variant="subtitle" size={14}>
          {textToHover}
        </Text>
      }
      className="custom-tooltip"
    >
      <Box sx={{ width: "fit-content", boxShadow: "none" }}>
        {textToHoverText}
      </Box>
    </CustomTooltip>
  ) : (
    textToHoverText
  );
};

export default memo(TooltipOverText);
