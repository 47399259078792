import React from "react";
import Text from "./Text/Text";
import Button from "@speed/common/src/components/Button/Button";

const NoDataAvailable = (props) => {
  return (
    <caption className={props?.className || "not-found"}>
      {props?.image && (
        <img
          alt="no-data"
          src={props?.image}
          style={{ width: "inherit", height: "inherit" }}
        />
      )}
      <Text
        size={18}
        font="regular"
        align="center"
        className="default-text margin-bottom20 margin-top27"
      >
        {props?.text}
      </Text>
      <Text size={14} font="medium" align="center" className="grey-text">
        {props?.subText}
      </Text>
      {props?.buttonText && (
        <Button
          variant="contained"
          label={props?.buttonText}
          onClick={props?.handleCreate}
          className="margin-top30"
        />
      )}
    </caption>
  );
};

export default NoDataAvailable;
