import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TableCell,
  TableRow,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useParams } from "react-router";
import CopyToClipboard from "react-copy-to-clipboard";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import CustomTable from "@speed/common/src/components/Table/Table";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import Text from "@speed/common/src/components/Text/Text";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import NoDataAvailable from "@speed/common/src/components/NoDataAvailable";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  getStatusCode,
  noOfRecords,
} from "../constants";
import { emptyWebhook } from "../images";
import jsonDataToShow from "../jsonLogic";
import {
  copyEventID,
  noWebhookSubText,
  noWebhookText,
  reqBodyLabel,
} from "../messages";

const EventResponse = (props) => {
  const { param, tab } = props;
  const queryParam = "?limit=" + noOfRecords;
  const [anchorEl, setAnchorEl] = useState(null);
  const [rows, setRows] = useState([]);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currentRowSelected, setCurrentRowSelected] = useState({});
  const [queryParams, setQueryParams] = useState();
  const urlParams = useParams();

  useEffect(() => fetchEventAttempts(), [tab]);

  const fetchEventAttempts = () => {
    setRows([]);
    setHasMore(true);
    getEventAttempts([], "");
  };
  const loadMore = useCallback(() => {
    getEventAttempts(rows, queryParams);
  }, [rows]);

  const getEventAttempts = async (lines, paramFromProps) => {
    setTableRowSkeleton(true);
    let method,
      params,
      path = "";
    if (param) {
      method = "GET";
      params = `${queryParam}&response_status=${param}${paramFromProps}`;
      path = `/webhooks/endpoint/${urlParams.id}/attempts`;
    } else {
      method = "GET";
      params = `${queryParam}${paramFromProps}`;
      path = `/webhooks/endpoint/${urlParams.id}/attempts`;
    }
    await callAPIInterface(method, path + params, {})
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              `&ending_before=${res.data[res.data.length - 1].attempt_id}`
            );
          }

          setRows(lines.concat(res?.data));
          lines?.length === 0 && setCurrentRowSelected(res?.data[0]);
        }
      })
      .catch((error) => {
        setTableRowSkeleton(false);
      });
  };

  const columns = [
    { title: "Entry", paddingLeft: "30px !important" },
    { title: "" },
    { title: "Date" },
  ];

  let columnsData = columns.map((column, index) => (
    <TableCell
      key={index}
      align={column?.align}
      sx={{ width: column?.width, paddingLeft: column.paddingLeft }}
    >
      {column?.title}
    </TableCell>
  ));

  let rowsData = rows?.map((rowItem, index) => {
    return (
      <TableRow
        key={index}
        className="clickable"
        selected={currentRowSelected?.attempt_id === rowItem?.attempt_id}
        sx={{
          "&.Mui-selected": {
            bgcolor: "#f0f7ff !important",
          },
        }}
        onClick={() => setCurrentRowSelected(rowItem)}
      >
        <TableCell sx={{ paddingLeft: "30px !important" }}>
          <Tag
            text={getStatusCode(
              rowItem?.response_status_code,
              rowItem?.response_status
            )}
            variant={
              rowItem?.response_status?.toLowerCase() === "success"
                ? "success-res"
                : "error"
            }
          />
        </TableCell>
        <TableCell className="truncate-api">
          <div>{rowItem?.event_type}</div>
        </TableCell>
        <TableCell>{dateTimeFormatInApp(rowItem?.timestamp)}</TableCell>
      </TableRow>
    );
  });
  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
    className: "events-attempts-table",
    isScrollTableElement: true,
  };

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return (
    <>
      {!rows?.length && !tableRowSkeleton ? (
        <Box
          height="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          bgcolor="white"
        >
          <NoDataAvailable
            text={noWebhookText}
            subText={noWebhookSubText}
            image={emptyWebhook}
          />
        </Box>
      ) : (
        <>
          <Grid container height="100%">
            <Grid item xs={7.49} height="100%">
              <CustomTable {...tableProps} />
            </Grid>
            <CustomDivider flexItem orientation="vertical" />
            <Grid
              item
              xs={4.49}
              bgcolor="white"
              height="100%"
              overflow="auto"
              position="relative"
              zIndex={5}
            >
              {rows?.length === 0 ||
              Object.keys(currentRowSelected).length === 0 ? (
                <Box />
              ) : (
                <Box display="flex" flexDirection="column">
                  <Box p={2}>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      gap={10}
                    >
                      <Text variant="h1" component="h2" noWrap>
                        {currentRowSelected?.event_type}
                      </Text>
                      <MoreHorizIcon
                        className="horizontal-dots-icon pointer-cursor"
                        onClick={(event) => {
                          event.stopPropagation();
                          handleClick(event);
                        }}
                      />
                    </Box>
                  </Box>
                  <Box p={2}>
                    <Text size={18} variant="h1" component="h2">
                      Response
                    </Text>
                    <Box my={3}>
                      <Tag
                        text={getStatusCode(
                          currentRowSelected?.response_status_code,
                          currentRowSelected?.response_status
                        )}
                        variant={
                          currentRowSelected?.response_status?.toLowerCase() ===
                          "success"
                            ? "success-res"
                            : "error"
                        }
                      />
                    </Box>
                    <Box mt={6} mb={3}>
                      <Text
                        size={16}
                        font="regular"
                        variant="body1"
                        className="grey-text"
                        align="center"
                      >
                        {currentRowSelected?.response_status}
                      </Text>
                    </Box>
                  </Box>
                  <Box display="flex" flexDirection="column" p={2} width="30vw">
                    <Text size={18} variant="h1" component="h2">
                      {reqBodyLabel()}
                    </Text>
                    <Box my={3}>
                      {jsonDataToShow(currentRowSelected?.request)}
                    </Box>
                  </Box>
                </Box>
              )}
              <CustomPopper
                disablePortal={true}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                position="bottom"
                handleClose={() => setAnchorEl(null)}
              >
                <List>
                  <ListItem disablePadding>
                    <CopyToClipboard
                      text={currentRowSelected?.request?.event_id}
                    >
                      <ListItemButton onClick={() => setAnchorEl(null)}>
                        <ListItemText primary={copyEventID} />
                      </ListItemButton>
                    </CopyToClipboard>
                  </ListItem>
                </List>
              </CustomPopper>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default EventResponse;
