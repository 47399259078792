import React, { Fragment, useState } from "react";
import { FieldArray, FormikProvider, useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import AddIcon from "@mui/icons-material/Add";
import Button from "@speed/common/src/components/Button/Button";
import { cancel } from "@speed/common/src/components/messages";
import Text from "@speed/common/src/components/Text/Text";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { Input } from "@speed/common/src/components/Input/Input";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import {
  addAnotherItem,
  emptyMetaDataKeyValue,
  key,
  metaDataText,
  uniqueMetaDataKey,
  value,
} from "./messages";
import { convertArrayToObjectForMetadata } from "./constants";
import { save } from "@speed/common/src/messages";
import { uniqueMethod } from "@speed/common/src/components/constants";

const MetaDataForm = (props) => {
  const { metaDataList, onSubmit, onCancel } = props;
  const [metaDataForDeletionProcedure, setMetaDataForDeletionProcedure] =
    useState([]);

  // For unique field validation in formik
  uniqueMethod();

  const validationSchema = yup.object().shape({
    metaData: yup.array().of(
      yup
        .object()
        .shape(
          {
            key: yup.string().when("value", {
              is: (val) => val && val.length > 0,
              then: yup.string().required(emptyMetaDataKeyValue("key")),
              otherwise: yup.string(),
            }),
            value: yup.string().when("key", {
              is: (val) => val && val.length > 0,
              then: yup.string().required(emptyMetaDataKeyValue("value")),
              otherwise: yup.string(),
            }),
          },
          ["value", "key"]
        )
        .uniqueProperty("key", uniqueMetaDataKey)
    ),
  });

  const emptyMetaDataList = [{ key: "", value: "" }];

  const initialValues = {
    metaData: metaDataList?.length > 0 ? metaDataList : emptyMetaDataList,
  };

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (value) => {
      const removedEmptyMetaData = value?.metaData?.filter(
        (val) => val.key.length > 0
      );
      const removedEmptyMetaDataForDeletionProcedure =
        metaDataForDeletionProcedure?.filter((val) => val.key.length > 0);
      let payload = {
        ...convertArrayToObjectForMetadata(
          removedEmptyMetaDataForDeletionProcedure
        ),
        ...convertArrayToObjectForMetadata(removedEmptyMetaData),
      };

      const difference = (obj1, obj2) => {
        let finalPayload = {};
        Object.keys(obj1).forEach((key) => {
          if (obj1[key] !== obj2[key]) {
            return (finalPayload = {
              ...finalPayload,
              [key]: obj2[key] ? obj2[key] : "",
            });
          }
        });
        return finalPayload;
      };

      payload = {
        ...payload,
        ...difference(convertArrayToObjectForMetadata(metaDataList), payload),
      };
      onSubmit(payload);
    },
  });

  const { values, handleChange, resetForm, errors, handleSubmit, setErrors } =
    formik;

  return (
    <FormikProvider value={formik}>
      <FieldArray name="metaData">
        {(arrayHelpers) => (
          <>
            <Box className="horizontal-table-wrapper">
              <Box className="align-left-right-content">
                <Text size={20} className="default-text">
                  {metaDataText}
                </Text>
              </Box>
              <CustomDivider sx={{ marginTop: "12px" }} />
              <TableContainer>
                <Table>
                  <TableBody className="border-none">
                    {values?.metaData?.map((rowItem, rowIndex) => {
                      return (
                        <Fragment key={rowIndex}>
                          <TableRow sx={{ display: "flex" }}>
                            <TableCell>
                              <IconButton
                                sx={{ color: "#e74c3c" }}
                                onClick={() => {
                                  if (values?.metaData?.length > 1) {
                                    metaDataList?.forEach((element) =>
                                      element?.key ===
                                      values.metaData[rowIndex].key
                                        ? setMetaDataForDeletionProcedure(
                                            (prev) => [
                                              ...prev,
                                              {
                                                key: values.metaData[rowIndex]
                                                  .key,
                                                value: "",
                                              },
                                            ]
                                          )
                                        : null
                                    );

                                    arrayHelpers.remove(rowIndex);
                                  } else {
                                    metaDataList?.length > 0 &&
                                      setMetaDataForDeletionProcedure(
                                        (prev) => [
                                          ...prev,
                                          {
                                            key: values.metaData[rowIndex].key,
                                            value: "",
                                          },
                                        ]
                                      );
                                    arrayHelpers.remove(rowIndex);
                                    arrayHelpers.push({ key: "", value: "" });
                                  }
                                  setErrors({});
                                }}
                                aria-label="close"
                              >
                                <RemoveCircleOutlinedIcon
                                  sx={{ height: 28, width: 28 }}
                                />
                              </IconButton>
                            </TableCell>
                            <TableCell width="35%">
                              <Box>
                                <Input
                                  fullWidth
                                  placeholder={key}
                                  showLabel={false}
                                  type="text"
                                  name={`metaData[${rowIndex}].key`}
                                  value={values.metaData[rowIndex].key}
                                  inputProps={{ maxLength: 40 }}
                                  onChange={(val) => {
                                    setErrors({});
                                    handleChange(val);
                                  }}
                                  error={
                                    errors?.metaData &&
                                    Boolean(errors.metaData[rowIndex]?.key)
                                  }
                                  sx={{
                                    "&.Mui-error": {
                                      border: "1px solid #e74c3c !important",
                                    },
                                  }}
                                />
                              </Box>
                            </TableCell>
                            <TableCell width="65%">
                              <Box>
                                <Input
                                  fullWidth
                                  placeholder={value}
                                  showLabel={false}
                                  type="text"
                                  name={`metaData[${rowIndex}].value`}
                                  value={values.metaData[rowIndex].value}
                                  inputProps={{ maxLength: 500 }}
                                  onChange={(val) => {
                                    setErrors({});
                                    handleChange(val);
                                  }}
                                  error={
                                    errors?.metaData &&
                                    Boolean(errors.metaData[rowIndex]?.value)
                                  }
                                  sx={{
                                    "&.Mui-error": {
                                      border: "1px solid #e74c3c !important",
                                    },
                                  }}
                                />
                              </Box>
                            </TableCell>
                          </TableRow>
                          {errors?.metaData &&
                            Boolean(errors.metaData[rowIndex]?.key) && (
                              <AlertMessage
                                sx={{ mr: "15px" }}
                                message={errors.metaData[rowIndex]?.key}
                                className="margin-top15"
                                severity="error"
                              />
                            )}
                          {errors?.metaData &&
                            Boolean(errors.metaData[rowIndex]?.value) && (
                              <AlertMessage
                                sx={{ mr: "15px" }}
                                message={errors.metaData[rowIndex]?.value}
                                className="margin-top15"
                                severity="error"
                              />
                            )}
                        </Fragment>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box
              ml={7.5}
              mt="18px"
              mr="15px"
              display="flex"
              justifyContent="space-between"
              alignItems="baseline"
            >
              <Box
                className="pointer-cursor add-metadata-container"
                onClick={() => {
                  if (values?.metaData?.length < 50) {
                    arrayHelpers.push({ key: "", value: "" });
                    setErrors({});
                  }
                }}
              >
                {values?.metaData?.length < 50 && (
                  <>
                    <AddIcon
                      sx={{
                        width: "16px",
                        height: "16px",
                        color: "inherit",
                      }}
                    />
                    <Text
                      className="default-text"
                      size={16}
                      variant="body1"
                      sx={{
                        color: "inherit !important",
                      }}
                      component="span"
                    >
                      {addAnotherItem}
                    </Text>
                  </>
                )}
              </Box>
              <Box>
                <Button
                  variant="outlined"
                  label={cancel}
                  onClick={() => {
                    resetForm(initialValues);
                    onCancel();
                  }}
                />
                <Button
                  style={{ margin: "0 0 0 28px" }}
                  variant="contained"
                  label={save}
                  onClick={handleSubmit}
                />
              </Box>
            </Box>
          </>
        )}
      </FieldArray>
    </FormikProvider>
  );
};

export default MetaDataForm;
