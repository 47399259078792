import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import { useEffect } from "react";
import { useLocation } from "react-router";
import { callAPIInterface, redirectToLogin } from "../constants";
import { useDispatch } from "react-redux";
import history from "@speed/common/src/components/history";
import { callAfterLoginFlow } from "../../redux/auth/actions";
import { authenticatingURL } from "@speed/common/src/messages";
import {
  apiCallSSOCommon,
  ssoData,
} from "@speed/common/src/components/constants";

function SocialMediaAuth() {
  const dispatch = useDispatch();
  const search = useLocation().search;

  const { signupMethod, authCode, decodedAuthState, userData } = ssoData(
    history,
    search
  );

  useEffect(() => {
    const { isFromSignUpPage } = decodedAuthState || {};

    let apiPayload = apiCallSSOCommon({
      decodedAuthState,
      authCode,
      redirectFn: redirectToLogin,
      signupMethod,
      userData,
    });
    if (decodedAuthState?.invitee_id)
      apiPayload.invitee_id = decodedAuthState.invitee_id;

    if (decodedAuthState?.affiliate_invite)
      apiPayload.affiliate_invite = decodedAuthState.affiliate_invite;

    if (apiPayload) {
      callAPIInterface("POST", "/sso", apiPayload)
        .then((response) => {
          callAfterLoginFlow(response, null, null, dispatch, isFromSignUpPage);
        })
        .catch((err) => {
          redirectToLogin();
        });
    }
  }, []);

  return (
    <BackdropLoader
      open={true}
      alt="Loading..."
      customClass="loading-in-auth"
      text={authenticatingURL}
    />
  );
}

export default SocialMediaAuth;
