import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Text } from "@speed/common/src/components/Text/Text";
import Box from "@mui/material/Box";
import { passwordPolicyLabel, passwordPolicy } from "../messages";

const PasswordPolicy = (props) => {
  const { isMobile } = props;
  const fromSignUp = props.from === "signup";
  const showMessage = (message, index) => {
    const styles = !fromSignUp && {
      color: props.activeState.includes(message) ? "#58c93f" : "#dcddde",
    };
    return (
      <Box
        className={`info-popper-box ${fromSignUp ? "sign-up" : ""}`}
        key={index}
      >
        {!fromSignUp && (
          <CheckCircleIcon style={styles} className="info-popper-icon" />
        )}
        <Text font="regular" size={isMobile ? 12 : 14} variant={"caption"}>
          {message}
        </Text>
      </Box>
    );
  };
  return (
    <>
      {!fromSignUp && (
        <Text size={props.fontSize} className="header-text">
          {passwordPolicyLabel}
        </Text>
      )}
      {passwordPolicy.map((item, index) => {
        return showMessage(item, index);
      })}
    </>
  );
};

export default PasswordPolicy;
