import React from "react";
import GetGuides from "./GetGuides";

const CashbackGuide = () => {
  return (
    <GetGuides
      moduleName="cashbacks"
      redirectUrl="/cashbacks"
      guideApiPath="cashbacks-guide"
      getStartedApiPath="Cashback"
      needHelpGuidePath="docs/send-payments/cashback"
    />
  );
};

export default CashbackGuide;
