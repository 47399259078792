import { createRef, useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Enabled2FABox from "./Enabled2FABox";
import Activate2FA from "./Activate2FA";
// import { addAppAuth, addSMSAuth } from "../../messages";
// import Button from "@speed/common/src/components/Button/Button";
import { callAPIInterface, getLowerCamelCaseText } from "../../constants";
import { useDispatch, useSelector } from "react-redux";
import { setProfileMfaAuthDetails } from "../../../redux/common/actions";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import { Box } from "@mui/material";

function TwoFactAuth() {
  const ref = createRef();
  const [loading, setLoading] = useState(false);
  const [removeMfaLoading, setRemoveMfaLoading] = useState(false);
  const [reverseType, setReverseType] = useState("");
  // const [buttonLabel, setButtonLabel] = useState("");
  // const [buttonType, setButtonType] = useState("");

  const profileMfaAuthDetails = useSelector(
    (state) => state.common.profileMfaAuthDetails
  );
  const user = useSelector((state) => state.auth.user);
  const dispatch = useDispatch();

  const smsConfigDetails = profileMfaAuthDetails?.filter(
    (item) => item.mfa_type.toLowerCase() === "sms"
  )[0];
  const showActiveSection = !smsConfigDetails?.is_mfa_phone_number_verified;

  // Launch Darkly Flags
  const {
    mfaGetuserconfigurations,
    mfaGettypes,
    mfaRemovemfa,
    mfaResendcode,
    mfaResendotpremoveconfigFe,
    mfaCaptchaVerifyFe,
  } = useFlags();
  const ldClient = useLDClient();

  useEffect(() => {
    if (user?.id) {
      const ldUser = {
        key: `anonymous-${user.id}`,
      };
      ldClient.identify(ldUser);
    }
  }, [user?.id]);
  const enable2FA = (type) => {
    // setButtonLabel(type === "sms" ? addAppAuth : addSMSAuth);
    // setButtonType(type === "sms" ? "authenticator" : "sms");
  };

  const handleAuthClick = (type) => {
    // setReverseType(type);
    // ref.current && ref.current.handleModalOpen(type);
  };

  const handleRemoveMfa = (type) => {
    // setButtonLabel(type === "sms" ? addSMSAuth : addAppAuth);
    // setButtonType(type);
    // setReverseType(type);
    setRemoveMfaLoading(true);
    ref.current && ref.current.handleRemoveSMSMfa();
  };

  const getMFAConfigDetails = async () => {
    setLoading(true);
    await callAPIInterface("GET", "/mfa")
      .then((response) => {
        dispatch(
          setProfileMfaAuthDetails(response?.data?.length ? response?.data : [])
        );
      })
      .catch(() => {});
    setLoading(false);
  };

  useEffect(() => {
    mfaGetuserconfigurations && getMFAConfigDetails();
  }, []);

  const commonLoaderStyle = {
    borderRadius: "8px",
  };

  const twoFactorAuthLoader = (
    <Box className="section-wrapper two-factor-container two-factor-loader">
      <CommonSkeleton
        width={250}
        height={28}
        color="#c4ccd2"
        sx={{ ...commonLoaderStyle, marginBottom: "18px" }}
      />
      <CommonSkeleton width={470} height={20} sx={commonLoaderStyle} />
    </Box>
  );

  const renderMFAComponent = (mfaDetails, index) => {
    const getElement = (details, mfaType, index, handleRemoveMfa) => (
      <Enabled2FABox
        loading={removeMfaLoading}
        mfaDetails={details}
        type={mfaType}
        key={index}
        handleRemoveMfa={handleRemoveMfa}
        mfaRemovemfa={mfaRemovemfa}
      />
    );

    switch (getLowerCamelCaseText(mfaDetails?.mfa_type)) {
      case "sms": {
        return (
          mfaDetails?.is_mfa_phone_number_verified &&
          getElement(mfaDetails, "sms", index, handleRemoveMfa)
        );
      }

      case "google_authenticator":
        return (
          mfaDetails?.is_mfa_authanticator_verified &&
          getElement(mfaDetails, "authenticator", index, handleRemoveMfa)
        );

      default:
        return null;
    }
  };

  return (
    <>
      <CustomDivider />
      <>
        {loading ? (
          twoFactorAuthLoader
        ) : (
          <>
            {(mfaGetuserconfigurations &&
              profileMfaAuthDetails?.length &&
              !showActiveSection) ||
            (mfaGetuserconfigurations && mfaGettypes) ? (
              <Paper
                elevation={0}
                className="section-wrapper two-factor-container"
              >
                <>
                  {profileMfaAuthDetails?.length
                    ? profileMfaAuthDetails.map((item, index) =>
                        renderMFAComponent(item, index)
                      )
                    : null}

                  {/* <Button
                    label={buttonLabel}
                    onClick={() => handleAuthClick(buttonType)}
                  /> */}
                </>
                <Activate2FA
                  setRemoveMfaLoading={setRemoveMfaLoading}
                  smsConfigDetails={smsConfigDetails}
                  mfaResendcode={mfaResendcode}
                  mfaRemoveResendcode={mfaResendotpremoveconfigFe}
                  mfaCaptchaVerifyCode={mfaCaptchaVerifyFe}
                  type={reverseType}
                  ref={ref}
                  enable2FA={enable2FA}
                  showActiveSection={showActiveSection}
                />
              </Paper>
            ) : (
              <AccessDenied />
            )}
          </>
        )}
      </>
    </>
  );
}

export default TwoFactAuth;
