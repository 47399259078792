import React from "react";
import {
  backToDashboard,
  helpGuideText,
  helpUs,
  paymentFailed,
  paymentFailedError,
  unableToProcess,
} from "../../messages";
import Button from "@speed/common/src/components/Button/Button";
import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import { paymentFailedImg } from "../../images";

const PaymentFailed = ({ handleModalClose, errorMsg }) => (
  <Box mt={"30px"} display="flex" flexDirection="column" alignItems="center">
    <img
      style={{ marginBottom: "16px" }}
      height="54px"
      width="54px"
      src={paymentFailedImg}
      alt="payment-failed"
    />
    <Text size={20} font="bold" className="default-text" variant="h6">
      {errorMsg.includes("has restricted") ? unableToProcess : paymentFailed}
    </Text>
    <Text
      size={16}
      font="regular"
      className="grey-text"
      variant="h6"
      sx={{ mt: "10px", width: "350px", textAlign: "center" }}
    >
      {errorMsg || paymentFailedError}
    </Text>
    <Box className="payment-failed-container">
      <Box display="flex" flexDirection="row" alignItems="center">
        <ContactSupportOutlinedIcon width={24} height={24} />
        <Text
          sx={{ ml: "6px" }}
          size={18}
          font="semibold"
          className="default-text"
          variant="h6"
        >
          {helpUs}
        </Text>
      </Box>
      <Text
        size={16}
        font="regular"
        className="grey-text"
        variant="h6"
        sx={{ mt: "15px", width: "492px", textAlign: "center" }}
      >
        {helpGuideText}
      </Text>
      <Box display="flex">
        <Button
          label={backToDashboard}
          onClick={() => {
            handleModalClose();
          }}
          type="submit"
          className="payment-failed"
        />
      </Box>
    </Box>
  </Box>
);

export default PaymentFailed;
