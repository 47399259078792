import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import { editEvents, eventsText } from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import CommonWebhookForm from "./CommonWebhookForm";

const ConfirmEndPointDetails = (props) => {
  const {
    values,
    setFieldValue,
    touched,
    setTouched,
    handleBackButton,
    handleClick,
    errors,
    versions,
    createWebhookPage,
  } = props;
  const defaultErrorMessageProps = {
    touched,
    errors,
  };
  return (
    <>
      <CommonWebhookForm
        values={values}
        setFieldValue={setFieldValue}
        touched={touched}
        setTouched={setTouched}
        errors={errors}
        defaultErrorMessageProps={defaultErrorMessageProps}
        versions={versions}
        createWebhookPage={createWebhookPage}
      />
      <Box
        className="margin-top30"
        display="flex"
        flexDirection="column"
        gap={2}
      >
        <Text sx={{ textTransform: "capitalize" }} size={16}>
          {eventsText[1]}
        </Text>
        <Box display="flex" gap={2} flexWrap="wrap">
          {values?.events?.map((event, eventIndex) => (
            <Tag
              key={eventIndex}
              text={event}
              onDelete={() => handleClick(event, true)}
              className="webhook-events-tag custom-payouts-status-label"
              variant="success-res"
            />
          ))}
        </Box>
        <Box>
          <Button
            icon="editIcon"
            onClick={handleBackButton}
            label={editEvents}
            variant="default"
            sx={{ color: "#2a67ff" }}
          />
        </Box>
      </Box>
    </>
  );
};

export default ConfirmEndPointDetails;
