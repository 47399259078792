import PropTypes from "prop-types";
import Switch from "@mui/material/Switch";
import "./switch.scss";

export const CustomSwitch = ({ checked, disabled, ...props }) => {
  return (
    <Switch
      className="switch"
      tabIndex={0}
      inputProps={{ "aria-label": "controlled" }}
      checked={checked}
      disabled={disabled}
      {...props}
    />
  );
};

export const propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  checked: true,
  disabled: false,
};

CustomSwitch.propTypes = propTypes;
CustomSwitch.defaultProps = defaultProps;
