import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import CommonHeader from "../Common/CommonHeader";
import AllPayments from "./AllPayments";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import "../../assets/styles/payments.scss";
import { useLocation } from "react-router-dom";
import {
  linkStatus,
  selectTabOnSearch,
  setQueryParamsForTab,
} from "@speed/common/src/components/constants";

function Payments() {
  const search = useLocation().search;

  const [selectedTab, setSelectedTab] = useState("1");
  const { history } = useSelector((state) => state.common);

  const tabData = [
    {
      label: "All",
      value: "1",
      tabContent: <AllPayments />,
      tabName: "all",
    },
    {
      label: linkStatus.paid.label,
      value: "2",
      tabContent: <AllPayments tab={[linkStatus.paid.value]} />,
      tabName: linkStatus.paid.value,
    },
    {
      label: linkStatus.unpaid.label,
      value: "3",
      tabContent: <AllPayments tab={[linkStatus.unpaid.value]} />,
      tabName: linkStatus.unpaid.value,
    },
    {
      label: linkStatus.expired.label,
      value: "4",
      tabContent: <AllPayments tab={[linkStatus.expired.value]} />,
      tabName: linkStatus.expired.value,
    },
    {
      label: linkStatus.cancelled.label,
      value: "5",
      tabContent: <AllPayments tab={[linkStatus.cancelled.value]} />,
      tabName: linkStatus.cancelled.value,
    },
  ];

  const handleChange = (_event, newValue) => {
    setSelectedTab(newValue);
    const selectedTabName = setQueryParamsForTab(tabData, newValue);
    handleSetQueryParam(selectedTabName);
  };

  const handleSetQueryParam = (searchValue) => {
    history.push({
      pathname: "/payments",
      search: searchValue ? `?status=${searchValue}` : null,
    });
  };

  useEffect(() => {
    //used when user searches manually
    const name = new URLSearchParams(search).get("status");
    selectTabOnSearch(name || null, tabData, setSelectedTab); //To set the tab
    handleSetQueryParam(name || null); // To set query params
  }, []);

  return (
    <Box className="section-wrapper">
      <CommonHeader />
      <Box className="payments-wrapper">
        <CustomTab
          onChange={handleChange}
          tabData={tabData}
          selectedTab={selectedTab}
          noScroll={true}
          // setTabRoute={(routeUrl) => { history.push(routeUrl) }}
        />{" "}
      </Box>
    </Box>
  );
}

export default Payments;
