import React, { useEffect, useState } from "react";
import Text from "@speed/common/src/components/Text/Text";
import { Grid, Box } from "@mui/material";
import { setup2faMsg, sendCode } from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import { QRCodeComponent } from "@speed/common/src/components/QRCode/QRCode";
import {
  callAPIInterface,
  CodeTextInput,
  concatedMobileNumber,
  mobileNumberWithoutCallingCode,
  QRCodeAndEnterTextToggle,
  redirectToLogin,
  Setup2FASubText,
  validatePhoneNumber,
} from "../constants";
import { useSelector } from "react-redux";
import { Countries } from "@speed/common/src/components/country";
import { useFormik } from "formik";
import * as yup from "yup";
import WithMobile2FA from "../Common/WithMobile2FA";
import { getCountryObj } from "@speed/common/src/components/constants";
import { sessionService } from "redux-react-session";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import { loadingMsg } from "@speed/common/src/components/messages";
import MainLogoLogin from "../Common/MainLogoLogin";
import NeedHelp from "./NeedHelp";

const Setup2FA = () => {
  const [usingPhone, setUsingPhone] = useState(true);
  const [byQRCode, setByQRCode] = useState(true);
  const [isFromAuth, setIsFromAuth] = useState(false);
  const [countryISOCode, setCountryISOCode] = useState();
  const [loading, setLoading] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [isBtnDisabled, setIsBtnDisabled] = useState(true);
  const [loginMfaAuthDetails, setLoginMFADetails] = useState([]);

  const { history, isMobile } = useSelector((state) => state.common);

  const isFromLoginPage = history?.location?.state?.from === "/login";
  const isFromVerifyEmailPage = history?.location?.state?.isFromVerifyEmailPage
    ? true
    : false;

  const handleTextChange = () => {
    setByQRCode(!byQRCode);
  };

  const handleDynamicWidthForMenu = async () => {
    document.body.style.overflow = "hidden";
    const formGridElement = await document.querySelector(".two-auth-grid");
    const callingCodeMenuElement = await document.querySelector(
      "#login-mfa-select-calling-code .MuiPaper-root"
    );

    if (isMobile && formGridElement && callingCodeMenuElement) {
      const dynamicWidth = formGridElement.offsetWidth + "px";
      callingCodeMenuElement.setAttribute(
        "style",
        `width:${dynamicWidth} !important`
      );
    }
  };

  const WithAuthenticator = () => {
    return (
      <>
        {byQRCode ? (
          <QRCodeComponent
            value="https://facebook.github.io/react/"
            size={200}
            icon
            iconSize={36}
          />
        ) : (
          <CodeTextInput value="uje6grortcozrml4avlsvlht" />
        )}
        <QRCodeAndEnterTextToggle
          byQRCode={byQRCode}
          onClick={handleTextChange}
        />
      </>
    );
  };

  const handleSubmitPhoneNumber = async () => {
    const session = await sessionService.loadSession();
    const phoneNumData = {
      session: session?.session,
      mfa_calling_code: values.mobileDialCode,
      mfa_phone_number: mobileNumberWithoutCallingCode(
        values.mobileDialCode,
        values.mobileNo
      ),
    };

    setBtnLoading(true);
    return callAPIInterface("POST", "/login/mfa/phone-number", phoneNumData)
      .then(async (response) => {
        await sessionService.saveSession({
          ...session,
          otp_expires_at: response.otp_expires_at,
          mfa_configurations: [response],
        });
        setBtnLoading(false);
        history.push({
          pathname: "/two-factor",
          state: {
            from: "/two-factor-setup",
            isFromVerifyEmailPage,
          },
        });
      })
      .catch(() => {
        setBtnLoading(false);
      });
  };

  const validationSchema = yup.object({
    mobileNo: yup.mixed().test("mobileNo", validatePhoneNumber).required(""),
  });

  const smsConfigDetails =
    loginMfaAuthDetails?.length &&
    loginMfaAuthDetails?.filter((item) => item.mfa_type === "SMS")[0];

  const mobileInputDetail = smsConfigDetails && {
    callingCode: smsConfigDetails?.mfa_calling_code,
    mobileNumber: concatedMobileNumber(
      smsConfigDetails?.mfa_calling_code,
      smsConfigDetails?.mfa_phone_number
    ),
  };

  const formik = useFormik({
    initialValues: {
      mobileDialCode: mobileInputDetail?.callingCode
        ? mobileInputDetail?.callingCode
        : "91",
      mobileNo: mobileInputDetail?.mobileNumber
        ? mobileInputDetail?.mobileNumber
        : "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmitPhoneNumber,
    enableReinitialize: true,
  });

  const {
    values,
    setFieldValue,
    setTouched,
    isValid,
    dirty,
    isSubmitting,
    errors,
    touched,
    handleSubmit,
  } = formik;

  useEffect(() => {
    setLoading(true);
    if (!isFromLoginPage) {
      redirectToLogin();
      return;
    }
    sessionService
      .loadSession()
      .then((session) => {
        if (session?.mfa_configurations) {
          setLoginMFADetails(session?.mfa_configurations);
        }
        setLoading(false);
      })
      .catch(() => {
        redirectToLogin();
      });
  }, []);

  useEffect(() => {
    history && setIsFromAuth(history.location.pathname === "/two-factor-setup");
  }, [history]);

  useEffect(() => {
    // Set country code according to calling_code
    let countryObj = getCountryObj("calling_code", values.mobileDialCode);
    setCountryISOCode(countryObj.iso2);
  }, [values.mobileDialCode]);

  useEffect(() => {
    setIsBtnDisabled(
      smsConfigDetails && mobileInputDetail.mobileNumber && isValid
        ? false
        : !(isValid && dirty) || isSubmitting
    );
  }, [isValid, dirty, isSubmitting, smsConfigDetails]);

  return (
    <>
      <MainLogoLogin />
      <Box className="container-layout">
        {!loading ? (
          <Grid
            container
            className="common-grid-wrapper signin-signup-container-layout"
            sx={{ width: "445px !important", padding: "0 !important" }}
          >
            <Grid
              display="flex"
              sx={{ padding: "40px" }}
              className="common-mobile-grid"
            >
              <Grid
                className="two-auth-grid"
                item
                component="form"
                onSubmit={handleSubmit}
              >
                <Text size={28} font="bold">
                  {setup2faMsg}
                </Text>
                <Setup2FASubText
                  isFromAuth={isFromAuth}
                  usingPhone={usingPhone}
                  byQRCode={byQRCode}
                />
                <Box width="100%">
                  {usingPhone ? (
                    <WithMobile2FA
                      setFieldValue={setFieldValue}
                      setTouched={setTouched}
                      countryISOCode={countryISOCode}
                      isFromAuth={isFromAuth}
                      values={values}
                      Countries={Countries}
                      touched={touched}
                      errors={errors}
                      handleDynamicWidthForMenu={handleDynamicWidthForMenu}
                    />
                  ) : (
                    <WithAuthenticator />
                  )}
                  <Button
                    loading={btnLoading}
                    type="submit"
                    className="submit-signin-btn margin-top30"
                    fullWidth
                    iconPosition="end"
                    icon="rightArrowIcon"
                    label={sendCode}
                    variant="contained"
                    disabled={isBtnDisabled}
                    // disabled={usingPhone ? !(isValid && dirty) || isSubmitting : false}
                  />
                </Box>
              </Grid>
            </Grid>
            <NeedHelp />
          </Grid>
        ) : (
          <BackdropLoader
            open={loading}
            alt="loading..."
            text={loadingMsg}
            customClass="loading-in-auth"
          />
        )}
      </Box>
    </>
  );
};

export default Setup2FA;
