import React from "react";
import GetGuides from "./GetGuides";

const PaymentLinkGuide = () => {
  return (
    <GetGuides
      moduleName="payment_link"
      redirectUrl="/payment-links"
      guideApiPath="payment-link-guide"
      getStartedApiPath="Payment"
      needHelpGuidePath="docs/receive-payments/payment-link"
    />
  );
};

export default PaymentLinkGuide;
