import React, { forwardRef } from "react";
import { Box } from "@mui/material";
import OneQRRightSidePreview from "./OneQRRightSidePreview";
import OneQRLinkForm from "../OneQR/OneQRLinkForm";
import OnQRLinkConfirmation from "./OnQRLinkConfirmation";

const CreateOneQRLink = forwardRef((props, ref) => {
  const {
    handleButtonDisable,
    isOneQRLink,
    confirmRef,
    handleDisableConfirmButton,
  } = props;

  return (
    <Box className="modal-container-grid" sx={{ display: "flex" }}>
      <Box className="inner-grid" sx={{ width: "45%" }}>
        <OneQRLinkForm
          handleButtonDisable={handleButtonDisable}
          isOneQRLink={isOneQRLink}
          ref={ref}
        />

        <OnQRLinkConfirmation
          isOneQRLink={isOneQRLink}
          oneQRLinkButtonDisable={handleDisableConfirmButton}
          ref={confirmRef}
        />
      </Box>
      <Box paddingLeft="70px" className="inner-grid">
        <OneQRRightSidePreview />
      </Box>
    </Box>
  );
});

export default CreateOneQRLink;
