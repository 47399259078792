import React, { useEffect, useState } from "react";
import { Box, IconButton, MenuItem } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import {
  addWallet,
  customizePaymentPage,
  displayAmountIn,
  enterPrivacyPolicyUrl,
  enterTermsUrlPlaceholder,
  privacyPolicyLabel,
  removeSpeedBrandLabel,
  selectWallet,
  showWalletLabel,
  speedBranding,
  termsOfServiceLabel,
  urlWithHttpValidate,
} from "../../messages";
import { useFormik, FieldArray, FormikProvider } from "formik";
import CustomSelect from "@speed/common/src/components/Select/Select";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import AddIcon from "@mui/icons-material/Add";
import { Input } from "@speed/common/src/components/Input/Input";
import { differenceBy, isEmpty } from "lodash";
import { callStrapiAPIInterface, validateURL } from "../../constants";
import * as yup from "yup";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import {
  setAccountPublicInfo,
  setSettingFormCancel,
  setSettingFormChange,
  setSettingFormValid,
  updatePaymentPageSettings,
  updatePublicInfo,
} from "../../../redux/common/actions";
import { reOrderWalletArray } from "@speed/common/src/components/constants";

const PaymentPageCustomizationForm = (props) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const [walletData, setWalletData] = useState([]);
  const [defaultWalletData, setDefaultWalletData] = useState([]);
  const [updatedWallets, setUpdatedWallets] = useState([]);
  const [defaultWalletObj, setDefaultWalletObj] = useState({
    name: "",
  });

  const currency = [
    { label: "BTC", value: "btc" },
    { label: "SATS", value: "sats" },
  ];

  const { settingFormSubmit, settingFormCancel, accountPublicInfo } =
    useSelector((state) => state.common);

  useEffect(() => {
    callStrapiAPIInterface(
      "GET",
      "/api/pay-via-wallets?populate=*&pagination[limit]=150"
    )
      .then((res) => {
        if (res.data) {
          const featuredList = [],
            unFeaturedList = [];
          res.data.forEach((item) => {
            const { name, logo, featured } = item.attributes;
            const walletObj = {
              id: item.id,
              name,
              logoUrl: logo.data.attributes.url,
              featured,
            };

            if (featured) featuredList.push(walletObj);
            else unFeaturedList.push(walletObj);
            if (name === "Speed Wallet") setDefaultWalletObj(walletObj);
          });
          const finalArray = [...featuredList, ...unFeaturedList];
          setWalletData(finalArray);
          setDefaultWalletData(finalArray);
        }
      })
      .catch(() => {});
  }, []);

  const initialValues = {
    display_amount_in_currency:
      accountPublicInfo?.display_amount_in_currency?.toLowerCase() || "btc",
    wallets: updatedWallets.length > 0 ? updatedWallets : [defaultWalletObj],
    terms_of_service_url: accountPublicInfo?.terms_of_service_url || "",
    privacy_policy_url: accountPublicInfo?.privacy_policy_url || "",
    showWallet: accountPublicInfo?.wallets?.length > 0 || false,
    show_payment_page_speed_branding:
      !accountPublicInfo?.show_payment_page_speed_branding || false,
  };

  const commonValidation = (name) =>
    yup.mixed().test({
      name: name,
      message: urlWithHttpValidate,
      test: (name) => validateURL(name, true),
    });

  const validationSchema = yup.object({
    terms_of_service_url: commonValidation("terms_of_service_url"),
    privacy_policy_url: commonValidation("privacy_policy_url"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const {
    values,
    setFieldValue,
    setErrors,
    resetForm,
    touched,
    errors,
    setTouched,
    isValid,
    dirty,
    isSubmitting,
  } = formik;

  useEffect(() => {
    if (values.showWallet) {
      formData["wallets"] = values.wallets;
      setFormData(formData);
    } else delete formData.wallets;
  }, [values.wallets, values.showWallet]);

  useEffect(() => {
    dispatch(setSettingFormValid(!(isValid && dirty) || isSubmitting));
  }, [isValid, dirty, isSubmitting]);

  useEffect(() => {
    dispatch(setSettingFormChange(dirty));
  }, [values]);

  useEffect(() => {
    // get values and call action to update this change into DB
    if (
      Object.keys(formData)?.length !== 0 &&
      isValid &&
      dirty &&
      settingFormSubmit
    ) {
      const formattedWallets = values.wallets?.map((item) => {
        return item.name;
      });
      formData["wallets"] = values.showWallet ? formattedWallets : [];
      dispatch(updatePublicInfo(formData))
        .then((response) => {
          dispatch(setAccountPublicInfo(response));
          !values.showWallet && setUpdatedWallets([]);
        })
        .catch(() => {});
    }
    setFormData({});
  }, [settingFormSubmit]);

  useEffect(() => {
    if (settingFormCancel) {
      setFormData({});
      resetForm();
      dispatch(setSettingFormCancel(false));
    }
  }, [settingFormCancel]);

  useEffect(() => {
    if (
      accountPublicInfo?.wallets?.length > 0 &&
      defaultWalletData.length > 0
    ) {
      const result = reOrderWalletArray(
        accountPublicInfo?.wallets,
        defaultWalletData
      );
      setUpdatedWallets(result);
      formData["wallets"] = result;
      setFormData(formData);
    }
  }, [accountPublicInfo, defaultWalletData]);

  const onChangeFormField = (fieldName, value, index) => {
    setFieldValue(fieldName, value);
    if (fieldName !== `wallets[${index}]`) {
      formData[fieldName] =
        fieldName === "show_payment_page_speed_branding" ? !value : value;
    }
    setFormData(formData);
  };

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  useEffect(() => {
    const result = differenceBy(defaultWalletData, values.wallets, "name");
    setWalletData(result);
  }, [values.wallets, defaultWalletData]);

  useEffect(() => {
    if (isEmpty(errors)) {
      const params = {
        display_amount_in_currency: values.display_amount_in_currency,
        show_payment_page_speed_branding:
          values.show_payment_page_speed_branding,
        terms_of_service_url: values.terms_of_service_url,
        privacy_policy_url: values.privacy_policy_url,
      };

      if (values.showWallet) {
        const filteredResults = values.wallets.filter(
          (item) => item.name !== ""
        );
        params["walletsLogo"] = filteredResults;
      }
      dispatch(updatePaymentPageSettings(params));
    }
  }, [values, errors]);

  const handleRemoveWallet = (arrayHelpers, index, value) => {
    arrayHelpers.remove(index);
    const isEmpty = Object.values(value).every((x) => x === null || x === "");
    if (!isEmpty) {
      const result = [value];
      setWalletData([...walletData, ...result]);
    }
  };

  return (
    <Box className="customization-form-wrapper">
      <Text font="semibold" size={18}>
        {customizePaymentPage}
      </Text>
      <CustomSelect
        customClass="currency-dropdown mt-20"
        name="display_amount_in_currency"
        showLabel
        label={displayAmountIn}
        value={values.display_amount_in_currency}
        MenuProps={{
          id: "display_amount_in_currency",
        }}
        onChange={(e) => {
          onChangeFormField("display_amount_in_currency", e.target.value);
        }}
        fullWidth={false}
      >
        {currency.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </CustomSelect>
      <CustomCheckbox
        checked={values.showWallet}
        label={showWalletLabel}
        className="mt-20 check-box"
        onChange={(_e, checked) => onChangeFormField("showWallet", checked)}
      />
      {values.showWallet && (
        <FormikProvider value={formik}>
          <FieldArray name="wallets">
            {(arrayHelpers) => (
              <>
                <Box>
                  {values.wallets?.map((wallet, index) => {
                    return (
                      <Box display="flex" alignItems="end" key={wallet.id}>
                        <CustomAutoComplete
                          customClass="wallet-dropdown mt-20"
                          name={`wallets[${index}]`}
                          options={walletData}
                          getOptionLabel={(value) => {
                            return value.name;
                          }}
                          onChange={(_e, value) => {
                            onChangeFormField(
                              `wallets[${index}]`,
                              value,
                              index
                            );
                          }}
                          value={values.wallets[index]}
                          showLabel={false}
                          fullWidth
                          placeholder={selectWallet}
                          disableClearable
                        />
                        {index !== 0 && (
                          <IconButton
                            sx={{ color: "#e74c3c" }}
                            className="wallet-remove-icon"
                            onClick={() =>
                              handleRemoveWallet(
                                arrayHelpers,
                                index,
                                values.wallets[index]
                              )
                            }
                          >
                            <RemoveCircleOutlinedIcon
                              sx={{ height: 24, width: 24 }}
                            />
                          </IconButton>
                        )}
                      </Box>
                    );
                  })}
                </Box>

                {values?.wallets?.length < 5 && (
                  <Box
                    className="add-wallet mt-20"
                    onClick={() => {
                      arrayHelpers.push({ name: "", value: "" });
                      setErrors({});
                    }}
                  >
                    <AddIcon
                      sx={{
                        width: "16px",
                        height: "16px",
                        color: "#2A67FF",
                      }}
                    />
                    <Text size={14} font="semibold" sx={{ color: "#2A67FF" }}>
                      {addWallet}
                    </Text>
                  </Box>
                )}
              </>
            )}
          </FieldArray>
        </FormikProvider>
      )}

      <Text size={16} font="medium" className="grey-text mt-20" variant="h6">
        {speedBranding}
      </Text>
      <CustomCheckbox
        sx={{ marginTop: "15px" }}
        checked={values.show_payment_page_speed_branding}
        label={removeSpeedBrandLabel}
        className="check-box"
        onChange={(_e, checked) =>
          onChangeFormField("show_payment_page_speed_branding", checked)
        }
      />
      <Box className="mt-20 terms-privacy-box">
        <Input
          sx={{ width: "80%" }}
          label={termsOfServiceLabel}
          value={values.terms_of_service_url}
          type="text"
          onChange={(e) => {
            setTouched({ ...touched, terms_of_service_url: false });
            onChangeFormField("terms_of_service_url", e.target.value);
          }}
          error={
            touched.terms_of_service_url && Boolean(errors.terms_of_service_url)
          }
          placeholder={enterTermsUrlPlaceholder}
          onBlur={(_e) => {
            setTouched({ ...touched, terms_of_service_url: true });
          }}
          fullWidth={true}
        />
        <InputErrorMessage
          {...defaultErrorMessageProps}
          inputName="terms_of_service_url"
          sx={{ width: "80%" }}
        />
      </Box>
      <Box className="mt-20 terms-privacy-box">
        <Input
          sx={{ width: "80%" }}
          label={privacyPolicyLabel}
          value={values.privacy_policy_url}
          type="text"
          onChange={(e) => {
            setTouched({ ...touched, privacy_policy_url: false });
            onChangeFormField("privacy_policy_url", e.target.value);
          }}
          error={
            touched.privacy_policy_url && Boolean(errors.privacy_policy_url)
          }
          placeholder={enterPrivacyPolicyUrl}
          onBlur={(_e) => {
            setTouched({ ...touched, privacy_policy_url: true });
          }}
        />
        <InputErrorMessage
          {...defaultErrorMessageProps}
          inputName="privacy_policy_url"
          sx={{ width: "80%" }}
        />
      </Box>
    </Box>
  );
};

export default PaymentPageCustomizationForm;
