import { Modal } from "@speed/common/src/components/Modal/Modal";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../../redux/auth/actions";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import { useState } from "react";
import Text from "@speed/common/src/components/Text/Text";
import { setShowAutomaticLogoutModal } from "../../redux/common/actions";
import {
  automaticLogoutCautionMessage,
  automaticLogoutModalHeader,
} from "../messages";
import { loggingOutMsg } from "@speed/common/src/components/messages";

function AutomaticLogoutModal() {
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const showAutomaticUserLogoutModal = useSelector(
    (state) => state.common.showAutomaticUserLogoutModal
  );
  const bodyContent = (
    <Text size={14} className="default-text">
      {automaticLogoutCautionMessage[0]}
      <br />
      {automaticLogoutCautionMessage[1]}
    </Text>
  );

  const handleClose = () => {
    setLoading(true);
    dispatch(setShowAutomaticLogoutModal(false));
    dispatch(logOut()).then(() => {
      setLoading(false);
    });
  };

  return (
    <>
      <BackdropLoader
        open={loading}
        alt="Logging out..."
        text={loggingOutMsg}
        customClass="loading-in-auth"
      />
      <Modal
        maxWidth="xs"
        body={bodyContent}
        handleClose={handleClose}
        open={showAutomaticUserLogoutModal}
        title={automaticLogoutModalHeader}
      />
    </>
  );
}

export default AutomaticLogoutModal;
