import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { validateURL } from "../constants";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  customMessageError,
  defaultSuccessMessage,
  enterWebsiteURLPlaceholder,
  messagePlaceholder,
  redirectionWebsiteInfoText,
  showSuccessMessage,
  successMessage,
  successURL,
  urlWithHttpValidate,
  websiteLabel,
  websiteURLError,
} from "../messages";
import { Box, Grid } from "@mui/material";
import { Input } from "@speed/common/src/components/Input/Input";
import Text from "@speed/common/src/components/Text/Text";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import _ from "lodash";

const OnQRLinkConfirmation = forwardRef((props, ref) => {
  const [showConfirmMsgOption, setShowConfirmMsgOption] = useState(true);
  const [showRedirectWebsiteOption, setShowRedirectWebsiteOption] =
    useState(false);

  const { oneQRLinkButtonDisable } = props;

  const websiteURLValidation = showRedirectWebsiteOption
    ? {
        website_url: yup
          .mixed()
          .test({
            name: "website_url",
            message: urlWithHttpValidate,
            test: (website_url) => validateURL(website_url, true),
          })
          .required(websiteURLError),
      }
    : {};

  const customMessageValidation = showConfirmMsgOption
    ? { custom_message: yup.string().required(customMessageError) }
    : {};

  const validationSchema = yup.object({
    ...customMessageValidation,
    ...websiteURLValidation,
  });

  const formik = useFormik({
    initialValues: {
      custom_message: defaultSuccessMessage,
      website_url: "",
    },
    validationSchema: validationSchema,
  });

  const { values, setFieldValue, setTouched, errors, touched } = formik;

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  useEffect(() => {
    if (
      (showConfirmMsgOption && values.custom_message === "") ||
      (showRedirectWebsiteOption && values.website_url === "")
    ) {
      oneQRLinkButtonDisable(true);
    } else {
      if (_.isEmpty(errors)) {
        oneQRLinkButtonDisable(false);
      } else {
        oneQRLinkButtonDisable(true);
      }
    }
  }, [showConfirmMsgOption, showRedirectWebsiteOption, values, errors]);

  const handleCheckboxChange = (event, option) => {
    switch (option) {
      case "confirmation":
        setShowConfirmMsgOption(!showConfirmMsgOption);
        onChangeOption("custom_message", !showConfirmMsgOption);
        break;
      case "redirect":
        setShowRedirectWebsiteOption(!showRedirectWebsiteOption);
        onChangeOption("website_url", !showRedirectWebsiteOption);
        break;
    }
  };

  const onChangeOption = (type, value) => {
    if (!value) {
      delete errors[type];
    }
  };

  const handleChangeMessage = (value) => {
    setFieldValue("custom_message", value);
  };

  useImperativeHandle(ref, () => ({
    handleSubmitConfirm: () => {
      let resultData = {};
      if (showConfirmMsgOption && values.custom_message !== "") {
        resultData.custom_message = values.custom_message;
      }
      if (showRedirectWebsiteOption && values.website_url !== "") {
        resultData.website_url = values.website_url;
      }
      return resultData;
    },
  }));

  return (
    <Grid container className="apply-auto-scroll">
      <Box sx={{ width: "480px" }}>
        <Grid
          padding="28px 3px"
          sx={{ display: !props.isOneQRLink ? "none" : "block" }}
        >
          <Text
            font="semibold"
            className="default-text"
            style={{ marginBottom: "22px" }}
            size={20}
            variant="h2"
          >
            {successMessage}
          </Text>

          <Box
            className={`confirmation-box ${
              showConfirmMsgOption && "active"
            } remove-cursor-pointer`}
          >
            <CustomCheckbox
              checked={showConfirmMsgOption}
              label={showSuccessMessage}
              className="checkbox-wrapper one-qr-checkbox"
              onChange={(event) => handleCheckboxChange(event, "confirmation")}
            />
            {showConfirmMsgOption && (
              <Box sx={{ marginLeft: "35px", marginTop: "12px" }}>
                <TextAreaComponent
                  showLabel={false}
                  value={values.custom_message}
                  autoFocus={showConfirmMsgOption}
                  maxRows={3}
                  minRows={3}
                  maxLength={500}
                  fullWidth
                  error={Boolean(errors.custom_message)}
                  onBlur={(_e) => {
                    setTouched({ ...touched, custom_message: true });
                  }}
                  onChange={(e) => {
                    handleChangeMessage(e.target.value);
                  }}
                  onFocus={(e) => {
                    let val = e.target.value;
                    e.target.value = "";
                    e.target.value = val;
                  }}
                  placeholder={messagePlaceholder}
                />
                <InputErrorMessage
                  {...defaultErrorMessageProps}
                  inputName="custom_message"
                />
              </Box>
            )}
          </Box>

          <Box
            className={`confirmation-box ${
              showRedirectWebsiteOption && "active"
            } remove-cursor-pointer`}
            style={{ marginTop: "20px" }}
          >
            <CustomCheckbox
              checked={showRedirectWebsiteOption}
              label={successURL}
              className="checkbox-wrapper one-qr-checkbox"
              onChange={(event) => handleCheckboxChange(event, "redirect")}
            />
            <Text
              className="grey-text"
              size={12}
              font="regular"
              variant="subtitle1"
              style={{ marginLeft: "30px" }}
            >
              {redirectionWebsiteInfoText}
            </Text>
            {showRedirectWebsiteOption && (
              <Box sx={{ marginLeft: "30px", marginTop: "15px" }}>
                <>
                  <Input
                    showLabel
                    value={values.website_url}
                    autoFocus={true}
                    name="website_url"
                    label={websiteLabel}
                    type="text"
                    fullWidth
                    error={Boolean(errors.website_url)}
                    placeholder={enterWebsiteURLPlaceholder}
                    onBlur={(_e) => {
                      setTouched({ ...touched, website_url: true });
                    }}
                    onChange={(e) =>
                      setFieldValue("website_url", e.target.value)
                    }
                  />

                  <InputErrorMessage
                    {...defaultErrorMessageProps}
                    inputName="website_url"
                  />
                </>
              </Box>
            )}
          </Box>
        </Grid>
      </Box>
    </Grid>
  );
});

export default OnQRLinkConfirmation;
