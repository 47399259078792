import PropTypes from "prop-types";
import Slide from "@mui/material/Slide";
import Box from "@mui/material/Box";
import { Text } from "../Text/Text";
import "./hello-bar.scss";
import { useEffect, useState } from "react";
import { isLightColor } from "../constants";

export const HelloBar = ({
  descriptionText,
  color,
  besideComponent = null,
  startIcon,
  id,
  hide,
}) => {
  const [textColor, setTextColor] = useState("#fff");

  useEffect(() => {
    isLightColor(color) ? setTextColor("#0a193e") : setTextColor("#fff");
  }, [color]);

  const content = (
    <Box
      className="hellobar-wrapper"
      sx={{ background: color }}
      id={id}
      display={hide ? "none !important" : "flex !important"}
    >
      <Text
        variant="body1"
        font="regular"
        className="desc-text"
        size={14}
        sx={{ color: textColor }}
        withIcon={startIcon ? "start" : ""}
      >
        {startIcon}
        {descriptionText}
      </Text>
      <Box
        className="beside-component content-center"
        sx={{ color: textColor }}
      >
        {besideComponent}
      </Box>
    </Box>
  );
  return (
    <Slide direction="down" in={true}>
      {content}
    </Slide>
  );
};

export const propTypes = {
  descriptionText: PropTypes.string,
  color: PropTypes.string,
  besideComponent: PropTypes.any,
  startIcon: PropTypes.any,
  hide: PropTypes.bool,
};

const defaultProps = {
  descriptionText: "",
  color: "#2A67FF",
  besideComponent: null,
  startIcon: null,
  hide: false,
};

HelloBar.propTypes = propTypes;
HelloBar.defaultProps = defaultProps;
