import React, { useEffect, useState } from "react";
import Text from "@speed/common/src/components/Text/Text";
import { Grid, Box } from "@mui/material";
import { Input } from "@speed/common/src/components/Input/Input";
import {
  confirmPasswordLabel,
  confirmPasswordValidate,
  newPasswordLabel,
  newPasswordMsg,
  newPasswordSubMsg,
} from "../messages";
import {
  loadingMsg,
  passwordPlaceholder,
} from "@speed/common/src/components/messages";
import {
  passwordValidationSchema,
  passwordPolicyState,
  sourceWalletApp,
} from "../constants";
import { Button } from "@speed/common/src/components/Button/Button";
import { useFormik } from "formik";
import * as yup from "yup";
import PasswordPolicy from "@speed/common/src/components/PasswordPolicy";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import { useDispatch, useSelector } from "react-redux";
import { sessionService } from "redux-react-session";
import {
  checkLinkStatus,
  verifyForgotPassword,
} from "../../redux/auth/actions";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import LinkStatus from "../Common/LinkStatus";
import MainLogoLogin from "../Common/MainLogoLogin";
import { save } from "@speed/common/src/messages";

const NewPassword = () => {
  const dispatch = useDispatch();
  const { history, isMobile } = useSelector((state) => state.common);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const [activeState, setActiveState] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isLink, setIsLink] = useState(false);
  const [linkStatus, setLinkStatus] = useState("");
  const [newPasswordScreen, setNewPasswordScreen] = useState(false);

  const handleRedirection = async (user) => {
    const searchURL = history.location.search.replace("+", "%2B");
    const data = new URLSearchParams(searchURL);
    const link = data.get("id") || (user && user.verification_link);
    const userEmail = data.get("email") || (user && user.email);
    const source = data.get("source");

    if (link) {
      setLoading(true);
      if (source === sourceWalletApp && !isMobile) {
        window.location = `${process.env.REACT_APP_CHROME_EXT_ENDPOINT}#/new-password?id=${link}&email=${userEmail}`;
      } else {
        await sessionService
          .saveUser({ verification_link: link })
          .then((_res) => history.push("/new-password"));
        dispatch(checkLinkStatus(link))
          .then((res) => {
            if (res) {
              setLoading(false);
              setNewPasswordScreen(true);
            }
          })
          .catch((e) => {
            setIsLink(true);
            const err = e?.response?.data?.errors;
            const errorType = err?.[0]?.type?.toLowerCase();
            if (
              errorType === "link_expired" ||
              errorType === "link_already_used"
            )
              setLinkStatus("expired");
            else history.push("/dashboard");
            setLoading(false);
          });
      }
    } else {
      history.push("/reset-password");
    }
  };

  useEffect(async () => {
    const user = await sessionService
      .loadUser()
      .then((res) => res)
      .catch(() => {});
    handleRedirection(user);
  }, []);

  const validationSchema = yup.object({
    newPassword: passwordValidationSchema,
    confirmPassword: yup
      .string()
      .when("newPassword", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([yup.ref("newPassword")], confirmPasswordValidate),
      })
      .required(""),
  });

  const handleSave = async (passwordValues) => {
    setLoading(true);
    const user = await sessionService.loadUser();
    const params = {
      verification_link: user.verification_link,
      password: passwordValues.newPassword,
    };
    dispatch(verifyForgotPassword(params, isLoggedIn, user)).then((_res) => {
      setLoading(false);
    });
  };

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: handleSave,
  });

  const {
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    dirty,
    handleSubmit,
    setFieldValue,
    setTouched,
  } = formik;

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const handleBlur = (name, value) => {
    setTouched({ ...touched, [name]: value });
  };
  const handleInputChange = (name, value) => {
    if (name === "newPassword") {
      setActiveState(passwordPolicyState(value));
    }
    setTouched({ ...touched, [name]: false });
    setFieldValue([name], value);
  };

  return (
    <>
      <BackdropLoader
        open={isLoading}
        alt="loading..."
        text={loadingMsg}
        customClass="loading-in-auth"
      />
      {isLink
        ? !isLoading && <LinkStatus from="resetPassword" type={linkStatus} />
        : newPasswordScreen && (
            <>
              <MainLogoLogin />
              <Box className="container-layout">
                <Grid
                  container
                  justifyContent="center"
                  direction="column"
                  className="common-grid-wrapper common-mobile-grid signin-signup-container-layout"
                >
                  <Text size={isMobile ? 22 : 30} font="bold">
                    {newPasswordMsg}
                  </Text>
                  <Text
                    size={isMobile ? 14 : 18}
                    font="regular"
                    variant="subtitle1"
                    className="margin-top10"
                  >
                    {newPasswordSubMsg}
                  </Text>
                  <Grid
                    item
                    className="email-password-grid"
                    component="form"
                    onSubmit={handleSubmit}
                    xs={12}
                    sm={7.5}
                    md={7.5}
                    lg={7.5}
                  >
                    <Input
                      customClass="margin-top30"
                      type="password"
                      isPasswordVisible={true}
                      label={newPasswordLabel}
                      value={values.newPassword}
                      name="newPassword"
                      fullWidth
                      inputProps={{ maxLength: 64 }}
                      placeholder={passwordPlaceholder}
                      onBlur={() => handleBlur("newPassword", true)}
                      onChange={(e) =>
                        handleInputChange("newPassword", e.target.value)
                      }
                      error={touched.newPassword && Boolean(errors.newPassword)}
                    />
                    <InputErrorMessage
                      {...defaultErrorMessageProps}
                      inputName="newPassword"
                    />
                    <Input
                      customClass="margin-top30"
                      type="password"
                      isPasswordVisible={true}
                      label={confirmPasswordLabel}
                      value={values.confirmPassword}
                      name="confirmPassword"
                      fullWidth
                      inputProps={{ maxLength: 64 }}
                      placeholder={passwordPlaceholder}
                      onBlur={() => handleBlur("confirmPassword", true)}
                      onChange={(e) =>
                        handleInputChange("confirmPassword", e.target.value)
                      }
                      error={
                        touched.confirmPassword &&
                        Boolean(errors.confirmPassword)
                      }
                    />
                    <InputErrorMessage
                      {...defaultErrorMessageProps}
                      inputName="confirmPassword"
                    />
                    <Button
                      fullWidth
                      className="submit-signin-btn margin-top30"
                      iconPosition="end"
                      icon="rightArrowIcon"
                      label={save}
                      type="submit"
                      disabled={!(isValid && dirty) || isSubmitting}
                    />
                    <Box className="password-policy-block reset">
                      <PasswordPolicy
                        fontSize={16}
                        activeState={activeState}
                        isMobile={isMobile}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </>
          )}
    </>
  );
};

export default NewPassword;
