import CustomTable from "@speed/common/src/components/Table/Table";
import React, { useState, useCallback, useEffect } from "react";
import { Box, TableCell, TableRow } from "@mui/material";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  noOfRecords,
  renderStatus,
  RouteBreadcrumbs,
} from "../constants";
import {
  generateBreadcrumbs,
  satsToBtcAmount,
} from "@speed/common/src/components/constants";
import { emptyPayment } from "../images";
import { useParams, Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { emptySubText } from "@speed/common/src/components/messages";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import "../../assets/styles/oneQR.scss";
import history from "@speed/common/src/components/history";

const AllOneQRPayments = (props) => {
  const queryParam = "?limit=" + noOfRecords;
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });

  const [hasMore, setHasMore] = useState(true);
  const [rows, setRows] = useState([]);
  const [queryParams, setQueryParams] = useState(queryParam);
  const urlParams = useParams();
  const [tableRowSkeleton, setTableRowSkeleton] = useState(true);

  const liveMode = useSelector((state) => state.auth.liveMode);
  useEffect(() => {
    setRows([]);
    setHasMore(true);
    getLatestPaymentList([], queryParam);
  }, [liveMode]);

  const columns = [
    { title: "Payment ID", width: "288px", paddingLeft: "35px !important" },
    { title: "Amount (BTC)", width: "200px", align: "right" },
    { title: "Date", width: "249px" },
    { title: "Status", width: "84px" },
  ];

  let columnsData = columns.map((column, index) => (
    <TableCell
      key={index}
      align={column.align}
      sx={{
        width: column.width,
        paddingLeft: column.paddingLeft,
      }}
    >
      {column.title}
    </TableCell>
  ));

  const getLatestPaymentList = async (lines, params) => {
    await callAPIInterface("GET", `/oneqrs/${urlParams?.id}/payments${params}`)
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam + "&ending_before=" + res.data[res.data.length - 1].id
            );
          }
          setRows(lines.concat(res.data));
        }
      })
      .catch(() => {
        setTableRowSkeleton(false);
      });
  };

  const rowsData =
    rows &&
    rows.map((rowItem) => {
      return (
        <TableRow
          key={rowItem.id}
          className="clickable"
          onClick={() => history.push(`/payments/` + rowItem.id)}
        >
          <TableCell sx={{ paddingLeft: "35px !important" }}>
            {rowItem.id}
          </TableCell>
          <TableCell align="right">
            {satsToBtcAmount(
              rowItem.status === "paid"
                ? rowItem.target_amount_paid
                : rowItem.target_amount
            )}
          </TableCell>
          <TableCell align="left">
            {dateTimeFormatInApp(rowItem?.created)}
          </TableCell>
          <TableCell align="left">{renderStatus(rowItem?.status)}</TableCell>
        </TableRow>
      );
    });

  const loadMore = useCallback(() => {
    getLatestPaymentList(rows, queryParams);
  }, [rows]);

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
    className: "oneQR-all-payment-table",
    textOnNoData: emptySubText(liveMode, "Payments"),
    noDataImage: emptyPayment,
  };

  return (
    <Box className="section-wrapper">
      <Box className="one-qr-all-payment">
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      </Box>
      <Box className="main-content">
        <CustomTable {...tableProps} />
      </Box>
    </Box>
  );
};

export default AllOneQRPayments;
