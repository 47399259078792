import _ from "lodash";
import React, { useEffect, useState, createRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import {
  AddMemberTitle,
  invited,
  addMemberLabel,
  membersLabel,
} from "../../messages";
import { Box } from "@mui/material";
import Members from "./Members";
import InviteMembers from "./InviteMembers";
import Button from "@speed/common/src/components/Button/Button";
import RoleMember from "./RoleMember";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import { callAPIInterface } from "../../constants";
import { setLoading, showToast } from "../../../redux/common/actions";
import { continueBtn } from "@speed/common/src/messages";

const UsersAccounts = () => {
  const [selectedTab, setSelectedTab] = useState("1");
  const history = useSelector((state) => state.common.history);
  const currentAccount = useSelector((state) => state.auth.currentAccount);
  const [roles, setRoles] = useState([]);
  const [addOpenModal, setAddOpenModal] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const ref = createRef();
  const [inviteUser, setInviteUser] = useState(false);
  const [rolesName, setRolesName] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    getRoles();
  }, []);

  useEffect(() => {
    getRolesName();
  }, [roles]);

  const tabData = [
    {
      label: membersLabel,
      value: "1",
      tabContent: currentAccount && (
        <Members roles={roles} rolesName={rolesName} />
      ),
      route: "/settings/team-members",
    },
    {
      label: invited,
      value: "2",
      tabContent: currentAccount && (
        <InviteMembers
          rolesName={rolesName}
          inviteUser={inviteUser}
          handleInviteUser={() => setInviteUser(false)}
        />
      ),
      route: "/settings/invite-members",
    },
  ];

  const getRolesName = async () => {
    let roleName = [];
    _.forEach(roles, function (value, key) {
      roleName[value.id] = value.role_name;
    });
    await setRolesName(roleName);
  };

  const handleChange = (_event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleAddModalClose = () => {
    setDisabled(true);
    setAddOpenModal(false);
  };

  const getRoles = async () => {
    await callAPIInterface("GET", "/roles")
      .then(async (response) => {
        if (response) {
          setRoles(response.data);
        }
      })
      .catch((_e) => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    const addMemberData = ref.current && ref.current.handleSubmit();
    setDisabled(true);
    if (addMemberData) {
      callAPIInterface("POST", "/invite-users", JSON.stringify(addMemberData))
        .then(() => {
          setAddOpenModal(false);
          setDisabled(true);
          setInviteUser(true);
          history.push("/settings/invite-members");
        })
        .catch((errors) => {
          if (errors?.response) {
            dispatch(
              showToast({
                isToastOpen: true,
                toastMessage: errors.response.data?.errors?.[0]?.message,
                toastVariant: "error",
              })
            );
          }
        });
    }
  };

  useEffect(() => {
    if (history.location.pathname) {
      let currentRoute = _.find(tabData, { route: history.location.pathname });
      if (currentRoute) {
        setSelectedTab(currentRoute.value);
      }
    }
  }, [history, history && history.location.pathname]);

  return (
    <Box className="users-and-teams">
      <Box className="action-btn-wrapper mui-fixed">
        {" "}
        <Button
          icon="addIcon"
          className="add-icon"
          label={addMemberLabel}
          variant="outlined"
          color="primary"
          onClick={() => setAddOpenModal(true)}
        />
      </Box>
      <CustomTab
        onChange={handleChange}
        tabData={tabData}
        selectedTab={selectedTab}
        setTabRoute={(routeUrl) => history.push(routeUrl)}
      />
      {addOpenModal === true && (
        <Modal
          maxWidth="sm"
          className="user-team-wrapper"
          body={
            <RoleMember roles={roles} ref={ref} setDisabled={setDisabled} />
          }
          footer={
            <Button
              label={continueBtn}
              disabled={disabled}
              onClick={handleSubmit}
            />
          }
          handleClose={handleAddModalClose}
          open={addOpenModal}
          title={AddMemberTitle}
        />
      )}
    </Box>
  );
};

export default UsersAccounts;
