import Button from "@speed/common/src/components/Button/Button";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import {
  deleteCustomerInstructions,
  deleteCustomerText,
  deleteText,
} from "../messages";
import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";

const DeleteCustomer = ({ openModal, setOpenModal }) => {
  const handleModalClose = () => setOpenModal(false);
  const handleDeleteCustomer = () => {
    handleModalClose();
  };

  const renderModalContent = () => {
    return (
      <>
        <Text size={14} className="default-text" variant="h1" font="regular">
          {deleteCustomerInstructions[0]}
        </Text>
        <Text
          size={14}
          className="default-text"
          variant="h1"
          font="regular"
          sx={{ marginTop: "20px" }}
        >
          {deleteCustomerInstructions[1]}
        </Text>
      </>
    );
  };

  const renderModalFooter = () => {
    return (
      <Box className="customer-delete-modal-footer">
        <Button
          className="delete-btn"
          color="error"
          type="submit"
          label={deleteText}
          onClick={handleDeleteCustomer}
          disabled={false}
        />
      </Box>
    );
  };

  return (
    <Modal
      maxWidth="sm"
      className="delete-customer-modal"
      open={openModal}
      body={renderModalContent()}
      footer={renderModalFooter()}
      handleClose={handleModalClose}
      title={deleteCustomerText}
    />
  );
};
export default DeleteCustomer;
