import React, { useEffect } from "react";
import { Box, Grid, CssBaseline } from "@mui/material";
import { useSelector } from "react-redux";
import Button from "@speed/common/src/components/Button/Button";
import { Text } from "@speed/common/src/components/Text/Text";
import { signIn } from "@speed/common/src/components/messages";
import { whiteLogo } from "@speed/common/src/components/images";
import {
  signup,
  alreadyHaveAccount,
  affiliateSignInSubtext,
  createANewAccount,
  affiliateSignUpSubtext,
  or,
} from "../messages";
import WelcomeBox from "../Common/WelcomeBox";
import {
  affiliatePartnerData,
  isAffiliatePartnerDataAvailable,
} from "../constants";

const JoinAffiliatePartner = () => {
  const { isMobile, history } = useSelector((state) => state.common);
  const fromPartnerDataAvailable = isAffiliatePartnerDataAvailable();

  useEffect(() => {
    !fromPartnerDataAvailable && history.push("/login");
  }, [fromPartnerDataAvailable]);

  return (
    <Grid
      className="sign-up"
      container
      component="main"
      direction={isMobile ? "column" : "row"}
      sx={{
        width: "auto",
      }}
    >
      <CssBaseline />
      <Box
        sx={{
          display: "flex",
          justifyContent: !isMobile ? "space-between" : "center",
          width: "100%",
        }}
      >
        {!isMobile && (
          <Box sx={{ paddingLeft: "150px", paddingTop: "60px" }}>
            <WelcomeBox data={affiliatePartnerData()} />
          </Box>
        )}
        <Box
          sx={!isMobile ? { paddingRight: "150px", marginBottom: "50px" } : {}}
        >
          {isMobile && (
            <Box className="welcome-box logo-signup">
              <img alt="main-logo" src={whiteLogo} width={150} height={48} />
            </Box>
          )}
          <Grid
            item
            elevation={4}
            className={!isMobile ? "signup-form" : "signup-mobile-form"}
            padding={isMobile ? "0px 37px" : ""}
          >
            <Text size={isMobile ? 22 : 30} font="bold">
              {alreadyHaveAccount}
            </Text>
            <Box sx={{ padding: "10px 0 24px 0" }}>
              <Text
                size={18}
                font="regular"
                className="default-text text margin-top10 lineHeight"
                variant="inline"
              >
                {affiliateSignInSubtext(affiliatePartnerData()?.account_name)}
              </Text>
            </Box>
            <Button
              className="margin-top30"
              color="primary"
              fullWidth
              label={signIn}
              variant="contained"
              onClick={() => history.push("/login")}
            />
            <Text
              size={30}
              sx={{ mt: "45px" }}
              align="center"
              className="grey-text"
              font="regular"
            >
              {or}
            </Text>
            <Text size={isMobile ? 22 : 30} sx={{ mt: "45px" }} font="bold">
              {createANewAccount}
            </Text>
            <Box sx={{ padding: "10px 0 24px 0" }}>
              <Text
                size={18}
                font="regular"
                className="default-text text margin-top10 lineHeight"
                variant="inline"
              >
                {affiliateSignUpSubtext(affiliatePartnerData()?.account_name)}
              </Text>
            </Box>
            <Button
              className="margin-top30"
              color="primary"
              fullWidth
              label={signup}
              variant="contained"
              onClick={() => history.push("/register")}
            />
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
};

export default JoinAffiliatePartner;
