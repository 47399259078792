import { useSelector, useDispatch } from "react-redux";
import {
  setSettingFormSubmit,
  setSettingFormChange,
  setSettingFormCancel,
} from "../../redux/common/actions";
import { closeAccount } from "../messages";
import { cancel } from "@speed/common/src/components/messages";
import { save } from "@speed/common/src/messages";
import { useLocation } from "react-router";
import { closeAccountPaths } from "../constants";
import CommonSettingsFooter from "@speed/common/src/components/CommonSettingsFooter";

function Footer() {
  const {
    settingFormValid,
    settingFormChange,
    settingFormSubmit,
    settingFormCloseAccount,
  } = useSelector((state) => state.common);
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    settingFormChange && (
      <CommonSettingsFooter
        firstBtnProps={{
          label:
            settingFormCloseAccount &&
            closeAccountPaths?.includes(location?.pathname)
              ? closeAccount
              : save,
          disabled: settingFormValid,
          loader: settingFormSubmit,
          color:
            settingFormCloseAccount &&
            closeAccountPaths?.includes(location?.pathname)
              ? "error"
              : "primary",
          handleFirstBtnClick: () => dispatch(setSettingFormSubmit(true)),
        }}
        secondBtnProps={{
          label: cancel,
          disabled: settingFormSubmit,
          handleSecondBtnClick: () => {
            dispatch(setSettingFormChange(false));
            dispatch(setSettingFormCancel(true));
            dispatch(setSettingFormSubmit(false));
          },
        }}
      />
    )
  );
}

export default Footer;
