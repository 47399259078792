import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  InputBase,
  Skeleton,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import Text from "@speed/common/src/components/Text/Text";
import { accordionIcons } from "@speed/common/src/components/images";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import {
  emptyEventsFromSearch,
  eventsText,
  searchEvents,
  selectAllEvents,
  selectEventsToSend,
} from "../messages";
import NoDataAvailable from "@speed/common/src/components/NoDataAvailable";
import { emptyWebhook } from "../images";
import { emptySubText } from "@speed/common/src/components/messages";

const EventForm = (props) => {
  const {
    events,
    isCheckAll,
    isGlobalCheckAll,
    expanded,
    handleChangeAccordion,
    list,
    handleClick,
    handleSelectAll,
    handleGlobalSelectAll,
    counts,
    liveMode,
    eventsSkeleton,
  } = props;
  const [filterValue, setFilterValue] = useState();
  const [dataToShow, setDataToShow] = useState(list);

  useEffect(() => {
    if (filterValue) {
      const result = [];
      list.forEach((item) => {
        item.enabled_events.forEach(
          (e) => e.enabled_event.includes(filterValue) && result.push(e)
        );
      });
      setDataToShow(result);
    }
  }, [filterValue]);

  return (
    <>
      <Box display="flex" justifyContent="space-between">
        <Text
          variant="subtitle1"
          size={20}
          font="semibold"
          className="margin-top30"
        >
          {selectEventsToSend}
        </Text>
        {!filterValue && (
          <Box display="flex" alignItems="center" className="margin-top30">
            <CustomCheckbox
              label=""
              onChange={handleGlobalSelectAll}
              sx={{ marginRight: 0 }}
              checked={isGlobalCheckAll}
              disabled={eventsSkeleton}
            />
            <Text
              className="default-text"
              size={16}
              variant="body1"
              font="semibold"
            >
              {selectAllEvents}
            </Text>
          </Box>
        )}
      </Box>
      <Box className="margin-top30">
        <InputBase
          fullWidth
          className="search-webhook-events-input"
          startAdornment={
            <SearchIcon
              sx={{
                height: 18,
                width: 18,
                marginRight: 1,
              }}
              htmlColor="#848b9e"
              onClick={() => {}}
            />
          }
          onChange={(e) => setFilterValue(e.target.value)}
          placeholder={searchEvents}
          showLabel={false}
          value={filterValue}
          type="text"
          disabled={eventsSkeleton}
        />
      </Box>
      <Box
        className="margin-top30"
        border={filterValue ? "none" : "solid 1px #e4e9ee"}
        borderRadius={1}
        overflow="hidden auto"
        maxHeight="65vh"
      >
        {filterValue ? (
          dataToShow?.length > 0 ? (
            dataToShow?.map((dataItem, dataIndex) => {
              return (
                <Box
                  display="flex"
                  alignItems="start"
                  key={`${dataItem}_${dataIndex}`}
                  p="8px 2px"
                >
                  <CustomCheckbox
                    label=""
                    onChange={() =>
                      handleClick(
                        dataItem?.enabled_event,
                        events?.includes(dataItem?.enabled_event)
                      )
                    }
                    sx={{ marginRight: 0 }}
                    checked={events?.includes(dataItem?.enabled_event)}
                  />
                  <Box>
                    <Text
                      className="default-text"
                      size={16}
                      variant="body1"
                      font="regular"
                    >
                      {dataItem?.enabled_event}
                    </Text>
                    <Text
                      className="grey-text"
                      size={14}
                      variant="body1"
                      font="regular"
                    >
                      {dataItem?.description}
                    </Text>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Box display="flex" justifyContent="center" alignItems="center">
              <NoDataAvailable
                text={emptySubText(liveMode, "events")}
                image={emptyWebhook}
                subText={emptyEventsFromSearch}
              />
            </Box>
          )
        ) : eventsSkeleton ? (
          Array(2)
            .fill()
            .map((x, i) => {
              return (
                <Box
                  p="16px 20px"
                  display="flex"
                  justifyContent="space-between"
                  border={0}
                  borderBottom="solid 1px #e4e9ee"
                  borderRadius={0}
                  key={i}
                >
                  <Skeleton
                    animation="wave"
                    width={100}
                    sx={{ bgcolor: "#eaeef1 !important" }}
                  />
                  <Skeleton
                    animation="wave"
                    width={60}
                    sx={{ bgcolor: "#eaeef166 !important" }}
                  />
                </Box>
              );
            })
        ) : (
          list?.map((listItem) => {
            return (
              <Accordion
                key={listItem?.title}
                expanded={expanded === listItem?.title}
                onChange={handleChangeAccordion(listItem?.title)}
                className="accordion-wrapper webhook-events-accordion"
                sx={{
                  border: "0 !important",
                  borderBottom: "solid 1px #e4e9ee !important",
                  borderRadius: "0 !important",
                }}
              >
                <AccordionSummary
                  expandIcon={
                    expanded === listItem?.title
                      ? accordionIcons["removeIcon"]
                      : accordionIcons["addIcon"]
                  }
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="95%"
                  >
                    <Box display="flex" alignItems="center" gap={1}>
                      <Text
                        variant="subtitle1"
                        size={16}
                        font="semibold"
                        sx={{ textTransform: "capitalize" }}
                      >
                        {listItem?.title?.replace(/_/g, " ")}
                      </Text>
                      {counts[listItem?.title] > 0 && (
                        <Tag
                          text={`${counts[listItem?.title]} selected`}
                          variant="success"
                        />
                      )}
                    </Box>
                    <Box display="flex" alignItems="center" gap={1}>
                      <Text
                        className="grey-text"
                        variant="subtitle1"
                        size={14}
                        font="regular"
                      >
                        {`${listItem?.enabled_events?.length} ${
                          listItem?.enabled_events?.length > 1
                            ? eventsText[1]
                            : eventsText[0]
                        }`}
                      </Text>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box
                    p="16px 20px"
                    pt={0}
                    display="flex"
                    flexDirection="column"
                    gap={2}
                  >
                    {listItem?.enabled_events?.length > 1 && (
                      <Box display="flex" alignItems="center">
                        <CustomCheckbox
                          label=""
                          onChange={() =>
                            handleSelectAll(
                              listItem?.title,
                              isCheckAll?.includes(listItem?.title)
                            )
                          }
                          sx={{ marginRight: 0 }}
                          checked={isCheckAll?.includes(listItem?.title)}
                        />
                        <Text
                          className="default-text"
                          size={16}
                          variant="body1"
                          font="regular"
                        >
                          {`Select all ${listItem?.title?.replace(
                            /_/g,
                            " "
                          )} events`}
                        </Text>
                      </Box>
                    )}
                    {listItem?.enabled_events?.map((event, eventIndex) => {
                      return (
                        <Box display="flex" alignItems="start" key={eventIndex}>
                          <CustomCheckbox
                            label=""
                            onChange={() =>
                              handleClick(
                                event?.enabled_event,
                                events?.includes(event?.enabled_event)
                              )
                            }
                            sx={{ marginRight: 0 }}
                            checked={events?.includes(event?.enabled_event)}
                          />
                          <Box>
                            <Text
                              className="default-text"
                              size={16}
                              variant="body1"
                              font="regular"
                            >
                              {event?.enabled_event}
                            </Text>
                            <Text
                              className="grey-text"
                              size={14}
                              variant="body1"
                              font="regular"
                            >
                              {event?.description}
                            </Text>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })
        )}
      </Box>
    </>
  );
};

export default EventForm;
