import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CommonHeader from "../Common/CommonHeader";
import {
  payoutStatus,
  selectTabOnSearch,
  setQueryParamsForTab,
} from "@speed/common/src/components/constants";
import "../../assets/styles/payouts.scss";
import { all } from "../messages";
import PayoutsList from "./PayoutsList";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import { useLocation } from "react-router";
import GetStartedHeader from "@speed/common/src/components/GetStartedHeader/GetStartedHeader";
import { callStrapiAPIInterface } from "../constants";
import {
  setCaughtErrorInStrapi,
  setIsDataAvailable,
  setIsModalOpen,
} from "../../redux/common/actions";

const Payouts = () => {
  const search = useLocation().search;
  const { history, isDataAvailable, caughtErrorInStrapi, isModalOpen } =
    useSelector((state) => state.common);

  const [selectedTab, setSelectedTab] = useState("1");
  const [payoutGuide, setPayoutGuide] = useState(null);
  const [isGetHeaderInfoLoading, setGetHeaderInfoLoading] = useState(true);
  const liveMode = useSelector((state) => state.auth.liveMode);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsDataAvailable(true));
    getPayoutGuide();
    return () => {
      dispatch(setIsModalOpen(false));
      dispatch(setCaughtErrorInStrapi(false));
    };
  }, [liveMode]);

  const tabData = [
    {
      label: all,
      value: "1",
      tabContent: <PayoutsList />,
      tabName: "all",
    },
    {
      label: payoutStatus.unpaid.label,
      value: "2",
      tabContent: <PayoutsList tab={payoutStatus.unpaid.value} />,
      tabName: payoutStatus.unpaid.value,
    },
    {
      label: payoutStatus.paid.label,
      value: "3",
      tabContent: <PayoutsList tab={payoutStatus.paid.value} />,
      tabName: payoutStatus.paid.value,
    },
    {
      label: payoutStatus.failed.label,
      value: "4",
      tabContent: <PayoutsList tab={payoutStatus.failed.value} />,
      tabName: payoutStatus.failed.value,
    },
  ];

  useEffect(() => {
    const name = new URLSearchParams(search).get("status");
    selectTabOnSearch(name || null, tabData, setSelectedTab);
    handleSetQueryParam(name || null);
  }, []);

  const handleChange = (_event, newValue) => {
    dispatch(setIsDataAvailable(true));
    setSelectedTab(newValue);
    const selectedTabName = setQueryParamsForTab(tabData, newValue);
    handleSetQueryParam(selectedTabName);
  };

  const handleSetQueryParam = (searchValue) => {
    history.push({
      pathname: "/payouts",
      search: searchValue ? `?status=${searchValue}` : null,
    });
  };

  const getPayoutGuide = () => {
    setGetHeaderInfoLoading(true);
    callStrapiAPIInterface(
      "GET",
      "/api/payout-guide?populate[header][populate][use_cases][populate]=*"
    )
      .then((response) => {
        setPayoutGuide(response?.data?.attributes?.header);
        setGetHeaderInfoLoading(false);
      })
      .catch((error) => {
        dispatch(setCaughtErrorInStrapi(true));
        setGetHeaderInfoLoading(false);
      });
  };

  const strapiHeaderCondition =
    !isDataAvailable &&
    !caughtErrorInStrapi &&
    selectedTab === "1" &&
    !isModalOpen;

  return (
    <Box
      bgcolor={strapiHeaderCondition ? "#f7fafc" : ""}
      className="payout-header section-wrapper payment-links-wrapper"
    >
      {strapiHeaderCondition ? (
        <GetStartedHeader
          setIsModalOpen={setIsModalOpen}
          component={tabData[0].tabContent}
          loading={isGetHeaderInfoLoading}
          getHeaderInfo={payoutGuide}
        />
      ) : (
        <>
          <CommonHeader />
          <CustomTab
            onChange={handleChange}
            tabData={tabData}
            selectedTab={selectedTab}
            sx={{
              minHeight: "unset",
              "& .MuiTabPanel-root": {
                minHeight: "unset",
                padding: 0,
              },
            }}
          />
        </>
      )}
    </Box>
  );
};

export default Payouts;
