import { Box, Skeleton } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import React, { useState } from "react";
import ErrorIcon from "@mui/icons-material/Error";
import { lightningNetworkFeeInfo, speedFee } from "../../messages";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";

const AccountFees = ({ isLoading, accountFees, isPaymentAddress = false }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  return isLoading ? (
    <Skeleton
      width={150}
      sx={{ mt: "16px", mb: isPaymentAddress && "18px" }}
      animation={"wave"}
    />
  ) : (
    <Box mb={isPaymentAddress && "18px"} className="account-fees-box">
      <Text className="grey-text" size={14} font="regular">
        {`(${accountFees > 0 ? accountFees + "%" : "No"} ${speedFee})`}
      </Text>

      <ErrorIcon
        aria-describedby={"account-fee"}
        sx={{
          height: "18px",
          width: "18px",
          color: "#A4A9B7 !important",
          marginLeft: "4px",
        }}
        onClick={(e) => handleClick(e)}
      />

      <CustomPopper
        id="account-fee"
        disablePortal={true}
        open={open}
        anchorEl={anchorEl}
        position="top"
        handleClose={() => setAnchorEl(null)}
        sx={{ width: "332px" }}
      >
        <Text variant="inherit" font="regular" size={16}>
          {lightningNetworkFeeInfo}
        </Text>
      </CustomPopper>
    </Box>
  );
};

export default AccountFees;
