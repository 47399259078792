import React from "react";
import { Box } from "@mui/system";
import { useSelector } from "react-redux";
import { Card, CardContent, Grid } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import {
  emailSpecified,
  publicSetting,
  supportEmail,
  updateEmailAddress,
} from "../../messages";
import Button from "@speed/common/src/components/Button/Button";

const EmailPreferences = () => {
  const { isMobile, history } = useSelector((state) => state.common);
  const currentAccount = useSelector((state) => state.auth.currentAccount);

  return (
    <Box
      className="box-container"
      style={{ minHeight: isMobile ? "95vh" : "83vh" }}
    >
      <Card className="preference-main-card">
        <Grid display="flex" alignItems={"center"}>
          <Grid item lg={10}>
            <CardContent className="card-body">
              <Text className="card-text" size={20} font="bold" align="left">
                {supportEmail}
              </Text>
            </CardContent>
            <CardContent className="card-body">
              <Text
                className="grey-text"
                sx={{ marginTop: "12px" }}
                size={16}
                font="regular"
                align="left"
                variant="subtitle2"
              >
                {emailSpecified(
                  currentAccount?.account?.account_public_info?.email
                )}{" "}
                <a
                  onClick={() => history.push("/settings/public-info")}
                  className="email-preference-info"
                >
                  {publicSetting}
                </a>
              </Text>
            </CardContent>
            <CardContent className="card-body">
              <Button
                className="update-email-address"
                onClick={() => history.push("/settings/public-info")}
                variant="contained"
                label={updateEmailAddress}
              />
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
};

export default EmailPreferences;
