import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@mui/material";

import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import Button from "@speed/common/src/components/Button/Button";
import { openIntercomChatModal } from "@speed/common/src/components/constants";
import { contactSupport, permissionLabel } from "../../messages";
import { setSettingFormChange } from "../../../redux/common/actions";
import AccountPermission from "./AccountPermission";

let tabData = [
  {
    label: permissionLabel,
    value: "1",
    tabContent: <AccountPermission />,
    route: "/settings/permission",
  },
];

function AccountStatus() {
  const dispatch = useDispatch();
  const history = useSelector((state) => state.common.history);

  const [selectedTab, setSelectedTab] = useState("1");

  const handleChange = (_event, newValue) => {
    dispatch(setSettingFormChange(false));
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (history) {
      let currentRoute = tabData.find(
        (tab) => tab.route === history.location.pathname
      );

      if (currentRoute) {
        setSelectedTab(currentRoute.value);
      }
    }
  }, [history?.location?.pathname]);

  return (
    <>
      <Box className="action-btn-wrapper mui-fixed">
        <Button
          className="add-icon"
          label={contactSupport}
          variant="outlined"
          color="primary"
          onClick={openIntercomChatModal}
        />
      </Box>
      <CustomTab
        className="account-status-wrapper"
        onChange={handleChange}
        tabData={tabData}
        selectedTab={selectedTab}
        setTabRoute={(routeUrl) => history.push(routeUrl)}
        sx={{ position: "relative" }}
        height={"45px"}
      />
    </>
  );
}

export default AccountStatus;
