import { Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import { useSelector } from "react-redux";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import WarningIcon from "@mui/icons-material/Warning";

import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import Text from "@speed/common/src/components/Text/Text";
import {
  clipboardElement,
  generateBreadcrumbs,
  redirectWhenEntityNotFound,
} from "@speed/common/src/components/constants";
import CustomDivider from "@speed/common/src/components/Divider/Divider";

import {
  _read,
  _write,
  buttonTextForIdNotFound,
  dateCreatedOn,
  details,
  entityNotFoundMessage,
  read,
  readAndWrite,
  subtitleForIdNotFound,
} from "../messages";
import {
  RouteBreadcrumbs,
  callAPIInterface,
  callStrapiAPIInterface,
  dateTimeFormatInApp,
} from "../constants";
import RestrictedKeysCustomPopper from "./RestrictedKeysCustomPopper";
import CreateRestrictedKeyModal from "./CreateRestrictedKeyModal";
import LogsSkeleton from "../Logs/LogsSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";

const RestrictedKeyDetails = (props) => {
  const urlParams = useParams();
  const liveMode = useSelector((state) => state.auth.liveMode);
  const [isLinkNotFound, setIsLinkNotFound] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [restrictedKeyDetails, setRestrictedKeyDetails] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [resourceBuckets, setResourceBuckets] = useState([]);
  const [resourceLoading, setResourceLoading] = useState(false);
  const [willCallApi, setWillCallApi] = useState(true);
  const [resourcesFromApiCataloging, setResourcesFromApiCataloging] = useState(
    {}
  );

  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const openPopper = Boolean(anchorEl);

  useEffect(() => {
    getRestrictedKeysResources();
    getResourcesFromApiCataloging();
  }, []);

  useEffect(() => willCallApi && loadDetailPage(), [liveMode, willCallApi]);

  const getResourcesFromApiCataloging = () => {
    callStrapiAPIInterface("GET", "/api/resources-api-catalogings").then(
      (response) => {
        if (response?.data) {
          const obj = {};
          response?.data?.forEach((d) => {
            if (d?.attributes?.resource_id) {
              obj[d.attributes.resource_id] = d.attributes.display_name;
            }
          });
          setResourcesFromApiCataloging(obj);
        }
      }
    );
  };

  const getRestrictedKeysResources = () => {
    setResourceLoading(true);
    callAPIInterface("GET", "/api-key/bucket-wise-resource")
      .then((response) => {
        setResourceLoading(false);
        setResourceBuckets(response?.buckets);
      })
      .catch((_error) => {
        setResourceLoading(false);
      });
  };

  const loadDetailPage = () => {
    setRestrictedKeyDetails(null);
    setShowSkeleton(true);
    callAPIInterface("GET", `/api-key/restricted-key/${urlParams?.id}`)
      .then((res) => {
        if (res) {
          setRestrictedKeyDetails(res);
        }
      })
      .catch((err) => {
        setIsLinkNotFound(redirectWhenEntityNotFound(err));
      })
      .finally(() => {
        setShowSkeleton(false);
        setWillCallApi(false);
      });
  };

  const headerContent = () => {
    return (
      <Box className="header-content" marginTop={3.25}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Text className="default-text" size={28}>
            {restrictedKeyDetails?.name}
          </Text>
          <Box display="flex" className="rest-key-copy" gap={1}>
            {clipboardElement(
              restrictedKeyDetails?.api_key,
              true,
              "restricted-key-clipboard",
              "inputBox",
              true
            )}
            <MoreHorizIcon
              className="horizontal-dots-icon pointer-cursor"
              tabIndex={0}
              onClick={(event) => {
                event.stopPropagation();
                setAnchorEl(anchorEl ? null : event.currentTarget);
              }}
            />
          </Box>
        </Box>
        <Box width="900px" mt="18px">
          <Text
            className="grey-text"
            size={14}
            font="regular"
            variant="subtitle"
          >
            {restrictedKeyDetails?.description}
          </Text>
        </Box>
      </Box>
    );
  };

  if (isLinkNotFound) {
    return (
      <Box className="not-found-wrapper">
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        <LinkNotFound
          icon={<WarningIcon className="warning-icon" />}
          title={entityNotFoundMessage("Link")}
          subTitle={`Restricted Key ${urlParams.id} is not found.`}
          btnText={`View ${(liveMode && "live") || "test"} mode links`}
        />
      </Box>
    );
  }

  const getNameOfResourceId = (id, name) => {
    return resourcesFromApiCataloging?.[id] || name;
  };

  const getReadAndWritePermissions = () => {
    const permissions = restrictedKeyDetails?.permissions;
    const readPermissions = [];
    const writePermissions = [];

    if (resourceBuckets?.length && permissions) {
      resourceBuckets.forEach((bucket) => {
        const isBucketRead = permissions.includes(bucket?.id + _read);
        const isBucketWrite = permissions.includes(bucket?.id + _write);

        bucket?.resources?.forEach((bucketResource) => {
          if (
            isBucketWrite ||
            permissions.includes(bucketResource?.id + _write)
          ) {
            if (bucketResource?.write) {
              writePermissions.push(
                getNameOfResourceId(bucketResource?.id, bucketResource?.name)
              );
            } else if (bucketResource?.read) {
              readPermissions.push(
                getNameOfResourceId(bucketResource?.id, bucketResource?.name)
              );
            }
          } else if (
            isBucketRead ||
            permissions.includes(bucketResource?.id + _read)
          ) {
            readPermissions.push(
              getNameOfResourceId(bucketResource?.id, bucketResource?.name)
            );
          }
        });
      });
    }

    return {
      readPermissions,
      writePermissions,
    };
  };

  const showDetailPage = () => {
    const { readPermissions, writePermissions } = getReadAndWritePermissions();
    return (
      <Grid
        pt="18px"
        display="table-cell"
        width="100vw !important"
        container
        spacing={1}
        className="payment-links-wrapper restricted-key-detail-page"
      >
        <Box className="section-wrapper" sx={{ position: "relative" }}>
          <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
          {restrictedKeyDetails ? (
            <Box
              display="flex"
              flexDirection="column"
              minHeight="calc(100vh - 106px)"
            >
              <Box>
                {headerContent()}
                <Box className="details-content" marginTop="65px">
                  <Text size={20} className="default-text">
                    {details}
                  </Text>
                </Box>
              </Box>
              <CustomDivider sx={{ my: "12px" }} />
              <Box gap={2.5} display="flex" flexWrap="wrap">
                <Grid xs={4} item>
                  <Box display="flex">
                    <Grid xs={4} item>
                      <Text
                        className="grey-text"
                        size={16}
                        variant="body1"
                        font="regular"
                      >
                        {readAndWrite}
                      </Text>
                    </Grid>
                    <Grid item xs={8}>
                      <Text
                        className="default-text"
                        size={14}
                        variant="body1"
                        font="regular"
                        sx={{ width: "260px" }}
                      >
                        {writePermissions.length
                          ? writePermissions.join(", ")
                          : "-"}
                      </Text>
                    </Grid>
                  </Box>
                </Grid>
                <Grid xs={4} item>
                  <Box display="flex">
                    <Grid item xs={2}>
                      <Text
                        className="grey-text"
                        size={16}
                        variant="body1"
                        font="regular"
                      >
                        {read}
                      </Text>
                    </Grid>
                    <Grid item xs={8}>
                      <Text
                        className="default-text"
                        size={14}
                        variant="body1"
                        font="regular"
                        sx={{ width: "260px" }}
                      >
                        {readPermissions.length
                          ? readPermissions.join(", ")
                          : "-"}
                      </Text>
                    </Grid>
                  </Box>
                </Grid>
                <Grid item xs={4}>
                  <Box display="flex">
                    <Grid item xs={4}>
                      <Text
                        className="grey-text"
                        size={16}
                        variant="body1"
                        font="regular"
                      >
                        {dateCreatedOn}
                      </Text>
                    </Grid>
                    <Grid item xs={8}>
                      <Box display="flex">
                        {dateTimeFormatInApp(restrictedKeyDetails?.created)}
                      </Box>
                    </Grid>
                  </Box>
                </Grid>
              </Box>
            </Box>
          ) : (
            (showSkeleton || resourceLoading) && <LogsSkeleton />
          )}
        </Box>
        <RestrictedKeysCustomPopper
          setAnchor={setAnchorEl}
          anchor={anchorEl}
          openPopper={openPopper}
          setIsUpdating={setIsUpdating}
          id={urlParams?.id}
          fromDetailPage={true}
          restrictedKeyDetails={restrictedKeyDetails}
          setRestrictedKeyDetails={setRestrictedKeyDetails}
        />
        <CreateRestrictedKeyModal
          isUpdating={isUpdating}
          setIsUpdating={setIsUpdating}
          restrictedKeyDetails={restrictedKeyDetails}
          fromDetailPage={true}
          setWillCallApi={setWillCallApi}
        />
      </Grid>
    );
  };

  return !isLinkNotFound ? (
    showDetailPage()
  ) : (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage("Restricted keys")}
        subTitle={subtitleForIdNotFound("Restricted keys", urlParams.id)}
        btnText={buttonTextForIdNotFound("Restricted keys", liveMode)}
      />
    </Box>
  );
};

export default RestrictedKeyDetails;
