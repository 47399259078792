import React, { Fragment, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Box, Drawer, InputBase, MenuItem } from "@mui/material";
import { useDispatch } from "react-redux";
import { Input } from "@speed/common/src/components/Input/Input";
import { RadioButton } from "@speed/common/src/components/RadioButton/RadioButton";
import Text from "@speed/common/src/components/Text/Text";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Button from "@speed/common/src/components/Button/Button";
import CustomSelect from "@speed/common/src/components/Select/Select";
import CustomDatePicker from "@speed/common/src/components/DatePicker";

const AdvanceFilter = ({
  anchor,
  handleDrawer,
  data,
  reduxValue,
  reduxAction,
  headerTitle,
}) => {
  const [dataToRender, setDataToRender] = useState(data);
  const [filterKey, setFilterKey] = useState();
  const [showFilterDetails, setShowFilterDetails] = useState(false);
  const [showFilterResult, setShowFilterResult] = useState(false);
  const [selectedFilterProperty, setSelectedFilterProperty] = useState({});
  const [selectedRadio, setSelectedRadio] = useState();
  const [filterValue, setFilterValue] = useState(null);
  const [rangeFilterValue, setRangeFilterValue] = useState(null);
  const [selectedFilterOptions, setSelectedFilterOptions] = useState([]);
  const [isError, setIsError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (reduxValue === false) {
      setSelectedFilterOptions([]);
      setShowFilterResult(false);
    }
  }, [reduxValue]);

  useEffect(() => {
    if (selectedFilterOptions?.length === 0) {
      dispatch(reduxAction(false));
    }
  }, [selectedFilterOptions]);

  const handleRadioChange = (event) => {
    setFilterValue(null);
    setRangeFilterValue(null);
    setIsError(false);
    setSelectedRadio(event?.value);
  };
  const handleFilterValueChange = (e) => setFilterValue(e?.target?.value);
  const deleteFilterOption = (option) => {
    const filteredArray = selectedFilterOptions?.filter(
      (opt) => opt !== option
    );
    setSelectedFilterOptions(filteredArray);
    if (filteredArray.length === 0) {
      setShowFilterResult(false);
    }
  };
  const handleSearch = (val) => {
    setFilterKey(val);
    const newFilteredProperties = data?.filter((x) =>
      x?.title?.toLowerCase()?.includes(val)
    );
    setDataToRender(newFilteredProperties);
  };
  const disableSubmit = () => {
    if (selectedRadio === "is-between") {
      if (filterValue && rangeFilterValue) {
        return isError;
      } else {
        return true;
      }
    } else if (filterValue) {
      return isError;
    } else {
      return true;
    }
  };
  const filterHeader = () => {
    return (
      <Box
        display="flex"
        paddingLeft={5}
        paddingRight={4.375}
        paddingY={3}
        bgcolor="#f7fafc"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text size={18} variant="h3">
          Advanced Filter
        </Text>
        <Box
          height={20}
          width={20}
          onClick={handleDrawer}
          component="span"
          className="pointer-cursor"
        >
          <CloseIcon sx={{ height: 20, width: 20 }} htmlColor="#848b9e" />
        </Box>
      </Box>
    );
  };
  const filterBody = () => {
    if (showFilterDetails === true) {
      return (
        <>
          <Box
            display="flex"
            alignItems="center"
            onClick={() => {
              setSelectedRadio();
              setFilterValue(null);
              setRangeFilterValue(null);
              setIsError(false);
              setSelectedFilterProperty({});
              setShowFilterDetails(false);
            }}
            className="pointer-cursor"
          >
            <ArrowBackIosIcon htmlColor="#9197a8" />
            <Text size={14} variant="body1">
              Back
            </Text>
          </Box>
          <Text size={16} variant="h4">
            {selectedFilterProperty?.title}
          </Text>
          <Box
            display="flex"
            flexDirection="column"
            gap={2}
            height="90%"
            overflow="hidden auto"
          >
            {selectedFilterProperty?.criteria?.map((x, i) => {
              return (
                <Fragment key={i}>
                  <RadioButton
                    checked={selectedRadio === x?.value}
                    label={x?.label}
                    onChange={() => handleRadioChange(x)}
                    value={x?.value}
                  />
                  {selectedRadio === x?.value ? (
                    x?.type === "normal-string" || x?.type === "number" ? (
                      <Box width="100%" paddingX={0.5}>
                        <Input
                          fullWidth
                          onChange={handleFilterValueChange}
                          placeholder={`${
                            selectedFilterProperty?.title
                          } ${x?.label?.toLowerCase()}`}
                          showLabel={false}
                          value={filterValue || ""}
                          type={x?.type === "number" ? "number" : "text"}
                        />
                      </Box>
                    ) : x?.type === "date-picker" ? (
                      <CustomDatePicker
                        value={filterValue}
                        setValue={setFilterValue}
                        setIsError={setIsError}
                      />
                    ) : x?.type === "dropdown" ? (
                      <Box width="100%" paddingX={0.5}>
                        <CustomSelect
                          className={"custom-select-filter"}
                          renderValue={(value) => {
                            if (value) {
                              return value;
                            } else {
                              return <Box>{x?.dropdownValues[0]?.value}</Box>;
                            }
                          }}
                          value={
                            filterValue
                              ? filterValue
                              : setFilterValue(x?.dropdownValues[0]?.value)
                          }
                          MenuProps={{
                            id: "custom-filter-select",
                            disableScrollLock: true,
                          }}
                          onChange={(e) => setFilterValue(e.target.value)}
                          label={`${
                            selectedFilterProperty?.title
                          } ${x?.label?.toLowerCase()}`}
                        >
                          {x?.dropdownValues?.map(
                            (dropDownValue, dropDownIndex) => (
                              <MenuItem
                                value={dropDownValue?.value}
                                key={dropDownIndex}
                              >
                                <Box>{dropDownValue?.value}</Box>
                              </MenuItem>
                            )
                          )}
                        </CustomSelect>
                      </Box>
                    ) : x?.type === "date-picker-range" ? (
                      <>
                        <CustomDatePicker
                          value={filterValue}
                          setValue={setFilterValue}
                          setIsError={setIsError}
                        />
                        <Text
                          sx={{ paddingLeft: 0.5 }}
                          size={16}
                          font="regular"
                          variant="body1"
                        >
                          and
                        </Text>
                        <CustomDatePicker
                          value={rangeFilterValue}
                          setValue={setRangeFilterValue}
                          setIsError={setIsError}
                        />
                      </>
                    ) : (
                      <></>
                    )
                  ) : (
                    <></>
                  )}
                </Fragment>
              );
            })}
          </Box>
        </>
      );
    } else if (showFilterResult === true && selectedFilterOptions?.length > 0) {
      return (
        <>
          <Text
            className="default-text"
            font="regular"
            size={16}
            variant="body1"
          >
            Showing 38 out of 168,431 records
          </Text>
          <Box>
            {selectedFilterOptions?.map((opt, index) => {
              return (
                <Box key={index}>
                  {index === 0 ? (
                    <></>
                  ) : (
                    <CustomDivider
                      orientation="vertical"
                      flexItem
                      text="And"
                      sx={{
                        "&.MuiDivider-root": {
                          "&::after, &::before": {
                            borderTop: 0,
                            borderLeft: "1px solid #e4e9ee",
                            height: 10,
                            left: "6%",
                          },
                          "& .MuiDivider-wrapper": {
                            textAlign: "left",
                            paddingY: "0",
                          },
                        },
                      }}
                    />
                  )}
                  <Box
                    padding={"12px 16px 13px"}
                    border={1}
                    borderRadius={1}
                    borderColor="#e4e9ee"
                    bgcolor="#f7fafc"
                    display="flex"
                    justifyContent="space-between"
                    key={index}
                    gap={1}
                  >
                    <Text font="regular" size={16} variant="body1">
                      {`${opt?.filterProperty} ${opt?.selectedValue?.replaceAll(
                        "-",
                        " "
                      )} `}
                      <Text font="regular" size={16} component="span">
                        {`${opt?.value} ${
                          opt?.rangeValue ? `and ${opt?.rangeValue}` : ""
                        }`}
                      </Text>
                    </Text>
                    <Box
                      component={"span"}
                      className="pointer-cursor"
                      width={18}
                      height={18}
                      onClick={() => deleteFilterOption(opt)}
                    >
                      <CloseIcon
                        sx={{ height: 18, width: 18 }}
                        htmlColor="#848b9e"
                      />
                    </Box>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box>
            <Button
              onClick={() => setShowFilterResult(false)}
              variant="outlined"
              label="And"
            />
          </Box>
        </>
      );
    } else {
      return (
        <>
          {selectedFilterOptions?.length > 0 ? (
            <Box
              display="flex"
              alignItems="center"
              onClick={() => setShowFilterResult(true)}
              className="pointer-cursor"
            >
              <ArrowBackIosIcon htmlColor="#9197a8" />
              <Text size={14} variant="body1">
                Back
              </Text>
            </Box>
          ) : (
            <></>
          )}
          <Text className="default-text" size={16} variant="h4">
            {headerTitle}
          </Text>
          <Box width="100%">
            <InputBase
              fullWidth
              sx={{
                borderRadius: 1,
                bgcolor: "white",
                fontFamily: "Inter-Medium",
                height: 40,
                padding: "10px 12px",
                color: "#0a193e",
                boxShadow:
                  "0 4px 4px -2px rgb(11 37 75 / 12%), 0 0 0 1px rgb(11 37 75 / 8%)",
                border: "1px solid transparent",
                "&.Mui-focused": {
                  outline: "3px solid #bfd1ff",
                  boxShadow: "none",
                },
              }}
              endAdornment={
                <>
                  {filterKey && (
                    <CloseIcon
                      sx={{ height: 18, width: 18, cursor: "pointer" }}
                      htmlColor="#848b9e"
                      onClick={() => handleSearch("")}
                    />
                  )}
                </>
              }
              onChange={(e) => handleSearch(e?.target?.value)}
              placeholder="Search property"
              showLabel={false}
              value={filterKey}
              type="text"
            />
          </Box>
          {dataToRender?.map((value, index) => (
            <span
              key={index}
              onClick={() => {
                setSelectedFilterProperty(value);
                setShowFilterDetails(true);
              }}
              className="pointer-cursor"
            >
              <Text
                className="default-text"
                font="regular"
                size={16}
                variant="body1"
              >
                {value?.title}
              </Text>
            </span>
          ))}
        </>
      );
    }
  };
  const filterFooter = () => {
    if (showFilterDetails === true) {
      return (
        <>
          <CustomDivider />
          <Box marginLeft={5} marginY={3} width={"auto"}>
            <Button
              label="Apply Filter"
              onClick={() => {
                setSelectedFilterOptions([
                  ...selectedFilterOptions,
                  {
                    id: selectedFilterOptions?.length + 1,
                    filterProperty: selectedFilterProperty?.title,
                    selectedValue: selectedRadio,
                    value: filterValue,
                    rangeValue: rangeFilterValue,
                  },
                ]);
                setSelectedRadio();
                setFilterValue(null);
                setRangeFilterValue(null);
                setIsError(false);
                setShowFilterDetails(false);
                setShowFilterResult(true);
                dispatch(reduxAction(true));
              }}
              type="submit"
              disabled={disableSubmit()}
            />
          </Box>
        </>
      );
    } else {
      return <></>;
    }
  };
  return (
    <Drawer
      anchor="right"
      open={anchor}
      onClose={handleDrawer}
      variant="persistent"
      sx={{
        "& .MuiDrawer-paperAnchorDockedRight": {
          boxShadow:
            "-10px 0 44px -2px rgba(11, 37, 75, 0.12), 0 0 0 1px rgba(11, 37, 75, 0.08);",
        },
      }}
    >
      <Box width={480} height={"100%"} minWidth={450} role="presentation">
        {filterHeader()}
        <CustomDivider />
        <Box
          paddingX={5}
          paddingY={3}
          height={
            showFilterDetails === true
              ? "calc(100% - 165px)"
              : "calc(100% - 76px)"
          }
          overflow="hidden auto"
          display="flex"
          flexDirection="column"
          gap={2}
        >
          {filterBody()}
        </Box>
        {filterFooter()}
      </Box>
    </Drawer>
  );
};

export default AdvanceFilter;
