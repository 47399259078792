import React, { useState } from "react";
import { priceMsg } from "../messages";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import EmailIcon from "@mui/icons-material/Email";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import InvoicePreview from "./InvoicePreview";
import EmailPreview from "./EmailPreview";
import PaymentPage from "./PaymentPage";
import RightSideCommonPreview from "../Common/RightSideCommonPreview";
import { content } from "../constants";
import { ButtonToggle } from "@speed/common/src/components/ButtonToggle/ButtonToggle";

const InvoicePreviewSection = () => {
  const [selected, setSelected] = useState("laptop");
  const [selectedInvoicePreview, setSelectedInvoicePreview] =
    useState("invoice");

  const handleChangeInvoicePreview = (_e, value) => {
    if (value !== null) {
      setSelectedInvoicePreview(value);
    }
  };

  const handleChange = (_e, value) => {
    if (value !== null) {
      setSelected(value);
    }
  };

  const invoiceContent = [
    {
      value: "invoice",
      children: <PictureAsPdfIcon style={{ width: 24, height: 24 }} />,
    },
    {
      value: "email",
      children: <EmailIcon style={{ width: 24, height: 24 }} />,
    },
    {
      value: "payment-page",
      children: <QrCodeScannerIcon style={{ width: 24, height: 24 }} />,
    },
  ];

  const renderPreviews = () => {
    switch (selectedInvoicePreview) {
      case "invoice":
        return <InvoicePreview />;
      case "email":
        return <EmailPreview />;
      case "payment-page":
        return <PaymentPage selected={selected} />;
      default:
        return null;
    }
  };

  const renderInvoicePreviewToggleButton = (
    <ButtonToggle
      content={invoiceContent}
      value={selectedInvoicePreview}
      onChange={handleChangeInvoicePreview}
      sx={{ marginRight: "11px !important" }}
    />
  );

  return (
    <>
      <RightSideCommonPreview
        priceMsg={priceMsg}
        content={content}
        selected={selected}
        handleChange={handleChange}
        additionalHeaderComponent={renderInvoicePreviewToggleButton}
        showWebMobilePreviewButton={selectedInvoicePreview === "payment-page"}
        customClass="invoice-preview-section-wrapper"
        brandingURL={`/settings/${
          selectedInvoicePreview === "invoice"
            ? "invoice-pdf"
            : "invoice-payment-page"
        }`}
      >
        {renderPreviews()}
      </RightSideCommonPreview>
    </>
  );
};

export default InvoicePreviewSection;
