import React from "react";

import { Modal } from "@speed/common/src/components/Modal/Modal";
import Button from "@speed/common/src/components/Button/Button";
import Text from "@speed/common/src/components/Text/Text";
import {
  alert,
  btc,
  cancel,
  increasedFeesMsg,
  proceedBtn,
  sats,
} from "../messages";
import { showAmount } from "@speed/common/src/components/constants";

const FeesExceedModal = ({
  openFeesModal,
  estimatedFees,
  newEstimatedFees,
  btnLoader,
  handleFeesProceed,
  handleClose,
  targetCurrency,
}) => {
  const feesModalFooter = (
    <>
      <Button
        sx={{ marginRight: "16px" }}
        label={proceedBtn}
        onClick={handleFeesProceed}
        loading={btnLoader}
      />
      <Button variant="outlined" label={cancel} onClick={handleClose} />
    </>
  );

  const formatDisplayValues = (amount) =>
    showAmount({
      amount,
      currency: targetCurrency,
      targetedCurrency: targetCurrency === sats ? btc : targetCurrency,
      showCommaSeparated: true,
      appendCurrency: true,
    });

  const feesModalBody = (
    <Text size={14} variant="subtitle1" className="default-text" font="regular">
      {increasedFeesMsg(
        formatDisplayValues(estimatedFees),
        formatDisplayValues(newEstimatedFees)
      )}
    </Text>
  );

  return (
    <Modal
      maxWidth="xs"
      open={openFeesModal}
      body={feesModalBody}
      footer={feesModalFooter}
      handleClose={handleClose}
      title={alert}
    />
  );
};

export default FeesExceedModal;
