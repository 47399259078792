import { Box } from "@mui/material";
import Button from "@speed/common/src/components/Button/Button";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import { cancel } from "@speed/common/src/components/messages";
import {
  addInternalNote,
  changeInvoiceStatusLabel,
  learnMore,
  invoiceNotePlaceholder,
  updateInvoiceStatus,
} from "../messages";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { RadioButton } from "@speed/common/src/components/RadioButton/RadioButton";
import { invoiceStatus } from "@speed/common/src/components/constants";
import { useState } from "react";
import Text from "@speed/common/src/components/Text/Text";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import CustomDivider from "@speed/common/src/components/Divider/Divider";

function ChangeInvoiceStatusModal({ openModal, setOpenModal }) {
  const [note, setNote] = useState("");
  const [selectedInvoiceStatus, setSelectedInvoiceStatus] = useState(
    invoiceStatus.paid.value
  );

  const radioElement = (status, statusDescription) => {
    return (
      <Box className="invoice-status-radio-element">
        <RadioButton
          checked={selectedInvoiceStatus === invoiceStatus[status].value}
          label={invoiceStatus[status].label}
          value={invoiceStatus[status].value}
          onChange={() => {
            setSelectedInvoiceStatus(invoiceStatus[status].value);
          }}
        />
        <Text
          font="regular"
          size={16}
          className="grey-text status-description"
          variant="inline"
        >
          {statusDescription}
        </Text>
      </Box>
    );
  };

  const handleModalClose = () => {
    setSelectedInvoiceStatus(invoiceStatus.paid.value);
    setNote("");
    setOpenModal(false);
  };

  const renderModalContent = () => {
    return (
      <Box className="invoice-status-change-body">
        <Box className="invoice-status-box">
          {radioElement(
            invoiceStatus.paid.value,
            "Payment was collected outside of Speed"
          )}
          {radioElement(
            invoiceStatus.void.value,
            "This invoice was accidentally finalised or contains a mistake."
          )}
          {radioElement(
            invoiceStatus.uncollectable.value,
            "Payment of this invoice is not expected. It is still possible to collect payment should your customer attempt to pay"
          )}
          <Box className="learn-more-text" onClick={() => {}}>
            <Text className="default-text" size={14}>
              {learnMore}
            </Text>
            <ArrowForwardIcon />
          </Box>
        </Box>
        <CustomDivider />
        <Box className="invoice-internal-note">
          <TextAreaComponent
            value={note}
            fullWidth
            showLabel
            label={addInternalNote}
            minRows={5}
            name="text-area-name"
            onChange={(e) => {
              setNote(e.target.value);
            }}
            placeholder={invoiceNotePlaceholder}
          />
        </Box>
      </Box>
    );
  };

  const renderModalFooter = () => {
    return (
      <Box className="invoice-change-status-footer">
        <Button
          variant="outlined"
          type="submit"
          label={cancel}
          onClick={handleModalClose}
          disabled={false}
        />
        <Button
          className="save-btn"
          type="submit"
          label={updateInvoiceStatus}
          onClick={handleModalClose}
          disabled={false}
        />
      </Box>
    );
  };

  return (
    <Modal
      maxWidth="sm"
      className="change-invoice-status-modal"
      open={openModal}
      body={renderModalContent()}
      footer={renderModalFooter()}
      handleClose={handleModalClose}
      title={changeInvoiceStatusLabel}
    />
  );
}

export default ChangeInvoiceStatusModal;
