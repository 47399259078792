import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import ModulePaymentList from "../ModulePaymentList";

const PaymentAddressPaymentList = (props) => {
  const { paListofpaymentsFe } = useFlags();

  return (
    <ModulePaymentList
      module="payment-addresses"
      ldFlag={paListofpaymentsFe}
      {...props}
    />
  );
};
export default PaymentAddressPaymentList;
