import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import { useSelector } from "react-redux";
import {
  due,
  invoice,
  invoiceFrom,
  learnAbout,
  payThisInvoice,
  speedCryptoInvoicing,
  to,
} from "../messages";
import StoreIcon from "@mui/icons-material/Store";
import {
  addDaysToCurrentDate,
  getBrandingPreviewColor,
  getLightColor,
  getPreviewTotalAmount,
  isLightColor,
  validateColor,
} from "@speed/common/src/components/constants";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import Button from "@speed/common/src/components/Button/Button";
import FooterWithWhiteLogo from "../Common/FooterWithWhiteLogo";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import EmailInfo from "@speed/common/src/components/EmailInfo";
import { accountIcons } from "@speed/common/src/components/images";
import { CustomAvatar } from "@speed/common/src/components/Avatar/Avatar";
import HeaderAmountSection from "@speed/common/src/components/HeaderAmountSection";
import InvoicePageProductsModal from "@speed/common/src/components/InvoicePageProductsModal";
import { dateFromTimestampInApp, getTimeZoneAndDateFormat } from "../constants";

const EmailPreview = () => {
  const { currentAccount, user } = useSelector((state) => state.auth);
  const { brandingPageUI, invoicePreviewData } = useSelector(
    (state) => state.common
  );
  const accountName = currentAccount?.account?.name;
  const previewColor = getBrandingPreviewColor(brandingPageUI);
  const [textColor, setTextColor] = useState("#fff");
  const [backGroundColor, setBackGroundColor] = useState("#2250A1");
  const [fontFamily, setFontFamily] = useState("system-ui");
  const [accountImage, setAccountImage] = useState(null);

  const defaultImage =
    accountIcons()[currentAccount?.account?.account_type || "Merchant"];

  useEffect(() => {
    previewColor && setBackGroundColor(previewColor);
  }, [previewColor]);

  const { timezone } = getTimeZoneAndDateFormat();

  useEffect(() => {
    if (brandingPageUI) {
      const {
        primary_color,
        primarySavedColor,
        use_logo_or_icon,
        logos,
        icons,
        checkout_font_family,
      } = brandingPageUI;

      setFontFamily(checkout_font_family || "system-ui");

      const selectedColor = validateColor(primary_color)
        ? primary_color
        : primarySavedColor;
      isLightColor(selectedColor)
        ? setTextColor("#0a193e")
        : setTextColor("#fff");

      if (use_logo_or_icon === "icon" && icons) {
        setAccountImage(brandingPageUI?.icons?.original);
      }

      if (use_logo_or_icon === "logo" && logos) {
        setAccountImage(brandingPageUI?.logos?.original);
      }
    }
  }, [brandingPageUI]);

  const fontStyle = {
    fontFamily: `${fontFamily} !important`,
    color: textColor,
  };

  const imageClassName =
    brandingPageUI && brandingPageUI.use_logo_or_icon === "logo"
      ? `account-logo-style checkout`
      : `account-icon-style`;

  const renderHeader = () => {
    return (
      <Box className="email-preview-header pl-20">
        <Text className="default-text" size={16} font="semibold">
          {invoiceFrom(currentAccount?.account?.name)}
        </Text>
        <Box display="flex">
          <Text
            className="default-text"
            size={14}
            font="semibold"
            sx={{ marginRight: "5px" }}
          >
            {`${to.toLocaleLowerCase()}: `}
          </Text>
          <Text className="default-text" size={14} font="regular" variant="h6">
            {invoicePreviewData?.customer?.email}
          </Text>
        </Box>
      </Box>
    );
  };

  const renderAccountImage = () => {
    return (
      <CustomAvatar
        size="small"
        src={accountImage}
        className={imageClassName}
      />
    );
  };

  const renderLogoOrIcon = () => {
    if (brandingPageUI?.use_logo_or_icon === "logo") {
      return accountImage ? (
        renderAccountImage()
      ) : (
        <Box className="account-logo-default">{defaultImage}</Box>
      );
    } else {
      return (
        <>
          {accountImage ? (
            renderAccountImage()
          ) : (
            <Box
              className="account-logo-default"
              sx={{
                marginRight: "5px",
                backgroundColor: `${getLightColor(
                  backGroundColor,
                  0.4
                )} !important`,
              }}
            >
              <StoreIcon style={{ fill: textColor }} />
            </Box>
          )}

          <Text
            size={16}
            font="regular"
            sx={{
              marginRight: "5px",
              marginLeft: "10px",
              ...fontStyle,
            }}
            variant="h6"
          >
            {currentAccount?.account?.name}
          </Text>
        </>
      );
    }
  };

  return (
    <Box marginTop="20px">
      <Grid container width="595px">
        <Grid item lg={9} className="email-preview-container">
          {renderHeader()}
          <Box sx={{ backgroundColor: backGroundColor }}>
            <Box
              display="flex"
              justifyContent="space-between"
              className="account-name-section"
            >
              <Box display="flex" alignItems="center">
                {renderLogoOrIcon()}
              </Box>
              <Box display="flex" alignItems="center">
                <Box display="flex" className="download-invoice">
                  <SaveAltIcon
                    style={{ fill: textColor, marginRight: "5px" }}
                  />
                  <Text
                    size={16}
                    font="regular"
                    variant="h6"
                    sx={{ marginRight: "12px", ...fontStyle }}
                  >
                    {invoice}
                  </Text>
                </Box>
                <Box
                  height={20}
                  width={35}
                  textAlign="right"
                  borderLeft={`1px solid ${textColor}`}
                >
                  <HelpOutlineIcon
                    className="invoice-help-icon"
                    style={{ fill: textColor }}
                  />
                </Box>
              </Box>
            </Box>
            <HeaderAmountSection
              paymentInfo={invoicePreviewData}
              totalAmount={getPreviewTotalAmount({ data: invoicePreviewData })}
              fontStyle={fontStyle}
              isZeroAmountLink={false}
            />
            <Box
              className="mail-info-box email-info-tbl-row"
              padding="30px"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <EmailInfo
                accountName={accountName}
                paymentInfo={invoicePreviewData}
                isFromEmailPreview={true}
                isGetTimezoneFromSystem={true}
                fontFamily={fontStyle.fontFamily}
                isGreyText={true}
                isDisplayStaticData={true}
                timezone={timezone}
              />
              <Button
                label={payThisInvoice}
                className="pay-this-invoice-btn"
                sx={{
                  width: "100%",
                  marginTop: "24px",
                  fontFamily: fontStyle.fontFamily,
                  backgroundColor: `${backGroundColor} !important`,
                  color: fontStyle.color,
                }}
              />
            </Box>
            <Box
              className="mail-info-box email-info-tbl-row"
              padding="30px"
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Text
                size={18}
                font="semibold"
                variant="h6"
                className="default-text"
                fontFamily={fontStyle.fontFamily}
                fontWeight={600}
              >
                {invoice} #{invoicePreviewData?.invoice_prefix}-DRAFT
              </Text>
              <Text
                size={14}
                font="semibold"
                variant="h6"
                className="grey-text"
                fontFamily={fontStyle.fontFamily}
              >
                {due}{" "}
                {dateFromTimestampInApp(
                  addDaysToCurrentDate(invoicePreviewData?.payment_terms || 0),
                  "MM/DD/YYYY"
                )}
              </Text>
              {/* product listing and total preview*/}
              <InvoicePageProductsModal
                from="email-preview"
                showProductImage={false}
                paymentInfo={invoicePreviewData}
                user={user}
                fontFamily={fontStyle.fontFamily}
                products={invoicePreviewData?.invoice_line_items || []}
                isShowTotal={true}
                getDataFromReducer={true}
              />
            </Box>
            <Box
              className="email-preview-footer"
              display="flex"
              justifyContent="space-between"
            >
              <FooterWithWhiteLogo
                fontStyle={fontStyle}
                textColor={textColor}
              />
              <Text
                size={10}
                font="regular"
                variant="subtitle"
                sx={{ ...fontStyle }}
              >
                {learnAbout}{" "}
                <CustomLink
                  href="#"
                  target="_blank"
                  size="small"
                  className="crypto-invoicing"
                  sx={{ ...fontStyle }}
                >
                  {speedCryptoInvoicing}
                </CustomLink>
              </Text>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default EmailPreview;
