import { Box, MenuItem, Skeleton, TextField } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import {
  addNote,
  addNotePlaceholder,
  addPayoutWalletAddress,
  balanceAvailableForModule,
  btc,
  enterAmount,
  fiat,
  lnInfoText,
  onchainInfoText,
  payoutDetails,
  payoutTypeEthereum,
  payoutTypeLightning,
  payoutTypeOnchain,
  sats,
  searchWalletPlaceholder,
  selectCurrencyLabel,
  selectPayoutType,
  selectWallet,
  sendMax,
  usdt,
  walletAddress,
} from "../messages";
import CustomSelect from "@speed/common/src/components/Select/Select";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import {
  addNewPayoutWallet,
  availableBalanceTextComponent,
  callAPIInterface,
  noOfRecords,
  payoutWalletCurrencies,
  renderTermsAgreementCheckbox,
} from "../constants";
import {
  btcToSatsAmount,
  clipboardElement,
  excludedCode,
  getCurrencyObj,
  linkStatus,
  satsToBtcAmount,
  satsToFiatAmount,
  showAmount,
} from "@speed/common/src/components/constants";
import Label from "@speed/common/src/components/Label/Label";
import { Input } from "@speed/common/src/components/Input/Input";
import { amount } from "@speed/common/src/components/messages";
import InfoIcon from "@mui/icons-material/Info";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import { handleClick, handleClose } from "../Common/AllLinkFormConstant";
import { currency } from "@speed/common/src/components/currency";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import CommonEstimateFeesBox from "../Common/CommonEstimateFeesBox";
import { useSelector } from "react-redux";

const RequestInstantPayout = ({
  formik,
  setSelectedWallet,
  isModalOpen,
  setBalance,
  selectedWallet,
  balance,
  isChecked,
  setIsChecked,
  estimatedFees: calculatedEstimatedFees,
  getPayoutBalance,
  feesLoader,
  getEstimatedFees,
  setFeesLoader,
  setEstimatedFees,
  balanceLoader,
  fnForValidatePayoutAmount,
  setSendMaxAmount,
  speedFeeValue,
  setSpeedFeeValue,
  currencyAmount,
  setCurrencyAmount,
}) => {
  const queryParam = "?limit=" + noOfRecords;

  const [searchQuery, setSearchQuery] = useState("");
  const [wallets, setWallets] = useState([]);
  const [walletSkeleton, setWalletSkeleton] = useState(false);
  const [walletsFromResult, setWalletsFromResult] = useState([]);
  const [hasMoreWallet, setHasMoreWallet] = useState(true);
  const [hasMoreWalletFromResult, setHasMoreWalletFromResult] = useState(true);
  const [queryParamsOfWallet, setQueryParamsOfWallet] = useState(queryParam);
  const [payoutBalanceLoading, setPayoutBalanceLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [walletOptions, setWalletOptions] = useState([]);
  const [timeoutId, setTimeoutId] = useState(null);
  const [sendMaxLoader, setSendMaxLoader] = useState(false);

  const [exchangeRateValue, setExchangeRateValue] = useState();
  const [exchangeRateLoader, setExchangeRateLoader] = useState(false);
  const [types, setTypes] = useState([]);

  const initialQueryParamOfSearch =
    "?limit=" + noOfRecords + "&name=" + searchQuery;

  const [queryParamsOfWalletForSearch, setQueryParamsOfWalletForSearch] =
    useState(initialQueryParamOfSearch);

  const open = Boolean(anchorEl);

  useEffect(() => setPayoutBalanceLoading(balanceLoader), [balanceLoader]);

  const accountAsset = useSelector((state) => state.common.accountAsset);

  const {
    values,
    setFieldValue,
    touched,
    errors,
    setTouched,
    resetForm,
    setErrors,
  } = formik;

  const isSATS = values.payoutCurrencyType?.code === "SATS";

  //Get exchange rates when fiat currency is selected
  const getExchangeRate = (currency) => {
    setExchangeRateLoader(true);
    const data = {
      currency,
      target_currency: values?.asset?.uuid,
    };
    callAPIInterface("POST", "/utility/exchange-rate", data)
      .then((res) => {
        setExchangeRateLoader(false);
        const exchangeRate = res?.target_lowest_rate;
        setExchangeRateValue(exchangeRate);
      })
      .catch((_e) => setExchangeRateLoader(false));
  };

  // Fetch the payout wallets initially and display them as an options in wallet auto complete.
  const getPayoutWallets = (lines, params) => {
    setWalletSkeleton(true);
    callAPIInterface("POST", `/payout-wallets/filter${params}`, {
      status: linkStatus.verified.value,
    })
      .then((walletRes) => {
        setWalletSkeleton(false);
        if (!walletRes.has_more) {
          setHasMoreWallet(false);
        } else {
          setQueryParamsOfWallet(
            queryParam +
              "&ending_before=" +
              walletRes.data[walletRes.data.length - 1].id
          );
        }
        setWallets(lines.concat(walletRes.data));
      })
      .catch(() => {
        setWalletSkeleton(false);
      });
  };

  // Fetch the payout wallets while searching wallet and display matched wallets in auto complete.
  const getPayoutWalletsFromResult = (lines, params) => {
    setWalletSkeleton(true);
    callAPIInterface("POST", `/payout-wallets/filter${params}`, {
      status: linkStatus.verified.value,
    })
      .then((walletRes) => {
        setWalletSkeleton(false);
        if (!walletRes.has_more) {
          setHasMoreWalletFromResult(false);
        } else {
          setQueryParamsOfWalletForSearch(
            initialQueryParamOfSearch +
              "&ending_before=" +
              walletRes.data[walletRes.data.length - 1].id
          );
        }
        setWalletsFromResult(lines.concat(walletRes.data));
      })
      .catch(() => {
        setWalletSkeleton(false);
      });
  };

  const fetchPayoutWalletsResult = () => {
    setWalletsFromResult([]);
    setHasMoreWalletFromResult(true);
    setQueryParamsOfWalletForSearch(initialQueryParamOfSearch);
    getPayoutWalletsFromResult([], initialQueryParamOfSearch);
  };

  const fetchPayoutWallets = () => {
    setWallets([]);
    setHasMoreWallet(true);
    getPayoutWallets([], queryParamsOfWallet);
  };

  // Set the initial values of the form when modal is open
  const handleCreatePayoutRequest = () => {
    setPayoutBalanceLoading(true);
    fetchPayoutWallets();
  };

  useEffect(() => {
    selectWallet &&
      getPayoutBalance()
        .then((balance) => {
          setPayoutBalanceLoading(false);
          setBalance(balance);
        })
        .catch(() => {
          setPayoutBalanceLoading(false);
        });
  }, [selectWallet, values?.asset]);

  const resetTheFormValue = () => {
    resetForm({});
    setWallets([]);
    setWalletsFromResult([]);
    setHasMoreWallet(true);
    setSelectedWallet({});
    setSearchQuery("");
    setQueryParamsOfWallet(queryParam);
    setQueryParamsOfWalletForSearch(initialQueryParamOfSearch);
  };

  useEffect(() => {
    if (isModalOpen) handleCreatePayoutRequest();
    else resetTheFormValue();
  }, [isModalOpen]);

  useEffect(() => {
    if (searchQuery) fetchPayoutWalletsResult();
  }, [searchQuery]);

  const loadMoreWalletsFromResult = useCallback(() => {
    getPayoutWalletsFromResult(walletsFromResult, queryParamsOfWalletForSearch);
  }, [walletsFromResult]);

  const loadMoreWallets = useCallback(() => {
    getPayoutWallets(wallets, queryParamsOfWallet);
  }, [wallets]);

  const walletCheck = searchQuery ? hasMoreWalletFromResult : hasMoreWallet;

  useEffect(() => {
    const walletOptions = searchQuery ? walletsFromResult : wallets;
    const filteredWallet = walletOptions.filter(
      (wallet) => wallet.type === values.payoutType
    );
    setWalletOptions(filteredWallet);
  }, [searchQuery, values.payoutType, wallets, walletsFromResult]);

  const validatePayoutAmount = (payoutType, amountInSats) => {
    return fnForValidatePayoutAmount(
      payoutType,
      amountInSats,
      calculatedEstimatedFees
    );
  };

  const debouncedHandleChange = (amount) => {
    const estimatedAPIFunc = async () => {
      values.wallet?.address &&
        amount > 0 &&
        (await getEstimatedFees(values.wallet?.address));
      setFeesLoader(false);
    };

    if (timeoutId) clearTimeout(timeoutId);
    const newTimeoutId = setTimeout(async () => {
      if (
        values.payoutType === payoutTypeOnchain ||
        values?.payoutType === payoutTypeEthereum
      ) {
        const result = validatePayoutAmount(
          values.payoutType,
          parseFloat(amount)
        );
        console.log({
          validate: result?.isValid,
        });
        result.isValid && estimatedAPIFunc();
        setFeesLoader(false);
      } else {
        estimatedAPIFunc();
      }
      amount === 0 && setEstimatedFees(0);
    }, 500);
    setTimeoutId(newTimeoutId);
  };

  useEffect(() => {
    if (values.payoutAmount) {
      setFeesLoader(true);
      values.payoutAmount && debouncedHandleChange(currencyAmount);
    }
  }, [currencyAmount, values.payoutType, values.payoutAmount]);

  const handleAmountChange = (value) => {
    const calculatedAmount = value.slice(0, isSATS ? 9 : 10);
    setFieldValue("payoutAmount", calculatedAmount);
  };

  useEffect(() => {
    if (values.payoutAmount) {
      if (
        values?.asset?.uuid === sats
          ? !excludedCode.includes(values.payoutCurrencyType?.code)
          : values?.payoutCurrencyType?.code !== values?.asset?.uuid
      ) {
        if (exchangeRateValue) {
          const satsValueOfAmount =
            values?.asset?.uuid === sats
              ? Math.floor(exchangeRateValue * values.payoutAmount)
              : exchangeRateValue * values.payoutAmount;

          satsValueOfAmount && setCurrencyAmount(satsValueOfAmount);
        }
      } else {
        setCurrencyAmount(
          values.payoutCurrencyType?.code === btc
            ? btcToSatsAmount(values.payoutAmount)
            : parseFloat(values.payoutAmount)
        );
      }
    } else setCurrencyAmount(0);
  }, [values.payoutAmount, values.payoutCurrencyType, exchangeRateValue]);

  const handleSelectChange = (e) => {
    const value = e.target.value;
    setTouched({ ...touched, payoutType: false });
    setFieldValue("payoutType", value);
    handleResetValues(values?.asset?.name);
  };

  const handleWalletClick = (e, optionProps, option) => {
    setFieldValue("wallet", option);
    setSelectedWallet(option);
    optionProps.onClick(e);
  };

  const handleCurrencyOnChange = (_e, value) => {
    setFieldValue("payoutAmount", "");
    setFieldValue("payoutCurrencyType", value);
    setEstimatedFees(0);
    if (
      values?.asset?.uuid === sats
        ? !excludedCode.includes(value?.code)
        : values?.asset?.uuid !== value?.code
    ) {
      getExchangeRate(value?.code);
      setCurrencyAmount(
        values?.asset?.uuid === sats && value?.code === btc
          ? btcToSatsAmount(values.payoutAmount)
          : parseFloat(values.payoutAmount)
      );
    }
  };

  const getSpeedFee = () => {
    const path = `/account-fees?payment_method=${values.payoutType}&transaction_code=04&target_currency=${values?.asset?.uuid}`;
    callAPIInterface("GET", path)
      .then((res) => {
        res && setSpeedFeeValue(res.fee_percentage);
      })
      .catch((_e) => {});
  };

  useEffect(() => {
    getSpeedFee();
  }, [values.payoutType]);

  const isSelectedCurrencyBTC = values.payoutCurrencyType?.code === "BTC";
  const isSelectedCurrencyFiat = !excludedCode.includes(
    values.payoutCurrencyType?.code
  );

  useEffect(() => {
    if (accountAsset?.length) {
      const firstCurrency = accountAsset?.[0]?.uuid;
      const selectedCurrencyTypes = handleSelectedCurrencyTypes(firstCurrency);
      setTypes(selectedCurrencyTypes);
      setFieldValue("asset", accountAsset?.[0]);
      setFieldValue("payoutType", selectedCurrencyTypes?.[0]?.value);
    }
  }, [accountAsset]);

  const setMaxAmountToInput = (amountToSet) => {
    const cryptoAmount = isSATS ? amountToSet : satsToBtcAmount(amountToSet);
    const amount = excludedCode.includes(values.payoutCurrencyType?.code)
      ? cryptoAmount
      : satsToFiatAmount(amountToSet, exchangeRateValue);
    setFieldValue("payoutAmount", amount);
    setCurrencyAmount(amountToSet);
  };

  const getLightningMaxAmount = () => {
    setSendMaxLoader(true);
    callAPIInterface("GET", "/withdraws/lightning-max-amount")
      .then((res) => {
        const lightningMaxLimit = Number(
          process.env.REACT_APP_LIGHTNING_MAX_LIMIT
        );
        const sendMaxAmount = res?.amount;
        if (sendMaxAmount > lightningMaxLimit)
          setMaxAmountToInput(lightningMaxLimit);
        else setMaxAmountToInput(sendMaxAmount);
        setSendMaxAmount(sendMaxAmount);
      })
      .catch((_e) => {})
      .finally(() => setSendMaxLoader(false));
  };

  const handleSelectedCurrencyTypes = (currency) => {
    return payoutWalletCurrencies?.filter(
      (type) => type?.currency === currency
    );
  };

  const handleResetValues = (selectedCurrency) => {
    setFieldValue("wallet", null);
    setFieldValue("payoutCurrencyType", getCurrencyObj(selectedCurrency));
    setFieldValue("payoutAmount", "");
    setSelectedWallet({});
    setErrors({});
    setEstimatedFees(null);
  };

  const handleChangeCurrency = (e) => {
    const selectedCurrency = e.target.value;
    const selectedCurrencyTypes = handleSelectedCurrencyTypes(
      selectedCurrency?.uuid
    );
    setTypes(selectedCurrencyTypes);
    setFieldValue("asset", selectedCurrency);
    setFieldValue("payoutType", selectedCurrencyTypes?.[0]?.value);
    //reset values
    handleResetValues(selectedCurrency?.name);
  };

  const feesSectionProps = {
    estimatedFees: calculatedEstimatedFees,
    amount: currencyAmount,
    exchangeRateValue,
    loader: feesLoader || exchangeRateLoader,
    speedFee: Math.floor(currencyAmount * (speedFeeValue / 100)),
    payoutType: values.payoutType,
    currencyCode: values?.payoutCurrencyType?.code,
    targetCurrency: values?.asset?.uuid,
  };

  return (
    <Box
      className="margin-top30 margin-bottom30"
      display="flex"
      justifyContent="center"
    >
      <Box width="600px">
        <Text
          className="default-text"
          size={20}
          variant="h2"
          sx={{ mb: "30px" }}
        >
          {payoutDetails}
        </Text>
        <Label>{selectCurrencyLabel}</Label>
        <Box width="100%">
          <CustomSelect
            value={values?.asset?.name}
            MenuProps={{
              id: "instant-payout-asset",
              disableScrollLock: true,
            }}
            renderValue={(_value) => {
              return _value?.name || values?.asset?.name;
            }}
            onChange={(e) => handleChangeCurrency(e)}
            customClass="margin-bottom30"
            name="asset"
          >
            {accountAsset?.map((dropDownValue) => {
              return (
                <MenuItem value={dropDownValue} key={dropDownValue?.uuid}>
                  <Box>{dropDownValue?.name}</Box>
                </MenuItem>
              );
            })}
          </CustomSelect>
        </Box>
        <CustomSelect
          name="payoutType"
          showLabel={true}
          label={selectPayoutType}
          value={values.payoutType}
          MenuProps={{
            id: "instant-payout-type",
          }}
          onChange={handleSelectChange}
          fullWidth={true}
        >
          {types.map((type) => (
            <MenuItem key={type.value} value={type.value}>
              {type.name}
            </MenuItem>
          ))}
        </CustomSelect>
        <CustomAutoComplete
          customClass="margin-top30"
          name="wallet"
          label={selectWallet}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={searchWalletPlaceholder}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          )}
          options={walletOptions}
          disableClearable
          noOptionsText={
            <Fragment>
              {addNewPayoutWallet(addPayoutWalletAddress)}
              {walletSkeleton && (
                <Box marginY={1} padding={1} borderRadius={1}>
                  <Skeleton
                    animation="wave"
                    width="50%"
                    sx={{ bgcolor: "#eaeef1 !important" }}
                  />
                  <Skeleton
                    animation="wave"
                    sx={{ bgcolor: "#eaeef166 !important" }}
                  />
                </Box>
              )}
            </Fragment>
          }
          value={values.wallet}
          getOptionLabel={(option) => option.name ?? ""}
          renderOption={(optionProps, option, _state) => {
            const index = walletOptions?.findIndex(
              (wallet) => wallet === option
            );
            const lastIndex = walletOptions?.findIndex(
              (wallet) => wallet === walletOptions[walletOptions.length - 1]
            );
            return (
              <Fragment key={index}>
                {index === 0 && addNewPayoutWallet(addPayoutWalletAddress)}
                <Box
                  marginY={1}
                  padding={1}
                  className="pointer-cursor"
                  onClick={(e) => handleWalletClick(e, optionProps, option)}
                  borderRadius={1}
                  sx={{
                    ":hover": {
                      bgcolor: "#f1f4f6",
                    },
                  }}
                >
                  <Text className="default-text" size={16} variant="body1">
                    {option.name}
                  </Text>
                  <Text className="grey-text" size={14} variant="body1">
                    {option.address}
                  </Text>
                </Box>
                {walletSkeleton && index === lastIndex && (
                  <Box marginY={1} padding={1} borderRadius={1}>
                    <Skeleton
                      animation="wave"
                      width="50%"
                      sx={{ bgcolor: "#eaeef1 !important" }}
                    />
                    <Skeleton
                      animation="wave"
                      sx={{ bgcolor: "#eaeef166 !important" }}
                    />
                  </Box>
                )}
              </Fragment>
            );
          }}
          ListboxProps={{
            role: "list-box",
            onScroll: (event) => {
              const listBoxNode = event.currentTarget;
              if (
                listBoxNode.scrollTop + listBoxNode.clientHeight ===
                listBoxNode.scrollHeight
              ) {
                if (walletCheck) {
                  searchQuery ? loadMoreWalletsFromResult() : loadMoreWallets();
                }
              }
            },
          }}
        />
        {selectedWallet && Object.keys(selectedWallet)?.length > 0 && (
          <>
            <Box className="margin-top30" position="relative">
              <Label>{walletAddress}</Label>
              {clipboardElement(
                values?.wallet?.address,
                "active",
                "custom-payout-clipboard-input",
                "inputBox",
                false,
                "instant-payout-clipboard"
              )}
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              className="margin-top30"
            >
              <Box display="flex" alignItems="center">
                <Label>{amount}</Label>
                <InfoIcon
                  className="label-with-icon"
                  sx={{
                    height: "20px !important",
                    width: "20px !important",
                    marginBottom: "14px",
                  }}
                  onClick={(e) => handleClick(e, setAnchorEl, anchorEl)}
                />
                <CustomPopper
                  disablePortal={true}
                  open={open}
                  anchorEl={anchorEl}
                  position="top"
                  handleClose={() => handleClose(setAnchorEl)}
                >
                  <Text className="grey-text" size={14} variant="subtitle1">
                    {values.payoutType === payoutTypeOnchain
                      ? onchainInfoText
                      : lnInfoText}
                  </Text>
                </CustomPopper>
              </Box>
              {values.payoutType === payoutTypeLightning &&
                (sendMaxLoader ? (
                  <Skeleton width={70} height={8} sx={{ mb: "14px" }} />
                ) : (
                  <Label
                    className="action-label instant-payout"
                    tabIndex={0}
                    onKeyDown={(e) => {
                      if (e.keyCode === 13) {
                        getLightningMaxAmount();
                      }
                    }}
                    onClick={getLightningMaxAmount}
                  >
                    {sendMax}
                  </Label>
                ))}
            </Box>

            <Box
              className={`dropdown-input-wrapper instant-payout-select-input ${
                payoutBalanceLoading && "disable-amount-input"
              }`}
            >
              <CustomAutoComplete
                name="payoutCurrencyType"
                options={currency}
                getOptionLabel={(value) => {
                  return `${value.code}`;
                }}
                onChange={handleCurrencyOnChange}
                value={values.payoutCurrencyType}
                showLabel={false}
                disableClearable
                renderOption={(_props, option) => {
                  return (
                    <>
                      <li {..._props}>{option.code}</li>
                      {option.isDivider ? (
                        <CustomDivider
                          textAlign="left"
                          className="currency-divider"
                          text={fiat}
                        />
                      ) : null}
                    </>
                  );
                }}
                customClass="instant-payout-select"
                disabled={payoutBalanceLoading}
              />

              <Box
                className="auto-payout-vertical-line"
                sx={{
                  margin: "auto",
                }}
              />
              <Input
                tabIndex={0}
                type="number"
                showLabel={false}
                disabled={payoutBalanceLoading}
                className="auto-payout-amount-input"
                placeholder={enterAmount}
                name="payoutAmount"
                value={values.payoutAmount}
                onWheel={(e) => e.target.blur()}
                error={Boolean(errors.payoutAmount)}
                onKeyDown={(event) => {
                  isSATS && event.key === "." && event.preventDefault();
                }}
                onChange={(e) => {
                  handleAmountChange(e.target.value);
                }}
                isTextVisible={
                  values?.asset?.uuid === sats
                    ? !excludedCode.includes(values.payoutCurrencyType?.code)
                    : values?.payoutCurrencyType?.code !== values?.asset?.uuid
                }
                inputText={
                  exchangeRateLoader ? (
                    <Skeleton width={50} height={8} />
                  ) : (
                    <>
                      ~
                      {showAmount({
                        amount: currencyAmount,
                        currency: values?.asset?.uuid,
                        targetedCurrency: values?.asset?.name,
                        showCommaSeparated: true,
                        appendCurrency: true,
                        multiplier: values?.asset?.uuid === usdt,
                      })}
                    </>
                  )
                }
              />
            </Box>
            {Boolean(values.payoutAmount) && Boolean(errors.payoutAmount) && (
              <AlertMessage
                message={errors.payoutAmount}
                className="margin-top15"
                severity="error"
              />
            )}
            {!payoutBalanceLoading &&
              availableBalanceTextComponent({
                preText: balanceAvailableForModule("payout"),
                balance,
                loader: balanceLoader,
                sx: { mt: "13px" },
                currency: values?.asset?.name,
              })}
            <Input
              fullWidth={true}
              showLabel={true}
              label={addNote}
              name="note"
              onChange={(e) => setFieldValue("note", e.target.value)}
              inputProps={{ maxLength: 255 }}
              placeholder={addNotePlaceholder}
              value={values.note}
              customClass="margin-top30"
            />
            {Boolean(values?.payoutAmount && calculatedEstimatedFees) && (
              <CommonEstimateFeesBox {...feesSectionProps} />
            )}
            {renderTermsAgreementCheckbox(isChecked, setIsChecked)}
          </>
        )}
      </Box>
    </Box>
  );
};
export default RequestInstantPayout;
