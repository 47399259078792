import React from "react";
import MultiEmailTag from "./Common/MultiEmailTag";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import { invalidEmail } from "@speed/common/src/components/messages";
import { limitForMultiEmail, multiEmailPlaceholder } from "./messages";
import { getEmailLabel, noOfEmails } from "./constants";
import { isEmail } from "react-multi-email";

const MultiEmailWithAlert = (props) => {
  const {
    emails,
    setEmails,
    invalidEmailFlag,
    setInValidEmailFlag,
    invalidEmailData,
    setInValidEmailData,
    limitEmail,
    setLimitEmail,
    setDisabled,
    customEmailTagProps,
  } = props;

  const handleEmailChange = (e) => {
    const loweredEmail = e.map((mail) => mail?.toLowerCase() || mail);
    setEmails(loweredEmail);
    setInValidEmailData("");
    setInValidEmailFlag(false);
    if (e.length <= noOfEmails) {
      if (e.length === 0) setDisabled(true);
      else setDisabled(false);
      setLimitEmail(false);
    } else {
      setDisabled(true);
      setLimitEmail(true);
    }
  };

  const validateEmail = (email) => {
    if (emails?.length > noOfEmails) {
      return true;
    }

    const isValidEmail = isEmail(email);
    if (!isValidEmail) {
      if (email === "undefined" || !emails) setDisabled(true);
      else {
        setInValidEmailData(email);
        setInValidEmailFlag(true);
        setDisabled(true);
      }
    } else setDisabled(false);
    return isValidEmail;
  };

  return (
    <>
      <MultiEmailTag
        style={{ cursor: "text" }}
        emails={emails}
        emailPlaceholder={multiEmailPlaceholder}
        handleEmailChange={handleEmailChange}
        validateEmail={validateEmail}
        getEmailLabel={getEmailLabel}
        {...customEmailTagProps}
      />
      {invalidEmailFlag && (
        <AlertMessage
          message={invalidEmail + ": " + invalidEmailData}
          className="margin-top15"
          severity="error"
          sx={{ wordBreak: "break-all" }}
        />
      )}
      {limitEmail && (
        <AlertMessage
          message={limitForMultiEmail()}
          className="margin-top15"
          severity="error"
        />
      )}
    </>
  );
};

export default MultiEmailWithAlert;
