import React from "react";
import GetGuides from "./GetGuides";

const InstantSendGuide = () => {
  return (
    <GetGuides
      moduleName="instant_send"
      redirectUrl="/instant-sends"
      guideApiPath="instant-send-guide"
      getStartedApiPath="Instant"
      needHelpGuidePath="docs/send-payments/instant-send"
    />
  );
};

export default InstantSendGuide;
