import { Text } from "@speed/common/src/components/Text/Text";
import { Input } from "@speed/common/src/components/Input/Input";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  accountInfoMsg,
  accountNameLabel,
  addressLine1Placeholder,
  addressLine2Placeholder,
  businessAddressLabel,
  cityPlaceholder,
  postalCodePlaceholder,
  postalCodeValidate,
  statePlaceholder,
  invalidLabel,
  updateSuccessMsg,
  currencyLabel,
} from "../../messages";
import { Countries } from "@speed/common/src/components/country";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import {
  setSettingFormCancel,
  setSettingFormChange,
  setSettingFormSubmit,
  setSettingFormValid,
  showToast,
  updateAccountInfo,
} from "../../../redux/common/actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Grid } from "@mui/material";
import {
  validatePostalCode,
  TimeZoneSelect,
  isAlphabet,
  renderCurrencyOption,
} from "../../constants";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import { sessionService } from "redux-react-session";
import { setInitialState, updateUser } from "../../../redux/auth/actions";
import {
  getCountryObj,
  isAlphaNumeric,
  getDefaultWalletCurrencyObj,
} from "@speed/common/src/components/constants";
import { Wallet, country } from "@speed/common/src/components/messages";
import { alphaNumName, countryPlaceholder } from "@speed/common/src/messages";
import { currency } from "@speed/common/src/components/currency";

function AccountInfo({ accountData }) {
  const [countryISOCode, setCountryISOCode] = useState();
  const { settingFormSubmit, settingFormCancel } = useSelector(
    (state) => state.common
  );
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({});
  const { user, currentAccount } = useSelector((state) => state.auth);
  const isWalletAccount = currentAccount?.account?.account_type === Wallet;
  const updatedCurrencyOption = currency.slice(2);

  const validationSchema = yup.object({
    name: yup.string().matches(isAlphaNumeric).required(""),
    city: yup.string().matches(isAlphabet).trim(),
    state: yup.string().matches(isAlphabet).trim(),
    country: yup.string().required(),
    postal_code: yup.mixed().test({
      name: "postal_code",
      test: (postal_code) => validatePostalCode(postal_code, countryISOCode),
    }),
  });

  const walletValidationSchema = yup.object({
    name: yup.string().matches(isAlphaNumeric).required(""),
    currency: yup.mixed().required(),
  });

  const currencyLocalValue = JSON.parse(
    localStorage.getItem("wallet_default_currency")
  );

  const formik = useFormik({
    initialValues: {
      name: accountData.name ? accountData.name : "",
      currency:
        currencyLocalValue ??
        getDefaultWalletCurrencyObj(currentAccount?.account?.country),
      country: accountData.country ? accountData.country : null,
      line1: accountData.line1 ? accountData.line1 : "",
      line2: accountData.line2 ? accountData.line2 : "",
      city: accountData.city ? accountData.city : "",
      state: accountData.state ? accountData.state : "",
      postal_code: accountData.postal_code ? accountData.postal_code : "",
      timezone: accountData.timezone ? accountData.timezone : null,
    },
    validationSchema: isWalletAccount
      ? walletValidationSchema
      : validationSchema,
    enableReinitialize: true,
  });

  const {
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
    setTouched,
    isValid,
    dirty,
    isSubmitting,
  } = formik;

  const onChangeFormField = (fieldname, value) => {
    setFieldValue(fieldname, value);
    formData[fieldname] = value;
    setFormData(formData);
  };

  useEffect(() => {
    if (values.country) {
      const countryObj = getCountryObj("short_name", values.country);
      if (countryObj) setCountryISOCode(countryObj.iso2);
    }
  }, [values.country]);

  useEffect(() => {
    dispatch(setSettingFormValid(!(isValid && dirty) || isSubmitting));
  }, [isValid, dirty, isSubmitting]);

  useEffect(() => {
    dispatch(setSettingFormChange(dirty));
  }, [values]);

  useEffect(() => {
    // get values and call action to update this change into DB
    if (
      Object.keys(formData).length !== 0 &&
      isValid &&
      dirty &&
      settingFormSubmit
    ) {
      if (formData?.currency) {
        localStorage.setItem(
          "wallet_default_currency",
          JSON.stringify(formData?.currency)
        );
        dispatch(
          showToast({
            isToastOpen: true,
            toastMessage: updateSuccessMsg("account info"),
            toastVariant: "success",
          })
        );
        dispatch(setSettingFormSubmit(false));
      }
      dispatch(updateAccountInfo(formData)).then(async (updatedAccountData) => {
        if (updatedAccountData) {
          const updatedAccountDetails = user.accounts_details.map(
            (accountInfo) => {
              if (accountInfo?.account?.id === updatedAccountData.id) {
                return { ...accountInfo, account: updatedAccountData };
              }
              return accountInfo;
            }
          );

          const updatedUserData = {
            ...user,
            accounts_details: updatedAccountDetails,
          };
          dispatch(updateUser(updatedUserData));

          let updatedcurrentAccountData = updatedAccountDetails.filter(
            (accountInfo) => accountInfo?.account?.id === accountData.id
          )[0];
          sessionService.loadSession().then((session) => {
            dispatch(
              setInitialState({
                ...session,
                current_acc: updatedcurrentAccountData,
              })
            );
          });
        }
      });
    }
    setFormData({});
  }, [settingFormSubmit]);

  useEffect(() => {
    if (settingFormCancel) {
      setFormData({});
      resetForm();
      dispatch(setSettingFormCancel(false));
    }
  }, [settingFormCancel]);

  const merchantAccountInfo = () => (
    <Grid style={{ width: "400px" }}>
      <Input
        type="text"
        name="name"
        inputProps={{ maxLength: 250 }}
        value={values.name}
        customClass="margin-top30"
        showLabel
        label={accountNameLabel}
        fullWidth
        placeholder={accountNameLabel}
        onBlur={() => setTouched({ ...touched, name: true })}
        onChange={(e) => {
          onChangeFormField("name", e.target.value);
        }}
      />
      {touched.name && Boolean(errors.name) && (
        <AlertMessage
          className="margin-top14"
          severity="error"
          message={alphaNumName}
        />
      )}
      <CustomAutoComplete
        allowedAutoComplete={true}
        name="country"
        value={values.country}
        customClass="margin-top30"
        placeholder={countryPlaceholder}
        showLabel
        label={businessAddressLabel}
        options={Countries}
        getOptionLabel={(optionValues) => {
          let country = getCountryObj(
            "short_name",
            typeof optionValues === "object"
              ? optionValues.short_name
              : optionValues
          );
          return country ? country.short_name : "";
        }}
        onBlur={() => setTouched({ ...touched, country: true })}
        onChange={(_e, changeCountryValues) => {
          if (changeCountryValues) {
            setCountryISOCode(changeCountryValues.iso2);
          } else {
            setCountryISOCode();
          }
          setTouched({ ...touched, country: false });
          onChangeFormField(
            "country",
            changeCountryValues && typeof changeCountryValues === "object"
              ? changeCountryValues.short_name
              : changeCountryValues
          );
        }}
      />

      <Input
        type="text"
        name="line1"
        value={values.line1}
        inputProps={{ maxLength: 250 }}
        showLabel={false}
        customClass="margin-top20"
        fullWidth
        placeholder={addressLine1Placeholder}
        onBlur={() => setTouched({ ...touched, line1: true })}
        onChange={(e) => {
          onChangeFormField("line1", e.target.value);
        }}
      />

      <Input
        type="text"
        name="line2"
        value={values.line2}
        inputProps={{ maxLength: 250 }}
        showLabel={false}
        customClass="margin-top20"
        fullWidth
        placeholder={addressLine2Placeholder}
        onChange={(e) => {
          onChangeFormField("line2", e.target.value);
        }}
      />

      <Input
        type="text"
        name="city"
        inputProps={{ maxLength: 250 }}
        showLabel={false}
        value={values.city}
        customClass="margin-top20"
        fullWidth
        placeholder={cityPlaceholder}
        onBlur={() => setTouched({ ...touched, city: true })}
        onChange={(e) => {
          onChangeFormField("city", e.target.value);
        }}
      />
      {touched.city && Boolean(errors.city) && (
        <AlertMessage
          className="margin-top14"
          severity="error"
          message={invalidLabel(cityPlaceholder)}
        />
      )}

      <Input
        type="text"
        name="state"
        inputProps={{ maxLength: 250 }}
        showLabel={false}
        value={values.state}
        customClass="margin-top20"
        fullWidth
        placeholder={statePlaceholder}
        onBlur={() => setTouched({ ...touched, state: true })}
        onChange={(e) => {
          onChangeFormField("state", e.target.value);
        }}
      />
      {touched.state && Boolean(errors.state) && (
        <AlertMessage
          className="margin-top14"
          severity="error"
          message={invalidLabel(statePlaceholder)}
        />
      )}
      <Input
        type="text"
        name="postal_code"
        inputProps={{ maxLength: 250 }}
        value={values.postal_code}
        showLabel={false}
        customClass="margin-top20"
        fullWidth
        placeholder={postalCodePlaceholder}
        onBlur={() => setTouched({ ...touched, postal_code: true })}
        onChange={(e) => {
          onChangeFormField("postal_code", e.target.value);
        }}
      />
      {touched.postal_code && Boolean(errors.postal_code) && (
        <AlertMessage
          className="margin-top14"
          severity="error"
          message={postalCodeValidate}
        />
      )}

      <TimeZoneSelect
        name="timezone"
        value={values.timezone}
        onChange={(value) => onChangeFormField("timezone", value)}
      />
    </Grid>
  );

  const walletAccountInfo = () => (
    <Grid style={{ width: "400px" }}>
      <Input
        type="text"
        name="name"
        inputProps={{ maxLength: 250 }}
        value={values.name}
        customClass="margin-top30"
        showLabel
        label={accountNameLabel}
        fullWidth
        placeholder={accountNameLabel}
        onBlur={() => setTouched({ ...touched, name: true })}
        onChange={(e) => {
          onChangeFormField("name", e.target.value);
        }}
      />
      {touched.name && Boolean(errors.name) && (
        <AlertMessage
          className="margin-top14"
          severity="error"
          message={alphaNumName}
        />
      )}
      <Input
        type="text"
        name="name"
        value={values.country}
        customClass="margin-top20"
        showLabel
        disabled
        label={country}
        fullWidth
      />
      <CustomAutoComplete
        name="currency"
        options={updatedCurrencyOption}
        getOptionLabel={(value) => {
          return `${value.code}`;
        }}
        onChange={(_e, value) => {
          onChangeFormField("currency", value);
        }}
        value={values.currency}
        showLabel
        label={currencyLabel}
        sx={{ marginTop: "20px" }}
        disableClearable
        fullWidth
        renderOption={renderCurrencyOption}
      />
    </Grid>
  );

  const prepareAccountInfo = () => {
    if (isWalletAccount) {
      return walletAccountInfo();
    }

    return merchantAccountInfo();
  };

  return (
    <Grid sx={{ padding: "20px 30px 100px 24px" }} container spacing={1}>
      <Grid container>
        <Text
          className="grey-text"
          font="regular"
          size={16}
          variant="subtitle1"
        >
          {accountInfoMsg}
        </Text>
      </Grid>
      {prepareAccountInfo()}
    </Grid>
  );
}

export default AccountInfo;
