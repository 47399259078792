import React, { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import WarningIcon from "@mui/icons-material/Warning";
import { useFlags } from "launchdarkly-react-client-sdk";

import LinkNotFound from "@speed/common/src/components/LinkNotFound";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import InstantSendSkeleton from "./InstantSendSkeleton";
import Text from "@speed/common/src/components/Text/Text";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import {
  clipboardElement,
  generateBreadcrumbs,
  getCurrencyObj,
  redirectWhenEntityNotFound,
  showAmount,
} from "@speed/common/src/components/constants";
import {
  callAPIInterface,
  dateTimeFormatInApp,
  instantSendStatus,
  ISWithdrawMethodsObj,
  RouteBreadcrumbs,
} from "../constants";
import {
  createdOn,
  details,
  entityNotFoundMessage,
  ethereumLabel,
  explorerLink,
  failureReason,
  instantSendLabel,
  instantSendNotFoundMsg,
  instantSendNotFoundSubMsg,
  networkFees,
  note,
  onchain,
  sendTo,
  speedFee,
  status,
  tronLabel,
  type,
  viewInEtherscan,
  viewInMemepool,
  viewInTronscan,
} from "../messages";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { KeyValueGrid } from "@speed/common/src/components/KeyValueGrid";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import { fullScreenBlue } from "../images";

const InstantSendDetail = (props) => {
  const liveMode = useSelector((state) => state.auth.liveMode);
  const urlParams = useParams();
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const { isDetailinstantsendFe } = useFlags();

  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [data, setData] = useState(null);
  const [showSkeleton, setShowSkeleton] = useState(false);

  const getInstantSendDetail = async () => {
    setData(null);
    setIsShowNotFound(false);
    setShowSkeleton(true);
    try {
      const result = await callAPIInterface(
        "GET",
        `/send/${urlParams.id}?currency_denomination=higher`
      );
      setData(result);
    } catch (e) {
      setIsShowNotFound(redirectWhenEntityNotFound(e));
    }
    setShowSkeleton(false);
  };

  const getExplorerLinkLabel = (methodName) => {
    switch (methodName) {
      case onchain:
        return viewInMemepool;
      case ethereumLabel:
        return viewInEtherscan;
      case tronLabel:
        return viewInTronscan;
      default:
        return "VIEW LINK";
    }
  };

  useEffect(() => {
    isDetailinstantsendFe && getInstantSendDetail();
  }, [liveMode, isDetailinstantsendFe]);

  const headerContent = () => (
    <Box className="header-content">
      <Text className="default-text" variant="h1" size={24}>
        {instantSendLabel}
      </Text>
      <Text
        className="default-text"
        sx={{ color: "#2A67FF !important", mt: "12px" }}
        variant="subtitle1"
        font="semibold"
        size={20}
      >
        {showAmount({
          amount: data.target_amount,
          currency: data.target_currency,
          appendCurrency: true,
        })}
      </Text>
      {data.target_currency !== data.currency && (
        <Text
          className="grey-text"
          size={16}
          font="medium"
          sx={{ mt: "12px" }}
          variant="subtitle"
        >
          ≈ {getCurrencyObj(data.currency)?.symbol}
          {data.amount}
        </Text>
      )}
      <Box marginTop="18px">
        {clipboardElement(
          data.id,
          true,
          "instant-send-detail-id",
          "inputBox",
          false
        )}
      </Box>
    </Box>
  );

  const detailKeyValueData = () => {
    const gridDataArray = [
      {
        key: createdOn,
        value: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {dateTimeFormatInApp(data.created)}
          </Text>
        ),
      },
      {
        key: type,
        value: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {ISWithdrawMethodsObj[data.withdraw_type]?.label}
          </Text>
        ),
      },
      {
        key: status,
        value: (
          <Tag
            text={instantSendStatus[data.status]?.label}
            variant={instantSendStatus[data.status]?.variant}
          />
        ),
      },
      {
        key: networkFees,
        value: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {showAmount({
              amount: data.fees || 0,
              currency: data.target_currency,
              appendCurrency: true,
            })}
          </Text>
        ),
      },
      {
        key: sendTo,
        value: clipboardElement(
          data.withdraw_request,
          true,
          "instant-send-to-clipboard detail",
          "inputBox",
          false
        ),
      },
      {
        key: speedFee,
        value: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {showAmount({
              amount: data.speed_fee?.amount || 0,
              currency: data.target_currency,
              appendCurrency: true,
            })}
          </Text>
        ),
      },
    ];

    data.failure_reason &&
      gridDataArray.push({
        key: failureReason,
        value: (
          <TruncatedTextTooltip
            textValue={data.failure_reason}
            cellWidth="300px"
          />
        ),
      });
    data.note &&
      gridDataArray.push({
        key: note,
        value: <TruncatedTextTooltip textValue={data.note} cellWidth="300px" />,
      });

    data.explorer_link &&
      gridDataArray.push({
        key: explorerLink,
        value: (
          <Box
            mt="2px"
            display="flex"
            alignItems="center"
            className="pointer-cursor"
            onClick={() => {
              window.open(data.explorer_link, "_blank", "noopener");
            }}
          >
            <Text
              size={14}
              font="semibold"
              variant="subtitle1"
              className="default-text"
              style={{ color: "#2A67FF" }}
            >
              {getExplorerLinkLabel(data.withdraw_method)}
            </Text>
            <img
              src={fullScreenBlue}
              style={{ height: "18px", width: "18px", marginLeft: "10px" }}
              alt="Screen-Enlarge"
            />
          </Box>
        ),
      });

    return gridDataArray;
  };

  const showData = () => {
    return data ? (
      <>
        {headerContent()}
        <Box className="details-content">
          <Text size={20} className="default-text">
            {details}
          </Text>
        </Box>
        <CustomDivider sx={{ my: "12px" }} />
        <Box
          gap={2.75}
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
        >
          {detailKeyValueData()?.map((detailData) => (
            <KeyValueGrid
              key={detailData?.key}
              keyName={detailData?.key}
              value={detailData?.value}
            />
          ))}
        </Box>
      </>
    ) : (
      showSkeleton && <InstantSendSkeleton props={props} />
    );
  };

  const detailContent = () => {
    return !isShowNotFound ? (
      <Grid className="payments-detail-wrapper" container spacing={1}>
        <Box className="section-wrapper" sx={{ position: "relative" }}>
          <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
          {showData()}
        </Box>
      </Grid>
    ) : (
      <Box className="not-found-wrapper">
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        <LinkNotFound
          icon={<WarningIcon className="warning-icon" />}
          title={entityNotFoundMessage("Instant send")}
          subTitle={instantSendNotFoundMsg(urlParams.id)}
          btnText={instantSendNotFoundSubMsg(liveMode)}
        />
      </Box>
    );
  };

  return isDetailinstantsendFe ? detailContent() : <AccessDenied />;
};

export default InstantSendDetail;
