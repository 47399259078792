import React, { useEffect, useState } from "react";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Box, Skeleton } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { callStrapiAPIInterface } from "../constants";
import history from "@speed/common/src/components/history";
import { openIntercomChatModal } from "@speed/common/src/components/constants";

function NeedHelp({
  readGuideUrl = `${process.env.REACT_APP_SPEED_DEV_URL}docs`,
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [needHelpInfo, setNeedHelpInfo] = useState(null);

  useEffect(() => {
    getHelpfulInformation();
  }, []);

  const getHelpfulInformation = () => {
    setIsLoading(true);
    callStrapiAPIInterface(
      "Get",
      "/api/helpful-information?populate[0]=need_help"
    )
      .then((response) => {
        setNeedHelpInfo(response?.data?.attributes);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const excludePathForReadGuideUrl = [
    "/point-of-sale-guide",
    "/speed-point-of-sale",
    "/my-assets",
  ];

  return (
    <Box mt="40px" mb="46px">
      <Text size={18} className="grey-text" style={{ paddingBottom: "20px" }}>
        {isLoading ? (
          <Skeleton
            animation="wave"
            width={208}
            height={28}
            sx={{
              bgcolor: "#EAEEF1 !important",
            }}
          />
        ) : (
          needHelpInfo?.header
        )}
      </Text>
      <Box className="help-info">
        {isLoading
          ? [...Array(2).keys()]?.map((helpInfo) => (
              <React.Fragment key={`info_${helpInfo}`}>
                <Box padding="30px 0 10px 0" className="header-text">
                  <Text font="semi-bold" size={22}>
                    <Skeleton
                      animation="wave"
                      width={307}
                      height={20}
                      sx={{
                        bgcolor: "#C4CCD2 !important",
                      }}
                    />
                  </Text>
                  <Text
                    variant="body1"
                    font="regular"
                    className="grey-text"
                    size={18}
                    style={{ width: "700px", paddingTop: "8px" }}
                  >
                    <Skeleton
                      animation="wave"
                      width={700}
                      height={14}
                      sx={{
                        bgcolor: "#EAEEF1 !important",
                      }}
                    />
                    <Skeleton
                      animation="wave"
                      width={425}
                      height={14}
                      sx={{
                        bgcolor: "#EAEEF1 !important",
                      }}
                    />
                  </Text>
                  <CustomLink
                    size="small"
                    alignIcon="end"
                    style={{
                      padding: "16px 0 40px 0",
                      fontSize: "16px",
                      fontWeight: "600px",
                      fontFamily: "Inter-SemiBold",
                    }}
                  >
                    <Skeleton
                      animation="wave"
                      width={217}
                      height={18}
                      sx={{
                        bgcolor: "#8BA7EC !important",
                      }}
                    />
                  </CustomLink>
                </Box>
                {helpInfo !== [...Array(2).keys()].length - 1 && (
                  <Box
                    className="steps-border"
                    borderBottom="1px solid #E4E9EE"
                    width="1100px"
                  />
                )}
              </React.Fragment>
            ))
          : needHelpInfo?.need_help?.map((helpInfo) => {
              return (
                <React.Fragment key={helpInfo?.id}>
                  <Box padding="30px 0 10px 0" className="header-text">
                    <Text font="semi-bold" size={22}>
                      {helpInfo?.help_title}
                    </Text>
                    <Text
                      variant="body1"
                      font="regular"
                      className="grey-text"
                      size={18}
                      style={{ width: "700px", paddingTop: "8px" }}
                    >
                      {helpInfo?.help_description}
                    </Text>
                    <Box display="flex" alignItems="center">
                      {excludePathForReadGuideUrl.includes(
                        history.location.pathname
                      ) &&
                      helpInfo?.link_comp?.title === "Read guide" ? null : (
                        <CustomLink
                          size="small"
                          alignIcon="end"
                          withIcon={
                            <ArrowForwardIcon
                              style={{ fontSize: "14px", color: "#9BABFD" }}
                            />
                          }
                          style={{
                            padding: "16px 37px 40px 0",
                            fontSize: "16px",
                            fontWeight: "600px",
                            fontFamily: "Inter-SemiBold",
                          }}
                          href={
                            helpInfo?.link_comp?.title === "Ask a question"
                              ? helpInfo?.link_comp?.redirection_url
                              : readGuideUrl
                          }
                          target="_blank"
                        >
                          {helpInfo?.link_comp?.title}
                        </CustomLink>
                      )}
                      {helpInfo?.chat_button && (
                        <CustomLink
                          size="small"
                          alignIcon="end"
                          withIcon={
                            <ArrowForwardIcon
                              style={{ fontSize: "14px", color: "#9BABFD" }}
                            />
                          }
                          style={{
                            padding: "16px 0 40px 0",
                            fontSize: "16px",
                            fontWeight: "600px",
                            fontFamily: "Inter-SemiBold",
                          }}
                          onClick={openIntercomChatModal}
                        >
                          {helpInfo?.chat_button?.title}
                        </CustomLink>
                      )}
                    </Box>
                  </Box>
                  {helpInfo?.id !== needHelpInfo?.need_help.length && (
                    <Box
                      className="steps-border"
                      borderBottom="1px solid #E4E9EE"
                      width="1100px"
                    />
                  )}
                </React.Fragment>
              );
            })}
      </Box>
    </Box>
  );
}

export default NeedHelp;
