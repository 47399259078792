import { Box } from "@mui/material";
import { Switch, Redirect, Route } from "react-router-dom";
import CommonHeader from "../Common/CommonHeader";
import Footer from "./Footer";
import ProfileForm from "./Profile";
import ChangePassword from "./ChangePassword";
import LoginSessions from "./LoginSessions";
import AssociatedAccounts from "./AssociatedAccount";
import PrivateRoute from "../../container/PrivateRoute";
import AccountDetails from "./AccountDetails";
import AccountStatus from "./AccountStatus";
import PaymentMethod from "./PaymentMethod";
import TwoFactAuth from "./TwoFactorAuth/TwoFactAuth";
import Notifications from "./Notifications";
import UsersAccounts from "./UsersAccounts";
import Branding from "./Branding";
import "../../assets/styles/settings.scss";
import Emails from "./Emails";
import PayoutWallets from "./PayoutWallets";
import PayoutScheduling from "./PayoutScheduling";
import CustomDomain from "./CustomDomain";
import Invoice from "./Invoice";
import PaymentPageSettings from "./PaymentPage/PaymentPageSettings";
import Connect from "../Connect";
import ConnectBranding from "../Connect/Branding/ConnectBranding";
import { useSelector } from "react-redux";
import { accountSettingsRoutes } from "../constants";
import { Merchant } from "@speed/common/src/components/messages";
import InstantSendSettings from "./InstantSend";

function Settings(props) {
  const { currentAccount } = useSelector((state) => state.auth);
  const accountType = currentAccount?.account?.account_type;
  const accountDetailsSubmenu = accountSettingsRoutes[accountType];

  return (
    <Box className="section-wrapper">
      <CommonHeader />
      <Switch>
        <PrivateRoute
          exact
          path="/settings/profile"
          component={ProfileForm}
          isWalletAllowedRoute={true}
        />
        <PrivateRoute
          exact
          path="/settings/change-password"
          component={ChangePassword}
          isWalletAllowedRoute={true}
        />
        <PrivateRoute
          exact
          path="/settings/login-sessions"
          component={LoginSessions}
          isWalletAllowedRoute={true}
        />
        <PrivateRoute
          exact
          path="/settings/associated-accounts"
          component={AssociatedAccounts}
          isWalletAllowedRoute={true}
        />
        <PrivateRoute
          exact
          path="/settings/payment-method"
          component={PaymentMethod}
        />
        {["emails", "email-preferences"].map((routeURL, index) => (
          <PrivateRoute
            key={index}
            exact
            path={`/settings/${routeURL}`}
            component={Emails}
          />
        ))}
        {["custom-domain", "custom-payment-domain"].map((routeURL) => (
          <PrivateRoute
            key={routeURL}
            exact
            path={`/settings/${routeURL}`}
            component={CustomDomain}
          />
        ))}
        <PrivateRoute
          exact
          path="/settings/custom-domain"
          component={CustomDomain}
        />
        <PrivateRoute exact path={`/settings/invoice`} component={Invoice} />
        <PrivateRoute
          exact
          path="/settings/instant-send"
          component={InstantSendSettings}
        />
        <PrivateRoute
          exact
          path="/settings/two-factor-auth"
          component={TwoFactAuth}
          isWalletAllowedRoute={true}
        />
        {accountType === Merchant && (
          <PrivateRoute
            exact
            path="/settings/notifications"
            component={Notifications}
          />
        )}
        {accountDetailsSubmenu?.map((routeURL, index) => (
          <PrivateRoute
            key={index}
            exact
            path={`/settings/${routeURL}`}
            component={AccountDetails}
            isWalletAllowedRoute={true}
          />
        ))}
        <PrivateRoute
          exact
          path="/settings/permission"
          component={AccountStatus}
          isWalletAllowedRoute={true}
        />
        {["connect-branding", "connected-extensions"].map((routeURL) => (
          <PrivateRoute
            key={routeURL}
            exact
            path={`/settings/${routeURL}`}
            component={Connect}
          />
        ))}
        <PrivateRoute
          exact
          path="/settings/connect-branding"
          component={ConnectBranding}
        />
        {/* <PrivateRoute
          exact
          path="/settings/connected-extensions"
          component={ConnectedExtensions}
        /> */}
        {/* {["payout-wallet", "payout-schedule"].map((routeURL, index) => (
          <PrivateRoute
            key={index}
            exact
            path={`/settings/${routeURL}`}
            component={PayoutAndScheduling}
          />
        ))} */}
        <PrivateRoute
          exact
          path={`/settings/payout-wallets`}
          component={PayoutWallets}
        />
        <PrivateRoute
          exact
          path={`/settings/payout-scheduling`}
          component={PayoutScheduling}
        />
        {[
          "checkout-payment",
          "email-receipt",
          "one-qr",
          "withdrawal-link",
          "invoice-pdf",
          "invoice-payment-page",
        ].map((routeURL, index) => (
          <PrivateRoute
            key={index}
            exact
            path={`/settings/${routeURL}`}
            component={Branding}
          />
        ))}
        {["team-members", "invite-members"].map((routeURL, index) => (
          <PrivateRoute
            key={index}
            exact
            path={`/settings/${routeURL}`}
            component={UsersAccounts}
          />
        ))}
        <PrivateRoute
          exact
          path="/settings/payment-page"
          component={PaymentPageSettings}
        />
        <Route
          render={() => (
            <Redirect
              to={{
                pathname: "/login",
                state: { pathname: props?.location?.pathname },
              }}
            />
          )}
        />
      </Switch>
      <Footer />
    </Box>
  );
}

export default Settings;
