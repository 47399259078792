import { Box } from "@mui/material";
import Button from "@speed/common/src/components/Button/Button";
import React, { useEffect, useState } from "react";
import {
  congratulationText,
  enterValidUsername,
  joinNowLnAddressText,
  lnAddressDetails,
  setUpText,
  setYourUsername,
  setupCompleted,
  setupLn,
  setupLnAddress,
  successfullyClaimedText,
  usernameMustBeLong,
  yourLnAddress,
} from "../messages";
import Text from "@speed/common/src/components/Text/Text";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import { lnaddressSetup } from "../images";
import { Input } from "@speed/common/src/components/Input/Input";
import { continueBtn } from "@speed/common/src/messages";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import { useFormik } from "formik";
import * as yup from "yup";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import { callAPIInterface, validatePaymentAddressUsername } from "../constants";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";

const lnAddressDomain = process.env.REACT_APP_WALLET_PAYMENT_ADDRESS_DOMAIN;

const CreatePaymentAddressModal = ({
  isSettingupLnAddress,
  setIsSettingupLnAddress,
  fetchPaymentAddress,
}) => {
  const [stepSetup, setStepSetup] = useState(1);
  const [showEnterLnaddress, setShowEnterLnaddress] = useState(false);
  const [showSetupCompleted, setShowSetupCompleted] = useState(false);
  const [isUsernameAvailable, setIsUsernameAvailable] = useState(false);
  const [isLnAddressCreating, setIsLnAddressCreating] = useState(false);

  const validationSchema = yup.object({
    username: yup
      .string()
      .required(enterValidUsername)
      .min(5, usernameMustBeLong)
      .test({
        name: "username",
        test: (name) => validatePaymentAddressUsername(name),
      }),
  });

  const formik = useFormik({
    initialValues: {
      username: "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const {
    values,
    setFieldValue,
    resetForm,
    touched,
    errors,
    setTouched,
    isValid,
    dirty,
  } = formik;

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  useEffect(() => {
    let timeoutId;
    if (values.username.length >= 5) {
      timeoutId = setTimeout(() => {
        validatePaymentAddress();
      }, 1000);
    }

    return () => clearTimeout(timeoutId);
  }, [values.username]);

  const validatePaymentAddress = () => {
    const data = {
      username: values.username,
      domain: lnAddressDomain,
    };
    callAPIInterface("POST", "/payment-addresses/validate", data)
      .then((res) => {
        if (res) {
          setIsUsernameAvailable(res?.is_available);
        }
      })
      .catch((_e) => {});
  };

  const createPaymentAddress = () => {
    const data = {
      username: values.username,
    };
    setIsLnAddressCreating(true);
    callAPIInterface("POST", "/payment-addresses", data)
      .then((res) => {
        if (res) {
          setShowEnterLnaddress(false);
          setShowSetupCompleted(true);
          setStepSetup(stepSetup + 1);
          setIsLnAddressCreating(false);
        }
      })
      .catch(() => {
        setIsLnAddressCreating(false);
      });
  };

  const showLnAddressSetup = () => {
    return showSetupCompleted ? (
      <>
        <Text size={14} variant="body1" className="grey-text" font="regular">
          {successfullyClaimedText}
        </Text>
        <Box className="lnaddress-complete-setup">
          <Text
            size={14}
            variant="body1"
            className="default-text"
            font="regular"
            style={{ letterSpacing: "1px" }}
          >
            {yourLnAddress}
          </Text>
          <Box className="lnaddress-modal">
            <TruncatedTextTooltip
              textProps={{
                size: 18,
                variant: "h6",
                className: "default-text ",
                font: "semibold",
                style: { color: "#2A67FF", marginRight: "8px" },
              }}
              textValue={`${values.username}${lnAddressDomain}`}
              cellWidth="168px"
            />
            <Clipboard text={`${values.username}${lnAddressDomain}`} />
          </Box>
        </Box>
      </>
    ) : (
      <>
        <Text size={14} variant="body1" className="grey-text" font="regular">
          {lnAddressDetails[0]}
        </Text>
        <Text
          size={14}
          variant="body1"
          className="grey-text"
          font="regular"
          sx={{ marginTop: "10px" }}
        >
          {lnAddressDetails[1]}
        </Text>
      </>
    );
  };

  const renderYourLnAddress = () => {
    return (
      <Box mt="40px" display="flex" flexDirection="column" alignItems="center">
        <Text
          size={14}
          variant="body1"
          className="default-text"
          font="regular"
          style={{ letterSpacing: "1px" }}
        >
          {yourLnAddress}
        </Text>
        <Text
          size={18}
          className="default-text lnaddress-text"
          variant="h6"
          font="semibold"
        >
          <span style={{ color: "#2A67FF" }}>{values.username}</span>
          {lnAddressDomain}
        </Text>
      </Box>
    );
  };

  const bodyContentLnAddress = () => (
    <Box display="flex" flexDirection="column" alignItems="center">
      {(!showEnterLnaddress || showSetupCompleted) && (
        <img src={lnaddressSetup} alt="lnaddress-setup" />
      )}
      <Text size={20} className="default-text" sx={{ margin: "10px 0px" }}>
        {showSetupCompleted ? congratulationText : setupLn}
      </Text>
      {showEnterLnaddress ? (
        <Box className="lnaddress-modal-text">
          <Text size={14} variant="body1" className="grey-text" font="regular">
            {joinNowLnAddressText}
          </Text>
          <Input
            type="text"
            name="name"
            inputProps={{ maxLength: 32 }}
            value={values.username}
            customClass="margin-top30"
            showLabel
            label={setYourUsername}
            fullWidth
            onBlur={() => setTouched({ ...touched, username: true })}
            error={touched.username && Boolean(errors.username)}
            onChange={(e) => {
              setTouched({ ...touched, username: false });
              setFieldValue("username", e.target.value);
            }}
          />
          <InputErrorMessage
            {...defaultErrorMessageProps}
            inputName="username"
          />
          {renderYourLnAddress()}
        </Box>
      ) : (
        showLnAddressSetup()
      )}
    </Box>
  );

  const footerLnAddress = () => (
    <Button
      fullWidth
      label={stepSetup === 3 ? continueBtn : setupLnAddress}
      variant="contained"
      loading={isLnAddressCreating}
      disabled={
        showEnterLnaddress ? !(isValid && dirty && isUsernameAvailable) : false
      }
      onClick={() => {
        handleSetuplnAddress(stepSetup);
      }}
    />
  );

  const handleLnAddressModalClose = () => {
    setIsSettingupLnAddress(false);
    resetForm();
    setShowEnterLnaddress(false);
    setShowSetupCompleted(false);
    setStepSetup(1);
    stepSetup === 3 && fetchPaymentAddress();
  };

  const handleSetuplnAddress = (stepNum) => {
    switch (stepNum) {
      case 1:
        setShowEnterLnaddress(true);
        setStepSetup(stepNum + 1);
        break;
      case 2:
        createPaymentAddress();
        break;
      case 3:
        handleLnAddressModalClose();
        break;
    }
  };

  return (
    <Modal
      maxWidth="xs"
      body={bodyContentLnAddress()}
      handleClose={() => handleLnAddressModalClose()}
      open={isSettingupLnAddress}
      footer={footerLnAddress()}
      title={stepSetup === 3 ? setupCompleted : setUpText}
    />
  );
};

export default CreatePaymentAddressModal;
