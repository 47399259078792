import React, { forwardRef, useState, useEffect } from "react";
import { AppBar, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Text from "@speed/common/src/components/Text/Text";
import {
  balanceExceeded,
  createInstantPayout,
  lnInfoText,
  onchainEstimateMsg,
  onchainInfoText,
  payoutTypeLightning,
  payoutTypeOnchain,
  proceedBtn,
  sendMaxMsg,
} from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  callAPIInterface,
  getPayoutBalanceAPI,
  idealTimeLimitReached,
  setShowCrucialActionModal,
  updateSessionLastActionTime,
} from "../constants";
import { useFormik } from "formik";
import {
  setExecuteAPICall,
  setIsModalOpen,
  setLoading,
  showToast,
} from "../../redux/common/actions";
import * as yup from "yup";
import history from "@speed/common/src/components/history";
import RequestInstantPayout from "./RequestInstantPayout";
import {
  btcToSatsAmount,
  satsToBtcAmount,
} from "@speed/common/src/components/constants";
import { sessionService } from "redux-react-session";
import FeesExceedModal from "../Common/FeesExceedModal";
import InsufficientBalanceModal from "../Common/InsufficientBalanceModal";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CreateInstantPayout = () => {
  const dispatch = useDispatch();

  const [disableConfirm, setDisableConfirm] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [balance, setBalance] = useState();
  const [selectedWallet, setSelectedWallet] = useState({});
  const [estimatedFees, setEstimatedFees] = useState(0);
  const [newEstimatedFees, setNewEstimatedFees] = useState();
  const [openFeesModal, setOpenFeesModal] = useState(false);
  const [openInsufficientBalanceModal, setOpenInsufficientBalanceModal] =
    useState(false);
  const [feesLoader, setFeesLoader] = useState(false);
  const [buttonLoader, setButtonLoader] = useState(false);
  const [balanceLoader, setBalanceLoader] = useState(false);
  const [amountInSats, setAmountInSats] = useState(0);
  const [feesBtnLoader, setFeesBtnLoader] = useState(false);
  const [sendMaxAmount, setSendMaxAmount] = useState(null);
  const [session, setSession] = useState(null);
  const [speedFeeValue, setSpeedFeeValue] = useState(0);

  const { isModalOpen, executeAPICall } = useSelector((state) => state.common);
  const user = useSelector((state) => state.auth.user);

  const speedFeesInSats = amountInSats * (speedFeeValue / 100);

  useEffect(() => {
    sessionService.loadSession().then((newSession) => {
      setSession(newSession);
    });
  }, []);

  useEffect(() => {
    if (executeAPICall) {
      const newSession = updateSessionLastActionTime();
      setSession(newSession);
      createNewRequest();
      dispatch(setLoading(false));
      setShowCrucialActionModal(user, false);
      dispatch(setExecuteAPICall(false));
    }
  }, [executeAPICall]);

  // Get the current balance of account.
  const getPayoutBalance = async () => {
    try {
      const result = await getPayoutBalanceAPI();
      setDisableConfirm(false);
      return result;
    } catch (e) {
      setDisableConfirm(true);
    }
  };

  const fnForValidatePayoutAmount = (
    payoutType,
    amountInSats,
    fees,
    sendMaxAmount
  ) => {
    const lightningMaxLimit = Number(process.env.REACT_APP_LIGHTNING_MAX_LIMIT);
    const onchainMaxLimit = Number(process.env.REACT_APP_ONCHAIN_MAX_LIMIT);
    const convertBalanceToSats = btcToSatsAmount(balance);
    const minimumSatsValue = payoutType === payoutTypeLightning ? 1 : 1000;
    const maximumSatsValue =
      payoutType === payoutTypeLightning ? lightningMaxLimit : onchainMaxLimit;

    if (amountInSats < minimumSatsValue || amountInSats > maximumSatsValue) {
      return {
        isValid: false,
        errorMessage:
          payoutType === payoutTypeLightning
            ? lnInfoText(lightningMaxLimit)
            : onchainInfoText(satsToBtcAmount(onchainMaxLimit)),
      };
    } else if (amountInSats + fees > convertBalanceToSats) {
      return {
        isValid: false,
        errorMessage: balanceExceeded,
      };
    } else if (sendMaxAmount && sendMaxAmount < amountInSats) {
      return {
        isValid: false,
        errorMessage: sendMaxMsg,
      };
    } else return { isValid: true };
  };

  const validatePayoutAmount = (parent) => {
    const { payoutType } = parent;
    return fnForValidatePayoutAmount(
      payoutType,
      amountInSats,
      estimatedFees + speedFeesInSats,
      sendMaxAmount
    );
  };

  const validationSchema = yup.object({
    payoutType: yup.string().required(""),
    payoutAmount: yup
      .number()
      .required("")
      .test("validation-payout-amount", function (value) {
        const validation = validatePayoutAmount(this.parent);
        if (!validation.isValid) {
          return this.createError({
            path: this.path,
            message: validation.errorMessage,
          });
        } else {
          return true;
        }
      }),
    wallet: yup.mixed().required(),
  });

  const initialValues = {
    wallet: null,
    payoutType: payoutTypeOnchain,
    payoutCurrencyType: { name: "Bitcoin", code: "BTC", symbol: "฿" },
    payoutAmount: "",
    note: "",
  };

  const getCalculatedBalance = async () => {
    const updatedBalance = await getPayoutBalance();
    const balanceInSats = btcToSatsAmount(updatedBalance);
    return { updatedBalance, balanceInSats };
  };

  const proceedFunc = (balanceInSats) => {
    if (balanceInSats < amountInSats + estimatedFees + speedFeesInSats)
      setOpenInsufficientBalanceModal(true);
    else {
      if (idealTimeLimitReached(session.last_action_time))
        setShowCrucialActionModal(user, true);
      else createNewRequest();
    }
  };

  const handleProceed = async (values) => {
    setButtonLoader(true);
    formik.setSubmitting(false);
    const { payoutType, wallet } = values;
    const { balanceInSats } = await getCalculatedBalance();

    if (payoutType === payoutTypeOnchain) {
      const feesResponse = await getEstimatedFees(wallet?.address, false);
      const newFees = feesResponse?.fee;
      if (estimatedFees < newFees) {
        setOpenFeesModal(true);
        setNewEstimatedFees(newFees);
      } else proceedFunc(balanceInSats);
    } else {
      proceedFunc(balanceInSats);
    }
    setButtonLoader(false);
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleProceed,
    enableReinitialize: true,
  });

  const {
    values,
    handleSubmit,
    setFieldValue,
    isValid,
    isSubmitting,
    dirty,
    validateForm,
  } = formik;

  const handleFeesProceed = async () => {
    setFeesBtnLoader(true);
    const { balanceInSats } = await getCalculatedBalance();

    // Check if balance is less than actual amount+fees
    if (balanceInSats < amountInSats + newEstimatedFees + speedFeesInSats) {
      setEstimatedFees(newEstimatedFees);
      setOpenInsufficientBalanceModal(true);
    } else {
      if (idealTimeLimitReached(session.last_action_time))
        setShowCrucialActionModal(user, true);
      else createNewRequest();
    }
    setOpenFeesModal(false);
    setFeesBtnLoader(false);
  };

  const handleTryAgain = () => {
    setOpenInsufficientBalanceModal(false);
    setFieldValue("payoutAmount", "");
    setFieldValue("payoutCurrencyType", initialValues.payoutCurrencyType);
    setEstimatedFees(estimatedFees);
    setBalanceLoader(true);
    // call the balance API to update balance
    getPayoutBalance()
      .then((balance) => {
        setBalanceLoader(false);
        setBalance(balance);
      })
      .catch((_e) => {
        setBalanceLoader(false);
      });
  };

  const abortController = new AbortController();

  const getEstimatedFees = (address, shouldStateSet = true) => {
    setFeesLoader(true);
    let params = {
      address_with_amount: {
        [address]: amountInSats,
      },
    };
    if (values.payoutType === payoutTypeOnchain) params.target_confirmation = 2;

    const { signal } = abortController;
    return callAPIInterface(
      "POST",
      "/withdraws/estimate",
      params,
      null,
      null,
      signal
    )
      .then((res) => {
        setFeesLoader(false);
        setDisableConfirm(false);
        if (shouldStateSet) {
          setEstimatedFees(res?.fee);
          validateForm();
        }
        return res;
      })
      .catch((_e) => {
        dispatch(
          showToast({
            isToastOpen: true,
            toastMessage: onchainEstimateMsg,
            toastVariant: "error",
          })
        );
        setFeesLoader(false);
        setDisableConfirm(true);
      });
  };

  useEffect(() => {
    return () => abortController.abort();
  }, []);

  useEffect(() => {
    formik.validateForm();
  }, [amountInSats]);

  const createNewRequest = () => {
    const params = {
      wallet_id: values?.wallet?.id,
      amount: values?.payoutAmount,
      currency: values?.payoutCurrencyType?.code,
      target_currency: "SATS",
    };
    if (values.note !== "") params.note = values.note;

    setDisableConfirm(true);
    callAPIInterface("POST", "/payouts", JSON.stringify(params))
      .then((result) => {
        result && history.push(`/payouts/${result.id}`);
        setDisableConfirm(false);
        handleModalClose();
      })
      .catch((_e) => {
        setDisableConfirm(false);
        handleModalClose();
      });
  };

  const handleModalClose = () => {
    dispatch(setIsModalOpen(false));
    setSelectedWallet({});
    setBalance();
    setIsChecked(false);
  };

  const disableBtnCheck =
    !(isValid && dirty) || isSubmitting || !isChecked || disableConfirm;

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={isModalOpen}
      TransitionComponent={Transition}
      className="fullscreen-modal"
      onClose={handleModalClose}
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleModalClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Text
            size={20}
            font="semibold"
            sx={{ flex: 1 }}
            className="default-text divider"
            variant="h6"
          >
            {createInstantPayout}
          </Text>
          <Button
            label={proceedBtn}
            variant="contained"
            color="primary"
            className="payment-link-btn"
            onClick={handleSubmit}
            loading={buttonLoader}
            disabled={disableBtnCheck}
          />
        </Toolbar>
      </AppBar>
      <RequestInstantPayout
        formik={formik}
        selectedWallet={selectedWallet}
        setSelectedWallet={setSelectedWallet}
        isModalOpen={isModalOpen}
        setBalance={setBalance}
        balance={balance}
        isChecked={isChecked}
        setIsChecked={setIsChecked}
        estimatedFees={estimatedFees}
        getPayoutBalance={getPayoutBalance}
        feesLoader={feesLoader}
        getEstimatedFees={getEstimatedFees}
        setFeesLoader={setFeesLoader}
        setEstimatedFees={setEstimatedFees}
        balanceLoader={balanceLoader}
        amountInSats={amountInSats}
        setAmountInSats={setAmountInSats}
        fnForValidatePayoutAmount={fnForValidatePayoutAmount}
        setSendMaxAmount={setSendMaxAmount}
        speedFeeValue={speedFeeValue}
        setSpeedFeeValue={setSpeedFeeValue}
      />
      {openFeesModal && (
        <FeesExceedModal
          btnLoader={feesBtnLoader}
          estimatedFees={estimatedFees}
          handleClose={() => setOpenFeesModal(false)}
          handleFeesProceed={handleFeesProceed}
          newEstimatedFees={newEstimatedFees}
          openFeesModal={openFeesModal}
        />
      )}
      {openInsufficientBalanceModal && (
        <InsufficientBalanceModal
          handleClose={() => setOpenInsufficientBalanceModal(false)}
          handleTryAgain={handleTryAgain}
          open={openInsufficientBalanceModal}
          totalFees={amountInSats + estimatedFees + speedFeesInSats}
          type="Payout"
        />
      )}
    </Dialog>
  );
};

export default CreateInstantPayout;
