import { useSelector } from "react-redux";
import { emptySubText } from "@speed/common/src/components/messages";
import PaymentAndCheckoutLinkTable from "../Common/PaymentAndCheckoutLinkTable";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { useFlags } from "launchdarkly-react-client-sdk";
import { emptySession } from "../images";
import { emptySessionSubText } from "../messages";

const sessionTableHeader = [
  { title: "Session ID", width: "170px", paddingLeft: "38px !important" },
  { title: "Description", width: "350px" },
  { title: "Amount", width: "230px", align: "right" },
  { title: "Created on", width: "235px" },
  { title: "Status", width: "138px" },
  { title: "Actions", width: "25px" },
];

const SessionList = ({
  tab,
  showHorizontalTable = false,
  searchQueryUrl,
  inputString,
  searchResultData,
  getModuleSearchResultData,
  totalNumOfSearchResults,
}) => {
  const { csListFe } = useFlags();
  const liveMode = useSelector((state) => state.auth.liveMode);

  return csListFe ? (
    <PaymentAndCheckoutLinkTable
      moduleName="checkout_session"
      tableHeader={sessionTableHeader}
      linkPath="checkout-sessions"
      tab={tab}
      showHorizontalTable={showHorizontalTable}
      getModuleSearchResultData={getModuleSearchResultData}
      searchQueryUrl={searchQueryUrl}
      inputString={inputString}
      searchResultData={searchResultData}
      totalNumOfSearchResults={totalNumOfSearchResults}
      textOnNoData={emptySubText(liveMode, "sessions", tab)}
      subTextOnNoData={emptySessionSubText}
      noDataImage={emptySession}
    />
  ) : (
    <AccessDenied />
  );
};

export default SessionList;
