import React, { useEffect, useState } from "react";
import { Box, Grid, TableCell, TableRow } from "@mui/material";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import {
  activateDeactivateWithdrawalLink,
  callAPIInterface,
  dateTimeFormatInApp,
  moduleMetaDataRows,
  renderStatus,
  reSendWithdrawalLinkEmail,
  RouteBreadcrumbs,
  showPassword,
  updateMetadata,
} from "../constants";
import Text from "@speed/common/src/components/Text/Text";
import {
  clipboardElement,
  generateBreadcrumbs,
  getCurrencyObj,
  linkStatus,
  redirectWhenEntityNotFound,
  satsToBtcAmount,
  showAmount,
} from "@speed/common/src/components/constants";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import {
  add,
  dateCreated,
  deActivate,
  details,
  edit,
  entityNotFoundMessage,
  events as eventsText,
  metaDataText,
  password as passwordHeader,
  resendEmail,
  status,
  withdraw,
  withdrawalLinkHeading,
} from "../messages";
import { useSelector } from "react-redux";
import Button from "@speed/common/src/components/Button/Button";
import { Input } from "@speed/common/src/components/Input/Input";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import MetaDataForm from "../MetaDataForm";
import { events } from "../Events/Events";
import { loaderSpinner } from "@speed/common/src/components/images";
import WarningIcon from "@mui/icons-material/Warning";
import DetailPagePaymentSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import LinkSkeleton from "@speed/common/src/components/Skeleton/LinkSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";
import { email, name } from "@speed/common/src/components/messages";

const withdrawColumnData = [
  { title: "Withdraw ID", width: "480px" },
  { title: "Amount (BTC)", width: "135px" },
  { title: "Date", width: "230px" },
  { title: "Status", width: "120px" },
];

function LinkDetail(props) {
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const urlParams = useParams();

  const [data, setData] = useState(null);
  const [isLinkActive, setLinkActive] = useState(true);
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [withdrawList, setWithdrawList] = useState([]);
  const [withdrawSkeleton, setWithdrawSkeleton] = useState(false);
  const [metaDataSkeleton, setMetaDataSkeleton] = useState(true);
  const [isMetaDataEditable, setIsMetaDataEditable] = useState(false);
  const [metaDataList, setMetaDataList] = useState([]);
  const [password, setPassword] = useState(null);
  const [isResendingEmail, setIsResendingEmail] = useState(false);
  const [deactivateButtonLoader, setDeactivateButtonLoader] = useState(false);
  const [passwordLoader, setPasswordLoader] = useState(false);
  const [copyPasswordLoader, setCopyPasswordLoader] = useState(false);
  const [passwordCheckState, setPasswordCheckState] = useState(false);
  const [eventSkeleton, setEventSkeleton] = useState(true);
  const [eventsList, setEventsList] = useState([]);

  const { liveMode, currentAccount } = useSelector((state) => state.auth);
  const { history } = useSelector((state) => state.common);

  let detailsDataTableArr = [
    {
      header: status,
      cell: (
        <Tag
          text={linkStatus[data?.status]?.label}
          variant={linkStatus[data?.status]?.variant}
        />
      ),
    },
    {
      header: dateCreated,
      cell: (
        <Text variant="h4" size={16} className="default-text" font="regular">
          {dateTimeFormatInApp(data?.created)}
        </Text>
      ),
    },
  ];
  useEffect(() => {
    loadDetailPage();
  }, [liveMode, urlParams?.id]);

  useEffect(() => {
    if (data?.metadata) {
      const convertedMetaDataToArray = Object.entries(data.metadata)?.map(
        (e) => ({
          key: e[0],
          value: e[1],
        })
      );
      setMetaDataList(convertedMetaDataToArray);
      setMetaDataSkeleton(false);
    }
  }, [data?.metadata]);

  const headerContent = () => {
    return (
      <Box className="header-content">
        <Text className="default-text" size={28}>
          {withdrawalLinkHeading}
        </Text>

        <Box className="header-price-content">
          <Box className="fiat-price">
            <Text className="default-text" size={20}>
              {getCurrencyObj(data.currency).symbol}
              {showAmount({
                amount: data.amount,
                currency: data.currency,
              })}
            </Text>
          </Box>
        </Box>

        <Box sx={{ marginTop: "15px" }}>
          {clipboardElement(
            data?.url,
            isLinkActive,
            "branding-detail",
            "",
            true
          )}
        </Box>
      </Box>
    );
  };

  const handleStopPasswordLoader = (icon) => {
    return icon === "eye-icon"
      ? setPasswordLoader(false)
      : setCopyPasswordLoader(false);
  };

  const handleShowPassword = (icon) => {
    if (icon === "eye-icon") {
      setPasswordCheckState(true);
      setPasswordLoader(true);
    } else {
      setPasswordLoader(false);
      setCopyPasswordLoader(true);
    }
    return new Promise((resolve, reject) => {
      showPassword(urlParams?.id, currentAccount?.account?.id)
        .then((res) => {
          if (res) {
            setPassword(res);
            handleStopPasswordLoader(icon);
            resolve(res);
          }
        })
        .catch((error) => {
          handleStopPasswordLoader(icon);
          reject(error);
        });
    });
  };

  const detailsTableData = () => {
    if (data?.is_secured) {
      detailsDataTableArr.push({
        header: passwordHeader,
        cell: (
          <Box display="flex" width="fit-content" alignItems="center">
            <Input
              className="password-protected"
              type="password"
              isPasswordVisible={true}
              showLabel={false}
              value={password || "dummyVal"}
              name="password"
              from="withdrawalLink"
              isFromDetailPage={true}
              handleShowPassword={!password && handleShowPassword}
              passwordLoader={passwordLoader}
              readOnly
            />
            <Box
              style={{ marginLeft: "10px" }}
              onClick={(event) => {
                event.stopPropagation();
                !password && handleShowPassword("copy-icon");
              }}
            >
              {copyPasswordLoader ? (
                <img src={loaderSpinner} alt="Loader" width={15} height={15} />
              ) : (
                <Clipboard
                  text={password}
                  from="withdrawal-link"
                  passwordCheckState={passwordCheckState}
                />
              )}
            </Box>
          </Box>
        ),
      });
    }

    if (data?.email) {
      detailsDataTableArr.push({
        header: email,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {data?.email}
          </Text>
        ),
      });
    }

    if (data?.name) {
      detailsDataTableArr.push({
        header: name,
        cell: (
          <Text variant="h4" size={16} className="default-text" font="regular">
            {data?.name}
          </Text>
        ),
      });
    }

    return detailsDataTableArr;
  };

  const getWithdrawList = () => {
    callAPIInterface("GET", `/withdrawal-links/${urlParams?.id}/withdraws`)
      .then((res) => {
        if (res) {
          setWithdrawSkeleton(false);
          setWithdrawList(res?.data);
        }
      })
      .catch(() => {
        setWithdrawSkeleton(false);
      });
  };

  const getEventsList = () => {
    setEventSkeleton(true);
    const filterEvents = {
      ids: [urlParams?.id],
    };
    callAPIInterface("POST", "/events/filters", JSON.stringify(filterEvents))
      .then((res) => {
        if (res) {
          setEventSkeleton(false);
          setEventsList(res?.data);
        }
      })
      .catch(() => {
        setEventSkeleton(false);
      });
  };

  const getWithdrawalLinkDetail = () => {
    setWithdrawSkeleton(true);
    setMetaDataSkeleton(true);
    callAPIInterface("GET", `/withdrawal-links/${urlParams.id}`)
      .then((res) => {
        if (res) {
          if (res?.withdraw_id.length) {
            getWithdrawList();
          } else {
            setWithdrawSkeleton(false);
          }
          getEventsList();
          setData(res);
          setLinkActive(res?.status === "active" || false);
        }
      })
      .catch((err) => {
        setWithdrawSkeleton(false);
        setMetaDataSkeleton(false);
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const loadDetailPage = () => {
    setData(null);
    setIsShowNotFound(false);
    getWithdrawalLinkDetail();
  };

  const handleResendEmail = () => {
    setIsResendingEmail(true);
    reSendWithdrawalLinkEmail(urlParams?.id)
      .then((res) => {
        if (res) {
          setIsResendingEmail(false);
        }
      })
      .catch(() => {
        setIsResendingEmail(false);
      });
  };

  const handleActiveDeActive = (status) => {
    if (!status) {
      setDeactivateButtonLoader(true);
      activateDeactivateWithdrawalLink(urlParams.id)
        .then((res) => {
          res && loadDetailPage();
          setDeactivateButtonLoader(false);
        })
        .catch(() => {
          setDeactivateButtonLoader(false);
        });
    }
  };

  const renderActivateDeActivateButton = () => {
    if (isLinkActive) {
      return (
        <>
          {data?.email && (
            <Button
              loading={isResendingEmail}
              icon="emailIcon"
              label={resendEmail}
              variant="outlined"
              onClick={handleResendEmail}
            />
          )}
          <Button
            icon="closeIcon"
            sx={{ marginLeft: "15px" }}
            label={deActivate}
            variant="outlined"
            color="error"
            loading={deactivateButtonLoader}
            onClick={() => {
              handleActiveDeActive(false);
            }}
          />
        </>
      );
    } else {
      return null;
    }
  };

  const onSubmit = (payload) => {
    setMetaDataSkeleton(true);
    const finalPayload = { metadata: payload };
    updateMetadata(finalPayload, `/withdrawal-links/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setData(res);
        }
        setMetaDataSkeleton(false);
        setIsMetaDataEditable(false);
      })
      .catch((err) => {
        setMetaDataSkeleton(false);
        setIsMetaDataEditable(false);
      });
  };

  const onCancel = () => setIsMetaDataEditable(false);

  const rows =
    withdrawList.length &&
    withdrawList.map((rowItem) => (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/withdraws/${rowItem?.id}`)}
      >
        <TableCell>
          {clipboardElement(rowItem?.id, true, "branding-detail", "text", true)}
        </TableCell>
        <TableCell align="center">
          {satsToBtcAmount(rowItem.target_amount_paid)}
        </TableCell>
        <TableCell align="left">
          {dateTimeFormatInApp(rowItem?.created)}
        </TableCell>
        <TableCell align="left">{renderStatus("paid")}</TableCell>
      </TableRow>
    ));

  const eventRows = eventsList?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/events/${rowItem?.id}`)}
      >
        <TableCell>{events(rowItem)}</TableCell>
        <TableCell align="right" sx={{ color: "#848b9e" }}>
          {dateTimeFormatInApp(rowItem?.created)}
        </TableCell>
      </TableRow>
    );
  });

  return !isShowNotFound ? (
    <Grid className="payment-link-detail-wrapper" container spacing={1}>
      <Box className="section-wrapper" sx={{ position: "relative" }}>
        {data && (
          <Box
            className="action-btn-wrapper"
            style={{ top: "23px", right: "2px" }}
          >
            {renderActivateDeActivateButton()}
          </Box>
        )}
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        {data ? (
          <>
            {headerContent()}

            <Box className="details-content">
              <VerticalTable label={details} rowData={detailsTableData()} />
            </Box>
          </>
        ) : (
          <LinkSkeleton props={props} />
        )}

        {!withdrawSkeleton ? (
          <Box className="product-content">
            <HorizontalTable
              label={withdraw}
              isColumnShown={rows.length > 0}
              isShowButton={rows.length > 3}
              columns={withdrawColumnData}
              rows={rows}
              rowsPerPage={3}
              isShowPagination={false}
              tableBodyClassName="border-none"
              handleViewAllClick={() => {
                history.push(`/withdrawal-links/${data?.id}/payments`);
              }}
            />
          </Box>
        ) : (
          <DetailPagePaymentSkeleton />
        )}

        {!metaDataSkeleton ? (
          <Box className="product-content meta-data-content">
            {isMetaDataEditable ? (
              <MetaDataForm
                metaDataList={metaDataList}
                setIsMetaDataEditable={setIsMetaDataEditable}
                onSubmit={onSubmit}
                onCancel={onCancel}
              />
            ) : (
              <HorizontalTable
                label={metaDataText}
                rows={moduleMetaDataRows(metaDataList)}
                rowsPerPage={51}
                isColumnShown={moduleMetaDataRows(metaDataList)?.length > 0}
                tableBodyClassName="border-none"
                isShowButton={!isMetaDataEditable}
                displayShowButton={true}
                viewAllButtonLabel={metaDataList?.length > 0 ? edit : add}
                viewAllButtonIcon="editIcon"
                handleViewAllClick={() => setIsMetaDataEditable(true)}
              />
            )}
          </Box>
        ) : (
          <DetailPagePaymentSkeleton />
        )}

        {!eventSkeleton ? (
          <Box className="product-content">
            <HorizontalTable
              label={eventsText}
              isColumnShown={eventRows?.length > 0}
              rows={eventRows}
              rowsPerPage={3}
              handleViewAllClick={() => {
                history.push(`/checkout-links/${urlParams?.id}/events`);
              }}
              isShowButton={eventRows?.length > 3}
              tableBodyClassName="border-none"
            />
          </Box>
        ) : (
          <DetailPagePaymentSkeleton />
        )}
      </Box>
    </Grid>
  ) : (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage("Link")}
        subTitle={`Withdrawal link ${urlParams.id} is not found.`}
        btnText={`View ${(liveMode && "live") || "test"} mode links`}
      />
    </Box>
  );
}

export default LinkDetail;
