import React, { useEffect, useState } from "react";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import {
  archive,
  archiveModalMessage,
  archivePrice,
  archivePriceModalMessage,
  archiveProduct,
  deleteModalMessage,
  deletePrice,
  deletePriceModalMessage,
  deleteProduct,
  deleteProductErrorMessage,
  deleteText,
  edit,
  noProductFound,
  unArchive,
} from "../messages";
import CommonModal from "./CommonModal";
import {
  archiveUnarchiveActions,
  editProduct,
  isFromPRoduct,
} from "../constants";

const CommonActionPopper = ({
  anchorEl,
  setAnchorEl,
  handleEdit, // will be used in future
  from,
  currentRow,
  afterArchiveAction,
  setRowLoader,
  getProductDetails,
}) => {
  //for actions: archive & unarchive

  const archivedAction = currentRow?.active ? archive : unArchive;
  const actionLowerCase = archivedAction.toLowerCase();

  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [message, setMessage] = useState("");
  const [action, setAction] = useState(null);
  const [deleteProductError, setDeleteProductError] = useState(false);
  const [isDisplayFooter, setIsDisplayFooter] = useState(true);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setAction(actionLowerCase);
  }, [actionLowerCase]);

  const handleArchive = () => {
    if (action === "unarchive") {
      handleAction();
    } else {
      setIsActionModalOpen(true);
      setDeleteProductError(false);
      setTitle(isFromPRoduct.includes(from) ? archiveProduct : archivePrice);
      setMessage(
        isFromPRoduct.includes(from)
          ? archiveModalMessage
          : archivePriceModalMessage
      );
    }
  };

  const handleDelete = () => {
    setIsActionModalOpen(true);
    setDeleteProductError(false);
    setTitle(isFromPRoduct.includes(from) ? deleteProduct : deletePrice);
    setMessage(
      isFromPRoduct.includes(from)
        ? deleteModalMessage
        : deletePriceModalMessage
    );
    setAction("delete");
  };

  const handleModalClose = () => {
    setIsActionModalOpen(false);
  };

  const handleAction = () => {
    if (action === "delete") {
      setDeleteProductError(true);
      setMessage(deleteProductErrorMessage || noProductFound);
      setIsDisplayFooter(false);
    } else if (archiveUnarchiveActions.includes(action)) {
      //for product and price archive/unarchive
      if (isFromPRoduct.includes(from)) {
        // for product archive/ unarchive
        if (action === "archive") {
          setLoading(true);
        } else if (action === "unarchive") {
          setRowLoader(true);
        }
      }

      editProduct(currentRow?.id, { active: !currentRow?.active })
        .then((res) => {
          setLoading(false);
          setIsActionModalOpen(false);
          if (from === "productDetail") {
            setAnchorEl(null);
            getProductDetails();
          } else {
            afterArchiveAction(res);
          }
        })
        .catch(() => {
          setLoading(false);
          setRowLoader(false);
        });
    }
  };

  return (
    <Box>
      <CustomPopper
        disablePortal={true}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        position="bottom"
        handleClose={() => setAnchorEl(null)}
      >
        <List>
          {/* <ListItem disablePadding>
            <ListItemButton onClick={handleEdit}>
              <ListItemText primary={edit} className="text-primary" />
            </ListItemButton>
          </ListItem> */}
          <ListItem disablePadding>
            <ListItemButton onClick={handleArchive}>
              <ListItemText primary={archivedAction} className="text-primary" />
            </ListItemButton>
          </ListItem>
          {/* <ListItem disablePadding>
            <ListItemButton onClick={handleDelete}>
              <ListItemText primary={deleteText} className="text-danger" />
            </ListItemButton>
          </ListItem> */}
        </List>
      </CustomPopper>
      <CommonModal
        message={message}
        handleModalClose={() => handleModalClose()}
        isModalOpen={isActionModalOpen}
        title={title}
        handleAction={handleAction}
        isDisplayFooter={isDisplayFooter}
        setIsDisplayFooter={(value) => setIsDisplayFooter(value)}
        deleteProductError={deleteProductError}
        action={action}
        from={from}
        actionLoader={loading}
        // productId="‘prod_O38Rjfu2x3Hy’"
      />
    </Box>
  );
};

export default CommonActionPopper;
