import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import CommonHeader from "../Common/CommonHeader";
import BalanceCard from "./BalanceCard";
import { availableBalance, availableBalanceText } from "../messages";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import "../../assets/styles/balance.scss";
import { useSelector } from "react-redux";
import { callAPIInterface } from "../constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import { fetchRawBalanceData } from "@speed/common/src/components/constants";

const Balances = () => {
  const [balanceData, setBalanceData] = useState();
  const [isLoading, setLoading] = useState(true);
  const liveMode = useSelector((state) => state.auth.liveMode);
  const { history, isMobile } = useSelector((state) => state.common);
  const {
    poListpayoutrequest,
    poCreatepayoutrequest,
    swListingswapFe,
    swCreateFe,
    trListingFe,
    trCreateFe,
  } = useFlags();

  const isPayout = poListpayoutrequest || poCreatepayoutrequest;

  const isSwap = swListingswapFe || swCreateFe;

  const isTransfer = trListingFe || trCreateFe;

  const getBalanceData = () => {
    setLoading(true);
    callAPIInterface("GET", "/balances")
      .then((resData) => {
        if (resData) {
          const data = fetchRawBalanceData(resData);
          setBalanceData(data);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getBalanceData();
  }, [liveMode]);

  const getBalanceObject = (currency) =>
    balanceData?.find((data) => data?.target_currency === currency);

  const commonBalanceCardProps = {
    icon: <CreditScoreIcon className="card-icon" />,
    msg: availableBalanceText,
    isLoading,
  };

  const usdtObject = getBalanceObject("USDT");

  return (
    <Box className="section-wrapper">
      <CommonHeader />
      <CustomDivider />
      <Box
        className="balance-wrapper box-container"
        style={{ minHeight: isMobile ? "95vh" : "83vh" }}
        display="flex"
        gap={3}
      >
        <BalanceCard
          text={`${availableBalance} (BTC)`}
          balanceData={getBalanceObject("SATS")}
          isPayout={isPayout}
          handleOnClickPayout={() => history.push("/payouts")}
          isTransfer={isTransfer}
          handleOnClickTransfer={() => history.push("/transfers")}
          isSwap={isSwap}
          handleOnClickSwap={() => history.push("/swaps")}
          isBalanceOtherThanSATS={false}
          {...commonBalanceCardProps}
        />
        {usdtObject?.amount > 0 && (
          <BalanceCard
            text={`${availableBalance} (USDT)`}
            balanceData={usdtObject}
            isBalanceOtherThanSATS={true}
            {...commonBalanceCardProps}
          />
        )}
      </Box>
    </Box>
  );
};

export default Balances;
