import CustomNotification from "@speed/common/src/components/Notification/Notification";
import { hideToast } from "../../redux/common/actions";
import { useSelector, useDispatch } from "react-redux";
import { pure } from "recompose";

const Notification = () => {
  const dispatch = useDispatch();
  const isToastOpen = useSelector((state) => state.common.isToastOpen);
  const toastMessage = useSelector((state) => state.common.toastMessage);
  const toastVariant = useSelector((state) => state.common.toastVariant);
  const toastAutoHideDuration = useSelector(
    (state) => state.common.toastAutoHideDuration
  );
  const notificationClass = useSelector(
    (state) => state.common.notificationClass
  );

  return (
    <CustomNotification
      open={isToastOpen}
      onClose={(_event, reason) => {
        if (reason === "clickaway") {
          return;
        }
        dispatch(hideToast());
      }}
      severity={toastVariant}
      message={toastMessage}
      className={notificationClass || ""}
      autoHideDuration={toastAutoHideDuration}
    />
  );
};

export default pure(Notification);
