import Button from "@speed/common/src/components/Button/Button";
import {
  continueWithApple,
  continueWithEmail,
  continueWithGoogle,
} from "../messages";
import { handleSSOLoginRedirection } from "@speed/common/src/components/constants";
// import { useFlags } from "launchdarkly-react-client-sdk";

const LoginWithSocialMedia = ({
  inviteeAuthState,
  forSignUp,
  ldFlagForGoogle = true,
  ldFlagForApple = true,
  ...props
}) => {
  // const { localhostSsoFe } = useFlags();
  const localhostSsoFe = false;
  const signInAppleButtonSx = {
    marginTop: "16px",
  };
  const signUpAppleButtonSx = {
    marginTop: !ldFlagForGoogle ? "0px" : "24px",
  };
  return (
    <>
      {ldFlagForGoogle && (
        <Button
          fullWidth
          sx={{ marginTop: forSignUp ? "0px" : "24px" }}
          variant="default"
          className="submit-signin-btn social-media-login"
          label={continueWithGoogle}
          icon="google"
          onClick={() =>
            handleSSOLoginRedirection(
              "google",
              inviteeAuthState,
              localhostSsoFe
            )
          }
        />
      )}
      {ldFlagForApple && (
        <Button
          fullWidth
          variant="default"
          className="submit-signin-btn social-media-login"
          sx={forSignUp ? signUpAppleButtonSx : signInAppleButtonSx}
          label={continueWithApple}
          icon="apple"
          onClick={() =>
            handleSSOLoginRedirection("apple", inviteeAuthState, localhostSsoFe)
          }
        />
      )}
      {forSignUp && (
        <Button
          fullWidth
          variant="default"
          className="submit-signin-btn social-media-login"
          sx={{ marginTop: forSignUp ? "24px" : "16px", marginBottom: "10px" }}
          label={continueWithEmail}
          icon="emailIcon"
          onClick={() => props?.setSignupWithEmailPassword?.(true)}
        />
      )}
    </>
  );
};
export default LoginWithSocialMedia;
