import Button from "@speed/common/src/components/Button/Button";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import { deleteDraftContentMsg, deleteDraftLabel } from "../messages";
import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import { cancel } from "@speed/common/src/components/messages";

const DeleteDraftModal = ({ openModal, setOpenModal }) => {
  const handleModalClose = () => setOpenModal(false);
  const renderModalContent = () => {
    return (
      <>
        <Text size={16} className="default-text">
          {deleteDraftContentMsg[0]}
        </Text>
        <Text
          size={16}
          variant="h4"
          className="grey-text"
          font="regular"
          sx={{ marginTop: "10px" }}
        >
          {deleteDraftContentMsg[1]}
        </Text>
      </>
    );
  };

  const renderModalFooter = () => {
    return (
      <Box className="invoice-delete-draft-modal-footer">
        <Button
          variant="outlined"
          type="submit"
          label={cancel}
          onClick={handleModalClose}
          disabled={false}
        />
        <Button
          className="delete-btn"
          color="error"
          type="submit"
          label={deleteDraftLabel}
          onClick={handleDeleteDraft}
          disabled={false}
        />
      </Box>
    );
  };

  const handleDeleteDraft = () => {};

  return (
    <Modal
      maxWidth="sm"
      className="invoice-item-currency-modal"
      open={openModal}
      body={renderModalContent()}
      footer={renderModalFooter()}
      handleClose={handleModalClose}
      title={deleteDraftLabel}
    />
  );
};
export default DeleteDraftModal;
