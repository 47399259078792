import { Box, Card, CardHeader } from "@mui/material";
import classNames from "classnames";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Text from "@speed/common/src/components/Text/Text";
import moment from "moment";
import { satsToBtcAmount } from "@speed/common/src/components/constants";
import { downArrowCircle, transactionChartImg, upArrowCircle } from "../images";
import { ShowNoDataCard } from "./utilities";

const LegendPadding = {
  id: "increase-legend-spacing",
  beforeInit(chart) {
    // Get reference to the original fit function
    const originalFit = chart.legend.fit;

    // Override the fit function
    chart.legend.fit = function fit() {
      // Call original function and bind scope in order to use `this` correctly inside it
      originalFit.bind(chart.legend)();
      // Change the height as suggested in another answers
      this.height += 10;
    };
  },
};

Chart.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
  LegendPadding
);
Chart.defaults.font.family = "Inter-Regular";

const getOrCreateTooltip = (chart) => {
  let tooltipEl = chart.canvas.parentNode.querySelector("div");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.style.background = "#ffffff";
    tooltipEl.style.color = "#0a193e";
    tooltipEl.style.opacity = 1;
    tooltipEl.style.pointerEvents = "none";
    tooltipEl.style.position = "absolute";
    tooltipEl.style.transform = "translate(-50%, 0)";
    tooltipEl.style.transition = "all .1s ease";
    tooltipEl.style.padding = "0";
    tooltipEl.style.borderRadius = "4px";
    tooltipEl.style.zIndex = 1;
    tooltipEl.style.boxShadow =
      "0 0 7px 3px rgba(11, 37, 75, 0.12), 0 0 0 1px rgba(11, 37, 75, 0.08)";

    const div = document.createElement("div");
    div.style.margin = "0px";

    tooltipEl.appendChild(div);
    chart.canvas.parentNode.appendChild(tooltipEl);
  }

  return tooltipEl;
};

const externalTooltipHandler = (context) => {
  // Tooltip Element
  const { chart, tooltip } = context;
  const tooltipEl = getOrCreateTooltip(chart);

  // Hide if no tooltip
  if (tooltip.opacity === 0) {
    tooltipEl.style.opacity = 0;
    return;
  }

  // Set Text
  if (tooltip.body) {
    const titleLines = tooltip.title || [];
    const bodyLines = tooltip.body.map((b) => b.lines);

    const heading = document.createElement("div");
    heading.style.padding = "13px 0 8px";
    heading.style.fontSize = "14px";
    heading.style.lineHeight = 1;
    heading.style.fontWeight = 700;
    heading.style.borderBottom = "1px solid #e4e9ee";
    heading.style.textAlign = "center";
    titleLines.forEach((title) => {
      title = getLabel(title);
      const text = document.createTextNode(title);
      heading.appendChild(text);
    });

    const content = document.createElement("div");
    bodyLines.forEach((body, i) => {
      const dataPoint = tooltip.dataPoints[i];
      const dataset = dataPoint.dataset;
      const value = `${dataPoint.raw}BTC`;

      content.style.padding = "12px 22px 20px 16px";
      content.style.display = "flex";
      content.style.alignItems = "center";

      const image = document.createElement("img");
      image.src = dataset.label === "Credit" ? upArrowCircle : downArrowCircle;
      image.alt = "icon";
      image.style.marginRight = "4px";

      const div = document.createElement("div");
      div.style.fontSize = "14px";
      div.style.lineHeight = "20px";
      div.style.display = "contents";

      const text = document.createTextNode(value);
      div.appendChild(text);

      content.appendChild(image);
      content.appendChild(div);
    });

    const root = tooltipEl.querySelector("div");

    // Remove old children
    while (root.firstChild) {
      root.firstChild.remove();
    }

    // Add new children
    root.appendChild(heading);
    root.appendChild(content);
  }

  const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

  // Display, position, and set styles for font
  tooltipEl.style.opacity = 1;
  tooltipEl.style.left = positionX + tooltip.caretX + "px";
  tooltipEl.style.top = positionY + tooltip.caretY + "px";
  tooltipEl.style.font = tooltip.options.bodyFont.string;
};

const options = {
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
      align: "end",
      labels: {
        boxWidth: 14,
        boxHeight: 14,
        useBorderRadius: true,
        borderRadius: 2,
        color: "#0a193e",
        font: {
          size: 14,
          lineHeight: 1.4,
        },
      },
    },
    tooltip: {
      enabled: false,
      position: "average",
      external: externalTooltipHandler,
    },
  },
  layout: {
    padding: {
      left: 20,
      right: 20,
      bottom: 20,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: "#848b9e",
        callback: function (value) {
          const label = this.getLabelForValue(value);
          return getLabel(label, false);
        },
      },
    },
    y: {
      title: {
        text: "Amount(BTC)",
        display: true,
        font: {
          size: 14,
          weight: 700,
          lineHeight: 1,
          color: "#0a193e",
        },
      },
      grid: {
        drawTicks: false,
      },
      ticks: {
        maxTicksLimit: 8,
        padding: 5,
        color: "#848b9e",
        callback: function (value) {
          const reg = /\.?0*$/;
          return value.toFixed(6).replace(reg, "");
        },
      },
    },
  },
};

let chartUnit = "years";
const getLabel = (date, withYears = true) => {
  switch (chartUnit) {
    case "days":
      let format = withYears ? "DD MMM YYYY" : "DD MMM";
      return moment(date, "YYYY-MM-DD").format(format);
    case "months":
      return moment(date, "YYYY-MM").format("MMM");
    case "years":
    default:
      return date;
  }
};

const TransactionChart = ({
  height,
  width,
  title,
  className,
  customStyle,
  data,
  loader,
}) => {
  const labels = [];
  const creditData = [];
  const debitData = [];
  const chartData = {
    labels,
    datasets: [],
  };

  if (data) {
    const chartDataArray = data.data;
    chartUnit = data.unit;

    chartDataArray.forEach((d) => {
      labels.push(d.date);
      creditData.push(satsToBtcAmount(d.credit_amount));
      debitData.push(satsToBtcAmount(d.debit_amount));
    });

    if (creditData.filter(Boolean).length || debitData.filter(Boolean).length) {
      chartData.datasets = [
        {
          label: "Credit",
          data: creditData,
          backgroundColor: "#82c79b",
          borderRadius: 2,
          borderSkipped: "bottom",
        },
        {
          label: "Debit",
          data: debitData,
          backgroundColor: "#ff9a9a",
          borderRadius: 2,
          borderSkipped: "bottom",
        },
      ];
    }
  }

  return (
    <Card
      className={classNames("dashboard-card", className)}
      sx={{
        height: height,
        width: width,
        ...customStyle,
      }}
    >
      <CardHeader
        title={
          <Text
            variant="h2"
            font="semibold"
            size={20}
            className="dashboard-title"
          >
            {title}
          </Text>
        }
      />

      <Box
        sx={{
          height: "300px",
        }}
      >
        <div className="chart-container">
          {!loader &&
          (creditData.filter(Boolean).length ||
            debitData.filter(Boolean).length) ? (
            <Bar options={options} data={chartData} />
          ) : (
            <img
              src={transactionChartImg}
              alt="loading"
              className="transaction-chart-loader"
            />
          )}

          {!loader &&
            !creditData.filter(Boolean).length &&
            !debitData.filter(Boolean).length &&
            ShowNoDataCard("transacations", "in last 30 days")}
        </div>
      </Box>
    </Card>
  );
};

export default TransactionChart;
