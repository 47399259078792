import { logOut } from "../../redux/auth/actions";
import { useEffect, useState } from "react";
import {
  invitee,
  acceptInvitation,
  pending,
  invitation,
  invitationForDiffAccount,
  invitationRedeem,
  invitationRedeemForDiffAccount,
  invitationExpired,
  partnerInvitationForDiffAccount,
  partnerInvitationExpired,
} from "../messages";
import { useLocation } from "react-router-dom";
import Invite from "./Invite";
import { useSelector, useDispatch } from "react-redux";
import { callAPIInterface } from "../constants";
import PartnerInvite from "../Partners/PartnerInvite";

const JoinInvite = ({ setLoading }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const [invited, setInvited] = useState(false);
  const [inviteForDiffAccountFlag, setInviteForDiffAccountFlag] =
    useState(false);
  const [expired, setExpired] = useState(false);
  const [inviteRedeem, setInviteRedeem] = useState(false);
  const [inviteRedeemForDiffAccount, setInviteRedeemForDiffAccount] =
    useState(false);
  const [status, setStatus] = useState("");
  const [accountName, setAccountName] = useState("");
  const [invitedByEmail, setInvitedByEmail] = useState("");
  const [email, setEmail] = useState("");
  const [accept, setAccept] = useState(false);
  const [inviteId, setInviteId] = useState();
  const [partnerInvite, setPartnerInvite] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const removeStorage = () => {
    localStorage.removeItem(invitee);
  };

  const handleClose = () => {
    removeStorage();
    invited && setInvited(false);
    inviteForDiffAccountFlag && setInviteForDiffAccountFlag(false);
    expired && setExpired(false);
    inviteRedeem && setInviteRedeem(false);
    inviteRedeemForDiffAccount && setInviteRedeemForDiffAccount(false);
    accept && setAccept(false);
  };

  const storageManage = () => {
    let invitee_details = JSON.parse(localStorage.getItem(invitee));
    if (invitee_details) {
      const {
        inviteeStatus,
        inviteeEmail,
        inviteeAccountName,
        invitedByEmailId,
        inviteeId,
      } = invitee_details;
      if (
        inviteeStatus &&
        inviteeEmail &&
        inviteeAccountName &&
        invitedByEmailId &&
        inviteeId
      ) {
        setEmail(inviteeEmail);
        setAccountName(inviteeAccountName);
        setInvitedByEmail(invitedByEmailId);
        setInviteId(inviteeId);
        if (inviteeStatus == pending) {
          if (inviteeEmail == user.email) {
            setStatus(invitation);
            setInvited(true);
          } else {
            setStatus(invitationForDiffAccount);
            setInviteForDiffAccountFlag(true);
          }
        } else if (inviteeStatus == acceptInvitation) {
          setStatus(acceptInvitation);
          setAccept(true);
        } else {
          if (inviteeEmail == user.email) {
            setStatus(invitationRedeem);
            setInviteRedeem(true);
          } else {
            setStatus(invitationRedeemForDiffAccount);
            setInviteRedeemForDiffAccount(true);
          }
        }
      }
    }
  };

  const partnerFlags = [
    partnerInvitationForDiffAccount,
    partnerInvitationExpired,
  ];

  const flagManagement = () => {
    if (location.data) {
      const { email, account_name, invited_by_email, flag, invitee_id } =
        location.data;
      setInviteId(invitee_id);
      setEmail(email);
      setAccountName(account_name);
      setInvitedByEmail(invited_by_email);
      setStatus(flag);
      flag === invitation && setInvited(true);
      flag === invitationForDiffAccount && setInviteForDiffAccountFlag(true);
      flag === invitationExpired && setExpired(true);
      flag === invitationRedeem && setInviteRedeem(true);
      flag === invitationRedeemForDiffAccount &&
        setInviteRedeemForDiffAccount(true);
      partnerFlags.includes(flag) && setPartnerInvite(true);
    }
  };

  useEffect(() => {
    flagManagement();
  }, [location.data]);

  useEffect(() => {
    user && storageManage();
  }, [user]);

  const handleButtonClick = async () => {
    removeStorage();
    if (inviteForDiffAccountFlag) {
      setLoading(true);
      setInviteForDiffAccountFlag(false);
      dispatch(logOut())
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
    if (invited) {
      setInvited(false);
      setLoading(true);
      await callAPIInterface(
        "POST",
        "/invite-users/" + inviteId + "/accept",
        ""
      ).then(() => {
        setLoading(false);
        window.location.reload();
        localStorage.setItem(
          invitee,
          JSON.stringify({
            inviteeStatus: acceptInvitation,
            inviteeEmail: email,
            inviteeAccountName: accountName,
            invitedByEmailId: invitedByEmail,
            inviteeId: inviteId,
          })
        );
      });
    }
  };

  const isOpen =
    invited ||
    inviteForDiffAccountFlag ||
    expired ||
    inviteRedeem ||
    inviteRedeemForDiffAccount;

  const handleClosePartnerInvite = () => {
    sessionStorage.removeItem("affiliate-partner-invitee");
    setPartnerInvite(false);
  };

  const handleButtonClickPartnerInvite = () => {
    if (location?.data?.flag === partnerInvitationForDiffAccount) {
      setLoading(true);
      setPartnerInvite(false);
      dispatch(logOut())
        .then(() => setLoading(false))
        .catch(() => setLoading(false));
    }
  };

  const commonModalProps = {
    accountName,
    invitedByEmail,
    email,
  };

  return (
    <>
      <Invite
        open={isOpen}
        handleClose={handleClose}
        status={status}
        handleButtonClick={handleButtonClick}
        {...commonModalProps}
      />
      <Invite
        open={accept}
        status={status}
        handleClose={handleClose}
        accountName={accountName}
        accountCount={user?.accounts_details?.length}
      />
      <PartnerInvite
        open={partnerInvite}
        handleClosePartnerInvite={handleClosePartnerInvite}
        handleButtonClick={handleButtonClickPartnerInvite}
        flag={location?.data?.flag}
        {...commonModalProps}
      />
    </>
  );
};
export default JoinInvite;
