import { Box } from "@mui/material";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import React, { useEffect, useState } from "react";
import { branding, connectedExtensions } from "../messages";
import ConnectBranding from "./Branding/ConnectBranding";
import ConnectedExtensions from "./ConnectedExtensions/ConnectedExtensions";

import { find } from "lodash";
import history from "@speed/common/src/components/history";
import { setSettingFormChange } from "../../redux/common/actions";
import { useDispatch } from "react-redux";

const Connect = () => {
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState("1");

  const tabData = [
    {
      label: branding,
      value: "1",
      tabContent: <ConnectBranding />,
      route: "/settings/connect-branding",
    },
    {
      label: connectedExtensions,
      value: "2",
      tabContent: <ConnectedExtensions />,
      route: "/settings/connected-extensions",
    },
  ];

  const handleChange = (_event, newValue) => {
    dispatch(setSettingFormChange(false));
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (history.location.pathname) {
      const currentRoute = find(tabData, {
        route: history.location.pathname,
      });
      if (currentRoute) {
        setSelectedTab(currentRoute.value);
      }
    }
  }, [history?.location.pathname]);

  return (
    <Box className="connect-wrapper">
      <CustomTab
        onChange={handleChange}
        tabData={tabData}
        selectedTab={selectedTab}
        setTabRoute={(routeUrl) => history.push(routeUrl)}
        sx={{ position: "relative", height: "100%" }}
      />
    </Box>
  );
};

export default Connect;
