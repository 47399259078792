import { Modal } from "./Modal/Modal";
import { Button } from "./Button/Button";
import { Text } from "./Text/Text";
import { Input } from "./Input/Input";
import { useFormik } from "formik";
import * as yup from "yup";
import { Countries } from "./country";
import { CustomAutoComplete } from "./AutoComplete/AutoComplete";
import { CustomLink } from "./Link/Link";
import { Box } from "@mui/material";
import { useState } from "react";
import { getCountryObj, isAlphaNumeric } from "./constants";
import {
  accountSettingSmall,
  accountTitle,
  alphaNumName,
  businessNameLabel,
  changeAccountMsg,
  companyPlaceholder,
  countryLabel,
  countryPlaceholder,
  createAccount,
  save,
} from "../messages";
import InputErrorMessage from "./InputErrorMessage";

const AddEditAccount = ({
  open,
  handleClose,
  isEdit,
  selectedAccount,
  handleAddAccount,
  handleUpdateAccount,
  callAPIInterface,
  generateToken,
  dispatch,
  setIsListLoading = null,
}) => {
  const [isLoading, setLoading] = useState(false);
  const validationSchema = yup.object({
    businessName: yup
      .string()
      .matches(isAlphaNumeric, alphaNumName)
      .required(""),
    country: yup.string().required(),
  });

  const updateAccountStates = (resetForm) => {
    setLoading(false);
    handleClose();
    resetForm();
  };

  const handleSubmit = async (accountValues, { resetForm }) => {
    setLoading(true);
    let postObject = {
      name: accountValues.businessName,
    };
    if (!isEdit) {
      postObject.country = accountValues.country;
    }
    isEdit
      ? await callAPIInterface("PUT", "/account", JSON.stringify(postObject))
          .then((accountData) => {
            handleUpdateAccount(accountData);
          })
          .catch(() => {
            updateAccountStates(resetForm);
          })
      : await callAPIInterface("POST", "/account", JSON.stringify(postObject))
          .then((accountData) => {
            dispatch(generateToken()).then(() => {
              handleAddAccount(accountData);
            });
          })
          .catch(() => {
            updateAccountStates(resetForm);
          });

    updateAccountStates(resetForm);
    setIsListLoading?.(true);
  };

  const formik = useFormik({
    initialValues: {
      businessName: isEdit && selectedAccount ? selectedAccount.name : "",
      country: isEdit && selectedAccount ? selectedAccount.country : null,
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handleCloseModal = () => {
    handleClose();
    formik.resetForm();
  };

  const {
    values,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setTouched,
    isValid,
    dirty,
    isSubmitting,
  } = formik;

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const accountSetting = (
    <CustomLink
      size="small"
      href={process.env.REACT_APP_URL + "/settings/account-info"}
      target="_blank"
    >
      {accountSettingSmall}
    </CustomLink>
  );

  let addAccountContent = (
    <Box component="form">
      {!isEdit && (
        <Text
          className="default-text"
          font="regular"
          size={14}
          variant="subtitle1"
        >
          {changeAccountMsg(accountSetting)}
        </Text>
      )}
      <Input
        type="text"
        name="businessname"
        inputProps={{ maxLength: 250 }}
        customClass={`${!isEdit ? "margin-top25" : ""}`}
        showLabel
        label={businessNameLabel}
        fullWidth
        placeholder={companyPlaceholder}
        onBlur={() => setTouched({ ...touched, businessName: true })}
        {...getFieldProps("businessName")}
      />
      <InputErrorMessage
        {...defaultErrorMessageProps}
        inputName="businessName"
      />

      <CustomAutoComplete
        name="country"
        value={values.country}
        customClass="create-account-country margin-top30"
        placeholder={countryPlaceholder}
        showLabel
        label={countryLabel}
        options={Countries}
        getOptionLabel={(optionValues) => {
          let country = getCountryObj(
            "short_name",
            typeof optionValues === "object"
              ? optionValues.short_name
              : optionValues
          );
          return country ? country.short_name : "";
        }}
        onChange={(_e, countyValue) => {
          setFieldValue(
            "country",
            countyValue && typeof countyValue === "object"
              ? countyValue.short_name
              : countyValue
          );
        }}
      />
    </Box>
  );

  return (
    <Modal
      open={open}
      body={addAccountContent}
      footer={
        <Button
          loading={isLoading}
          type="submit"
          label={isEdit ? save : createAccount}
          onClick={formik.handleSubmit}
          disabled={!(isValid && dirty) || isSubmitting}
        />
      }
      handleClose={() => handleCloseModal()}
      title={accountTitle(isEdit)}
    />
  );
};
export default AddEditAccount;
