import Text from "@speed/common/src/components/Text/Text";
import React from "react";
import { Box } from "@mui/material";

const SetupCommonHeader = ({ header, subHeader }) => {
  const textStyle = { lineHeight: "140%" };

  return (
    <Box>
      <Text
        size={28}
        variant="h1"
        font="semibold"
        className="default-text"
        sx={{ ...textStyle }}
      >
        {header}
      </Text>
      <Text
        size={18}
        variant="subtitle1"
        font="regular"
        className="grey-text"
        sx={{ ...textStyle, mt: "10px" }}
      >
        {subHeader}
      </Text>
    </Box>
  );
};

export default SetupCommonHeader;
