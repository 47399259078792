import React, { useState, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CustomTable from "@speed/common/src/components/Table/Table";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Button from "@speed/common/src/components/Button/Button";
import { callAPIInterface, noOfRecords, timeAgo } from "../constants";
import { sessionService } from "redux-react-session";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import { logOut } from "../../redux/auth/actions";
import { signoutAllText, signOutAllSessionTitle, yes, no } from "../messages";
import { loadingMsg } from "@speed/common/src/components/messages";
import FiberManualRecordSharpIcon from "@mui/icons-material/FiberManualRecordSharp";
import { Box, Grid } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import AdvanceFilter from "../AdvanceFilter";
import { isLoginSessionFilterEnable } from "../../redux/common/actions";
import filterableData from "../AdvanceFilter/filter";
import { userAgentBox } from "@speed/common/src/components/constants";

const LoginSessions = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [open, setOpen] = useState(false);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const loginSessionFilter = useSelector(
    (state) => state.common.loginSessionFilter
  );
  const [queryParams, setQueryParams] = useState("?limit=" + noOfRecords);
  const [session, setSession] = useState(null);
  const [anchor, setAnchor] = useState(false);
  const columns = [
    { title: "Device", width: "400px" },
    { title: "Location", width: "250px" },
    { title: "IP Address", width: "280px" },
    { title: "Time", width: "220px" },
    { title: "Status", width: "190px" },
  ];

  useEffect(() => {
    setTableRowSkeleton(true);
    loadInitialData();
  }, []);

  const loadLoginSessions = async () => {
    const res = await callAPIInterface("GET", "/sessions" + queryParams);
    if (res) {
      setTableRowSkeleton(false);
      if (!res.has_more) {
        setHasMore(false);
      } else {
        setQueryParams(
          "?limit=" +
            noOfRecords +
            "&ending_before=" +
            res.data[res.data.length - 1].id
        );
      }
      setRows(rows.concat(res.data));
    }
  };

  useEffect(() => {
    if (session) {
      loadLoginSessions();
    }
  }, [session]);

  const loadInitialData = async () => {
    let newSession = await sessionService.loadSession();
    setSession(newSession);
  };

  const loadMore = useCallback(() => {
    setTableRowSkeleton(true);
    loadItems();
  }, [rows]);

  const loadItems = () => {
    loadLoginSessions();
  };

  const signOutSessions = async () => {
    setOpen(false);
    setLoading(isLoggedIn);
    await callAPIInterface("DELETE", "/sessions");
    dispatch(logOut()).catch(() => setLoading(false));
  };

  const content = (
    <Box>
      <Text
        className="default-text"
        align="center"
        size={16}
        font="regular"
        variant="subtitle1"
      >
        {signoutAllText}
      </Text>
    </Box>
  );

  const footer = (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      display="flex"
    >
      <Button
        style={{ width: "175px" }}
        label={yes}
        onClick={signOutSessions}
      />
      <Button
        style={{ width: "175px" }}
        variant="outlined"
        label={no}
        onClick={() => setOpen(false)}
      />
    </Grid>
  );

  let columnsData = columns.map((column, index) => (
    <TableCell key={index} sx={{ width: column.width }}>
      {" "}
      {column.title}{" "}
    </TableCell>
  ));

  let rowsData =
    rows &&
    rows.map((rowItem) => {
      return (
        <TableRow key={rowItem.id}>
          <TableCell>
            {userAgentBox(
              rowItem?.user_agent,
              session && rowItem.id == session.session
            )}
          </TableCell>
          <TableCell>
            {[rowItem.geo_city, rowItem.geo_country_code]
              .filter(Boolean)
              .join(", ")}
          </TableCell>
          <TableCell>{rowItem.ip_address}</TableCell>
          <TableCell>{timeAgo(rowItem.created, user?.timezone)}</TableCell>
          <TableCell>
            <Box className="flex-inline">
              <Box
                className={`icon-wrapper small-icon ${
                  rowItem.session_state == "ACTIVE" ? "active" : "expired"
                }`}
              >
                {" "}
                <FiberManualRecordSharpIcon />
              </Box>
              {rowItem.session_state == "ACTIVE" ? "Active" : "Expired"}
            </Box>
          </TableCell>
        </TableRow>
      );
    });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
  };
  const toggleDrawer = () => {
    setAnchor(false);
  };

  const filterParams = [
    filterableData.created_date,
    filterableData.status,
    filterableData.ip_address,
    filterableData.city,
    filterableData.country,
  ];
  return (
    <>
      <CustomDivider />
      <Box className="action-btn-wrapper mui-fixed" gap={2} display="flex">
        {/* {loginSessionFilter === true && (
          <Button
            icon="closeIcon"
            label={clearFilter}
            variant="outlined"
            color="error"
            onClick={() => dispatch(isLoginSessionFilterEnable(false))}
          />
        )}
        <Button
          icon="filterIcon"
          label={filter}
          variant="outlined"
          color="primary"
          onClick={() => setAnchor(true)}
        /> */}
        <Button
          icon="signoutIcon"
          label={signOutAllSessionTitle}
          variant="outlined"
          color="error"
          onClick={() => {
            setOpen(true);
          }}
        />
      </Box>
      <Box className="main-content">
        <BackdropLoader
          open={loading}
          alt="Loading..."
          text={loadingMsg}
          customClass="loading-in-auth"
        />
        <CustomTable {...tableProps} />
      </Box>
      <Modal
        maxWidth="xs"
        body={content}
        footer={footer}
        handleClose={() => {
          return;
        }}
        open={open}
        title={signOutAllSessionTitle}
        closeIcon={false}
      />
      <AdvanceFilter
        anchor={anchor}
        handleDrawer={toggleDrawer}
        data={filterParams}
        reduxValue={loginSessionFilter}
        reduxAction={isLoginSessionFilterEnable}
        headerTitle="Login session properties"
      />
    </>
  );
};
export default LoginSessions;
