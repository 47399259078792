import { Link, useParams } from "react-router-dom";
import { RouteBreadcrumbs } from "../constants";
import { Text } from "@speed/common/src/components/Text/Text";
import { Box } from "@mui/system";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import WebhookAttemptList from "./WebhookAttemptList";
import {
  generateBreadcrumbs,
  webHookStatus,
} from "@speed/common/src/components/constants";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import { webhookAttempts, webhookSubheader } from "../messages";
import { useLocation } from "react-router";
import _ from "lodash";

const WebhookAttempt = (props) => {
  const liveMode = useSelector((state) => state.auth.liveMode);
  const history = useSelector((state) => state.common.history);
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const [selectedTab, setSelectedTab] = useState("1");
  const search = useLocation().search;
  const apiVersion = new URLSearchParams(search).get("api_version");
  const urlParams = useParams();

  const { from, platformAccID } = { ...useLocation()?.state };

  useEffect(() => {
    platformAccID &&
      localStorage.setItem(
        "platformAccData",
        JSON.stringify({ platformAccID, from })
      );
  }, [platformAccID]);

  const platformAccData =
    localStorage.getItem("platformAccData") &&
    JSON.parse(localStorage.getItem("platformAccData"));

  const platformAccountID = platformAccID || platformAccData?.platformAccID;
  const fromType = from || platformAccData?.from;

  useEffect(() => {
    if (history) {
      let currentRoute = _.find(tabData, { route: history.location.pathname });
      if (currentRoute) {
        setSelectedTab(currentRoute.value);
      }
    }
  }, [history]);

  useEffect(() => {
    setSelectedTab(1);
  }, [liveMode]);

  const commonProps = {
    eventId: urlParams.id,
    version: apiVersion,
    from: fromType,
    platformAccID: platformAccountID,
  };
  const tabData = [
    {
      label: "All",
      value: 1,
      tabContent: <WebhookAttemptList {...commonProps} />,
      tabName: "all",
    },
    {
      label: webHookStatus.succeeded.label,
      value: 2,
      tabContent: (
        <WebhookAttemptList
          tab={[webHookStatus.succeeded.value]}
          {...commonProps}
        />
      ),
      tabName: webHookStatus.succeeded.value,
    },
    {
      label: webHookStatus.failed.label,
      value: 3,
      tabContent: (
        <WebhookAttemptList
          tab={[webHookStatus.failed.value]}
          {...commonProps}
        />
      ),
      tabName: webHookStatus.failed.value,
    },
  ];
  return (
    <Box className="section-wrapper">
      <Box className="setting-header-wrapper">
        <Breadcrumbs
          component={Link}
          breadcrumbData={breadcrumbs}
          sx={{ paddingBottom: "20px" }}
        />
        <Text variant="h6" font="semibold" size={20} withIcon="end">
          {webhookAttempts}
        </Text>
        <Text
          variant="body1"
          className="grey-text"
          font="regular"
          size={14}
          style={{ marginTop: "5px" }}
        >
          {webhookSubheader}
        </Text>
      </Box>
      <Box>
        <CustomTab
          onChange={(_event, newValue) => {
            setSelectedTab(newValue);
          }}
          tabData={tabData}
          selectedTab={selectedTab}
          sx={{
            minHeight: "unset",
            "& .MuiTabPanel-root": { minHeight: "unset", padding: 0 },
          }}
          setTabRoute={() => {
            history.push(
              `${history.location.pathname}?api_version=${apiVersion}`,
              {
                from,
                platformAccID,
              }
            );
          }}
        />
      </Box>
    </Box>
  );
};

export default WebhookAttempt;
