import React from "react";
import CommonWebPreview from "../../Common/CommonWebView";
import RenderConnectPreview from "@speed/common/src/components/Connect/RenderConnectPreview";
import { dummyConnectDomain } from "../../messages";

const ConnectWebViewDisplay = (props) => {
  const params = {
    ...props,
    contentClassName: "connect-web-content",
    visibleInConnectPage: false,
    deviceType: "web",
  };
  return (
    <CommonWebPreview
      marginTop="30px"
      marginBottom="200px"
      dummyDomain={dummyConnectDomain}
    >
      <RenderConnectPreview {...params} />
    </CommonWebPreview>
  );
};

export default ConnectWebViewDisplay;
