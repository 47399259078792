import { Box } from "@mui/material";
import React from "react";
import PriceComponent from "./PriceComponent";
import PricingModalSelectComponent from "./PricingModalSelectComponent";
import isEmpty from "lodash/isEmpty";

const PricingFormAccordion = ({ index, formik }) => {
  const { values, setFieldValue, errors } = formik;

  const handleChangeCurrency = (val, index) => {
    setFieldValue(`pricing[${index}].currency`, val);
  };

  return (
    <Box marginBottom="10px">
      <PricingModalSelectComponent
        value={values?.pricing[index]?.modal}
        name={`pricing[${index}].modal`}
        formik={formik}
      />
      <PriceComponent
        currencyName={`pricing[${index}].currency`}
        currencyValue={values?.pricing[index]?.currency}
        amountName={`pricing[${index}].amount`}
        amountValue={values?.pricing[index]?.amount}
        handleChangeCurrency={(val) => handleChangeCurrency(val, index)}
        formik={formik}
        error={!isEmpty(errors) && errors?.pricing[index]?.amount}
        className="product-price-currency"
      />
    </Box>
  );
};

export default PricingFormAccordion;
