import { Box } from "@mui/system";
import { MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Text from "@speed/common/src/components/Text/Text";
import {
  modules,
  notificationMsg,
  selectEmailTriggerFrequency,
  updateSuccessMsg,
} from "../../messages";
import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import { find } from "lodash";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import {
  setSettingFormCancel,
  setSettingFormChange,
  setSettingFormSubmit,
  setSettingFormValid,
  showToast,
} from "../../../redux/common/actions";
import {
  callAPIInterface,
  settingsNotificationEmailFrequency,
} from "../../constants";
import CustomSelect from "@speed/common/src/components/Select/Select";
import {
  capitalizeFirstChar,
  isSameArray,
} from "@speed/common/src/components/constants";

const EmailNotification = ({
  moduleName,
  moduleEventData,
  getUpdatedNotificationDetails,
  currentFormData,
  settingEventModuleTabs,
  formNotificationPreferences,
  setFormNotificationPreferences,
  setFormData,
  isChangedSettings,
}) => {
  const dispatch = useDispatch();
  const allowedMethodsForNotification = ["Email", "SMS"];
  const [formikEventDetails, setFormikEventDetails] = useState(currentFormData);
  const { liveMode } = useSelector((state) => state.auth);
  const disableUpdateNotifications = !liveMode;
  const { settingFormSubmit, settingFormCancel } = useSelector(
    (state) => state.common
  );

  const formik = useFormik({
    initialValues: {
      notificationDetails: moduleEventData,
    },
    enableReinitialize: true,
  });

  const { values, resetForm, setFieldValue, isValid, dirty, isSubmitting } =
    formik;

  const updatedSettings = dirty || isChangedSettings;
  const getUpdatedEventObjectArray = () => {
    let updatedListOfSettings = [],
      isSameModuleArray = isSameArray(
        formikEventDetails,
        formNotificationPreferences
      );

    if (!isSameModuleArray) {
      formNotificationPreferences?.forEach((initialbModules) => {
        initialbModules?.sub_module?.forEach((initialSubModules) => {
          initialSubModules.events?.forEach((initialEventObj) => {
            formikEventDetails?.forEach((currentModules) => {
              currentModules?.sub_module?.forEach((currentSubModules) => {
                currentSubModules?.events?.forEach((currentEventObj) => {
                  const isSameCurrentValue = isSameArray(
                    initialEventObj,
                    currentEventObj
                  );
                  if (
                    currentEventObj.id === initialEventObj.id &&
                    !isSameCurrentValue
                  ) {
                    const idStringType = currentEventObj.notification_event_id
                      ? "id"
                      : "notification_event_id";
                    updatedListOfSettings.push({
                      [idStringType]: currentEventObj.id,
                      email_value: currentEventObj.email_value,
                      sms_value: currentEventObj.sms_value,
                      ...(currentEventObj.trigger_frequency && {
                        trigger_frequency: capitalizeFirstChar(
                          currentEventObj.trigger_frequency
                        ),
                      }),
                    });
                  }
                });
              });
            });
          });
        });
      });
    }
    return updatedListOfSettings;
  };

  const settingNotificationModuleFormDetails = (allModuleEventDetails) => {
    const updatedModuleDetails = allModuleEventDetails?.filter(
      (moduleData) => moduleData.module === moduleName
    )?.[0];
    setFieldValue("notificationDetails", updatedModuleDetails);
  };

  const updateFormEventDetails = (formEventDetails, newEventDetails) => {
    const updatedEventDetails = getUpdatedNotificationDetails?.(
      formEventDetails,
      newEventDetails
    );
    setFormData(updatedEventDetails);
    setFormikEventDetails(updatedEventDetails);
    settingNotificationModuleFormDetails(updatedEventDetails);
    return updatedEventDetails;
  };

  useEffect(() => {
    dispatch(
      setSettingFormValid(!(isValid && updatedSettings) || isSubmitting)
    );
  }, [isValid, dirty, isSubmitting]);

  useEffect(() => {
    dispatch(
      setSettingFormChange(!disableUpdateNotifications && updatedSettings)
    );
  }, [values, isChangedSettings, disableUpdateNotifications]);

  useEffect(() => {
    if (settingFormCancel) {
      resetForm();
      setFormData(formNotificationPreferences);
      settingEventModuleTabs(formNotificationPreferences);
      setFormikEventDetails(formNotificationPreferences);
      dispatch(setSettingFormCancel(false));
    }
  }, [settingFormCancel]);

  useEffect(() => {
    if (isValid && updatedSettings && settingFormSubmit) {
      const uniqueUpdatedObjArray = getUpdatedEventObjectArray();
      callAPIInterface("POST", "/user/notification/preference", {
        user_notification_preferences: uniqueUpdatedObjArray,
      })
        .then((response) => {
          if (response) {
            const updatedEventDetails = updateFormEventDetails(
              formikEventDetails,
              response.user_notification_preferences
            );

            settingEventModuleTabs(updatedEventDetails);
            setFormData(updatedEventDetails);
            setFormNotificationPreferences(updatedEventDetails);
            dispatch(setSettingFormSubmit(false));
            dispatch(
              showToast({
                isToastOpen: true,
                toastMessage: updateSuccessMsg(
                  "notification preferences",
                  true
                ),
                toastVariant: "success",
              })
            );
          }
        })
        .catch(() => {
          dispatch(setSettingFormSubmit(false));
        });
    }
  }, [settingFormSubmit]);

  const getFieldNameForSelection = (isForSMS, toCheckForCheckboxDisable) => {
    const forDisable = isForSMS ? "sms_value" : "email_value";
    const forEnable = isForSMS ? "is_sms_enabled" : "is_email_enabled";
    return toCheckForCheckboxDisable ? forEnable : forDisable;
  };

  const getFilteredUnSelectedEventObj = (eventCheckBoxSelectedArray) => {
    const lengthOfUnSelectedCheckbox = eventCheckBoxSelectedArray?.filter(
      (value) => value === false
    )?.length;
    return {
      lengthOfUnSelectedCheckbox,
      noOfTotalEvents: eventCheckBoxSelectedArray?.length,
    };
  };

  const getSubModulesCheckboxSelectionObj = (
    isForSMS = false,
    toCheckForCheckboxDisable = false
  ) => {
    let eventCheckBoxSelectedArray = [];
    const keyNameForSelection = getFieldNameForSelection(
      isForSMS,
      toCheckForCheckboxDisable
    );
    values.notificationDetails?.sub_module?.forEach((subModule) => {
      subModule.events?.forEach((event) => {
        if (
          toCheckForCheckboxDisable ||
          (!toCheckForCheckboxDisable &&
            event[getFieldNameForSelection(isForSMS, true)])
        )
          eventCheckBoxSelectedArray.push(event[keyNameForSelection]);
      });
    });
    if (eventCheckBoxSelectedArray.length) {
      return getFilteredUnSelectedEventObj(eventCheckBoxSelectedArray);
    }
  };

  const getSingleEventCheckboxSelectionObj = (
    subModulesList,
    isForSMS = false,
    toCheckForCheckboxDisable = false
  ) => {
    let eventCheckBoxSelectedArray = [];
    const keyNameForSelection = getFieldNameForSelection(
      isForSMS,
      toCheckForCheckboxDisable
    );
    subModulesList?.events?.forEach((event) => {
      if (
        toCheckForCheckboxDisable ||
        (!toCheckForCheckboxDisable &&
          event[getFieldNameForSelection(isForSMS, true)])
      )
        eventCheckBoxSelectedArray.push(event[keyNameForSelection]);
    });
    if (eventCheckBoxSelectedArray.length) {
      return getFilteredUnSelectedEventObj(eventCheckBoxSelectedArray);
    }
  };

  const handleClickAllCheckboxes = (
    isChecked,
    isForSMS,
    eventModuleName,
    isForSingleModuleCheck = false
  ) => {
    const keyNameForDisabledEvents = getFieldNameForSelection(isForSMS, true);
    const updatedModule = values.notificationDetails?.sub_module?.map(
      (subModuleDetails) => {
        if (
          !isForSingleModuleCheck ||
          (isForSingleModuleCheck && subModuleDetails.name === eventModuleName)
        ) {
          const updatedSubModule = subModuleDetails.events?.map(
            (defaultEventDetails) => {
              const updatedEventObj = defaultEventDetails[
                keyNameForDisabledEvents
              ]
                ? {
                    ...defaultEventDetails,
                    ...(isForSMS
                      ? {
                          sms_value: !isChecked,
                        }
                      : {
                          email_value: !isChecked,
                        }),
                  }
                : defaultEventDetails;
              return updatedEventObj;
            }
          );
          return { ...subModuleDetails, events: updatedSubModule };
        }
        return subModuleDetails;
      }
    );

    const updatedModuleDetails = {
      module: moduleName,
      sub_module: updatedModule,
    };
    setFieldValue("notificationDetails", updatedModuleDetails);

    const updatedEventDetails = formikEventDetails.map((event) => {
      if (event.module === moduleName) return updatedModuleDetails;
      return event;
    });
    setFormData(updatedEventDetails);
    setFormikEventDetails(updatedEventDetails);
  };

  const renderHeaderModulesSection = () =>
    allowedMethodsForNotification.map((methodName) => {
      const isForSms = methodName === "SMS";
      const {
        lengthOfUnSelectedCheckbox: lengthOfDisabledCheckbox,
        noOfTotalEvents: lengthOfTotalEvents,
      } = getSubModulesCheckboxSelectionObj(isForSms, true) || {};
      const { lengthOfUnSelectedCheckbox, noOfTotalEvents } =
        getSubModulesCheckboxSelectionObj(isForSms) || {};

      const showAllSelectionCheckbox =
        lengthOfDisabledCheckbox !== lengthOfTotalEvents;
      const isChecked = lengthOfUnSelectedCheckbox === 0;
      const isIndeterminateCheckbox =
        lengthOfUnSelectedCheckbox < noOfTotalEvents && !isChecked;

      return (
        <Box className="method-checkbox-section" key={methodName}>
          {showAllSelectionCheckbox && (
            <>
              <Text size={16}>{methodName}</Text>
              <CustomCheckbox
                onChange={() => {
                  handleClickAllCheckboxes(isChecked, methodName === "SMS");
                }}
                indeterminate={isIndeterminateCheckbox}
                checked={isChecked}
                disabled={disableUpdateNotifications}
                name={methodName}
                label=""
              />
            </>
          )}
        </Box>
      );
    });

  const renderSubModulesSection = () => (
    <Box className="sub-module-box">
      {values.notificationDetails?.sub_module?.map((subModule) => {
        return (
          <Box className="module-main-container-box" key={subModule.name}>
            <Box className="sub-module-header">
              <Box className="module-name">
                <Text size={16} className="default-text">
                  {subModule.name}
                </Text>
              </Box>
              {allowedMethodsForNotification.map((methodName) => {
                const isForSms = methodName === "SMS";
                const {
                  lengthOfUnSelectedCheckbox: lengthOfDisabledCheckbox,
                  noOfTotalEvents: lengthOfTotalEvents,
                } =
                  getSingleEventCheckboxSelectionObj(
                    subModule,
                    isForSms,
                    true
                  ) || {};
                const showAllSelectionCheckbox =
                  lengthOfDisabledCheckbox !== lengthOfTotalEvents;
                const checkBoxObj =
                  getSingleEventCheckboxSelectionObj(subModule, isForSms) || {};
                const isCheckedBox =
                  checkBoxObj.lengthOfUnSelectedCheckbox === 0;
                const isIndeterminateCheckbox =
                  checkBoxObj.lengthOfUnSelectedCheckbox <
                    checkBoxObj.noOfTotalEvents && !isCheckedBox;

                return (
                  <Box
                    key={methodName}
                    className="method-checkbox-section"
                    align="center"
                  >
                    {showAllSelectionCheckbox && (
                      <CustomCheckbox
                        onChange={() =>
                          handleClickAllCheckboxes(
                            isCheckedBox,
                            methodName === "SMS",
                            subModule.name,
                            true
                          )
                        }
                        indeterminate={isIndeterminateCheckbox}
                        checked={isCheckedBox}
                        disabled={disableUpdateNotifications}
                        name={subModule.name}
                        label=""
                      />
                    )}
                  </Box>
                );
              })}
            </Box>
            {subModule.events?.map((eventData) => {
              return (
                <Box className="sub-module-event-box" key={eventData.id}>
                  <Box
                    className="sub-module-event-text module-name"
                    align="center"
                  >
                    <Text size={16} variant="h4" font="regular">
                      {eventData.event}
                    </Text>
                    {eventData.trigger_frequency && (
                      <Box className="email-trigger-frequency-box">
                        <CustomSelect
                          disabled={disableUpdateNotifications}
                          name="email-trigger-frequency"
                          displayEmpty
                          customClass="trigger-frequency-select"
                          value={capitalizeFirstChar(
                            eventData.trigger_frequency
                          )}
                          placeholder={selectEmailTriggerFrequency}
                          MenuProps={{
                            id: "settings-notification-menu",
                          }}
                          onChange={(e) => {
                            const newEventArray = [
                              {
                                ...eventData,
                                trigger_frequency: e.target.value,
                              },
                            ];

                            updateFormEventDetails(
                              formikEventDetails,
                              newEventArray
                            );
                          }}
                          renderValue={(value) => {
                            if (value) {
                              return find(settingsNotificationEmailFrequency, {
                                value: value,
                              })?.value;
                            } else {
                              return <Box> {selectEmailTriggerFrequency} </Box>;
                            }
                          }}
                        >
                          {settingsNotificationEmailFrequency.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.value}
                            </MenuItem>
                          ))}
                        </CustomSelect>
                      </Box>
                    )}
                  </Box>
                  {allowedMethodsForNotification.map((methodName) => {
                    const isForSms = methodName === "SMS";
                    const eventTypeObj = {
                      enable_Key: isForSms
                        ? "is_sms_enabled"
                        : "is_email_enabled",
                      value_key: isForSms ? "sms_value" : "email_value",
                    };

                    return (
                      <Box
                        key={methodName}
                        className="method-checkbox-section"
                        align="center"
                      >
                        {eventData[eventTypeObj.enable_Key] && (
                          <CustomCheckbox
                            onChange={() => {
                              const newEventArray = [
                                {
                                  ...eventData,
                                  [eventTypeObj.value_key]:
                                    !eventData[eventTypeObj.value_key],
                                },
                              ];
                              updateFormEventDetails(
                                formikEventDetails,
                                newEventArray
                              );
                            }}
                            checked={eventData[eventTypeObj.value_key]}
                            disabled={disableUpdateNotifications}
                            name={eventData.event}
                            label=""
                          />
                        )}
                      </Box>
                    );
                  })}
                </Box>
              );
            })}
          </Box>
        );
      })}
    </Box>
  );

  return (
    <Box className="main-content settings-notification-wrapper">
      {!liveMode && (
        <Box className="notification-msg">
          <Text variant="h6" font="regular" size={14}>
            {notificationMsg}
          </Text>
        </Box>
      )}
      <Box className="module-selection-wrapper">
        <Box className="module-name-text">
          <Text variant="h6" font="semibold">
            {moduleName}
          </Text>
        </Box>
        <Box className="module-checkbox-section">
          <Box className="module-header-box">
            <Box className="module-name header-text">
              <Text size={20} className="default-text">
                {modules}
              </Text>
            </Box>
            {renderHeaderModulesSection()}
          </Box>
          {renderSubModulesSection()}
        </Box>
      </Box>
    </Box>
  );
};

export default EmailNotification;
