import React, { useState } from "react";
import { Box, ListItem, ListItemButton } from "@mui/material";
import {
  automaticPayout,
  edit,
  lightning,
  lnAddressWallet,
  minimumBalancePayout,
  minimumPayout,
  onchain,
  onchainAddressWallet,
} from "../../messages";
import Text from "@speed/common/src/components/Text/Text";
import {
  autoPayoutStatus,
  convertExponentialToDecimal,
} from "@speed/common/src/components/constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import { BTC } from "@speed/common/src/components/messages";
import CommonCardWithSwitch from "../../Common/CommonCardWithSwitch";
import { payoutVerticalLine } from "../../constants";

const AlreadySetupPayoutComponent = ({
  scheduledPayoutData,
  isPayoutCreating,
  setIsDeactivating,
  setPayoutScheduleId,
  setSelectedPayout,
  formik,
  setIsEditing,
  setOpenAutoPayoutModal,
}) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const { pschUpdateFe } = useFlags();

  const { setFieldValue, values } = formik;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const walletTypeOnchain = scheduledPayoutData?.wallet?.type === onchain;

  const showMinPayoutBalance = () => {
    return (
      <>
        {/* <Text
          className="grey-text"
          size={14}
          font="regular"
          variant="subtitle1"
          style={{ marginRight: "30px" }}
        >
          {`${fees} : `}
          <span style={{ color: "#667085" }}>
            {scheduledPayoutData?.fees_preference.charAt(0).toUpperCase() +
              scheduledPayoutData?.fees_preference.slice(1)}
          </span>
        </Text>

        {payoutVerticalLine()} */}

        <Text
          className="grey-text"
          size={14}
          font="regular"
          variant="subtitle1"
          style={{ marginRight: "30px" }}
        >
          {minimumPayout}
          <span style={{ color: "#667085" }}>
            {convertExponentialToDecimal(
              +(scheduledPayoutData?.min_payout_amount / 100000000)
            )}{" "}
            {BTC}
          </span>
        </Text>
        {payoutVerticalLine()}
      </>
    );
  };

  const bottomLineComponent = () =>
    scheduledPayoutData?.id && (
      <Box display="flex" flexDirection="row" ml="30px" mb="14px">
        {showMinPayoutBalance()}
        <Text
          className="grey-text"
          size={14}
          font="regular"
          variant="subtitle1"
          style={{ marginLeft: "30px" }}
        >
          {minimumBalancePayout}
          <span style={{ color: "#667085" }}>
            {convertExponentialToDecimal(
              +(scheduledPayoutData?.min_balance / 100000000)
            )}{" "}
            {BTC}
          </span>
        </Text>
      </Box>
    );

  const getNameAccToWallet = () => {
    let walletName = "";
    if (scheduledPayoutData?.wallet?.type === lightning) {
      walletName = scheduledPayoutData?.wallet?.address;
    } else if (walletTypeOnchain) {
      walletName = scheduledPayoutData?.wallet?.name;
    }
    return walletName;
  };

  const getWalletTypeName = () => {
    return walletTypeOnchain ? onchainAddressWallet : lnAddressWallet;
  };

  const customPopperContent = (
    <ListItem disablePadding>
      <ListItemButton
        onClick={() => {
          setPayoutScheduleId(scheduledPayoutData?.id);
          setSelectedPayout(values.payout_type);
          setIsEditing(true);
          setOpenAutoPayoutModal?.(true);
        }}
      >
        <Text
          size={14}
          className="grey-text pointer-cursor list-item"
          variant="subtitle1"
        >
          {edit}
        </Text>
      </ListItemButton>
    </ListItem>
  );

  const handleSwitchOnChange = (_e, checked) => {
    setIsDeactivating(true);
    setPayoutScheduleId(scheduledPayoutData?.id);
    setFieldValue("auto_payout_enabled", checked ? "active" : "deactivated");
  };

  const isActive = scheduledPayoutData?.status === "active";

  return (
    <CommonCardWithSwitch
      anchorEl={anchorEl}
      loader={isPayoutCreating}
      bottomComponent={bottomLineComponent}
      customPopperContent={customPopperContent}
      data={scheduledPayoutData}
      iconClick={handleClick}
      isMoreIconVisible={isActive && !isPayoutCreating}
      setAnchorEl={setAnchorEl}
      switchChecked={isActive}
      switchDisabled={
        scheduledPayoutData?.status === "on_hold" || isPayoutCreating
      }
      switchOnChange={handleSwitchOnChange}
      title={automaticPayout}
      walletName={getNameAccToWallet()}
      walletType={getWalletTypeName()}
      isLdFlagRequired={true}
      switchLDFlagName={pschUpdateFe}
      tagProps={{
        text: autoPayoutStatus[scheduledPayoutData?.status]?.label,
        variant: autoPayoutStatus[scheduledPayoutData?.status]?.variant,
        deleteIcon: autoPayoutStatus[scheduledPayoutData?.status]?.icon,
      }}
    />
  );
};

export default AlreadySetupPayoutComponent;
