import React from "react";
import "../../assets/styles/payment-links.scss";
import { useFlags } from "launchdarkly-react-client-sdk";
import ModuleEventList from "../ModuleEventList";
import { useParams } from "react-router";

const PaymentAddressDetailsEvents = (props) => {
  const urlParams = useParams();
  const { paEventFe } = useFlags();
  return (
    <ModuleEventList ldFlag={paEventFe} urlParams={urlParams} {...props} />
  );
};

export default PaymentAddressDetailsEvents;
