import WarningIcon from "@mui/icons-material/Warning";
import { Grid, TableCell, TableRow } from "@mui/material";
import { Box } from "@mui/system";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import Text from "@speed/common/src/components/Text/Text";
import {
  clipboardElement,
  generateBreadcrumbs,
  getCurrencyObj,
  redirectWhenEntityNotFound,
  showAmount,
} from "@speed/common/src/components/constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import "../../assets/styles/payments.scss";
import { events } from "../Events/Events";
import DetailPageWithdrawSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import {
  RouteBreadcrumbs,
  callAPIInterface,
  dateTimeFormatInApp,
  displayExplorerLink,
  getWithdrawPaymentMethod,
  renderWithdrawStatus,
} from "../constants";
import {
  btc,
  dateCreated,
  dateModified,
  entityNotFoundMessage,
  events as eventsText,
  explorerLink,
  method,
  networkRoutingFee,
  note,
  sats,
  status,
  totalAmount,
  withdrawDetails,
  withdrawRequest,
} from "../messages";
import PaymentsSkeleton from "@speed/common/src/components/Skeleton/PaymentsSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";
import { KeyValueGrid } from "@speed/common/src/components/KeyValueGrid";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { promoCode, speedFees } from "@speed/common/src/messages";
import { amount } from "@speed/common/src/components/messages";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";

const WithdrawDetail = (props) => {
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const liveMode = useSelector((state) => state.auth.liveMode);
  const history = useSelector((state) => state.common.history);
  const { wiFetchwithdraw } = useFlags();
  const [data, setData] = useState(null);
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [showSkeleton, setShowSkeleton] = useState(false);
  const [eventSkeleton, setEventSkeleton] = useState(true);
  const [eventsList, setEventsList] = useState([]);

  const { wiEventFe } = useFlags();

  const urlParams = useParams();

  const getWithdrawsDetail = async () => {
    setShowSkeleton(true);
    await callAPIInterface(
      "GET",
      `/withdraws/${urlParams.id}?currency_denomination=higher`
    )
      .then((res) => {
        if (res) {
          setData(res);
        }
        setShowSkeleton(false);
      })
      .catch((err) => {
        setShowSkeleton(false);
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const getEventsList = async () => {
    setEventSkeleton(true);
    const filterEvents = {
      ids: [urlParams?.id],
    };
    await callAPIInterface(
      "POST",
      "/events/filters",
      JSON.stringify(filterEvents)
    )
      .then((res) => {
        if (res) {
          setEventSkeleton(false);
          setEventsList(res?.data);
        }
      })
      .catch(() => {
        setEventSkeleton(false);
      });
  };

  useEffect(() => {
    setData(null);
    getWithdrawsDetail();
    setIsShowNotFound(false);
    wiEventFe && getEventsList();
  }, [liveMode, urlParams?.id, wiEventFe]);

  const renderWithdrawDetail = () => {
    const {
      target_currency,
      target_amount,
      est_fees,
      speed_fee,
      withdraw_method,
      statement_descriptor,
      on_chain_tx_explorer_link,
      promo_code,
    } = { ...data };
    const targetCurrency = target_currency === sats ? btc : target_currency;
    const speedFeeAmt = speed_fee?.amount || 0;
    const gridProps = {
      keyNameCellWidth: 5,
      valueCellWidth: 7,
    };

    const withdrawAmount = showAmount({
      amount: target_amount,
      currency: target_currency,
      targetedCurrency: targetCurrency,
      appendCurrency: true,
    });

    const networkFeeAmount = showAmount({
      amount: est_fees,
      currency: target_currency,
      targetedCurrency: targetCurrency,
      appendCurrency: true,
    });

    const speedFeeAmount = showAmount({
      amount: speedFeeAmt,
      currency: target_currency,
      targetedCurrency: targetCurrency,
      appendCurrency: true,
    });

    const totalWithdrawAmount = showAmount({
      amount: target_amount + est_fees + speedFeeAmt,
      currency: target_currency,
      targetedCurrency: targetCurrency,
      appendCurrency: true,
    });

    return (
      <Box className="details-content">
        <Box className="details-content" marginTop="60px">
          <Text size={20} className="default-text">
            {withdrawDetails}
          </Text>
        </Box>
        <CustomDivider sx={{ my: "12px" }} />
        <Box
          gap={3}
          display="flex"
          flexWrap="wrap"
          justifyContent="space-between"
          pr="16px"
        >
          <KeyValueGrid
            {...gridProps}
            keyName={status}
            value={renderWithdrawStatus(data?.status)}
          />
          <KeyValueGrid
            {...gridProps}
            keyName={dateCreated}
            value={
              <Text
                variant="h4"
                size={16}
                className="default-text"
                font="regular"
              >
                {dateTimeFormatInApp(data?.created)}
              </Text>
            }
          />
          <KeyValueGrid
            {...gridProps}
            keyName={dateModified}
            value={
              <Text
                variant="h4"
                size={16}
                className="default-text"
                font="regular"
              >
                {dateTimeFormatInApp(data?.modified)}
              </Text>
            }
          />
          <KeyValueGrid
            {...gridProps}
            keyName={withdrawRequest}
            value={
              <Text
                variant="h4"
                size={16}
                className="default-text"
                font="regular"
              >
                {clipboardElement(
                  data?.withdraw_request,
                  true,
                  "webhook-id-copy",
                  "",
                  true
                )}
              </Text>
            }
          />
          <KeyValueGrid
            {...gridProps}
            keyName={method}
            value={getWithdrawPaymentMethod(data)}
          />
          <KeyValueGrid
            {...gridProps}
            keyName={amount}
            value={withdrawAmount}
          />
          <KeyValueGrid
            {...gridProps}
            keyName={networkRoutingFee}
            value={networkFeeAmount}
          />
          <KeyValueGrid
            {...gridProps}
            keyName={speedFees}
            value={speedFeeAmount}
          />
          <KeyValueGrid
            {...gridProps}
            keyName={totalAmount}
            value={totalWithdrawAmount}
          />
          {on_chain_tx_explorer_link && (
            <KeyValueGrid
              {...gridProps}
              keyName={explorerLink}
              value={displayExplorerLink(
                on_chain_tx_explorer_link,
                withdraw_method
              )}
            />
          )}
          {statement_descriptor && (
            <KeyValueGrid
              {...gridProps}
              keyName={note}
              value={
                <TruncatedTextTooltip
                  textValue={statement_descriptor}
                  cellWidth="300px"
                />
              }
            />
          )}
          {promo_code && (
            <KeyValueGrid
              {...gridProps}
              keyName={promoCode}
              value={promo_code}
            />
          )}
        </Box>
      </Box>
    );
  };

  const eventRows = eventsList?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/events/${rowItem?.id}`)}
      >
        <TableCell>{events(rowItem)}</TableCell>
        <TableCell align="right" sx={{ color: "#848b9e" }}>
          {dateTimeFormatInApp(rowItem?.created)}
        </TableCell>
      </TableRow>
    );
  });

  const renderEventsSection = () =>
    wiEventFe && (
      <>
        {!eventSkeleton ? (
          <Box className="product-content">
            <HorizontalTable
              label={eventsText}
              isColumnShown={eventRows?.length > 0}
              rows={eventRows}
              rowsPerPage={3}
              handleViewAllClick={() => {
                history.push(`/withdraws/${urlParams?.id}/events`);
              }}
              isShowButton={eventRows?.length > 3}
              tableBodyClassName="border-none"
            />
          </Box>
        ) : (
          <DetailPageWithdrawSkeleton />
        )}
      </>
    );

  const headerContent = () => {
    const {
      currency,
      target_currency,
      target_amount_paid,
      target_amount,
      amount,
      status,
    } = data;

    const targetedCurrency = target_currency === sats ? btc : target_currency;
    return (
      <Box className="header-content">
        <Text className="btc-amount" variant="h1" size={28}>
          {showAmount({
            amount: status === "paid" ? target_amount_paid : target_amount,
            currency: target_currency,
            targetedCurrency,
            appendCurrency: true,
          })}
        </Text>
        <Box className="header-price-content">
          <Text
            className="grey-text"
            size={16}
            font="regular"
            variant="subtitle"
          >
            ≈ {getCurrencyObj(currency)?.symbol}
            {amount}
          </Text>
          <Box>
            {clipboardElement(data?.id, true, "payments-detail", "text", true)}
          </Box>
        </Box>
      </Box>
    );
  };

  const renderWithdrawSection = () =>
    !isShowNotFound ? (
      <Grid className="payments-detail-wrapper" container spacing={1}>
        <Box className="section-wrapper" sx={{ position: "relative" }}>
          <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
          {data ? (
            <>
              {headerContent()}
              {renderWithdrawDetail()}
            </>
          ) : (
            showSkeleton && <PaymentsSkeleton props={props} />
          )}
          {renderEventsSection()}
        </Box>
      </Grid>
    ) : (
      <Box className="not-found-wrapper">
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        <LinkNotFound
          icon={<WarningIcon className="warning-icon" />}
          title={entityNotFoundMessage("Withdraw")}
          subTitle={`Withdraw ${urlParams.id} is not found.`}
          btnText={`View ${(liveMode && "live") || "test"} mode withdraws`}
        />
      </Box>
    );
  return wiFetchwithdraw ? renderWithdrawSection() : <AccessDenied />;
};

export default WithdrawDetail;
