import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import { versionLabel, versionPlaceHolder, viewAll } from "../messages";
import history from "@speed/common/src/components/history";
import CustomSelect from "@speed/common/src/components/Select/Select";
import MenuItem from "@mui/material/MenuItem";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import AllEvents from "./AllEvents";
import { webHookStatus } from "@speed/common/src/components/constants";
import { find } from "lodash";

const CommonWebhookAttempt = ({
  attemptLabel,
  webhookEvent,
  setWebhookEvent,
  versions,
  selectedVersion,
  setSelectedVersion,
  urlParams,
  selectedTab,
  setSelectedTab,
  from,
  platformAccID = "",
  oneLineLabel,
}) => {
  const [requestEndpoint, setRequestEndpoint] = useState(false);
  const [accordionExpand, setAccordionExpand] = useState(false);
  const [openDropdownMenu, setOpenDropdownMenu] = useState(false);

  const handleAccordionExpand = (panel) => (event, isExpanded) => {
    handleRequestEndpoint(false);
    setAccordionExpand(isExpanded ? panel : false);
  };

  const handleRequestEndpoint = (endpoint) => {
    setRequestEndpoint(endpoint);
  };
  const eventProps = {
    version: selectedVersion,
    requestEndpoint: requestEndpoint,
    accordionExpand: accordionExpand,
    eventId: urlParams?.id,
    handleAccordionExpand: handleAccordionExpand,
    handleRequestEndpoint: handleRequestEndpoint,
    setWebhookEvent,
    webhookEvent,
    from,
    platformAccID,
  };

  const tabData = [
    {
      label: "All",
      value: 1,
      tabContent: <AllEvents {...eventProps} />,
      tabName: "all",
    },
    {
      label: webHookStatus.succeeded.label,
      value: 2,
      tabContent: (
        <AllEvents tab={webHookStatus.succeeded.value} {...eventProps} />
      ),
      tabName: webHookStatus.succeeded.value,
    },
    {
      label: webHookStatus.failed.label,
      value: 3,
      tabContent: (
        <AllEvents tab={webHookStatus.failed.value} {...eventProps} />
      ),
      tabName: webHookStatus.failed.value,
    },
  ];

  useEffect(() => {
    if (history) {
      let currentRoute = find(tabData, { route: history.location.pathname });
      if (currentRoute) {
        setSelectedTab(currentRoute.value);
      }
    }
  }, [history]);

  return (
    <Box
      className={
        requestEndpoint
          ? "webhook-attempt-events-endpoint"
          : accordionExpand
          ? "webhook-attempt-events-accordian"
          : "webhook-attempt-events"
      }
    >
      {oneLineLabel({
        label: attemptLabel,
        iconLabel: viewAll,
        shouldIconTextVisible: webhookEvent?.length > 3,
        customStyle: { padding: "12px 0 27px 0" },
        handleClick: () => {
          history.push(
            `/events/${urlParams?.id}/webhook-attempts?api_version=${selectedVersion}`,
            {
              from,
              platformAccID,
            }
          );
        },
      })}
      {versions?.length == 1 && (
        <Text
          component="span"
          size={16}
          className="account-name webhook-version"
          noWrap
        >
          {versionLabel + " " + versions}
        </Text>
      )}
      {versions?.length > 1 && (
        <CustomSelect
          customClass="webhook-select"
          placeholder={versionPlaceHolder}
          onKeyDownCapture={(event) => {
            if (event.key === "Tab") {
              event.stopPropagation();
            }
            if (event.key === "Escape") {
              setOpenDropdownMenu(false);
            }
          }}
          value={selectedVersion}
          MenuProps={{
            id: "custom-filter-select",
          }}
          open={openDropdownMenu}
          onClose={() => {
            setOpenDropdownMenu(false);
          }}
          onOpen={() => {
            setOpenDropdownMenu(true);
          }}
          onChange={(e) => {
            setSelectedVersion(e.target.value);
            setOpenDropdownMenu(false);
          }}
          renderValue={(value) => {
            if (value) {
              return versionLabel + " " + value;
            } else {
              return <Box>{versionPlaceHolder}</Box>;
            }
          }}
        >
          {versions?.length > 1 &&
            versions.map((version, index) => (
              <MenuItem key={index + 1} value={version}>
                <Text
                  component="span"
                  size={16}
                  className="account-name"
                  noWrap
                >
                  {versionLabel + " " + version}
                </Text>
              </MenuItem>
            ))}
        </CustomSelect>
      )}
      <CustomTab
        onChange={(_event, newValue) => {
          setSelectedTab(newValue);
        }}
        tabData={tabData}
        selectedTab={selectedTab}
        setTabRoute={(routeUrl) => history.push(routeUrl)}
        sx={{ position: "relative" }}
        className="webhook-tabs-data"
        height="45px"
        noScroll={true}
      />
    </Box>
  );
};

export default CommonWebhookAttempt;
