import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import { useSelector } from "react-redux";
import {
  addDaysToCurrentDate,
  getBrandingPreviewColor,
  totalAmountInvoiceUI,
  isLightColor,
  validateColor,
} from "@speed/common/src/components/constants";
import {
  dateOfDue,
  dateOfIssue,
  invoice,
  invoiceNumber,
  payOnline,
  payment,
} from "../messages";
import Text from "@speed/common/src/components/Text/Text";
import {
  dateFromTimestampInApp,
  getTimeZoneAndDateFormat,
  hexToRgba,
} from "../constants";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import BillingInfo from "./BillingInfo";
import ProductQtyPrice from "./ProductQtyPrice";
import StoreIcon from "@mui/icons-material/Store";

const InvoicePreview = ({ from, rows, totalRows, customFields }) => {
  const isFromBranding = from === "branding";
  const [backGroundColor, setBackGroundColor] = useState("#2250A1");
  const [textColor, setTextColor] = useState("#fff");
  const [data, setData] = useState([]);
  const [customFieldsData, setCustomFieldsData] = useState([]);

  const { brandingPageUI, invoicePreviewData } = useSelector(
    (state) => state.common
  );
  const { timezone } = getTimeZoneAndDateFormat();

  const previewColor = getBrandingPreviewColor(brandingPageUI);

  useEffect(() => {
    if (from === "branding") {
      setData(rows);
      setCustomFieldsData(customFields);
    } else {
      setData(invoicePreviewData);
      setCustomFieldsData([]);
    }
  }, [invoicePreviewData]);

  useEffect(() => {
    previewColor && setBackGroundColor(previewColor);
  }, [previewColor]);

  useEffect(() => {
    if (brandingPageUI) {
      const { primary_color, primarySavedColor } = brandingPageUI;

      const selectedColor = validateColor(primary_color)
        ? primary_color
        : primarySavedColor;
      isLightColor(selectedColor)
        ? setTextColor("#0a193e")
        : setTextColor("#fff");
    }
  }, [brandingPageUI]);

  const renderInfoTableRow = (label, value, isBold = false) => {
    return (
      <TableRow className="invoice-info-tbl-row">
        <TableCell sx={{ width: 105 }}>
          <Text
            size={12}
            font={isBold ? "semibold" : "regular"}
            variant="h6"
            className="default-text"
          >
            {label}
          </Text>
        </TableCell>
        <TableCell>
          <Text
            size={12}
            font={isBold ? "semibold" : "regular"}
            variant="h6"
            className="default-text"
          >
            {value}
          </Text>
        </TableCell>
      </TableRow>
    );
  };

  const defaultLogo = (
    <Box
      className="default-logo"
      sx={{ backgroundColor: hexToRgba(backGroundColor, 0.4) }}
    >
      <StoreIcon sx={{ fill: textColor }} />
    </Box>
  );

  const renderLogoIcon = () => {
    return brandingPageUI?.use_logo_or_icon === "logo" ? (
      brandingPageUI?.logos ? (
        <img
          src={brandingPageUI?.logos?.original}
          alt="logo"
          className="logo-icon-img"
        />
      ) : (
        defaultLogo
      )
    ) : brandingPageUI?.icons ? (
      <img
        src={brandingPageUI?.icons?.original}
        alt="logo"
        className="logo-icon-img"
      />
    ) : (
      defaultLogo
    );
  };

  return (
    <Box marginTop="20px">
      <Grid container>
        <Grid
          item
          lg={isFromBranding ? 5 : 9}
          className="invoice-preview-container"
        >
          <Box>
            <Box
              className="invoice-top-border"
              sx={{ backgroundColor: backGroundColor }}
            ></Box>
            <Text
              size={16}
              font="semibold"
              className="default-text invoice-info-text"
            >
              {invoice}
            </Text>
            <Grid container>
              <Grid item lg={9}>
                <Box className="invoice-info-section">
                  <TableContainer>
                    <Table aria-label="a dense table">
                      <TableBody>
                        {renderInfoTableRow(
                          invoiceNumber,
                          `#${data?.invoice_prefix || "INV"}-DRAFT`,
                          true
                        )}
                        {renderInfoTableRow(
                          dateOfIssue,
                          dateFromTimestampInApp(new Date(), "MM/DD/YYYY"),
                          timezone
                        )}
                        {renderInfoTableRow(
                          dateOfDue,
                          dateFromTimestampInApp(
                            addDaysToCurrentDate(data?.payment_terms || 0),
                            "MM/DD/YYYY"
                          )
                        )}
                        {customFieldsData?.map((field) =>
                          renderInfoTableRow(field.name, field.value)
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </Grid>
              <Grid item lg={3} className="account-logo">
                {brandingPageUI?.use_logo_or_icon !== ""
                  ? renderLogoIcon()
                  : defaultLogo}
              </Grid>
            </Grid>
            <Box
              className="payment-section"
              sx={{
                backgroundColor: hexToRgba(backGroundColor, 0.2),
              }}
            >
              <Box className="payment">
                <Text
                  size={8}
                  font="regular"
                  variant="h6"
                  className="default-text pl-20"
                >
                  {payment.toLocaleUpperCase()}
                </Text>
                <Text
                  size={11}
                  font="semibold"
                  variant="h6"
                  className="default-text pl-20"
                >
                  {totalAmountInvoiceUI(
                    data?.invoice_line_items,
                    data?.currency
                  )}
                </Text>
              </Box>
              <CustomLink size="small" className="pay-online">
                {payOnline}
              </CustomLink>
            </Box>
            {data?.memo && (
              <>
                <Box className="memo-section">
                  <Text
                    size={10}
                    font="regular"
                    variant="h6"
                    className="default-text"
                  >
                    {data?.memo}
                  </Text>
                </Box>
                {<CustomDivider className="divider" />}
              </>
            )}
            <BillingInfo customer={data?.customer} />
            <Box className="product-table-with-footer">
              <ProductQtyPrice
                rows={data?.invoice_line_items}
                currency={data?.currency}
              />
              {data?.footer && (
                <Text
                  className="default-text footer-desc pl-20"
                  size={10}
                  font="regular"
                  variant="h6"
                >
                  {data?.footer}
                </Text>
              )}
            </Box>
          </Box>
          <Box className="invoice-pdf-footer-section">
            <CustomDivider
              className="divider"
              sx={{ marginBottom: "0px !important" }}
            />
            <Box
              className="invoice-footer"
              display="flex"
              justifyContent="space-between"
              padding="0px 20px"
            >
              <Text
                className="default-text"
                size={10}
                font="regular"
                variant="h6"
              >
                {`#${data?.invoice_prefix || "INV"}-DRAFT - `}
                {totalAmountInvoiceUI(data?.invoice_line_items, data?.currency)}
                {" due "}
                {dateFromTimestampInApp(
                  addDaysToCurrentDate(data?.payment_terms || 0),
                  "MMM DD, YYYY"
                )}
              </Text>
              <Text
                className="default-text"
                size={10}
                font="regular"
                variant="h6"
              >
                01
              </Text>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default InvoicePreview;
