import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import Button from "@speed/common/src/components/Button/Button";
import {
  enterCustomAmount,
  maxUsdtAllowed,
  min1usdtRequired,
  reset,
  submit,
  usdt,
} from "../messages";
import { commonCustomDivider } from "../constants";
import { useState } from "react";
import AmountBox from "./SendPayment/AmountBox";

const EnterCustomAmount = ({
  switchCurrency,
  amount,
  fiatAmount,
  note,
  setAmount,
  setFiatAmount,
  setNote,
  isLoading,
  exchangeRateLoading,
  isNoteClicked,
  exchangeRate,
  setSwitchCurrency,
  currency,
  onSubmit,
  displayAmount,
  selectedTab,
}) => {
  const [isLargeAmount, setIsLargeAmount] = useState(false);

  const handleReset = () => {
    setAmount(0);
    setFiatAmount(0);
    setNote("");
    setIsLargeAmount(false);
  };

  const getTextOfBtn = () => {
    let btnText = submit;
    if (currency === usdt) {
      if (amount <= 0) btnText = min1usdtRequired;
      else if (amount > 100000) btnText = maxUsdtAllowed;
    } else {
      btnText = submit;
    }
    return btnText;
  };

  const amountBoxProps = {
    switchCurrency,
    setFiatAmount,
    setAmount,
    exchangeRate,
    fiatAmount,
    amount,
    displayAmount,
    currency,
    note,
    setNote,
    setIsLargeAmount,
    isNoteClicked,
    isLargeAmount,
    setSwitchCurrency,
    exchangeRateLoading,
    fromReceive: true,
    selectedTab,
  };

  return (
    <Box sx={{ backgroundColor: "#f7fafc" }}>
      <Box className="amount-container">
        <Box className="enter-amount-heading" justifyContent="space-between">
          <Text size={20} font="semibold" className="default-text" variant="h6">
            {enterCustomAmount}
          </Text>
          <Text
            size={16}
            font="semibold"
            sx={{ color: "#2A67FF", cursor: "pointer" }}
            onClick={handleReset}
          >
            {reset}
          </Text>
        </Box>
        <AmountBox {...amountBoxProps} />
        {commonCustomDivider("30px")}
        <Box className="content-center">
          <Button
            sx={{ margin: "24px 0px", width: "520px" }}
            disabled={amount <= 0 || isLargeAmount}
            variant="contained"
            className="send-amt-btn"
            label={getTextOfBtn()}
            onClick={() => onSubmit(amount, note)}
            loading={isLoading}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default EnterCustomAmount;
