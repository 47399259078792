import LinkIcon from "@mui/icons-material/Link";
import { Grid, Box } from "@mui/material";
import Text from "./Text/Text";
import Translation from "./Translation";

function PaymentLinkWebsiteView(props) {
  return (
    <Grid
      className="content-center"
      flexDirection="column"
      height="690px"
      padding="0 20px"
    >
      <Box
        className={`link-icon-box ${
          props.from == "mobile" ? "mobile-icon" : "web-icon"
        }`}
      >
        <LinkIcon />
      </Box>
      <Box>
        <Text
          font={props.from == "web" && "semibold"}
          align="center"
          size={props.from == "mobile" ? 14 : 16}
          variant="subtitle1"
        >
          <Translation value="websitePreviewMessage" />
        </Text>
      </Box>
    </Grid>
  );
}

export default PaymentLinkWebsiteView;
