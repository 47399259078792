import { Box, TableCell, TableRow } from "@mui/material";
import {
  clipboardElement,
  satsToBtcAmount,
} from "@speed/common/src/components/constants";
import {
  renderWithdrawStatus,
  callAPIInterface,
  noOfRecords,
  dateTimeFormatInApp,
} from "../constants";
import { useState, useEffect, useCallback } from "react";
import CustomTable from "@speed/common/src/components/Table/Table";
import { useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { emptyWithdraw } from "../images";
import { emptyWithdrawSubText, onchain } from "../messages";
import {
  emptySubText,
  lightningLabel,
  onChainLabel,
} from "@speed/common/src/components/messages";
import HorizontalSearchPageTable from "../Common/HorizontalSearchPageTable";

function AllWithdraw({
  tab,
  showHorizontalTable = false,
  searchResultData,
  getModuleSearchResultData,
  searchQueryUrl,
  inputString,
  totalNumOfSearchResults,
}) {
  const queryParam = "?limit=" + noOfRecords;
  const [rows, setRows] = useState([]);
  const liveMode = useSelector((state) => state.auth.liveMode);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [queryParams, setQueryParams] = useState(queryParam);
  const history = useSelector((state) => state.common.history);
  const { wiListallwithdraw, wiFetchwithdraw } = useFlags();
  const [loadMoreId, setLoadMoreId] = useState("");
  const fromSearchPage = history.location.pathname === "/search";

  useEffect(() => {
    setRows([]);
    setHasMore(true);
    getWithdrawList([], queryParam);
  }, [liveMode]);

  const columns = [
    {
      title: "Withdraw ID",
      width: "170px",
      align: "left",
      paddingLeft: "38px !important",
    },
    {
      title: "Amount (BTC)",
      width: "192px",
      align: "right",
    },
    { title: "Status", width: "150px" },
    { title: "Method", width: "150px", align: "left" },
    { title: "Withdraw Request", width: "200px", align: "left" },
    { title: "Created on", width: "200px", align: "left" },
  ];

  const getWithdrawList = async (lines, params) => {
    if (fromSearchPage) {
      if (showHorizontalTable) {
        setRows(searchResultData);
      } else {
        setTableRowSkeleton(true);
        await getModuleSearchResultData(searchResultData)
          .then((result) => {
            setTableRowSkeleton(false);
            if (!result.hasMore) {
              setHasMore(false);
            }
            setRows(result?.concatedResultData);
          })
          .catch(() => {
            setTableRowSkeleton(false);
          });
      }
    } else {
      let method,
        data,
        path = "";
      //use method POST when tab is selected other than 'All'
      if (tab) {
        method = "POST";
        data = {
          status: tab,
          limit: noOfRecords,
          ending_before: loadMoreId,
        };
        path = "/withdraws/filter" + params;
      } else {
        method = "GET";
        data = {};
        path = "/withdraws" + params;
      }

      setTableRowSkeleton(true);
      await callAPIInterface(method, path, data)
        .then((res) => {
          if (res) {
            setTableRowSkeleton(false);
            if (!res.has_more) {
              setHasMore(false);
            } else {
              setQueryParams(
                queryParam +
                  "&ending_before=" +
                  res.data[res.data.length - 1].id
              );
              setLoadMoreId(res.data[res.data.length - 1].id);
            }
            setRows(lines.concat(res.data));
          }
        })
        .catch((_err) => {
          setTableRowSkeleton(false);
        });
    }
  };

  const loadMore = useCallback(() => {
    getWithdrawList(rows, queryParams);
  }, [rows]);

  let columnsData = columns.map((column) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{ width: column.width, paddingLeft: column.paddingLeft }}
    >
      {column.title}
    </TableCell>
  ));

  let rowsData = rows?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className={wiFetchwithdraw ? "clickable" : ""}
        onClick={() => {
          wiFetchwithdraw && history.push(`/withdraws/${rowItem.id}`);
        }}
      >
        <TableCell sx={{ textAlign: "left", pl: "38px !important" }}>
          {clipboardElement(
            rowItem.id,
            true,
            "payments-listing-id-clipboard",
            "inputBox",
            false
          )}
        </TableCell>
        <TableCell
          style={{
            textAlign: "right",
            wordBreak: "break-word",
          }}
        >
          {satsToBtcAmount(
            rowItem.status === "paid"
              ? rowItem.target_amount_paid
              : rowItem.target_amount
          )}
        </TableCell>
        <TableCell>{renderWithdrawStatus(rowItem.status)}</TableCell>
        <TableCell>
          {rowItem?.withdraw_method === onchain ? onChainLabel : lightningLabel}
        </TableCell>
        <TableCell>
          {clipboardElement(
            rowItem.withdraw_request,
            true,
            "payments-listing-id-clipboard",
            "inputBox",
            false
          )}
        </TableCell>
        <TableCell style={{ textAlign: "left" }}>
          {dateTimeFormatInApp(rowItem.created)}
        </TableCell>
      </TableRow>
    );
  });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
    className: "payment-table",
    loadMore: loadMore,
    hasMore: hasMore,
    textOnNoData: emptySubText(liveMode, "withdraws", tab),
    subTextOnNoData: emptyWithdrawSubText,
    noDataImage: emptyWithdraw,
  };

  const withdrawTable = () => {
    return showHorizontalTable && fromSearchPage ? (
      <HorizontalSearchPageTable
        moduleName="withdraw"
        columns={columns}
        rows={tableProps.rows}
        searchQueryUrl={searchQueryUrl}
        inputString={inputString}
        totalNumOfSearchResults={totalNumOfSearchResults}
      />
    ) : (
      <CustomTable {...tableProps} />
    );
  };

  return (
    <Box className="section-wrapper">
      <Box className="main-content">
        <Box className="payment-wrapper">
          {wiListallwithdraw ? withdrawTable() : <AccessDenied />}
        </Box>
      </Box>
    </Box>
  );
}

export default AllWithdraw;
