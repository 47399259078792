import React, { useEffect, useRef, useState } from "react";
import {
  completedText,
  doneText,
  moduleNameIntialSetup,
  progressText,
  skipForNow,
  skipText,
  undoSkip,
} from "../messages";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import { Box, CircularProgress, Skeleton } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import CustomAccordion from "@speed/common/src/components/Accordion/Accordion";
import "../../assets/styles/manage-apps.scss";
import Button from "@speed/common/src/components/Button/Button";
import StatusComponent from "@speed/common/src/components/Status/Status";
import { callAPIInterface } from "../constants";
import ShowHideTask from "./ShowHideTask";
import ListInitialSetupSteps from "./ListInitialSetupSteps";
import history from "@speed/common/src/components/history";
import ReactHtmlParser from "react-html-parser";
import { useDispatch } from "react-redux";
import { setIsModalOpen } from "../../redux/common/actions";
import "../../assets/styles/get-started.scss";

const GuideHeader = ({
  onBoardingSteps,
  loading,
  moduleName,
  loaderFn = null,
}) => {
  const [filteredOnboardingSteps, setFilteredOnboardingSteps] = useState([]);
  const [expanded, setExpanded] = useState("");
  const [showHiddenTask, setShowHiddenTask] = useState(false);
  const [currentStepId, setCurrentStepId] = useState(null);
  const [currentQuestionnaireData, setCurrentQuestionnaireData] = useState([]);
  const [guideStepsIds, setGuideStepsIds] = useState(null);
  const [stepCompletedLoading, setStepCompletedLoading] = useState(false);
  const [skipLoading, setSkipLoading] = useState(false);
  const [isListLoading, setIsListLoading] = useState(false);
  const dispatch = useDispatch();
  const scrollRef = useRef();

  useEffect(() => {
    setIsListLoading(true);
    getUserQuestionnaireResponse();
  }, []);

  useEffect(() => {
    const filteredList = [];
    if (showHiddenTask) {
      const completedList = [];
      onBoardingSteps?.steps?.forEach((onboardingStep) => {
        if (!guideStepsIds?.includes(onboardingStep?.id?.toString())) {
          filteredList.push(onboardingStep);
        } else {
          completedList.push(onboardingStep);
        }
      });
      setFilteredOnboardingSteps([...filteredList, ...completedList]);
    } else {
      onBoardingSteps?.steps?.forEach((onboardingStep) => {
        if (!guideStepsIds?.includes(onboardingStep?.id?.toString())) {
          filteredList.push(onboardingStep);
        }
      });
      setFilteredOnboardingSteps(filteredList);
    }
  }, [showHiddenTask, onBoardingSteps, guideStepsIds]);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#2ECC71" : "#308fe8",
    },
  }));

  const handleChange = (id) => (_e, newExpanded) => {
    setExpanded(newExpanded ? id : false);
  };

  const getUserQuestionnaireResponse = (setLoading = null) => {
    callAPIInterface("GET", "/onboarding")
      .then((response) => {
        const data = response?.data || [];
        let currentModuleData = [];
        data.forEach((modules) => {
          if (modules?.module === moduleName) {
            currentModuleData = modules?.response;
          }
        });
        setCurrentQuestionnaireData(currentModuleData);
        const guideStepId = [];
        currentModuleData?.forEach((guideSteps) => {
          guideStepId.push(guideSteps?.id);
        });
        setGuideStepsIds([...guideStepId]);
        if (setLoading) {
          setLoading(false);
        }
        setIsListLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setIsListLoading(false);
      });
  };

  const handleStepCompleted = (id, stepId, stepName, setLoading) => {
    const stepData = {
      id: id,
      module: moduleName,
      answers: [{ id: stepId, text: stepName }],
    };
    setLoading(true);
    callAPIInterface("POST", "/onboarding", stepData)
      .then(() => {
        getUserQuestionnaireResponse(setLoading);
      })
      .catch(() => setLoading(false));
  };

  const handleDeleteUserResponse = (id) => {
    const deleteResponse = { id: id, module: moduleName };
    setSkipLoading(true);
    callAPIInterface("DELETE", "/onboarding", deleteResponse)
      .then(() => {
        getUserQuestionnaireResponse(setSkipLoading);
      })
      .catch(() => setSkipLoading(false));
  };

  const getStatus = (id) => {
    let status;
    currentQuestionnaireData?.forEach((guideSteps) => {
      if (guideSteps?.id === id?.toString()) {
        status = guideSteps?.answers?.[0]?.text;
      }
    });
    return status;
  };

  const handleUndoSkip = (onboardingStep) => {
    setCurrentStepId(onboardingStep?.id);
    if (guideStepsIds?.includes(onboardingStep?.id?.toString())) {
      handleDeleteUserResponse(onboardingStep?.id);
    } else {
      handleStepCompleted(
        onboardingStep?.id,
        onboardingStep?.step_id,
        skipText,
        setSkipLoading
      );
    }
  };

  const accordionDetailsComp = (onboardingStep) => {
    return (
      <Box paddingLeft="53px">
        <Text
          className="accordian-details"
          font="regular"
          variant="body1"
          size={18}
        >
          {onboardingStep?.subtitle}
        </Text>
        <Box width={onboardingStep?.is_meta_details_text ? "700px" : ""}>
          {ReactHtmlParser(onboardingStep?.meta_details)}
        </Box>
        {onboardingStep?.allowed_skip &&
          getStatus(onboardingStep?.id) !== doneText && (
            <Box className="step-completed-loading">
              {getStatus(onboardingStep?.id) !== skipText && (
                <Button
                  label={onboardingStep?.button_comp?.title}
                  variant="contained"
                  color="primary"
                  className="payment-link-btn"
                  loading={
                    currentStepId === onboardingStep?.id
                      ? stepCompletedLoading
                      : null
                  }
                  onClick={() => {
                    setCurrentStepId(onboardingStep?.id);
                    handleStepCompleted(
                      onboardingStep?.id,
                      onboardingStep?.step_id,
                      doneText,
                      setStepCompletedLoading
                    );
                    history.push(onboardingStep?.button_comp?.redirect_url);
                    if (onboardingStep?.button_comp?.willOpenModal) {
                      dispatch(setIsModalOpen(true));
                    }
                  }}
                  disabled={skipLoading}
                />
              )}
              {skipLoading ? (
                <CircularProgress
                  style={{
                    width: "15px",
                    height: "15px",
                    marginRight: "5px",
                    color: "#848b9e",
                    marginLeft: "60px",
                  }}
                />
              ) : (
                <Text
                  style={{
                    lineHeight: "140%",
                    color: "#848B9E",
                    paddingLeft:
                      getStatus(onboardingStep?.id) !== skipText ? "24px" : "",
                    cursor: "pointer",
                    pointerEvents:
                      skipLoading || stepCompletedLoading ? "none" : "",
                  }}
                  font="semibold"
                  size={18}
                  onClick={() => handleUndoSkip(onboardingStep)}
                >
                  {guideStepsIds?.includes(onboardingStep?.id?.toString()) &&
                  getStatus(onboardingStep?.id) === skipText
                    ? undoSkip
                    : skipForNow}
                </Text>
              )}
            </Box>
          )}
      </Box>
    );
  };

  const completedTextStyle = {
    textDecoration: "line-through",
  };

  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };

  const StepsLoader = () => {
    const loaderArr = [...Array(2).keys()];
    return loaderArr.map((val) => (
      <Box className="steps-border" key={`steps-${val}`} width="1100px">
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          pb="40px"
        >
          <Skeleton
            animation="wave"
            width={307}
            height={20}
            sx={{
              bgcolor: "#C4CCD2 !important",
              marginTop: "40px",
            }}
          />
        </Box>
        {val !== loaderArr?.length - 1 && (
          <Box
            className="steps-border"
            borderBottom="1px solid #E4E9EE"
            width="1100px"
          />
        )}
      </Box>
    ));
  };

  return (
    <>
      <Box className="guide-header" ref={scrollRef}>
        <Box display="flex" flexDirection="row">
          {loading ? (
            <Skeleton
              animation="wave"
              variant="circular"
              width={100}
              height={100}
              sx={{ bgcolor: "#EAEEF1 !important", margin: "2px 14px 0px 0px" }}
            />
          ) : (
            <img
              src={onBoardingSteps?.icon?.data?.attributes?.url}
              height={100}
              width={100}
              alt=""
              style={{ margin: "2px 14px 0px 0px" }}
            />
          )}
          <Box pl="26px" className="header-text" width="700px">
            {loading ? (
              <Skeleton
                animation="wave"
                width={307}
                height={20}
                sx={{
                  bgcolor: "#C4CCD2 !important",
                }}
              />
            ) : (
              <Text font="semi-bold" size={24}>
                {onBoardingSteps?.header}
              </Text>
            )}
            {loading ? (
              <Skeleton
                animation="wave"
                width={700}
                height={14}
                sx={{
                  bgcolor: "#EAEEF1 !important",
                }}
              />
            ) : (
              <Text
                variant="body1"
                font="regular"
                className="grey-text"
                size={18}
              >
                {onBoardingSteps?.subheader}
              </Text>
            )}
          </Box>
        </Box>
        <Box
          pt="50px"
          className="steps-border"
          width="1100px"
          pb={
            guideStepsIds?.length === onBoardingSteps?.steps?.length &&
            !showHiddenTask &&
            !onBoardingSteps?.footer_content
              ? "50px"
              : "10px"
          }
        >
          {loading ? (
            <Skeleton
              animation="wave"
              width={172}
              height={14}
              sx={{
                bgcolor: "#C4CCD2 !important",
              }}
            />
          ) : (
            <Box display="flex" flexDirection="row" pb="8px">
              <Text
                variant="body1"
                font="regular"
                className="grey-text"
                size={18}
                align="left"
                style={{ paddingRight: "4px" }}
              >
                {progressText}
              </Text>
              <Text
                className="card-sub-text"
                size={18}
                font="bold"
                align="left"
              >
                {loading || !guideStepsIds ? (
                  <Skeleton
                    animation="wave"
                    width={84}
                    height={14}
                    sx={{
                      bgcolor: "#EAEEF1 !important",
                      marginTop: "8px",
                    }}
                  />
                ) : (
                  guideStepsIds?.length >= 0 &&
                  onBoardingSteps?.steps?.length >= 0 &&
                  `${
                    100 -
                    Math.ceil(
                      ((onBoardingSteps?.steps?.length -
                        guideStepsIds?.length) /
                        onBoardingSteps?.steps?.length) *
                        100
                    )
                  }% ${completedText}`
                )}
              </Text>
            </Box>
          )}
          {loading ? (
            <Skeleton
              className="steps-border"
              animation="wave"
              width={1094}
              height={14}
              sx={{
                bgcolor: "#EAEEF1 !important",
              }}
            />
          ) : (
            <BorderLinearProgress
              className="steps-border"
              variant="determinate"
              value={
                guideStepsIds?.length >= 0 &&
                onBoardingSteps?.steps?.length >= 0 &&
                100 -
                  Math.ceil(
                    ((onBoardingSteps?.steps?.length - guideStepsIds?.length) /
                      onBoardingSteps?.steps?.length) *
                      100
                  )
              }
              style={{
                width: "1100px",
                alignItems: "center",
              }}
            />
          )}
        </Box>
        {(loading || isListLoading) &&
          (moduleName === moduleNameIntialSetup ? loaderFn() : <StepsLoader />)}
        {moduleName === moduleNameIntialSetup ? (
          <ListInitialSetupSteps
            skipLoading={skipLoading}
            stepCompletedLoading={stepCompletedLoading}
            filteredOnboardingSteps={filteredOnboardingSteps}
            getStatus={getStatus}
            handleDeleteUserResponse={handleDeleteUserResponse}
            handleStepCompleted={handleStepCompleted}
            guideStepsIds={guideStepsIds}
            setCurrentStepId={setCurrentStepId}
            currentStepId={currentStepId}
            setSkipLoading={setSkipLoading}
            setStepCompletedLoading={setStepCompletedLoading}
            isListLoading={isListLoading}
          />
        ) : (
          filteredOnboardingSteps?.map(
            (onboardingStep, index) =>
              !isListLoading && (
                <Box
                  className="accordian-main-box steps-border"
                  key={onboardingStep?.id}
                  width="1100px"
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="space-between"
                    paddingBottom="40px"
                  >
                    <CustomAccordion
                      key={onboardingStep?.id}
                      className={`support-center-accordian ${
                        expanded !== onboardingStep.title
                          ? "border-accordian"
                          : "rotate-icon"
                      } `}
                      isTitleShown={true}
                      expandIcon="expandMore"
                      onChange={() => handleChange(onboardingStep?.id)}
                      expanded={expanded === onboardingStep?.id}
                      accordianPanel={onboardingStep}
                      title={onboardingStep?.title}
                      style={{
                        pointerEvents:
                          skipLoading || stepCompletedLoading ? "none" : "",
                      }}
                      textStyle={
                        guideStepsIds?.includes(
                          onboardingStep?.id?.toString()
                        ) && getStatus(onboardingStep?.id) === doneText
                          ? completedTextStyle
                          : null
                      }
                      customComponent={accordionDetailsComp(onboardingStep)}
                    />
                    {guideStepsIds?.includes(onboardingStep?.id?.toString()) &&
                      getStatus(onboardingStep?.id) === doneText && (
                        <Box pt="40px">
                          <StatusComponent
                            severity="completed"
                            label="Completed"
                            textClassName="completed-text"
                          />
                        </Box>
                      )}
                    {guideStepsIds?.includes(onboardingStep?.id?.toString()) &&
                      getStatus(onboardingStep?.id) === skipText && (
                        <Box pt="40px">
                          <StatusComponent
                            severity="skipped"
                            label="Skipped"
                            textClassName="skipped-text"
                          />
                        </Box>
                      )}
                  </Box>
                  {expanded !== onboardingStep.title &&
                    index !==
                      (onBoardingSteps?.footer_content
                        ? filteredOnboardingSteps?.length
                        : filteredOnboardingSteps?.length - 1) && (
                      <Box
                        className="steps-border"
                        borderBottom="1px solid #E4E9EE"
                        width="1100px"
                      />
                    )}
                </Box>
              )
          )
        )}
        {!isListLoading && onBoardingSteps?.footer_content && (
          <Box className="footer-content">
            {ReactHtmlParser(onBoardingSteps?.footer_content)}
          </Box>
        )}
      </Box>
      {currentQuestionnaireData?.length ? (
        <ShowHideTask
          showHiddenTask={showHiddenTask}
          setShowHiddenTask={setShowHiddenTask}
          setExpanded={setExpanded}
          currentQuestionnaireData={currentQuestionnaireData}
          handleScroll={handleScroll}
          scrollRef={scrollRef}
        />
      ) : null}
    </>
  );
};

export default GuideHeader;
