import { Box, Grid, TableCell, TableRow } from "@mui/material";
import SimpleTable from "@speed/common/src/components/SimpleTable";
import Text from "@speed/common/src/components/Text/Text";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import {
  getAmountByCurrency,
  totalAmountInvoiceUI,
  getCurrencyObj,
} from "@speed/common/src/components/constants";
import React from "react";

const ProductQtyPrice = ({ rows, currency }) => {
  const columns = [
    { title: "Description", width: "180px" },
    { title: "QTY", width: "38px" },
    { title: "Unit price", width: "78px" },
    // { title: "Tax", width: "27px" },
    { title: "Amount", width: "27px", align: "right" },
  ];

  let columnsData = columns.map((column, index) => (
    <TableCell
      key={column.title}
      sx={{ width: column.width, position: "unset" }}
    >
      <Text
        align={column.align}
        size={10}
        font="regular"
        variant="h6"
        className="grey-text"
      >
        {column.title.toLocaleUpperCase()}
      </Text>
    </TableCell>
  ));

  const renderRows = (value, align = "left") => {
    return (
      <TableCell>
        <TruncatedTextTooltip
          textValue={value}
          cellWidth="180px"
          textProps={{
            size: 10,
            variant: "h6",
            className: "default-text",
            font: "regular",
            align: align,
          }}
        />
      </TableCell>
    );
  };

  const renderSummationRow = (
    name,
    amount,
    font,
    displayBottomBorder = true
  ) => {
    const removeBorder = !displayBottomBorder ? "0px !important" : "";
    return (
      <TableRow>
        <TableCell sx={{ borderBottom: removeBorder }}>
          <Text size={10} font={font} variant="h6" className="default-text">
            {name}
          </Text>
        </TableCell>
        <TableCell sx={{ borderBottom: removeBorder }}>
          <Text
            size={10}
            font={font}
            variant="h6"
            className="default-text"
            align="right"
          >
            {amount}
          </Text>
        </TableCell>
      </TableRow>
    );
  };
  let rowsData = rows?.map((rowItem) => {
    const currencyWIthAmount = (
      <>
        {getCurrencyObj(rowItem?.currency)?.symbol}
        {getAmountByCurrency(rowItem?.unit_amount, rowItem?.currency)}
      </>
    );
    const amount = (
      <>
        {getCurrencyObj(rowItem?.currency)?.symbol}
        {getAmountByCurrency(
          rowItem?.unit_amount * rowItem?.quantity,
          rowItem?.currency
        )}
      </>
    );
    return (
      <TableRow key={rowItem.name}>
        {renderRows(rowItem?.name)}
        {renderRows(rowItem?.quantity)}
        {renderRows(currencyWIthAmount)}
        {/* {renderRows(rowItem.tax)} */}
        {renderRows(amount, "right")}
      </TableRow>
    );
  });

  let totalRowsData = [
    renderSummationRow(
      "Total",
      totalAmountInvoiceUI(rows, currency),
      "regular"
    ),
    renderSummationRow(
      "Amount due",
      totalAmountInvoiceUI(rows, currency),
      "semibold",
      false
    ),
  ];
  return (
    <Box>
      {/* product, qty, price table */}
      <SimpleTable
        columns={columnsData}
        rows={rowsData}
        className="product-table"
      />

      <Grid container>
        <Grid item lg={6} sm={6}></Grid>
        <Grid item lg={6} sm={6}>
          {/* total table */}
          <SimpleTable rows={totalRowsData} className="total-table" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ProductQtyPrice;
