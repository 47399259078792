import React, { useEffect, useState } from "react";
import { Box, Grid, TableCell, TableRow } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import WarningIcon from "@mui/icons-material/Warning";
import "../../assets/styles/payments.scss";
import "../../assets/styles/balance.scss";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import {
  RouteBreadcrumbs,
  callAPIInterface,
  dateTimeFormatInApp,
  getAccNameFromId,
  renderSenderReceiverOfTransfer,
  transferType,
} from "../constants";
import Text from "@speed/common/src/components/Text/Text";
import {
  clipboardElement,
  generateBreadcrumbs,
  redirectWhenEntityNotFound,
  showAmount,
} from "@speed/common/src/components/constants";
import {
  buttonTextForIdNotFound,
  dateCreated,
  destinationAccount,
  entityNotFoundMessage,
  events as eventsText,
  fromAccount,
  subtitleForIdNotFound,
  transferDetailsText,
  type,
} from "../messages";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import { events } from "../Events/Events";
import DetailPagePaymentSkeleton from "@speed/common/src/components/Skeleton/DetailPagePaymentSkeleton";
import PaymentsSkeleton from "@speed/common/src/components/Skeleton/PaymentsSkeleton";
import LinkNotFound from "@speed/common/src/components/LinkNotFound";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { KeyValueGrid } from "@speed/common/src/components/KeyValueGrid";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";

const TransferDetail = (props) => {
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const [isShowNotFound, setIsShowNotFound] = useState(false);
  const [data, setData] = useState(null);
  const [transferEventsList, setTransferEventsList] = useState([]);
  const [transferEventSkeleton, setTransferEventSkeleton] = useState(true);

  const { liveMode, currentAccount } = useSelector((state) => state.auth);
  const history = useSelector((state) => state.common.history);
  const urlParams = useParams();
  const { trListingFe, trEventFe } = useFlags();

  const getTransferEventsList = () => {
    setTransferEventSkeleton(true);
    const filterEvents = {
      ids: [urlParams?.id],
    };
    callAPIInterface("POST", "/events/filters", JSON.stringify(filterEvents))
      .then((res) => {
        if (res) {
          setTransferEventSkeleton(false);
          setTransferEventsList(res?.data);
        }
      })
      .catch(() => {
        setTransferEventSkeleton(false);
      });
  };

  const getTransferDetail = () => {
    callAPIInterface("GET", `/balances/transfer/${urlParams.id}`)
      .then((res) => {
        if (res) {
          setData(res);
          trEventFe && getTransferEventsList();
        }
      })
      .catch((err) => {
        setIsShowNotFound(redirectWhenEntityNotFound(err));
      });
  };

  const loadDetailPage = () => {
    setData(null);
    setIsShowNotFound(false);
    getTransferDetail();
  };

  useEffect(() => trListingFe && loadDetailPage(), [trListingFe, liveMode]);

  const transferEventRows = transferEventsList?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/events/${rowItem?.id}`)}
      >
        <TableCell>{events(rowItem)}</TableCell>
        <TableCell
          align="right"
          sx={{ color: "#848b9e", display: "flex", minWidth: "250px" }}
        >
          {dateTimeFormatInApp(rowItem?.created)}
        </TableCell>
      </TableRow>
    );
  });

  const transferHeaderContent = () => {
    return (
      <Box className="header-content">
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Text className="btc-amount" variant="h1" size={28}>
            {showAmount({
              amount: data?.target_amount,
              currency: data?.target_currency,
              appendCurrency: true,
            })}
          </Text>
          <Box className="header-price-content" mt="0px !important">
            {clipboardElement(data?.id, true, "payments-detail", "text", true)}
          </Box>
        </Box>
        <Box className="header-price-content" marginTop={2}>
          <Box
            className="fiat-price"
            width="100%"
            sx={{ wordBreak: "break-word" }}
          >
            {data?.description}
          </Box>
        </Box>
      </Box>
    );
  };

  const renderEventDataTable = () => (
    <Box className="product-content">
      <HorizontalTable
        label={eventsText}
        isColumnShown={transferEventRows?.length > 0}
        rows={transferEventRows}
        rowsPerPage={3}
        handleViewAllClick={() => {
          history.push(`/transfers/${urlParams?.id}/events`);
        }}
        isShowButton={transferEventRows?.length > 3}
        tableBodyClassName="border-none"
      />
    </Box>
  );

  const renderTransferEventContent = () => {
    return !transferEventSkeleton ? (
      renderEventDataTable()
    ) : (
      <DetailPagePaymentSkeleton />
    );
  };

  const keyGridWidthProps = {
    keyNameCellWidth: 5,
    valueCellWidth: 7,
  };

  const getAccountId = (isDestinationAccount = false) =>
    renderSenderReceiverOfTransfer(data, currentAccount?.account?.id)?.[
      isDestinationAccount ? "receiver" : "sender"
    ];

  return !isShowNotFound ? (
    <Grid className="payments-detail-wrapper" container spacing={1}>
      <Box className="section-wrapper" position="relative">
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
        {trListingFe ? (
          <>
            {data ? (
              <>
                {transferHeaderContent()}
                <Box className="details-content">
                  <Box className="details-content" marginTop="60px">
                    <Text size={20} className="default-text">
                      {transferDetailsText}
                    </Text>
                  </Box>
                  <CustomDivider sx={{ my: "12px" }} />
                  <Box
                    gap={2}
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="space-between"
                    pr="16px"
                  >
                    <KeyValueGrid
                      {...keyGridWidthProps}
                      keyName={dateCreated}
                      value={
                        <Text
                          variant="h4"
                          size={16}
                          className="default-text"
                          font="regular"
                        >
                          {dateTimeFormatInApp(data?.created)}
                        </Text>
                      }
                    />
                    <KeyValueGrid
                      {...keyGridWidthProps}
                      keyName={type}
                      value={
                        <Text
                          variant="h4"
                          size={16}
                          className="default-text"
                          font="regular"
                        >
                          {transferType[data?.type]?.label}
                        </Text>
                      }
                    />
                    <KeyValueGrid
                      {...keyGridWidthProps}
                      keyName={fromAccount}
                      value={
                        <TruncatedTextTooltip
                          textValue={getAccNameFromId(getAccountId())}
                          cellWidth="300px"
                        />
                      }
                    />
                    <KeyValueGrid
                      {...keyGridWidthProps}
                      keyName={destinationAccount}
                      value={
                        <TruncatedTextTooltip
                          textValue={getAccNameFromId(getAccountId(true))}
                          cellWidth="300px"
                        />
                      }
                    />
                  </Box>
                </Box>
              </>
            ) : (
              <PaymentsSkeleton props={props} />
            )}
            {trEventFe && renderTransferEventContent()}
          </>
        ) : (
          <AccessDenied />
        )}
      </Box>
    </Grid>
  ) : (
    <Box className="not-found-wrapper">
      <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      <LinkNotFound
        icon={<WarningIcon className="warning-icon" />}
        title={entityNotFoundMessage("Transfer")}
        subTitle={subtitleForIdNotFound("Transfer", urlParams.id)}
        btnText={buttonTextForIdNotFound("transfers", liveMode)}
      />
    </Box>
  );
};

export default TransferDetail;
