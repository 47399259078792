import React from "react";
import CommonBoxWrapper from "../Settings/PayoutScheduling/CommonBoxWrapper";
import { Box, Skeleton, List } from "@mui/material";
import { CustomSwitch } from "@speed/common/src/components/Switch/Switch";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import Text from "@speed/common/src/components/Text/Text";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import { autoPayoutStatus } from "@speed/common/src/components/constants";
import { payoutVerticalLine } from "../constants";

const CommonCardWithSwitch = ({
  isSwitchVisible = true,
  switchLDFlagName = "",
  isLdFlagRequired = false,
  bottomComponent,
  data,
  loader,
  switchOnChange,
  switchChecked,
  switchDisabled,
  isMoreIconVisible,
  iconClick,
  title,
  customPopperContent,
  anchorEl,
  setAnchorEl,
  walletName,
  walletType,
  tagProps,
}) => {
  const customSwitchComponent = () => {
    const switchComponent = loader ? (
      <Skeleton
        animation="wave"
        width="80px"
        height="20px"
        sx={{ bgcolor: "#C4CCD2 !important" }}
      />
    ) : (
      <CustomSwitch
        checked={switchChecked}
        onChange={switchOnChange}
        disabled={switchDisabled}
      />
    );
    return (
      data?.status && (
        <Box
          className={`${
            data?.status === autoPayoutStatus.active.value
              ? "auto-payout-switch"
              : ""
          }`}
          display="flex"
          pt="30px"
        >
          {isSwitchVisible && switchComponent}
          {isMoreIconVisible && (
            <MoreHorizIcon
              className="horizontal-dots-icon pointer-cursor"
              style={{ marginLeft: "20px" }}
              tabIndex={0}
              onClick={iconClick}
            />
          )}
        </Box>
      )
    );
  };

  let tagPassingProps = {
    text: tagProps?.text,
    variant: tagProps?.variant,
    sx: { p: "4px" },
  };

  if (tagProps?.icon)
    tagPassingProps = {
      ...tagPassingProps,
      deleteIcon: tagProps?.icon,
      onDelete: () => {},
    };

  return (
    <>
      <CommonBoxWrapper
        footer={
          isLdFlagRequired
            ? switchLDFlagName && customSwitchComponent()
            : customSwitchComponent()
        }
        bottomComponent={bottomComponent}
      >
        <Box>
          <Box mb="8px" display="flex" alignItems="center">
            <Text
              className="card-text"
              size={20}
              font="bold"
              align="left"
              style={{ paddingRight: "8px" }}
            >
              {title}
            </Text>
            {data?.status && (
              <Box>
                <Tag
                  className="custom-payouts-status-label"
                  {...tagPassingProps}
                />
              </Box>
            )}
          </Box>
          <Box display="flex">
            <TruncatedTextTooltip
              textProps={{
                className: "grey-text",
              }}
              textValue={walletName}
              cellWidth="300px"
            />
            {data?.id && payoutVerticalLine({ m: "0 16px" })}
            {walletType && (
              <Text
                className="grey-text"
                size={16}
                font="regular"
                variant="subtitle1"
              >
                {walletType}
              </Text>
            )}
          </Box>
        </Box>
      </CommonBoxWrapper>
      <CustomPopper
        disablePortal={true}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        position="bottom-end"
        handleClose={() => setAnchorEl(null)}
      >
        <List>{customPopperContent}</List>
      </CustomPopper>
    </>
  );
};

export default CommonCardWithSwitch;
