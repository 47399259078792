import React, { useState } from "react";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import OneQRPreview from "../Common/OneQRPreview";
import { useSelector } from "react-redux";
import { download, viewOneQRTitle } from "../messages";
import { Box } from "@mui/material";
import Button from "@speed/common/src/components/Button/Button";
import { handleDownloadImage } from "../constants";

const ViewQRModal = ({ handleClose, viewQR, className, data }) => {
  const [downloadLoader, setDownloadLoader] = useState(false);

  const { brandingPageUI } = useSelector((state) => state.common);

  const globalStates = {
    brandingPageUI,
  };

  const handleDownload = () => {
    setDownloadLoader(true);
    handleDownloadImage(data?.name, data?.id, data?.created)
      .then((value) => {
        setDownloadLoader(value);
      })
      .catch((value) => {
        setDownloadLoader(value);
      });
  };

  const renderOneQRPreview = (qrType) => {
    return (
      <Box className="view-qr">
        <OneQRPreview
          {...globalStates}
          height="545px"
          type="phone"
          QRType={qrType}
          qrCodeValue={data?.payment_request}
          className={
            brandingPageUI?.icons || brandingPageUI?.logos
              ? "qr-code-img-small"
              : "qr-code-img-large"
          }
        />
        <Button
          label={download}
          variant="contained"
          color="primary"
          className="download-btn"
          loading={downloadLoader}
          onClick={() => handleDownload()}
        />
      </Box>
    );
  };
  return (
    <Modal
      maxWidth="xs"
      body={renderOneQRPreview(data?.oneqr_method)}
      handleClose={handleClose}
      open={viewQR}
      title={viewOneQRTitle}
      className={className}
    />
  );
};

export default ViewQRModal;
