import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  TableCell,
  TableRow,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import {
  callAPIInterface,
  dateFromTimestampInApp,
  dateTimeFormatInApp,
  finalizeInvoice,
  noOfRecords,
  sendInvoiceAndEmail,
} from "../constants";
import { useDispatch, useSelector } from "react-redux";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import { emptySubText } from "@speed/common/src/components/messages";
import {
  emailSentToastMsg,
  emptyInvoiceSubText,
  finalizeInvoiceMsg,
  sendInvoice,
} from "../messages";
import { emptyPayment } from "../images";
import CustomTable from "@speed/common/src/components/Table/Table";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  clipboardElement,
  getAmountByCurrency,
  getCurrencyObj,
  invoiceStatus,
} from "@speed/common/src/components/constants";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
// import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DeleteDraftModal from "./DeleteDraftModal";
import MoreActionButton from "./MoreActionButton";
import { setIsDataAvailable, showToast } from "../../redux/common/actions";
import { loaderSpinner } from "@speed/common/src/components/images";

function InvoiceTable({ tab }) {
  const queryParam = "?limit=" + noOfRecords;
  const [queryParams, setQueryParams] = useState(queryParam);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentInvoiceRow, setCurrentInvoiceRow] = useState(null);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [hasMoreInvoices, sethasMoreInvoices] = useState(true);
  const [rows, setRows] = useState([]);
  const [rowLoader, setRowLoader] = useState(false);
  const [rowId, setRowId] = useState(null);
  const [onFinalizingRows, setOnFinalizingRows] = useState([]);
  const [showSendInvoiceLoader, setShowSendInvoiceLoader] = useState(false);
  const [openDeleteDraftModal, setOpenDeleteDraftModal] = useState(false);

  const dispatch = useDispatch();
  const liveMode = useSelector((state) => state.auth.liveMode);
  const { history, isDataAvailable, caughtErrorInStrapi } = useSelector(
    (state) => state.common
  );
  const openInvoicePopper = Boolean(anchorEl);
  const isFromDraftInvoice =
    currentInvoiceRow?.status?.toLowerCase() === "draft";

  const invoiceColumns = [
    {
      title: "Invoice ID",
      width: "170px",
      paddingLeft: "38px !important",
    },
    {
      title: "Customer",
      width: "200px",
    },
    { title: "Email", width: "200px" },
    { title: "Invoice number", width: "200px" },
    { title: "Status", width: "130px" },
    { title: "Amount", width: "200px" },
    { title: "Created on", width: "200px" },
    { title: "Due", width: "120px" },
    { title: "Actions", width: "25px" },
  ];

  const getInvoicesList = (lines, params) => {
    let method,
      data,
      path = "";
    //use method POST when tab is selected other than 'All'
    if (tab) {
      method = "POST";
      data = {
        status: tab,
      };
      path = "/invoices/filter";
    } else {
      method = "GET";
      data = {};
      path = "/invoices";
    }

    setTableRowSkeleton(true);
    callAPIInterface(method, path + params, data)
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            sethasMoreInvoices(false);
          } else {
            setQueryParams(
              queryParam + "&ending_before=" + res.data[res.data.length - 1].id
            );
          }
          setRows(lines.concat(res.data));
          dispatch(setIsDataAvailable(res.data.length > 0));
        }
      })
      .catch((_err) => {
        setTableRowSkeleton(false);
      });
  };

  useEffect(() => {
    setRows([]);
    sethasMoreInvoices(true);
    getInvoicesList([], queryParam);
  }, [liveMode]);

  const loadMore = useCallback(() => {
    getInvoicesList(rows, queryParams);
  }, [rows]);

  useEffect(() => {
    setRows(onFinalizingRows);
  }, [onFinalizingRows]);

  const handleClick = (event, rowData) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setCurrentInvoiceRow(rowData);
  };

  const invoiceColumnData = invoiceColumns.map((column) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{ width: column.width, paddingLeft: column.paddingLeft }}
    >
      {column.title}
    </TableCell>
  ));

  const invoicesRowsData = rows?.map((rowItem) => {
    const buttonData = [
      // {
      //   iconElement: <SaveAltIcon />,
      //   onClick: () => {},
      // },
      {
        iconElement: <MoreHorizIcon />,
        onClick: (event) => {
          handleClick(event, rowItem);
        },
      },
    ];
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/invoices/${rowItem.id}`)}
      >
        {rowItem.id === rowId && rowLoader ? (
          invoiceColumns.map((column) => {
            return (
              <TableCell
                key={column.title}
                sx={{ padding: "18px 16px !important", width: column.width }}
              >
                <Skeleton />
              </TableCell>
            );
          })
        ) : (
          <>
            <TableCell sx={{ paddingLeft: "38px !important" }}>
              {clipboardElement(
                rowItem.id,
                true,
                "payments-listing-id-clipboard",
                "inputBox",
                false
              )}
            </TableCell>
            <TableCell>{rowItem?.customer?.name}</TableCell>
            <TableCell>{rowItem?.customer?.email}</TableCell>
            <TableCell>{rowItem?.invoice_number || "-"}</TableCell>
            <TableCell>
              <Tag
                text={invoiceStatus[rowItem?.status?.toLowerCase()].label}
                variant={invoiceStatus[rowItem?.status?.toLowerCase()].variant}
                className="text-capitalize"
              />
            </TableCell>
            <TableCell>
              {getCurrencyObj(rowItem?.currency)?.symbol}
              {getAmountByCurrency(rowItem?.invoice_amount, rowItem?.currency)}
            </TableCell>
            <TableCell>{dateTimeFormatInApp(rowItem.created)}</TableCell>
            <TableCell>
              {rowItem?.invoice_due_date
                ? dateFromTimestampInApp(rowItem?.invoice_due_date)
                : "-"}
            </TableCell>
            <TableCell className="invoice-action-table-cell">
              {rowItem?.status?.toLowerCase() !== "paid" && (
                <MoreActionButton
                  buttonData={buttonData}
                  isOpenMoreMenu={
                    openInvoicePopper &&
                    rowItem?.id === currentInvoiceRow?.id &&
                    "open-options"
                  }
                />
              )}
            </TableCell>
          </>
        )}
      </TableRow>
    );
  });

  const tableProps = {
    columns: invoiceColumnData,
    rows: invoicesRowsData,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMoreInvoices,
    loadMore: loadMore,
    className: "payment-table invoice-data-table",
    textOnNoData: emptySubText(liveMode, "invoices", tab),
    subTextOnNoData: emptyInvoiceSubText,
    noDataImage: emptyPayment,
  };

  const setRowDataOnFinalizingInvoice = (res) => {
    rows.map((row) => {
      if (row.id === currentInvoiceRow?.id) {
        row.status = res.status;
        row.invoice_number = res.invoice_number;
        row.invoice_due_date = res.invoice_due_date;
      }
    });
    setOnFinalizingRows(rows);
    setRowLoader(false);
    setAnchorEl(null);
  };

  const handleClickSendInvoice = () => {
    if (isFromDraftInvoice) {
      setRowId(currentInvoiceRow?.id);
      setRowLoader(true);
    } else {
      setShowSendInvoiceLoader(true);
    }

    sendInvoiceAndEmail(currentInvoiceRow?.id)
      .then((res) => {
        if (res) {
          dispatch(
            showToast({
              isToastOpen: true,
              toastMessage: emailSentToastMsg,
              toastVariant: "success",
            })
          );
          if (isFromDraftInvoice) {
            setRowDataOnFinalizingInvoice(res);
            setRowLoader(false);
          } else {
            setShowSendInvoiceLoader(false);
          }
          setAnchorEl(null);
        }
      })
      .catch(() => {
        if (isFromDraftInvoice) {
          setRowLoader(false);
        } else {
          setShowSendInvoiceLoader(false);
        }
        setAnchorEl(null);
      });
  };

  const handleClickFinalizeInvoice = () => {
    setRowId(currentInvoiceRow?.id);
    setRowLoader(true);
    finalizeInvoice(currentInvoiceRow?.id)
      .then((res) => {
        if (res) {
          setRowDataOnFinalizingInvoice(res);
        }
      })
      .catch(() => {
        setRowLoader(false);
        setAnchorEl(null);
      });
  };

  const handleClickInvoiceAction = (type) => {
    switch (type) {
      case "sendInvoice": {
        handleClickSendInvoice();
        break;
      }
      case "finalizeInvoice": {
        handleClickFinalizeInvoice();
        break;
      }
      case "downloadPdf": {
        break;
      }
      case "duplicate": {
        break;
      }
      case "sendReminder": {
        break;
      }
      case "muteReminder": {
        break;
      }
      case "deleteDraft": {
        setOpenDeleteDraftModal(true);
        break;
      }
      case "viewCustomer": {
        currentInvoiceRow?.customer_id &&
          history.push(`/customers/${currentInvoiceRow.customer_id}`);
        break;
      }
      default:
        setAnchorEl(null);
    }
  };

  const invoiceActionList = [
    {
      label: sendInvoice,
      onClick: () => handleClickInvoiceAction("sendInvoice"),
      isLoadingSectionRequired: true,
      isLoading: showSendInvoiceLoader,
      disabled: showSendInvoiceLoader,
    },
    isFromDraftInvoice && {
      label: finalizeInvoiceMsg,
      onClick: () => handleClickInvoiceAction("finalizeInvoice"),
    },
    // {
    //   label: "Download PDF",
    //   onClick: () => handleClickInvoiceAction("downloadPdf"),
    // },
    // {
    //   label: "Edit Invoice",
    //   disabled: true,
    // },
    // {
    //   label: "Duplicate",
    //   onClick: () => handleClickInvoiceAction("duplicate"),
    //   disabled: true,
    // },
    // {
    //   label: "Send Reminder",
    //   onClick: () => handleClickInvoiceAction("sendReminder"),
    // },
    // {
    //   label: "Mute Reminder",
    //   onClick: () => handleClickInvoiceAction("muteReminder"),
    // },
    // {
    //   label: "Delete Draft",
    //   onClick: () => handleClickInvoiceAction("deleteDraft"),
    //   isDividerBelow: true,
    //   sx: { color: "#E74C3C" },
    // },
    // {
    //   label: "View customer",
    //   onClick: () => handleClickInvoiceAction("viewCustomer"),
    //   sx: { color: "#2A67FF" },
    // },
  ];

  const invoiceRowActions = () => {
    return invoiceActionList.map((action) => (
      <>
        {action?.label && (
          <React.Fragment key={action.label}>
            <ListItem disablePadding>
              <ListItemButton
                disabled={action.disabled}
                onClick={() => {
                  if (action.onClick) action.onClick();
                }}
              >
                <ListItemText primary={action.label} sx={{ ...action.sx }} />
                {action?.isLoadingSectionRequired && action?.isLoading && (
                  <img
                    style={{ marginLeft: "10px" }}
                    src={loaderSpinner}
                    alt="Loader"
                    width={15}
                    height={15}
                  />
                )}
              </ListItemButton>
            </ListItem>
            {action.isDividerBelow && <CustomDivider />}
          </React.Fragment>
        )}
      </>
    ));
  };

  return (
    <>
      <Box className="main-content">
        {(isDataAvailable || caughtErrorInStrapi) && (
          <CustomTable {...tableProps} />
        )}
      </Box>
      <CustomPopper
        disablePortal={true}
        open={openInvoicePopper}
        anchorEl={anchorEl}
        position="bottom-end"
        handleClose={() => setAnchorEl(null)}
      >
        <List className="invoice-list-action-popper">
          {invoiceRowActions()}
        </List>
      </CustomPopper>
      {openDeleteDraftModal && (
        <DeleteDraftModal
          openModal={openDeleteDraftModal}
          setOpenModal={(value) => setOpenDeleteDraftModal(value)}
        />
      )}
    </>
  );
}

export default InvoiceTable;
