import React, { useState, useEffect } from "react";
import {
  getAccountName,
  getBrandingPreviewColor,
  isFontAvailable,
  isLightColor,
  validateColor,
} from "@speed/common/src/components/constants";
import { Box } from "@mui/material";
import { QRCodeComponent } from "@speed/common/src/components/QRCode/QRCode";
import { bitCoinLogo } from "../images";
import Text from "@speed/common/src/components/Text/Text";
import { defaultLnValue, defaultOnchainValue, payVia } from "../messages";
import FooterWithLogo from "./FooterWithLogo";
import { useSelector } from "react-redux";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import PayViaOptions from "@speed/common/src/components/PayViaOptions";
import { lightningLabel } from "@speed/common/src/components/messages";
import DiagonalSection from "@speed/common/src/components/DiagonalSection";

const OneQRPreview = (props) => {
  const { brandingPageUI, qrCodeValue, className } = props;

  const currentAccount = useSelector((state) => state.auth.currentAccount);
  const paymentPageSettings = useSelector(
    (state) => state.common.paymentPageSettings
  );

  const checkForShowLogo = paymentPageSettings?.walletsLogo?.length > 0;

  const codeValue =
    qrCodeValue ||
    (brandingPageUI?.qr_type === "Lightning QR code"
      ? defaultLnValue
      : defaultOnchainValue);

  const accountName = getAccountName(currentAccount);

  const [fontFamily, setFontFamily] = useState("system-ui");
  const [loader, setLoader] = useState(false);
  const [textColor, setTextColor] = useState("#fff");

  const previewColor = getBrandingPreviewColor(brandingPageUI);

  const [backGroundColor, setBackGroundColor] = useState(null);

  const [accountImage, setAccountImage] = useState(null);

  useEffect(() => {
    if (brandingPageUI?.use_logo_or_icon) {
      if (brandingPageUI?.use_logo_or_icon === "logo") {
        setAccountImage(brandingPageUI?.logos?.original);
      } else if (brandingPageUI?.use_logo_or_icon === "icon") {
        setAccountImage(brandingPageUI?.icons?.original);
      }
    }
  }, [brandingPageUI]);

  useEffect(() => {
    if (brandingPageUI) {
      const { checkout_font_family, primary_color, primarySavedColor } =
        brandingPageUI;
      if (checkout_font_family !== "system-ui") {
        setLoader(true);
        isFontAvailable(checkout_font_family)
          .then((res) => {
            res && setLoader(false);
          })
          .catch(() => setLoader(false));
      }
      setFontFamily(checkout_font_family || "system-ui");
      const selectedColor = validateColor(primary_color)
        ? primary_color
        : primarySavedColor;
      isLightColor(selectedColor)
        ? setTextColor("#0a193e")
        : setTextColor("#fff");
    }
  }, [brandingPageUI]);

  useEffect(() => {
    previewColor
      ? setBackGroundColor(previewColor)
      : setBackGroundColor("#2250A1");
  }, [previewColor]);

  const fontStyle = {
    fontFamily: `${fontFamily} !important`,
    color: textColor,
  };

  const accountNameText = () => {
    return (
      <Text
        component="span"
        className="checkout-acc-name"
        size={16}
        sx={{
          maxWidth: "160px",
          whiteSpace: "nowrap",
          overflow: " hidden",
          marginLeft: "6px",
          color: "#0a193e",
          ...fontStyle.fontFamily,
        }}
        font="regular"
        variant="subtitle1"
      >
        {accountName.length > 18 ? (
          <TruncatedTextTooltip textValue={accountName} cellWidth="175px" />
        ) : (
          accountName
        )}
      </Text>
    );
  };

  return (
    <Box
      className="mobile-view-header"
      sx={{
        display: "grid",
        gridTemplateColumns: "1fr",
        padding: "0px !important",
      }}
    >
      <DiagonalSection color={backGroundColor} />
      <Box className="other-section">
        <Box
          className="qr-preview-section"
          sx={!checkForShowLogo ? { height: "450px !important" } : {}}
        >
          <Box className="one-qr-preview-header">
            <img
              src={bitCoinLogo}
              alt="logo"
              className="bit-coin-logo"
              height={35}
            />
            {(brandingPageUI?.qr_type === "Lightning QR code" ||
              props.QRType === "lnurl") && (
              <Text
                variant="subtitle1"
                className="grey-text"
                font="regular"
                size={16}
                sx={fontStyle}
              >
                {lightningLabel}
              </Text>
            )}
          </Box>
          <Box className="one-qr-preview-body">
            {accountImage && (
              <>
                <Box className="pay-logo-section">
                  <img src={accountImage} alt="logo" height={22} />
                  {brandingPageUI?.use_logo_or_icon === "icon"
                    ? accountNameText()
                    : ""}
                </Box>
              </>
            )}

            <QRCodeComponent
              value={codeValue}
              size={(accountImage && 225) || 270}
              className={className ? className : ""}
              from="oneQR"
            />
            {checkForShowLogo && (
              <Box className="pay-via-section">
                <Text
                  variant="subtitle1"
                  className="grey-text"
                  font="regular"
                  size={10}
                  align="center"
                  sx={fontStyle}
                >
                  {payVia}
                </Text>
                <PayViaOptions
                  from="oneQR"
                  className="pay-via-options"
                  classNameForLogo="pay-option-logo"
                  paymentPageSettings={paymentPageSettings}
                />
              </Box>
            )}
          </Box>
        </Box>
        {!paymentPageSettings?.show_payment_page_speed_branding && (
          <FooterWithLogo fontStyle={fontStyle} />
        )}
      </Box>
    </Box>
  );
};

export default OneQRPreview;
