import { createTheme } from "@mui/material";
import FontRegular from "./assets/fonts/Inter-Regular.woff";

export const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Inter-regular",
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        fontFamily: "Inter-regular",
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontFamily: "Inter-regular",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: "Inter-regular",
        },
      },
    },
    MuiCssBaseline: {
      "@global": {
        "@font-face": {
          fontFamily: "Inter-Regular",
          fontStyle: "normal",
          fontDisplay: "swap",
          fontWeight: 400,
          src: `url(${FontRegular}) format('woff')`,
          unicodeRange:
            "U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF",
        },
      },
    },
  },
});

export default theme;
