import React from "react";
import GetGuides from "./GetGuides";

const AffiliatePartnersGuide = () => {
  return (
    <GetGuides
      moduleName="affiliate_partners"
      redirectUrl="/partners/affiliate-partners"
      guideApiPath="affiliate-partners-guide"
      getStartedApiPath="Affiliate"
      needHelpGuidePath="docs/business-operations/affiliate-partners"
    />
  );
};

export default AffiliatePartnersGuide;
