import { Modal } from "@speed/common/src/components/Modal/Modal";
import { Box } from "@mui/material";
import { Button } from "@speed/common/src/components/Button/Button";
import {
  doneBtn,
  newApiKey,
  newApiKeyMsg,
  apiKeyLabel,
  keySafeText,
  learnMore,
} from "../messages";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Text } from "@speed/common/src/components/Text/Text";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import { clipboardElement } from "@speed/common/src/components/constants";

const KeyNote = ({ openApiKeyModal, handleCloseApiModal, apiKey }) => {
  const apiKeyModalContent = (
    <Box component="form" className="secret-key-popup">
      <Box className="safe-text">
        <Text className="default-text" font="Semibold" size={14} variant="h4">
          <b>{keySafeText}</b>
        </Text>
        <Text
          className="default-text"
          font="regular"
          size={14}
          variant="subtitle1"
        >
          {newApiKeyMsg}
        </Text>
        <CustomLink
          size="small"
          alignIcon="end"
          withIcon={<ArrowForwardIcon style={{ fontSize: "14px" }} />}
          href={`${process.env.REACT_APP_SPEED_API_URL}reference/manage-api-keys#best-practices-to-keep-them-safe`}
          target="_blank"
        >
          {learnMore}
        </CustomLink>
      </Box>
      <Box className="notes-data">
        <Box className="label-notes">
          <Text
            className="default-text1"
            font="Semibold"
            size={14}
            variant="h4"
          >
            {apiKeyLabel}
          </Text>
          {clipboardElement(apiKey, true, "notes-clipboard", "", true)}
        </Box>
      </Box>
    </Box>
  );

  return (
    <Modal
      maxWidth="xs"
      body={apiKeyModalContent}
      footer={
        <Button
          type="button"
          label={doneBtn}
          onClick={() => handleCloseApiModal()}
        />
      }
      className="api-popup"
      handleClose={() => handleCloseApiModal()}
      open={openApiKeyModal}
      title={newApiKey}
    />
  );
};
export default KeyNote;
