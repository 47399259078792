import React, { forwardRef, useEffect, useState } from "react";
import {
  AppBar,
  Box,
  Dialog,
  IconButton,
  Slide,
  Toolbar,
  Grid,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import WarningIcon from "@mui/icons-material/Warning";
import { sessionService } from "redux-react-session";
import { Cookies } from "react-cookie";
import Lottie from "lottie-react";
import Text from "@speed/common/src/components/Text/Text";
import Button from "@speed/common/src/components/Button/Button";
import { back, next } from "@speed/common/src/components/messages";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import { accountTitle, selectAnAccount } from "@speed/common/src/messages";
import AddEditAccount from "@speed/common/src/components/AddEditAccount";
import SelectAccount from "@speed/common/src/components/SelectAccount";
import { redirectWhenEntityNotFound } from "@speed/common/src/components/constants";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import {
  callAPIInterface,
  callStrapiAPIInterface,
  expireSession,
  getTimestamp,
  isOneClickIntegrationAvailable,
  oneClickCommonModalBody,
  oneClickIntegrationData,
} from "../constants";
import {
  _read,
  _write,
  accountAlreadyAffiliated,
  allow,
  cancel,
  confirmation,
  connectWithApp,
  connectionProgress,
  connectionValidationFailed,
  oneClickCancellationMessage,
  oneClickCancellationWarningMessage,
  oneClickInActiveAppMessage,
  oneClickInValidAppMessage,
  oneClickIntegrationMessage,
  onlyWalletAccountAffiliateError,
  permissionDetails,
  read,
  readWriteTextSymbol,
  yesGoBack,
} from "../messages";
import { generateToken } from "../../redux/auth/actions";
import { oneClickSuccessAnimation, speedIcon, syncArrow } from "../images";
import { showToast } from "../../redux/common/actions";
import oneClickConnectingAnimation from "./OneClickConnectingAnimation.json";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const OneClickIntegrationConnect = () => {
  const dispatch = useDispatch();
  const history = useSelector((state) => state.common.history);
  const user = useSelector((state) => state.auth.user);
  const fromOneClickDataAvailable = isOneClickIntegrationAvailable();

  const [goToNextPage, setGoToNextPage] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState();
  const [accountSelected, setAccountSelected] = useState();
  const [open, setOpen] = useState(false);
  const [openCloseModal, setOpenCloseModal] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [showBackDrop, setShowBackDrop] = useState(false);
  const [hasInvalidData, setHasInvalidData] = useState(false);
  const [hasStatusInvalid, setHasStatusInvalid] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [resourceBuckets, setResourceBuckets] = useState([]);
  const [resourcesFromApiCataloging, setResourcesFromApiCataloging] = useState(
    {}
  );
  const [data, setData] = useState();
  const [isListLoading, setIsListLoading] = useState(false);
  const cookies = new Cookies();

  const getDetails = () => {
    return callAPIInterface(
      "GET",
      `/manage-app/oauth/authorize?client_id=${
        oneClickIntegrationData()?.client_id
      }`
    );
  };

  useEffect(() => {
    if (!fromOneClickDataAvailable) {
      cookies.remove("oneclick-authorize-data");
      history.push("/dashboard");
    }
  }, []);

  const getResourcesFromApiCataloging = async () => {
    try {
      const response = await callStrapiAPIInterface(
        "GET",
        "/api/resources-api-catalogings"
      );
      const resources = response?.data?.reduce((acc, curr) => {
        if (curr.attributes?.resource_id) {
          acc[curr.attributes.resource_id] = curr.attributes.display_name;
        }
        return acc;
      }, {});
      setResourcesFromApiCataloging(resources);
    } catch (error) {}
  };

  const getRestrictedKeysResources = () => {
    callAPIInterface("GET", "/api-key/bucket-wise-resource")
      .then((response) => {
        setResourceBuckets(response?.buckets);
      })
      .catch(() => {});
  };

  useEffect(() => {
    if (fromOneClickDataAvailable) {
      const result =
        user?.accounts_details?.length > 0 &&
        user?.accounts_details.map((item) => item.account);
      setAccountList(result);
      setIsListLoading(false);
      getRestrictedKeysResources();
      getResourcesFromApiCataloging();
      getDetails()
        .then((res) => {
          if (res) {
            if (res.status !== "active") {
              setHasStatusInvalid(true);
              setHasInvalidData(true);
            } else {
              setData(res);
            }
          }
        })
        .catch((error) => {
          if (redirectWhenEntityNotFound(error)) {
            dispatch(
              showToast({
                isToastOpen: false,
              })
            );
            setHasInvalidData(true);
          }
        });
    }
  }, [fromOneClickDataAvailable, user?.accounts_details]);

  const handleModalClose = () => {
    cookies.remove("oneclick-authorize-data");
    history.push("/dashboard");
  };

  const setAccountDetails = async (id) => {
    try {
      const session = await sessionService.loadSession();
      session &&
        sessionService
          .saveSession({ ...session, speed_acc_id: id })
          .then(() => {
            callAPIInterface("GET", "/select-account").catch(() => {});
          });
    } catch (error) {
      throw new Error("session_expired");
    }
  };

  const handleAllow = async () => {
    try {
      setDisableSubmit(true);
      await setAccountDetails(accountSelected?.id);

      const allowData = { client_id: data?.client_id };
      const res = await callAPIInterface(
        "POST",
        "/manage-app/oauth/allow",
        JSON.stringify(allowData)
      );
      if (res) {
        const redirect_url = new URL(oneClickIntegrationData()?.redirect_url);
        cookies.remove("oneclick-authorize-data");

        redirect_url.searchParams.set("status", "SUCCESS");
        redirect_url.searchParams.set("code", res?.authentication_code);
        setTimeout(() => {
          window.location = redirect_url;
        }, 21000);
      }
    } catch (error) {
      if (error?.message === "session_expired") {
        expireSession();
      }
      setDisableSubmit(false);
    }
  };

  const handleAddAccount = async (accountData) => {
    if (accountData) {
      const { token_response: updatedTokens } = accountData;
      if (updatedTokens && Object.keys(updatedTokens).length !== 0) {
        const session = await sessionService.loadSession();
        await sessionService.saveSession({
          ...session,
          access_token: updatedTokens.access_token,
          id_token: updatedTokens.id_token,
          timestamp: getTimestamp(),
        });
      }
    }
  };

  const getNameOfResourceId = (id, name) => {
    return resourcesFromApiCataloging?.[id] || name;
  };

  const getReadAndWritePermissions = () => {
    const permissions = data?.permissions;
    const readPermissions = [];
    const writePermissions = [];

    if (!resourceBuckets?.length || !permissions)
      return { readPermissions, writePermissions };

    resourceBuckets.forEach((bucket) => {
      const bucketRead = permissions?.includes(bucket.id + _read);
      const bucketWrite = permissions?.includes(bucket.id + _write);
      bucket.resources?.forEach((resource) => {
        const resourceName = getNameOfResourceId(resource.id, resource.name);
        if (bucketWrite || permissions?.includes(resource.id + _write)) {
          writePermissions.push(resourceName);
        }
        if (bucketRead || permissions?.includes(resource.id + _read)) {
          readPermissions.push(resourceName);
        }
      });
    });

    return { readPermissions, writePermissions };
  };

  const requestBody = () => {
    if (!goToNextPage) {
      return (
        <Box className="margin-top30 content-center" flexDirection="column">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            marginBottom="30px"
            width={600}
          >
            <Text className="default-text" size={20} variant="h2">
              {selectAnAccount}
            </Text>
            <Button
              icon="addIcon"
              className="add-icon"
              label={accountTitle(false)}
              variant="outlined"
              color="primary"
              onClick={() => setOpen(true)}
            />
          </Box>
          {user && accountList && (
            <SelectAccount
              selectedRadio={selectedRadio}
              setSelectedRadio={setSelectedRadio}
              setAccountSelected={setAccountSelected}
              walletAcctMsg={onlyWalletAccountAffiliateError}
              alreadyConnectedMsg={accountAlreadyAffiliated}
              accountsList={accountList}
              isListLoading={isListLoading}
            />
          )}
          <AddEditAccount
            open={open}
            isEdit={false}
            handleClose={() => setOpen(false)}
            handleAddAccount={handleAddAccount}
            callAPIInterface={callAPIInterface}
            generateToken={generateToken}
            dispatch={dispatch}
            setIsListLoading={setIsListLoading}
          />
        </Box>
      );
    } else {
      const { readPermissions, writePermissions } =
        getReadAndWritePermissions();
      return (
        <Box
          display="flex"
          justifyContent="center"
          maxHeight="calc(100% - 70px)"
          overflow="hidden auto"
        >
          <Box width={600} className="margin-top30">
            <Box className="content-center" gap={3}>
              <img src={speedIcon} height={60} width={60} alt="speed" />
              {disableSubmit ? (
                <Lottie
                  animationData={oneClickConnectingAnimation}
                  loop={true}
                  autoplay={true}
                />
              ) : (
                <img src={syncArrow} height={23} width={37} alt="sync-arrow" />
              )}
              <img
                src={data?.logo_url}
                height={60}
                width={60}
                alt={data?.display_name}
              />
            </Box>
            {!disableSubmit && (
              <Text
                className="grey-text"
                sx={{ mt: 2, maxWidth: "60%", mx: "auto" }}
                size={14}
                align="center"
                variant="subtitle1"
                font="regular"
              >
                {oneClickIntegrationMessage(
                  data?.display_name,
                  accountSelected?.name
                )}
              </Text>
            )}
            <Box
              className="mt-40"
              border="1px solid #E4E9EE"
              bgcolor="#F7FAFC"
              p="14px 20px"
            >
              <Text size={18} font="regular">
                {disableSubmit ? connectionProgress : permissionDetails}
              </Text>
            </Box>
            <Box
              borderTop="0px !important"
              border="1px solid #E4E9EE"
              p={disableSubmit ? 0 : "20px"}
              display="flex"
              flexDirection="column"
              gap="10px"
            >
              {disableSubmit ? (
                <img
                  src={oneClickSuccessAnimation}
                  alt="One-Click-Success-Animation"
                  style={{
                    width: "600px",
                    height: "197px",
                    objectFit: "contain",
                    objectPosition: "left",
                  }}
                />
              ) : (
                <>
                  <Text
                    size={14}
                    className="email-verify-text"
                    font="regular"
                    sx={{ textTransform: "uppercase" }}
                  >
                    {readWriteTextSymbol}
                  </Text>
                  <Text
                    size={14}
                    className="grey-text"
                    variant="subtitle"
                    font="regular"
                  >
                    {writePermissions.length
                      ? writePermissions.join(", ")
                      : "---"}
                  </Text>
                  <Text
                    size={14}
                    className="email-verify-text"
                    font="regular"
                    sx={{ textTransform: "uppercase" }}
                  >
                    {read}
                  </Text>
                  <Text
                    size={14}
                    className="grey-text"
                    variant="subtitle"
                    font="regular"
                  >
                    {readPermissions.length
                      ? readPermissions.join(", ")
                      : "---"}
                  </Text>
                </>
              )}
            </Box>
          </Box>
        </Box>
      );
    }
  };

  const handleRedirectBack = () => {
    setOpenCloseModal(false);
    setShowBackDrop(true);
    const redirect_url = new URL(oneClickIntegrationData()?.redirect_url);
    cookies.remove("oneclick-authorize-data");
    redirect_url.searchParams.set("status", "ERROR");
    redirect_url.searchParams.set("reason", "USER_DENIED");
    window.location = redirect_url;
  };

  const closeModalFooter = (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      display="flex"
    >
      <Button
        style={{ width: "175px" }}
        label={cancel}
        onClick={() => setOpenCloseModal(false)}
        variant="outlined"
      />
      <Button
        style={{ width: "175px" }}
        color="error"
        label={yesGoBack}
        onClick={handleRedirectBack}
      />
    </Grid>
  );

  const closeModalBody = (
    <>
      <Box
        bgcolor="#F8F3DF"
        border="1px solid #E4E9EE"
        borderRadius="5px"
        p={2}
        mb="30px"
        display="flex"
        alignItems="center"
        gap={1}
      >
        <WarningIcon sx={{ color: "#F1C40F", height: 18, width: 18 }} />
        <Text font="regular" size={14} variant="body2">
          {oneClickCancellationWarningMessage(data?.display_name)}
        </Text>
      </Box>
      <Text className="default-text" font="regular" size={16} variant="body2">
        {oneClickCancellationMessage(data?.display_name)}
      </Text>
    </>
  );

  return (
    <Dialog
      disableEscapeKeyDown
      fullScreen
      open={true}
      TransitionComponent={Transition}
      className="fullscreen-modal"
      onClose={() => setOpenCloseModal(true)}
    >
      <AppBar sx={{ position: "relative" }} className="modal-app-bar">
        <Toolbar>
          {!disableSubmit && (
            <>
              <IconButton
                edge="start"
                color="inherit"
                onClick={() => setOpenCloseModal(true)}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Text
                size={20}
                font="semibold"
                sx={{ flex: 1 }}
                className="default-text divider"
                variant="h6"
              >
                {connectWithApp}
              </Text>
              {goToNextPage ? (
                <>
                  <Button
                    variant="outlined"
                    color="primary"
                    label={back}
                    onClick={() => setGoToNextPage(false)}
                    sx={{ mr: "18px" }}
                  />
                  <Button
                    label={allow}
                    variant="contained"
                    color="primary"
                    className="payment-link-btn"
                    onClick={handleAllow}
                  />
                </>
              ) : (
                <Button
                  label={next}
                  variant="contained"
                  color="primary"
                  className="payment-link-btn"
                  onClick={() => setGoToNextPage(true)}
                  disabled={!selectedRadio}
                />
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
      {requestBody()}
      <Modal
        maxWidth="xs"
        footer={closeModalFooter}
        body={closeModalBody}
        open={openCloseModal}
        title={confirmation}
        handleClose={() => setOpenCloseModal(false)}
      />
      <Modal
        maxWidth="xs"
        body={oneClickCommonModalBody(
          hasStatusInvalid
            ? oneClickInActiveAppMessage
            : oneClickInValidAppMessage
        )}
        open={hasInvalidData}
        handleClose={() => {
          setHasInvalidData(false);
          handleModalClose();
        }}
        title={connectionValidationFailed}
      />
      <BackdropLoader
        open={showBackDrop}
        alt="Loading..."
        customClass="loading-in-auth"
      />
    </Dialog>
  );
};

export default OneClickIntegrationConnect;
