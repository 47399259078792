import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ErrorIcon from "@mui/icons-material/Error";

import { Modal } from "@speed/common/src/components/Modal/Modal";
import { openIntercomChatModal } from "@speed/common/src/components/constants";
import Button from "@speed/common/src/components/Button/Button";
import Text from "@speed/common/src/components/Text/Text";
import { setAccountRestrictedModalData } from "../../redux/common/actions";
import {
  accountRestrictedTitle,
  checkAccountStatusLabel,
  contactSupport,
} from "../messages";

function AccountRestrictedModal() {
  const dispatch = useDispatch();
  const { history, accountRestrictedModalData } = useSelector(
    (state) => state.common
  );

  const { errorMessage, callbackErrorFn } = { ...accountRestrictedModalData };

  const handleCloseModal = () => {
    dispatch(
      setAccountRestrictedModalData({
        openRestrictedModal: false,
      })
    );
    callbackErrorFn?.();
  };

  const renderBodyContent = () => (
    <Grid container direction="row" justifyContent="center">
      <ErrorIcon className="error-icon" />
      <Text sx={{ textAlign: "center" }} size={18} variant="h4" font="regular">
        {errorMessage}
      </Text>
    </Grid>
  );

  const renderModalFooter = () => (
    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
      <Button
        variant="outlined"
        type="submit"
        label={contactSupport}
        onClick={() => {
          openIntercomChatModal();
          handleCloseModal();
        }}
      />
      <Button
        type="submit"
        label={checkAccountStatusLabel}
        onClick={() => {
          history.push("/settings/permission");
          handleCloseModal();
        }}
      />
    </Box>
  );

  return (
    <Modal
      open
      className="account-restricted-modal"
      maxWidth="sm"
      body={renderBodyContent()}
      footer={renderModalFooter()}
      handleClose={handleCloseModal}
      title={accountRestrictedTitle}
    />
  );
}

export default AccountRestrictedModal;
