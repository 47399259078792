import React from "react";
import { Grid } from "@mui/material";
import PreviewHeader from "./PreviewHeader";
import { useSelector } from "react-redux";
import PaymentLinkWebsiteView from "@speed/common/src/components/PaymentLinkWebsiteView";

const CommonMobileView = (props) => {
  const { marginTop, marginBottom, dummyDomain } = props;
  const { redirectToWebsitePreview } = useSelector((state) => state.common);
  return (
    <Grid
      container
      sx={{
        marginTop: `${marginTop} !important`,
        marginBottom: `${marginBottom} !important`,
        marginLeft: "5px",
        width: "800px",
      }}
    >
      <Grid item className="mobile-view-branding">
        <PreviewHeader from="mobile" dummyDomain={dummyDomain} />
        {redirectToWebsitePreview ? (
          <PaymentLinkWebsiteView from="mobile" />
        ) : (
          props.children
        )}
      </Grid>
    </Grid>
  );
};

export default CommonMobileView;
