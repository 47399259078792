import PhoneNumberInput from "@speed/common/src/components/PhoneNumberInput/PhoneNumberInput";
import Box from "@mui/material/Box";
import { carrierRateMsg, mobileNoLabel } from "../messages";
import Text from "@speed/common/src/components/Text/Text";

const WithMobile2FA = ({
  isFromAuth,
  setFieldValue,
  setTouched,
  touched,
  errors,
  values,
  countryISOCode,
  Countries,
  handleDynamicWidthForMenu,
}) => {
  return (
    <Box className="margin-top30">
      <PhoneNumberInput
        onOpen={() => {
          if (handleDynamicWidthForMenu) {
            handleDynamicWidthForMenu();
          }
        }}
        onClose={() => {
          document.body.style.overflow = "auto";
        }}
        name="mobileNo"
        options={Countries}
        error={touched.mobileNo && Boolean(errors.mobileNo)}
        selectValue={values.mobileDialCode}
        MenuProps={{
          id: isFromAuth
            ? "login-mfa-select-calling-code"
            : "mfa-select-calling-code",
          disableScrollLock: true,
          style: {
            overflow: "auto",
          },
        }}
        countryIsoCode={countryISOCode}
        inputValue={values.mobileNo}
        label={mobileNoLabel}
        showLabel={true}
        onChangeSelect={(value) => {
          setFieldValue(
            "mobileDialCode",
            typeof value === "object" ? value.calling_code : value
          );
        }}
        onBlur={() => setTouched({ ...touched, mobileNo: true })}
        onChange={(value) => {
          setTouched({ ...touched, mobileNo: false });
          setFieldValue("mobileNo", value);
        }}
      />
      <Text
        variant="subtitle1"
        font="regular"
        size={14}
        className="grey-text margin-top14"
      >
        {carrierRateMsg}
      </Text>
    </Box>
  );
};

export default WithMobile2FA;
