import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import { available, archived, all, addProduct } from "../messages";
import CommonHeader from "../Common/CommonHeader";
import ProductsList from "./ProductsList";
import {
  selectTabOnSearch,
  setQueryParamsForTab,
} from "@speed/common/src/components/constants";
import Button from "@speed/common/src/components/Button/Button";
import "../../assets/styles/payment-links.scss";
import "../../assets/styles/products.scss";
import history from "@speed/common/src/components/history";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setCaughtErrorInStrapi,
  setIsDataAvailable,
  setIsModalOpen,
} from "../../redux/common/actions";
import { callStrapiAPIInterface } from "../constants";
import GetStartedHeader from "@speed/common/src/components/GetStartedHeader/GetStartedHeader";

function Products() {
  const [selectedTab, setSelectedTab] = useState("1");
  const [isGetHeaderInfoLoading, setGetHeaderInfoLoading] = useState(true);
  const [productsGuide, setProductsGuide] = useState(null);
  const dispatch = useDispatch();

  const { isDataAvailable, caughtErrorInStrapi, isModalOpen } = useSelector(
    (state) => state.common
  );
  const liveMode = useSelector((state) => state.auth.liveMode);

  const { search } = useLocation();

  useEffect(() => {
    //used when user searches manually
    const name = new URLSearchParams(search).get("status");
    selectTabOnSearch(name || null, tabData, setSelectedTab); //To set the tab
    handleSetQueryParam(name || null); // To set query params

    dispatch(setIsDataAvailable(true));
    getProductsGuide();
    return () => {
      dispatch(setIsModalOpen(false));
      dispatch(setCaughtErrorInStrapi(false));
    };
  }, [liveMode]);

  const getProductsGuide = () => {
    setGetHeaderInfoLoading(true);
    callStrapiAPIInterface(
      "GET",
      "/api/product-guide?populate[header][populate][use_cases][populate]=*"
    )
      .then((response) => {
        setProductsGuide(response?.data?.attributes?.header);
        setGetHeaderInfoLoading(false);
      })
      .catch((error) => {
        dispatch(setCaughtErrorInStrapi(true));
        setGetHeaderInfoLoading(false);
      });
  };

  const tabData = [
    {
      label: all,
      value: "1",
      tabContent: (
        <ProductsList
          isOpen={isModalOpen}
          setOpenModal={(value) => {
            dispatch(setIsModalOpen(value));
          }}
        />
      ),
      tabName: "all",
    },
    {
      label: available,
      value: "2",
      tabContent: (
        <ProductsList
          isOpen={isModalOpen}
          tab="available"
          setOpenModal={(value) => dispatch(setIsModalOpen(value))}
        />
      ),
      tabName: "available",
    },
    {
      label: archived,
      value: "3",
      tabContent: (
        <ProductsList
          tab="archived"
          isOpen={isModalOpen}
          setOpenModal={(value) => dispatch(setIsModalOpen(value))}
        />
      ),
      tabName: "archived",
    },
  ];

  const handleChange = (_event, newValue) => {
    dispatch(setIsDataAvailable(true));
    setSelectedTab(newValue);
    const selectedTabName = setQueryParamsForTab(tabData, newValue);
    handleSetQueryParam(selectedTabName);
  };

  const handleSetQueryParam = (searchValue) => {
    history.push({
      pathname: "/products",
      search: searchValue ? `?status=${searchValue}` : null,
    });
  };

  const strapiHeaderCondition =
    !isDataAvailable &&
    !caughtErrorInStrapi &&
    selectedTab === "1" &&
    !isModalOpen;

  return (
    <Box
      bgcolor={strapiHeaderCondition ? "#f7fafc" : ""}
      className="section-wrapper payment-links-wrapper product-wrapper"
    >
      {strapiHeaderCondition ? (
        <GetStartedHeader
          setIsModalOpen={setIsModalOpen}
          loading={isGetHeaderInfoLoading}
          getHeaderInfo={productsGuide}
        />
      ) : (
        <>
          <CommonHeader />
          <CustomTab
            onChange={handleChange}
            tabData={tabData}
            selectedTab={selectedTab}
            noScroll={true}
            sx={{
              minHeight: "unset",
              "& .MuiTabPanel-root": {
                minHeight: "unset",
                padding: 0,
              },
            }}
          />
        </>
      )}

      {(!strapiHeaderCondition ||
        (!isDataAvailable && selectedTab !== "1") ||
        caughtErrorInStrapi) && (
        <Box className="action-btn-wrapper mui-fixed">
          <Button
            icon="addIcon"
            className="add-icon"
            label={addProduct}
            variant="outlined"
            color="primary"
            onClick={() => {
              dispatch(setIsModalOpen(true));
            }}
          />
        </Box>
      )}
    </Box>
  );
}

export default Products;
