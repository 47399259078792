export const Timezones = [
  {
    locale: "Etc/GMT+12",
    timezone: "(GMT-12:00) International Date Line West",
    abbreviation: "DST",
    timezoneName: "Dateline Standard Time",
  },
  {
    locale: "Pacific/Samoa",
    timezone: "(GMT-11:00) Midway Island, Samoa",
    abbreviation: "SST",
    timezoneName: "Samoa Standard Time",
  },
  {
    locale: "US/Hawaii",
    timezone: "(GMT-10:00) Hawaii",
    abbreviation: "HST",
    timezoneName: "Hawaiian Standard Time",
  },
  {
    locale: "US/Alaska",
    timezone: "(GMT-09:00) Alaska",
    abbreviation: "AKST",
    timezoneName: "Alaskan Standard Time",
  },
  {
    locale: "America/Los_Angeles",
    timezone: "(GMT-08:00) Pacific Time (US and Canada); Tijuana",
    abbreviation: "PST",
    timezoneName: "Pacific Standard Time",
  },
  {
    locale: "America/Denver",
    timezone: "(GMT-07:00) Mountain Time (US and Canada)",
    abbreviation: "MST",
    timezoneName: "Mountain Standard Time",
  },
  {
    locale: "America/Mazatlan",
    timezone: "(GMT-07:00) Chihuahua, La Paz, Mazatlan",
    abbreviation: "MST",
    timezoneName: "Mexico Standard Time 2",
  },
  {
    locale: "US/Arizona",
    timezone: "(GMT-07:00) Arizona",
    abbreviation: "MST",
    timezoneName: "U.S. Mountain Standard Time",
  },
  {
    locale: "Canada/Central",
    timezone: "(GMT-06:00) Central Time (US and Canada",
    abbreviation: "CST",
    timezoneName: "Central Standard Time",
  },
  {
    locale: "Canada/Saskatchewan",
    timezone: "(GMT-06:00) Saskatchewan",
    abbreviation: "CST",
    timezoneName: "Canada Central Standard Time",
  },
  {
    locale: "America/Mexico_City",
    timezone: "(GMT-06:00) Guadalajara, Mexico City, Monterrey",
    abbreviation: "MST",
    timezoneName: "Mexico Standard Time",
  },
  {
    locale: "America/Guatemala",
    timezone: "(GMT-06:00) Central America",
    abbreviation: "CST",
    timezoneName: "Central America Standard Time",
  },
  {
    locale: "Canada/Eastern",
    timezone: "(GMT-05:00) Eastern Time (US and Canada)",
    abbreviation: "EST",
    timezoneName: "Eastern Standard Time",
  },
  {
    locale: "America/Indianapolis",
    timezone: "(GMT-05:00) Indiana (East)",
    abbreviation: "EST",
    timezoneName: "U.S. Eastern Standard Time",
  },
  {
    locale: "America/Lima",
    timezone: "(GMT-05:00) Bogota, Lima, Quito",
    abbreviation: "-05",
    timezoneName: "S.A. Pacific Standard Time",
  },
  {
    locale: "Canada/Atlantic",
    timezone: "(GMT-04:00) Atlantic Time (Canada)",
    abbreviation: "AST",
    timezoneName: "Atlantic Standard Time",
  },
  {
    locale: "America/Caracas",
    timezone: "(GMT-04:00) Caracas, La Paz",
    abbreviation: "-04",
    timezoneName: "S.A. Western Standard Time    ",
  },
  {
    locale: "America/Santiago",
    timezone: "(GMT-04:00) Santiago",
    abbreviation: "-03",
    timezoneName: "Pacific S.A. Standard Time",
  },
  {
    locale: "Canada/Newfoundland",
    timezone: "(GMT-03:30) Newfoundland and Labrador",
    abbreviation: "NST",
    timezoneName: "Newfoundland and Labrador Standard Time",
  },
  {
    locale: "America/Argentina/Buenos_Aires",
    timezone: "(GMT-03:00) Buenos Aires, Georgetown",
    abbreviation: "-03",
    timezoneName: "S.A. Eastern Standard Time",
  },
  {
    locale: "Atlantic/Azores",
    timezone: "(GMT-01:00) Azores",
    abbreviation: "-01",
    timezoneName: "Azores Standard Time",
  },
  {
    locale: "Atlantic/Cape_Verde",
    timezone: "(GMT-01:00) Cape Verde Islands",
    abbreviation: "-01",
    timezoneName: "Cape Verde Standard Time",
  },
  {
    locale: "Europe/Dublin",
    timezone: "(GMT) Greenwich Mean Time: Dublin, Edinburgh, Lisbon, London",
    abbreviation: "GMT",
    timezoneName: "GMT Standard Time",
  },
  {
    locale: "Africa/Monrovia",
    timezone: "(GMT) Casablanca, Monrovia",
    abbreviation: "GMT",
    timezoneName: "Greenwich Standard Time",
  },
  {
    locale: "Europe/Budapest",
    timezone: "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague",
    abbreviation: "CET",
    timezoneName: "Central Europe Standard Time",
  },
  {
    locale: "Europe/Sarajevo",
    timezone: "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb",
    abbreviation: "CET",
    timezoneName: "Central European Standard Time",
  },
  {
    locale: "Europe/Brussels",
    timezone: "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris",
    abbreviation: "CET",
    timezoneName: "Romance Standard Time",
  },
  {
    locale: "Europe/Amsterdam",
    timezone: "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna",
    abbreviation: "CET",
    timezoneName: "W. Europe Standard Time",
  },
  {
    locale: "Africa/Brazzaville",
    timezone: "(GMT+01:00) West Central Africa",
    abbreviation: "WAT",
    timezoneName: "W. Central Africa Standard Time",
  },
  {
    locale: "Europe/Bucharest",
    timezone: "(GMT+02:00) Bucharest",
    abbreviation: "EET",
    timezoneName: "E. Europe Standard Time",
  },
  {
    locale: "Africa/Cairo",
    timezone: "(GMT+02:00) Cairo",
    abbreviation: "EET",
    timezoneName: "Egypt Standard Time",
  },
  {
    locale: "Europe/Helsinki",
    timezone: "(GMT+02:00) Helsinki, Kiev, Riga, Sofia, Tallinn, Vilnius",
    abbreviation: "EET",
    timezoneName: "FLE Standard Time",
  },
  {
    locale: "Europe/Athens",
    timezone: "(GMT+02:00) Athens, Istanbul, Minsk",
    abbreviation: "EET",
    timezoneName: "GTB Standard Time",
  },
  {
    locale: "Asia/Jerusalem",
    timezone: "(GMT+02:00) Jerusalem",
    abbreviation: "IST",
    timezoneName: "Israel Standard Time",
  },
  {
    locale: "Africa/Harare",
    timezone: "(GMT+02:00) Harare, Pretoria",
    abbreviation: "CAT",
    timezoneName: "South Africa Standard Time",
  },
  {
    locale: "Europe/Moscow",
    timezone: "(GMT+03:00) Moscow, St. Petersburg, Volgograd",
    abbreviation: "MSK",
    timezoneName: "Russian Standard Time",
  },
  {
    locale: "Asia/Kuwait",
    timezone: "(GMT+03:00) Kuwait, Riyadh",
    abbreviation: "+03",
    timezoneName: "Arab Standard Time",
  },
  {
    locale: "Africa/Nairobi",
    timezone: "(GMT+03:00) Nairobi",
    abbreviation: "EAT",
    timezoneName: "E. Africa Standard Time",
  },
  {
    locale: "Asia/Baghdad",
    timezone: "(GMT+03:00) Baghdad",
    abbreviation: "+03",
    timezoneName: "Arabic Standard Time",
  },
  {
    locale: "Asia/Tehran",
    timezone: "(GMT+03:30) Tehran",
    abbreviation: "+0330",
    timezoneName: "Iran Standard Time",
  },
  {
    locale: "Asia/Muscat",
    timezone: "(GMT+04:00) Abu Dhabi, Muscat",
    abbreviation: "+04",
    timezoneName: "Arabian Standard Time",
  },
  {
    locale: "Asia/Baku",
    timezone: "(GMT+04:00) Baku, Tbilisi, Yerevan",
    abbreviation: "+04",
    timezoneName: "Caucasus Standard Time",
  },
  {
    locale: "Asia/Kabul",
    timezone: "(GMT+04:30) Kabul",
    abbreviation: "+0430",
    timezoneName: "Transitional Islamic State of Afghanistan Standard Time",
  },
  {
    locale: "Asia/Yekaterinburg",
    timezone: "(GMT+05:00) Ekaterinburg",
    abbreviation: "+05",
    timezoneName: "Ekaterinburg Standard Time",
  },
  {
    locale: "Asia/Karachi",
    timezone: "(GMT+05:00) Islamabad, Karachi, Tashkent",
    abbreviation: "PKT",
    timezoneName: "West Asia Standard Time",
  },
  {
    locale: "Asia/Kolkata",
    timezone: "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi",
    abbreviation: "IST",
    timezoneName: "India Standard Time",
  },
  {
    locale: "Asia/Kathmandu",
    timezone: "(GMT+05:45) Kathmandu",
    abbreviation: "+0545",
    timezoneName: "Nepal Standard Time",
  },
  {
    locale: "Dhaka",
    timezone: "(GMT+06:00) Astana, Dhaka",
    abbreviation: "+06",
    timezoneName: "Central Asia Standard Time",
  },
  {
    locale: "Asia/Almaty",
    timezone: "(GMT+06:00) Almaty, Novosibirsk",
    abbreviation: "+06",
    timezoneName: "N. Central Asia Standard Time",
  },
  {
    locale: "Asia/Yangon",
    timezone: "(GMT+06:30) Yangon Rangoon",
    abbreviation: "+0630",
    timezoneName: "Myanmar Standard Time",
  },
  {
    locale: "Asia/Bangkok",
    timezone: "(GMT+07:00) Bangkok, Hanoi, Jakarta",
    abbreviation: "+07",
    timezoneName: "S.E. Asia Standard Time",
  },
  {
    locale: "Asia/Krasnoyarsk",
    timezone: "(GMT+07:00) Krasnoyarsk",
    abbreviation: "+07",
    timezoneName: "North Asia Standard Time",
  },
  {
    locale: "Asia/Chongqing",
    timezone: "(GMT+08:00) Beijing, Chongqing, Hong Kong SAR, Urumqi",
    abbreviation: "CST",
    timezoneName: "China Standard Time",
  },
  {
    locale: "Asia/Kuala_Lumpur",
    timezone: "(GMT+08:00) Kuala Lumpur, Singapore",
    abbreviation: "+08",
    timezoneName: "Singapore Standard Time",
  },
  {
    locale: "Asia/Taipei",
    timezone: "(GMT+08:00) Taipei",
    abbreviation: "CST",
    timezoneName: "Taipei Standard Time",
  },
  {
    locale: "Australia/Perth",
    timezone: "(GMT+08:00) Perth",
    abbreviation: "AWST",
    timezoneName: "W. Australia Standard Time",
  },
  {
    locale: "Asia/Irkutsk",
    timezone: "(GMT+08:00) Irkutsk, Ulaanbaatar",
    abbreviation: "+08",
    timezoneName: "North Asia East Standard Time",
  },
  {
    locale: "Asia/Seoul",
    timezone: "(GMT+09:00) Seoul",
    abbreviation: "KST",
    timezoneName: "Korea Standard Time",
  },
  {
    locale: "Asia/Tokyo",
    timezone: "(GMT+09:00) Osaka, Sapporo, Tokyo",
    abbreviation: "JST",
    timezoneName: "Tokyo Standard Time",
  },
  {
    locale: "Asia/Yakutsk",
    timezone: "(GMT+09:00) Yakutsk",
    abbreviation: "+09",
    timezoneName: "Yakutsk Standard Time",
  },
  {
    locale: "Australia/Darwin",
    timezone: "(GMT+09:30) Darwin",
    abbreviation: "ACST",
    timezoneName: "A.U.S. Central Standard Time",
  },
  {
    locale: "Australia/Adelaide",
    timezone: "(GMT+09:30) Adelaide",
    abbreviation: "ACDT",
    timezoneName: "Cen. Australia Standard Time",
  },
  {
    locale: "Australia/Canberra",
    timezone: "(GMT+10:00) Canberra, Melbourne, Sydney",
    abbreviation: "AEDT",
    timezoneName: "A.U.S. Eastern Standard Time",
  },
  {
    locale: "Australia/Brisbane",
    timezone: "(GMT+10:00) Brisbane",
    abbreviation: "AEST",
    timezoneName: "E. Australia Standard Time",
  },
  {
    locale: "Australia/Hobart",
    timezone: "(GMT+10:00) Hobart",
    abbreviation: "AEDT",
    timezoneName: "Tasmania Standard Time",
  },
  {
    locale: "Asia/Vladivostok",
    timezone: "(GMT+10:00) Vladivostok",
    abbreviation: "+10",
    timezoneName: "Vladivostok Standard Time",
  },
  {
    locale: "Pacific/Guam",
    timezone: "(GMT+10:00) Guam, Port Moresby",
    abbreviation: "ChST",
    timezoneName: "West Pacific Standard Time",
  },
  {
    locale: "Asia/Magadan",
    timezone: "(GMT+11:00) Magadan, Solomon Islands, New Caledonia",
    abbreviation: "+11",
    timezoneName: "Central Pacific Standard Time",
  },
  {
    locale: "Asia/Kamchatka",
    timezone: "(GMT+12:00) Fiji Islands, Kamchatka, Marshall Islands",
    abbreviation: "+12",
    timezoneName: "Fiji Islands Standard Time",
  },
  {
    locale: "Pacific/Auckland",
    timezone: "(GMT+12:00) Auckland, Wellington",
    abbreviation: "NZDT",
    timezoneName: "New Zealand Standard Time",
  },
  {
    locale: "Pacific/Tongatapu",
    timezone: "(GMT+13:00) Nuku'alofa",
    abbreviation: "+13",
    timezoneName: "Tonga Standard Time",
  },
  {
    locale: "America/Sao_Paulo",
    timezone: "(GMT-03:00) Brasilia",
    abbreviation: "-02",
    timezoneName: "E. South America Standard Time",
  },
  {
    locale: "America/Godthab",
    timezone: "(GMT-03:00) Greenland",
    abbreviation: "-03",
    timezoneName: "Greenland Standard Time",
  },
  {
    locale: "Atlantic/South_Georgia",
    timezone: "(GMT-02:00) Mid-Atlantic",
    abbreviation: "-02",
    timezoneName: "Mid-Atlantic Standard Time",
  },
  {
    locale: "Asia/Colombo",
    timezone: "(GMT+06:00) Sri Jayawardenepura",
    abbreviation: "+0530",
    timezoneName: "Sri Lanka Standard Time",
  },
];
