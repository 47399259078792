import { Box, Card, CardHeader, TableCell, TableRow } from "@mui/material";
import CustomTable from "@speed/common/src/components/Table/Table";
import Text from "@speed/common/src/components/Text/Text";
import Button from "@speed/common/src/components/Button/Button";
import {
  convertExponentialToDecimal,
  satsToBtcAmount,
} from "@speed/common/src/components/constants";
import {
  payments,
  payouts,
  transactions,
  withdraws,
  viewAll,
} from "../messages";
import { ShowNoDataCard } from "./utilities";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import { dateTimeFormatInApp } from "../constants";

const TableCellComp = ({ children, customCellStyle }) => (
  <TableCell
    style={{
      fontSize: "14px",
      lineHeight: "14px",
      padding: "16px 12px",
      ...customCellStyle,
    }}
  >
    {children}
  </TableCell>
);

const prepareRowData = (row, module) => {
  switch (module) {
    case payments:
    case withdraws:
      return (
        <TableRow key={row.id}>
          <TableCellComp>
            {satsToBtcAmount(row.target_amount_paid ?? row.target_amount)}
          </TableCellComp>
          <TableCellComp>{dateTimeFormatInApp(row.created)}</TableCellComp>
        </TableRow>
      );
    case payouts:
      return (
        <TableRow key={row.id}>
          <TableCellComp>
            {convertExponentialToDecimal(+row?.amount?.toFixed(8))}
          </TableCellComp>
          <TableCellComp customCellStyle={{ padding: "14px 12px" }}>
            <TruncatedTextTooltip
              textValue={row.wallet?.name || "-"}
              cellWidth="90px"
              textProps={{
                size: 14,
                className: "truncate-text-lineheight",
              }}
            />
          </TableCellComp>
          <TableCellComp>{dateTimeFormatInApp(row.created)}</TableCellComp>
        </TableRow>
      );
    case transactions:
      return (
        <TableRow key={row.id}>
          <TableCellComp>{row.type}</TableCellComp>
          <TableCellComp customCellStyle={{ textAlign: "right" }}>
            {satsToBtcAmount(row.amount)}
          </TableCellComp>
          <TableCellComp customCellStyle={{ textAlign: "right" }}>
            {satsToBtcAmount(row.net)}
          </TableCellComp>
          <TableCellComp customCellStyle={{ textAlign: "right" }}>
            {dateTimeFormatInApp(row.created)}
          </TableCellComp>
        </TableRow>
      );
    default:
      return [];
  }
};

const RecentSummaryTable = ({
  columns,
  rows,
  chartTitle,
  handleViewAll,
  showViewAll = true,
  module,
  width,
  loader,
}) => {
  const columnsData = columns.map((column) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{ minWidth: column.width, paddingLeft: column.paddingLeft }}
      style={{ fontSize: "12px", padding: "12px" }}
    >
      {column.title}{" "}
    </TableCell>
  ));

  const rowData =
    rows &&
    rows.map((row) => {
      return prepareRowData(row, module);
    });

  const tableProps = {
    columns: columnsData,
    rows: rowData,
    className: "link-table",
    hideNoData: true,
    loadingRowsCount: 5,
  };

  return (
    <Card
      sx={{
        width: width || "370px",
        padding: "0 21px 22px 26px",
      }}
    >
      <CardHeader
        title={
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text
              variant="h2"
              font="semibold"
              size={20}
              className="dashboard-title"
            >
              {chartTitle}
            </Text>

            {showViewAll && (
              <Button
                variant="outlined"
                style={{ fontSize: "14px", padding: "8px 16px" }}
                label={viewAll}
                onClick={handleViewAll}
              />
            )}
          </Box>
        }
        sx={{ padding: "22px 0" }}
      />
      <Box className="main-content">
        <CustomTable {...tableProps} />
        {!rowData.length && !loader && ShowNoDataCard(module)}
      </Box>
    </Card>
  );
};

export default RecentSummaryTable;
