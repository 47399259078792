import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import { all } from "../../messages";
import PayoutWalletList from "./PayoutWalletList";
import {
  linkStatus,
  selectTabOnSearch,
  setQueryParamsForTab,
} from "@speed/common/src/components/constants";
import { Box } from "@mui/material";

const PayoutWallets = () => {
  const search = useLocation().search;
  const [selectedTab, setSelectedTab] = useState("1");
  const history = useSelector((state) => state.common.history);
  useEffect(() => {
    const name = new URLSearchParams(search).get("status");
    selectTabOnSearch(name || null, tabData, setSelectedTab);
    handleSetQueryParam(name || null);
  }, []);

  const tabData = [
    {
      label: all,
      value: "1",
      tabContent: <PayoutWalletList />,
      tabName: "all",
    },
    {
      label: linkStatus.verified.label,
      value: "2",
      tabContent: <PayoutWalletList tab={linkStatus.verified.value} />,
      tabName: linkStatus.verified.value,
    },
    {
      label: linkStatus.unverified.label,
      value: "3",
      tabContent: <PayoutWalletList tab={linkStatus.unverified.value} />,
      tabName: linkStatus.unverified.value,
    },
    {
      label: linkStatus.deactivated.label,
      value: "4",
      tabContent: <PayoutWalletList tab={linkStatus.deactivated.value} />,
      tabName: linkStatus.deactivated.value,
    },
  ];

  const handleChange = (_event, newValue) => {
    setSelectedTab(newValue);
    const selectedTabName = setQueryParamsForTab(tabData, newValue);
    handleSetQueryParam(selectedTabName);
  };

  const handleSetQueryParam = (searchValue) => {
    history.push({
      pathname: "/settings/payout-wallets",
      search: searchValue ? `?status=${searchValue}` : null,
    });
  };

  return (
    <Box className="payment-links-wrapper">
      <CustomTab
        onChange={handleChange}
        tabData={tabData}
        selectedTab={selectedTab}
        sx={{
          minHeight: "unset",
          "& .MuiTabPanel-root": {
            minHeight: "unset",
            padding: 0,
          },
        }}
      />
    </Box>
  );
};

export default PayoutWallets;
