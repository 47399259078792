import { maintenanceInProgress } from "@speed/common/src/components/images";
import React, { useEffect } from "react";
import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import {
  underMaintenance,
  underMaintenanceDesc,
  underMaintenanceInfo,
  underMaintenanceStatus,
} from "@speed/common/src/messages";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import history from "@speed/common/src/components/history";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { fromDate, toDate } from "../messages";

const UnderMaintenance = ({
  title = underMaintenance,
  subtitle = underMaintenanceDesc,
  fromModal = false,
}) => {
  const maintenanceDetails = useSelector(
    (state) => state.common.maintenanceDetails
  );

  useEffect(() => {
    if (maintenanceDetails?.status !== underMaintenanceStatus) {
      history.push("/my-assets");
    }
  }, []);

  const displayDateTime = (title, date) => {
    return (
      <Box display="flex" flexDirection="column" alignItems="center">
        <Text
          className="grey-text"
          size={16}
          font="regular"
          variant="subtitle1"
        >
          {title}
        </Text>
        <Text className="default-text" size={16} font="semibold">
          {` ${moment(date).format("DD/MM/YYYY")}`}
        </Text>
        <Text
          className="default-text"
          size={16}
          font="semibold"
          sx={{ mb: "16px" }}
        >
          {`${moment(date).format("LT")}`}
        </Text>
      </Box>
    );
  };

  return (
    <Box className="under-maintenance-container">
      <Box className="under-maintenance-section">
        <img src={maintenanceInProgress} alt="under-maintenance-img" />
        <Text size={22} font="bold" sx={{ textAlign: "center", mt: "16px" }}>
          {title}
        </Text>
        <Text
          size={16}
          font="regular"
          variant="subtitle1"
          sx={{ textAlign: "center", mt: "10px" }}
          className="grey-text"
        >
          {subtitle}
        </Text>
        {fromModal && (
          <Box display="flex" sx={{ margin: "10px" }}>
            {displayDateTime(
              fromDate,
              maintenanceDetails?.maintenance_data?.start_time
            )}
            <CustomDivider
              orientation="vertical"
              sx={{ margin: "-8px 32px" }}
            />
            {displayDateTime(
              toDate,
              maintenanceDetails?.maintenance_data?.end_time
            )}
          </Box>
        )}
      </Box>
      {fromModal && (
        <Text
          sx={{ width: "480px", mb: "60px" }}
          size={14}
          font="regular"
          variant="body2"
          className="grey-text"
        >
          {underMaintenanceInfo}
        </Text>
      )}
    </Box>
  );
};

export default UnderMaintenance;
