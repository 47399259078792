import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  TableCell,
  TableRow,
} from "@mui/material";
import CustomTable from "@speed/common/src/components/Table/Table";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import { clipboardElement } from "@speed/common/src/components/constants";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import {
  callAPIInterface,
  concatedMobileNumber,
  dateTimeFormatInApp,
  noOfRecords,
} from "../constants";
import { useCallback, useEffect, useState } from "react";
import { deleteText } from "../messages";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import { useDispatch, useSelector } from "react-redux";
import DeleteCustomer from "./DeleteCustomer";
import { formatPhoneNumberIntl } from "react-phone-number-input";
import { setIsDataAvailable } from "../../redux/common/actions";
import HorizontalSearchPageTable from "../Common/HorizontalSearchPageTable";

const columns = [
  {
    title: "Customer ID",
    width: "170px",
    align: "left",
    paddingLeft: "38px !important",
  },
  { title: "Name", width: "300px", align: "left" },
  { title: "Email", width: "200px", align: "left" },
  { title: "Phone", width: "200px", align: "left" },
  { title: "Created on", width: "250px", align: "left" },
  // { title: "Actions", width: "150px", align: "center" },
];

const CustomerTable = ({
  getModuleSearchResultData,
  inputString,
  searchResultData,
  searchQueryUrl,
  showHorizontalTable = false,
  totalNumOfSearchResults,
}) => {
  const queryParam = "?limit=" + noOfRecords;
  const [anchorEl, setAnchorEl] = useState(null);
  const [hasMoreCustomers, setHasMoreCustomers] = useState(true);
  const [rows, setRows] = useState([]);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [customerTableRowSkeleton, setCustomerTableRowSkeleton] =
    useState(false);
  const [openDeleteCustomerModal, setOpenDeleteCustomerModal] = useState(false);
  const dispatch = useDispatch();
  const { liveMode } = useSelector((state) => state.auth);
  const { history, isDataAvailable, caughtErrorInStrapi } = useSelector(
    (state) => state.common
  );
  const showActionButton = false;
  const openPopper = Boolean(anchorEl);

  const fromSearchPage = history.location.pathname === "/search";

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const loadMoreCustomers = useCallback(() => {
    getCustomerList(rows, queryParams);
  }, [rows]);

  useEffect(() => {
    setRows([]);
    setHasMoreCustomers(true);
    getCustomerList([], queryParam);
  }, [liveMode]);

  const getCustomerList = (lines, params) => {
    if (fromSearchPage) {
      if (showHorizontalTable) {
        setRows(searchResultData);
      } else {
        setCustomerTableRowSkeleton(true);
        getModuleSearchResultData(searchResultData)
          .then((result) => {
            setCustomerTableRowSkeleton(false);
            if (!result.hasMore) {
              setHasMoreCustomers(false);
            }
            setRows(result?.concatedResultData);
          })
          .catch(() => {
            setCustomerTableRowSkeleton(false);
          });
      }
    } else {
      setCustomerTableRowSkeleton(true);
      callAPIInterface("GET", "/customers" + params, {})
        .then((res) => {
          if (res) {
            setCustomerTableRowSkeleton(false);
            if (!res.has_more) {
              setHasMoreCustomers(false);
            } else {
              setQueryParams(
                queryParam +
                  "&ending_before=" +
                  res.data[res.data.length - 1].id
              );
            }
            setRows(lines.concat(res.data));
            dispatch(setIsDataAvailable(res.data.length > 0));
          }
        })
        .catch((_err) => {
          setCustomerTableRowSkeleton(false);
        });
    }
  };

  const columnsData = columns.map((column) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{ width: column.width, paddingLeft: column.paddingLeft }}
    >
      {column.title}
    </TableCell>
  ));

  const rowsData = rows?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/customers/${rowItem.id}`)}
      >
        <TableCell sx={{ paddingLeft: "38px !important" }}>
          {clipboardElement(
            rowItem.id,
            true,
            "payments-listing-id-clipboard",
            "inputBox",
            false
          )}
        </TableCell>
        <TableCell>
          <TruncatedTextTooltip
            textValue={rowItem.name}
            boxProps={{
              sx: {
                display: "grid",
              },
            }}
          />
        </TableCell>
        <TableCell>{rowItem.email}</TableCell>
        <TableCell>
          {formatPhoneNumberIntl(
            concatedMobileNumber(rowItem?.calling_code, rowItem?.phone)
          ) || "-"}
        </TableCell>
        <TableCell>{dateTimeFormatInApp(rowItem.created)}</TableCell>
        {showActionButton && (
          <TableCell style={{ textAlign: "center" }}>
            <MoreHorizIcon
              className="horizontal-dots-icon"
              tabIndex={0}
              onClick={(event) => {
                event.stopPropagation();
                handleClick(event);
              }}
            />
          </TableCell>
        )}
      </TableRow>
    );
  });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: customerTableRowSkeleton,
    hasMore: hasMoreCustomers,
    loadMore: loadMoreCustomers,
    className: "customer-table",
  };

  const handleDeleteClick = () => {
    setOpenDeleteCustomerModal(true);
    setAnchorEl(null);
  };

  return (
    <>
      <Box className="main-content">
        {showHorizontalTable && fromSearchPage ? (
          <HorizontalSearchPageTable
            moduleName="customer"
            columns={columns}
            rows={tableProps.rows}
            searchQueryUrl={searchQueryUrl}
            inputString={inputString}
            totalNumOfSearchResults={totalNumOfSearchResults}
          />
        ) : (
          (isDataAvailable || caughtErrorInStrapi) && (
            <CustomTable {...tableProps} />
          )
        )}
      </Box>
      <CustomPopper
        disablePortal={true}
        open={openPopper}
        anchorEl={anchorEl}
        position="bottom"
        handleClose={() => setAnchorEl(null)}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton onClick={() => handleDeleteClick()}>
              <ListItemText primary={deleteText} className="text-danger" />
            </ListItemButton>
          </ListItem>
        </List>
      </CustomPopper>
      {openDeleteCustomerModal && (
        <DeleteCustomer
          openModal={openDeleteCustomerModal}
          setOpenModal={(value) => setOpenDeleteCustomerModal(value)}
        />
      )}
    </>
  );
};

export default CustomerTable;
