import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import { useLocation } from "react-router-dom";
import EmailNotification from "./EmailNotification";
import PageLoader from "@speed/common/src/components/PageLoader";
import { callAPIInterface } from "../../constants";
import {
  isSameArray,
  selectTabOnSearch,
  setQueryParamsForTab,
} from "@speed/common/src/components/constants";

const notificationSettingModuleSeqence = [
  "Receive Payments",
  "Send Payments",
  "Balances",
  "Swaps",
  "Transfers",
  "Partners",
];

function Notifications() {
  const [selectedTab, setSelectedTab] = useState("1");
  const [loader, setLoader] = useState(true);
  const [tabData, setTabData] = useState([]);
  const [formNotificationPreferences, setFormNotificationPreferences] =
    useState();
  const [formData, setFormData] = useState();
  const { search } = useLocation();
  const name = new URLSearchParams(search).get("module");

  const history = useSelector((state) => state.common.history);
  const settingEventModuleTabs = (eventData) => {
    const eventTabData = eventData?.map((tabData, index) => {
      return {
        label: tabData.module,
        tabName: tabData.module,
        value: (index + 1).toString(),
        tabContent: (
          <EmailNotification
            isChangedSettings={
              !isSameArray(formNotificationPreferences, eventData)
            }
            moduleName={tabData.module}
            moduleEventData={tabData}
            currentFormData={eventData}
            formNotificationPreferences={formNotificationPreferences}
            setFormNotificationPreferences={setFormNotificationPreferences}
            getUpdatedNotificationDetails={getUpdatedNotificationDetails}
            settingEventModuleTabs={settingEventModuleTabs}
            setFormData={setFormData}
          />
        ),
      };
    });
    setTabData(eventTabData);
  };

  const getUserSavedNotificationEventDetails = () => {
    return callAPIInterface("GET", "/user/notification/preference")
      .then((response) => {
        return response.user_notification_preferences;
      })
      .catch(() => {
        return null;
      });
  };

  const getNotificationKeyNameToFilter = (eventObj) =>
    eventObj.notification_event_id ? "notification_event_id" : "id";

  const getUpdatedNotificationDetails = (
    defaultEventArray,
    userSavedSettings
  ) => {
    let finalUpdatedArray = defaultEventArray;
    userSavedSettings?.forEach((userEventDetails) => {
      const eventId =
        userEventDetails[getNotificationKeyNameToFilter(userEventDetails)];

      if (eventId) {
        finalUpdatedArray = finalUpdatedArray?.map(
          (defaultEventModuleDetails) => {
            const updatedModule = defaultEventModuleDetails.sub_module?.map(
              (subModuleDetails) => {
                const updatedSubModule = subModuleDetails.events?.map(
                  (defaultEventDetails) => {
                    let updatedEventObj = defaultEventDetails;
                    if (
                      defaultEventDetails[
                        getNotificationKeyNameToFilter(defaultEventDetails)
                      ] === eventId
                    ) {
                      updatedEventObj = {
                        ...defaultEventDetails,
                        ...(userEventDetails.notification_event_id && {
                          notification_event_id:
                            userEventDetails.notification_event_id,
                        }),
                        id: userEventDetails.id,
                        email_value: userEventDetails.email_value,
                        sms_value: userEventDetails.sms_value,
                        ...(userEventDetails.trigger_frequency && {
                          trigger_frequency: userEventDetails.trigger_frequency,
                        }),
                      };
                    }
                    return updatedEventObj;
                  }
                );
                return { ...subModuleDetails, events: updatedSubModule };
              }
            );
            return { ...defaultEventModuleDetails, sub_module: updatedModule };
          }
        );
      }
    });
    return finalUpdatedArray;
  };

  const getUpdatedModuleSequenceResponse = (oldResponse) => {
    let updatedSequenceResponse = Array(oldResponse?.length).fill(undefined);
    oldResponse?.forEach((moduleData) => {
      const indexOfFoundModule = notificationSettingModuleSeqence.indexOf(
        moduleData.module
      );
      if (indexOfFoundModule === -1) updatedSequenceResponse.push(moduleData);
      else updatedSequenceResponse[indexOfFoundModule] = moduleData;
    });
    return updatedSequenceResponse.filter(Boolean);
  };

  const getDefaultSpeedNotificationEventDetails = () => {
    setLoader(true);
    callAPIInterface("GET", "/user/notification/event")
      .then(async (response) => {
        if (response) {
          const defaultNotificationSettings = getUpdatedModuleSequenceResponse(
            response.notification_settings
          );
          const userSavedSettings =
            await getUserSavedNotificationEventDetails();

          if (userSavedSettings) {
            const updatedEventDetails = getUpdatedNotificationDetails(
              defaultNotificationSettings,
              userSavedSettings
            );
            setFormData(updatedEventDetails);
            settingEventModuleTabs(updatedEventDetails);
            setFormNotificationPreferences(updatedEventDetails);
          } else {
            setFormData(defaultNotificationSettings);
            settingEventModuleTabs(defaultNotificationSettings);
            setFormNotificationPreferences(defaultNotificationSettings);
          }
          setLoader(false);
        }
      })
      .catch(() => {});
  };

  const handleSetQueryParam = (searchValue) => {
    history.push({
      pathname: "/settings/notifications",
      search: searchValue ? `?module=${searchValue}` : null,
    });
  };

  const handleChangeTabs = (_event, newValue) => {
    setSelectedTab(newValue);
    const selectedTabName = setQueryParamsForTab(tabData, newValue);
    handleSetQueryParam(selectedTabName);
    settingEventModuleTabs(formData);
  };

  useEffect(() => {
    if (name && tabData?.length) {
      selectTabOnSearch(name || null, tabData, setSelectedTab);
      handleSetQueryParam(name);
    }
    if (formNotificationPreferences)
      settingEventModuleTabs(formNotificationPreferences);
    else getDefaultSpeedNotificationEventDetails();
  }, [formNotificationPreferences]);

  return loader ? (
    <PageLoader
      open={loader}
      alt="loading..."
      customClass="page-loader"
      sx={{ position: "absolute" }}
    />
  ) : (
    <CustomTab
      onChange={handleChangeTabs}
      tabData={tabData}
      selectedTab={selectedTab}
      className="notifications-tab"
    />
  );
}

export default Notifications;
