import React, { useState } from "react";
import { Box } from "@mui/material";
import { CustomTab } from "@speed/common/src/components/Tab/Tab";
import { all, failed, succeeded } from "../messages";
import { useSelector } from "react-redux";
import CommonHeader from "../Common/CommonHeader";
import LogsData from "./LogsData";

const Logs = () => {
  const [selectedTab, setSelectedTab] = useState("1");
  const { history } = useSelector((state) => state.common);
  const [startDateTimeStamp, setStartDateTimeStamp] = useState(null);
  const [endDateTimeStamp, setEndDateTimeStamp] = useState(null);
  const [checkedValues, setCheckedValues] = useState([]);
  const [apiEndpointData, setApiEndpointData] = useState("");
  const [selectedDateItem, setSelectedDateItem] = useState("");

  const commonProps = {
    startDateTimeStamp,
    setStartDateTimeStamp,
    endDateTimeStamp,
    setEndDateTimeStamp,
    checkedValues,
    setCheckedValues,
    apiEndpointData,
    setApiEndpointData,
    selectedDateItem,
    setSelectedDateItem,
  };

  const handleChange = (_event, newValue) => {
    setSelectedTab(newValue);
  };

  const tabData = [
    {
      label: all,
      value: "1",
      tabContent: <LogsData tab="All" {...commonProps} />,
    },
    {
      label: succeeded,
      value: "2",
      tabContent: <LogsData tab="success" {...commonProps} />,
    },
    {
      label: failed,
      value: "3",
      tabContent: <LogsData tab="error" {...commonProps} />,
    },
  ];

  return (
    <Box className="section-wrapper">
      <CommonHeader />
      <CustomTab
        onChange={handleChange}
        tabData={tabData}
        selectedTab={selectedTab}
        setTabRoute={(routeUrl) => history.push(routeUrl)}
        sx={{
          position: "relative",
          minHeight: "unset",
          "& .MuiTabPanel-root": { minHeight: "unset", padding: 0 },
        }}
        height="45px"
      />
    </Box>
  );
};

export default Logs;
