import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  noOfRecords,
  renderWebhookStatus,
  callAPIInterface,
  getStatusCode,
  dateTimeFormatInApp,
} from "../constants";
import { Box } from "@mui/system";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
// import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import NoDataAvailable from "@speed/common/src/components/NoDataAvailable";
import {
  succeeded,
  failed,
  fail,
  success,
  HttpStatusCodeLabel,
  RequestEndpointLabel,
  emptyWebhookSubText,
  viewAllLinesLabel,
  hideLinesLabel,
} from "../messages";
import { emptySubText } from "@speed/common/src/components/messages";
import { emptyWebhook } from "../images";
import jsonDataToShow from "../jsonLogic";
import CustomButton from "@speed/common/src/components/Button/Button";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import TruncatedTextTooltip from "@speed/common/src/components/TruncatedTextTooltip";
import AttemptTableSkeleton from "./AttemptTableSkeleton";

function AllEvents({
  version,
  eventId,
  tab,
  handleAccordionExpand,
  handleRequestEndpoint,
  requestEndpoint,
  accordionExpand,
  setWebhookEvent,
  webhookEvent,
  from,
  platformAccID = "",
}) {
  const liveMode = useSelector((state) => state.auth.liveMode);
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    getWebhookEvent();
  }, [liveMode, version, tab]);

  const columns = [
    { title: "", width: "160px" },
    { title: "Webhook URL", width: "580px" },
    { title: "Date", width: "175px" },
    // { title: "Action", width: "100px" }
  ];

  let columnsData = columns.map((column, index) => (
    <Box
      key={index}
      sx={{ width: column.width, fontSize: "14px", color: "#848b9e" }}
    >
      {column.title}
    </Box>
  ));

  const getWebhookEvent = () => {
    const commonPath = `/webhooks/event/${eventId}/attempts?api_version=${version}`;
    const path =
      from === "connect"
        ? `${commonPath}&merchant_account_id=${platformAccID}`
        : commonPath;
    setLoader(true);
    if (version != undefined && version != []) {
      let url = path;
      if (tab) {
        const tabData = tab == succeeded.toLowerCase() ? success : fail;
        url += `&response_status=${tabData}`;
      }
      callAPIInterface("GET", url)
        .then((res) => {
          if (res) {
            setWebhookEvent(res.data);
            setLoader(false);
          }
        })
        .catch((_e) => setLoader(false));
    } else {
      setLoader(false);
      setWebhookEvent([]);
    }
  };

  const webhookEventData =
    webhookEvent?.length > 0 &&
    webhookEvent.map((rowItem, index) => {
      if (index < 3)
        return (
          <>
            <Accordion
              expanded={accordionExpand === rowItem.attempt_id}
              onChange={handleAccordionExpand(rowItem.attempt_id)}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={"panel" + index + "bh-content"}
                id={"panel" + index + "bh-content"}
              >
                <Typography sx={{ width: "160px" }}>
                  {renderWebhookStatus(
                    rowItem.response_status.toLowerCase() == success
                      ? succeeded.toLowerCase()
                      : failed.toLowerCase()
                  )}
                </Typography>
                <Typography sx={{ width: "580px" }}>
                  {" "}
                  <TruncatedTextTooltip
                    textValue={rowItem.endpoint_url}
                    cellWidth="580px"
                  />
                </Typography>
                <Typography sx={{ width: "175px", color: "text.secondary" }}>
                  {dateTimeFormatInApp(rowItem.timestamp)}
                </Typography>
                {/* <Typography sx={{ width: '12%' }}><MoreHorizIcon className="horizontal-dots-icon pointer-cursor" />
              </Typography> */}
              </AccordionSummary>
              <AccordionDetails>
                <Typography className="events-accordion">
                  <Box sx={{ display: "flex" }}>
                    <Box>{HttpStatusCodeLabel} :</Box>
                    <Box sx={{ paddingLeft: "80px" }}>
                      <Tag
                        text={getStatusCode(
                          rowItem?.response_status_code,
                          rowItem?.response_status
                        )}
                        variant={
                          rowItem?.response_status?.toLowerCase() === "success"
                            ? "success-res"
                            : "error"
                        }
                      />
                    </Box>
                  </Box>
                </Typography>
                <Typography className="events-accordion">
                  <Box sx={{ display: "flex" }}>
                    <Box>{RequestEndpointLabel} :</Box>
                    <Box className="events-webhook-endpoint">
                      <Box
                        className={`request-endpoint ${
                          requestEndpoint ? "" : "request-endpoint-hide"
                        }`}
                      >
                        {jsonDataToShow(rowItem?.request)}
                      </Box>
                      <Box className="jsonDisplay-showMoreFade-endpoint"></Box>
                      <CustomButton
                        className="request-endpoint-button"
                        label={
                          requestEndpoint ? hideLinesLabel : viewAllLinesLabel
                        }
                        variant="default"
                        icon="visibilityIcon"
                        onClick={() =>
                          requestEndpoint
                            ? handleRequestEndpoint(false)
                            : handleRequestEndpoint(true)
                        }
                      />
                    </Box>
                  </Box>
                </Typography>
              </AccordionDetails>
            </Accordion>
            <CustomDivider />
          </>
        );
    });

  return (
    <>
      {loader ? (
        <AttemptTableSkeleton />
      ) : noOfRecords > 0 && webhookEvent?.length > 0 ? (
        <Box className="webhook-events-details">
          <Box className="webhook-events-columns">{columnsData}</Box>
          <CustomDivider />
          {webhookEventData}
        </Box>
      ) : (
        <Box className="NoDataAvailable">
          <NoDataAvailable
            text={emptySubText(
              true,
              "",
              tab
                ? tab == succeeded.toLowerCase()
                  ? succeeded + " Webhook attempts"
                  : failed + " Webhook attempts"
                : "Webhook attempts"
            )}
            subText={emptyWebhookSubText}
            image={emptyWebhook}
          />
        </Box>
      )}
    </>
  );
}

export default AllEvents;
