import { Box } from "@mui/material";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import WarningIcon from "@mui/icons-material/Warning";
import Text from "@speed/common/src/components/Text/Text";

function ErrorWarningModal({
  modalHeaderText,
  errorMessage,
  openModal,
  setOpenModal,
}) {
  const handleModalClose = () => setOpenModal(false);

  const renderModalContent = () => {
    return (
      <>
        <Box display="flex" flexDirection="column" gap={2} alignItems="center">
          <WarningIcon sx={{ color: "#E74C3C", height: 60, width: 60 }} />
          <Text
            align="center"
            className="default-text"
            font="regular"
            size={18}
            variant="body1"
          >
            {errorMessage}
          </Text>
        </Box>
      </>
    );
  };

  return (
    <Modal
      maxWidth="sm"
      className="error-warning-modal"
      open={openModal}
      handleClose={handleModalClose}
      body={renderModalContent()}
      title={modalHeaderText}
    />
  );
}

export default ErrorWarningModal;
