import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  imageDimensionInvalid,
  imageInvalid,
  imageSizeInvalid,
  imageUploadError,
} from "../messages";
import { allowedImageTypes } from "./constants";

const UploadFile = (props) => {
  const { imgDimension, apiFunc, showLoader, id, showToast } = props;
  const [imageError, setImageError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (imageError) {
      dispatch(
        showToast({
          isToastOpen: true,
          toastMessage: imageError,
          toastVariant: "error",
        })
      );
      setImageError(false);
    }
  }, [imageError]);

  const onUpload = async (e) => {
    startUploading();
    const file = e.target.files[0];
    let fileName = file.name.replace(/[^\w\-.]+/gi, "");
    fileName = `user_${Math.floor(new Date().getTime() / 1000)}.${fileName
      .split(".")
      .pop()}`;
    e.preventDefault();
    if (file) {
      let isFileValid = validateFile(file, fileName);
      if (isFileValid) {
        await apiFunc(file, fileName);
      }
    }
  };

  const startUploading = () => {
    showLoader(true);
  };

  const onFileTypeError = () => {
    showLoader(false);
    setImageError(imageInvalid);
  };

  const onFileUploadError = (error) => {
    showLoader(false);
    setImageError(imageUploadError);
  };

  const onFileDimensionError = () => {
    showLoader(false);
    setImageError(imageDimensionInvalid(imgDimension));
  };

  const onFileSizeError = () => {
    showLoader(false);
    setImageError(imageSizeInvalid);
  };

  const validateFile = (file, fileName) => {
    try {
      const invalidFile = false;
      let invalidDimension = false;
      let invalidImage = false;
      let fileType = fileName.split(".").pop();

      //file type validation
      if (!allowedImageTypes.includes(fileType)) {
        onFileTypeError();
        return invalidFile;
      }

      //size validation
      if (file.size > 2000000) {
        onFileSizeError();
        return invalidFile;
      }

      function imgOnLoad(image) {
        const w = image.width;
        const h = image.height;
        if (w < imgDimension || h < imgDimension) {
          invalidDimension = true;
        }
      }

      function imgOnErr() {
        invalidImage = true;
      }

      function readerOnLoad(e) {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => imgOnLoad(img);
        img.onerror = imgOnErr;
      }

      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = readerOnLoad;

      //invalid dimension
      if (invalidDimension) {
        onFileDimensionError();
        return invalidFile;
      }

      // error on image
      if (invalidImage) {
        onFileUploadError();
        return invalidFile;
      }

      return true; // returns true if valid
    } catch (err) {
      return false;
    }
  };

  return (
    <input
      className="input-element"
      accept=".jpg, .jpeg, .png, .jfif"
      id={id}
      type="file"
      onChange={(event) => onUpload(event)}
      onClick={(event) => (event.target.value = null)}
    />
  );
};

export default UploadFile;
