import Button from "@speed/common/src/components/Button/Button";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import React from "react";
import {
  cancel,
  deactivatePayoutWallet,
  deactivateWalletAccountText,
  goToPayoutScheduling,
} from "../../messages";
import history from "@speed/common/src/components/history";
import Text from "@speed/common/src/components/Text/Text";

const deactivatePayoutModalContent = (
  <Text font="regular" size={18} variant="subtitle1">
    {deactivateWalletAccountText}
  </Text>
);

const DeactivatePayoutModal = ({
  isDeactivateModalOpen,
  setIsDeactivateModalOpen,
}) => {
  const deactivationModalFooter = () => {
    return (
      <>
        <Button
          variant="outlined"
          label={cancel}
          onClick={() => setIsDeactivateModalOpen(false)}
        />
        <Button
          style={{ marginLeft: "16px" }}
          label={goToPayoutScheduling}
          onClick={() => history.push("/settings/payout-scheduling")}
        />
      </>
    );
  };
  return (
    <Modal
      maxWidth="xs"
      body={deactivatePayoutModalContent}
      footer={deactivationModalFooter()}
      handleClose={() => setIsDeactivateModalOpen(false)}
      open={isDeactivateModalOpen}
      title={deactivatePayoutWallet}
    />
  );
};

export default DeactivatePayoutModal;
