import { callAPIInterface } from "../../components/constants";
import * as Types from "./types";
import { passwordSuccess, updateSuccessMsg } from "../../components/messages";
import { store } from "../store";
import { ratesForCurrencyCode } from "../../components/WithdrawalLinks/Flatfile/constants";

const handleError = (dispatch) => {
  dispatch(setSettingFormSubmit(false));
  dispatch(setSettingFormChange(false));
  return false;
};

export const setSettingFormValid = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_SETTING_FORM_VALID,
    payload: data,
  });
};

export const setSettingFormChange = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_SETTING_FORM_CHANGE,
    payload: data,
  });
};

export const setSettingFormCancel = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_SETTING_FORM_CANCEL,
    payload: data,
  });
};

export const setSettingFormSubmit = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_SETTING_FORM_SUBMIT,
    payload: data,
  });
};

export const setSettingFormCloseAccount = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_SETTING_FROM_CLOSE_ACCOUNT,
    payload: data,
  });
};

export const showToast = (data) => (dispatch) => {
  dispatch({
    type: Types.SHOW_TOAST_MESSAGE,
    payload: data,
  });
};

export const hideToast = (data) => (dispatch) => {
  dispatch({
    type: Types.HIDE_TOAST_MESSAGE,
    payload: data,
  });
};

export const setRouteDetails = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_ROUTE_DETAILS,
    payload: data,
  });
};

export const setLoading = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_LOADING,
    payload: data,
  });
};

export const setConfirmAction = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_CONFIRM_ACTION_MODAL,
    payload: data,
  });
};

export const setExecuteAPICall = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_EXECUTE_API,
    payload: data,
  });
};

export const setAccountPublicInfo = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_ACCOUNT_PUBLIC_INFO,
    payload: data,
  });
};

export const changePassword = (data) => async (dispatch) => {
  return callAPIInterface("POST", "/change-password", JSON.stringify(data))
    .then((_response) => {
      dispatch(setSettingFormSubmit(false));
      dispatch(setSettingFormChange(false));
      dispatch(
        showToast({
          isToastOpen: true,
          toastMessage: passwordSuccess,
          toastVariant: "success",
        })
      );
      return true;
    })
    .catch((_error) => {
      handleError(dispatch);
    });
};

export const updateUserProfile = (data) => async (dispatch) => {
  return callAPIInterface("POST", "/user", JSON.stringify(data))
    .then((response) => {
      dispatch(setSettingFormSubmit(false));
      dispatch(setSettingFormChange(false));
      dispatch(
        showToast({
          isToastOpen: true,
          toastMessage: updateSuccessMsg("profile"),
          toastVariant: "success",
        })
      );
      return response;
    })
    .catch((_error) => {
      handleError(dispatch);
    });
};

export const updateAccountInfo = (data) => async (dispatch) => {
  return callAPIInterface("PUT", "/account", JSON.stringify(data))
    .then((response) => {
      dispatch(setSettingFormSubmit(false));
      dispatch(setSettingFormChange(false));
      dispatch(
        showToast({
          isToastOpen: true,
          toastMessage: updateSuccessMsg("account info"),
          toastVariant: "success",
        })
      );
      return response;
    })
    .catch((_error) => {
      handleError(dispatch);
    });
};

export const updatePublicInfo = (data, isBrandingPage) => async (dispatch) => {
  return callAPIInterface("POST", "/account-public-info", JSON.stringify(data))
    .then((response) => {
      dispatch(setSettingFormSubmit(false));
      dispatch(setSettingFormChange(false));
      dispatch(
        showToast({
          isToastOpen: true,
          toastMessage: updateSuccessMsg(
            `${isBrandingPage ? "branding" : "public"} info`
          ),
          toastVariant: "success",
        })
      );
      return response;
    })
    .catch((_error) => {
      handleError(dispatch);
    });
};

export const getAccountPublicInfo = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    callAPIInterface("GET", "/account-public-info")
      .then((response) => {
        dispatch(setAccountPublicInfo(response));
        resolve(response);
      })
      .catch((e) => reject(e));
  });
};

export const changeEmail = (data) => async () => {
  return callAPIInterface("POST", "/change-email", JSON.stringify(data))
    .then((_response) => {
      return true;
    })
    .catch((_error) => {
      return false;
    });
};

export const getPaymentMethodsList = () => async (dispatch) => {
  return new Promise((resolve, reject) => {
    callAPIInterface("GET", "/assets-account/payment-methods")
      .then((response) => {
        resolve(response);
        if (response) {
          const result =
            response.data?.length &&
            response.data?.filter((data) => data.name === "onchain");
          dispatch({
            type: Types.SET_ONCHAIN_PAYMENT_METHOD,
            payload: result[0]?.status,
          });
          const btcTolerence = result[0]?.amount_paid_tolerance?.toString();
          if (btcTolerence) {
            dispatch(setOnchainTolerance(btcTolerence));
          }
        }
      })
      .catch((e) => reject(e));
  });
};

export const updatePaymentInfo = (data) => async (dispatch) => {
  dispatch({
    type: Types.UPDATE_PAYMENT_INFO,
    payload: data,
  });
};

export const updatePaymentMessage = (data) => async (dispatch) => {
  dispatch({
    type: Types.UPDATE_PAYMENT_MESSAGE,
    payload: data,
  });
};

export const setPaymentRedirectWebPreview = (data) => async (dispatch) => {
  dispatch({
    type: Types.SET_REDIRECT_TO_WEBSITE_PREVIEW,
    payload: data,
  });
};

export const setPaymentConfirmationOption = (data) => async (dispatch) => {
  dispatch({
    type: Types.SET_PAYMENT_CONFIRMATION_OPTION,
    payload: data,
  });
};

export const setBrandingPageUI = (color) => (dispatch) => {
  dispatch({
    type: Types.SET_BRANDING_PAGE_UI,
    payload: color,
  });
};

export const setBrandingView = (view) => (dispatch) => {
  dispatch({
    type: Types.SET_BRANDING_VIEW,
    payload: view,
  });
};

export const setCheckoutLoader = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_CHECKOUT_LOADER,
    payload: data,
  });
};

export const isLoginSessionFilterEnable = (data) => (dispatch) => {
  dispatch({
    type: Types.LOGIN_SESSION_FILTER,
    payload: data,
  });
};

export const setWebPageSearchResults = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_WEB_PAGE_SEARCH_RESULTS,
    payload: data,
  });
};

export const setSearchedModuleName = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_SEARCHED_MODULE_NAME,
    payload: data,
  });
};

export const setProfileMfaAuthDetails = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_PROFILE_MFA_AUTH_DETAILS,
    payload: data,
  });
};

export const setIsDataAvailable = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_IS_DATA_AVAILABLE,
    payload: data,
  });
};

export const setIsModalOpen = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_IS_MODAL_OPEN,
    payload: data,
  });
};
export const setCaughtErrorInStrapi = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_CAUGHT_ERROR_IN_STRAPI,
    payload: data,
  });
};

export const setOnchainTolerance = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_ONCHAIN_PAID_TOLERENCE,
    payload: data,
  });
};

export const setOpenRestrictedKeyCreateModal = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_OPEN_RESTRICTED_KEY_CREATE_MODAL,
    payload: data,
  });
};
export const updatePaymentPageSettings = (data) => async (dispatch) => {
  dispatch({
    type: Types.SET_PAYMENT_PAGE_SETTINGS,
    payload: data,
  });
};

export const setLastInvoiceSavedIn = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_LAST_INVOICE_SAVED_IN,
    payload: data,
  });
};

export const setInvoicePreviewData = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_INVOICE_PREVIEW_DATA,
    payload: data,
  });
};

export const setConnectBranding = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_CONNECT_BRANDING_DETAILS,
    payload: data,
  });
};

export const setPathname = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_PATH_NAME,
    payload: data,
  });
};
export const setPlatformConnectBranding = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_PLATFORM_CONNECT_BRANDING,
    payload: data,
  });
};

export const setWalletSelectedTransaction = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_WALLET_SELECTED_TRANSACTION,
    payload: data,
  });
};

export const getConnectBrandingDetails = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    callAPIInterface("GET", "/connect/branding")
      .then((response) => {
        const brandingPageUI = store.getState()?.common?.brandingPageUI;
        if (response?.is_use_account_branding) {
          response.branding_color = brandingPageUI?.primary_color;
          response.icons = brandingPageUI?.icons;
        }
        response && dispatch(setPlatformConnectBranding(response));
        resolve(response);
      })
      .catch((e) => reject(e));
  });
};

export const updateConnectBrandingInfo = (data) => async (dispatch) => {
  return callAPIInterface("POST", "/connect/branding", JSON.stringify(data))
    .then((response) => {
      dispatch(setSettingFormSubmit(false));
      dispatch(setSettingFormChange(false));
      dispatch(
        showToast({
          isToastOpen: true,
          toastMessage: updateSuccessMsg(`connect branding info`),
          toastVariant: "success",
        })
      );
      return response;
    })
    .catch((_error) => {
      handleError(dispatch);
    });
};
export const setWalletSendModalClose = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_WALLET_HANDLE_MODAL_CLOSE,
    payload: data,
  });
};

export const setVerifyOTP = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_VERIFY_OTP_MODAL,
    payload: data,
  });
};

export const setPayoutModalOpen = (data) => async (dispatch) => {
  dispatch({
    type: Types.SET_PAYOUT_MODAL_OPEN,
    payload: data,
  });
};

export const setPayoutModalData = (data) => async (dispatch) => {
  dispatch({
    type: Types.SET_PAYOUT_MODAL_DATA,
    payload: data,
  });
};

export const setPayoutSchedulingEnabled = (data) => async (dispatch) => {
  dispatch({
    type: Types.SET_PAYOUT_SCHEDULING_ENABLED,
    payload: data,
  });
};
export const setWalletPaymentAddress = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_WALLET_PAYMENT_ADDRESS,
    payload: data,
  });
};

export const setIsWalletTransactonDrawerOpen = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_IS_WALLET_TRANSACTION_DRAWER_OPEN,
    payload: data,
  });
};

export const setWalletAppliedTransactonFiltersObj = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_WALLET_TRANSACTION_FILTER,
    payload: data,
  });
};

export const setShowAutomaticLogoutModal = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_AUTOMATIC_USER_LOGOUT_MODAL,
    payload: data,
  });
};

export const setWalletAssets = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_WALLET_ASSET,
    payload: data,
  });
};

export const setAccountRestrictedModalData = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_ACCOUNT_RESTRICTED_MODAL_DATA,
    payload: data,
  });
};

export const setInstantSendNextClicked = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_INSTANT_SEND_NEXT_CLICKED,
    payload: data,
  });
};

export const setInstantSendButtonClicked = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_INSTANT_SEND_BUTTON_CLICKED,
    payload: data,
  });
};

export const setDefaultSelectedAsset = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_DEFAULT_SELECTED_ASSET,
    payload: data,
  });
};

export const setPaymentProcessingRecallApis = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_PAYMENT_PROCESSING_RECALL_APIS,
    payload: data,
  });
};

export const setShowBTCAmount = (data) => (dispatch) => {
  dispatch({ type: Types.SET_SHOW_BTC_AMOUNT, payload: data });
};

export const setMaintenanceDetails = (data) => (dispatch) => {
  dispatch({ type: Types.SET_MAINTENANCE_DETAILS, payload: data });
};

export const setShowMaintenanceBar = (data) => (dispatch) => {
  dispatch({ type: Types.SET_SHOW_UNDER_MAINTENANCE_BAR, payload: data });
};

export const getAccountAsset = (data) => async (dispatch) => {
  try {
    const accountAsset = await callAPIInterface("GET", "/assets-account");
    const sortedData = accountAsset?.data?.sort(
      (a, b) => b.is_default - a.is_default
    );
    const result = sortedData?.filter((asst) => {
      return asst.payment_method_details?.some(
        (a) => a.is_payment_method_enabled
      );
    });
    dispatch({ type: Types.SET_ACCOUNT_ASSETS, payload: result });
  } catch (_err) {
    return false;
  }
};

export const setFlatfileName = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_FLATFILE_NAME,
    payload: data,
  });
};

export const setFlatfileCurrencyRates = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_FLATFILE_CURRENCY_RATES,
    payload: data,
  });
};

export const setFlatSheetData = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_FLATFILE_SHEET_DATA,
    payload: data,
  });
};

export const setSendViaEmailModalAPIDetails = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_SEND_VIA_EMAIL_MODAL_API_DETAILS,
    payload: data,
  });
};

export const setOpenSendViaEmailModal = (data) => (dispatch) => {
  dispatch({
    type: Types.SET_OPEN_SEND_VIA_EMAIL_MODAL,
    payload: data,
  });
};

export const WLImportFile = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    callAPIInterface("POST", "/withdrawal-links/files", JSON.stringify(data))
      .then((response) => {
        dispatch(setOpenSendViaEmailModal(true));
        dispatch(setFlatfileName(null));
        dispatch(setFlatfileCurrencyRates(ratesForCurrencyCode));
        dispatch(setSendViaEmailModalAPIDetails(response));
        resolve(response);
      })
      .catch((e) => reject(e));
  });
};
