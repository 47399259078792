import { createStore, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./rootReducer";
import { sessionService } from "redux-react-session";
import * as Sentry from "@sentry/react";

const sentryReduxEnhancer = Sentry.createReduxEnhancer();

const middleware = [thunk];

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(...middleware),
  sentryReduxEnhancer
);

export const store = createStore(rootReducer, enhancer);

const validateSession = (session) => {
  if (session.access_token && session.id_token) {
    return true;
  }
};

const options = {
  refreshOnCheckAuth: true,
  redirectPath: "/login",
  validateSession,
};

sessionService.initSessionService(store, options);
