import React, { useState, useEffect } from "react";
import Text from "@speed/common/src/components/Text/Text";
import { Grid, Box } from "@mui/material";
import EmailScreen from "./EmailScreen";
import PasswordScreen from "./PasswordScreen";
import {
  signInMsg,
  signIndescriptionMsg,
} from "@speed/common/src/components/messages";
import {
  affiliateInvitationMessage,
  loginAffiliateNotFoundMessage,
  magicLinkMsg,
} from "../messages";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  affiliatePartnerData,
  affiliatePartnerDataNotAvailable,
  handleSessionSharingRedirection,
  isAffiliatePartnerDataAvailable,
} from "../constants";
// import MagicLink from './MagicLink';
import MainLogoLogin from "../Common/MainLogoLogin";
import WarningIcon from "@mui/icons-material/Warning";

const SignIn = () => {
  const location = useLocation();
  const [emailScreen, setEmailScreen] = useState(true);
  const [elementIn, setElementIn] = useState(true);
  const [magicLinkScreen, setMagicLinkScreen] = useState(false);
  const [email, setEmail] = useState("");
  const [user, setUser] = useState(null);
  const transitionDuration = 100;

  const { history, isMobile } = useSelector((state) => state.common);

  const fromPartnerDataAvailable = isAffiliatePartnerDataAvailable();
  const noDataFromPartner = affiliatePartnerDataNotAvailable(location);

  useEffect(() => {
    setElementIn(false);
    setTimeout(() => {
      setElementIn(true);
    }, transitionDuration);
  }, [emailScreen]);

  const handleEmailScreen = (newEmail) => {
    newEmail && setEmail(newEmail);
    setEmailScreen(!emailScreen);
  };

  useEffect(() => {
    const loginInviteData = JSON.parse(
      sessionStorage.getItem("loginInviteData")
    );
    location.data &&
      sessionStorage.setItem("loginInviteData", JSON.stringify(location.data));
    const emailToBeSet = location?.data?.email || loginInviteData?.email;
    emailToBeSet && setEmail(emailToBeSet);
  }, [location.data]);

  useEffect(() => {
    fromPartnerDataAvailable &&
      affiliatePartnerData()?.email &&
      setEmail(affiliatePartnerData().email);
  }, [fromPartnerDataAvailable]);

  const handleRedirection = async (user) => {
    const searchURL = history.location.search.replace("+", "%2B");
    const data = new URLSearchParams(searchURL);
    const storage = JSON.parse(localStorage.getItem("documentation_details"));
    const from = data.get("from") || storage?.from;
    const redirect = data.get("redirect") || storage?.redirect;
    if (from === "documentation" && redirect) {
      localStorage.setItem(
        "documentation_details",
        JSON.stringify({ from, redirect })
      );
      history.push("/login");
    }
  };

  useEffect(() => {
    handleRedirection();
    handleSessionSharingRedirection(location.search);
  }, []);

  const handleMagicLink = () => {
    setMagicLinkScreen(!magicLinkScreen);
    setEmailScreen(true);
    setElementIn(false);
    setTimeout(() => {
      setElementIn(true);
    }, transitionDuration);
  };

  const setVerifiedUserData = (verifyUser) => {
    const { first_name, last_name } = verifyUser;
    setUser({ name: first_name + " " + last_name, ...verifyUser });
  };

  return (
    <>
      <MainLogoLogin />
      <Box className="container-layout">
        <Grid
          container
          alignItems="center"
          className="common-grid-wrapper common-mobile-grid signin-signup-container-layout"
        >
          {(fromPartnerDataAvailable || noDataFromPartner) && (
            <Box
              bgcolor={noDataFromPartner ? "#F8F3DF" : "#F7FAFC"}
              border={!noDataFromPartner && "1px solid #E4E9EE"}
              borderRadius="5px"
              py={2}
              px={noDataFromPartner ? 1 : 3}
              mb="30px"
              display="flex"
              alignItems="center"
              gap={1}
            >
              {noDataFromPartner && (
                <WarningIcon sx={{ color: "#F1C40F", height: 18, width: 18 }} />
              )}
              <Text
                className="grey-text"
                font="regular"
                size={14}
                variant="body2"
              >
                {noDataFromPartner
                  ? loginAffiliateNotFoundMessage
                  : affiliateInvitationMessage(affiliatePartnerData())}
              </Text>
            </Box>
          )}
          <Text size={isMobile ? 22 : 30} font="bold" sx={{ width: "100%" }}>
            {magicLinkScreen ? magicLinkMsg : signInMsg}
          </Text>
          {!fromPartnerDataAvailable && (
            <Text
              size={isMobile ? 14 : 18}
              font="regular"
              className="default-text text margin-top10"
              variant="subtitle1"
              sx={{ lineHeight: "140% !important" }}
            >
              {signIndescriptionMsg}
            </Text>
          )}
          {/* {magicLinkScreen ? */}
          {/* <MagicLink handleEmailScreen={handleEmailScreen} handleMagicLink={handleMagicLink} /> : */}
          {emailScreen ? (
            <EmailScreen
              handleEmailScreen={handleEmailScreen}
              setVerifiedUserData={setVerifiedUserData}
              handleMagicLink={handleMagicLink}
              checked={elementIn}
              email={email}
            />
          ) : (
            <PasswordScreen
              verifiedUserData={user}
              handleEmailScreen={handleEmailScreen}
              handleMagicLink={handleMagicLink}
              checked={elementIn}
              email={email}
            />
          )}
        </Grid>
      </Box>
    </>
  );
};

export default SignIn;
