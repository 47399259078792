import React from "react";
import SetupCommonHeader from "./SetupCommonHeader";
import Button from "@speed/common/src/components/Button/Button";
import {
  autoPayoutComplete,
  readyToFly,
  startJourney,
} from "../../../messages";
import history from "@speed/common/src/components/history";
import { useDispatch } from "react-redux";
import { setPayoutModalOpen } from "../../../../redux/common/actions";

const SetupCompletion = () => {
  const dispatch = useDispatch();
  return (
    <>
      <SetupCommonHeader header={readyToFly} subHeader={autoPayoutComplete} />
      <Button
        label={startJourney}
        className="margin-top30"
        onClick={() => {
          history.push("/dashboard");
          dispatch(setPayoutModalOpen(false));
        }}
      />
    </>
  );
};

export default SetupCompletion;
