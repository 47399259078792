import React from "react";
import Box from "@mui/material/Box";
import { CustomAvatar } from "./Avatar/Avatar";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import TruncatedTextTooltip from "./TruncatedTextTooltip";

const UserBox = ({ user, handleIconClick }) => {
  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.keyCode === 13) {
      handleIconClick();
    }
  };

  return (
    <Box className="connect-user-box">
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <CustomAvatar
          src={user?.profile_images?.large || ""}
          userName={user?.name}
          className="user-profile-image"
        />
        <Box sx={{ ml: "14px" }}>
          <TruncatedTextTooltip
            cellWidth="450px"
            textValue={user?.name}
            textProps={{ className: "name-text" }}
            boxProps={{ className: "name-text-box" }}
          />
          <TruncatedTextTooltip
            cellWidth="450px"
            textValue={user?.email}
            textProps={{ className: "email-text" }}
            boxProps={{ className: "email-text-box" }}
          />
        </Box>
      </Box>
      <Box>
        <EditSharpIcon
          onClick={handleIconClick}
          tabIndex={0}
          onKeyUp={handleKeypress}
          sx={{ color: "#848B9E", width: "20px", height: "20px" }}
        />
      </Box>
    </Box>
  );
};

export default UserBox;
