import { Box, MenuItem, Skeleton } from "@mui/material";
import {
  fetchPrecisedInteger,
  getCurrencyObj,
} from "@speed/common/src/components/constants";
import { currencyForSwap } from "@speed/common/src/components/currencyForSwap";
import { Input } from "@speed/common/src/components/Input/Input";
import Label from "@speed/common/src/components/Label/Label";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import InputMask from "@speed/common/src/components/MaskInput";
import CustomSelect from "@speed/common/src/components/Select/Select";
import Text from "@speed/common/src/components/Text/Text";
import React, { useEffect } from "react";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import {
  balanceAvailableForModule,
  enterAmountPlaceholder,
  from,
  minimumAmountLabel,
  swapAmount,
  swapDetailsText,
  swapFullAmount,
  to,
} from "../messages";

const SwapRequestDetails = (props) => {
  const {
    values,
    setFieldValue,
    touched,
    setTouched,
    amountRef,
    errors,
    setErrors,
    defaultErrorMessageProps,
    balanceLoading,
    balanceData,
    handleDisableNext,
    isValid,
    dirty,
    resetForm,
    minimumAmount,
  } = props;

  useEffect(() => {
    if (values?.amount) {
      const shouldDisable = !(isValid && dirty);
      handleDisableNext(shouldDisable);
    }
  }, [dirty, isValid, values?.amount]);

  const handleChangeCurrency = (e) => {
    handleDisableNext(true);
    resetForm({});
    setFieldValue("currency", getCurrencyObj(e.target.value, true));
    setFieldValue(
      "target_currency",
      currencyForSwap?.find((currency) => currency?.code !== e.target.value)
    );
    const amountBalance =
      balanceData?.find((value) => value?.target_currency === e.target.value)
        ?.amount || 0;
    setFieldValue("balance", amountBalance);
    setErrors({});
  };

  return (
    <Box className="margin-top30" display="flex" justifyContent="center">
      <Box>
        <Text className="default-text" size={20} variant="h2">
          {swapDetailsText}
        </Text>
        <Box width={600} className="margin-top30">
          <CustomSelect
            showLabel
            label={from}
            customClass="margin-top30"
            disabled={values?.balance === ""}
            value={
              values?.currency && Object.keys(values.currency).length > 0
                ? values.currency?.code
                : setFieldValue("currency", getCurrencyObj("SATS", true))
            }
            MenuProps={{
              id: "payout-wallet-address",
              disableScrollLock: true,
            }}
            onChange={handleChangeCurrency}
            renderValue={() => {
              if (values?.currency) {
                return values?.currency?.code;
              }
            }}
          >
            {currencyForSwap?.map((dropDownValue, dropDownIndex) => (
              <MenuItem value={dropDownValue?.code} key={dropDownIndex}>
                <Box>{dropDownValue?.code}</Box>
              </MenuItem>
            ))}
          </CustomSelect>
        </Box>
        <Box width={600} className="margin-top30">
          <Input
            showLabel
            label={to}
            value={values?.target_currency?.code}
            name="target_currency"
            fullWidth
            disabled
          />
        </Box>
        <Box width={600} className="margin-top30">
          <Box display="flex" justifyContent="space-between">
            <Label>{swapAmount}</Label>
            <Label>
              {minimumAmountLabel(
                "swap",
                minimumAmount,
                values?.currency?.code
              )}
            </Label>
          </Box>
          <InputMask
            label={""}
            onChange={(e) => {
              setTouched({ ...touched, amount: false });
              amountRef.current.amount = e.target.value;
              setFieldValue("amount", e.target.value);
            }}
            ref={amountRef}
            value={values.amount}
            error={touched.amount && Boolean(errors.amount)}
            onBlur={() => setTouched({ ...touched, amount: true })}
            placeholder={enterAmountPlaceholder}
            disabled={values?.balance ? values.balance < minimumAmount : true}
          />
          <InputErrorMessage {...defaultErrorMessageProps} inputName="amount" />
        </Box>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="baseline"
          width={600}
        >
          <Text
            sx={{
              mt: "15px",
              display: "flex",
              flexWrap: "wrap",
              gap: 0.5,
              width: "75%",
            }}
            className="grey-text"
            size={14}
            variant="body1"
          >
            {balanceAvailableForModule("swap")}
            <Text className="default-text" size={14} variant="caption">
              {balanceLoading ? (
                <Skeleton width={100} />
              ) : (
                `${fetchPrecisedInteger(values?.balance, 8)} ${
                  values?.currency?.code
                }`
              )}
            </Text>
          </Text>
          {values?.balance >= minimumAmount && (
            <CustomLink
              size="small"
              sx={{ textDecoration: "underline" }}
              onClick={() => setFieldValue("amount", values?.balance)}
            >
              {swapFullAmount}
            </CustomLink>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default SwapRequestDetails;
