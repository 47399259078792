import { Box } from "@mui/material";
import classNames from "classnames";

const MoreActionButton = ({
  isOpenMoreMenu,
  buttonData,
  wrapperBoxClassName,
  wrapperBoxStyle,
}) => {
  const numOfButtons = buttonData?.length;
  const getActionBtnClassName = (index, dataLength) => {
    if (!index) {
      return "first-action-btn";
    } else if (index === dataLength - 1) {
      return "last-action-btn";
    }
    return "";
  };

  return (
    <>
      {numOfButtons > 0 && (
        <Box
          className={classNames(
            "more-action-button-box",
            `${numOfButtons > 1 ? "extra" : "single"}-btn-available`,
            isOpenMoreMenu && "open-options",
            wrapperBoxClassName
          )}
          sx={{
            "&:hover.extra-btn-available .btn-actions-wrapper, &.open-options .btn-actions-wrapper":
              {
                width: `${numOfButtons * 40}px !important`,
                right: `${(numOfButtons - 1) * 40}px !important`,
              },
            ...wrapperBoxStyle,
          }}
        >
          <Box
            className="btn-actions-wrapper"
            sx={{ width: `${numOfButtons * 40}px` }}
            onClick={(event) => {
              event.stopPropagation();
            }}
          >
            {buttonData?.map((button, index) => {
              const customClassName = classNames(
                index !== numOfButtons - 1
                  ? "extra-action-icon"
                  : "main-action-btn",
                getActionBtnClassName(index, numOfButtons)
              );

              return (
                <Box
                  key={index}
                  className={customClassName}
                  tabIndex={0}
                  onKeyDown={(e) => {
                    if (e.keyCode === 13) {
                      button.onClick(e);
                    }
                  }}
                  onClick={button.onClick}
                >
                  {button.iconElement}
                </Box>
              );
            })}
          </Box>
        </Box>
      )}
    </>
  );
};

export default MoreActionButton;
