import React, { useState, useEffect, useCallback } from "react";
import { Box, TableCell, TableRow } from "@mui/material";
import CommonHeader from "../Common/CommonHeader";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import CustomTable from "@speed/common/src/components/Table/Table";
import {
  noOfRecords,
  callAPIInterface,
  dateTimeFormatInApp,
} from "../constants";
import { events } from "./Events";
import { useSelector } from "react-redux";
import { useFlags } from "launchdarkly-react-client-sdk";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import { emptySubText } from "@speed/common/src/components/messages";
import { emptyEventsSubText } from "../messages";
import { emptyEvents } from "@speed/common/src/components/images";

const Events = () => {
  const [rows, setRows] = useState([]);
  const queryParam = "?limit=" + noOfRecords;
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [hasMore, setHasMore] = useState(true);
  const liveMode = useSelector((state) => state.auth.liveMode);
  const history = useSelector((state) => state.common.history);
  const { evGetalleventslist } = useFlags();

  useEffect(() => {
    setRows([]);
    setHasMore(true);
    evGetalleventslist && getEvents([], queryParam);
  }, [liveMode]);

  const loadMore = useCallback(() => {
    evGetalleventslist && getEvents(rows, queryParams);
  }, [rows]);

  const getEvents = async (lines, params) => {
    setTableRowSkeleton(true);
    await callAPIInterface("GET", "/events" + params)
      .then((response) => {
        if (response) {
          setTableRowSkeleton(false);
          if (!response.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam +
                "&ending_before=" +
                response.data[response.data.length - 1].id
            );
          }
          setRows(lines.concat(response.data));
        }
      })
      .catch((_e) => {
        setTableRowSkeleton(false);
      });
  };

  const columns = [
    { title: "Events", width: "600px", paddingLeft: "38px !important" },
    { title: "Event ID", width: "200px" },
    { title: "Date", width: "200px" },
  ];

  let columnsData = columns.map((column, index) => (
    <TableCell
      key={index}
      align={column.align}
      sx={{
        width: column.width,
        position: "unset",
        paddingLeft: column.paddingLeft,
      }}
    >
      {column.title}{" "}
    </TableCell>
  ));

  let rowsData = rows?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="pointer-cursor"
        onClick={() => history.push(`/events/${rowItem.id}`)}
      >
        <TableCell sx={{ paddingLeft: "38px !important" }}>
          {events(rowItem)}
        </TableCell>
        <TableCell>{rowItem.id}</TableCell>
        <TableCell> {dateTimeFormatInApp(rowItem?.created)}</TableCell>
      </TableRow>
    );
  });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    className: "link-table",
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
    textOnNoData: emptySubText(true, "", "Events"),
    subTextOnNoData: emptyEventsSubText,
    noDataImage: emptyEvents,
  };
  return (
    <Box className="section-wrapper">
      <CommonHeader />
      <CustomDivider />
      <Box className="main-content">
        {evGetalleventslist ? (
          <CustomTable {...tableProps} />
        ) : (
          <AccessDenied />
        )}
      </Box>
    </Box>
  );
};
export default Events;
