import { Box } from "@mui/material";
import {
  additionalOptionLabel,
  customFieldLabel,
  customFieldTooltipMsg,
  doNotSendRemindersLabel,
  doNotSendRemindersTooltipMsg,
  footerPlaceHolder,
  memoPlaceHolder,
  memoTitle,
} from "../messages";
import InvoiceCustomFieldForm from "./InvoiceCustomFieldForm";
import { useState } from "react";
import classNames from "classnames";
import { TextAreaComponent } from "@speed/common/src/components/TextArea/TextArea";
import { footerTitle } from "@speed/common/src/messages";

function InvoiceAdditionalOptions({
  saveLastSavedTime,
  formik,
  labelElement,
  checkboxElement,
  disableEditFormElement,
}) {
  const showCustomFieldSection = false;
  const showReminderSection = false;
  const { values, setFieldValue } = formik;
  const [customFieldsList, setCustomFieldsList] = useState([]);
  const [isCustomFieldsEditable, setIsCustomFieldsEditable] = useState(false);

  const onSubmitCustomFields = (payload) => {
    const finalPayload = { metadata: payload };
    setIsCustomFieldsEditable(false);
  };

  const onCancelCustomFields = () => setIsCustomFieldsEditable(false);

  return (
    <Box
      className={classNames(
        "invoice-additional-options margin-top30",
        disableEditFormElement && "disable-edit-section"
      )}
    >
      {labelElement(additionalOptionLabel)}
      {checkboxElement(
        "includeFooter",
        footerTitle,
        () => {
          saveLastSavedTime(true);
          setFieldValue("includeFooter", !values.includeFooter);
        },
        values.includeFooter && (
          <TextAreaComponent
            showLabel={false}
            value={values.footerMessage}
            maxRows={3}
            minRows={3}
            maxLength={500}
            fullWidth
            onChange={(e) => {
              setFieldValue("footerMessage", e.target.value);
            }}
            placeholder={footerPlaceHolder}
          />
        )
      )}
      {checkboxElement(
        "includeMemo",
        memoTitle,
        () => {
          saveLastSavedTime(true);
          setFieldValue("includeMemo", !values.includeMemo);
        },
        values.includeMemo && (
          <TextAreaComponent
            showLabel={false}
            value={values.memoMessage}
            maxRows={3}
            minRows={3}
            maxLength={500}
            fullWidth
            onChange={(e) => {
              setFieldValue("memoMessage", e.target.value);
            }}
            placeholder={memoPlaceHolder}
          />
        )
      )}
      {showCustomFieldSection &&
        checkboxElement(
          "includeCustomField",
          customFieldLabel,
          () => {
            saveLastSavedTime(true);
            setFieldValue("includeCustomField", !values.includeCustomField);
          },
          values.includeCustomField && (
            <Box className="invoice-custom-field-form">
              <InvoiceCustomFieldForm
                customFieldsList={customFieldsList}
                setIsCustomFieldsEditable={setIsCustomFieldsEditable}
                onSubmit={onSubmitCustomFields}
                onCancel={onCancelCustomFields}
              />
            </Box>
          ),
          customFieldTooltipMsg,
          true
        )}
      {showReminderSection &&
        checkboxElement(
          "doNotSendReminders",
          doNotSendRemindersLabel,
          () => {
            saveLastSavedTime(true);
            setFieldValue("doNotSendReminders", !values.doNotSendReminders);
          },
          false,
          doNotSendRemindersTooltipMsg,
          true
        )}
    </Box>
  );
}

export default InvoiceAdditionalOptions;
