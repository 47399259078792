import React, { Fragment, useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { CustomCheckbox } from "@speed/common/src/components/Checkbox/Checkbox";
import { Input } from "@speed/common/src/components/Input/Input";
import Label from "@speed/common/src/components/Label/Label";
import Text from "@speed/common/src/components/Text/Text";
import PageLoader from "@speed/common/src/components/PageLoader";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import {
  keyDetails,
  keyName,
  modules,
  none,
  secretKeyPlaceholder,
  read,
  write,
  _read,
  _write,
  bucketText,
  resourceText,
  addDescription,
  description,
} from "../messages";
import "../../assets/styles/settings.scss";
import { callAPIInterface, callStrapiAPIInterface } from "../constants";

function RestrictedKeyTypeList({
  setFieldValue,
  setTouched,
  touched,
  errors,
  values,
  defaultErrorMessageProps,
}) {
  const [loading, setLoading] = useState(false);
  const [resourceBuckets, setResourceBuckets] = useState([]);
  const [noneCheckedPermissions, setNoneCheckedPermissions] = useState([]);
  const [resourcesFromApiCataloging, setResourcesFromApiCataloging] =
    useState(null);
  const [resourceLoading, setResourceLoading] = useState(false);

  useEffect(() => {
    getRestrictedKeysResources();
    getResourcesFromApiCataloging();
  }, []);

  const getResourcesFromApiCataloging = () => {
    setLoading(true);
    callStrapiAPIInterface("GET", "/api/resources-api-catalogings")
      .then((response) => {
        setResourcesFromApiCataloging(response?.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const permissionHeader = [write, read, none];

  // bucket -> parent checkbox
  // resource -> child checkbox
  const handleBucketResourceCheckForNoneCheck = (id) => {
    return values?.keyPermissions?.some((p) => p.includes(id));
  };

  // handle check all none initially
  const handleAllNoneCheck = (resourceBuckets) => {
    if (resourceBuckets?.length) {
      let allNoneCheck = [];
      resourceBuckets.forEach((bucket) => {
        let isResourceChecked = false;
        if (!handleBucketResourceCheckForNoneCheck(bucket?.id)) {
          bucket?.resources?.forEach((bucketResource) => {
            if (!handleBucketResourceCheckForNoneCheck(bucketResource?.id)) {
              allNoneCheck.push(bucketResource?.id);
            } else {
              isResourceChecked = true;
            }
          });
          if (!isResourceChecked) {
            allNoneCheck.push(bucket?.id);
          }
        }
      });
      setNoneCheckedPermissions(allNoneCheck);
    }
  };

  // api call to get all the bucket permissions
  const getRestrictedKeysResources = () => {
    setResourceLoading(true);
    callAPIInterface("GET", "/api-key/bucket-wise-resource")
      .then((response) => {
        setResourceLoading(false);
        setResourceBuckets(response?.buckets);
        handleAllNoneCheck(response?.buckets);
      })
      .catch((_error) => {
        setResourceLoading(false);
      });
  };

  // handle to remove resources when bucket checked
  const handleRemoveBucketResource = (
    restrictedKeyPermissions,
    id,
    permissionNeedFor
  ) => {
    resourceBuckets?.forEach((bucket) => {
      if (bucket?.id === id) {
        bucket?.resources.forEach((resourceBucket) => {
          if (
            restrictedKeyPermissions.includes(resourceBucket?.id + _read) ||
            restrictedKeyPermissions.includes(resourceBucket?.id + _write)
          ) {
            const checkForPermissions = [
              resourceBucket?.id + _read,
              resourceBucket?.id + _write,
            ];
            restrictedKeyPermissions = handleRemoveRestrictedPermissions(
              restrictedKeyPermissions,
              checkForPermissions
            );
          }
        });
      }
    });
    restrictedKeyPermissions.push(permissionNeedFor);
    return restrictedKeyPermissions;
  };

  // handle filter data from permission
  const handleRemoveRestrictedPermissions = (
    restrictedKeyPermissions,
    removePermission
  ) => {
    return restrictedKeyPermissions.filter(
      (permission) => !removePermission.includes(permission)
    );
  };

  // handle only one permission check for bucket and resource
  const handleOppositePermissionUnchecked = (
    restrictedKeyPermissions,
    bucketId,
    permission
  ) => {
    if (permission === write.toLowerCase()) {
      const oppositePermission = bucketId + _read;
      restrictedKeyPermissions = handleRemoveRestrictedPermissions(
        restrictedKeyPermissions,
        oppositePermission
      );
    } else {
      const oppositePermission = bucketId + _write;
      restrictedKeyPermissions = handleRemoveRestrictedPermissions(
        restrictedKeyPermissions,
        oppositePermission
      );
    }

    return restrictedKeyPermissions;
  };

  // when bucket uncheck -> check all the resources for the bucket
  const handleCheckAllBucketResource = (
    restrictedKeyPermissions,
    id,
    permission
  ) => {
    resourceBuckets?.forEach((bucket) => {
      if (bucket?.id === id) {
        bucket?.resources.forEach((resourceBucket) => {
          if (resourceBucket[permission]) {
            restrictedKeyPermissions.push(
              resourceBucket?.id + "_" + permission
            );
          }
        });
      }
    });
    return restrictedKeyPermissions;
  };

  // handle remove resource of write and read when parent none check
  const handleRemoveResourceNoneChecked = (id, newNoneCheckedPermissions) => {
    const newArr = [];
    resourceBuckets?.forEach((bucket) => {
      if (bucket?.id === id && bucket?.resources) {
        for (const noneCheck of newNoneCheckedPermissions) {
          const index = bucket.resources.findIndex((r) => r.id === noneCheck);
          if (index === -1) {
            newArr.push(noneCheck);
          }
        }
      }
    });

    return newArr;
  };

  // handle buckets permission
  const handleBucketsPermission = (bucketId, accessibility, checked) => {
    const permissionNeedFor = bucketId + "_" + accessibility;
    let restrictedKeyPermissions = [...values.keyPermissions];
    if (!checked) {
      // on check of the any parent other child should be removed
      restrictedKeyPermissions = handleOppositePermissionUnchecked(
        restrictedKeyPermissions,
        bucketId,
        accessibility
      );
      // remove bucket resource from permission array if any
      restrictedKeyPermissions = handleRemoveBucketResource(
        restrictedKeyPermissions,
        bucketId,
        permissionNeedFor
      );
      // parent none check removed
      let newNoneCheckedPermissions = noneCheckedPermissions?.filter(
        (noneChecked) => noneChecked !== bucketId
      );
      // if parent none check is removed then child also removed
      newNoneCheckedPermissions = handleRemoveResourceNoneChecked(
        bucketId,
        newNoneCheckedPermissions
      );
      setNoneCheckedPermissions(newNoneCheckedPermissions);
    } else {
      // when untick the parent we want all the child to be selected
      restrictedKeyPermissions = handleRemoveRestrictedPermissions(
        restrictedKeyPermissions,
        permissionNeedFor
      );
      restrictedKeyPermissions = handleCheckAllBucketResource(
        restrictedKeyPermissions,
        bucketId,
        accessibility
      );
    }
    setFieldValue("keyPermissions", [...restrictedKeyPermissions]);
  };

  // handle resources permission
  const handleBucketResourcePermissions = (
    bucketId,
    bucketResourceId,
    accessibility,
    checked
  ) => {
    const permissionNeedFor = bucketResourceId + "_" + accessibility;
    let restrictedKeyPermissions = [...values.keyPermissions];
    const checkedForPermission = [bucketId, bucketResourceId];
    if (!checked) {
      // uncheck the opposite permssion
      restrictedKeyPermissions = handleOppositePermissionUnchecked(
        restrictedKeyPermissions,
        bucketResourceId,
        accessibility
      );
      restrictedKeyPermissions.push(permissionNeedFor);
      // remove from none
      let newNoneCheckedPermissions = noneCheckedPermissions?.filter(
        (noneChecked) => !checkedForPermission.includes(noneChecked)
      );
      setNoneCheckedPermissions(newNoneCheckedPermissions);
    }
    setFieldValue("keyPermissions", [...restrictedKeyPermissions]);
  };

  // handle bucket/parent none check to remove all other check
  const handleBucketNoneCheck = (bucketId, checked) => {
    const noneChecked = [];
    let restrictedKeyPermissions = [...values.keyPermissions];
    if (!checked) {
      resourceBuckets?.forEach((bucket) => {
        if (bucket?.id === bucketId) {
          noneChecked.push(bucket?.id);
          bucket?.resources?.forEach((resource) => {
            noneChecked.push(resource?.id);
            const resourceCheckedPermission = [
              bucketId + _read,
              bucketId + _write,
              resource?.id + _read,
              resource?.id + _write,
            ];
            restrictedKeyPermissions = handleRemoveRestrictedPermissions(
              restrictedKeyPermissions,
              resourceCheckedPermission
            );
          });
        }
      });
      setNoneCheckedPermissions([...noneCheckedPermissions, ...noneChecked]);
      setFieldValue("keyPermissions", [...restrictedKeyPermissions]);
    }
  };

  // handle resource none check to remove write read none check
  const handleResourceNoneChecked = (id, checked) => {
    const checkForPermissions = [id + _read, id + _write];
    let noneChecked = [];
    let restrictedKeyPermissions = [...values.keyPermissions];
    if (!checked) {
      noneChecked.push(id);
      restrictedKeyPermissions = handleRemoveRestrictedPermissions(
        restrictedKeyPermissions,
        checkForPermissions
      );
    }
    setNoneCheckedPermissions([...noneCheckedPermissions, ...noneChecked]);
    setFieldValue("keyPermissions", [...restrictedKeyPermissions]);
  };

  // handle none check
  const handleBucketsNonePermission = (bucketId, type, checked) => {
    if (type === bucketText) {
      handleBucketNoneCheck(bucketId, checked);
    } else {
      handleResourceNoneChecked(bucketId, checked);
    }
  };

  const handleShowBucketAndResourceName = (id, name) => {
    const resource = resourcesFromApiCataloging?.find(
      (resource) => resource?.attributes?.resource_id === id
    );
    return resource?.attributes?.display_name || name;
  };

  const listTableLoader = () =>
    [...Array(3).keys()]?.map((bucket) => (
      <TableCell key={bucket} align="center">
        <CommonSkeleton width={50} height={14} color={"#C4CCD2"} />
      </TableCell>
    ));

  const resourceLoader = () => {
    return (
      <TableBody className="table-body">
        <Box borderRight="1px solid #e4e9ee">
          {[...Array(2).keys()]?.map((bucket) => (
            <Fragment key={bucket?.id}>
              <TableRow className="table-subheader">
                <TableCell className="table-subheader-module">
                  <Box
                    style={{
                      display: "flex",
                      gap: "1rem",
                    }}
                  >
                    <Text size={16} className="default-text">
                      <CommonSkeleton
                        width={100}
                        height={14}
                        color={"#C4CCD2"}
                      />
                    </Text>
                  </Box>
                </TableCell>
                {listTableLoader()}
              </TableRow>
              {[...Array(2).keys()].map((bucketResource) => (
                <TableRow className="table-cell" key={bucketResource?.id}>
                  <TableCell
                    className="table-cell-module"
                    align="center"
                    sx={{
                      width: "100%",
                    }}
                  >
                    <Text
                      size={16}
                      variant="h4"
                      font="regular"
                      className="grey-text"
                    >
                      <CommonSkeleton
                        width={100}
                        height={14}
                        color={"#C4CCD2"}
                      />
                    </Text>
                  </TableCell>
                  {listTableLoader()}
                </TableRow>
              ))}
            </Fragment>
          ))}
        </Box>
      </TableBody>
    );
  };

  return (
    <Box
      marginBottom="30px"
      className="margin-top30"
      display="flex"
      justifyContent="center"
    >
      {loading ? (
        <PageLoader
          open={loading}
          alt="Loading..."
          customClass="page-loader preview-loader"
          sx={{ position: "absolute", margin: "auto" }}
        />
      ) : (
        <Box>
          <Text className="default-text" size={20} variant="h2">
            {keyDetails}
          </Text>
          <Box width={600} className="margin-top30">
            <Box display="flex" justifyContent="space-between">
              <Label>{keyName}</Label>
            </Box>
            <Input
              name="keyNameValue"
              showLabel={false}
              fullWidth
              label={keyName}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 100) {
                  setTouched({ ...touched, keyNameValue: false });
                  setFieldValue("keyNameValue", inputValue);
                }
              }}
              placeholder={secretKeyPlaceholder}
              type="text"
              value={values.keyNameValue}
              error={touched.keyNameValue && Boolean(errors.keyNameValue)}
              onBlur={() => setTouched({ ...touched, keyNameValue: true })}
            />
          </Box>
          <Box marginBottom="30px">
            <InputErrorMessage
              {...defaultErrorMessageProps}
              inputName="keyNameValue"
            />
          </Box>
          <Box width={600} className="margin-top30">
            <Box display="flex" justifyContent="space-between">
              <Label>{description}</Label>
            </Box>
            <Input
              name={description}
              showLabel={false}
              fullWidth
              label={description}
              onChange={(e) => {
                const inputValue = e.target.value;
                if (inputValue.length <= 255) {
                  setTouched({ ...touched, keyNameValue: false });
                  setFieldValue("description", inputValue);
                }
              }}
              placeholder={addDescription}
              type="text"
              value={values.description}
              error={touched.description && Boolean(errors.description)}
              onBlur={() => setTouched({ ...touched, description: true })}
            />
          </Box>
          <Box marginBottom="40px">
            <InputErrorMessage
              {...defaultErrorMessageProps}
              inputName="description"
            />
          </Box>
          <Box className="restricted-key-create">
            <TableContainer sx={{ overflowX: "unset", height: "100%" }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{ height: "100%", position: "relative" }}
              >
                <TableHead className="table-header">
                  <TableRow>
                    <TableCell className="table-header-module">
                      <Text size={20} className="default-text">
                        {modules}
                      </Text>
                    </TableCell>
                    {permissionHeader?.map((permission, index) => (
                      <TableCell key={index} align="center">
                        <Text
                          sx={{ textAlign: "left" }}
                          size={16}
                          className="grey-text"
                        >
                          {permission}
                        </Text>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                {resourceLoading ? (
                  resourceLoader()
                ) : (
                  <TableBody className="table-body">
                    <Box borderRight="1px solid #e4e9ee">
                      {resourceBuckets?.map((bucket, index) => (
                        <Fragment key={bucket?.id}>
                          <TableRow className="table-subheader">
                            <TableCell className="table-subheader-module">
                              <Box
                                style={{
                                  display: "flex",
                                  gap: "1rem",
                                }}
                              >
                                <Text size={16} className="default-text">
                                  {handleShowBucketAndResourceName(
                                    bucket?.id,
                                    bucket?.name
                                  )}
                                </Text>
                                {/* <CustomLink
                                size="small"
                                alignIcon="end"
                                withIcon={
                                  <ArrowForwardIcon
                                    style={{ fontSize: "14px" }}
                                  />
                                }
                                href={`${process.env.REACT_APP_SPEED_API_URL}reference/introduction`}
                                target="_blank"
                              >
                                {viewDocs}
                              </CustomLink> */}
                              </Box>
                            </TableCell>
                            <TableCell align="center">
                              <CustomCheckbox
                                onChange={(_e) => {
                                  handleBucketsPermission(
                                    bucket?.id,
                                    write.toLowerCase(),
                                    values?.keyPermissions?.includes(
                                      bucket.id + _write
                                    )
                                  );
                                }}
                                checked={values?.keyPermissions?.includes(
                                  bucket.id + _write
                                )}
                                disabled={!bucket?.write}
                                name={bucket?.name}
                                label=""
                              />
                            </TableCell>
                            <TableCell align="center">
                              <CustomCheckbox
                                id={index}
                                onChange={() =>
                                  handleBucketsPermission(
                                    bucket?.id,
                                    read.toLowerCase(),
                                    values?.keyPermissions?.includes(
                                      bucket.id + _read
                                    )
                                  )
                                }
                                checked={values?.keyPermissions?.includes(
                                  bucket.id + _read
                                )}
                                disabled={!bucket?.read}
                                name={bucket?.name}
                                label=""
                              />
                            </TableCell>
                            <TableCell align="center">
                              <CustomCheckbox
                                id={index}
                                onChange={() => {
                                  handleBucketsNonePermission(
                                    bucket?.id,
                                    bucketText,
                                    noneCheckedPermissions?.includes(bucket?.id)
                                  );
                                }}
                                checked={noneCheckedPermissions?.includes(
                                  bucket?.id
                                )}
                                name={bucket?.name}
                                label=""
                              />
                            </TableCell>
                          </TableRow>
                          {bucket?.resources?.map((bucketResource) => (
                            <TableRow
                              className="table-cell"
                              key={bucketResource?.id}
                            >
                              <TableCell
                                className="table-cell-module"
                                align="center"
                                sx={{
                                  width: "100%",
                                }}
                              >
                                <Text
                                  size={16}
                                  variant="h4"
                                  font="regular"
                                  className="grey-text"
                                >
                                  {handleShowBucketAndResourceName(
                                    bucketResource?.id,
                                    bucketResource?.name
                                  )}
                                </Text>
                              </TableCell>
                              <TableCell align="center">
                                <CustomCheckbox
                                  onChange={() =>
                                    handleBucketResourcePermissions(
                                      bucket?.id,
                                      bucketResource?.id,
                                      write.toLowerCase(),
                                      values?.keyPermissions?.includes(
                                        bucketResource.id + _write
                                      )
                                    )
                                  }
                                  checked={
                                    values?.keyPermissions?.includes(
                                      bucketResource.id + _write
                                    ) ||
                                    (bucketResource?.write &&
                                      values.keyPermissions.includes(
                                        bucket?.id + _write
                                      ))
                                  }
                                  disabled={
                                    !bucketResource?.write ||
                                    values.keyPermissions.includes(
                                      bucket?.id + _write
                                    ) ||
                                    values.keyPermissions.includes(
                                      bucket?.id + _read
                                    )
                                  }
                                  name={bucketResource?.name}
                                  label=""
                                />
                              </TableCell>
                              <TableCell align="center">
                                <CustomCheckbox
                                  onChange={() =>
                                    handleBucketResourcePermissions(
                                      bucket?.id,
                                      bucketResource?.id,
                                      read.toLowerCase(),
                                      values?.keyPermissions?.includes(
                                        bucketResource.id + _read
                                      )
                                    )
                                  }
                                  checked={
                                    values?.keyPermissions?.includes(
                                      bucketResource.id + _read
                                    ) ||
                                    (bucketResource?.read &&
                                      values.keyPermissions.includes(
                                        bucket?.id + _read
                                      ))
                                  }
                                  disabled={
                                    !bucketResource?.read ||
                                    values.keyPermissions.includes(
                                      bucket?.id + _read
                                    ) ||
                                    values.keyPermissions.includes(
                                      bucket?.id + _write
                                    )
                                  }
                                  name={bucketResource?.name}
                                  label=""
                                />
                              </TableCell>
                              <TableCell align="center">
                                <CustomCheckbox
                                  onChange={() =>
                                    handleBucketsNonePermission(
                                      bucketResource?.id,
                                      resourceText,
                                      noneCheckedPermissions?.includes(
                                        bucketResource?.id
                                      )
                                    )
                                  }
                                  checked={noneCheckedPermissions?.includes(
                                    bucketResource?.id
                                  )}
                                  disabled={
                                    values.keyPermissions.includes(
                                      bucket?.id + _write
                                    ) ||
                                    values.keyPermissions.includes(
                                      bucket?.id + _read
                                    )
                                  }
                                  name={bucketResource?.name}
                                  label=""
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </Fragment>
                      ))}
                    </Box>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default RestrictedKeyTypeList;
