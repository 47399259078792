export const SET_IS_LOGGED_IN = "SET_IS_LOGGED_IN";
export const SET_USER = "SET_USER";
export const SET_INITIAL_DATA = "SET_INITIAL_DATA";
export const RESET_STORE = "RESET_STORE";
export const SHOW_TOAST_MESSAGE = "SHOW_TOAST_MESSAGE";
export const HIDE_TOAST_MESSAGE = "HIDE_TOAST_MESSAGE";
export const SET_CONNECT_PAGE_DATA = "SET_CONNECT_PAGE_DATA";
export const SET_CONNECT_PAGE_LOADING = "SET_CONNECT_PAGE_LOADING";
export const SET_SHOULD_LOADER_SHOW = "SET_SHOULD_LOADER_SHOW";
export const SET_SELECTED_ACCOUNT_ID = "SET_SELECTED_ACCOUNT_ID";
