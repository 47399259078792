import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import classNames from "classnames";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import history from "@speed/common/src/components/history";
import Text from "@speed/common/src/components/Text/Text";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  moreResultsText,
  noSearchResultfound,
  searchResultForText,
  viewAllResultsLabel,
  webPageResultLabel,
} from "../messages";
import { Link } from "react-router-dom";
import NoDataAvailable from "@speed/common/src/components/NoDataAvailable";
import { emptySearchResult } from "../images";
import {
  loadingMsg,
  tryAgainText,
} from "@speed/common/src/components/messages";
import CheckoutLinkTable from "../Common/CheckoutLinkTable";
import PaymentLinkTable from "../Common/PaymentLinkTable";
import PaymentTable from "../Common/PaymentTable";
import { setSearchedModuleName } from "../../redux/common/actions";
import { searchableModules } from "@speed/common/src/components/constants";
import { searchbarIcons } from "@speed/common/src/components/images";
import { getWebSearchAPIResults } from "../constants";
import _ from "lodash";
import BackdropLoader from "@speed/common/src/components/BackdropLoader";
import CustomerTable from "../Customer/CustomerTable";
import ProductsList from "../Products/ProductsList";
import SessionList from "../Sessions/SessionsList";
import AllWithdraw from "../Withdraws/AllWithdraws";

export default function SearchDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const [inputString, setInputString] = useState("");
  const [isModuleResultPage, setModuleResultPage] = useState(false);
  const [searchResultData, setSearchResultData] = useState();
  const [moduleSearchRsultData, setModuleSearchResultData] = useState([]);
  const [searchNextPage, setSearchNextPage] = useState(null);
  const [noModuleResults, setNoModuleResults] = useState(false);
  const [searchResultContent, setSearchResultContent] = useState([]);
  const { liveMode, user } = useSelector((state) => state.auth);
  const { webPageSearchResults, searchedModuleName } = useSelector(
    (state) => state.common
  );
  const searchQueryString = new URLSearchParams(history.location.search).get(
    "query"
  );

  const dispatch = useDispatch();
  const isAvailableModuleSearchResults =
    isModuleResultPage && moduleSearchRsultData?.length > 0;
  const getInitialLoadModuleName = () => {
    let moduleNameWithFilter = searchQueryString.split(" ").filter((string) => {
      return string.includes("is:");
    })[0];

    return (
      moduleNameWithFilter &&
      searchableModules[moduleNameWithFilter.replace("is:", "")]
    );
  };

  const getModuleSearchResultData = async (resultsData) => {
    if (searchQueryString) {
      const apiSearchResponse = await getWebSearchAPIResults(
        searchQueryString,
        searchNextPage,
        user?.date_format
      );

      if (apiSearchResponse?.has_more) {
        setSearchNextPage(apiSearchResponse?.next_page);
      } else {
        setSearchNextPage(null);
      }

      let concatedResultData;
      if (apiSearchResponse?.data?.length > 0) {
        concatedResultData = searchNextPage
          ? resultsData.concat(apiSearchResponse?.data)
          : apiSearchResponse?.data;
        setModuleSearchResultData(concatedResultData);
      } else {
        setSearchNextPage(null);
        concatedResultData = moduleSearchRsultData;
        setNoModuleResults(moduleSearchRsultData?.length ? false : true);
      }

      return {
        concatedResultData,
        hasMore: apiSearchResponse?.has_more,
      };
    }
  };

  useEffect(async () => {
    if (searchQueryString) {
      setSearchResultData();
      setModuleSearchResultData([]);
      setSearchNextPage(null);
      setInputString(searchQueryString);
      setNoModuleResults(false);

      const isModulePage = searchQueryString.includes("is:");
      setModuleResultPage(isModulePage);
      const moduleName = getInitialLoadModuleName();
      moduleName
        ? dispatch(setSearchedModuleName(moduleName))
        : setModuleResultPage(false);

      setIsLoading(true);
      await getWebSearchAPIResults(searchQueryString, null, user?.date_format)
        .then((response) => {
          setIsLoading(false);
          if (response?.data?.length > 0) {
            const filterByModuleData = _.mapValues(
              _.groupBy(response?.data, "object"),
              (results) => results
            );
            if (isModulePage) {
              setModuleSearchResultData(response?.data);
            } else {
              setSearchResultData({
                data: filterByModuleData,
                facet: response?.facet,
              });
            }
          } else {
            setSearchResultData();
            setModuleSearchResultData([]);
          }
        })
        .catch(() => {
          setIsLoading(false);
        });
    } else {
      setInputString("");
      history.push("/dashboard");
    }
  }, [searchQueryString, liveMode]);

  const SearchResultTextHeader = ({ searchResultFor }) => {
    const headerTitle = (
      <>
        {searchResultForText(isAvailableModuleSearchResults)}
        {"\u00A0"}
        {isAvailableModuleSearchResults ? (
          searchResultFor
        ) : (
          <>
            &quot;
            <Text
              className="result-search-text"
              component="span"
              font="semibold"
              noWrap
            >
              {searchResultFor}
            </Text>
            &quot;
          </>
        )}
      </>
    );

    return (
      <>
        <Box className="search-result-for-header">
          <Text
            className="default-text search-result-for-txt"
            variant="h5"
            size={24}
          >
            {headerTitle}
          </Text>
        </Box>
        {isAvailableModuleSearchResults && <CustomDivider />}
      </>
    );
  };

  const WebPagesResultContent = () => {
    return (
      <Box className="web-page-result-wrapper">
        <Box className="web-app-title-text">
          <Text className="default-text" variant="h5" size={20}>
            {webPageResultLabel}
          </Text>
        </Box>
        <CustomDivider className="margin-top15" />
        {webPageSearchResults.map((result, index) => {
          let listProps = {};
          if (result.isExternalLink) {
            listProps.onClick = () => {
              window.open(result.href, "_blank", "noopener");
            };
          } else {
            listProps.to = result.href;
            listProps.component = Link;
          }
          return (
            <React.Fragment key={index}>
              <List className="web-page-result" key={index}>
                <ListItem disablePadding>
                  <ListItemButton {...listProps}>
                    <ListItemIcon>{searchbarIcons[result.icon]}</ListItemIcon>
                    <ListItemText primary={result.text} />
                  </ListItemButton>
                </ListItem>
              </List>
              <CustomDivider />
            </React.Fragment>
          );
        })}
      </Box>
    );
  };

  const MoreResultsContent = () => {
    const facetMappingObj = {
      tbl_checkout_link: {
        facetIconName: "checkoutLinks",
        facetName: "checkout.link",
        facetQueryUrl: "checkout_link",
      },
      tbl_checkout_session: {
        facetIconName: "checkoutSessions",
        facetName: "checkout.session",
        facetQueryUrl: "checkout_session",
      },
      tbl_payment_link: {
        facetIconName: "paymentLinks",
        facetName: "payment.link",
        facetQueryUrl: "payment_link",
      },
      tbl_payment: {
        facetIconName: "payments",
        facetName: "payment",
        facetQueryUrl: "payment",
      },
      tbl_withdraw: {
        facetIconName: "withdraws",
        facetName: "withdraw",
        facetQueryUrl: "withdraw",
      },
      tbl_customers: {
        facetIconName: "customers",
        facetName: "customer",
        facetQueryUrl: "customer",
      },
      tbl_product: {
        facetIconName: "products",
        facetName: "product",
        facetQueryUrl: "product",
      },
    };

    let facetsData;
    if (searchResultData?.facet) {
      facetsData = Object.keys(searchResultData?.facet)
        .map((key) => ({
          facetName: key,
          facetCount: searchResultData?.facet[key],
          facetQueryUrl: facetMappingObj[key].facetQueryUrl,
          iconName: facetMappingObj[key].facetIconName,
        }))
        .filter(
          (facet) =>
            !Object.keys(searchResultData?.data).includes(
              facetMappingObj[facet.facetName]?.facetName
            )
        );
    }

    return (
      <>
        {facetsData?.length > 0 && (
          <Box className="web-page-result-wrapper">
            <Box className="web-app-title-text">
              <Text className="default-text" variant="h5" size={20}>
                {moreResultsText}
              </Text>
            </Box>
            <CustomDivider className="margin-top15" />
            {facetsData?.length > 0 &&
              facetsData.map((facet, index) => {
                return (
                  <React.Fragment key={index}>
                    <List className="web-page-result" key={index}>
                      <ListItem disablePadding>
                        <ListItemButton
                          component={Link}
                          to={
                            "/search" +
                            `?query=${encodeURIComponent(
                              `is:${facet.facetQueryUrl} ${inputString}`
                            )}`
                          }
                        >
                          <ListItemIcon>
                            {searchbarIcons[facet.iconName]}
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Text
                                className="default-text"
                                font="regular"
                                variant="h3"
                                size={14}
                              >
                                {searchableModules[facet.facetQueryUrl]}
                              </Text>
                            }
                          />
                          <Text
                            className="default-text"
                            variant="h3"
                            size={14}
                            sx={{
                              color: "#2a67ff !important",
                              marginRight: "10px",
                            }}
                          >
                            {`${viewAllResultsLabel[0]} ${facet.facetCount} ${
                              facet.facetCount === 1
                                ? viewAllResultsLabel[1]
                                : viewAllResultsLabel[2]
                            }`}
                          </Text>
                        </ListItemButton>
                      </ListItem>
                    </List>
                    <CustomDivider />
                  </React.Fragment>
                );
              })}
          </Box>
        )}
      </>
    );
  };

  useEffect(() => {
    let resultContent = [];
    if (isModuleResultPage && searchedModuleName && !noModuleResults) {
      const renderModulePageTable = {
        "Checkout Links": (
          <CheckoutLinkTable
            getModuleSearchResultData={getModuleSearchResultData}
            inputString={inputString}
            searchResultData={moduleSearchRsultData}
          />
        ),
        "Checkout Sessions": (
          <SessionList
            getModuleSearchResultData={getModuleSearchResultData}
            inputString={inputString}
            searchResultData={moduleSearchRsultData}
          />
        ),
        "Payment Links": (
          <PaymentLinkTable
            getModuleSearchResultData={getModuleSearchResultData}
            inputString={inputString}
            searchResultData={moduleSearchRsultData}
          />
        ),
        Payments: (
          <PaymentTable
            getModuleSearchResultData={getModuleSearchResultData}
            inputString={inputString}
            searchResultData={moduleSearchRsultData}
          />
        ),
        Withdraws: (
          <AllWithdraw
            getModuleSearchResultData={getModuleSearchResultData}
            inputString={inputString}
            searchResultData={moduleSearchRsultData}
          />
        ),
        Customers: (
          <CustomerTable
            getModuleSearchResultData={getModuleSearchResultData}
            inputString={inputString}
            searchResultData={moduleSearchRsultData}
          />
        ),
        Products: (
          <ProductsList
            getModuleSearchResultData={getModuleSearchResultData}
            inputString={inputString}
            searchResultData={moduleSearchRsultData}
          />
        ),
      };
      resultContent = renderModulePageTable[searchedModuleName];
    } else if (
      !isModuleResultPage &&
      (webPageSearchResults?.length > 0 || searchResultData?.data)
    ) {
      resultContent = [
        webPageSearchResults?.length > 0 && <WebPagesResultContent />,
        <>
          {searchResultData?.data && (
            <>
              {searchResultData?.data["payment"] && (
                <PaymentTable
                  searchQueryUrl="payment"
                  inputString={inputString}
                  showHorizontalTable
                  searchResultData={searchResultData?.data["payment"]}
                  totalNumOfSearchResults={searchResultData?.facet?.tbl_payment}
                />
              )}
              {searchResultData?.data["checkout.link"] && (
                <CheckoutLinkTable
                  searchQueryUrl="checkout_link"
                  inputString={inputString}
                  showHorizontalTable
                  searchResultData={searchResultData?.data["checkout.link"]}
                  totalNumOfSearchResults={
                    searchResultData?.facet?.tbl_checkout_link
                  }
                />
              )}
              {searchResultData?.data["checkout.session"] && (
                <SessionList
                  searchQueryUrl="checkout_session"
                  inputString={inputString}
                  showHorizontalTable
                  searchResultData={searchResultData?.data["checkout.session"]}
                  totalNumOfSearchResults={
                    searchResultData?.facet?.tbl_checkout_session
                  }
                />
              )}
              {searchResultData?.data["payment.link"] && (
                <PaymentLinkTable
                  searchQueryUrl="payment_link"
                  inputString={inputString}
                  showHorizontalTable
                  searchResultData={searchResultData?.data["payment.link"]}
                  totalNumOfSearchResults={
                    searchResultData?.facet?.tbl_payment_link
                  }
                />
              )}
              {searchResultData?.data["withdraw"] && (
                <AllWithdraw
                  searchQueryUrl="withdraw"
                  inputString={inputString}
                  showHorizontalTable
                  searchResultData={searchResultData?.data["withdraw"]}
                  totalNumOfSearchResults={
                    searchResultData?.facet?.tbl_withdraw
                  }
                />
              )}
              {searchResultData?.data["customer"] && (
                <CustomerTable
                  searchQueryUrl="customer"
                  inputString={inputString}
                  showHorizontalTable
                  searchResultData={searchResultData?.data["customer"]}
                  totalNumOfSearchResults={
                    searchResultData?.facet?.tbl_customers
                  }
                />
              )}
              {searchResultData?.data["product"] && (
                <ProductsList
                  searchQueryUrl="product"
                  inputString={inputString}
                  showHorizontalTable
                  searchResultData={searchResultData?.data["product"]}
                  totalNumOfSearchResults={searchResultData?.facet?.tbl_product}
                />
              )}
            </>
          )}
        </>,
        <MoreResultsContent />,
      ];
    } else {
      resultContent = !isLoading && (
        <Box className="no-result-found-wrapper">
          <NoDataAvailable
            text={noSearchResultfound}
            image={emptySearchResult}
            subText={tryAgainText}
          />
        </Box>
      );
    }
    setSearchResultContent(resultContent);
  }, [
    isModuleResultPage,
    noModuleResults,
    searchResultData,
    moduleSearchRsultData,
    searchQueryString,
    searchNextPage,
  ]);

  let isResultAvailable =
    (!isModuleResultPage &&
      (webPageSearchResults?.length > 0 || searchResultData)) ||
    isAvailableModuleSearchResults;

  return (
    <>
      {inputString && (
        <Box
          className={classNames(
            "search-details-main-wrapper",
            !isResultAvailable && "no-result"
          )}
        >
          {isLoading ? (
            <BackdropLoader
              open={isLoading}
              alt="loading..."
              text={loadingMsg}
              customClass="loading-in-auth"
            />
          ) : (
            <>
              <SearchResultTextHeader
                searchResultFor={
                  isAvailableModuleSearchResults
                    ? searchedModuleName
                    : inputString
                }
              />
              <Box
                className={classNames(
                  "result-content",

                  isAvailableModuleSearchResults && "module-results"
                )}
              >
                {searchResultContent}
              </Box>
            </>
          )}
        </Box>
      )}
    </>
  );
}
