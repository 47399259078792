import React, { useEffect, useState } from "react";
import { Box, Skeleton } from "@mui/material";
import {
  appStoreWalletURL,
  callStrapiAPIInterface,
  chromeExtensionURL,
  playStoreWalletURL,
} from "../constants";
import { setCaughtErrorInStrapi } from "../../redux/common/actions";
import Text from "@speed/common/src/components/Text/Text";
import "../../assets/styles/wallet-app.scss";
import {
  peopleLogo,
  posMobileLogo,
  speedLogoOnboarding,
} from "@speed/common/src/components/images";
import {
  alreadyDownloadedMsg,
  appStore,
  availableOn,
  customers,
  googlePlayStore,
  hiddenText,
  posMobile,
  tenThousandPlus,
  walletAdMsg,
} from "../messages";
import { appStoreBadge, googlePlayBadge, webStoreAvailable } from "../images";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import NeedHelp from "../GetStarted/NeedHelp";
import { useDispatch } from "react-redux";

const UseCaseLoading = () => (
  <Box marginBottom="50px" width="456px" marginRight="60px">
    <Box className="use-case-list-heading">
      <Skeleton
        variant="circular"
        animation="wave"
        className="get-started-loader"
        sx={{
          marginRight: "14px",
        }}
      />
      <Skeleton
        animation="wave"
        width={290}
        height={18}
        className="get-started-loader"
      />
    </Box>
    <Text
      variant="body1"
      className="use-case-list-desc"
      font="regular"
      size={16}
    >
      {Array(3)
        .fill()
        .map((item) => {
          return (
            <Skeleton
              animation="wave"
              width={418}
              height={10}
              className="get-started-loader"
            />
          );
        })}
      <span style={{ display: "none" }}>{hiddenText}</span>
    </Text>
  </Box>
);

const UseCaseList = ({ useCase }) => {
  return (
    <Box
      marginBottom="50px"
      width="456px"
      marginRight="60px"
      sx={{ display: "flex", justifyContent: "space-between" }}
    >
      <Box>
        <img src={useCase?.icon?.data?.attributes?.url} alt="use-case-icon" />
      </Box>
      <Box sx={{ paddingLeft: "14px" }}>
        <Text font="semi-bold" size={22} style={{ lineHeight: "130%" }}>
          {useCase?.title}
        </Text>
        <Text
          variant="body1"
          className="use-case-list-desc"
          font="regular"
          size={16}
        >
          {useCase?.subtitle}
        </Text>
      </Box>
    </Box>
  );
};

const WalletApp = () => {
  const [loading, setLoading] = useState(false);
  const [walletGetStarted, setWalletGetStarted] = useState({});

  const dispatch = useDispatch();

  useEffect(() => {
    getWalletGetStarted();
  }, []);

  const getWalletGetStarted = () => {
    setLoading(true);
    callStrapiAPIInterface(
      "GET",
      "/api/wallet-account-header?populate[0]=wallet_app_page&populate[1]=wallet_app_page.icon&populate[2]=wallet_app_page.use_cases&populate[3]=wallet_app_page.use_cases.icon"
    )
      .then((response) => {
        setWalletGetStarted(response?.data?.attributes?.wallet_app_page);
        setLoading(false);
      })
      .catch((error) => {
        dispatch(setCaughtErrorInStrapi(true));
        setLoading(false);
      });
  };

  return (
    <>
      <Box className="get-started-box">
        <Box className="get-started-header-box">
          <Box className="get-started-header-content">
            <Box paddingLeft="50px">
              <Box
                style={{
                  marginTop: "50px",
                  lineHeight: "160%",
                }}
              >
                {loading ? (
                  <Skeleton
                    animation="wave"
                    width={241}
                    height={60}
                    sx={{ bgcolor: "#C4CCD2 !important", borderRadius: "26px" }}
                  />
                ) : (
                  <img
                    height="36px"
                    alt={walletGetStarted?.icon?.data?.attributes?.name}
                    src={walletGetStarted?.icon?.data?.attributes?.url}
                  />
                )}
              </Box>
              <Text size={48} font="bold" className="wallet-title">
                {loading ? (
                  <Skeleton
                    animation="wave"
                    width={669}
                    height={45}
                    sx={{ bgcolor: "#EAEEF1 !important", borderRadius: "26px" }}
                  />
                ) : (
                  walletGetStarted?.title
                )}
              </Text>
              <Text
                variant="body1"
                className="grey-text"
                font="regular"
                size={20}
                style={{
                  lineHeight: "150%",
                  width: "575px",
                  marginTop: "10px",
                }}
              >
                {loading ? (
                  <>
                    <Skeleton
                      animation="wave"
                      width={669}
                      height={27}
                      sx={{
                        bgcolor: "#EAEEF1 !important",
                        borderRadius: "26px",
                        marginBottom: "10px",
                      }}
                    />
                    <Skeleton
                      animation="wave"
                      width={241}
                      height={27}
                      sx={{
                        bgcolor: "#EAEEF1 !important",
                        borderRadius: "26px",
                      }}
                    />
                  </>
                ) : (
                  walletGetStarted?.subtitle
                )}
              </Text>
            </Box>
            <Box>
              <img
                src={speedLogoOnboarding}
                position="fixed"
                alt="speed-icon"
              />
            </Box>
          </Box>
          <Box
            className="advertisement-section"
            sx={{ display: "flex", justifyContent: "space-between" }}
            style={{
              backgroundImage: `url(
                ${process.env.REACT_APP_IMAGE_ICON_S3_URL}/app/whiteVector.png
              )`,
            }}
          >
            <Box>
              <Box className="qr-code-section">
                <Box sx={{ width: "484px" }}>
                  <Text
                    variant="h4"
                    font="regular"
                    size={20}
                    className="wallet-ad-msg"
                  >
                    {walletAdMsg}
                  </Text>
                  <Text
                    variant="h2"
                    font="bold"
                    size={24}
                    sx={{ paddingTop: "37px", color: "#2D3748" }}
                  >
                    {availableOn}
                  </Text>
                  <Box
                    sx={{
                      width: "340px",
                      display: "flex",
                      justifyContent: "space-between",
                      paddingTop: "14px",
                    }}
                  >
                    <CustomLink
                      size="large"
                      href={playStoreWalletURL}
                      target="_blank"
                      style={{ marginRight: "12px" }}
                    >
                      <img src={googlePlayBadge} alt={googlePlayStore} />
                    </CustomLink>
                    <CustomLink
                      size="large"
                      href={appStoreWalletURL}
                      target="_blank"
                      style={{ marginRight: "12px" }}
                    >
                      <img src={appStoreBadge} alt={appStore} />
                    </CustomLink>
                    <CustomLink
                      size="large"
                      href={chromeExtensionURL}
                      target="_blank"
                    >
                      <img src={webStoreAvailable} alt={webStoreAvailable} />
                    </CustomLink>
                  </Box>
                </Box>
              </Box>
              <Box className="refrences">
                <Box>
                  <img src={peopleLogo} atl={customers} />
                </Box>
                <Box>
                  <Text
                    variant="h5"
                    font="regular"
                    size={16}
                    className="default-text"
                  >
                    {tenThousandPlus}
                  </Text>
                  <Text
                    variant="h5"
                    font="regular"
                    size={16}
                    className="default-text"
                  >
                    {alreadyDownloadedMsg}
                  </Text>
                </Box>
              </Box>
            </Box>
            <Box>
              <Box className="pos-mobile-logo">
                <img src={posMobileLogo} alt={posMobile} />
              </Box>
            </Box>
          </Box>

          <Box className="get-started-use-cases-box">
            <Text
              variant="h5"
              className="use-case-text"
              font="semibold"
              size={18}
            >
              {loading ? (
                <Skeleton
                  animation="wave"
                  width={110}
                  height={22}
                  className="get-started-loader"
                />
              ) : (
                walletGetStarted?.use_cases_title?.toUpperCase()
              )}
            </Text>
            <Box className="use-case-list">
              {loading
                ? Array(2)
                    .fill()
                    .map((val) => <UseCaseLoading key={val} />)
                : walletGetStarted?.use_cases?.map((useCase) => (
                    <UseCaseList key={useCase?.id} useCase={useCase} />
                  ))}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="main-content-initial-setup">
        <NeedHelp />
      </Box>
    </>
  );
};

export default WalletApp;
