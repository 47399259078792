import React from "react";
import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import { Button } from "@speed/common/src/components/Button/Button";
import history from "./history";

const LinkNotFound = ({ icon, title, subTitle, btnText, redirect = null }) => {
  const redirectToListPage = () => {
    const pathToRedirect = history?.location?.pathname?.split("/")?.[1];
    pathToRedirect && history.push(`/${pathToRedirect}`);
  };

  return (
    <Box className="link-not-found-wrapper">
      <Box className="center-icon">
        <Box className="warning-container">{icon}</Box>
      </Box>
      <Text
        size={18}
        font="semibold"
        align="center"
        className="not-found-link-title"
      >
        {title}
      </Text>
      <Text
        size={14}
        font="regular"
        align="center"
        variant="subtitle1"
        className="not-found-link-sub-title"
      >
        {subTitle}
      </Text>
      <Button
        className="view-live-mode-link-btn"
        label={btnText}
        color="primary"
        onClick={redirect || redirectToListPage}
      />
    </Box>
  );
};

export default LinkNotFound;
