import React, { useContext, useEffect, useState } from "react";
import { Input } from "@speed/common/src/components/Input/Input";
import InfoIcon from "@mui/icons-material/Info";
import {
  passwordLabel,
  passwordPlaceholder,
} from "@speed/common/src/components/messages";
import { useFormik } from "formik";
import Box from "@mui/material/Box";
import * as yup from "yup";
import PasswordPolicy from "../../PasswordPolicy";
import { ConnectContext } from "../../../contexts/contexts";
import UserBox from "../../UserBox";

const PasswordScreen = (props) => {
  const { emailScreen, email, setPassword, setEmailScreen } = props;
  const [opacity, setOpacity] = useState(0);

  useState(
    () =>
      setTimeout(() => {
        setOpacity(emailScreen ? 0 : 1);
      }, 300),
    [emailScreen]
  );

  // Get context value using consumer
  const connectPageData = useContext(ConnectContext);

  const validationSchema = yup.object({
    password: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      password: "",
    },
    validationSchema: validationSchema,
  });

  const { values, isValid, isSubmitting, dirty, setSubmitting, setFieldValue } =
    formik;

  useEffect(() => {
    setPassword(values.password);
  }, [values.password]);

  useEffect(() => {
    connectPageData?.setButtonDisabled(!(isValid && dirty) || isSubmitting);
  }, [isValid, dirty, isSubmitting]);

  const handleIconClick = (e) => {
    //it triggers by pressing the enter key
    setEmailScreen(true);
    setPassword("");
  };

  return (
    <Box
      width="100%"
      sx={{ transition: "opacity 0.7s ease", opacity: opacity }}
    >
      <UserBox
        user={{ email, ...props.verifiedUserData }}
        handleIconClick={handleIconClick}
      />
      <Input
        autoFocus
        customClass="margin-top30"
        type="password"
        isPasswordVisible={true}
        onChange={(e) => {
          setSubmitting(false);
          setFieldValue("password", e.target.value);
        }}
        showLabel
        label={passwordLabel}
        value={values.password}
        name="password"
        fullWidth
        placeholder={passwordPlaceholder}
        isIcon={true}
        iconContent={<PasswordPolicy from="signup" />}
        icon={
          <InfoIcon
            style={{
              height: 14,
              width: 14,
              color: "#848b9e",
              marginLeft: "7px",
            }}
          />
        }
      />
    </Box>
  );
};

export default PasswordScreen;
