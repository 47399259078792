import { useDispatch, useSelector } from "react-redux";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import { verifyItsYouTitleLabel } from "../messages";
import { Grid } from "@mui/material";
import { setVerifyOTP } from "../../redux/common/actions";
import CommonVerifyOTPSection from "./CommonVerifyOTPSection";

const VerifyOTPModal = () => {
  const dispatch = useDispatch();
  const { openVerifyOTPModal } = useSelector((state) => state.common);

  const bodyContent = (
    <Grid container direction="row" justifyContent="center">
      <CommonVerifyOTPSection />
    </Grid>
  );

  return (
    <Modal
      className="verify-otp-modal"
      maxWidth="xs"
      body={bodyContent}
      handleClose={() => {
        dispatch(setVerifyOTP(false));
      }}
      open={openVerifyOTPModal}
      title={verifyItsYouTitleLabel}
    />
  );
};

export default VerifyOTPModal;
