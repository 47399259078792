import React, { useEffect, useState } from "react";
import CommonHeader from "../Common/CommonHeader";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { Box, Skeleton, TableCell, TableRow } from "@mui/material";
import { add, alreadyAdded, sats } from "../messages";
import Button from "@speed/common/src/components/Button/Button";
import { emptySubText } from "@speed/common/src/components/messages";
import { useSelector } from "react-redux";
import Text from "@speed/common/src/components/Text/Text";
import { clipboardElement } from "@speed/common/src/components/constants";
import CustomTable from "@speed/common/src/components/Table/Table";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { callAPIInterface, noOfRecords } from "../constants";
import { emptyTransaction } from "../images";

const columns = [
  {
    title: "Currency",
    paddingLeft: "36px !important",
    width: "200px !important",
  },
  {
    title: "Denomination",
    width: "150px !important",
  },
  { title: "Asset Id", width: "300px" },
  {
    title: "Action",
    width: "50px",
    align: "right",
    paddingRight: "24px !important",
  },
];

const AllAssets = () => {
  const queryParam = "?limit=" + noOfRecords;
  const liveMode = useSelector((state) => state.auth.liveMode);
  const walletAssets = useSelector((state) => state.common.walletAssets);

  const [isAddLoading, setIsAddLoading] = useState(false);
  const [assetId, setAssetId] = useState(null);
  const [accountAssets, setAccountAssets] = useState(null);
  const [isAssetsLoading, setIsAssetsLoading] = useState(true);
  // const [searchValue, setSearchValue] = useState("");
  // const [anchorEl, setAnchorEl] = useState(null);
  // const [rowId, setRowId] = useState(null);

  // const openPopper = Boolean(anchorEl);

  useEffect(() => {
    setAccountAssets([]);
    getAccountAssets(queryParam, true);
  }, [liveMode]);

  const getAssetIds = (accountAssetsData) => {
    const taprootAssetIds = accountAssetsData?.map((asset) => {
      return asset?.uuid;
    });
    return taprootAssetIds;
  };

  const getAccountAssets = (params, isLoading) => {
    isLoading && setIsAssetsLoading(true);
    callAPIInterface("GET", "/assets-account" + params, {})
      .then((res) => {
        if (res?.data) {
          setIsAssetsLoading(false);
          const assetIds = getAssetIds(res.data);
          setAccountAssets(assetIds);
        }
      })
      .catch(() => {
        setIsAssetsLoading(false);
      });
  };

  const addAssetsInAccount = (addedAssetId) => {
    const data = { asset_id: addedAssetId };
    setIsAddLoading(true);
    callAPIInterface("POST", "/assets-account", data)
      .then((res) => {
        setAccountAssets([...accountAssets, res?.uuid]);
        setIsAddLoading(false);
      })
      .catch(() => {
        setIsAddLoading(false);
      });
  };

  // const handleClick = (event, id) => {
  //   setAnchorEl(anchorEl ? null : event.currentTarget);
  //   setRowId(id);
  // };

  // const handleRemoveClick = () => {
  //   setIsAddLoading(true);
  //   callAPIInterface("DELETE", `/assets-account/${"aam_lqm3abgbZQ1m3oka"}`)
  //     .then((res) => {
  //       setAnchorEl(null);
  //       setIsAddLoading(false);
  //     })
  //     .catch(() => {
  //       setIsAddLoading(false);
  //     });
  // };

  let columnsData = columns.map((column) => (
    <TableCell
      sx={{
        paddingLeft: column?.paddingLeft,
        width: column?.width,
        textAlign: column?.align,
        paddingRight: column?.paddingRight,
      }}
      key={column.title}
    >
      {column.title}
    </TableCell>
  ));

  const rowsData =
    !isAssetsLoading &&
    walletAssets?.length > 0 &&
    walletAssets.map((rowItem) => {
      return (
        <TableRow
          // className="clickable"
          // onClick={() => history.push(`/assets/${rowItem?.id}`)}
          key={rowItem?.id}
        >
          {rowItem.uuid === assetId && isAddLoading ? (
            columns.map((column) => {
              return (
                <TableCell
                  key={column?.title}
                  sx={{
                    minWidth: column.width,
                    padding: "18px 16px !important",
                  }}
                >
                  <Skeleton />
                </TableCell>
              );
            })
          ) : (
            <>
              <TableCell style={{ paddingLeft: "36px" }}>
                <Text font="semibold" size={16} withIcon="start">
                  <img
                    src={rowItem?.icon}
                    width="32px"
                    height="32px"
                    alt=""
                    style={{ marginRight: "10px", borderRadius: "20px" }}
                  />
                  {rowItem?.name}
                </Text>
              </TableCell>
              <TableCell sx={{ textAlign: "left" }}>{rowItem?.uuid}</TableCell>
              <TableCell style={{ textAlign: "left" }}>
                {rowItem?.id
                  ? clipboardElement(
                      rowItem?.id,
                      true,
                      "all-assets-details-clipboard",
                      "inputBox",
                      false
                    )
                  : "-"}
              </TableCell>
              <TableCell sx={{ textAlign: "right" }}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="end"
                  pr="14px"
                >
                  {rowItem?.uuid === sats ||
                  accountAssets?.includes(rowItem?.uuid) ? (
                    <Box display="flex" alignItems="center">
                      <CheckCircleIcon
                        className="success-icon"
                        sx={{ color: "#2ecc71 !important", mr: "4px" }}
                      />
                      <Text
                        size={16}
                        font="semibold"
                        className="grey-text"
                        sx={{ textWrap: "nowrap" }}
                      >
                        {alreadyAdded}
                      </Text>
                    </Box>
                  ) : (
                    <Box onClick={(event) => event.stopPropagation()}>
                      <Button
                        icon="addIcon"
                        className="add-icon"
                        label={add}
                        variant="outlined"
                        color="primary"
                        onClick={() => {
                          setAssetId(rowItem?.uuid);
                          addAssetsInAccount(rowItem?.id);
                        }}
                      />
                    </Box>
                  )}
                  {/* <MoreHorizIcon
                sx={{ ml: "24px" }}
                className="horizontal-dots-icon pointer-cursor"
                tabIndex={0}
                onClick={(event) => {
                  event.stopPropagation();
                  handleClick(event, rowItem?.id);
                }}
              /> */}
                </Box>
              </TableCell>
            </>
          )}
        </TableRow>
      );
    });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: isAssetsLoading,
    textOnNoData: emptySubText(liveMode, "assets"),
    noDataImage: emptyTransaction,
  };

  return (
    <Box className="main-content">
      <Box className="payment-links-wrapper section-wrapper">
        <CommonHeader />
        <CustomDivider />
        {/* <Box className="action-btn-wrapper mui-fixed">
          <Button
            className="add-icon"
            label={requestAssets}
            variant="outlined"
            color="primary"
            onClick={() => {}}
          />
        </Box>
        <InputBase
          value={searchValue}
          fullWidth
          className="assets-search-bar"
          placeholder={assetsSearchBar}
          inputProps={{ "aria-label": searchValue, maxLength: 50 }}
          onChange={(event) => setSearchValue(event.target.value)}
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon
                sx={{
                  height: "18px",
                  width: "18px",
                  marginLeft: "14px",
                  marginRight: "8px",
                }}
                htmlColor="#667085"
                onClick={() => {}}
              />
            </InputAdornment>
          }
          showLabel={false}
          type="text"
          disabled={false}
        />
        <CustomDivider /> */}
        <CustomTable {...tableProps} className="all-assets-table" />
        {/* <CustomPopper
          disablePortal={true}
          open={openPopper}
          anchorEl={anchorEl}
          position="bottom"
          handleClose={() => setAnchorEl(null)}
        >
          <List>
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleRemoveClick()}>
                <ListItemText primary={remove} className="text-danger" />
              </ListItemButton>
            </ListItem>
          </List>
        </CustomPopper> */}
      </Box>
    </Box>
  );
};

export default AllAssets;
