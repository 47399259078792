import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TableCell, TableRow, Box } from "@mui/material";
import { useSelector } from "react-redux";
import { Breadcrumbs } from "@speed/common/src/components/Breadcrumbs/Breadcrumbs";
import { generateBreadcrumbs } from "@speed/common/src/components/constants";
import CustomTable from "@speed/common/src/components/Table/Table";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { emptySubText } from "@speed/common/src/components/messages";
import { emptyEvents } from "@speed/common/src/components/images";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import {
  RouteBreadcrumbs,
  callAPIInterface,
  noOfRecords,
  dateTimeFormatInApp,
} from "./constants";
import { events } from "./Events/Events";

const ModuleEventList = (props) => {
  const breadcrumbs = generateBreadcrumbs(RouteBreadcrumbs, { ...props });
  const { ldFlag, urlParams } = props;
  const queryParam = "?limit=" + noOfRecords;
  const [tableRowSkeleton, setTableRowSkeleton] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [rows, setRows] = useState([]);
  const [queryParams, setQueryParams] = useState(queryParam);

  const history = useSelector((state) => state.common.history);
  const liveMode = useSelector((state) => state.auth.liveMode);

  const getAllEvents = (lines, params) => {
    setTableRowSkeleton(true);
    const filterEvents = {
      ids: [urlParams?.id],
    };
    callAPIInterface(
      "POST",
      `/events/filters${params}`,
      JSON.stringify(filterEvents)
    )
      .then((res) => {
        if (res) {
          setTableRowSkeleton(false);
          if (!res.has_more) {
            setHasMore(false);
          } else {
            setQueryParams(
              queryParam + "&ending_before=" + res.data[res.data.length - 1].id
            );
          }
          setRows(lines.concat(res.data));
        }
      })
      .catch(() => {
        setTableRowSkeleton(false);
      });
  };

  useEffect(() => {
    if (ldFlag) {
      setRows([]);
      setHasMore(true);
      getAllEvents([], queryParam);
    }
  }, [liveMode, ldFlag]);

  const columns = [
    { title: "Events", width: "500px", paddingLeft: "38px !important" },
    { title: "Date", width: "200px" },
  ];

  let columnsData = columns?.map((column, index) => (
    <TableCell
      key={index}
      sx={{ paddingLeft: column.paddingLeft, minWidth: column.width }}
    >
      {column.title}
    </TableCell>
  ));

  const loadMore = useCallback(() => {
    getAllEvents(rows, queryParams);
  }, [rows]);

  const rowsData = rows?.map((rowItem) => {
    return (
      <TableRow
        key={rowItem.id}
        className="clickable"
        onClick={() => history.push(`/events/${rowItem?.id}`)}
      >
        <TableCell sx={{ paddingLeft: "38px !important" }}>
          {events(rowItem)}
        </TableCell>
        <TableCell>{dateTimeFormatInApp(rowItem.created)}</TableCell>
      </TableRow>
    );
  });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
    className: "link-table",
    textOnNoData: emptySubText(liveMode, "events"),
    noDataImage: emptyEvents,
  };

  return (
    <Box className="section-wrapper">
      <Box className="payment-link-header-wrapper">
        <Breadcrumbs component={Link} breadcrumbData={breadcrumbs} />
      </Box>
      <CustomDivider />
      <Box className="payments-wrapper">
        <Box className="section-wrapper">
          <Box className="payment-wrapper">
            <Box className="main-content">
              {ldFlag ? <CustomTable {...tableProps} /> : <AccessDenied />}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ModuleEventList;
