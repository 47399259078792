import React from "react";
import { Switch } from "react-router-dom";
import PrivateRoute from "../../container/PrivateRoute";
import "../../assets/styles/affiliate-partners.scss";
import AffiliatePartners from "./AffiliatePartners";
import MerchantDetail from "./MerchantDetail";
import MerchantTransfers from "./MerchantTransfers";
import PartnerConnect from "./PartnerConnect";

const Partners = () => {
  return (
    <Switch>
      {["affiliate-partners", "affiliate-partners-invited"].map((routeURL) => (
        <PrivateRoute
          key={routeURL}
          exact
          path={`/partners/${routeURL}`}
          component={AffiliatePartners}
        />
      ))}

      <PrivateRoute
        exact
        path="/partners/affiliate-partners/:id"
        component={MerchantDetail}
      />
      <PrivateRoute
        exact
        path="/partners/affiliate-partners/:id/transfers"
        component={MerchantTransfers}
      />
      <PrivateRoute
        exact
        path="/partners/affiliate-partners-connect"
        component={PartnerConnect}
        isWalletAllowedRoute={true}
      />
    </Switch>
  );
};

export default Partners;
