import { Box, Grid } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import AlertMessage from "@speed/common/src/components/AlertMessage/AlertMessage";
import { CustomAutoComplete } from "@speed/common/src/components/AutoComplete/AutoComplete";
import { CustomAvatar } from "@speed/common/src/components/Avatar/Avatar";
import Button from "@speed/common/src/components/Button/Button";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { Input } from "@speed/common/src/components/Input/Input";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import PhoneNumberInput from "@speed/common/src/components/PhoneNumberInput/PhoneNumberInput";
import CustomSelect from "@speed/common/src/components/Select/Select";
import Text from "@speed/common/src/components/Text/Text";
import UploadFile from "@speed/common/src/components/UploadFile";
import { getCountryObj } from "@speed/common/src/components/constants";
import { Countries } from "@speed/common/src/components/country";
import {
  emailLabel,
  emailPlaceholder,
  invalidEmail,
} from "@speed/common/src/components/messages";
import { countryLabel, countryPlaceholder } from "@speed/common/src/messages";
import { useFormik } from "formik";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { updateUser } from "../../redux/auth/actions";
import {
  changeEmail,
  setSettingFormCancel,
  setSettingFormChange,
  setSettingFormValid,
  showToast,
  updateUserProfile,
} from "../../redux/common/actions";
import {
  TimeZoneSelect,
  callAPIInterface,
  concatedMobileNumber,
  dateFormatArray,
  genderArray,
  isAlphabet,
  mobileNumberWithoutCallingCode,
  validatePhoneNumber,
} from "../constants";
import { defaultUser } from "../images";
import {
  changeEmailLabel,
  changeEmailTitle,
  dateFormatLabel,
  dateFormatPlaceholder,
  firstNameLabel,
  firstNamePlaceholder,
  genderLabel,
  genderPlaceholder,
  invalidLabel,
  lastNameLabel,
  lastNamePlaceholder,
  mobileNoLabel,
  sendVerification,
  upload,
} from "../messages";
import ChangeEmail from "./ChangeEmail";

function ProfileForm() {
  const { settingFormSubmit, settingFormCancel } = useSelector(
    (state) => state.common
  );
  const user = useSelector((state) => state.auth.user);
  const [countryISOCode, setCountryISOCode] = useState();
  const [circleLoader, setCircleLoader] = useState(false);
  const [openChangeEmailModal, setOpenChangeEmailModal] = useState(false);
  const [isVerificationEmailSent, setIsVerificationEmailSent] = useState(false);
  const [formData, setFormData] = useState({});
  const [newEmail, setNewEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [disabledSendEmail, setDisabledSendEmail] = useState(true);
  const dispatch = useDispatch();

  const validationSchema = yup.object({
    first_name: yup.string().required("").matches(isAlphabet).trim(),
    last_name: yup.string().required("").matches(isAlphabet).trim(),
    email: yup.string().required("").email(invalidEmail),
    phone_number: yup.mixed().test({
      name: "phone_number",
      test: (phoneNumber) => validatePhoneNumber(phoneNumber),
    }),
    country: yup.string().required(),
  });

  const formik = useFormik({
    initialValues: {
      first_name: user && user.first_name ? user.first_name : "",
      last_name: user && user.last_name ? user.last_name : "",
      email: user && user.email ? user.email : "",
      gender: user && user.gender ? user.gender : "",
      calling_code: user && user.calling_code ? user.calling_code : "91",
      phone_number:
        user && user.phone_number
          ? concatedMobileNumber(user.calling_code, user.phone_number)
          : null,
      country: user && user.country ? user.country : null,
      language: user && user.language ? user.language : "English",
      timezone: user && user.timezone ? user.timezone : null,
      date_format: user && user.date_format ? user.date_format : "",
      profile_image:
        user && user.profile_images ? user.profile_images.large : defaultUser,
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
  });

  const {
    values,
    setFieldValue,
    resetForm,
    setTouched,
    isValid,
    dirty,
    isSubmitting,
    errors,
    touched,
  } = formik;

  const onChangeFormField = (fieldname, value) => {
    setFieldValue(fieldname, value);
    if (fieldname == "first_name" || fieldname == "last_name") {
      value = value.trim();
    }
    formData[fieldname] = value;
    setFormData(formData);
  };

  useEffect(() => {
    // Set country code according to calling_code
    let countryObj = getCountryObj("calling_code", values.calling_code);
    if (countryObj) setCountryISOCode(countryObj.iso2);
  }, [values.calling_code]);

  useEffect(() => {
    dispatch(setSettingFormValid(!(isValid && dirty) || isSubmitting));
  }, [isValid, dirty, isSubmitting]);

  useEffect(() => {
    dispatch(setSettingFormChange(dirty));
  }, [values]);

  useEffect(() => {
    // get values and call action to update this change into DB
    if (
      Object.keys(formData).length != 0 &&
      isValid &&
      dirty &&
      settingFormSubmit
    ) {
      let submitFormData = formData;
      if (formData.phone_number) {
        submitFormData = {
          ...formData,
          calling_code: values?.calling_code,
          phone_number: mobileNumberWithoutCallingCode(
            values?.calling_code,
            values.phone_number
          ),
        };
      }
      dispatch(updateUserProfile(submitFormData)).then((updatedUser) => {
        if (updatedUser) {
          dispatch(updateUser(updatedUser));
        }
      });
    }
    setFormData({});
  }, [settingFormSubmit]);

  useEffect(() => {
    if (settingFormCancel) {
      setFormData({});
      resetForm();
      dispatch(setSettingFormCancel(false));
    }
  }, [settingFormCancel]);

  const showLoader = (status) => {
    setCircleLoader(status);
  };

  const handleButtonClick = (e) => {
    if (e.keyCode === 13) {
      e.currentTarget.click();
    }
  };

  const changeEmailFooter = (
    <Button
      label={sendVerification}
      disabled={disabledSendEmail}
      onClick={() => {
        if (!emailError) {
          dispatch(changeEmail({ email: newEmail })).then((res) => {
            if (res) {
              setIsVerificationEmailSent(true);
            }
          });
        }
      }}
    ></Button>
  );

  const selectItems = (arrayValue) => {
    return arrayValue.map((option) => (
      <MenuItem key={option.value} value={option.value}>
        {option.label}
      </MenuItem>
    ));
  };

  const responseImage = (profile) => {
    onChangeFormField("profile_image", profile.file_name);
    setCircleLoader(false);
  };

  const handleUploadTempImage = async (file, fileName) => {
    try {
      const apiRes = await callAPIInterface(
        "POST",
        "/upload-image",
        null,
        file,
        fileName
      );
      responseImage(apiRes);
    } catch (error) {
      showLoader(false);
    }
  };

  return (
    <>
      <CustomDivider />
      <Box className="box-container">
        <Grid style={{ width: "400px" }}>
          <Box className="user-image-box">
            <CustomAvatar
              userName={user && user.name}
              size="large"
              isLoading={circleLoader}
              src={values.profile_image}
            />
            <Box className="user-image-desc">
              <Text
                variant="h5"
                size={20}
                font="semibold"
                style={{ marginBottom: "15px" }}
              >
                Change Profile Picture
              </Text>
              <label htmlFor="file_upload">
                <UploadFile
                  showLoader={(status) => showLoader(status)}
                  imgDimension={200}
                  apiFunc={handleUploadTempImage}
                  id="file_upload"
                  showToast={showToast}
                />
                <Button
                  variant="outlined"
                  component="span"
                  label={upload}
                  onKeyDown={handleButtonClick}
                ></Button>
              </label>
            </Box>
          </Box>

          <Input
            customClass="margin-top30"
            showLabel
            value={values.first_name}
            name="first_name"
            label={firstNameLabel}
            type="text"
            fullWidth
            inputProps={{ maxLength: 250 }}
            placeholder={firstNamePlaceholder}
            onBlur={() => setTouched({ ...touched, first_name: true })}
            onChange={(e) => {
              onChangeFormField("first_name", e.target.value);
            }}
          />
          {touched.first_name && Boolean(errors.first_name) && (
            <AlertMessage
              className="margin-top14"
              severity="error"
              message={invalidLabel(firstNameLabel)}
            />
          )}

          <Input
            customClass="margin-top30"
            showLabel
            value={values.last_name}
            name="last_name"
            label={lastNameLabel}
            type="text"
            fullWidth
            inputProps={{ maxLength: 250 }}
            placeholder={lastNamePlaceholder}
            onBlur={() => setTouched({ ...touched, last_name: true })}
            onChange={(e) => {
              onChangeFormField("last_name", e.target.value);
            }}
          />
          {touched.last_name && Boolean(errors.last_name) && (
            <AlertMessage
              className="margin-top14"
              severity="error"
              message={invalidLabel(lastNameLabel)}
            />
          )}

          <Input
            customClass="margin-top30"
            showLabel
            disabled
            label={emailLabel}
            {...(user?.signup_method === "email" && {
              actionLabel: changeEmailLabel,
            })}
            type="email"
            fullWidth
            name="email"
            value={values.email}
            actionLabelClick={() => {
              setOpenChangeEmailModal(true);
            }}
            placeholder={emailPlaceholder}
          />

          <CustomSelect
            name="gender"
            displayEmpty
            showLabel
            label={genderLabel}
            customClass="margin-top30"
            value={values.gender}
            placeholder={genderPlaceholder}
            MenuProps={{
              id: "gender-select",
            }}
            onChange={(e) => {
              onChangeFormField("gender", e.target.value);
            }}
            renderValue={(value) => {
              if (value) {
                return value;
              } else {
                return <Box> {genderPlaceholder} </Box>;
              }
            }}
          >
            {selectItems(genderArray)}
          </CustomSelect>

          <Box className="margin-top30">
            <PhoneNumberInput
              name="phone_number"
              label={mobileNoLabel}
              options={Countries}
              error={touched.phone_number && Boolean(errors.phone_number)}
              selectValue={values.calling_code}
              countryIsoCode={countryISOCode}
              MenuProps={{ id: "select-calling-code" }}
              inputValue={values.phone_number}
              showLabel={true}
              onOpen={() => document.body.classList.add("select-popover")}
              onClose={() =>
                setTimeout(() => {
                  document.body.classList.remove("select-popover");
                }, 400)
              }
              onChangeSelect={(value) => {
                onChangeFormField(
                  "calling_code",
                  typeof value === "object" ? value.calling_code : value
                );
              }}
              onBlur={() => setTouched({ ...touched, phone_number: true })}
              onChange={(value) => {
                setTouched({ ...touched, phone_number: false });
                onChangeFormField("phone_number", value);
              }}
            />
          </Box>

          <CustomAutoComplete
            name="country"
            options={Countries}
            getOptionLabel={(value) => {
              let country = getCountryObj(
                "short_name",
                typeof value === "object" ? value.short_name : value
              );
              return country ? country.short_name : "";
            }}
            onChange={(_e, value) => {
              onChangeFormField(
                "country",
                value && typeof value === "object" ? value.short_name : value
              );
            }}
            value={values.country}
            customClass="margin-top30"
            placeholder={countryPlaceholder}
            showLabel
            disableClearable={true}
            label={countryLabel}
            allowedAutoComplete={true}
          />

          {/*                      
                <CustomAutoComplete
                    name="language"
                    options={languageArray}
                    getOptionLabel={(value) => {
                        let language = _.find(languageArray, { id: value && typeof value === 'object' ? value.id : value })
                        return language.label
                    }}
                    onChange={(e, value) => { setFieldValue("language", value && typeof value === 'object' ? value.id : value) }}
                    value={values.language}
                    customClass="margin-top30"
                    placeholder={languagePlaceholder}
                    showLabel
                    label={languageLabel}
                /> */}

          <TimeZoneSelect
            name="timezone"
            value={values.timezone}
            onChange={(value) => {
              onChangeFormField("timezone", value);
            }}
          />

          <CustomSelect
            name="date_format"
            displayEmpty
            showLabel
            label={dateFormatLabel}
            customClass="margin-top30"
            value={values.date_format}
            placeholder={dateFormatPlaceholder}
            MenuProps={{
              id: "date-format",
            }}
            onChange={(e) => {
              onChangeFormField("date_format", e.target.value);
            }}
            renderValue={(value) => {
              if (value) {
                let dateFormatObj = _.find(dateFormatArray, { value: value });
                return dateFormatObj && dateFormatObj.label;
              } else {
                return <Box> {dateFormatPlaceholder} </Box>;
              }
            }}
          >
            {selectItems(dateFormatArray)}
          </CustomSelect>
        </Grid>
      </Box>
      <Modal
        maxWidth="xs"
        body={
          <ChangeEmail
            isVerificationEmailSent={isVerificationEmailSent}
            onChange={(email, error) => {
              setNewEmail(email);
              setEmailError(error);
            }}
            onDisabled={(value) => setDisabledSendEmail(value)}
            onResend={() => setIsVerificationEmailSent(false)}
          />
        }
        footer={!isVerificationEmailSent && changeEmailFooter}
        handleClose={() => {
          setOpenChangeEmailModal(false);
          setTimeout(() => {
            setIsVerificationEmailSent(false);
          }, 200);
        }}
        open={openChangeEmailModal}
        title={changeEmailTitle}
      />
    </>
  );
}

export default ProfileForm;
