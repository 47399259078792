import React from "react";
import { Box, Skeleton } from "@mui/material";

import {
  btcToSatsAmount,
  commaSeperatedCurrencyAmount,
  getDisplayAmount,
  satsToFiatAmount,
} from "@speed/common/src/components/constants";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Text from "@speed/common/src/components/Text/Text";

const EstimateFeesRowSection = (props) => {
  const {
    title,
    subtitle = "",
    feesAmount,
    isLast = false,
    loader,
    currencyToShow,
    isSelectedCurrencyBTC,
    isSelectedCurrencyFiat,
    amountInSats,
    exchangeRateValue,
    currencyCode,
    shouldRangeShow = false,
  } = props;

  const isFiatOrBtc = isSelectedCurrencyBTC || isSelectedCurrencyFiat;

  const formattedRangeAmt = `0 - ${commaSeperatedCurrencyAmount(
    feesAmount,
    "BTC"
  )}`;

  /**
   *  For estimated fee; Added check if address method is onchain or lightning
   *  @if method is onchain it will display the fee according to currency selected by user either in sats or btc
   *  @else method is lightning it will display the range of fee; for exp: 0-5 sats
   */
  const checkRangeInFees = shouldRangeShow
    ? formattedRangeAmt
    : commaSeperatedCurrencyAmount(feesAmount, "BTC");

  return (
    <>
      <Box className={`est-fees-row ${isLast && "last"}`}>
        <Text
          font="medium"
          className="default-text title-text"
          size={16}
          variant="subtitle1"
        >
          {title}
          {Boolean(subtitle) && (
            <>
              <br />
              <Text
                size={14}
                className="grey-text"
                font="regular"
                variant="subtitle1"
              >
                {subtitle}
              </Text>
            </>
          )}
        </Text>
        {loader ? (
          <Skeleton
            width={70}
            height={18}
            animation="wave"
            sx={{ bgcolor: "#eaeef1 !important" }}
          />
        ) : (
          feesAmount && (
            <Text
              font="regular"
              className="default-text"
              size={16}
              align="right"
              variant="subtitle1"
            >
              <b>{!isFiatOrBtc ? checkRangeInFees : feesAmount}</b>{" "}
              {currencyToShow}
              {isLast && (
                <>
                  <br />
                  {isFiatOrBtc && (
                    <Text
                      variant="inline"
                      size={14}
                      className="grey-text"
                      font="medium"
                    >
                      (~
                      {getDisplayAmount(
                        isSelectedCurrencyFiat,
                        isSelectedCurrencyFiat
                          ? satsToFiatAmount(amountInSats, exchangeRateValue)
                          : btcToSatsAmount(feesAmount),
                        currencyCode
                      )}{" "}
                      {isSelectedCurrencyFiat ? currencyCode : "SATS"})
                    </Text>
                  )}
                </>
              )}
            </Text>
          )
        )}
      </Box>
      {!isLast && <CustomDivider sx={{ margin: "16px 0" }} />}
    </>
  );
};

export default EstimateFeesRowSection;
