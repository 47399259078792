import React from "react";
import { paymentFailedImg } from "../../images";
import { Box } from "@mui/material";
import Text from "@speed/common/src/components/Text/Text";
import Clipboard from "@speed/common/src/components/Clipboard/Clipboard";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import {
  backToDashboard,
  error,
  redemptionFailed,
  redemptionFailedSubText,
} from "../../messages";
import Button from "@speed/common/src/components/Button/Button";

const RedemptionFailed = ({ receiveWithdrawError, handleModalClose }) => (
  <Box display="flex" flexDirection="column" alignItems="center">
    <img
      style={{ marginBottom: "16px", marginTop: "30px" }}
      height="54px"
      width="54px"
      src={paymentFailedImg}
      alt=""
    />
    <Text size={20} font="bold" className="default-text" variant="h6">
      {redemptionFailed}
    </Text>
    <Text
      size={16}
      font="regular"
      className="grey-text"
      variant="h6"
      sx={{ pt: "10px", width: "540px", textAlign: "center", mb: "40px" }}
    >
      {typeof receiveWithdrawError?.reason === "string"
        ? receiveWithdrawError?.reason
        : redemptionFailedSubText}
    </Text>
    {!receiveWithdrawError?.reason && (
      <Box className="redumption-failed-error-box">
        <Box p="21px 30px 16px 30px">
          <Box className="redumption-error-box-header">
            <Text size={16} font="bold" className="default-text" variant="h6">
              {error}
            </Text>
            <Clipboard
              text={receiveWithdrawError}
              style={{ height: "24px", width: "24px" }}
            />
          </Box>
          <CustomDivider
            style={{
              width: "480px",
              marginTop: "16px",
            }}
          />
        </Box>
        <Box
          style={{ wordBreak: "break-word" }}
          display="flex"
          alignItems="center"
          pl="30px"
          pr="30px"
        >
          <Text size={14} className="grey-text" font="regular" variant="h6">
            {receiveWithdrawError}
          </Text>
        </Box>
      </Box>
    )}
    <Box display="flex" mt={"146px"} mb={"30px"}>
      <Button
        label={backToDashboard}
        onClick={() => {
          handleModalClose();
        }}
        type="submit"
        className="payment-failed"
      />
    </Box>
  </Box>
);

export default RedemptionFailed;
