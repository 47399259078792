import { Box, Skeleton } from "@mui/material";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import Text from "@speed/common/src/components/Text/Text";
import React from "react";
import { from, swapAmount, swapSummary, to, youGet } from "../messages";
import { fetchPrecisedInteger } from "@speed/common/src/components/constants";
import {
  renderModuleRequestSummaryKeyValuePair,
  renderTermsAgreementCheckbox,
} from "../constants";

const SwapRequestSummary = (props) => {
  const { values, isChecked, setIsChecked, targetAmountLoading } = props;

  return (
    <Box className="margin-top30" display="flex" justifyContent="center">
      <Box width={600}>
        <Text className="default-text" size={20} variant="h2">
          {swapSummary}
        </Text>
        {renderModuleRequestSummaryKeyValuePair(from, values?.currency?.code)}
        {renderModuleRequestSummaryKeyValuePair(
          to,
          values?.target_currency?.code
        )}
        {renderModuleRequestSummaryKeyValuePair(
          swapAmount,
          `${fetchPrecisedInteger(values?.amount, 8)} ${values?.currency?.code}`
        )}
        <CustomDivider sx={{ mt: "24px", mb: "20px" }} />
        {renderModuleRequestSummaryKeyValuePair(
          youGet,
          <>
            {targetAmountLoading ? (
              <Skeleton width={100} />
            ) : (
              `${fetchPrecisedInteger(values?.target_amount, 8)} ${
                values?.target_currency?.code
              }`
            )}
          </>
        )}
        {renderTermsAgreementCheckbox(isChecked, setIsChecked)}
      </Box>
    </Box>
  );
};

export default SwapRequestSummary;
