import React, { useState } from "react";
import { Box, ListItem, ListItemButton } from "@mui/material";
import {
  edit,
  minimumBalancePayout,
  minimumPayout,
  sats,
} from "../../messages";
import Text from "@speed/common/src/components/Text/Text";
import {
  autoPayoutStatus,
  showAmount,
} from "@speed/common/src/components/constants";
import { useFlags } from "launchdarkly-react-client-sdk";
import CommonCardWithSwitch from "../../Common/CommonCardWithSwitch";
import { getWalletTypeName, payoutVerticalLine } from "../../constants";

const AlreadySetupPayoutComponent = ({
  scheduledPayoutData,
  isPayoutCreating,
  setIsDeactivating,
  setPayoutScheduleId,
  setSelectedPayout,
  formik,
  setIsEditing,
  setOpenAutoPayoutModal,
  setSelectedCurrency,
  selectedCurrency,
}) => {
  const [anchorEl, setAnchorEl] = useState(false);
  const { pschUpdateFe } = useFlags();

  const { setFieldValue, values } = formik;
  const scheduledPayoutCurrency = scheduledPayoutData?.uuid;

  const handleClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const showMinPayoutBalance = () => {
    return (
      <>
        {/* <Text
          className="grey-text"
          size={14}
          font="regular"
          variant="subtitle1"
          style={{ marginRight: "30px" }}
        >
          {`${fees} : `}
          <span style={{ color: "#667085" }}>
            {scheduledPayoutData?.fees_preference.charAt(0).toUpperCase() +
              scheduledPayoutData?.fees_preference.slice(1)}
          </span>
        </Text>

        {payoutVerticalLine()} */}

        <Text
          className="grey-text"
          size={14}
          font="regular"
          variant="subtitle1"
          style={{ marginRight: "30px" }}
        >
          {minimumPayout}
          <span style={{ color: "#667085" }}>
            {displayAmountCurrencyWise(scheduledPayoutData?.min_payout_amount)}
          </span>
        </Text>
        {payoutVerticalLine()}
      </>
    );
  };

  const displayAmountCurrencyWise = (amount) => {
    return showAmount({
      amount: amount,
      currency: scheduledPayoutCurrency,
      targetedCurrency:
        scheduledPayoutCurrency === sats ? "BTC" : scheduledPayoutCurrency,
      showCommaSeparated: true,
      appendCurrency: true,
    });
  };

  const bottomLineComponent = () =>
    scheduledPayoutData?.id && (
      <Box display="flex" flexDirection="row" ml="30px" mb="14px">
        {showMinPayoutBalance()}
        <Text
          className="grey-text"
          size={14}
          font="regular"
          variant="subtitle1"
          style={{ marginLeft: "30px" }}
        >
          {minimumBalancePayout}
          <span style={{ color: "#667085" }}>
            {displayAmountCurrencyWise(scheduledPayoutData?.min_balance)}
          </span>
        </Text>
      </Box>
    );

  const customPopperContent = (
    <ListItem disablePadding>
      <ListItemButton
        onClick={() => {
          setPayoutScheduleId(scheduledPayoutData?.id);
          setSelectedPayout?.(values.payout_type);
          setIsEditing(true);
          setOpenAutoPayoutModal?.(true);
          setSelectedCurrency?.(scheduledPayoutData?.uuid);
        }}
      >
        <Text
          size={14}
          className="grey-text pointer-cursor list-item"
          variant="subtitle1"
        >
          {edit}
        </Text>
      </ListItemButton>
    </ListItem>
  );

  const handleSwitchOnChange = (_e, checked) => {
    setIsDeactivating(true);
    setPayoutScheduleId(scheduledPayoutData?.id);
    setFieldValue("auto_payout_enabled", checked ? "active" : "deactivated");
    setSelectedCurrency?.(scheduledPayoutData?.uuid);
  };

  const isActive = scheduledPayoutData?.status === "active";
  const isLoader =
    scheduledPayoutData?.uuid === selectedCurrency && isPayoutCreating;

  return (
    <CommonCardWithSwitch
      anchorEl={anchorEl}
      loader={isLoader}
      bottomComponent={bottomLineComponent}
      customPopperContent={customPopperContent}
      data={scheduledPayoutData}
      iconClick={handleClick}
      isMoreIconVisible={scheduledPayoutData?.status === "active" && !isLoader}
      setAnchorEl={setAnchorEl}
      switchChecked={isActive}
      switchDisabled={
        scheduledPayoutData?.status === "on_hold" || isPayoutCreating
      }
      switchOnChange={handleSwitchOnChange}
      title={scheduledPayoutData?.name}
      walletName={scheduledPayoutData?.wallet?.name}
      walletType={getWalletTypeName(scheduledPayoutData?.wallet?.type)}
      isLdFlagRequired={true}
      switchLDFlagName={pschUpdateFe}
      tagProps={{
        text: autoPayoutStatus[scheduledPayoutData?.status]?.label,
        variant: autoPayoutStatus[scheduledPayoutData?.status]?.variant,
        deleteIcon: autoPayoutStatus[scheduledPayoutData?.status]?.icon,
      }}
      showLeftSideIcon={true}
      leftIcon={scheduledPayoutData?.icon}
    />
  );
};

export default AlreadySetupPayoutComponent;
