import { Box } from "@mui/system";
import React from "react";
import VerticalTable from "@speed/common/src/components/VerticalTable";
import CommonSkeleton from "@speed/common/src/components/CommonSkeleton";

const LinkSkeleton = ({ from }) => {
  const detailsTableData = () => {
    return [
      {
        header: (
          <CommonSkeleton
            width={53}
            sx={{ marginTop: "17px", borderRadius: "8px" }}
          />
        ),
        cell: (
          <CommonSkeleton
            width={60}
            sx={{ marginTop: "17px", borderRadius: "8px" }}
            color="#c4ccd2"
          />
        ),
      },
      {
        header: <CommonSkeleton width={102} sx={{ borderRadius: "8px" }} />,
        cell: (
          <CommonSkeleton
            width={176}
            color="#c4ccd2"
            sx={{ borderRadius: "8px" }}
          />
        ),
      },
    ];
  };

  const headerContent = () => {
    return (
      <Box className="header-content">
        {
          <CommonSkeleton
            width={241}
            height={29}
            sx={{ marginBottom: "16px", borderRadius: "8px" }}
            color="#c4ccd2"
          />
        }

        <Box className="header-price-content">
          <Box className="fiat-price">
            {
              <CommonSkeleton
                color={from === "oneQR" ? "#cbcfda" : "#8ba7ec"}
                height={23}
                sx={{ marginBottom: "11px", borderRadius: "8px" }}
              />
            }
          </Box>
        </Box>

        {from !== "cashback" && (
          <Box sx={{ marginTop: "15px" }}>
            {<CommonSkeleton width={317} sx={{ borderRadius: "8px" }} />}
          </Box>
        )}
      </Box>
    );
  };

  const headerContentForConnect = () => {
    return (
      <Box className="header-content">
        <CommonSkeleton
          width={241}
          height={29}
          sx={{ marginBottom: "18px", borderRadius: "8px" }}
          color="#c4ccd2"
        />
        <Box className="header-price-content">
          <Box className="fiat-price">
            <CommonSkeleton
              color="#8ba7ec"
              height={23}
              sx={{ borderRadius: "8px" }}
            />
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <>
      {from === "connect" ? headerContentForConnect() : headerContent()}

      {
        <Box className="details-content" marginTop={"70px !important"}>
          <VerticalTable
            label={
              <CommonSkeleton
                width={69}
                height={18}
                color="#c4ccd2"
                sx={{ borderRadius: "8px" }}
              />
            }
            rowData={detailsTableData()}
            color="#c4ccd2"
          />
        </Box>
      }
    </>
  );
};

export default LinkSkeleton;
