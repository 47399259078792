import Text from "@speed/common/src/components/Text/Text";
import React, { useState } from "react";
import UnderMaintenance from "../Common/UnderMaintenance";
import { AppBar, Box, Dialog, IconButton, Slide, Toolbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { upcomingMaintenanceScheduledText, viewDetails } from "../messages";
import moment from "moment-timezone";
import { useSelector } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const MaintenanceHelloBar = () => {
  const maintenanceDetails = useSelector(
    (state) => state.common.maintenanceDetails
  );
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Box display="flex">
      <Text
        size={14}
        font="regular"
        variant="body2"
        sx={{ color: "#fff", mr: "4px" }}
      >
        {upcomingMaintenanceScheduledText}
        {moment(maintenanceDetails?.maintenance_data?.start_time).format(
          "DD/MM/YYYY"
        )}
      </Text>
      <Text
        size={14}
        sx={{
          color: "#fff",
          borderBottom: "1px solid #fff",
          cursor: "pointer",
        }}
        onClick={() => setIsModalOpen(true)}
      >
        {viewDetails}
      </Text>
      <Dialog
        disableEscapeKeyDown
        fullScreen
        open={isModalOpen}
        TransitionComponent={Transition}
        className="fullscreen-modal"
      >
        <AppBar sx={{ position: "relative" }} className="modal-app-bar">
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={() => setIsModalOpen(false)}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <UnderMaintenance
          title={maintenanceDetails?.maintenance_data?.title}
          subtitle={maintenanceDetails?.maintenance_data?.description}
          fromModal={true}
        />
      </Dialog>
    </Box>
  );
};

export default MaintenanceHelloBar;
