import React from "react";
import { Box, Grid } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import Button from "@speed/common/src/components/Button/Button";
import Text from "@speed/common/src/components/Text/Text";
import {
  associateWithAccount,
  invitationDiffAccount,
  invitationExpiredMessage,
  partnerInvitationExpired,
  partnerInvitationExpiredMessage,
  signOutLockedIn,
} from "../messages";
import { continueBtn } from "@speed/common/src/messages";

const PartnerInvite = (props) => {
  const {
    open,
    handleClosePartnerInvite,
    accountName,
    invitedByEmail,
    email,
    handleButtonClick,
    flag,
  } = props;

  const emailFormat = (emailId) => (
    <span style={{ color: "#2a67ff" }}>{emailId}</span>
  );

  const footer = (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      display="flex"
    >
      <Button
        style={{ width: "400px" }}
        label={
          flag === partnerInvitationExpired ? continueBtn : signOutLockedIn
        }
        onClick={
          flag === partnerInvitationExpired
            ? handleClosePartnerInvite
            : handleButtonClick
        }
      />
    </Grid>
  );

  const body =
    flag === partnerInvitationExpired ? (
      <Box bgcolor="#F8F3DF" p={2} display="flex" alignItems="center" gap={1}>
        <WarningIcon sx={{ color: "#F1C40F", height: 16, width: 18.526 }} />
        <Text font="regular" size={14} variant="body2">
          {partnerInvitationExpiredMessage}
        </Text>
      </Box>
    ) : (
      invitationDiffAccount(
        emailFormat(invitedByEmail),
        emailFormat(email),
        accountName,
        true
      )
    );

  return (
    <Modal
      maxWidth="xs"
      body={body}
      footer={footer}
      open={open}
      title={
        flag === partnerInvitationExpired
          ? invitationExpiredMessage
          : associateWithAccount(accountName)
      }
      handleClose={handleClosePartnerInvite}
    />
  );
};

export default PartnerInvite;
