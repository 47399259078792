import { Box, MenuItem } from "@mui/material";
import CommonDateFilter from "@speed/common/src/components/CommonDateFilter";
import CustomSelect from "@speed/common/src/components/Select/Select";
import {
  getTimezoneMomentObj,
  infoTitleElement,
} from "@speed/common/src/components/constants";
import history from "@speed/common/src/components/history";
import { transactionDate, valueLabel } from "@speed/common/src/messages";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import {
  setWalletAppliedTransactonFiltersObj,
  setWalletSelectedTransaction,
} from "../../redux/common/actions";
import {
  currencyLabel,
  currencyPlaceholder,
  selectType,
  type,
} from "../messages";
import { getTimeZoneAndDateFormat } from "../constants";
import moment from "moment";
import CommonAdvanceWalletFilter from "./CommonAdvanceWalletFilter";

const typeOption = [
  { id: 1, short_name: "Payment" },
  { id: 2, short_name: "Network Fees" },
  { id: 3, short_name: "Withdraw" },
  { id: 4, short_name: "Withdraw Reversal" },
  { id: 5, short_name: "Network Fees Reversal" },
  { id: 6, short_name: "Reward Redemption" },
];

const TransactionFilterWallet = ({
  anchor,
  handleDrawer,
  targetCurrency,
  getFilteredTransactionList,
  setRows,
  setHasMore,
  setFilteredEndingBefore,
}) => {
  const { timezone: timezoneToUse } = getTimeZoneAndDateFormat();
  const timezone = timezoneToUse || moment.tz.guess();
  const walletTransactionDateFilterRef = useRef();
  const walletTransactionAppliedFilterObj = useSelector(
    (state) => state.common.walletTransactionAppliedFilterObj
  );
  const dispatch = useDispatch();
  const walletAssets = useSelector((state) => state.common.walletAssets);

  const [disabled, setDisabled] = useState(true);

  const resetFormValues = () => {
    walletTransactionDateFilterRef?.current?.setDisableStartDate?.();
    walletTransactionDateFilterRef?.current?.setDisableEndDate?.();
    setDisabled(true);
    resetForm();
  };

  const handleCloseDrawer = () => {
    resetFormValues();
    handleDrawer();
  };

  const walletTransactionValidationSchema = yup.object({
    ...walletTransactionDateFilterRef?.current?.dateFilterValidationSchema,
  });

  const formik = useFormik({
    initialValues: {
      currencyList:
        walletTransactionAppliedFilterObj?.target_currency?.in ||
        targetCurrency,
      typeList: walletTransactionAppliedFilterObj?.type?.in || [],
      start_at: +walletTransactionAppliedFilterObj?.created?.gte || null,
      end_at: +walletTransactionAppliedFilterObj?.created?.lte || null,
    },
    validationSchema: walletTransactionValidationSchema,
    enableReinitialize: true,
  });

  const { values, isValid, dirty, setFieldValue, resetForm } = formik;

  const getTransactionFilterRequestBody = () => {
    const { currencyList, typeList, start_at, end_at } = values;

    let requestObj = {};

    if (currencyList) {
      requestObj.target_currency = {
        in: currencyList,
      };
    }

    if (typeList.length > 0) {
      requestObj.type = {
        in: typeList,
      };
    }

    if (start_at) {
      requestObj.created = {
        ...requestObj.created,
        gte: +getTimezoneMomentObj(start_at, timezone)
          .startOf("day")
          .format("x"),
      };
    }

    if (end_at) {
      requestObj.created = {
        ...requestObj.created,
        lte: +getTimezoneMomentObj(end_at, timezone).endOf("day").format("x"),
      };
    }

    dispatch(setWalletAppliedTransactonFiltersObj(requestObj));
    return requestObj;
  };

  const redirectToPath = (reqObj) => {
    const baseUrl = history?.location?.pathname;
    let queryParam = "";
    if (reqObj?.target_currency) {
      queryParam += `&target_currency=${reqObj?.target_currency?.in?.[0]}`;
    }
    if (reqObj?.type) {
      queryParam += `&source_type=${reqObj?.type?.in?.join(",")}`;
    }
    if (reqObj?.created?.gte) {
      queryParam += `&created_gte=${reqObj?.created?.gte}`;
    }
    if (reqObj?.created?.lte) {
      queryParam += `&created_lte=${reqObj?.created?.lte}`;
    }

    if (queryParam.length) {
      queryParam = "?" + queryParam.slice(1);
    }

    return history.push(baseUrl + queryParam);
  };

  const handleApplyTransactionFilters = () => {
    setFilteredEndingBefore("");
    setRows([]);
    setHasMore(true);
    dispatch(setWalletSelectedTransaction(null));
    const reqObj = getTransactionFilterRequestBody();
    redirectToPath(reqObj);
    getFilteredTransactionList([], reqObj, false, true);
    handleCloseDrawer();
  };

  const renderCurrencyFilters = () => (
    <Box className="filter-country">
      {infoTitleElement(currencyLabel)}
      <CustomSelect
        multiple
        name="currencyList"
        displayEmpty
        showLabel
        label={valueLabel("")}
        customClass="margin-top15"
        value={values.currencyList}
        MenuProps={{
          id: "filter-source-type-menu",
          disableScrollLock: true,
        }}
        onChange={(event) => {
          const {
            target: { value },
          } = event;

          setFieldValue(
            "currencyList",
            typeof value === "string" ? value.split(",") : value
          );
        }}
        renderValue={(selected) => {
          if (selected?.length === 0) {
            return <Box component="span">{currencyPlaceholder}</Box>;
          }

          return selected?.join(", ");
        }}
      >
        {walletAssets?.length > 0 &&
          walletAssets?.map((option) => (
            <MenuItem key={option.id} value={option?.uuid}>
              {option?.uuid}
            </MenuItem>
          ))}
      </CustomSelect>
    </Box>
  );

  const renderTypeFilters = () => (
    <Box className="filter-country margin-top30">
      {infoTitleElement(type)}
      <CustomSelect
        multiple
        disabled={!typeOption.length}
        name="typeList"
        displayEmpty
        showLabel
        label={valueLabel("")}
        customClass="filter-country margin-top15"
        value={values.typeList}
        MenuProps={{
          id: "filter-operator-menu",
          disableScrollLock: true,
        }}
        onChange={(event) => {
          const {
            target: { value },
          } = event;

          setFieldValue(
            "typeList",
            typeof value === "string" ? value.split(",") : value
          );
        }}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <Box component="span">{selectType}</Box>;
          }

          return selected?.join(", ");
        }}
      >
        {typeOption?.map((option) => (
          <MenuItem key={option.id} value={option?.short_name}>
            {option?.short_name}
          </MenuItem>
        ))}
      </CustomSelect>
    </Box>
  );

  const transactionFilterBody = () => (
    <Box className="filter-body">
      {renderCurrencyFilters()}
      {renderTypeFilters()}
      <CommonDateFilter
        dateLabel={transactionDate}
        ref={walletTransactionDateFilterRef}
        formik={formik}
      />
    </Box>
  );

  useEffect(() => {
    setDisabled(!(isValid && dirty));
  }, [dirty, isValid, values, anchor]);

  return (
    <CommonAdvanceWalletFilter
      anchor={anchor}
      handleApplyFiltersButton={handleApplyTransactionFilters}
      handleClose={handleCloseDrawer}
      disableApplyButton={disabled}
      advanceFilterBody={transactionFilterBody}
    />
  );
};

export default TransactionFilterWallet;
