import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Grid } from "@mui/material";
import { Input } from "@speed/common/src/components/Input/Input";
import {
  confirmPasswordLabel,
  confirmPasswordValidate,
  newPasswordLabel,
} from "../messages";
import { passwordPolicyState, passwordValidationSchema } from "../constants";
import InputErrorMessage from "@speed/common/src/components/InputErrorMessage";
import * as yup from "yup";
import { passwordPlaceholder } from "@speed/common/src/components/messages";
import { useFormik } from "formik";
import {
  setSettingFormValid,
  setSettingFormChange,
  changePassword,
  setSettingFormCancel,
} from "../../redux/common/actions";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import PasswordPolicy from "@speed/common/src/components/PasswordPolicy";

function ChangePassword() {
  const { settingFormSubmit, settingFormCancel, isMobile, history } =
    useSelector((state) => state.common);
  const dispatch = useDispatch();

  const [activeState, setActiveState] = useState([]);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (user?.signup_method !== "email") {
      history.push("/dashboard");
      return;
    }
  }, []);

  const validationSchema = yup.object({
    old_password: yup.string().required(""),
    new_password: passwordValidationSchema,
    confirm_password: yup
      .string()
      .when("new_password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: yup
          .string()
          .oneOf([yup.ref("new_password")], confirmPasswordValidate),
      })
      .required(""),
  });

  const formik = useFormik({
    initialValues: {
      old_password: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: validationSchema,
  });

  const {
    values,
    errors,
    touched,
    isValid,
    isSubmitting,
    dirty,
    resetForm,
    setFieldValue,
    setTouched,
    getFieldProps,
  } = formik;

  const defaultErrorMessageProps = {
    touched,
    errors,
  };

  const handleBlur = (name, value) => {
    setTouched({ ...touched, [name]: value });
  };
  const handleInputChange = (name, value) => {
    if (name === "new_password") {
      setActiveState(passwordPolicyState(value));
    }
    setTouched({ ...touched, [name]: false });
    setFieldValue([name], value);
  };

  const clearFormData = () => {
    resetForm();
    setActiveState([]);
  };

  useEffect(() => {
    dispatch(setSettingFormChange(dirty));
  }, [values]);

  useEffect(() => {
    dispatch(setSettingFormValid(!(isValid && dirty) || isSubmitting));
  }, [isValid, dirty, isSubmitting]);

  useEffect(() => {
    if (dirty && settingFormSubmit) {
      let passwordObj = Object.assign({}, values);
      delete passwordObj.confirm_password;
      dispatch(changePassword(passwordObj)).then(() => clearFormData());
    }
    if (settingFormCancel) {
      clearFormData();
      setSettingFormCancel(false);
    }
  }, [settingFormSubmit, settingFormCancel]);

  return (
    <>
      <CustomDivider />
      <Box
        className="box-container"
        style={{ minHeight: isMobile ? "95vh" : "83vh" }}
      >
        <Grid container spacing={1}>
          <Grid style={{ width: "400px" }} component="form" autoComplete="off">
            <Input
              customClass="margin-top30"
              showLabel
              name="old_password"
              label="Old Password"
              type="password"
              fullWidth
              placeholder="Old Password"
              inputProps={{
                autoComplete: "new-password",
              }}
              {...getFieldProps("old_password")}
            />
            <Input
              customClass="margin-top30"
              type="password"
              isPasswordVisible={true}
              showLabel
              label={newPasswordLabel}
              value={values.new_password}
              name="new_password"
              fullWidth
              inputProps={{ maxLength: 64 }}
              placeholder={passwordPlaceholder}
              onBlur={() => handleBlur("new_password", true)}
              onChange={(e) =>
                handleInputChange("new_password", e.target.value)
              }
              error={touched.new_password && Boolean(errors.new_password)}
            />
            <InputErrorMessage
              {...defaultErrorMessageProps}
              inputName="new_password"
            />
            <Input
              customClass="margin-top30"
              type="password"
              isPasswordVisible={true}
              showLabel
              label={confirmPasswordLabel}
              value={values.confirm_password}
              name="confirm_password"
              fullWidth
              inputProps={{ maxLength: 64 }}
              placeholder={passwordPlaceholder}
              onBlur={() => handleBlur("confirm_password", true)}
              onChange={(e) =>
                handleInputChange("confirm_password", e.target.value)
              }
              error={
                touched.confirm_password && Boolean(errors.confirm_password)
              }
            />
            <InputErrorMessage
              {...defaultErrorMessageProps}
              inputName="confirm_password"
            />
            <Box className="password-policy-block change-password">
              <PasswordPolicy
                fontSize={14}
                activeState={activeState}
                isMobile={isMobile}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ChangePassword;
