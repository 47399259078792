import { Box, Drawer, TableCell, TableRow } from "@mui/material";
import CustomDivider from "@speed/common/src/components/Divider/Divider";
import { CustomLink } from "@speed/common/src/components/Link/Link";
import CustomTable from "@speed/common/src/components/Table/Table";
import CloseIcon from "@mui/icons-material/Close";
import Text from "@speed/common/src/components/Text/Text";
import { useSelector } from "react-redux";
import {
  btc,
  manageLimitLabel,
  manageSendingLimits,
  usdt,
} from "../../messages";
import { useEffect, useState } from "react";
import BTCManageLimits from "./BTCManageLimits";
import USDTManageLimits from "./USDTManageLimits";
import { callAPIInterface } from "../../constants";
import { isEmpty } from "lodash";

const columns = [
  { title: "Currency", width: "400px", paddingLeft: "36px !important" },
  { title: "Action", width: "250px", align: "center" },
];

function InstantSendSettings() {
  const currencyToShowAction = [btc, usdt];
  const [limitsData, setLimitsData] = useState();
  const [loadingLimits, setLoadingLimits] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState();
  const accountAsset = useSelector((state) => state.common.accountAsset);

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const handleClickAssetLimit = (rowItem) => {
    setSelectedAsset(rowItem.name);
    setOpenDrawer(true);
  };

  const getSendLimit = async () => {
    setLoadingLimits(true);
    try {
      const result = await callAPIInterface("GET", "/send/limits");
      !isEmpty(result) && setLimitsData(result);
    } catch (e) {}
    setLoadingLimits(false);
  };

  useEffect(() => {
    getSendLimit();
  }, []);

  const columnsData = columns.map((column) => (
    <TableCell
      key={column}
      align={column.align}
      sx={{
        minWidth: column.width,
        paddingLeft: column.paddingLeft,
      }}
    >
      {column.title}
    </TableCell>
  ));

  const rowsData = accountAsset?.map((rowItem) => (
    <TableRow key={rowItem.id}>
      <TableCell sx={{ width: "500px", paddingLeft: "36px !important" }}>
        <Text size={16} withIcon="start">
          <img
            src={rowItem?.icon}
            style={{ height: "20px", width: "20px", marginRight: "10px" }}
            alt="icon"
          />
          {rowItem?.name}
        </Text>
      </TableCell>
      <TableCell align="center">
        {currencyToShowAction.includes(rowItem?.name) && (
          <CustomLink
            size="small"
            bold
            onClick={() => !loadingLimits && handleClickAssetLimit(rowItem)}
          >
            {manageSendingLimits}
          </CustomLink>
        )}
      </TableCell>
    </TableRow>
  ));

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    tableRowSkeleton: !accountAsset?.length,
    hasMore: false,
    loadMore: () => {},
  };

  const headerSection = () => {
    return (
      <Box className="filter-header">
        <Text className="default-text" size={18} variant="h3">
          {manageLimitLabel(selectedAsset)}
        </Text>
        <Box
          height={20}
          width={20}
          onClick={handleCloseDrawer}
          component="span"
          className="pointer-cursor"
        >
          <CloseIcon sx={{ height: 20, width: 20 }} htmlColor="#848b9e" />
        </Box>
      </Box>
    );
  };

  const methodProps = {
    allTypeLimitData: limitsData,
    setLimitsData,
    selectedAsset,
  };

  const renderAssetWiseLimitSection = () =>
    selectedAsset === btc ? (
      <BTCManageLimits limitsData={limitsData?.["SATS"]} {...methodProps} />
    ) : (
      <USDTManageLimits limitsData={limitsData?.["USDT"]} {...methodProps} />
    );

  return (
    <>
      <CustomDivider />
      <CustomTable {...tableProps} />
      <Drawer
        className="accounts-filter-wrapper"
        anchor="right"
        open={openDrawer}
        onClose={handleCloseDrawer}
      >
        <Box className="filter-box-wrapper" role="presentation">
          {headerSection()}
          <CustomDivider />
          {renderAssetWiseLimitSection()}
        </Box>
      </Drawer>
    </>
  );
}

export default InstantSendSettings;
