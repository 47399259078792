import { combineReducers } from "redux";
import authConnect from "./authConnect/reducer";
import { sessionReducer } from "redux-react-session";

const connectAppReducer = combineReducers({
  authConnect: authConnect,
  connectSession: sessionReducer,
});

const connectRootReducer = (state, action) => {
  if (action.type === "RESET_STORE") {
    return connectAppReducer(undefined, action);
  }

  return connectAppReducer(state, action);
};

export default connectRootReducer;
