import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  TableCell,
  TableRow,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useDispatch, useSelector } from "react-redux";
import AccessDenied from "@speed/common/src/components/AccessDenied";
import CustomTable from "@speed/common/src/components/Table/Table";
import { emptySubText } from "@speed/common/src/components/messages";
import { Tag } from "@speed/common/src/components/Tag/Tag";
import { linkStatus } from "@speed/common/src/components/constants";
import {
  emptyPartner,
  loaderSpinner,
} from "@speed/common/src/components/images";
import { CustomPopper } from "@speed/common/src/components/Popper/Popper";
import { Modal } from "@speed/common/src/components/Modal/Modal";
import Button from "@speed/common/src/components/Button/Button";
import Text from "@speed/common/src/components/Text/Text";
import {
  cancelInviteRequestText,
  confirmBtn,
  remove,
  removeInvitee,
  verificationEmailSentTitle,
} from "../messages";
import { callAPIInterface, noOfRecords } from "../constants";
import { setIsDataAvailable, showToast } from "../../redux/common/actions";
import { resendMsg } from "@speed/common/src/messages";

const tableHeader = [
  {
    title: "Email",
    paddingLeft: "38px !important",
    width: "950px",
  },
  { title: "Status", width: "160px" },
  { title: "Actions", width: "25px", align: "center" },
];

const Invited = (props) => {
  const dispatch = useDispatch();
  const { shouldUpdateData } = props;
  const queryParam = "?limit=" + noOfRecords;
  const {
    aflGetlistofinvitedpartnerFe,
    aflRemoveinvitationFe,
    aflResendemailFe,
  } = useFlags();
  const [columns, setColumns] = useState(tableHeader);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentRow, setCurrentRow] = useState(null);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [rows, setRows] = useState([]);
  const [tableRowSkeleton, setTableRowSkeleton] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [queryParams, setQueryParams] = useState(queryParam);
  const [rowLoader, setRowLoader] = useState(false);
  const [isResendingEmail, setIsResendingEmail] = useState(false);
  const { isDataAvailable, caughtErrorInStrapi } = useSelector(
    (state) => state.common
  );
  const [merchantHasData, setMerchantHasData] = useState(false);

  const fetchInvitees = () => {
    setRows([]);
    setHasMore(true);
    getInvitees([], queryParam);
  };

  const loadMore = useCallback(() => {
    getInvitees(rows, queryParams);
  }, [rows]);

  const getInvitees = (lines, params) => {
    setTableRowSkeleton(true);

    const invitePartnersGuide = new Promise((resolve, reject) => {
      callAPIInterface("GET", "/affiliate-partner/invite" + params, {})
        .then((res) => {
          if (res) {
            setTableRowSkeleton(false);
            if (!res.has_more) {
              setHasMore(false);
            } else {
              setQueryParams(
                queryParam +
                  "&ending_before=" +
                  res.data[res.data.length - 1].id
              );
            }
            setRows(lines.concat(res.data));
          }
          resolve(res);
        })
        .catch((e) => reject(e));
    });

    const affiliatePartnerPromise = new Promise((resolve, reject) => {
      callAPIInterface("GET", "/affiliate-partner?limit=1", {})
        .then((resInvitee) => {
          resolve(resInvitee);
        })
        .catch((e) => reject(e));
    });

    Promise.all([invitePartnersGuide, affiliatePartnerPromise])
      .then((res) => {
        setMerchantHasData(res?.[1]?.data?.length > 0);
        if (!res?.[1]?.data?.length) {
          dispatch(setIsDataAvailable(res?.[0]?.data?.length > 0));
        }
      })
      .catch(() => {
        setTableRowSkeleton(false);
      });
  };

  useEffect(
    () => aflGetlistofinvitedpartnerFe && fetchInvitees(),
    [aflGetlistofinvitedpartnerFe, shouldUpdateData]
  );

  const handleClick = (event, rowData) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
    setCurrentRow(rowData);
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      handleClick(e, currentRow);
    }
  };

  let columnsData = columns.map((column) => (
    <TableCell
      key={column.title}
      align={column.align}
      sx={{
        minWidth: column.width,
        height: "50px",
        paddingLeft: column.paddingLeft,
      }}
    >
      {column.title}
    </TableCell>
  ));

  const managePartnerFlags = aflRemoveinvitationFe || aflResendemailFe;

  useEffect(() => {
    if (managePartnerFlags) {
      setColumns(tableHeader);
    } else {
      const newColumns = tableHeader.filter(
        (column) => column.title !== "Actions"
      );
      setColumns(newColumns);
    }
  }, [aflRemoveinvitationFe, aflResendemailFe]);

  let rowsData = rows?.map((rowItem) => {
    return (
      <TableRow key={rowItem?.id}>
        {rowItem.id === currentRow?.id && rowLoader ? (
          columns.map((column) => {
            return (
              <TableCell
                key={`${column.title}_loader`}
                sx={{
                  width: column.width,
                  padding: "18px 16px !important",
                  height: "50px",
                }}
              >
                <Skeleton />
              </TableCell>
            );
          })
        ) : (
          <>
            <TableCell sx={{ paddingLeft: "38px !important", height: "50px" }}>
              {rowItem?.email}
            </TableCell>
            <TableCell>
              <Tag
                text={linkStatus[rowItem?.status?.toLowerCase()]?.label}
                variant={linkStatus[rowItem?.status?.toLowerCase()]?.variant}
              />
            </TableCell>
            {managePartnerFlags && (
              <TableCell align="center">
                <MoreHorizIcon
                  className="horizontal-dots-icon pointer-cursor"
                  tabIndex={0}
                  onKeyDown={handleKeyDown}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleClick(event, rowItem);
                  }}
                />
              </TableCell>
            )}
          </>
        )}
      </TableRow>
    );
  });

  const tableProps = {
    columns: columnsData,
    rows: rowsData,
    textOnNoData: emptySubText(true, "invites pending"),
    noDataImage: emptyPartner,
    tableRowSkeleton: tableRowSkeleton,
    hasMore: hasMore,
    loadMore: loadMore,
  };

  const confirmationModalContent = (
    <Text className="grey-text" size={16} font="regular" variant="subtitle1">
      {cancelInviteRequestText(currentRow?.email)}
    </Text>
  );

  const closeModal = () => {
    setOpenConfirmModal(false);
  };

  const handleResendEmail = () => {
    setIsResendingEmail(true);
    callAPIInterface(
      "POST",
      `/affiliate-partner/invite/${currentRow?.id}/resend-email`,
      {}
    )
      .then(() => {
        setIsResendingEmail(false);
        dispatch(
          showToast({
            isToastOpen: true,
            toastMessage: verificationEmailSentTitle,
            toastVariant: "success",
          })
        );
      })
      .catch(() => {
        setIsResendingEmail(false);
      });
  };

  const handleRemoveEmail = () => {
    closeModal();
    setRowLoader(true);
    callAPIInterface(
      "DELETE",
      `/affiliate-partner/invite/${currentRow?.id}`,
      {}
    )
      .then(() => {
        const filteredRows = rows?.filter((row) => row.id !== currentRow?.id);
        setRows(filteredRows);
        if (!merchantHasData) {
          dispatch(setIsDataAvailable(filteredRows.length > 0));
        }
        setRowLoader(false);
      })
      .catch(() => {
        setRowLoader(false);
      });
  };

  const confirmationModalFooter = (
    <Button
      color="error"
      style={{ width: "100px" }}
      label={confirmBtn}
      onClick={handleRemoveEmail}
    />
  );

  return (
    <Box className="main-content">
      {aflGetlistofinvitedpartnerFe ? (
        (isDataAvailable || caughtErrorInStrapi) && (
          <CustomTable {...tableProps} />
        )
      ) : (
        <AccessDenied />
      )}
      {(aflResendemailFe || aflRemoveinvitationFe) && (
        <CustomPopper
          disablePortal={true}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          position="bottom"
          handleClose={() => setAnchorEl(null)}
        >
          <List>
            {aflResendemailFe && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={handleResendEmail}
                  sx={{ gap: 1 }}
                  disabled={isResendingEmail}
                >
                  <ListItemText primary={resendMsg} />
                  {isResendingEmail && (
                    <img
                      src={loaderSpinner}
                      alt="Loader"
                      width={15}
                      height={15}
                    />
                  )}
                </ListItemButton>
              </ListItem>
            )}
            {aflRemoveinvitationFe && (
              <ListItem disablePadding>
                <ListItemButton
                  onClick={() => setOpenConfirmModal(true)}
                  disabled={isResendingEmail}
                >
                  <ListItemText primary={remove} className="text-danger" />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </CustomPopper>
      )}
      {aflRemoveinvitationFe && (
        <Modal
          maxWidth="sm"
          body={confirmationModalContent}
          footer={confirmationModalFooter}
          handleClose={() => setOpenConfirmModal(false)}
          open={openConfirmModal}
          title={removeInvitee}
        />
      )}
    </Box>
  );
};

export default Invited;
