import { Box } from "@mui/material";
import { searchableModules } from "@speed/common/src/components/constants";
import HorizontalTable from "@speed/common/src/components/HorizontalTable";
import { useDispatch, useSelector } from "react-redux";
import { setSearchedModuleName } from "../../redux/common/actions";
import { viewAllResultsLabel } from "../messages";

const HorizontalSearchPageTable = ({
  moduleName,
  columns,
  rows,
  searchQueryUrl,
  inputString,
  totalNumOfSearchResults,
}) => {
  const history = useSelector((state) => state.common.history);
  const dispatch = useDispatch();
  const moduleTitle = searchableModules[moduleName];
  const maxNumOfRow = 10;
  return (
    <>
      {rows?.length && (
        <Box className="module-result-wrapper">
          <HorizontalTable
            tableBodyClassName="pointer-cursor"
            label={moduleTitle}
            columns={columns}
            rows={rows}
            rowsPerPage={maxNumOfRow}
            isColumnShown={1}
            viewAllButtonLabel={`${
              viewAllResultsLabel[0]
            } ${totalNumOfSearchResults} ${
              totalNumOfSearchResults === 1
                ? viewAllResultsLabel[1]
                : viewAllResultsLabel[2]
            }`}
            handleViewAllClick={() => {
              let searchQueryParams = encodeURIComponent(
                `is:${searchQueryUrl} ${inputString}`
              );
              dispatch(setSearchedModuleName(moduleTitle));
              history.push("/search" + `?query=${searchQueryParams}`);
            }}
            isShowButton
            isShowPagination={false}
          />
        </Box>
      )}
    </>
  );
};

export default HorizontalSearchPageTable;
